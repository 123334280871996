import {
    useLanguageLocaleState,
    LanguageLocaleType,
} from '@contexts/LanguageSwitcher';
import { Icons } from '@components/Atoms';
import { useConfigurationState } from '@contexts/ConfigurationContext';
import { EnvironmentEnum } from '@interfaces/Api/ConfigurationResponse';

export const LanguageSelector = () => {
    const { selectedLanguage, saveSelectedLanguage } = useLanguageLocaleState();
    const { configuration } = useConfigurationState();

    const languages = [
        LanguageLocaleType.en,
        LanguageLocaleType.de,
        LanguageLocaleType.it,
        LanguageLocaleType.es,
        LanguageLocaleType.fr,
        LanguageLocaleType.jp,
    ];

    if (
        [
            EnvironmentEnum.DEV,
            EnvironmentEnum.LOCAL,
            EnvironmentEnum.UAT,
        ].includes(configuration?.environment)
    ) {
        languages.push(LanguageLocaleType.cimode);
    }

    return (
        <div className="relative border-b border-gray-200 pb-1 mr-4 cursor-pointer w-14">
            <Icons name="GlobeEuropeAfricaIcon" size="small" />
            <select
                value={selectedLanguage}
                className="ml-1 mr-6 bg-transparent border-none outline-none absolute cursor-pointer -top-1px left-0 pl-4 z-30 text-xs"
                name="language-switcher"
                onChange={(e) =>
                    saveSelectedLanguage(e.target.value as LanguageLocaleType)
                }
            >
                {languages.map((option) => (
                    <option value={option} key={option}>
                        {option === 'jp' ? '日本語' : option.toUpperCase()}
                    </option>
                ))}
            </select>
        </div>
    );
};
