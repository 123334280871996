import React from 'react';
import { useTranslation } from 'react-i18next';

import { PerformanceSummary } from '@interfaces/Api';

import { getFormattedCurrency } from '@helpers/isoCurrencies';
import { getStyling } from '@helpers/getStyling';

import { WidgetDoughnutChart } from '@components/Charts';

export interface ReportsBreakdownProps {
    title?: string;
    subscriptionAmount?: number;
    subscriptionCurrency?: string;
    chartData: PerformanceSummary;
}

const SubscriptionInvestmentReportsBreakdown: React.FC<
    ReportsBreakdownProps
> = (props: ReportsBreakdownProps) => {
    const { t } = useTranslation();
    const getSubscriptionAmount = () => {
        return [
            getFormattedCurrency(
                props.subscriptionAmount,
                props.subscriptionCurrency
            ),
        ]
            .filter((a) => a)
            .join('');
    };

    const { product_a, product_b, product_c } = getStyling();

    const breakDownData = {
        labels: [
            t('client.investment_reports.paid_in') + ' - ' +
                getFormattedCurrency(
                    props.chartData.totalPaidIn,
                    props.subscriptionCurrency
                ),
            t('client.investment_reports.undrawn') + ' - ' +
                getFormattedCurrency(
                    props.chartData.totalUndrawn,
                    props.subscriptionCurrency
                ),
            t('client.investment_reports.called_but_unpaid') + ' - ' +
                getFormattedCurrency(
                    props.chartData.totalUndrawnCalled,
                    props.subscriptionCurrency
                ),
        ],
        datasets: [
            {
                label: '# of Total Commitments',
                data: [
                    props.chartData.totalPaidIn,
                    props.chartData.totalUndrawn,
                    props.chartData.totalUndrawnCalled,
                ],
                backgroundColor: [product_a, product_b, product_c],
            },
        ],
    };

    return (
        <div className="mb-8">
            <div className="py-4 border-b border-gray-200">
                <h2 className="text-xl">{props.title}</h2>
            </div>
            <div className="flex justify-center my-4">
                <div className="w-full md:w-1/3">
                    <WidgetDoughnutChart
                        title={t('client.investment_reports.total_commitment')}
                        abstract={getSubscriptionAmount()}
                        data={breakDownData}
                    />
                </div>
            </div>
        </div>
    );
};

export default SubscriptionInvestmentReportsBreakdown;
