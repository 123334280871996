import * as Yup from 'yup';

import getClientRegistrationFormFields from '@pages/Clients/Clients/Components/Registration/ClientRegistrationFormFields';

import {
    AddressSelection,
    AdvisorSelection,
    BankAccountsSelection,
    BookingCentreSelection,
    BusinessDetailsSelection,
    ContactDetailsSelection,
    ClientTypeSelection,
    EndInvestorTypeSelection,
    IntermediariesSelection,
    JurisdictionSelection,
    KycSelection,
    LoginAccountSelection,
    NationalitiesSelection,
    NomineeSelection,
    PersonalDetailsSelection,
    ServiceLevelSelection,
    SubscriptionProcessSelection,
} from './Components';

import { StepProps } from './Components/Interfaces';
import { t } from 'i18next';

export const getStepsConfig = () => {
    const steps: StepProps[] = [
        {
            name: getClientRegistrationFormFields(t).intermediaryId.name,
            component: IntermediariesSelection,
            validationSchema: Yup.object().shape({
                intermediaryId: Yup.string().required(
                    t('client.registration.intermediary.required_validation_message')
                ),
            }),
        },
        {
            name: getClientRegistrationFormFields(t).endInvestorType.name,
            component: EndInvestorTypeSelection,
            validationSchema: Yup.object().shape({
                endInvestorType: Yup.string().required(
                    t('client.registration.investor_type.required_validation_message')
                ),
            }),
        },
        {
            name: getClientRegistrationFormFields(t).bookingCentreIds.name,
            component: BookingCentreSelection,
            validationSchema: Yup.object().shape({
                bookingCentreIds: Yup.array().required(
                    t('client.registration.booking_centres.required_validation_message')
                ),
            }),
        },
        {
            name: getClientRegistrationFormFields(t).adviserSystemUserId.name,
            component: AdvisorSelection,
            validationSchema: Yup.object().shape({
                adviserSystemUserId: Yup.string().required(
                    t('client.registration.advisor.client_adviser.required_validation_message')
                ),
            }),
        },
        {
            name: getClientRegistrationFormFields(t).serviceLevelType.name,
            component: ServiceLevelSelection,
            validationSchema: Yup.object().shape({
                serviceLevelType: Yup.string().required(
                    t('client.registration.service_level_type.required_validation_message')
                ),
            }),
        },
        {
            name: 'subscriptionProcessType',
            component: SubscriptionProcessSelection,
            validationSchema: Yup.object().shape({
                subscriptionProcessType: Yup.string().required(
                    t('client.registration.subscription_process.required_validation_message')
                ),
            }),
        },
        {
            name: 'nomineeEndInvestorProfileId',
            component: NomineeSelection,
            validationSchema: Yup.object().shape({
                nomineeEndInvestorProfileId: Yup.string().required(
                    t('client.registration.nominee.required_validation_message')
                ),
            }),
        },
        {
            name: 'onboardingJurisdictionCountryCode',
            component: JurisdictionSelection,
            validationSchema: Yup.object().shape({
                onboardingJurisdictionCountryCode: Yup.string().required(
                    'Please select a jurisdiction'
                ),
            }),
        },
        {
            name: 'clientType',
            component: ClientTypeSelection,
            validationSchema: Yup.object().shape({
                clientType: Yup.string().required(
                    t('client.registration.client_type.required_validation_message')
                ),
            }),
        },
        {
            name: 'isKycCompliant',
            component: KycSelection,
            validationSchema: Yup.object().shape({
                isKycCompliant: Yup.string().required(
                    t('client.registration.kyc_compliance.required_validation_message')
                ),
            }),
        },
        {
            name: 'businessEntityDetails',
            component: BusinessDetailsSelection,
            validationSchema: Yup.object().shape({
                companyName: Yup.string().required(
                    'Please enter a company name'
                ),
                registrationNumber: Yup.string().required(
                    'Please enter a business registration number'
                ),
                subscribersLegalForm: Yup.string().required(
                    'Please enter a subscribers legal form'
                ),
                subscribersRegister: Yup.string().required(
                    'Please enter a subscribers register'
                ),
                subscriberParentListingAuthority: Yup.string().required(
                    'Please enter a subscriber parent listing authority'
                ),
                subscribersRegulator: Yup.string().required(
                    'Please enter a subscribers regulator'
                ),
            }),
        },
        {
            name: 'individualDetails',
            component: PersonalDetailsSelection,
            validationSchema: Yup.object().shape({
                firstName: Yup.string()
                    .required(t('client.registration.personal_details.legal_first_name.required_validation_message')),
                lastName: Yup.string()
                    .required(t('client.registration.personal_details.legal_last_name.required_validation_message')),
                dateOfBirth: Yup.string().required(
                    t('client.registration.personal_details.date_of_birth.required_validation_message')
                ),
                townOfBirth: Yup.string().required(
                    t('client.registration.personal_details.town_of_birth.required_validation_message')
                ),
                countryCodeOfBirth: Yup.string().required(
                    t('client.registration.personal_details.country_of_birth.required_validation_message')
                ),
            }),
        },
        {
            name: 'nationalities',
            component: NationalitiesSelection,
        },
        {
            name: 'emailAddress',
            component: ContactDetailsSelection,
            validationSchema: Yup.object().shape({
                emailAddress: Yup.string()
                    .email()
                    .required(
                        getClientRegistrationFormFields(t).emailAddress
                            .requiredErrorMsg
                    ),
                phoneDiallingCode: Yup.string()
                    .max(5, 'Enter a valid Area code')
                    .matches(
                        /^(\+?\d{1,3}|\d{1,4})$/,
                        "Must match a Country Dial code eg '+44' "
                    )
                    .required(
                        getClientRegistrationFormFields(t).phoneDiallingCode
                            .requiredErrorMsg
                    ),
                phoneNumber: Yup.string()
                    .max(18)
                    .required(
                        getClientRegistrationFormFields(t).phoneNumber
                            .requiredErrorMsg
                    ),
            }),
        },
        {
            name: 'addresses',
            component: AddressSelection,
            validationSchema: Yup.object().shape({
                addresses: Yup.array().of(
                    Yup.object().shape({
                        houseName: Yup.string().required(
                            t('client.registration.addresses.house_name_number.required_validation_message')
                        ),
                        residentialLine1: Yup.string().required(
                            t('client.registration.addresses.line1.required_validation_message')
                        ),
                        city: Yup.string().required('Please enter a city'),
                        postCode: Yup.string().required(
                            t('client.registration.addresses.postcode.required_validation_message')
                        ),
                        countryCode: Yup.string().required(
                            t('client.registration.addresses.country.placeholder')
                        ),
                    })
                ),
            }),
        },
        {
            name: 'bankAccounts',
            component: BankAccountsSelection,
            validationSchema: Yup.object().shape({
                bankAccounts: Yup.array().of(
                    Yup.object().shape({
                        bankName: Yup.string().required(
                            t('client.registration.bank_accounts.account_nickname.required_validation_message')
                        ),
                        countryCurrencyCode: Yup.string().required(
                            t('client.registration.bank_accounts.currency.required_validation_message')
                        ),
                        countryCodeOfAccount: Yup.string().required(
                            t('client.registration.bank_accounts.country_of_bank_account.required_validation_message')
                        ),
                        IBAN: Yup.string().required('client.registration.bank_accounts.iban.required_validation_message'),
                        confirmIBAN: Yup.string().oneOf(
                            [Yup.ref('IBAN'), null],
                            t('client.registration.bank_accounts.iban.match_validation_message')
                        ),
                    })
                ),
            }),
        },
        {
            name: 'systemUsers',
            component: LoginAccountSelection,
            validationSchema: Yup.array().of(
                Yup.object().shape({
                    _id: Yup.string().optional(),
                    email: Yup.string().optional(),
                })
            ),
        },
    ];

    return { steps };
};
