import {
    createContext,
    PropsWithChildren,
    useContext,
    useState,
    useCallback,
} from 'react';

import {
    EndInvestorPortfolioItem,
    SubscriptionLifecycle,
} from '@interfaces/Api';

import { LifecycleGetBySubscriptionId } from '@stores/Subscriptions/Subscriptions.services';
import { PortfolioGetBySubscriptionId } from '@api/Dashboard';

export interface PortfolioContextProps {
    getLifecycleData: (subscriptionId: any) => void;
    subscriptionLifecycleData?: SubscriptionLifecycle;
    getPortfolioBySubscriptionId: (
        endInvestorProfileId: string,
        subscriptionId: string
    ) => void;
    endInvestorPortfolioItem?: EndInvestorPortfolioItem;
    portfolioIsLoading?: boolean;
}

const portfolioDefaultState: PortfolioContextProps = {
    getLifecycleData: () => {},
    subscriptionLifecycleData: undefined,
    getPortfolioBySubscriptionId: (
        endInvestorProfileId: string,
        subscriptionId: string
    ) => {},
    endInvestorPortfolioItem: undefined,
    portfolioIsLoading: false,
};

const PortfolioContext = createContext<PortfolioContextProps>(
    portfolioDefaultState
);

export default PortfolioContext;

export const usePortfolioState = () => {
    return useContext(PortfolioContext);
};

export const PortfolioProvider = ({ children }: PropsWithChildren<{}>) => {
    const [subscriptionLifecycleData, setSubscriptionLifecycleData] =
        useState<SubscriptionLifecycle>();
    const [LifecycleDataIsLoading, setLifecycleDataIsLoading] =
        useState<boolean>(false);
    const [portfolioIsLoading, setPortfolioIsLoading] =
        useState<boolean>(false);

    const [endInvestorPortfolioItem, setEndInvestorPortfolioItem] =
        useState<EndInvestorPortfolioItem>();

    const getLifecycleData = useCallback(async (subscriptionId: string) => {
        try {
            setLifecycleDataIsLoading(true);
            const result = await LifecycleGetBySubscriptionId(subscriptionId);
            setSubscriptionLifecycleData(result);
            setLifecycleDataIsLoading(false);
        } catch (error) {
            console.log('error getting lifecycle data', subscriptionId);
        }
    }, []);

    const getPortfolioBySubscriptionId = useCallback(
        async (endInvestorProfileId: string, subscriptionId: string) => {
            try {
                setPortfolioIsLoading(true);
                const result = await PortfolioGetBySubscriptionId(
                    endInvestorProfileId,
                    subscriptionId
                );
                setEndInvestorPortfolioItem(result);
                setPortfolioIsLoading(false);
            } catch (err) {
                setPortfolioIsLoading(false);
                console.error(
                    '[ProductOverviewContext]: getPortfolioBySubscriptionId, err:',
                    err
                );
            }
        },
        []
    );

    return (
        <PortfolioContext.Provider
            value={{
                getLifecycleData,
                subscriptionLifecycleData,
                getPortfolioBySubscriptionId,
                endInvestorPortfolioItem,
                portfolioIsLoading,
            }}
        >
            {children}
        </PortfolioContext.Provider>
    );
};
