import { Button } from '@components/Atoms';
import { getPillStyle } from '@components/Molecules';
import { Tab } from '@headlessui/react';
import classNames from 'classnames';

interface TabsProps {
    tabs: string[];
    activeTab?: number;
    onClick: (tab: string) => void;
    tabsPanel: React.ReactNode[];
    includeOutline?: boolean;
}

export const Tabs = ({
    tabs,
    tabsPanel,
    activeTab,
    onClick,
    includeOutline,
}: TabsProps) => {
    const handleOnClick = (tab: string) => {
        onClick(tab);
    };

    return (
        <div className="relative">
            <Tab.Group selectedIndex={activeTab}>
                <div className="absolute -top-5 left-0 w-full flex justify-center">
                    <Tab.List className="inline-flex flex-row rounded-full bg-pill-bg items-center">
                        {tabs.map((tab) => (
                            <Tab as="div" key={tab} className="h-full">
                                {({ selected }) => (
                                    <Button
                                        onClick={() => handleOnClick(tab)}
                                        label={tab}
                                        className={getPillStyle(selected)}
                                        buttonType="pill"
                                    />
                                )}
                            </Tab>
                        ))}
                    </Tab.List>
                </div>
                <Tab.Panels>
                    {tabsPanel.map((child, i) => (
                        <Tab.Panel
                            className={classNames(
                                includeOutline &&
                                    'border border-bg-logo-grey p-8 shadow-sm rounded-xl'
                            )}
                            key={i}
                        >
                            {child}
                        </Tab.Panel>
                    ))}
                </Tab.Panels>
            </Tab.Group>
        </div>
    );
};
