import React from 'react';

export const IconCloseDark = ({
    width = 16,
    height = 16,
    fill = 'none',
    stroke = 'currentColor',
}) => {
    return (
        <svg
            width={width}
            height={height}
            viewBox={'0 0 16 16'}
            fill={fill}
            stroke={stroke}
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M12.2426 4.4644C12.4379 4.26913 12.4379 3.95255 12.2426 3.75729C12.0474 3.56203 11.7308 3.56203 11.5355 3.75729L8 7.29282L4.46447 3.75729C4.2692 3.56203 3.95262 3.56203 3.75736 3.75729C3.5621 3.95255 3.5621 4.26913 3.75736 4.4644L7.29289 7.99993L3.75736 11.5355C3.5621 11.7307 3.5621 12.0473 3.75736 12.2426C3.95262 12.4378 4.2692 12.4378 4.46447 12.2426L8 8.70704L11.5355 12.2426C11.7308 12.4378 12.0474 12.4378 12.2426 12.2426C12.4379 12.0473 12.4379 11.7307 12.2426 11.5355L8.70711 7.99993L12.2426 4.4644Z"
                fill="#010445"
            />
        </svg>
    );
};
