export const sum = (...args: (number | undefined)[]): number => {
    return (
        [...args]
            .filter((a) => a)
            .reduce((prevVal, currVal) => {
                return currVal! + prevVal!;
            }, 0) || 0
    );
};

export const getFormattedPercentage = (
    percentage: number,
    decimalPlaces?: number
): string => {
    const text = Number(percentage / 100).toLocaleString(undefined, {
        style: 'percent',
        minimumFractionDigits: decimalPlaces || 2,
    });

    return text;
};
