import React, { createContext, useContext, useEffect, useMemo, useState } from 'react';

import {
    AuthPermissionEnum,
    AuthRoleEnum,
    CurrentUserResponse,
    SystemUserRoleActions,
} from '@interfaces/Api';

import {
    authenticationCurrentUserGet,
    authenticationLogin,
    authenticationLogout,
    authenticationVerify,
} from '@api/Authentication';
import { getSystemUserRoleActions } from '@api/SystemUserRoleActions';

import { SessionTimeout } from '@components/Organisms';

import { useMountEffect } from '@hooks/useMountEffect';

import {
    getLocalStorageAuthTokens,
    LOCAL_STORAGE_AUTH_TOKENS,
    removeLocalStorageAuthTokens,
    setLocalStorageAuthTokens,
} from '@helpers/auth.helper';
import { LoginStatus } from '@interfaces/Api/LoginResponse';
import { DocusignAuthProvider } from './DocusignContext';
import { EndInvestorProfileProvider } from './EndInvestorProfileContext';
import { LanguageLocaleProvider } from './LanguageSwitcher';
import { SubscriptionTemplatesProvider } from './SubscriptionTemplatesContext';
import { DocumentTemplateLibraryProvider } from './DocumentTemplateLibrary';
import { ProspectiveInvestorResponse, ProspectiveInvestorStatusEnum } from '@interfaces/Api/ProspectiveInvestorResponse';
import { ProspectiveInvestorsGetAll } from '@api/ProspectiveInvestors';
import { isBeforeTwentyFourHours } from '@helpers/Product.helper';

export interface AuthContextProps {
    currentUser?: CurrentUserResponse;
    prospectiveInvestor?: ProspectiveInvestorResponse;
    forbiddenToAccessDetailPage?: boolean;
    isLoggedIn: boolean;
    hasPermissions: (
        permission: AuthPermissionEnum | AuthPermissionEnum[]
    ) => boolean;
    hasRoles: (roles: AuthRoleEnum[]) => boolean;
    login: (
        email: string,
        password: string
    ) => Promise<{ status: LoginStatus }>;
    loginVerifyMfa: (
        email: string,
        password: string,
        mfaType: 'email',
        mfaCode: string
    ) => Promise<any>;
    logout: () => void;
    getCurrentUserData: () => Promise<void>;
    currentSystemUserRoleActions?: SystemUserRoleActions;
}

export const authDefaultState: AuthContextProps = {
    isLoggedIn: false,
    hasPermissions: (permission) => false,
    forbiddenToAccessDetailPage: false,
    hasRoles: (roles) => false,
    login: async (email: string, password: string) => ({
        status: LoginStatus.Success,
    }),
    loginVerifyMfa: async (
        email: string,
        password: string,
        mfaType: 'email',
        mfaCode: string
    ) => {},
    logout: async () => {},
    getCurrentUserData: async () => {},
    currentSystemUserRoleActions: undefined,
};

export const AuthContext = createContext<AuthContextProps>(authDefaultState);

export const useAuthState = () => {
    return useContext(AuthContext);
};

export const AuthProvider: React.FC = ({
    children,
}: React.PropsWithChildren<{}>) => {
    const [currentUser, setCurrentUser] = useState<CurrentUserResponse>();
    const [prospectiveInvestor, setProspectiveInvestor] = useState<ProspectiveInvestorResponse>();
    const [forbiddenToAccessDetailPage, setForbiddenToAccessDetailPage] = useState<boolean>();

    const [currentSystemUserRoleActions, setCurrentSystemUserRolesActions] =
        useState<SystemUserRoleActions>();
    const [currentUserLoading, setCurrentUserLoading] = useState<boolean>(true);

    const login = async (
        email: string,
        password: string
    ): Promise<{
        status: LoginStatus;
    }> => {
        const result = await authenticationLogin(email, password);

        if (result.authorizationTokens) {
            setLocalStorageAuthTokens(result.authorizationTokens);
            await getCurrentUserData();
        }
        return {
            status: result.status,
        };
    };

    const loginVerifyMfa = async (
        email: string,
        password: string,
        mfaType: 'email',
        mfaCode: string
    ) => {
        const result = await authenticationVerify({
            email,
            password,
            mfaType,
            mfaCode,
        });
        if (result) {
            setLocalStorageAuthTokens(result);
            await getCurrentUserData();
        }
    };

    const logout = () => {
        // invalidate tokens
        authenticationLogout().then();
        // remove tokens from local storage
        removeLocalStorageAuthTokens();
        // set current user to undefined
        setCurrentUser(undefined);
    };

    const hasPermissions = (
        permissions: AuthPermissionEnum | AuthPermissionEnum[]
    ) => {
        if (Array.isArray(permissions)) {
            // check if permissions are all include in currentUser permissions
            for (let i = 0; i < permissions.length; i++) {
                if (!currentUser?.permissions.includes(permissions[i])) {
                    return false;
                }
            }
            return true;
        }
        return currentUser?.permissions?.includes(permissions) || false;
    };

    const hasRoles = (roles: string[]) => {
        const role = currentUser?.user.role?.toString();
        if (role) {
            return roles.includes(role);
        }
        return false;
    };

    const getCurrentUserData = async () => {
        try {
            const currentUser = await authenticationCurrentUserGet();
            setCurrentUser(currentUser);
            const userRoleActions = await getSystemUserRoleActions();
            setCurrentSystemUserRolesActions(userRoleActions);
        } catch (err) {
            console.error(err);
        }
    };

    // Load user data on mount
    useMountEffect(() => {
        async function RunAsync() {
            setCurrentUserLoading(true);
            if (getLocalStorageAuthTokens()) {
                await getCurrentUserData();
            }
            setCurrentUserLoading(false);
        }
        RunAsync();
    });

    useMountEffect(() => {
        // if auth tokens are removed from local storage in a different tab, set current user to undefined
        const handleStorageChange = (e: StorageEvent) => {
            if (e.key === LOCAL_STORAGE_AUTH_TOKENS) {
                const authTokens = getLocalStorageAuthTokens();
                if (!authTokens) {
                    setCurrentUser(undefined);
                }
            }
        };

        window.addEventListener('storage', handleStorageChange);

        return () => {
            window.removeEventListener('storage', handleStorageChange);
        };
    });

    useEffect(() =>  {
        const fetchProspectiveInvestor = async () => {
            if (currentUser?.user.role === AuthRoleEnum.prospectiveInvestor) {
                const response = (
                    await ProspectiveInvestorsGetAll()
                )?.[0];
                
                let canNotAccessDetailPage = false;
                
                if (currentUser?.user?.role == AuthRoleEnum.prospectiveInvestor) {
                    if (response?.status !== ProspectiveInvestorStatusEnum.approved) {
                        canNotAccessDetailPage = true;
                    } else {
                        if (response?.countryCodeOfResidence == 'GB' && isBeforeTwentyFourHours(response)) {
                            canNotAccessDetailPage = true;
                        } else {
                            canNotAccessDetailPage = false;
                        }
                    }
                }

                setForbiddenToAccessDetailPage(canNotAccessDetailPage);
                setProspectiveInvestor(response);
            }
        }

        fetchProspectiveInvestor();
    }, [currentUser?.user.role])


    return (
        <AuthContext.Provider
            value={{
                currentUser,
                prospectiveInvestor,
                forbiddenToAccessDetailPage,
                isLoggedIn: !!currentUser,
                hasPermissions,
                hasRoles,
                login,
                loginVerifyMfa,
                logout,
                getCurrentUserData,
                currentSystemUserRoleActions: currentSystemUserRoleActions,
            }}
        >
                {/* Other contexts shouldn't load unless a user is logged in */}
                {!currentUserLoading && currentUser && (
                    <>
                        <LanguageLocaleProvider>
                            <EndInvestorProfileProvider>
                                <DocusignAuthProvider>
                                    <SubscriptionTemplatesProvider>
                                        <DocumentTemplateLibraryProvider>
                                            {children}
                                        </DocumentTemplateLibraryProvider>
                                    </SubscriptionTemplatesProvider>
                                </DocusignAuthProvider>
                            </EndInvestorProfileProvider>

                            <SessionTimeout />
                        </LanguageLocaleProvider>
                    </>
                )}

                {!currentUserLoading && !currentUser && <div>{children}</div>}
        </AuthContext.Provider>
    );
};
