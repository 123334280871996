import * as Yup from 'yup';
import { getFormattedCurrency } from '@helpers/isoCurrencies';

export const getValidation = (minimumInvestment: number) =>
    Yup.object().shape({
        field_products: Yup.string().required('Products is a required field'),
        field_product_share_classes: Yup.string().required(
            'Product share classes is a required field'
        ),
        amount: Yup.number()
            .required('Amount is a required field')
            .min(
                minimumInvestment,
                `Amount must be equal or more than ${getFormattedCurrency(
                    minimumInvestment,
                    'GBP'
                )}`
            ),
        upFrontFeePercentage: Yup.number().required(
            'An upfront fee percentage is required'
        ),
        agreeTermAndCondition: Yup.boolean().oneOf([true]),
    });

export const initialValues = {
    field_products: '',
    field_product_share_classes: '',
    amount: 0,
    upFrontFeePercentage: 0,
    relianceDocument: null,
    agreeTermAndCondition: false,
};

export interface FormData {
    field_products: string;
    field_product_share_classes: string;
    amount: number;
    upFrontFeePercentage: number;
    relianceDocument?: any;
    agreeTermAndCondition: boolean;
}
