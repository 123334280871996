import React, { useCallback, useEffect, useMemo, useState } from 'react';

import {
    LiquidityTypeEnum,
    ProductResponse,
    SigningDocumentTypeEnum,
    SubscriptionResponse,
    SubscriptionStatusEnum,
} from '@interfaces/Api';

import { Button, Icons } from '@components/Atoms';
import { AlertBox, AlertBoxType } from '@components/Molecules';

import dayjs from 'dayjs';
import { dateShortFormatter } from '@helpers/Date.helper';
import {
    canCancelSubscription,
    doesDocumentTypeExist,
    isDocumentSigned,
} from '@services/SubscriptionsHelper';
import { useAuthState } from '@contexts/AuthContext';
import {
    getUpcomingSignedSubscriptionDeadline,
    nextDateString,
} from '@helpers/Product.helper';

interface SubscriptionStatusInfoProps {
    subscription: SubscriptionResponse;
    product?: ProductResponse;
    onCancelSubscription?: () => void;
}

export const SubscriptionStatusInfo: React.FC<SubscriptionStatusInfoProps> = (
    props
) => {
    const { subscription, product, onCancelSubscription } = props;
    const { hasPermissions } = useAuthState();

    const subscriptionSubmittedChecklist = useMemo(() => {
        const items: {
            text: string;
            showItem: boolean;
            isSigned: boolean;
        }[] = [
            {
                text: 'Subscription Agreement has been signed',
                showItem: doesDocumentTypeExist(
                    subscription,
                    SigningDocumentTypeEnum.Subscription
                ),
                isSigned: isDocumentSigned(
                    subscription,
                    SigningDocumentTypeEnum.Subscription
                ),
            },
            {
                text: 'AML-KYC document has been signed',
                showItem: doesDocumentTypeExist(
                    subscription,
                    SigningDocumentTypeEnum.AmlKyc
                ),
                isSigned: isDocumentSigned(
                    subscription,
                    SigningDocumentTypeEnum.AmlKyc
                ),
            },
            {
                text: 'German Semi-professional questionnaire has been signed',
                showItem: doesDocumentTypeExist(
                    subscription,
                    SigningDocumentTypeEnum.GermanSemiProfessionalQuestionnaire
                ),
                isSigned: isDocumentSigned(
                    subscription,
                    SigningDocumentTypeEnum.GermanSemiProfessionalQuestionnaire
                ),
            },
            {
                text: 'The Nominee Indemnity Subscription Agreement Document has been signed',
                showItem: doesDocumentTypeExist(
                    subscription,
                    SigningDocumentTypeEnum.NomineeIndemnitySubscriptionAgreement
                ),
                isSigned: isDocumentSigned(
                    subscription,
                    SigningDocumentTypeEnum.NomineeIndemnitySubscriptionAgreement
                ),
            },
            {
                text: 'The Subscription Additional Shares Request Form has been signed',
                showItem: doesDocumentTypeExist(
                    subscription,
                    SigningDocumentTypeEnum.SubscriptionAdditionalSharesRequest
                ),
                isSigned: isDocumentSigned(
                    subscription,
                    SigningDocumentTypeEnum.SubscriptionAdditionalSharesRequest
                ),
            },
            {
                text: "The Underlying Client's Subscription Additional Shares Request Form has been signed",
                showItem: doesDocumentTypeExist(
                    subscription,
                    SigningDocumentTypeEnum.NomineeIndemnitySubscriptionAdditionalSharesRequest
                ),
                isSigned: isDocumentSigned(
                    subscription,
                    SigningDocumentTypeEnum.NomineeIndemnitySubscriptionAdditionalSharesRequest
                ),
            },
            {
                text: 'Fund Admin has reviewed the subscription',
                showItem: true,
                isSigned: [
                    SubscriptionStatusEnum.rejected,
                    SubscriptionStatusEnum.approved,
                ].includes(subscription.status),
            },
        ];

        return items
            .filter((a) => a.showItem)
            .map((item, i) => {
                return (
                    <li key={i} className="mb-2 flex items-center">
                        <p className="mr-4">{item.text}</p>
                        <Icons
                            name={item.isSigned ? 'CheckIcon' : 'ClockIcon'}
                            size="small"
                        />
                    </li>
                );
            });
    }, [subscription]);

    const statusMessages: {
        title: string;
        alertType: AlertBoxType;
        message: () => JSX.Element | null;
    }[] = useMemo(() => {
        let messages = [
            {
                title: 'Subscription Cancelled',
                alertType: 'error' as AlertBoxType,
                message: () => {
                    if (
                        subscription.status === SubscriptionStatusEnum.cancelled
                    ) {
                        return <p>This subscription has been cancelled.</p>;
                    }
                    return null;
                },
            },
            {
                title: 'Subscription Information',
                alertType: 'info' as AlertBoxType,
                message: () => {
                    if (
                        [
                            SubscriptionStatusEnum.inProgress,
                            SubscriptionStatusEnum.readyForReview,
                        ].includes(subscription.status)
                    ) {
                        if (
                            product?.liquidityType ===
                                LiquidityTypeEnum.closedEnded &&
                            product.closedEndedInformation?.keyDates
                        ) {
                            const keyDate =
                                getUpcomingSignedSubscriptionDeadline(
                                    product.closedEndedInformation.keyDates
                                );

                            if (keyDate?.signedSubscriptionDeadline) {
                                const signedSubscriptionDeadline =
                                    dateShortFormatter(
                                        keyDate.signedSubscriptionDeadline
                                    );

                                return (
                                    <p>
                                        Subscription Deadline Date:{' '}
                                        {signedSubscriptionDeadline}
                                    </p>
                                );
                            }
                        }
                        if (
                            product?.liquidityType ===
                            LiquidityTypeEnum.openEnded
                        ) {
                            const information = product?.openEndedInformation;
                            const readableNextSubscriptionDate =
                                information?.subscriptionFrequency &&
                                information.subscriptionFrequencyDeadline &&
                                nextDateString(
                                    information.subscriptionFrequency,
                                    information.subscriptionFrequencyDeadline,
                                    information.subscriptionNoticeDays || 0
                                );
                            if (readableNextSubscriptionDate) {
                                return (
                                    <p>
                                        Next Subscription Date:{' '}
                                        {readableNextSubscriptionDate}
                                    </p>
                                );
                            }
                        }
                    }
                    return null;
                },
            },
            {
                title: 'Subscription Submitted',
                alertType: 'info' as AlertBoxType,
                message: () => {
                    if (
                        [
                            SubscriptionStatusEnum.readyForReview,
                            SubscriptionStatusEnum.approved,
                            SubscriptionStatusEnum.rejected,
                        ].includes(subscription.status)
                    ) {
                        return (
                            <>
                                <ul className="mt-4">
                                    {subscriptionSubmittedChecklist}
                                </ul>

                                {/* TODO: Below needs to be refactored further */}
                                {product &&
                                    canCancelSubscription(
                                        subscription,
                                        product,
                                        hasPermissions
                                    ) && (
                                        <div className="flex justify-end items-center">
                                            {/* if subscription is approved, then show in cooling off period message */}
                                            {subscription.status ===
                                                SubscriptionStatusEnum.approved &&
                                                product.liquidityType ==
                                                    LiquidityTypeEnum.closedEnded && (
                                                    <p className="pr-3">
                                                        Cooling off period ends
                                                        on{' '}
                                                        {dateShortFormatter(
                                                            dayjs(
                                                                subscription
                                                                    .statusLogs[
                                                                    subscription
                                                                        .statusLogs
                                                                        .length -
                                                                        1
                                                                ].createdAt
                                                            )
                                                                .add(
                                                                    product
                                                                        .closedEndedInformation
                                                                        ?.coolingOffDays ||
                                                                        0,
                                                                    'day'
                                                                )
                                                                .toDate()
                                                        )}
                                                    </p>
                                                )}

                                            <Button
                                                size="small"
                                                label="Cancel subscription"
                                                onClick={() => {
                                                    onCancelSubscription &&
                                                        onCancelSubscription();
                                                }}
                                            />
                                        </div>
                                    )}
                            </>
                        );
                    }
                    return null;
                },
            },
        ].filter((a) => a.message());

        // only show error messages if there are error messages
        if (messages.find((a) => a.alertType === 'error')) {
            messages = messages.filter((a) => a.alertType === 'error');
        }
        return messages;
    }, [
        hasPermissions,
        onCancelSubscription,
        product,
        subscription,
        subscriptionSubmittedChecklist,
    ]);

    return (
        <div>
            {statusMessages &&
                statusMessages.map((potentialMessage) => (
                    <AlertBox
                        key={potentialMessage.title}
                        alertType={potentialMessage.alertType as AlertBoxType}
                        title={potentialMessage.title}
                        message={potentialMessage.message()}
                    />
                ))}
        </div>
    );
};
