import React from 'react';
import { useTranslation } from 'react-i18next';
import { ChartData } from 'chart.js';

import { SubscriptionPerformancePoint } from '@interfaces/Api';

import { WidgetLineChart } from '@components/Charts';

import { getStyling } from '@helpers/getStyling';

export interface ReportsPerformanceProps {
    title?: string;
    chartData: Array<SubscriptionPerformancePoint>;
}

const SubscriptionInvestmentReportsPerformance: React.FC<
    ReportsPerformanceProps
> = (props: ReportsPerformanceProps) => {
    const { t } = useTranslation();
    const performanceOptions = {
        responsive: true,
        scales: {
            y: {
                ticks: {
                    format: {
                        style: 'percent',
                        maximumFractionDigits: 2,
                    },
                },
            },
        },
    };

    const { product_a, product_b, product_c } = getStyling();

    const performanceChartData: ChartData<'line', any, any> = {
        labels: props.chartData.map(
            (val: SubscriptionPerformancePoint) => val.effectiveDate
        ),
        datasets: [
            {
                label: 'DPI',
                data: props.chartData.map(
                    (val: SubscriptionPerformancePoint) => val.dpi
                ),
                borderColor: product_a,
                backgroundColor: product_a,
            },
            {
                label: 'RVPI',
                data: props.chartData.map(
                    (val: SubscriptionPerformancePoint) => val.rvpi
                ),
                borderColor: product_b,
                backgroundColor: product_b,
            },
            {
                label: 'TVPI',
                data: props.chartData.map(
                    (val: SubscriptionPerformancePoint) => val.tvpi
                ),
                borderColor: product_c,
                backgroundColor: product_c,
            },
        ],
    };

    return (
        <div className="mb-8">
            <div className="py-4 border-b border-gray-200">
                <h2 className="text-xl">{props.title}</h2>
            </div>
            <div className="flex justify-center my-4">
                <div className="w-11/12">
                    <WidgetLineChart
                        title="Performance"
                        data={performanceChartData}
                        options={performanceOptions}
                    />
                    <h6 className="text-xs text-logo-blue hover:text-indigo-500">
                        <span className="mr-4 md:mx-4">
                            <strong>RVPI:</strong> {t('performance.rvpi.formula')}
                            capital{' '}
                        </span>
                        |
                        <span className="mx-4">
                            {' '}
                            <strong>DPI:</strong> {t('performance.dpi.formula')}
                        </span>
                        |
                        <span className="mx-4">
                            <strong>TVPI:</strong> {t('performance.tvpi.formula')}
                            value)/paid in capital
                        </span>
                    </h6>
                </div>
            </div>
        </div>
    );
};

export default SubscriptionInvestmentReportsPerformance;
