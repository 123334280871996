import { BaseUrl } from '@helpers/Environment';
import { Http } from '../libs/http';

const API_SUITABILITYQUESTIONNAIRE_URL = `${BaseUrl}/suitability-questionnaire`;

export enum MifidProFlagEnum {
    red = 'red',
    amber = 'amber',
    green = 'green',
}

export interface SuitabilityQuestionnaireResponseBody {
    prospectiveUserId: string;
    savedQuestionnaireWithAnswers?: {
        question: string;
        selectedAnswers: {
            text: string;
            customText?: string;
            points?: number;
            mifidProFlag?: MifidProFlagEnum;
        }[];
    }[];
    totalPoints: number;
    mifidProStatus: 'approved' | 'manual' | 'rejected';
    status: 'approved' | 'manual' | 'rejected';
    statusLogs: { createDate: string; createdBy: string }[];
    createdDate: Date;
    createdBy: string;
}

interface SuitabilityQuestionnaireRequestBody {
    [questionId: string]: { answerId: string; extraText?: string }[];
}

export const SuitabilityQuestionnairePost = async (
    body: SuitabilityQuestionnaireRequestBody
) => {
    return Http.post<any>(API_SUITABILITYQUESTIONNAIRE_URL, body);
};

export const SuitabilityQuestionnaireGet = async () => {
    return Http.get<SuitabilityQuestionnaireResponseBody[]>(
        API_SUITABILITYQUESTIONNAIRE_URL
    );
};

export const SuitabilityQuestionnaireGetByProspectiveInvestorId = async (
    prospectiveInvestorId: string
) => {
    return Http.get<SuitabilityQuestionnaireResponseBody[]>(
        `${API_SUITABILITYQUESTIONNAIRE_URL}`,
        null,
        { prospectiveInvestorId }
    );
};
