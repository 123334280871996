export const DesktopTableHeaders = ({
    columnNames,
}: {
    columnNames: string[];
}) => (
    <thead>
        <tr>
            <th></th>
            {columnNames.map((columnName: string) => (
                <th
                    className="text-left p-2 text-sm md:text-xs xl:text-base"
                    key={columnName}
                >
                    {columnName}
                </th>
            ))}
        </tr>
    </thead>
);
