import { useTranslation } from 'react-i18next';
import { useRegisterInvestorState } from '../RegisterInvestor.context';

import { useSystemUsers } from '@stores/SystemUsers/useSystemUsers';

export const LoginAccountSummary = () => {
    const { t } = useTranslation();
    const { data: currentState } = useRegisterInvestorState();
    const { systemUsers } = useSystemUsers();
    const selectedSystemUser = currentState?.systemUsers?.[0];
    return (
        <p>
            <span className="font-bold mr-2">{t('client.registration.summary.login_account')}:</span>
            {selectedSystemUser?.email ??
                systemUsers?.find(
                    (user) => user._id === selectedSystemUser?._id
                )?.email}
        </p>
    );
};
