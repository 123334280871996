import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { PortfolioGet } from '@api/Dashboard';
import { AuthRoleEnum, EndInvestorPortfolioItem } from '@interfaces/Api';
import { useEndInvestorProfile } from '@hooks/useEndInvestorProfile';
import { DashboardType } from '@components/Organisms/Dashboard/Dashboard.helper';
import { Dashboard } from '@components/Organisms';
import { Icons, Loading } from '@components/Atoms';
import { AlertBox } from '@components/Molecules';

interface DashboardPageProps {
    currentUserRole: AuthRoleEnum;
    portfolioData: EndInvestorPortfolioItem[];
    portfolioDataLoading: boolean | undefined;
}

const DashboardPage: React.FC<DashboardPageProps> = ({ currentUserRole, portfolioData, portfolioDataLoading }) => {
    const { t } = useTranslation();
    const [dashboardType, setDashboardType] =
        useState<DashboardType>('endInvestor');

    const { isEndInvestorProfileLoading, endInvestorProfile } =
        useEndInvestorProfile();

    return (
        <>
            {portfolioDataLoading ? (
                <div className="p-10 ml-2">
                    <Loading size="large"></Loading>
                </div>
            ) : (
                <div className="h-full pb-9 xs:container xs:mx-auto max-w-full">
                    {isEndInvestorProfileLoading ? (
                        <div className="ml-2">
                            <Icons name="Loading" size="xxl" />
                        </div>
                    ) : (
                        portfolioData.length < 1 ?
                        (
                            <AlertBox
                                alertType="info"
                                message={t('client.portfolio.no_portfolio_data')}
                            />
                        )
                        :
                        (
                            <Dashboard
                                dashboardType={dashboardType}
                                items={portfolioData}
                            />
                        )
                    )}
                </div>
            )}
        </>
    );
};

export default DashboardPage;
