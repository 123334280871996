import { useEffect, useMemo, useState } from 'react';
import { AuthRoleEnum, SystemUserResponse } from '@interfaces/Api';
import { EntityRolesEnum } from '@interfaces/EntityRoles';
import { getAllEntityRoles } from '@services/EntityRoles';
import { useConfigurationState } from '@contexts/ConfigurationContext';
import { useAuthState } from '@contexts/AuthContext';
import { useParams } from 'react-router-dom';

export enum EntityRolesLabelEnum {
    AppSupport = 'App Support',
    Distributor = 'Distributor',
    AssetManager = 'Asset Manager',
    FundServices = 'Fund Services',
    Profiles = 'Profiles',
    SubDistributor = 'Sub Distributor',
    Investor = 'Investor',
}

export const useEntityRoles = ({ user }: { user?: SystemUserResponse }) => {
    const { userId } = useParams();
    const isEdit = !!userId;
    const { currentSystemUserRoleActions } = useAuthState();
    const { preferences } = useConfigurationState();
    const [entityRoles, setEntityRoles] = useState<
        {
            name: EntityRolesEnum;
            roles: AuthRoleEnum[];
        }[]
    >([]);

    useEffect(() => {
        const getData = async () =>
            await getAllEntityRoles().then((data) => {
                const filteredData = data.filter(
                    (entity: {
                        name: EntityRolesEnum;
                        roles: AuthRoleEnum[];
                    }) => {
                        if (
                            preferences?.feature?.roles
                                ?.enableExternalWealthManager
                        ) {
                            return true;
                        }
                        return (
                            entity.roles.filter(
                                (role) =>
                                    role ===
                                    AuthRoleEnum.externalRelationshipManager
                            ).length === 0
                        );
                    }
                );

                setEntityRoles(filteredData);
            });

        getData();
    }, []);

    const profileRoles =
        (entityRoles.find((entity) => entity.name === EntityRolesEnum.Profiles)
            ?.roles as AuthRoleEnum[]) || [];

    const distributorRoles =
        (entityRoles.find(
            (entity) => entity.name === EntityRolesEnum.Distributor
        )?.roles as AuthRoleEnum[]) || [];

    const entityRoleCombinations: {
        roles: AuthRoleEnum[];
        possibleCombinations?: AuthRoleEnum[];
    }[] = [
        {
            roles: [AuthRoleEnum.superUser],
        },
        {
            roles: [AuthRoleEnum.endInvestor],
            possibleCombinations: [AuthRoleEnum.productViewer],
        },
        {
            roles: [AuthRoleEnum.externalRelationshipManager],
            possibleCombinations: profileRoles,
        },
        {
            roles: [AuthRoleEnum.assetManagerAdministrator],
            possibleCombinations: [...profileRoles, ...distributorRoles],
        },
        {
            roles: [AuthRoleEnum.productViewer],
            possibleCombinations: [AuthRoleEnum.endInvestor],
        },
        {
            roles: profileRoles,
            possibleCombinations: [
                ...distributorRoles,
                AuthRoleEnum.externalRelationshipManager,
                AuthRoleEnum.assetManagerAdministrator,
            ],
        },

        {
            roles: distributorRoles,
            possibleCombinations: [
                ...distributorRoles.filter((role) => role !== user?.role),
                ...profileRoles,
                AuthRoleEnum.assetManagerAdministrator,
            ],
        },
    ];

    const userActions = isEdit
        ? currentSystemUserRoleActions?.update
        : currentSystemUserRoleActions?.create;

    const filteredEntityRoles = useMemo(() => {
        return entityRoles.filter(
            (entity) =>
                entity.roles.filter((role) => userActions?.includes(role))
                    .length > 0
        );
    }, [entityRoles, userActions]);

    return {
        entityRoles: filteredEntityRoles,
        entityRoleCombinations,
    };
};
