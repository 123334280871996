import { updateEndInvestorProfile } from '@api/EndInvestorProfiles';
import { Button } from '@components/Atoms';
import { Modal, FormSelect } from '@components/Molecules';
import { useEndInvestorProfileState } from '@contexts/EndInvestorProfileContext';
import { notifyError } from '@helpers/toastrHelper';
import { EndInvestorProfileResponse } from '@interfaces/Api';

import { Form, Formik } from 'formik';
import { useEffect, useState } from 'react';

interface ILinkedProfileModal {
    linkedProfile: boolean;
    endInvestorProfile: EndInvestorProfileResponse;
    profileOptions: { label: string; value: string }[];
    toggleLinkedProfile: (val: boolean) => void;
}

export const LinkedProfileModal = ({
    linkedProfile,
    profileOptions,
    toggleLinkedProfile,
    endInvestorProfile,
}: ILinkedProfileModal) => {
    const { getEndInvestorProfile } = useEndInvestorProfileState();
    const [defaultProfileId, setDefaultProfileId] = useState(
        endInvestorProfile._id
    );
    useEffect(() => {
        const loadData = async () => {
            const data = await getEndInvestorProfile(endInvestorProfile._id);
            setDefaultProfileId(data?.profileId);
        };

        loadData();

        return () => setDefaultProfileId(defaultProfileId);
    }, []);

    const handleProfileSelection = async (body: { profileId: string }) => {
        try {
            await updateEndInvestorProfile({
                endInvestorProfileId: endInvestorProfile._id,
                body,
            });
        } catch (error) {
            notifyError(`${error}`);
        } finally {
            toggleLinkedProfile(false);
        }
    };
    return (
        <Modal width="w-1/3" show={linkedProfile}>
            <Modal.Header>
                <h3>Linked profile</h3>
            </Modal.Header>
            <Modal.Body>
                <Formik
                    onSubmit={handleProfileSelection}
                    enableReinitialize
                    initialValues={{
                        profileId: defaultProfileId,
                    }}
                >
                    <Form>
                        <FormSelect
                            name="profileId"
                            label="Profile selection"
                            optionsData={profileOptions}
                        />

                        <div className="flex justify-between items-center">
                            <Button
                                onClick={() => toggleLinkedProfile(false)}
                                label="Cancel"
                            />
                            <Button type="submit" label="Submit" />
                        </div>
                    </Form>
                </Formik>
            </Modal.Body>
        </Modal>
    );
};
