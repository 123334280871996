import { useTranslation } from 'react-i18next';
import { ReviewClientSummaryDetailsProps } from '@interfaces/ReviewClientSummaryDetailsProps';
import { dateShortFormatter } from '@helpers/Date.helper';

import { Icons } from '@components/Atoms';
import { isoCountries } from '@helpers/isoCountries';
import { endInvestorTypeLabelMap } from '@interfaces/Api/EndInvestorProfileResponse';
import { ReviewItem } from './ReviewItem';

const ReviewClientPersonalDetails = (
    props: ReviewClientSummaryDetailsProps
) => {
    const { t } = useTranslation();
    const getNationalityNames = () => {
        // nationalityCountryCode needs to be deprecated, in the meantime default to it else use nationalities
        if (props.data?.individualDetails?.nationalityCountryCode) {
            return isoCountries[
                props.data?.individualDetails?.nationalityCountryCode || ''
            ];
        }

        return props.data?.individualDetails?.nationalities
            ?.map((a) => isoCountries[a.countryCode])
            .join(' - ');
    };

    const personalDetails = [
        {
            label: 'Investor type:',
            value: endInvestorTypeLabelMap[
                props.data
                    ?.endInvestorType as keyof typeof endInvestorTypeLabelMap
            ],
        },
        {
            label: 'First Name:',
            value: props.data?.individualDetails?.firstName,
        },
        {
            label: 'Last Name:',
            value: props.data?.individualDetails?.lastName,
        },
        {
            label: 'Date of Birth:',
            value: props.data?.individualDetails?.dateOfBirth
                ? dateShortFormatter(props.data?.individualDetails?.dateOfBirth)
                : '',
        },
        {
            label: 'Town of birth:',
            value: props.data?.individualDetails?.townOfBirth,
        },
        {
            label: 'Country of birth:',
            value: isoCountries[
                props.data?.individualDetails?.countryCodeOfBirth || ''
            ],
        },
        {
            label: 'Nationalities:',
            value: getNationalityNames(),
        },
    ];

    return (
        <div className="flex flex-col md:flex-row w-full leading-5 mb-4">
            <div className="w-full lg:w-2/5 md:mr-4">
                <div className="w-full h-full bg-white rounded-lg p-5 flex flex-col justify-center items-center">
                    <div className="mb-4">
                        <div className="flex flex-col justify-center items-center p-4 shadow-sm bg-gray-500 w-36 h-36 rounded-full text-white">
                            <Icons name="UserIcon" size="xxl" />
                        </div>
                    </div>

                    <p className="text-xl text-gray-700 font-bold mb-2 text-center">{`${props.data?.individualDetails?.firstName} ${props.data?.individualDetails?.lastName}`}</p>
                </div>
            </div>

            <div className="w-full lg:w-4/5 flex flex-col justify-start p-5 md:py-10 text-xs">
                <h2 className="text-xl py-2 text-logo-blue">
                    {props.data?.businessEntityDetails?.companyName !== ''
                        ? ''
                        : t('end_investor_type.individual')}
                </h2>

                {personalDetails.map((item, index) =>
                    item.value ? (
                        <ReviewItem
                            key={index}
                            label={item.label}
                            value={item.value}
                        />
                    ) : null
                )}
            </div>
        </div>
    );
};

export default ReviewClientPersonalDetails;
