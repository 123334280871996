import { useEffect, useRef, useState } from 'react';

import { KebabMenuWrapper } from './KebabMenuWrapper';

interface KebabMenuProps {
    menuItems: { title: string; id: string | number }[];
    onMenuItemClick: (id: string | number) => void;
    dropdownSide?: 'left' | 'right';
}

const KebabMenu: React.FC<KebabMenuProps> = (props) => {
    const { menuItems, onMenuItemClick, dropdownSide } = props;
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const dropRef = useRef<HTMLDivElement>(null);

    const handleDropDown = (e: Event): void => {
        const target = e.target as HTMLButtonElement;
        if (!target.closest(`.${dropRef?.current?.className}`) && isMenuOpen) {
            setIsMenuOpen(false);
        }
    };

    useEffect(() => {
        document.addEventListener('click', handleDropDown);
        return () => {
            document.removeEventListener('click', handleDropDown);
        };
    });

    return (
        <KebabMenuWrapper dropdownSide={dropdownSide}>
            <div role="none">
                {menuItems.map((menuItem, i) => (
                    <a
                        key={`${menuItem.id}_${i}`}
                        onClick={() => {
                            onMenuItemClick(menuItem.id);
                        }}
                        className="text-gray-700 block px-4 py-2 text-sm cursor-pointer"
                        role="menuitem"
                        tabIndex={-1}
                        id={`menu-item-${i}`}
                    >
                        {menuItem.title}
                    </a>
                ))}
            </div>
        </KebabMenuWrapper>
    );
};

KebabMenu.defaultProps = {
    dropdownSide: 'right',
};

export default KebabMenu;
