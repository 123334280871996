import { ErrorMessage } from 'formik';
import { ReactNode } from 'react';

interface FormFieldWrapperProps {
    label?: string;
    rightIcon?: ReactNode;
    leftIcon?: ReactNode;
    name?: string;
    children: any;
    isInputHidden?: boolean;
}

export const FormFieldWrapper = (props: FormFieldWrapperProps) => {
    const { isInputHidden = false } = props;
    return (
        <>
            {props.label && (
                <label
                    htmlFor={props.name}
                    className="absolute top-2 left-1 text-xs flex items-center px-2 pointer-events-none h-auto"
                >
                    {props.label}
                </label>
            )}

            {props.children}

            {props.leftIcon && (
                <div className="absolute top-7 left-3 p-1 pl-0">
                    {props.leftIcon}
                </div>
            )}

            {props.rightIcon && (
                <div className="absolute top-6 flex items-center right-4 z-10">
                    {props.rightIcon}
                </div>
            )}

            {props.name && !isInputHidden && (
                <div className="flex content-start text-red-500 text-sm">
                    <ErrorMessage
                        name={props.name}
                        render={(msg) => <div>{msg}</div>}
                    />
                </div>
            )}
        </>
    );
};
