import React, { useState, useEffect } from 'react';

import { SubscriptionResponse, SubscriptionStatusEnum } from '@interfaces/Api';
import { EndInvestorProfilePortfolio } from '@interfaces/EndInvestorProfilePortfolio';

import { useEndInvestorProfileState } from '@contexts/EndInvestorProfileContext';

import { useProducts } from '@stores/Products/useProducts';

import { Accordion } from '@components/Molecules';

import SubscriptionInvestmentReportsPage from '@containers/Subscriptions/InvestmentReports/SubscriptionInvestmentReportsPage';
import { useConfigurationState } from '@contexts/ConfigurationContext';
import { EnvironmentEnum } from '@interfaces/Api/ConfigurationResponse';

interface ClientInvestmentReportsProps {
    endInvestorProfileId?: string;
}

const ClientInvestmentReports: React.FC<ClientInvestmentReportsProps> = (
    props
) => {
    const { getEndInvestorProfilePortfolio } = useEndInvestorProfileState();
    const { products } = useProducts();
    const { isEnvironment } = useConfigurationState();

    const [endInvestorProfilePortfolio, setEndInvestorProfilePortfolio] =
        useState<EndInvestorProfilePortfolio>();
    const getProductName = (subscription: SubscriptionResponse) =>
        [products.find((e) => e._id === subscription.productId)?.name]
            .filter((a) => a)
            .join(' ');

    useEffect(() => {
        async function RunAsync() {
            if (!props.endInvestorProfileId) return;
            const endInvestorProfilePortfolio =
                await getEndInvestorProfilePortfolio(
                    props.endInvestorProfileId
                );
            if (!endInvestorProfilePortfolio) return;
            setEndInvestorProfilePortfolio(endInvestorProfilePortfolio);
        }

        RunAsync();
    }, [props.endInvestorProfileId, getEndInvestorProfilePortfolio]);

    return (
        <div className="my-8">
            {endInvestorProfilePortfolio?.investmentReports
                .filter(
                    (a) =>
                        a.subscription.status == SubscriptionStatusEnum.approved
                )
                .map((val, index) => (
                    <div key={index.toString()}>
                        <Accordion
                            title={getProductName(val.subscription)}
                            badge={val.subscription.shareClass?.name}
                        >
                            <SubscriptionInvestmentReportsPage
                                title={getProductName(val.subscription)}
                                performanceSummary={val.performanceSummary}
                                chartDataPerformance={
                                    val.subscriptionPerformancePoints
                                }
                                productData={val.product}
                                subscription={val.subscription}
                                fundTransactions={val.fundTransactions}
                            />
                        </Accordion>
                    </div>
                ))}

            {(isEnvironment(EnvironmentEnum.DEMO) ||
                isEnvironment(EnvironmentEnum.DEV) ||
                isEnvironment(EnvironmentEnum.LOCAL)) &&
                props.endInvestorProfileId === '62cc5ce5ab3eb6d7be5a7c37' && (
                    <>
                        {endInvestorProfilePortfolio?.investmentReports
                            .filter(
                                (a) =>
                                    a.subscription.status ==
                                    SubscriptionStatusEnum.approved
                            )
                            .slice(0, 1)
                            .map((val, index) => (
                                <div key={index.toString()}>
                                    <Accordion
                                        title={
                                            'Global Mid-Market Access Feeder'
                                        }
                                        badge="1U"
                                    >
                                        <SubscriptionInvestmentReportsPage
                                            title={`Global Mid-Market Access Feeder`}
                                            performanceSummary={
                                                val.performanceSummary
                                            }
                                            chartDataPerformance={
                                                val.subscriptionPerformancePoints
                                            }
                                            productData={val.product}
                                            subscription={val.subscription}
                                            fundTransactions={
                                                val.fundTransactions
                                            }
                                        />
                                    </Accordion>
                                </div>
                            ))}

                        {endInvestorProfilePortfolio?.investmentReports
                            .filter(
                                (a) =>
                                    a.subscription.status ==
                                    SubscriptionStatusEnum.approved
                            )
                            .slice(0, 1)
                            .map((val, index) => (
                                <div key={index.toString()}>
                                    <Accordion
                                        title={
                                            'Blackstone European Opportunities'
                                        }
                                        badge="0U"
                                    >
                                        <SubscriptionInvestmentReportsPage
                                            title={`Blackstone European Opportunities`}
                                            performanceSummary={
                                                val.performanceSummary
                                            }
                                            chartDataPerformance={
                                                val.subscriptionPerformancePoints
                                            }
                                            productData={val.product}
                                            subscription={val.subscription}
                                            fundTransactions={
                                                val.fundTransactions
                                            }
                                        />
                                    </Accordion>
                                </div>
                            ))}
                    </>
                )}
        </div>
    );
};

export default ClientInvestmentReports;
