import { create } from 'zustand';
import { devtools } from 'zustand/middleware';
import { ProductPriorities, ProductResponse } from '@interfaces/Api';
import {
    handleFetchProducts,
    handlePrioritiseProducts,
} from './Products.services';

interface ProductStore {
    products: ProductResponse[];
    product: ProductResponse | undefined;
    isLoading: boolean;
    actions: {
        fetchProducts: (endInvestorProfileIds?: string[]) => void;
        prioritiseProducts: (productPriorities: ProductPriorities[]) => void;
        fetchProduct: (productId: string) => void;
    };
}

export const useProductsStore = create<ProductStore>()(
    devtools(
        (set): ProductStore => ({
            products: [],
            product: undefined,
            isLoading: false,
            actions: {
                fetchProducts: async (endInvestorProfileIds?: string[]) =>
                    handleFetchProducts(set, endInvestorProfileIds),
                fetchProduct: async (productId: string) => {
                    // const product = state.products.find((product) => product._id === productId);
                    // set({ product });
                },
                prioritiseProducts: async (
                    productPriorities: ProductPriorities[]
                ) => handlePrioritiseProducts(set, productPriorities),
            },
        })
    )
);
