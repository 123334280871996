import { Icons, SelectBox } from '@components/Atoms';
import { FormFieldWrapper } from '@components/Molecules';
import { EndInvestorProfileResponse } from '@interfaces/Api';
import { SelectOption } from '@interfaces/InterfaceFormsProps';
import { getEndInvestorName } from '@helpers/EndInvestorHelper';
import { useEffect, useState } from 'react';

interface SelectEndInvestorProps {
    endInvestorProfiles: EndInvestorProfileResponse[];
    onChange: (selectedEndInvestor?: EndInvestorProfileResponse) => void;
}

const SelectEndInvestor: React.FC<SelectEndInvestorProps> = ({
    endInvestorProfiles,
    onChange,
}) => {
    const [options, setOptions] = useState<SelectOption<string>[]>([]);

    const handleOnChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
        const selectedEndInvestorProfile = endInvestorProfiles.find(
            (endInvestor) => endInvestor._id === e.target.value
        );

        onChange(selectedEndInvestorProfile);
    };

    useEffect(() => {
        const options: SelectOption<string>[] = endInvestorProfiles.map(
            (endInvestor) => ({
                value: endInvestor._id,
                label: getEndInvestorName(endInvestor),
            })
        );
        setOptions(options);
    }, [endInvestorProfiles]);

    return (
        <div className="relative w-full md:w-1/2 mb-4 cursor-default">
            <FormFieldWrapper
                rightIcon={<Icons name="ChevronDown" size="small" />}
                label="Client"
            >
                <SelectBox
                    name="endInvestorName"
                    optionsData={options}
                    onChange={handleOnChange}
                />
            </FormFieldWrapper>
        </div>
    );
};

export default SelectEndInvestor;
