import { BaseUrl } from '@helpers/Environment';
import { DocusignInfo, DocusignConsentRequestBody } from '@interfaces/Api';
import { getLocalStorageAuthTokens } from '@helpers/auth.helper';
import { Http } from '../libs/http';

const API_DOCUSIGN_URL = `${BaseUrl}/docusign`;

export const docusignInformationGet = async (): Promise<DocusignInfo> =>
    Http.get(`${API_DOCUSIGN_URL}/information`);

export const docusignConsentPost = async (
    body: DocusignConsentRequestBody
): Promise<DocusignInfo> => Http.post(`${API_DOCUSIGN_URL}/consent`, body);

export const docusignEnvelopeGet = async (envelopeId: string) => {
    const bearerToken = getLocalStorageAuthTokens()?.access?.token;

    const response = await fetch(
        `${API_DOCUSIGN_URL}/envelope?envelopeId=${envelopeId}`,
        {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json',
                Authorization: `Bearer ${bearerToken}`,
                'Access-Control-Expose-Headers': 'Content-Disposition',
            },
        }
    );

    return response;
};
