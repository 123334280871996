import { useTranslation } from 'react-i18next';
import { useSubscriptionTemplateState } from '@contexts/SubscriptionTemplatesContext';
import {
    EndInvestorProfileResponse,
    SubscriptionTemplateResponse,
} from '@interfaces/Api';
import { getFirstStepSubscriptionTemplate } from '@services/SubscriptionsHelper';
import { useNavigate } from 'react-router-dom';

export const useCreateTemplate = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { createSubscriptionTemplate } = useSubscriptionTemplateState();

    const create = async (endInvestorProfile: EndInvestorProfileResponse) => {
        const bodyObj: SubscriptionTemplateResponse = {
            endInvestorProfileId: endInvestorProfile._id,
        };
        const newSubscriptionTemplate = await createSubscriptionTemplate(
            bodyObj
        );
        if (newSubscriptionTemplate) {
            navigate(
                `/subscription-templates/${
                    newSubscriptionTemplate._id
                }/${getFirstStepSubscriptionTemplate(t, endInvestorProfile)}`
            );
        } else {
            console.error(
                'Clients.tsx, new Subscription Templated could not be created'
            );
        }
    };

    return {
        create,
    };
};
