import { PoliticalPersonDetail } from './PoliticalPersonDetail';

enum PoliticalExposureTypeEnum {
    aPersonHoldingALegislativeAdministrativeOrJudicialOffice = 'aPersonHoldingALegislativeAdministrativeOrJudicialOffice',
    aPersonExercisingAPublicFunctionIncludingForAPublicAgencyOrPublicEnterprise = 'aPersonExercisingAPublicFunctionIncludingForAPublicAgencyOrPublicEnterprise',
    anOfficialOrAgentOfAPublicInternationalOrganisation = 'anOfficialOrAgentOfAPublicInternationalOrganisation',
    aPersonOrCompanyManifestlyCloseToOrConnectedWithTheAbovePersons = 'aPersonOrCompanyManifestlyCloseToOrConnectedWithTheAbovePersons',
}

// the below probably need some sort of translations work
export const PoliticalExposureTypeEnumLabels: {
    [key in PoliticalExposureTypeEnum]: string;
} = {
    aPersonHoldingALegislativeAdministrativeOrJudicialOffice:
        'a person holding a legislative, administrative or judicial office, whether appointed or elected',
    aPersonExercisingAPublicFunctionIncludingForAPublicAgencyOrPublicEnterprise:
        'a person exercising a public function (including for a public agency or public enterprise)',
    anOfficialOrAgentOfAPublicInternationalOrganisation:
        'an official or agent of a public international organisation',
    aPersonOrCompanyManifestlyCloseToOrConnectedWithTheAbovePersons:
        'a person or company manifestly close to or connected with the above persons',
};
export const politicalExposureTypeEnumOptions = Object.keys(
    PoliticalExposureTypeEnumLabels
).map((key) => ({
    label: PoliticalExposureTypeEnumLabels[key],
    value: key,
}));

export interface PoliticalExposure {
    isPoliticallyExposedPerson: boolean;
    politicalExposureType?: PoliticalExposureTypeEnum;
    politicalExposureDetails?: string;
    politicalPersons: PoliticalPersonDetail[];
}
