import { Button } from '@components/Atoms';
import { EventTypeLabelEnum } from '@interfaces/eventType.enum';
import { Link } from 'react-router-dom';

export const EventSimulator = () => {
    //TODO: Generate event type list from Enums
    const eventsTypesList = [
        {
            title: 'Capital Call Notice',
            description:
                'Simulate a Capital Call Notice Event for an End Investor',
            eventType: EventTypeLabelEnum.CAPITAL_CALL_NOTICE_EVENT,
        },
        {
            title: 'Capital Call Paid',
            description:
                'Simulate a Capital Call Paid Event for an End Investor',
            eventType: EventTypeLabelEnum.CAPITAL_CALL_PAID_EVENT,
        },
    ];

    return (
        <div className="mb-5">
            <h1 className="text-3xl text-logo-blue font-bold">
                Event Simulator
            </h1>

            {eventsTypesList.map((event) => (
                <div key={event.eventType} className="my-4">
                    <div className="flex justify-between align-middle bg-white border-b my-2 md:my-0 py-2">
                        <div>
                            <h3 className="text-lg">
                                <strong>{event.title}</strong>
                            </h3>
                            <p>{event.description}</p>
                        </div>
                        <Link
                            className="self-end"
                            to={`${event.eventType}`}
                            state={event}
                        >
                            <Button label="Send Event" />
                        </Link>
                    </div>
                </div>
            ))}
        </div>
    );
};
