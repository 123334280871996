import { useHandleBrowserBackButton } from '@hooks/useHandleBrowserBackButton';
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { BackButtonBox } from './BackButtonBox';

interface AlertBackButtonBoxProps {
    title: string;
    navigateBackURL: string;
    message: string;
    icon?: React.ReactNode;
    currentPathName: string;
}

const AlertBackButtonBox: React.FC<AlertBackButtonBoxProps> = (
    props: AlertBackButtonBoxProps
) => {
    const [showModal, setShowModal] = useState<boolean>(false);
    const isModalActive: boolean = useHandleBrowserBackButton();
    const navigate = useNavigate();

    const onNavigationLeavePge = () => {
        setShowModal(false);
        navigate(props.navigateBackURL);
    };

    useEffect(() => {
        const onPopstate = () => {
            setShowModal(!isModalActive);
        };
        window.history.pushState(null, '', window.location.pathname);

        window.addEventListener('beforeunload', (e) => {
            if (window.location.pathname === props.currentPathName) {
                e.preventDefault();
                e.returnValue = '';
                return null;
            } else {
                window.removeEventListener('beforeunload', onPopstate);
            }
        });

        window.addEventListener('popstate', onPopstate);
        return () => window.removeEventListener('popstate', onPopstate);
    }, [isModalActive, props.currentPathName]);

    return (
        <BackButtonBox
            showModal={showModal}
            title={props.title}
            message={props.message}
            onNavigationLeavePge={onNavigationLeavePge}
            setShowModal={setShowModal}
            icon={props.icon}
        />
    );
};

export default AlertBackButtonBox;
