import React, { useState, useEffect } from 'react';
import { Outlet, useParams, useNavigate, useLocation } from 'react-router-dom';

import { productsGetById } from '@api/Products';

import { useProducts } from '@stores/Products/useProducts';

import { ProductResponse } from '@interfaces/Api';

import { notifyError } from '@helpers/toastrHelper';

import { Button, Icons } from '@components/Atoms';
import { AlertBackButtonBox, ProductSteps } from '@components/Organisms';

const ProductAddEdit: React.FC = () => {
    useProducts();
    const { productId } = useParams();
    const navigate = useNavigate();
    const location = useLocation();

    const [matchedProduct, setMatchedProduct] = useState<ProductResponse>();
    const title = matchedProduct
        ? ` Manage Product - ${matchedProduct?.name}`
        : 'New Product';

    useEffect(() => {
        const pathname = location.pathname;
        window.localStorage.setItem('pageUrl', JSON.stringify(pathname));

        if (productId !== 'new') {
            productsGetById(productId as string)
                .then((response) => {
                    setMatchedProduct(response);
                })
                .catch((err) => {
                    notifyError(err.toString());
                });
        }
    }, [productId, location]);

    const isEdit = productId !== 'undefined';

    return (
        <>
            <div className="h-full pb-9">
                <div className="xs:container xs:mx-auto max-w-full">
                    <div className="flex flex-col items-stretch">
                        <div className="flex justify-between w-full mb-8">
                            <div className="w-2/3  md:w-8/12">
                                <h1 className="text-2xl md:text-3xl text-logo-blue  align-middle pr-3 md:pr-0">
                                    {title}
                                </h1>
                            </div>
                            <div className="w-1/3 md:w-4/12 text-right">
                                {isEdit && (
                                    <Button
                                        label="Preview"
                                        buttonType="primary"
                                        id="publish-button"
                                        onClick={() => {
                                            navigate(
                                                `/products/${matchedProduct?.slug}`
                                            );
                                        }}
                                    />
                                )}
                            </div>
                        </div>
                        <div className="space-x-auto">
                            <div className="flex flex-wrap">
                                <aside className="block sm:w-3/12 px-0">
                                    <ProductSteps />
                                </aside>
                                <main
                                    role="main"
                                    className="w-full sm:w-9/12 px-0 md:pl-10"
                                >
                                    <Outlet />
                                </main>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* MODAL CONFIRM FORM FILL & SUBMISSION */}
            <AlertBackButtonBox
                title="Confirm Navigation"
                navigateBackURL="/admin/products"
                message={`Are you sure you want to leave this page?
                    Any information added on this page will be lost.
                `}
                icon={
                    <Icons
                        name="ExclamationTriangleIcon"
                        size="xxl"
                        strokeWidth=".2"
                    />
                }
                currentPathName={window.location.pathname}
            />
        </>
    );
};

export default ProductAddEdit;
