import { getLocalStorageAuthTokens } from '@helpers/auth.helper';
import { Document } from '@interfaces/Api';

import { BaseUrl } from '../Helpers/Environment';
import { Http } from '@libs/http';

const API_DOCUMENTS = `${BaseUrl}/documents`;

// TODO: pull into own file
export type UploadDocumentType =
    | 'kycDocument'
    | 'passport'
    | 'proofOfAddress'
    | 'sourceOfFunds'
    | 'sourceOfWealth'
    | 'manualSignedSubscriptionAgreement'
    | 'nomineeUnderlyingInvestors'
    | 'proofOfEmployment'
    | 'transactionStatement'
    | 'accountStatement'
    | 'productDocument';

export const documentsPost = async (
    label: UploadDocumentType,
    file: File
): Promise<{ url: string; fileName: string }> => {
    const documentUploadUrl = new URL(API_DOCUMENTS);
    documentUploadUrl.searchParams.set('label', label);

    const formData = new FormData();
    formData.append('upfile', file);

    return Http.post(documentUploadUrl.toString(), formData);
};
