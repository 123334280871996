import { useCallback } from 'react';

import { AuthPermissionEnum, AuthRoleEnum } from '@interfaces/Api';

import { EndInvestorProfileResponse } from '@interfaces/Api/EndInvestorProfileResponse';

import { useAuthState } from '@contexts/AuthContext';
import { useConfigurationState } from '@contexts/ConfigurationContext';

import { getFormattedCurrency } from '@helpers/isoCurrencies';
import { getEndInvestorName } from '@helpers/EndInvestorHelper';
import { getProductDetails } from '@helpers/Product.helper';

import SubscriptionList, {
    SubscriptionListItem,
} from '@containers/Subscriptions/SubscriptionList';

import { ModalService } from '@services/ModalService';
import { useProducts } from '@stores/Products/useProducts';
import { useSubscriptions } from '@stores/Subscriptions/useSubscriptions';
import { useSubscriptionsActions } from '@stores/Subscriptions';
import { Loading } from '@components/Atoms';

export interface ReviewClientSubscriptionsListProps {
    endInvestorProfile: EndInvestorProfileResponse;
}

const ReviewClientSubscriptionsList: React.FC<
    ReviewClientSubscriptionsListProps
> = (props: ReviewClientSubscriptionsListProps) => {
    const { preferences } = useConfigurationState();
    const { hasPermissions, hasRoles } = useAuthState();
    const { products } = useProducts();
    const { subscriptions, isLoading } = useSubscriptions(
        props.endInvestorProfile._id
    );

    const { deleteSubscription } = useSubscriptionsActions();

    const filteredSubscriptions = hasRoles([AuthRoleEnum.fundAdmin])
        ? subscriptions.filter((s) => s._id !== props.endInvestorProfile._id)
        : subscriptions;

    const handleDeleteSubscription = async (subscriptionId: string) => {
        const subscription = filteredSubscriptions.find(
            (val) => val._id === subscriptionId
        );
        if (subscription) {
            const product = products.find(
                (val) => val._id === subscription?.productId
            );

            let midSentencePhrase;
            if (product) {
                midSentencePhrase = getProductDetails({
                    productName: product?.name,
                    shareClassName: subscription?.shareClass?.name,
                    subscriptionAmount: getFormattedCurrency(
                        subscription?.subscriptionAmount,
                        subscription?.subscriptionCurrencyCode
                    ),
                });
            } else {
                midSentencePhrase = 'that has no associated product';
            }

            ModalService.getInstance()
                .setTitle('Delete Subscription')
                .setWidth('300px')
                .setComponent(
                    <div>
                        All data related to the subscription{' '}
                        <b>{midSentencePhrase}</b> and any documents will be
                        permanently deleted from the system.{' '}
                        <b>This operation is irreversible.</b> Are you sure you
                        want to continue?
                    </div>
                )
                .setShowFooter(true)
                .setConfirmationLabel('Delete')
                .setConfirmCallback(async () => {
                    await deleteSubscription(subscriptionId);
                    ModalService.getInstance().hideModal();
                })
                .showModal();
        }
    };

    const subscriptionListItems = useCallback(() => {
        return filteredSubscriptions.map((subscription) => {
            const product = products.find(
                (val) => val._id === subscription.productId
            );

            const endInvestorName = getEndInvestorName(
                props.endInvestorProfile
            );

            return {
                subscriptionId: subscription._id,
                subscriptionUrl: `../subscriptions/${subscription._id}/`,
                subscriptionStatus: subscription.status,
                endInvestorName,
                productName: product?.name,
                shareClassName: subscription?.shareClass?.name,
                subscriptionAmountFormatted: getFormattedCurrency(
                    subscription.subscriptionAmount,
                    subscription.subscriptionCurrencyCode
                ),
                subscriptionAmount: subscription.subscriptionAmount,
                subscriptionCurrencyCode: subscription.subscriptionCurrencyCode,
                acceptedSubscriptionAmount:
                    subscription.acceptedSubscriptionAmount,
                status: subscription.status,
                product,
                statusLogLatestDate: subscription?.statusLogs.sort(
                    (a, b) =>
                        new Date(b.createdAt).getTime() -
                        new Date(a.createdAt).getTime()
                )[0].createdAt,
            } as SubscriptionListItem;
        });
    }, [products, props.endInvestorProfile, filteredSubscriptions]);

    const canDeleteSubscription =
        preferences?.feature?.data?.canSuperUserDeleteSubscriptions &&
        hasPermissions([AuthPermissionEnum.deleteSubscription]);
    const data = subscriptionListItems();

    if (isLoading) return <Loading />;

    return (
        <div className="mb-6">
            <SubscriptionList
                subscriptionListItems={subscriptionListItems()}
                onDeleteSubscription={
                    canDeleteSubscription ? handleDeleteSubscription : undefined
                }
            />
        </div>
    );
};

export default ReviewClientSubscriptionsList;
