import { useRegisterInvestorState } from '../RegisterInvestor.context';
import { useEndInvestorProfileState } from '@contexts/EndInvestorProfileContext';
import { InvestorType } from '@interfaces/Api/InvestorJurisdictionsResponse';
import { useTranslation } from 'react-i18next';

export const ClientTypeSummary = () => {
    const { t } = useTranslation();
    const { data } = useRegisterInvestorState();
    const { investorJurisdictions = [] } = useEndInvestorProfileState();

    const findByInvestorType = (investorType: InvestorType) =>
        investorType.value === data['clientType'];

    const clientTypeLabel = investorJurisdictions
        .find((investorJurisdictions) =>
            investorJurisdictions.investorTypes.find(findByInvestorType)
        )
        ?.investorTypes.find(findByInvestorType)?.label;

    return (
        <p>
            <span className="font-bold mr-2">{t('client.registration.summary.client_type')}:</span>
            {clientTypeLabel}
        </p>
    );
};
