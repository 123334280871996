import React from 'react';
import { Link } from 'react-router-dom';
import classNames from 'classnames';

import { ProductResponse } from '@interfaces/Api';
import { AvailabilityStatusEnum } from '@interfaces/Api/ProductResponse';

import { toCapitalizedWords } from '@helpers/ToCapitalizedWords';

import { Icons } from '@components/Atoms';
import { useStorageTokenState } from '@contexts/StorageTokenContext';

interface ProductCardAdminProps {
    product: ProductResponse;
    onEditProduct: () => void;
}

const ProductCardAdmin: React.FC<ProductCardAdminProps> = ({
    product,
    onEditProduct,
}) =>{
    const { withToken } = useStorageTokenState();
    
    return (
        <article className="flex flex-col bg-logo-blue overflow-hidden border-2 rounded-xl h-full">
            <div className="relative group">
                <Link to={`/products/${product.slug}`}>
                    <img
                        src={withToken(product.thumbnail
                            ? product.thumbnail
                            : '/assets/default.jpg')
                        }
                        className="rounded-xl object-cover h-64 w-full mr-0 aspect-[4/3]"
                        alt={product.name}
                    />
                </Link>
                <p
                    className={classNames(
                        'absolute right-2 top-2 p-2 cursor-pointer group',
                        product.availabilityStatus !==
                            AvailabilityStatusEnum.openForSubscription &&
                            'bg-white rounded-full'
                    )}
                >
                    {product.availabilityStatus !==
                    AvailabilityStatusEnum.openForSubscription ? (
                        <Icons name="LockClosedIcon" />
                    ) : null}
                </p>
            </div>
            <p className="text-white text-2xl sm:text-sm md:leading-[1rem] p-3 h-auto lg:h-24 md:truncate md:text-ellipsis md:overflow-hidden ... lg:whitespace-normal lg:overflow-visible">
                {product.name}
            </p>
    
            {product.assetClassType && (
                <div className="text-blue-400 py-6 px-4">
                    <p className="bg-brand-navy-light text-xs capitalize rounded-xl inline-block md:break-all lg:break-normal lg:whitespace-normal lg:overflow-visible leading-4 p-2">
                        {toCapitalizedWords(product.assetClassType)}{' '}
                    </p>
                </div>
            )}
    
            <div className="flex justify-between">
                <div className="flex flex-wrap m-3 lg:m-5 space-x-1">
                    {product.shareClasses
                        ?.map((shareClass) => shareClass.currency)
                        .filter((val, index, self) => self.indexOf(val) === index)
                        .map((val, i) => (
                            <div
                                key={i}
                                className="bg-gold-300 text-xs rounded-lg inline-block px-1 lg:px-2 last-of-type:ml-0 py-2"
                            >
                                {val}
                            </div>
                        ))}
                </div>
    
                <div className="flex flex-wrap space-x-2 m-3 lg:m-5">
                    <div className="w-1/3">
                        <Link to={`/admin/products/${product._id}`}>
                            <Icons name="PencilIcon" stroke="white" />
                        </Link>
                    </div>
                    <div className="w-1/3">
                        <button
                            onClick={onEditProduct}
                            className={`${
                                product.status === 'published'
                                    ? 'cursor-default'
                                    : 'cursor-pointer'
                            }`}
                            disabled={product.status === 'published'}
                        >
                            <Icons
                                name="TrashIcon"
                                strokeWidth="1.5"
                                stroke={`${
                                    product.status === 'published'
                                        ? 'gray'
                                        : 'white'
                                }`}
                            />
                        </button>
                    </div>
                </div>
            </div>
        </article>
    )
};

export default ProductCardAdmin;
