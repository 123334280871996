import React from 'react';

import { useAuthState } from '@contexts/AuthContext';

import { toCapitalizedWords } from '@helpers/ToCapitalizedWords';

const Footer: React.FC = () => {
    const d = new Date();
    const footerDate = d.getFullYear();
    const { currentUser } = useAuthState();

    return (
        <footer className="bg-footer-bg h-20">
            <div className="container mx-auto px-4 sm:px-8 flex justify-between items-center h-full">
                <div className="flex flex-wrap items-stretch">
                    <p className="text-white text-sm">
                        &copy; {footerDate} S64
                    </p>
                    <div className="hidden">testing pipelines</div>
                </div>

                {currentUser?.user.role && (
                    <div className="flex flex-wrap items-stretch">
                        <p className="text-white text-sm">
                            {toCapitalizedWords(currentUser?.user.role)} View
                        </p>
                    </div>
                )}
            </div>
        </footer>
    );
};
export default Footer;
