import { useTranslation } from 'react-i18next';
import { useRegisterInvestorState } from '../RegisterInvestor.context';
import { isoCountries } from '@helpers/isoCountries';

export const NationalitiesSummary = (): JSX.Element => {
    const { t } = useTranslation();
    const { data } = useRegisterInvestorState();

    return (
        <p>
            <span className="font-bold mr-2">{t('client.registration.nationalities.title')}:</span>
            {data.individualDetails?.nationalities
                ?.map((nat) => isoCountries[nat.countryCode])
                .join(' - ')}
        </p>
    );
};
