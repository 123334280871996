import React from 'react';
import { Navigate, useLocation, useSearchParams } from 'react-router-dom';
import { useAuthState } from '@contexts/AuthContext';
import { useConfigurationState } from '@contexts/ConfigurationContext';

const RequiredAuth: React.FC = ({ children }) => {
    const { isLoggedIn } = useAuthState();
    const location = useLocation();
    const [searchParams] = useSearchParams();
    const { configuration } = useConfigurationState();

    const defaultRedirectRoute = configuration?.preferences?.feature
        ?.authentication?.enableProductsPageWithoutLoggingIn
        ? '/products'
        : '/login';

    return (
        <>
            {isLoggedIn ? (
                children
            ) : (
                <Navigate
                    to={defaultRedirectRoute}
                    replace
                    state={{
                        path: `${location.pathname}${
                            searchParams ? `?${searchParams}` : ''
                        }`,
                    }}
                />
            )}
        </>
    );
};

export default RequiredAuth;
