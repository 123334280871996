import { useAuthState } from '@contexts/AuthContext';
import { useConfigurationState } from '@contexts/ConfigurationContext';
import { AuthRoleEnum, SystemUserResponse } from '@interfaces/Api';
import IncompleteClientRegistration from './IncompleteClientRegistration';

interface IncompleteClientRegistrationContainerProps {
    systemUsers?: SystemUserResponse[];
}

const IncompleteClientRegistrationContainer: React.FC<
    IncompleteClientRegistrationContainerProps
> = (props) => {
    const { preferences } = useConfigurationState();
    const { currentUser } = useAuthState();

    const loggedInClientSystemUser = props.systemUsers
        ?.filter(
            (systemUser) =>
                systemUser.role == AuthRoleEnum.endInvestor &&
                (!systemUser.endInvestorProfileIds || systemUser.endInvestorProfileIds?.length <= 0)
        )
        .find((a) => a._id === currentUser?.user._id);

    const invitedBySystemUser = props.systemUsers?.find(
        ({ _id }) =>
            _id === loggedInClientSystemUser?.invitationLog?.[0]?.sentBy
    );

    return (
        <IncompleteClientRegistration
            allowInvitedClientToRegister={
                !!preferences?.feature.investorRegistrationProcess
                    ?.allowInvitedClientToRegister
            }
            authorizeRMToComplete={
                !!currentUser?.user.authorizeRMToComplete?.authorized
            }
            loggedInClientSystemUser={loggedInClientSystemUser}
            invitedBySystemUser={invitedBySystemUser}
        />
    );
};

export default IncompleteClientRegistrationContainer;
