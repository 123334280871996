import { ErrorMessage, Field, FieldInputProps, FieldMetaProps } from 'formik';
import dayjs from 'dayjs';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import localizedFormat from 'dayjs/plugin/localizedFormat';

export interface DatePickerProps {
    id?: string;
    name: string;
    value?: string;
    defaultValue?: string;
    min?: number | string | undefined;
    max?: number | string | undefined;
    label?: string;
    onChange?: (value: string) => void;
    onBlur?: Function;
    disabled?: boolean;
}

dayjs.extend(advancedFormat);
dayjs.extend(localizedFormat);

const DatePicker = (props: DatePickerProps) => {
    const handleNewDateFormat = (fieldValue: any) => {
        let valISODate = null;
        const inputDateVal = fieldValue === undefined ? null : fieldValue;
        valISODate = inputDateVal
            ? new Date(inputDateVal).toISOString()
            : fieldValue;
        return fieldValue === valISODate && valISODate !== undefined
            ? dayjs(valISODate.split('T')[0]).format('YYYY-MM-DD')
            : dayjs(fieldValue).format('YYYY-MM-DD') && fieldValue;
    };

    return (
        <div className="relative w-full mb-3 cursor-default">
            <Field
                id={props.name}
                name={props.name}
                defaultValue={props.defaultValue}
                onChange={props.onChange}
                onBlur={props.onBlur}
            >
                {({
                    field,
                    meta,
                }: {
                    field: FieldInputProps<string>;
                    meta: FieldMetaProps<string>;
                }) => {
                    return (
                        <input
                            className={`min-w-full w-full ${
                                props.label ? 'h-16 pt-5' : 'h-12'
                            } pl-3 pr-3 placeholder-gray-300 border border-gray-300 rounded-lg focus:shadow-outline ${
                                meta.touched && meta.error
                                    ? 'border-red-error bg-red-error-900'
                                    : ''
                            }`}
                            type="date"
                            {...field}
                            min={props.min || '1900-01-01'}
                            max={props.max}
                            disabled={props.disabled}
                            value={handleNewDateFormat(field.value)}
                        />
                    );
                }}
            </Field>
            {props.label && (
                <div className="absolute left-1 top-2 text-xs flex items-center px-2 pointer-events-none">
                    {props.label}
                </div>
            )}
            <div className="flex content-start text-red-500 text-sm">
                <ErrorMessage
                    name={props.name!}
                    render={(msg: any) => <div>{msg}</div>}
                />
            </div>
        </div>
    );
};

export default DatePicker;
