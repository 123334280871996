import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { AuthRoleEnum, Preferences, SystemUserResponse } from '@interfaces/Api';

import { useAuthState } from '@contexts/AuthContext';

import {
    useMenuNavItems,
    AdminMenuItem,
} from '@components/Organisms/Navbar/useMenuNavItems';
import { useConfigurationState } from '@contexts/ConfigurationContext';
import { endInvestorContainsMoreThenTwoProfiles } from '@helpers/SystemUser.helper';

export const useNavBar = () => {
    const { hasPermissions, currentUser } = useAuthState();
    const { t } = useTranslation();
    const { adminMenuItems, defaultToolMenuItems } = useMenuNavItems();
    const { preferences } = useConfigurationState();

    const adminSubMenuVisibleItems = adminMenuItems.map(
        (item: AdminMenuItem) => ({
            name: item.label,
            link: item.link,
            showItem: hasPermissions(item.requiredPermissions),
        })
    );

    const toolSubMenuVisibleItems = defaultToolMenuItems
        .map((item) => ({
            ...item,
            showItem: item.showItem(
                currentUser?.user.role,
                preferences?.feature
            ),
        }))
        .filter((item) => item.showItem);

    const loginEndInvestorContainsMoreThenTwoProfiles =
        endInvestorContainsMoreThenTwoProfiles(currentUser?.user);

    const defaultMenuItems: {
        link?: string;
        name: string;
        showItem?: (
            authRole: AuthRoleEnum,
            feature: Preferences['feature']
        ) => boolean;
        subMenuitems?: { link?: string; name: string; showItem?: boolean }[];
    }[] = useMemo(() => {
        return [
            {
                link: '/portfolio',
                name: t('navbar.portfolio'),
                showItem: (authRole: AuthRoleEnum) =>
                    AuthRoleEnum.endInvestor === authRole &&
                    !loginEndInvestorContainsMoreThenTwoProfiles,
            },
            {
                link: '/clients',
                name: t('navbar.clients'),
                showItem: (authRole: AuthRoleEnum) =>
                    [
                        AuthRoleEnum.superUser,
                        AuthRoleEnum.administrator,
                        AuthRoleEnum.relationshipManager,
                        AuthRoleEnum.externalRelationshipManager,
                        AuthRoleEnum.fundAdmin,
                        AuthRoleEnum.systemViewer,
                        AuthRoleEnum.assetManagerAdministrator,
                    ].includes(authRole) ||
                    loginEndInvestorContainsMoreThenTwoProfiles,
            },
            {
                link: '/subscriptions',
                name: t('navbar.subscriptions'),
                showItem: (authRole: AuthRoleEnum) =>
                    [AuthRoleEnum.fundAdmin].includes(authRole),
            },
            {
                link: '/products',
                name: t('navbar.products'),
                showItem: (authRole: AuthRoleEnum) =>
                    [
                        AuthRoleEnum.superUser,
                        AuthRoleEnum.administrator,
                        AuthRoleEnum.relationshipManager,
                        AuthRoleEnum.externalRelationshipManager,
                        AuthRoleEnum.endInvestor,
                        AuthRoleEnum.systemViewer,
                        AuthRoleEnum.assetManagerAdministrator,
                        AuthRoleEnum.productViewer,
                        AuthRoleEnum.profileManager,
                        AuthRoleEnum.profileAdministrator,
                        AuthRoleEnum.prospectiveInvestor,
                    ].includes(authRole),
            },
            {
                link: '/application',
                name: t('navbar.application'),
                showItem: (authRole: AuthRoleEnum) =>
                    AuthRoleEnum.prospectiveInvestor === authRole,
            },
            {
                link: '/tasks',
                name: t('navbar.tasks'),
                showItem: (
                    authRole: AuthRoleEnum,
                    feature: Preferences['feature']
                ) =>
                    [
                        AuthRoleEnum.superUser,
                        AuthRoleEnum.administrator,
                        AuthRoleEnum.systemViewer,
                        AuthRoleEnum.relationshipManager,
                        AuthRoleEnum.externalRelationshipManager,
                        AuthRoleEnum.fundAdmin,
                    ].includes(authRole) && !!feature.topMenu?.displayTasks,
            },
            {
                link: '/distributor-overview',
                name: t('navbar.distributor'),
                showItem: (
                    authRole: AuthRoleEnum,
                    feature: Preferences['feature']
                ) =>
                    [
                        AuthRoleEnum.superUser,
                        AuthRoleEnum.assetManagerAdministrator,
                    ].includes(authRole) &&
                    !!feature.topMenu?.displayDistributorsOverview,
            },
            {
                name: t('navbar.tools'),
                subMenuitems: toolSubMenuVisibleItems,
            },
            {
                name: t('navbar.admin'),
                showItem: (authRole: AuthRoleEnum) =>
                    [
                        AuthRoleEnum.superUser,
                        AuthRoleEnum.administrator,
                        AuthRoleEnum.systemViewer,
                        AuthRoleEnum.assetManagerAdministrator,
                        AuthRoleEnum.profileManager,
                        AuthRoleEnum.profileAdministrator,
                    ].includes(authRole),
                subMenuitems: adminSubMenuVisibleItems,
            },
            {
                name: t('navbar.subscriptions'),
                showItem: (authRole: AuthRoleEnum) =>
                    [AuthRoleEnum.fundAdmin].includes(authRole),
            },
        ];
    }, [adminSubMenuVisibleItems, defaultToolMenuItems, t]);

    return { defaultMenuItems };
};
