import { useCallback, useMemo } from 'react';
import { Form, Formik } from 'formik';
import { useNavigate, useParams } from 'react-router-dom';
import * as Yup from 'yup';

import { AuthRoleEnum, IntermediaryResponse } from '@interfaces/Api';
import { SelectOption } from '@interfaces/InterfaceFormsProps';

import { Button } from '@components/Atoms';
import { FormInputBox, FormSelect } from '@components/Molecules';

import { useAuthState } from '@contexts/AuthContext';
import { useIntermediaries } from '@stores/Intermediaries/useIntermediaries';

import { errorToString } from '@helpers/error.helper';
import { notifyError, notifySuccess } from '@helpers/toastrHelper';
import { isoCountriesOptions } from '@helpers/isoCountries';

import { useBookingCentres } from '@stores/BookingCentres/useBookingCentres';
import { useSystemUsers } from '@stores/SystemUsers/useSystemUsers';

interface Fields {
    name: string;
    type: 'text' | 'selection';
    label: string;
    placeholder: string;
    optionsData?: SelectOption<string>[];
    disabled?: (params: any) => boolean;
    handleFieldChange?: (
        e: React.ChangeEvent<HTMLSelectElement>,
        value: any,
        setValues: any
    ) => void;
    visible?: (values: any) => boolean;
    required?: boolean;
}

const convertFieldsToSchema = (fields: Fields[]) =>
    fields.reduce((acc: any, current) => {
        if (current.required) {
            acc[current.name] = Yup.string().required(
                `${current.label} is required`
            );
        }
        return acc;
    }, {});

export const MutateBookingCentre = () => {
    const { userId } = useParams();
    const { systemUsers } = useSystemUsers();
    const { intermediaries } = useIntermediaries();
    const { currentUser } = useAuthState();

    const { addBookingCentre } = useBookingCentres();

    const fields: Fields[] = useMemo(() => {
        const fields: Fields[] = [
            {
                name: 'bookingCentreName',
                type: 'text',
                label: 'Booking Centre Name',
                placeholder: 'Booking Centre Name',
                required: true,
            },
            {
                name: 'description',
                type: 'text',
                label: 'Description',
                placeholder: 'Description',
            },
            {
                name: 'countryCode',
                label: 'Country Name',
                placeholder: 'Country Name',
                type: 'selection',
                required: true,
                optionsData: isoCountriesOptions,
            },
        ];
        if (currentUser?.user.role == AuthRoleEnum.superUser) {
            fields.push({
                name: 'intermediaryId',
                label: 'Intermediary',
                placeholder: 'Intermediary',
                type: 'selection',
                required: true,
                optionsData: intermediaries.map(
                    (item: IntermediaryResponse) => ({
                        label: item.name || '',
                        value: item._id || '',
                    })
                ),
                disabled: () =>
                    currentUser?.user.role !== AuthRoleEnum.superUser,
            });
        }
        return fields;
    }, [currentUser?.user.role, intermediaries]);

    const user: any = systemUsers.find(({ _id }) => _id === userId);
    const isEdit = Boolean(user);
    const validationSchema = Yup.object().shape(convertFieldsToSchema(fields));

    const navigate = useNavigate();

    const submitHandler = useCallback(
        (values: any, actions: any) => {
            const mutateMap = isEdit
                ? {
                      func: addBookingCentre,
                      successMessage: 'Booking centre updated!',
                      failMessage: 'Could not update Booking centre. ',
                  }
                : {
                      func: addBookingCentre,
                      successMessage: 'New Booking centre has been created',
                      failMessage: 'Could not create new Booking centre. ',
                  };

            const data = { ...values };
            if (
                !values.intermediaryId &&
                currentUser?.user.role === AuthRoleEnum.administrator
            ) {
                data.intermediaryId = currentUser.user.intermediaryId;
            }

            if (!values.intermediaryId && !data.intermediaryId) {
                notifyError('intermediaryId is required');
                return;
            }

            mutateMap
                .func(data)
                .then(() => {
                    notifySuccess(mutateMap.successMessage);
                    if (!isEdit) {
                        actions?.resetForm();
                    }
                    navigate('/admin/booking-centres');
                })
                .catch((e) => {
                    actions?.setSubmitting(false);
                    notifyError(mutateMap.failMessage + errorToString(e));
                });
        },
        [currentUser?.user.intermediaryId, currentUser?.user.role, isEdit]
    );

    const initialValues = useMemo(() => {
        return fields.reduce((acc, { name }) => {
            const value = isEdit ? user?.[name] : '';

            acc[name] = value;
            return acc;
        }, {} as Record<string, string | undefined>);
    }, [fields, isEdit, user]);

    // if it's edit mode, UI need to wait the user data to be loaded
    const shouldShowForm = (isEdit && user) || !isEdit;

    return (
        <div>
            {shouldShowForm && (
                <Formik
                    onSubmit={submitHandler}
                    initialValues={initialValues}
                    validationSchema={validationSchema}
                >
                    {({
                        values,
                        setValues,
                        handleChange,
                        handleBlur,
                        isSubmitting,
                    }: any) => (
                        <Form className="text-logo-blue">
                            <div className="w-full mb-5">
                                <h1 className="text-3xl text-logo-blue ">
                                    {isEdit ? 'Update' : 'Add'} Booking Centre
                                </h1>
                            </div>
                            <div>
                                {fields.map(
                                    ({
                                        type,
                                        label,
                                        name,
                                        placeholder,
                                        disabled,
                                        optionsData,
                                        handleFieldChange,
                                        visible,
                                    }) => {
                                        if (visible && !visible(values)) {
                                            return <div key={name}></div>;
                                        }

                                        const value: any = values?.[name];

                                        let component: any;

                                        switch (type) {
                                            case 'text':
                                                component = (
                                                    <FormInputBox
                                                        name={name}
                                                        key={name}
                                                        label={label}
                                                        type={type}
                                                        value={value}
                                                        onChange={handleChange}
                                                        onBlur={handleChange}
                                                        placeholder={
                                                            placeholder
                                                        }
                                                        data-testid={name}
                                                    />
                                                );

                                                break;
                                            case 'selection':
                                                component = (
                                                    <FormSelect
                                                        key={name}
                                                        name={name}
                                                        placeholder={
                                                            placeholder
                                                        }
                                                        label={label}
                                                        value={value}
                                                        onChange={(
                                                            e: React.ChangeEvent<HTMLSelectElement>
                                                        ) => {
                                                            if (
                                                                handleFieldChange
                                                            ) {
                                                                handleFieldChange(
                                                                    e,
                                                                    values,
                                                                    setValues
                                                                );
                                                            }

                                                            handleChange(e);
                                                        }}
                                                        onBlur={handleBlur}
                                                        optionsData={
                                                            optionsData
                                                        }
                                                    />
                                                );
                                                break;
                                            default:
                                                component = (
                                                    <div key={name}></div>
                                                );
                                                break;
                                        }

                                        return component;
                                    }
                                )}
                            </div>
                            <div className="flex justify-between pt-3">
                                <Button
                                    label="Cancel"
                                    buttonType="secondary"
                                    type="button"
                                    id="cancel-button"
                                    onClick={() => {
                                        navigate('/admin/booking-centres');
                                    }}
                                />
                                <Button
                                    label={`${
                                        isEdit ? 'Update' : 'Add'
                                    } Booking Centre`}
                                    buttonType="primary"
                                    type="submit"
                                    id="create-button"
                                    disabled={isSubmitting}
                                />
                            </div>
                        </Form>
                    )}
                </Formik>
            )}
        </div>
    );
};
