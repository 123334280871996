import { create } from 'zustand';
import { devtools } from 'zustand/middleware';
import { SystemUserResponse } from '@interfaces/Api';
import { handleFetchSystemUsers } from './SystemUsers.services';

interface SystemUsersStore {
    systemUsers: SystemUserResponse[];
    isLoading: boolean;
    actions: {
        fetchSystemUsers: (
            hasAssetManagerAndIntermediaryInfo: boolean
        ) => Promise<void>;
    };
}

export const useSystemUsersStore = create<SystemUsersStore>()(
    devtools(
        (set): SystemUsersStore => ({
            systemUsers: [],
            isLoading: false,
            actions: {
                fetchSystemUsers: async (
                    hasAssetManagerAndIntermediaryInfo
                ) => {
                    await handleFetchSystemUsers(
                        set,
                        hasAssetManagerAndIntermediaryInfo
                    );
                },
            },
        })
    )
);
