import { SubscriptionResponse } from './SubscriptionResponse';
import { SubscriptionPerformancePoint } from './SubscriptionPerformancePoint';
import { ProductResponse } from './ProductResponse';

export enum FundTransactionType {
    capitalCall = 'CapitalCall',
    distribution = 'Distribution',
}

export interface FundTransaction {
    date: string;
    amount: number;
    description: string;
    type: FundTransactionType;
}

export interface PerformanceSummary {
    allocatedAmount: number;
    totalPaidIn: number;
    totalUndrawn: number;
    totalUndrawnCalled: number;
}

export interface InvestmentReport {
    subscription: SubscriptionResponse;
    product: ProductResponse;
    fundTransactions: FundTransaction[];
    performanceSummary: PerformanceSummary;
    subscriptionPerformancePoints: SubscriptionPerformancePoint[];
}
