import React from 'react';
import { Form, Formik } from 'formik';

import { Button, Icons, InputBox } from '@components/Atoms';
import { notifySuccess } from '@helpers/toastrHelper';
import { useAuthState } from '@contexts/AuthContext';
import { useDocumentTemplatesLibraryState } from '@contexts/DocumentTemplateLibrary';

interface TopBarProps {
    setCurrentPageNumber: (n: number) => void;
    setSearchText: (val: string) => void;
    setShowDocumentModal: (val: boolean) => void;
    showDocumentUploadModal: boolean;
}

export const TopBar: React.FC<TopBarProps> = ({
    setCurrentPageNumber,
    setSearchText,
    setShowDocumentModal,
    showDocumentUploadModal,
}) => {
    const { getDocumentTemplates } = useDocumentTemplatesLibraryState();
    const { currentSystemUserRoleActions } = useAuthState();

    const refreshTableData = async () => {
        await getDocumentTemplates();
        notifySuccess('Document Template Data has been refreshed');
    };

    return (
        <>
            <div className="flex justify-between w-full mb-5">
                <h1 className="text-3xl text-logo-blue font-bold align-middle w-3/5 lg:w-4/5">
                    Document Templates Library
                </h1>
                {currentSystemUserRoleActions?.create &&
                    currentSystemUserRoleActions.create.length > 0 && (
                        <Button
                            label="Upload Document"
                            onClick={() =>
                                setShowDocumentModal(!showDocumentUploadModal)
                            }
                        />
                    )}
            </div>

            <div className="flex flex-wrap justify-between mb-2">
                <div
                    id="search-user"
                    className="justify-start w-4/5 md:w-2/5 items-left"
                >
                    <Formik initialValues={{ search: '' }} onSubmit={() => {}}>
                        <Form
                            onChange={(val: any) => {
                                setCurrentPageNumber(1);
                                setSearchText(val.target.value);
                            }}
                        >
                            <InputBox
                                name="search"
                                placeholder="Search for a document template..."
                                autoComplete="off"
                            />
                        </Form>
                    </Formik>
                </div>

                <Button
                    hasIconOnly
                    startIcon={<Icons name="ArrowPathIcon" />}
                    onClick={refreshTableData}
                    className="self-end bg-brand-navy-light rounded-md p-1 mr-2 cursor-pointer"
                />
            </div>
        </>
    );
};
