import { formatDateTimeString } from '@helpers/Date.helper';
import { getEndInvestorName } from '@helpers/EndInvestorHelper';
import { getSystemUserFullName } from '@helpers/SystemUser.helper';
import { ReviewClientSummaryDetailsProps } from '@interfaces/ReviewClientSummaryDetailsProps';
import { ReviewItem } from './ReviewItem';

const ReviewClientContactDetails = (props: ReviewClientSummaryDetailsProps) => (
    <div className="leading-5 mb-4">
        <ReviewItem
            label="Email Address:"
            value={props.data?.individualDetails?.emailAddress}
        />

        <div className="text-base">
            <strong>Phone Number: </strong>
            {props.data?.individualDetails?.phoneDiallingCode}
            {props.data?.individualDetails?.phoneNumber}
        </div>

        {props.linkedSystemUser && (
            <>
                {props.data?.individualDetails?.emailAddress !==
                    props.linkedSystemUser?.email && (
                    <ReviewItem
                        label="Login Email:"
                        value={props.linkedSystemUser?.email}
                    />
                )}

                {getSystemUserFullName(props.linkedSystemUser) !==
                    getEndInvestorName(props.data) && (
                    <ReviewItem
                        label="Login Name:"
                        value={getSystemUserFullName(props.linkedSystemUser)}
                    />
                )}

                {props.linkedSystemUser?.lastLoginDate && (
                    <ReviewItem
                        label="Last Login:"
                        value={formatDateTimeString(
                            props.linkedSystemUser?.lastLoginDate
                        )}
                    />
                )}
            </>
        )}
    </div>
);

export default ReviewClientContactDetails;
