import { Link, LinkProps } from 'react-router-dom';

interface ConditionalLinkProps extends LinkProps {
    linkCondition: boolean;
}

const ConditionalLink: React.FC<ConditionalLinkProps> = (props) => {
    const data: LinkProps = {
        to: props.to,
        title: props.title,
    };

    return !!props.linkCondition && props.to ? (
        <Link {...data}>{props.children}</Link>
    ) : (
        <>{props.children}</>
    );
};

export default ConditionalLink;
