interface ProductStep {
    endPathUrl: string;
    stepName: string;
    showStep: boolean;
}

export const productSteps: ProductStep[] = [
    {
        endPathUrl: 'product-information',
        stepName: 'Product Information',
        showStep: true,
    },
    {
        endPathUrl: 'liquidity-information',
        stepName: 'Liquidity Information',
        showStep: true,
    },
    {
        endPathUrl: 'product-fees',
        stepName: 'Product Fees',
        showStep: true,
    },
    {
        endPathUrl: 'product-description',
        stepName: 'Product Description',
        showStep: true,
    },
    {
        endPathUrl: 'contact-information',
        stepName: 'Contact Information',
        showStep: true,
    },
    {
        endPathUrl: 'product-eligibility',
        stepName: 'Eligibility Jurisdiction',
        showStep: true,
    },
    {
        endPathUrl: 'product-eligibility-exclusion',
        stepName: 'Eligibility Exclusion',
        showStep: true,
    },
    {
        endPathUrl: 'shareclass-information',
        stepName: 'ShareClass Information',
        showStep: true,
    },
    { endPathUrl: 'product-images', stepName: 'Images', showStep: true },
    { endPathUrl: 'product-videos', stepName: 'Videos', showStep: true },
    { endPathUrl: 'product-documents', stepName: 'Documents', showStep: true },
    {
        endPathUrl: 'logo',
        stepName: 'Logo',
        showStep: true,
    },
    {
        endPathUrl: 'visibility-settings',
        stepName: 'Visibility Settings',
        showStep: true,
    },
    {
        endPathUrl: 'subscription-signing-documents-templates',
        stepName: 'Signing documents',
        showStep: true,
    },
];
