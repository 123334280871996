import React, { useMemo } from "react";
import {
    closestCenter,
    DndContext, 
    DragEndEvent,
    MouseSensor,
    useSensor, 
    useSensors,
} from "@dnd-kit/core";
import { 
    arrayMove,
    SortableContext,
    useSortable,
    verticalListSortingStrategy,
} from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import { Icons } from "@components/Atoms/Icons";

type HandlePosition = 'left' | 'right';

interface SortableItemProps {
    id: string;
    handlePosition?: HandlePosition;
    handleClassName?: string;
}

interface SortableListProps {
    listItems?: any;
    listElements: JSX.Element[];
    onSorted: (listItems: any) => void;
    handlePosition?: HandlePosition;
    handleClassName?: string;
}

const SortableItem: React.FC<SortableItemProps> = ({ id, children, handlePosition = 'right', handleClassName }) => {
    const { attributes, listeners, setNodeRef, transform, setActivatorNodeRef } = useSortable({ id });
    
    const style = {
        transform: CSS.Transform.toString(transform),
    };

    const handleIcon = (
        <button ref={setActivatorNodeRef} {...listeners} type="button" className={`h-4 ${handleClassName}`}>
            <Icons name='Bars2Icon' stroke="lightgray" size="small" />
        </button>
    );

    return (
        <div ref={setNodeRef} style={style} {...attributes} className="flex cursor-default">           
            { handlePosition === 'left' ? handleIcon : null }
            { children }
            { handlePosition === 'right' ? handleIcon : null }
        </div>
    );
}

const SortableList: React.FC<SortableListProps> = ({ listItems, listElements, onSorted, handlePosition, handleClassName }) => {
    const itemIndex = useMemo(() => listElements.map((_, index) => index.toString()), [listElements]);

    const sensors = useSensors(
        useSensor(MouseSensor)
    );

    const handleDragEnd = (event: DragEndEvent) => {
        const { active, over } = event;

        if (active.id !== over!.id) {
            const oldIndex = itemIndex.indexOf(active.id.toString());
            const newIndex = itemIndex.indexOf(over!.id.toString());
            const newItemIndex = arrayMove(itemIndex, oldIndex, newIndex);
            
            const newListItems: any = [];
            newItemIndex.forEach((index: any) => {
                newListItems.push(listItems[Number(index)]);
            });
            
            onSorted(newListItems);
        }
    };

    return (
        <DndContext sensors={sensors} collisionDetection={closestCenter} onDragEnd={handleDragEnd}>
            <SortableContext items={itemIndex} strategy={verticalListSortingStrategy}>
                { 
                    itemIndex.map((item) => (
                        <SortableItem 
                            key={item}
                            id={item.toString()}
                            handlePosition={handlePosition}
                            handleClassName={handleClassName}
                        >
                            {listElements[Number(item)]}
                        </SortableItem>
                    ))
                }
            </SortableContext>
        </DndContext>
    );
}

export default SortableList;