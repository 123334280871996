import {
    FormCheckbox,
    FormCheckboxList,
    FormRadioGroup,
    FormSelect,
} from '@components/Molecules';
import { useFormikContext } from 'formik';
import { Button } from '@components/Atoms';
import { toCapitalizedWords } from '@helpers/ToCapitalizedWords';
import { useCallback, useState } from 'react';
import { investorCountryRequirementTree } from 'common';
import { ModalService } from '@services/ModalService';
import { WarningTextType } from 'common/dist/Tenants/HG/ProspectiveInvestor/InvestorCountryRequirementTree';
import { PdfViewer } from '@components/Organisms';

interface CategoryOfInvestorProps {
    onBack?: () => void;
    onSubmit?: (action: 'submit' | 'review' | 'reject') => void;
}

const CategoryOfInvestor: React.FC<CategoryOfInvestorProps> = (props) => {
    const { values } = useFormikContext<{
        countryCodeOfResidence: string;
        investorType: string;
        investorCategory?: string;
        investorCategoryType?: string;
        subInvestorCategoryType?: string;
        checkBoxConfirmation?: boolean;
    }>();
    const { onBack, onSubmit } = props;
    const [isSubmitting, setIsSubmitting] = useState(false);

    const investorCategories = investorCountryRequirementTree.find(
        (a) => a.countryCode == values.countryCodeOfResidence
    ).investorCategories;

    const investoryCategoryOptions = investorCategories?.map((a) => ({
        label: a.name,
        value: a.name,
    }));

    const investorCategory = investorCategories?.find(
        (a) => a.name == values.investorCategory
    );

    const investorCategoryTypesOptions =
        investorCategory?.investorCategoryTypes?.map((a) => ({
            label: a.name,
            value: a.value,
        }));

    const investorCategoryType = investorCategory?.investorCategoryTypes?.find(
        (a) => a.value == values.investorCategoryType
    );

    const investorCategoryTypeDirectSubmission =
        investorCategoryType?.type == 'directSubmission' &&
        investorCategoryType;

    const investorCategoryTypeCheckboxSubmission =
        investorCategoryType?.type == 'checkboxSubmission' &&
        investorCategoryType;

    const investorCategoryTypeSubcategoriesSubmission =
        investorCategoryType?.type == 'subcategorySubmission' &&
        investorCategoryType;
    const investorCategoryTypeSubcategoriesCheckboxSubmissionOptions =
        investorCategoryTypeSubcategoriesSubmission?.subInvestorCategoryTypes?.map(
            (a) => ({
                label: a.name,
                value: a.value,
            })
        );
    const subsubselection =
        investorCategoryTypeSubcategoriesSubmission?.subInvestorCategoryTypes?.find(
            (a) => a.value == values.subInvestorCategoryType
        );

    const handleSubmit = (action: 'submit' | 'review' | 'reject') => {
        setIsSubmitting(true);
        onSubmit(action);
    };

    const getSubmitButton = () => {
        let action: 'submit' | 'review' | 'reject';
        let isDisabled = true;
        if (investorCategoryTypeDirectSubmission) {
            action = investorCategoryTypeDirectSubmission.action;
            isDisabled = false;
        }
        if (investorCategoryTypeCheckboxSubmission) {
            action = investorCategoryTypeCheckboxSubmission.action;
            isDisabled = values.checkBoxConfirmation != true;
        }
        if (investorCategoryTypeSubcategoriesSubmission && subsubselection) {
            action = subsubselection.action;
            if (subsubselection.type == 'directSubmission') {
                isDisabled = false;
            }
            if (subsubselection.type == 'checkboxSubmission') {
                isDisabled = values.checkBoxConfirmation != true;
            }
        }

        return {
            showSubmitButton: !!action,
            action,
            isDisabled,
        };
    };

    const openPopup = useCallback((text: string, warningTextType?: WarningTextType) => {
        let content;

        if (warningTextType === WarningTextType.Pdf) {
            content = <PdfViewer fileURL={text} enableControls compact/>
        } else {
            content = <div dangerouslySetInnerHTML={{__html: text}}></div>
        }

        ModalService.getInstance()
            .setShowHeader(false)
            .setShowFooter(true)
            .setWidth('w-auto')
            .setBodyStyle({maxHeight: 'calc(100vh - 52px)'})
            .setHideCancelBtn(true)
            .setConfirmationLabel('Close')
            .setConfirmCallback(() => {
                ModalService.getInstance().hideModal();
            })
            .setComponent(content)
            .showModal();
    }, []);

    return (
        <>
            <h2 className="mb-4 text-2xl">Category of Investor</h2>
            <FormSelect
                name="investorCategory"
                label="Investor Category *"
                optionsData={investoryCategoryOptions}
            />

            {investorCategoryTypesOptions && (
                <FormRadioGroup
                    name="investorCategoryType"
                    label="Investor Category type *"
                    optionsData={investorCategoryTypesOptions}
                />
            )}

            {investorCategoryTypeCheckboxSubmission && (
                <div className="py-4">
                    <p className="pb-6 whitespace-pre-wrap">
                        {investorCategoryTypeCheckboxSubmission.text}
                    </p>
                    <FormCheckbox
                        name="checkBoxConfirmation"
                        actionLabel={<>
                            {investorCategoryTypeCheckboxSubmission.checkBox
                                .checkBoxText}&nbsp;{investorCategoryTypeCheckboxSubmission.checkBox
                                    .warningText && <span className='underline' onClick={(event) => {
                                        event.preventDefault();
                                        event.stopPropagation();
                                        openPopup(investorCategoryTypeCheckboxSubmission.checkBox
                                            .warningText, investorCategoryTypeCheckboxSubmission.checkBox
                                            .warningTextType)
                                    }}>{investorCategoryTypeCheckboxSubmission.checkBox
                                    .warningLabel}</span>}</>
                        }
                    />
                </div>
            )}

            {investorCategoryTypeSubcategoriesSubmission && (
                <div className="py-4">
                    <p className="pb-6 whitespace-pre-wrap">
                        {investorCategoryTypeSubcategoriesSubmission.text}
                    </p>
                    <FormRadioGroup
                        name="subInvestorCategoryType"
                        optionsData={
                            investorCategoryTypeSubcategoriesCheckboxSubmissionOptions
                        }
                    />
                    {subsubselection && (
                        <div className="pt-4">
                            <p className="pb-6 whitespace-pre-wrap">
                                {subsubselection.text}
                            </p>
                            {subsubselection.type == 'checkboxSubmission' && (
                                <FormCheckbox
                                    name="checkBoxConfirmation"
                                    actionLabel={
                                        subsubselection.checkBox.checkBoxText
                                    }
                                />
                            )}
                        </div>
                    )}
                </div>
            )}

            <div className="flex justify-between py-4">
                <Button
                    type="button"
                    label="Back"
                    buttonType="secondary"
                    onClick={(e) => {
                        e.preventDefault();
                        onBack();
                    }}
                />
                {getSubmitButton().showSubmitButton && (
                    <Button
                        type="submit"
                        label="Submit"
                        onClick={(e) => {
                            e.preventDefault();
                            handleSubmit(getSubmitButton().action);
                        }}
                        isLoading={isSubmitting}
                        disabled={getSubmitButton().isDisabled}
                    />
                )}
            </div>
        </>
    );
};

export default CategoryOfInvestor;
