import React, { FC, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';

import {
    authenticationVerifySetPasswordPost,
    authenticationChangePasswordPost,
} from '@api/Authentication';

import { useMountEffect } from '@hooks/useMountEffect';
import { regPatterns } from '@helpers/Password.helper';
import { notifyError, notifySuccess } from '@helpers/toastrHelper';

import PasswordCheckList from '@pages/Password/PasswordCheckList';

import { Button, Icons } from '@components/Atoms';
import { FormInputBox } from '@components/Molecules';

interface PasswordFormProps {
    passwordToken?: string;
    closeForm: () => void;
}

const EyeIconToggle: FC<{ show: boolean; onToggle: () => void }> = ({
    show,
    onToggle,
}) => {
    return (
        <Button
            hasIconOnly
            className="-mt-3"
            onClick={onToggle}
            startIcon={
                <Icons
                    stroke="lightgray"
                    name={show ? 'EyeSlashIcon' : 'EyeIcon'}
                />
            }
        />
    );
};

const PasswordForm: React.FC<PasswordFormProps> = (
    props: PasswordFormProps
) => {
    const navigate = useNavigate();
    const location = useLocation();

    const [showPassword, setShowPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);

    const [contains10C, setContains10C] = useState(false); // min 8 characters
    const [uppercaseRegex, setUppercaseRegex] = useState(false); // uppercase letter
    const [lowercaseRegex, setLowercaseRegex] = useState(false); // lowercase letter
    const [numericRegex, setNumericRegex] = useState(false); // number
    const [specialRegex, setSpecialRegex] = useState(false); // special

    useMountEffect(() => {
        if (!props.passwordToken && location.pathname === '/create-password') {
            navigate('/login');
        }
    });

    const validationSchema = Yup.object().shape({
        password: regPatterns.reduce((acc, { reg, msg }) => {
            acc.matches(reg, msg);
            return acc;
        }, Yup.string().trim().strict(true).required('Please enter a valid password')),

        confirmPassword: Yup.string()
            .required('Please enter the same password again')
            .when('password', {
                is: () => true,
                then: Yup.string()
                    .trim()
                    .oneOf(
                        [Yup.ref('password')],
                        'Both passwords need to be the same'
                    ),
            }),
    });

    const submitForm = async (val: {
        password: string;
        confirmPassword: string;
    }) => {
        if (val.password != val.confirmPassword) {
            notifyError('Passwords do not match');
            return;
        }

        try {
            if (props.passwordToken) {
                await authenticationVerifySetPasswordPost({
                    password: val.password,
                    passwordToken: props.passwordToken,
                });

                notifySuccess('Password created successfully');
                setTimeout(() => {
                    navigate('/login');
                }, 1000);
            } else {
                await authenticationChangePasswordPost({
                    password: val.password,
                    confirmPassword: val.confirmPassword,
                });
                notifySuccess('Password updated successfully');
                props.closeForm();
            }
        } catch (err: any) {
            if (err?.status === 406) {
                notifyError(err?.result?.message.join('\n'));
            } else {
                notifyError(
                    'An error has occured, please contact support if you need assistance in setting up the account'
                );
            }
        }
    };

    return (
        <Formik
            initialValues={{ password: '', confirmPassword: '' }}
            validationSchema={validationSchema}
            onSubmit={submitForm}
            validate={(values) => {
                setContains10C(values.password.length >= 10);
                setUppercaseRegex(regPatterns[1].reg.test(values.password));
                setLowercaseRegex(regPatterns[2].reg.test(values.password));
                setNumericRegex(regPatterns[3].reg.test(values.password));
                setSpecialRegex(regPatterns[4].reg.test(values.password));
            }}
        >
            {({ handleChange, isSubmitting, isValid }) => (
                <Form className="w-auto">
                    <FormInputBox
                        name="password"
                        placeholder="Password"
                        type={showPassword ? 'text' : 'password'}
                        onChange={(e: React.ChangeEvent<any>) => {
                            handleChange(e);
                        }}
                        rightIcon={
                            <EyeIconToggle
                                show={showPassword}
                                onToggle={() => {
                                    setShowPassword(!showPassword);
                                }}
                            />
                        }
                    />
                    <FormInputBox
                        name="confirmPassword"
                        placeholder="Confirm password"
                        type={showConfirmPassword ? 'text' : 'password'}
                        rightIcon={
                            <EyeIconToggle
                                show={showConfirmPassword}
                                onToggle={() => {
                                    setShowConfirmPassword(
                                        !showConfirmPassword
                                    );
                                }}
                            />
                        }
                    />
                    <div className="border border-slate-300 bg-white rounded-md p-3 text-xs">
                        <PasswordCheckList
                            regPatterns={regPatterns}
                            contains10C={contains10C}
                            uppercaseRegex={uppercaseRegex}
                            lowercaseRegex={lowercaseRegex}
                            numericRegex={numericRegex}
                            specialRegex={specialRegex}
                        />
                    </div>
                    {props.passwordToken ? (
                        <Button
                            type="submit"
                            label="Set password"
                            className="w-full mt-2"
                            disabled={isSubmitting || !isValid}
                        />
                    ) : (
                        <div className="p-2 flex justify-between">
                            <Button
                                buttonType="secondary"
                                onClick={props.closeForm}
                                label="Cancel"
                            ></Button>
                            <Button
                                type="submit"
                                disabled={isSubmitting || !isValid}
                                label="Change password"
                            ></Button>
                        </div>
                    )}
                </Form>
            )}
        </Formik>
    );
};

export default PasswordForm;
