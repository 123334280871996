// ProgressBar component is used to display the progress of a task.
// It takes a progress prop which is a number between 0 and 100.
// Styles done using tailwindcss

import React from 'react';

interface ProgressBarProps {
    progress: number;
}

const ProgressBar: React.FC<ProgressBarProps> = ({ progress }) => {
    return (
        <div className="w-full h-2 bg-gray-200 rounded overflow-hidden">
            <div
                className="h-full bg-blue-500"
                style={{ width: `${progress}%` }}
            ></div>
        </div>
    );
};

export default ProgressBar;
