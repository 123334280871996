import { IntermediaryResponse } from '@interfaces/Api';
import { Http } from '@libs/http';
import { BaseUrl } from '@helpers/Environment';
import { notifyError, notifySuccess } from '@helpers/toastrHelper';

import { IntermediariesStore } from './Intermediaries.store';

const URL = `${BaseUrl}/intermediary`;

export const handleFetchIntermediaries = async (set) => {
    try {
        set({ isLoading: true });
        const intermediaries: IntermediaryResponse[] = await Http.get(URL);
        set({ intermediaries }, false, 'fetchIntermediaries');
    } catch (_) {
        notifyError('An error has occured, distributors have not been loaded');
    }

    set({ isLoading: false });
};

export const handleAddIntermediary = async (set, intermediary) => {
    try {
        const response: IntermediaryResponse = await Http.post(
            URL,
            intermediary
        );

        set(
            (state: IntermediariesStore) => ({
                intermediaries: [...state.intermediaries, response],
            }),
            false,
            'addIntermediary'
        );

        notifySuccess(
            'Distributor added successfully, now creating the user',
            3000
        );
    } catch (_) {
        notifyError(
            'An error has occured, a new distributor has not been added'
        );
    }
};
