import { Icons } from '../Icons';
import ReactTooltip from 'react-tooltip';

interface TooltipProps {
    tip: string;
    
}

const Tooltip: React.FC<TooltipProps> = (props) => {
    return (
        <div data-tip={props.tip}>
            {props.children || (
                <Icons name="InformationCircleIcon" strokeWidth=".2"></Icons>
            )}
            <ReactTooltip />
        </div>
    );
};

export default Tooltip;
