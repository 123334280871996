import { InvestorJurisdictionsResponse } from '@interfaces/Api';
import { Http } from './../libs/http';
import { BaseUrl } from '../Helpers/Environment';

const API_INVESTORJURISDICTIONS_URL = `${BaseUrl}/investor-jurisdictions`;

export const InvestorJurisdictionsGetAll = async () => {
    return Http.get<InvestorJurisdictionsResponse>(
        API_INVESTORJURISDICTIONS_URL
    );
};
