import React from 'react';

import { EndInvestorProfileResponse, ProposalResponse } from '@interfaces/Api';
import SubscriptionProposalsItemElem from './SubscriptionProposalsItemElem';

export interface SubscriptionProposalsListItem {
    proposal: ProposalResponse;
    endInvestorProfile: EndInvestorProfileResponse;
}

interface SubscriptionProposalsProps {
    subscriptionProposalsListItems: SubscriptionProposalsListItem[];
    onProposalUpdate?(): void;
}

const SubscriptionProposals: React.FC<SubscriptionProposalsProps> = (props) => (
    <>
        {props?.subscriptionProposalsListItems?.length > 0 ? (
            props.subscriptionProposalsListItems.map((val) => (
                <SubscriptionProposalsItemElem
                    key={val.proposal._id}
                    proposal={val.proposal}
                    endInvestorProfile={val.endInvestorProfile}
                    onProposalUpdate={props.onProposalUpdate}
                />
            ))
        ) : (
            <p>There are currently no proposals.</p>
        )}
    </>
);

export default SubscriptionProposals;
