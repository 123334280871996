import {
    EndInvestorProfileResponse,
    Preferences,
    ProductResponse,
    SubscriptionResponse,
    SubscriptionTemplateResponse,
    SubscriptionStatusEnum,
    SigningDocumentStatusEnum,
    ConfigurationResponse,
    SigningDocumentTypeEnum,
    LiquidityTypeEnum,
    AuthPermissionEnum,
} from '@interfaces/Api';
import { VPRiskWarning } from '@components/Organisms';

import { ModalService } from './ModalService';
import { isEndInvestorNomineeAccount } from '@helpers/EndInvestorHelper';
import dayjs from 'dayjs';
import { SubscriptionListItem } from '@containers/Subscriptions/SubscriptionList';
import { NavigateFunction } from 'react-router';

export interface SubscriptionStep {
    endPathUrl: string;
    stepName: string;
    isRequired?: boolean;
    isValid?: boolean;
    showStep: boolean;
    canClick?: boolean;
}

export const getFirstStepSubscriptionTemplate = (
    t: (key: string) => string,
    endInvestorProfile: EndInvestorProfileResponse
) => {
    const steps = getSubscriptionTemplateSteps(t, endInvestorProfile);
    const endPathUrl = steps?.[0]?.endPathUrl;
    return endPathUrl;
};

export const getSubscriptionSteps = (
    t: (key: string) => string,
    endInvestorProfile?: EndInvestorProfileResponse,
    subscription?: SubscriptionResponse,
    feature?: Preferences['feature'],
    product?: ProductResponse
): SubscriptionStep[] => {
    if (!endInvestorProfile) {
        return [];
    }

    const repeatedOpenEndedSubscription =
        product?.liquidityType === LiquidityTypeEnum.openEnded &&
        subscription?.parentSubscriptionId;

    const steps = [
        {
            endPathUrl: 'investment-details',
            stepName: t('subscriptions.add.sidebar.investment_details'),
            isRequired: true,
            isValid: !!subscription?.subscriptionAmount,
            showStep: true,
        },
        {
            endPathUrl: 'german-semi-professional-questionnaire',
            stepName: t(
                'subscriptions.add.sidebar.german_semi_pro_questionnaire'
            ),
            isRequired: true,
            isValid: !!subscription?.germanSemiProfessionalCategorisation,
            showStep:
                endInvestorProfile?.onboardingJurisdictionCountryCode ===
                    'DE' &&
                endInvestorProfile?.clientType === 'semiProfessional',
        },
        // Leave the below 'Opt-Up Questionnaire' step in place for now
        {
            endPathUrl: 'opt-up-questionnaire',
            stepName: t('subscriptions.add.sidebar.opt_up_questionnaire'),
            isRequired: false,
            isValid: false,
            showStep: false,
        },
        {
            endPathUrl: 'distributor-details',
            stepName: t('subscriptions.add.sidebar.distributor_details'),
            isRequired: true,
            isValid: !!subscription?.distributorDetails?.distributorName,
            showStep:
                !isEndInvestorNomineeAccount(endInvestorProfile) &&
                !repeatedOpenEndedSubscription,
        },
        {
            endPathUrl: 'source-of-funds-and-wealth',
            stepName: t('subscriptions.add.sidebar.source_of_funds'),
            isRequired: true,
            isValid: !!(
                subscription?.sourceOfFundsAndWealth?.describeSourceOfFunds &&
                subscription?.sourceOfFundsAndWealth?.describeSourceOfWealth
            ),
            showStep: !repeatedOpenEndedSubscription,
        },
        {
            endPathUrl: 'banking-preferences',
            stepName: t('subscriptions.add.sidebar.banking_preferences'),
            isRequired: true,
            isValid: !!subscription?.distributionOfFunds,
            showStep: !repeatedOpenEndedSubscription,
        },
        {
            endPathUrl: 'beneficial-owners',
            stepName: t('subscriptions.add.sidebar.beneficial_owners'),
            isRequired: true,
            isValid: !!(
                subscription?.beneficialOwnership &&
                subscription?.beneficialOwnership?.ownershipConfirmation
            ),
            showStep:
                !!feature?.subscriptionProcess?.enableBeneficiaryOwnerSection &&
                !repeatedOpenEndedSubscription,
        },
        {
            endPathUrl: 'politically-exposed-people',
            stepName: t('subscriptions.add.sidebar.politically_exposed_people'),
            isRequired: true,
            isValid: !!subscription?.politicalExposure,
            showStep: !repeatedOpenEndedSubscription,
        },
        {
            endPathUrl: 'subscription-contacts',
            stepName: t('subscriptions.add.sidebar.subscription_contacts'),
            isRequired: false,
            isValid: !!(
                subscription?.contacts && subscription.contacts.length > 0
            ),
            showStep: !repeatedOpenEndedSubscription,
        },
        {
            endPathUrl: 'reporting',
            stepName: t('subscriptions.add.subscription_report.add.title'),
            isRequired: false,
            isValid: !!subscription.report,
            showStep: true,
        },
        {
            endPathUrl: 'tax-declarations',
            stepName: t('subscriptions.add.sidebar.tax_declarations'),
            isRequired: true,
            isValid: !!subscription?.taxInformation,
            showStep:
                !!feature?.subscriptionProcess?.taxSection &&
                !repeatedOpenEndedSubscription,
        },
        {
            endPathUrl: 'supporting-documents',
            stepName: t('subscriptions.add.sidebar.supporting_documents'),
            isRequired: false,
            isValid: false,
            showStep: true,
        },
        {
            endPathUrl: 'attestations',
            stepName: t('subscriptions.add.sidebar.attestations'),
            isRequired: true,
            isValid: !!(
                (subscription?.attestations?.understandsEnglish &&
                    subscription?.attestations?.readAllDocuments &&
                    subscription?.attestations?.agreedElectronicDelivery) ||
                (subscription?.attestations?.hasReviewedProspectus &&
                    subscription.attestations.requestNotGuaranteed)
            ),
            showStep: true,
        },
        {
            endPathUrl: 'review',
            stepName: t('subscriptions.add.sidebar.review'),
            isRequired: true,
            isValid:
                subscription?.status === SubscriptionStatusEnum.approved ||
                subscription?.status ===
                    SubscriptionStatusEnum.readyForReview ||
                subscription?.status === SubscriptionStatusEnum.rejected,
            showStep: true,
        },
        {
            endPathUrl: 'documents',
            stepName: t('subscriptions.add.sidebar.documents'),
            isRequired: false,
            isValid: !!(subscription
                ? hasSignedSubscriptionDocument(subscription)
                : false),
            showStep:
                subscription?.status !== SubscriptionStatusEnum.inProgress,
        },
    ].filter((a) => a.showStep);

    return steps;
};

const hasSignedSubscriptionDocument = (subscription: SubscriptionResponse) => {
    const signedDocuments = subscription?.signingDocuments?.filter(
        (document) =>
            document.status === SigningDocumentStatusEnum.signed &&
            document.signedDocument
    );
    return signedDocuments ? signedDocuments?.length > 0 : false;
};

export const getSubscriptionTemplateSteps = (
    t: (key: string) => string,
    endInvestorProfile?: EndInvestorProfileResponse,
    subscriptionTemplate?: SubscriptionTemplateResponse,
    feature?: Preferences['feature']
): SubscriptionStep[] => {
    if (!endInvestorProfile) {
        return [];
    }

    const steps = [
        {
            endPathUrl: 'template-details',
            stepName: t('subscription.add.details.title'),
            showStep: true,
            isValid: !!subscriptionTemplate?.templateName,
        },
        {
            endPathUrl: 'distributor-details',
            stepName: t('subscriptions.add.sidebar.distributor_details'),
            showStep: !isEndInvestorNomineeAccount(endInvestorProfile),
            isValid:
                !!subscriptionTemplate?.distributorDetails?.distributorName,
        },
        {
            endPathUrl: 'source-of-funds-and-wealth',
            stepName: t('subscriptions.add.sidebar.source_of_funds'),
            showStep: true,
            isValid: !!(
                subscriptionTemplate?.sourceOfFundsAndWealth
                    ?.describeSourceOfFunds &&
                subscriptionTemplate?.sourceOfFundsAndWealth
                    ?.describeSourceOfWealth
            ),
        },
        {
            endPathUrl: 'banking-preferences',
            stepName: t('subscriptions.add.sidebar.banking_preferences'),
            showStep: true,
            isValid: !!subscriptionTemplate?.distributionOfFunds,
        },
        {
            endPathUrl: 'beneficial-owners',
            stepName: t('subscriptions.add.sidebar.beneficial_owners'),
            showStep:
                !!feature?.subscriptionProcess?.enableBeneficiaryOwnerSection,
            isValid: !!(
                subscriptionTemplate?.beneficialOwnership &&
                subscriptionTemplate?.beneficialOwnership?.ownershipConfirmation
            ),
        },
        {
            endPathUrl: 'politically-exposed-people',
            stepName: t('subscriptions.add.sidebar.politically_exposed_people'),
            showStep: true,
            isValid: !!subscriptionTemplate?.politicalExposure,
        },
        {
            endPathUrl: 'subscription-contacts',
            stepName: t('subscriptions.add.sidebar.subscription_contacts'),
            showStep: true,
            isValid: !!(
                subscriptionTemplate?.contacts &&
                subscriptionTemplate?.contacts.length > 0
            ),
        },
        {
            endPathUrl: 'tax-declarations',
            stepName: t('subscriptions.add.sidebar.tax_declarations'),
            showStep: true,
            isValid: !!subscriptionTemplate?.taxInformation,
        },
    ].filter((a) => a.showStep);

    return steps;
};

export const handleCreateSubscriptionEvent = async (
    t: (key: string) => string,
    navigate: NavigateFunction,
    {
        acceptRiskBeforeCreatingSubscription,
        productId,
        endInvestorProfileId,
        subscriptionTemplateId,
    }: {
        endInvestorProfileId: string;
        acceptRiskBeforeCreatingSubscription?: boolean;
        productId?: string;
        subscriptionTemplateId?: string;
    }
) => {
    const navigateToNewSubscription = async () => {
        const queryParams = [
            endInvestorProfileId &&
                `endInvestorProfileId=${endInvestorProfileId}`,
            //TODO not sure if we want to put this here
            acceptRiskBeforeCreatingSubscription && `acceptRisk=true`,
            productId && `productId=${productId}`,
            subscriptionTemplateId &&
                `subscriptionTemplateId=${subscriptionTemplateId}`,
        ].filter((a) => a);

        const url = ['/subscriptions/create', queryParams.join('&')].join('?');

        navigate(url, {});
    };

    if (acceptRiskBeforeCreatingSubscription) {
        ModalService.getInstance()
            .setTitle(t('Risk Warning'))
            .setComponent(
                <VPRiskWarning
                    onAbort={() => {
                        ModalService.getInstance().hideModal();
                    }}
                    onAccept={async () => {
                        await navigateToNewSubscription();
                        ModalService.getInstance().hideModal();
                    }}
                />
            )
            .showModal();
    } else {
        await navigateToNewSubscription();
    }
};

export const placementFeeAppliesToSubscription = (
    endInvestorProfile: EndInvestorProfileResponse,
    configuration: ConfigurationResponse
): boolean => {
    // Placement fee is applicable only if it is enabled and if the investor is not a nominee entity - https://s64capital.atlassian.net/browse/AF-773

    const applies =
        !!configuration?.preferences?.feature?.subscriptionProcess
            ?.placementFeeApplies &&
        endInvestorProfile.endInvestorType !==
            EndInvestorProfileResponse.EndInvestorType.nomineeEntity;

    return applies;
};

export const canCancelSubscription = (
    subscription: SubscriptionResponse,
    product: Partial<ProductResponse>,
    hasPermissions: (permissions: AuthPermissionEnum[]) => boolean
): boolean => {
    if (product.liquidityType == LiquidityTypeEnum.openEnded) {
        return false;
    }

    if (
        subscription.status === SubscriptionStatusEnum.readyForReview &&
        hasPermissions([
            AuthPermissionEnum.updateSubscriptionStatusFromReadyForReviewToCancelled,
        ])
    ) {
        return true;
    }

    // check if subscription has been approved and is still in the product cooling off period
    if (
        product.liquidityType == LiquidityTypeEnum.closedEnded &&
        subscription.status === SubscriptionStatusEnum.approved &&
        hasPermissions([
            AuthPermissionEnum.updateSubscriptionStatusFromApprovedToCancelled,
        ])
    ) {
        const subscriptionApprovedDate = new Date(
            subscription.statusLogs[
                subscription.statusLogs.length - 1
            ].createdAt
        );

        const canCancelApproved =
            // subscription is within cooling off period
            dayjs(subscriptionApprovedDate)
                .add(
                    product.closedEndedInformation?.coolingOffDays || 0,
                    'days'
                )
                .isAfter(new Date());

        return canCancelApproved;
    }

    return false;
};

export const doesDocumentTypeExist = (
    subscription: SubscriptionResponse,
    signingDocumentType: SigningDocumentTypeEnum
) =>
    !!subscription.signingDocuments?.find(
        (document) => document.signingDocumentType === signingDocumentType
    );

export const isDocumentSigned = (
    subscription: SubscriptionResponse | SubscriptionListItem,
    signingDocumentType: SigningDocumentTypeEnum
) =>
    subscription.signingDocuments?.find(
        (document) => document.signingDocumentType === signingDocumentType
    )?.status === 'signed';

export const inducementFeeApplies = (
    subscription: SubscriptionResponse
): boolean => {
    return subscription.status == SubscriptionStatusEnum.approved;
};
