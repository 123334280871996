import { EffectCallback, useEffect } from 'react';

/**
 *
 * @param method method which should only be run once
 * @returns a hook which can be used to run only once at the
 * beginning of a component's lifecycle
 */

// eslint-disable-next-line react-hooks/exhaustive-deps
export const useMountEffect = (method: EffectCallback) => useEffect(method, []);
