import { ProspectiveInvestorsGetAll } from '@api/ProspectiveInvestors';
import { useAuthState } from '@contexts/AuthContext';
import { useMountEffect } from '@hooks/useMountEffect';
import { ProspectiveInvestorResponse } from '@interfaces/Api/ProspectiveInvestorResponse';
import { useState } from 'react';
import InvestorDetailsSection from './InvestorDetailsSection';
import PersonalDetailsSection from './PersonalDetailsSection';
import { mainLayoutWithBreadcrumbsConfig } from '@components/Layout';

const ProspectiveInvestorProfilePage = () => {
    const { currentUser } = useAuthState();

    const [prospectiveInvestor, setProspectiveInvestor] =
        useState<ProspectiveInvestorResponse>();

    useMountEffect(() => {
        const systemUserId = currentUser.user._id;
        ProspectiveInvestorsGetAll().then((investors) => {
            const investor = investors.find(
                (a) => a.systemUserId == systemUserId
            );
            setProspectiveInvestor(investor);
        });
    });

    return (
        <div>
            <PersonalDetailsSection prospectiveInvestor={prospectiveInvestor} />
            <InvestorDetailsSection prospectiveInvestor={prospectiveInvestor} />
        </div>
    );
};

export default mainLayoutWithBreadcrumbsConfig(
    ProspectiveInvestorProfilePage,
    [],
    undefined
);
