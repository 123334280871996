import { useTranslation } from 'react-i18next';
import { AssetClassTypeLabel } from '@interfaces/Api';
import { AssetClassTypeEnum, getAssetClassTypeLabels } from '@interfaces/Api/ProductResponse';

interface ProductLargeThumbnailProps {
    productName?: string;
    productThumbnailUrl?: string;
    shareClassName?: string;
    shareClassType?: string;
    assetClassType?: AssetClassTypeEnum;
}

const ProductLargeThumbnail: React.FC<ProductLargeThumbnailProps> = ({
    productName,
    productThumbnailUrl,
    shareClassName,
    shareClassType,
    assetClassType,
}) => {
    const { t } = useTranslation();
    return (
        <div className="bg-logo-blue rounded-xl">
            <img
                src={productThumbnailUrl}
                alt={productName}
                className="h-52 rounded-xl w-full object-cover"
            />

            <div className="p-4">
                <p className="text-2xl text-white capitalize pb-1">
                    {productName}
                </p>
                {shareClassName && (
                    <>
                        <div className="flex py-1">
                            <div className="flex-2 xl:flex-1 pr-1">
                                <p className="text-base text-brand-navy">
                                    <strong>{t('subscriptions.add.investment_details.share_class')}</strong>
                                </p>
                            </div>
                            <div className="flex-2 lg:flex-3">
                                <p className="text-base text-white capitalize">{`${shareClassName} ${shareClassType}`}</p>
                            </div>
                        </div>
                    </>
                )}
                {assetClassType && (
                    <div className="flex py-1">
                        <div className="flex-2 xl:flex-1 pr-1">
                            <p className="text-base text-brand-navy">
                                <strong>{t('subscriptions.add.investment_details.asset_class_type')}</strong>
                            </p>
                        </div>
                        <div className="flex-2 lg:flex-3">
                            <p className="text-base text-white capitalize">
                                {getAssetClassTypeLabels(t, assetClassType)}
                            </p>
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
};

export default ProductLargeThumbnail;
