import { useEffect } from 'react';
import { useSubscriptionsActions, useSubscriptionsState } from './';

export const useSubscriptions = (endInvestorProfileId?: string) => {
    const { subscriptions, isLoading } = useSubscriptionsState();
    const { fetchSubscriptions, resetSubscriptions } =
        useSubscriptionsActions();

    useEffect(() => {
        const loadData = async () => {
            await fetchSubscriptions(endInvestorProfileId);
        };

        loadData();

        return () => {
            resetSubscriptions();
        };
    }, [endInvestorProfileId]);

    return { isLoading, subscriptions };
};
