import toast from 'react-hot-toast';

export const notifySuccess = (msg: string, duration?: number) => {
    toast.dismiss();
    
    if (!duration) duration = (msg.length * 50)

    toast.success(msg, {
        style: {
            border: '1px solid rgba(1, 16, 49, 1)',
            padding: '16px',
            color: 'rgba(1, 16, 49, 1)',
        },
        iconTheme: {
            primary: 'rgba(1, 16, 49, 1)',
            secondary: '#FFFAEE',
        },
         duration ,
    });
};

export const notifyError = (msg: string, duration?: number) =>
{
    if (!duration) duration = (msg.length * 50)
    toast.error(msg, {
        style: {
            border: '1px solid rgba(1, 16, 49, 1)',
            padding: '16px',
            color: 'red',
        },
        iconTheme: {
            primary: 'rgba(1, 16, 49, 1)',
            secondary: '#FFFAEE',
        },
        duration
    });
}
