
import { useTranslation } from 'react-i18next';
import { SelectBox, Icons } from '@components/Atoms';
import { FormFieldWrapper } from '@components/Molecules';
import { FilterType, SortType } from '../Dashboard.helper';

interface DashboardDataControlsProps {
    setFilteredBy: (value: FilterType) => void;
    setSortedBy: (value: SortType) => void;
    filterByList: { label: string; value: FilterType }[];
    sortByList: { label: string; value: SortType }[];
}

export const DashboardDataControls = ({
    setFilteredBy,
    filterByList,
    setSortedBy,
    sortByList,
}: DashboardDataControlsProps) => {
    const { t } = useTranslation();

    return (
        <section className="flex justify-between">
            <div className="relative mb-8 w-48">
                <FormFieldWrapper
                    label={t('client.portfolio.filter_by_liquidity')}
                    rightIcon={<Icons name="ChevronDown" size="small" />}
                >
                    <SelectBox
                        name="funds types"
                        optionsData={filterByList}
                        onChange={({ target: { value } }) =>
                            setFilteredBy(value as FilterType)
                        }
                        showDefault={false}
                    />
                </FormFieldWrapper>
            </div>

            <div className="relative mb-8 w-48">
                <FormFieldWrapper
                    label={t('ui.controls.sort_by')}
                    rightIcon={<Icons name="ChevronDown" size="small" />}
                >
                    <SelectBox
                        name="funds types"
                        optionsData={sortByList}
                        onChange={({ target: { value } }) =>
                            setSortedBy(value as SortType)
                        }
                    />
                </FormFieldWrapper>
            </div>
        </section>

    );
};
