import { create } from 'zustand';
import { devtools } from 'zustand/middleware';
import {
    CreateBookingCentreRequestBody,
    BookingCentreResponse,
} from '@interfaces/Api';
import {
    handleAddBookingCentre,
    handleDeleteBookingCentre,
    handleFetchBookingCentres,
} from './BookingCentres.services';

export interface BookingCentresStore {
    bookingCentres: BookingCentreResponse[];
    isLoading: boolean;
    actions: {
        fetchBookingCentres: (intermediaryId: string) => Promise<void>;
        addBookingCentre: (
            bookingCentre: CreateBookingCentreRequestBody
        ) => Promise<BookingCentreResponse | void>;
        deleteBookingCentre: (bookingCentreId: string) => Promise<void>;
    };
}

export const useBookingCentresStore = create<BookingCentresStore>()(
    devtools(
        (set): BookingCentresStore => ({
            bookingCentres: [],
            isLoading: false,
            actions: {
                fetchBookingCentres: async (intermediaryId: string) => {
                    await handleFetchBookingCentres(set, intermediaryId);
                },
                addBookingCentre: async (
                    bookingCentre: CreateBookingCentreRequestBody
                ) => {
                    await handleAddBookingCentre(set, bookingCentre);
                },
                deleteBookingCentre: async (bookingCentreId: string) => {
                    await handleDeleteBookingCentre(set, bookingCentreId);
                },
            },
        })
    )
);
