import { useEffect, useState } from 'react';
import { Await, useParams } from 'react-router-dom';
import PersonalDetailsSection from '../ProspectiveInvestorProfilePage/PersonalDetailsSection';
import InvestorDetailsSection from '../ProspectiveInvestorProfilePage/InvestorDetailsSection';
import {
    ProspectiveInvestorsGetById,
    ProspectiveInvestorsPutConvert,
    ProspectiveInvestorsPutStatus,
} from '@api/ProspectiveInvestors';
import {
    ProspectiveInvestorResponse,
    ProspectiveInvestorStatusEnum,
} from '@interfaces/Api/ProspectiveInvestorResponse';
import { BasicBreadcrumbs, Button } from '@components/Atoms';
import { mainLayoutWithBreadcrumbsConfig } from '@components/Layout';
import SuitabilityQuestionnaireSection from '../ProspectiveInvestorProfilePage/SuitabilityQuestionnaireSection';
import {
    SuitabilityQuestionnaireGetByProspectiveInvestorId,
    SuitabilityQuestionnaireResponseBody,
} from '@api/SuitabilityQuestionnaire';
import { AlertBox, AlertBoxType } from '@components/Molecules';
import AwaitingManualReviewSection from './AwaitingManualReviewSection';
import { notifyError, notifySuccess } from '@helpers/toastrHelper';
import ManualReviewSection from './ManualReviewSection';

const getAlertBox = (
    prospectiveInvestor: ProspectiveInvestorResponse
): {
    title: string;
    message: string | React.ReactNode;
    alertType: AlertBoxType;
} => {
    switch (prospectiveInvestor?.status) {
        case ProspectiveInvestorStatusEnum.approved: {
            return {
                title: 'Approved',
                message: (
                    <div className="flex flex-row justify-between">
                        <div>
                            The investor has been approved to invest on the
                            platform
                        </div>
                        {!prospectiveInvestor.endInvestorProfileId && (
                            <div>
                                <Button
                                    label="Allow Investor to Invest"
                                    onClick={() => {
                                        // TODO: just convert the
                                        ProspectiveInvestorsPutConvert(
                                            prospectiveInvestor._id
                                        ).then(() => {
                                            notifySuccess(
                                                'The user can now invest on the platform'
                                            );
                                        });
                                    }}
                                />
                            </div>
                        )}
                    </div>
                ),
                alertType: 'success',
            };
        }
        case ProspectiveInvestorStatusEnum.awaitingReview:
            return {
                title: 'Requires Manual Review',
                message:
                    'The investor requires manual review before they can invest on the platform. Please review the investor details and suitability questionnaire below.',
                alertType: 'warning',
            };
        case ProspectiveInvestorStatusEnum.awaitingAssessment:
            return {
                title: 'Pending',
                message:
                    'The investor needs to complete their suitability assessment to invest on the platform.',
                alertType: 'warning',
            };
        case ProspectiveInvestorStatusEnum.rejected:
            return {
                title: 'Rejected',
                message:
                    'The investor has been rejected to invest in the platform',
                alertType: 'error',
            };
        default:
            return {
                title: 'Unknown',
                message: 'The investor status is unknown',
                alertType: 'error',
            };
    }
};

const ProspectiveInvestorViewPage = () => {
    const params = useParams();
    const prospectiveInvestorId = params['prospectiveInvestorId'];

    const [prospectiveInvestor, setProspectiveInvestor] =
        useState<ProspectiveInvestorResponse>();
    const [suitabilityQuestionnaire, setSuitabilityQuestionnaire] =
        useState<SuitabilityQuestionnaireResponseBody>();

    useEffect(() => {
        if (prospectiveInvestorId) {
            ProspectiveInvestorsGetById(prospectiveInvestorId).then(
                (investor) => {
                    setProspectiveInvestor(investor);
                    console.log(
                        '[ProductProspectiveInvestorAlert] prospectiveInvestor',
                        prospectiveInvestor
                    );
                }
            );
        }
    }, [prospectiveInvestorId]);

    useEffect(() => {
        if (
            prospectiveInvestor?._id &&
            prospectiveInvestor?.requiresSuitabilityAssessment
        ) {
            SuitabilityQuestionnaireGetByProspectiveInvestorId(
                prospectiveInvestor._id
            ).then((suitabilityQuestionnaire) => {
                setSuitabilityQuestionnaire(suitabilityQuestionnaire[0]);
            });
        }
    }, [prospectiveInvestor]);

    const updateProspectiveInvestorStatus = async (
        status: ProspectiveInvestorStatusEnum,
        reason: string
    ) => {
        try {
            const updatedInvestor = await ProspectiveInvestorsPutStatus(
                prospectiveInvestor._id,
                {
                    status: status,
                    assessmentReason: reason,
                }
            );
            notifySuccess('Success');

            setProspectiveInvestor(updatedInvestor);
        } catch (e) {
            notifyError('There is an error here');
        }
    };

    const alertBoxInformation =
        prospectiveInvestor && getAlertBox(prospectiveInvestor);

    const showAwaitingManualReviewSection =
        prospectiveInvestor?.status ===
        ProspectiveInvestorStatusEnum.awaitingReview;

    return (
        <div>
            {/* Show user status, passed, pending or rejected */}
            {alertBoxInformation && (
                <AlertBox
                    title={alertBoxInformation.title}
                    message={alertBoxInformation.message}
                    alertType={alertBoxInformation.alertType}
                />
            )}

            <PersonalDetailsSection prospectiveInvestor={prospectiveInvestor} />
            <InvestorDetailsSection prospectiveInvestor={prospectiveInvestor} />
            {suitabilityQuestionnaire && (
                <SuitabilityQuestionnaireSection
                    prospectiveInvestor={prospectiveInvestor}
                    suitabilityQuestionnaire={suitabilityQuestionnaire}
                />
            )}
            {/* TODO: show section that allows an admin to submit whether or not to accept application or not */}
            <div className="mb-12">
                {showAwaitingManualReviewSection && (
                    <AwaitingManualReviewSection
                        prospectiveInvestor={prospectiveInvestor}
                        suitabilityQuestionnaire={suitabilityQuestionnaire}
                        onUpdateProspectiveInvestorStatus={
                            updateProspectiveInvestorStatus
                        }
                    />
                )}

                {prospectiveInvestor?.assessmentReason && (
                    <ManualReviewSection
                        prospectiveInvestor={prospectiveInvestor}
                    />
                )}
            </div>
        </div>
    );
};

export const ProspectiveInvestorViewPageBreadcrumbs: React.FC = () => {
    const params = useParams();
    const prospectiveInvestorId = params['prospectiveInvestorId'];

    const [prospectiveInvestor, setProspectiveInvestor] =
        useState<ProspectiveInvestorResponse>();

    useEffect(() => {
        if (prospectiveInvestorId) {
            ProspectiveInvestorsGetById(prospectiveInvestorId).then(
                (investor) => {
                    setProspectiveInvestor(investor);
                }
            );
        }
    }, [prospectiveInvestorId]);

    const name = [
        prospectiveInvestor?.personalDetails?.firstName,
        prospectiveInvestor?.personalDetails?.surname,
    ]
        .filter((a) => a)
        .join(' ');

    const items = [
        {
            displayText: 'Prospective Investors',
            navigationUrl: '/admin/prospective-investors',
        },
        {
            displayText: name,
            navigationUrl: `/admin/prospective-investors/${prospectiveInvestorId}`,
        },
    ];

    return <BasicBreadcrumbs items={items} />;
};

export default mainLayoutWithBreadcrumbsConfig(
    ProspectiveInvestorViewPage,
    undefined,
    <ProspectiveInvestorViewPageBreadcrumbs />
);
