import { useFormikContext } from 'formik';

import { EndInvestorProfileResponse, getSubscriptionProcessTypeLabels } from '@interfaces/Api';
import { RadioOption } from '@interfaces/InterfaceFormsProps';

import { FormRadioGroup } from '@components/Molecules';

import getClientRegistrationFormFields from '../ClientRegistrationFormFields';
import { useRegisterInvestorState } from '../RegisterInvestor.context';
import { useTranslation } from 'react-i18next';


export const SubscriptionProcessSelection = () => {
    const { t } = useTranslation();
    const subscriptionProcessTypeRadioOptions: RadioOption<string>[] = [
        {
            label: getSubscriptionProcessTypeLabels(t, EndInvestorProfileResponse.SubscriptionProcessTypeEnum.Direct),
            value: EndInvestorProfileResponse.SubscriptionProcessTypeEnum.Direct,
            abstract: t('client.registration.subscription_process.signed_by_investor'),
        },
        {
            label: getSubscriptionProcessTypeLabels(t, EndInvestorProfileResponse.SubscriptionProcessTypeEnum.Nominee),
            value: EndInvestorProfileResponse.SubscriptionProcessTypeEnum.Nominee,
            abstract: t('client.registration.subscription_process.investor_beneficial_owner'),
        },
    ];
    const { handleChange } = useFormikContext<any>();

    const { data, setData } = useRegisterInvestorState();

    return (
        <>
            <h2 className="pt-7 py-3 text-2xl">{t('client.registration.summary.subscription_process')}</h2>

            <FormRadioGroup
                name={getClientRegistrationFormFields(t).subscriptionProcessType.name}
                disabled={
                    data.serviceLevelType ===
                    EndInvestorProfileResponse.ServiceLevelTypeEnum
                        .ExecutionOnly
                }
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    handleChange(e);

                    if (
                        e.target.value ===
                        EndInvestorProfileResponse.SubscriptionProcessTypeEnum
                            .Direct
                    ) {
                        setData({
                            ...data,
                            nomineeEndInvestorProfileId: undefined,
                        });
                    }
                }}
                optionsData={subscriptionProcessTypeRadioOptions}
            />
        </>
    );
};
