import { useIntermediaries } from '@stores/Intermediaries/useIntermediaries';
import { Icons } from '@components/Atoms';
import { FormSelect } from '@components/Molecules';
import getClientRegistrationFormFields from '../ClientRegistrationFormFields';
import { useTranslation } from 'react-i18next';

export const IntermediariesSelection = () => {
    const { t } = useTranslation();
    const { intermediaries, isLoading } = useIntermediaries();

    if (isLoading) {
        return <Icons name="Loading" size="small" />;
    }

    return (
        <>
            <h2 className="mb-4 text-2xl">{t('client.registration.intermediary.title')}</h2>

            {intermediaries.length > 0 && (
                <div className="relative w-full mb-3 cursor-default">
                    <FormSelect
                        label={
                            getClientRegistrationFormFields(t).intermediaryId.label
                        }
                        name={getClientRegistrationFormFields(t).intermediaryId.name}
                        optionsData={intermediaries.map((intermediary) => ({
                            label: intermediary.name,
                            value: intermediary._id,
                        }))}
                    />
                </div>
            )}
        </>
    );
};
