import React, { lazy, Suspense, useEffect, useState } from 'react';
import { Form, Formik, Field, ErrorMessage } from 'formik';
import { useParams } from 'react-router-dom';
import * as Yup from 'yup';
const MDEditor = lazy(() => import('@uiw/react-md-editor'));

import EditProductFormikButtons from '@pages/Admin/ProductsManagement/Components/EditProductFormikButtons';

import { useProductSubmit } from '@hooks/useProductSubmit';

import { ProductResponse } from '@interfaces/Api';
import { useProductsState } from '@stores/Products';

const ProductDescription: React.FC = () => {
    const [formValue, setFormValue] = useState<Partial<ProductResponse>>({
        descriptionMarkdown: '',
    });

    const { productId } = useParams();
    const { products } = useProductsState();
    const product = products.filter((e) => e._id === productId)[0];

    useEffect(() => {
        if (productId && product) {
            setFormValue(product);
        }
    }, [product, productId]);

    const selectedValidationSchema = Yup.object().shape({
        descriptionMarkdown: Yup.string().required('Required'),
    });

    const onSubmit = useProductSubmit(productId, `contact-information`);

    if (!(productId === 'new' || (productId && formValue._id))) return null;

    return (
        <div className="w-full">
            <h1 className="text-xl text-gray-500">Product Description</h1>

            <Formik
                initialValues={formValue}
                onSubmit={onSubmit}
                validationSchema={selectedValidationSchema}
                validateOnMount
                enableReinitialize
            >
                <Form className="mb-3">
                    <h3 className="text-sm font-normal leading-8 mb-3">
                        Please enter all product and Overview data...
                    </h3>
                    <div className="text-logo-blue">
                        <div className="container">
                            <Field
                                id="descriptionMarkdown"
                                name="descriptionMarkdown"
                            >
                                {(props: any) => (
                                    <Suspense fallback={<div />}>
                                        <MDEditor
                                            value={props.field.value}
                                            onChange={(value) => {
                                                props.form.setFieldValue(
                                                    'descriptionMarkdown',
                                                    value
                                                );
                                            }}
                                            height={480}
                                        />
                                    </Suspense>
                                )}
                            </Field>

                            <div className="flex content-start text-red-500 text-sm">
                                <ErrorMessage
                                    name="descriptionMarkdown"
                                    render={(msg) => <div>{msg}</div>}
                                />
                            </div>
                        </div>
                    </div>
                    <EditProductFormikButtons
                        productId={productId}
                        isReadOnly={false}
                        pathPrevious="product-fees"
                        pathNext="contact-information"
                    />
                </Form>
            </Formik>
        </div>
    );
};

export default ProductDescription;
