import classNames from 'classnames';
import { getDaysInMonth, months } from './Calendar.utils';

import { areDatesEqual } from '@helpers/Date.helper';

import 'reactjs-popup/dist/index.css';
import { CalendarRow } from './CalendarRow';

export const CalendarBody = (props: any) => {
    const { events, selectedYear } = props;

    const getDayBgStyle = (sameDates: Date) => {
        const event = findEvent(sameDates);

        return classNames('cursor-pointer p-2', {
            [event?.color as keyof typeof events]: event,
        });
    };

    const findEvent = (date: Date) =>
        events?.find((event: any) => areDatesEqual(event.date, date));

    const rows = months.map((month: string, monthIndex: number) => {
        const days = getDaysInMonth(monthIndex, selectedYear);

        const monthData: any[] = ['month', ...days];

        return (
            <tr key={monthIndex}>
                {monthData.map((day, i) => (
                    <CalendarRow
                        key={i}
                        day={day}
                        month={month}
                        events={events}
                        findEvent={findEvent}
                        getDayBgStyle={getDayBgStyle}
                    />
                ))}
            </tr>
        );
    });

    return <>{rows}</>;
};
