import { dateShortestFormatter } from '@helpers/Date.helper';
import classNames from 'classnames';

export enum TimelineEndType {
    header = 'header',
    footer = 'footer',
}
interface TimelineEndProps {
    iconURL?: string;
    title: string;
    date?: Date;
    endType: TimelineEndType;
}

export const TimelineEnd: React.FC<TimelineEndProps> = ({
    iconURL,
    title,
    date,
    endType,
}) => {
    const endStyle = classNames(
        ['flex flex-col items-center absolute'],
        endType === TimelineEndType.header
            ? '-top-16 w-full'
            : '-bottom-30 w-full'
    );
    return date ? (
        <div className={endStyle} data-testid={`event-${endType}`}>
            {iconURL && (
                <img src={iconURL} className="w-10" alt="Timeline icon" />
            )}
            <p className="text-center">
                {title} <br /> <strong>{dateShortestFormatter(date)}</strong>
            </p>
        </div>
    ) : null;
};
