import React from 'react';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Pie } from 'react-chartjs-2';
import { PieChartProps } from '@interfaces/InterfaceChartProps';

const WidgetPieChart: React.FC<PieChartProps> = (props: PieChartProps) => {
    ChartJS.register(ArcElement, Tooltip, Legend);

    return <Pie {...props} />;
};

export default WidgetPieChart;
