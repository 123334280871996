import { Suspense, lazy } from 'react';

/*
 * This is a helper function to lazy load components.
 * It is used to lazy load components that are not used on the initial page load.
 * This is done to improve the initial page load time.
 *
 * Example usage:
 *
 * import { lazyPlus } from '@libs/lazy';
 *
 * const Test = lazyPlus(() => import('@components/Test'));
 *
 * */
export const lazyPlus = (
    factory: () => Promise<{
        default: React.FC<{}>;
    }>
) => {
    const Test = lazy(factory);
    // eslint-disable-next-line react/display-name
    return () => {
        return (
            <Suspense fallback={<div />}>
                <Test />
            </Suspense>
        );
    };
};
