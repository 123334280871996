import { useIntermediariesStore } from './Intermediaries.store';

const useIntermediariesState = () =>
    useIntermediariesStore((state) => ({
        isLoading: state.isLoading,
        intermediaries: state.intermediaries,
    }));

const useIntermediariesActions = () =>
    useIntermediariesStore((state) => state.actions);

export { useIntermediariesState, useIntermediariesActions };
