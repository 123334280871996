import React from 'react';
import classNames from 'classnames';
import { Icons } from '@components/Atoms';

export type AlertBoxType = 'info' | 'warning' | 'success' | 'error';

interface AlertBoxProps {
    title?: string;
    message?: string | React.ReactNode;
    alertType: AlertBoxType;
}

const alertBoxClassName: { [key in AlertBoxType]: string } = {
    info: 'bg-blue-100 text-blue-700',
    warning: 'bg-yellow-100 text-yellow-700',
    success: 'bg-green-100 text-green-700',
    error: 'bg-red-100 text-red-700',
};

export const AlertBox: React.FC<AlertBoxProps> = ({
    title,
    message,
    alertType,
}) => (
    <div
        className={classNames(
            `${alertBoxClassName[alertType]} w-full rounded-lg p-4 my-4 text-sm block my-2`
        )}
        role="alert"
    >
        <div className="flex flex-col justify-between">
            <div className="flex items-center mb-2">
                <Icons name="InformationCircleIcon" strokeWidth=".1" />
                <span className="font-bold mr-1 text-base ml-2">{title}</span>
            </div>

            {typeof message === 'string' ? (
                <p>{message}</p>
            ) : (
                <div>{message}</div>
            )}
        </div>
    </div>
);
