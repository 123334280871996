import React, { useEffect, useState, useCallback } from 'react';
import { Link } from 'react-router-dom';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';

import { SystemUserResponse } from '@interfaces/Api';

import { useAuthState } from '@contexts/AuthContext';

import { Button, Icons } from '@components/Atoms';

import { EndInvestorRequestRMToComplete } from '@api/EndInvestorProfiles';

import { notifyError, notifySuccess } from '@helpers/toastrHelper';
import { errorToString } from '@helpers/error.helper';

interface IncompleteClientRegistrationProps {
    allowInvitedClientToRegister?: boolean;
    authorizeRMToComplete?: boolean;
    loggedInClientSystemUser?: SystemUserResponse;
    invitedBySystemUser?: SystemUserResponse;
}

const IncompleteClientRegistration: React.FC<
    IncompleteClientRegistrationProps
> = ({
    allowInvitedClientToRegister,
    authorizeRMToComplete,
    loggedInClientSystemUser,
    invitedBySystemUser,
}) => {
    const { t } = useTranslation();
    const [name, setName] = useState<string | null>('');
    const { getCurrentUserData } = useAuthState();

    const getInvitationInfo = (
        invitationSentBy?: SystemUserResponse,
        invitationSentAt?: Date
    ): string => {
        if (invitationSentBy && invitationSentAt) {
            return `${invitationSentBy.firstName} ${
                invitationSentBy.surname
            } ${t('clients.new_invitations.on')} ${dayjs(invitationSentAt).format('DD MMM YYYY')}`;
        }

        return '';
    };
    const handlerRequestRMButton = () => {
        EndInvestorRequestRMToComplete()
            .then(async () => {
                notifySuccess(
                    'authorized wealth manager to complete the profile successfully'
                );
                await getCurrentUserData();
            })
            .catch((error) => notifyError(errorToString(error)));
    };

    useEffect(() => {
        if (loggedInClientSystemUser) {
            const inviteeEndInvestor = [
                loggedInClientSystemUser?.firstName,
                loggedInClientSystemUser?.surname,
            ]
                .filter((a) => a)
                .join(' ');
            setName(inviteeEndInvestor);
        }
    }, [loggedInClientSystemUser]);

    return (
        <div className="flex md:h-full items-center justify-center md:p-10 bg-white">
            <div className="container grid max-w-screen-xl gap-8 lg:grid-cols-2 lg:grid-rows-2">
                <div className="row-span-1 flex flex-col rounded-md border border-slate-200">
                    <div className="h-1/4 flex-1 bg-brand-navy-light">
                        <div className="h-24 bg-white" />
                        <div className="-mt-20 flex justify-center">
                            <div className="h-36 rounded-full bg-brand-navy-semi dark:bg-brand-navy-semi px-6 my-6 text-center">
                                <div className="p-8 my-6 rounded-full bg-brand-navy-light dark:bg-brand-navy-light">
                                    <Icons name="UserIcon" />
                                </div>
                            </div>
                        </div>

                        <div>
                            <h3 className="mt-3 md:mt-5 mb-1 px-3 text-center text-lg font-bold">
                                {name}
                            </h3>
                            <h4 className="mt-3 md:mb-5 px-3 text-center text-sky-500 font-medium">
                                {loggedInClientSystemUser?.email}
                            </h4>
                            <blockquote>
                                <p className="mx-2 mb-7 text-center text-base">
                                    Invited by{' '}
                                    <span className="font-bold">
                                        {getInvitationInfo(
                                            invitedBySystemUser,
                                            loggedInClientSystemUser
                                                ?.invitationLog?.[0].sentAt
                                        )}
                                    </span>
                                </p>
                            </blockquote>
                        </div>
                    </div>
                </div>

                <div className="relative flex rounded-md border border-slate-200 items-center justify-center md:overflow-hidden mb-16 md:mb-0">
                    <div className="px-10">
                        <h3 className="text-xl font-medium text-gray-700">
                            Incomplete Registration
                        </h3>
                        <p className="mt-2 text-slate-500 grow-0">
                            {authorizeRMToComplete &&
                                'Your RM will complete the registration on your behalf'}

                            {!authorizeRMToComplete &&
                                !allowInvitedClientToRegister &&
                                'Your client registration is incomplete, please request your RM to register your details for you.'}

                            {!authorizeRMToComplete &&
                                allowInvitedClientToRegister &&
                                'Your client registration is incomplete, please register by clicking the Register button below or Request RM to complete this registration.'}
                        </p>
                    </div>
                    <div className="max-w-2xl mx-1 my-3 absolute bottom-0 right-0 flex justify-end">
                        {allowInvitedClientToRegister && (
                            <>
                                {authorizeRMToComplete && (
                                    <Button disabled>Register</Button>
                                )}
                                {!authorizeRMToComplete && (
                                    <Link to="/clients/register-investor">
                                        <Button>Register</Button>
                                    </Link>
                                )}
                            </>
                        )}

                        {!authorizeRMToComplete && (
                            <Button onClick={handlerRequestRMButton}>
                                Request RM to register
                            </Button>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default IncompleteClientRegistration;
