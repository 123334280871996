import { ConfigurationResponse } from '@interfaces/Api';
import { ClientEnum } from '@interfaces/Api/ConfigurationResponse';
import { useEffect, useMemo, useState } from 'react';

export const useTheme = ({
    configuration,
}: {
    configuration: ConfigurationResponse;
}) => {
    const [themeName, setThemeName] = useState<ClientEnum>(ClientEnum.S64);

    const availableThemes: ClientEnum[] = useMemo(
        () => [ClientEnum.S64, ClientEnum.HG, ClientEnum.ODDO],
        []
    );

    useEffect(() => {
        if (
            ClientEnum[configuration?.client] &&
            availableThemes.includes(configuration?.client.toLocaleUpperCase() as ClientEnum)
        ) {
            setThemeName(configuration?.client);
        } else {
            setThemeName(ClientEnum.S64);
        }
    }, [availableThemes, configuration?.client]);

    return { themeName };
};
