import { getLocalStorageAuthTokens } from '@helpers/auth.helper';
import { BaseUrl } from '@helpers/Environment';
import { downloadFileOnTheClient } from '@helpers/File.helper';
import {
    EndInvestorProfileResponse,
    SubscriptionResponse,
} from '@interfaces/Api';
import { Http } from '../libs/http';

export const API_NOMINEE_URL = `${BaseUrl}/nominees`;

export interface NomineeInvestorResponseBody {
    endInvestorProfile: EndInvestorProfileResponse;
    subscriptionsWithAssociatedParentSubscriptions: {
        subscription: SubscriptionResponse;
        associatedNomineeSubscription: SubscriptionResponse;
    }[];
}

export const nomineeUnderlyingInvestorsGet = (
    nomineeEndInvestorProfileId: string,
    productId?: string,
    shareClassName?: string
) =>
    Http.get<NomineeInvestorResponseBody[]>(
        `${API_NOMINEE_URL}/${nomineeEndInvestorProfileId}/investors`,
        undefined,
        {
            productId: productId,
            shareClassName: shareClassName,
        }
    );

export const saveBlockBooking = async (
    nomineeEndInvestorProfileId: string,
    subscriptionId: string
) => {
    // TODO:  A reusable method to download a blob from the server should be created, the below should be refactored
    const token = getLocalStorageAuthTokens()?.access?.token;
    const urlInterface = new URL(`${API_NOMINEE_URL}/block-booking`);
    urlInterface.searchParams.set(
        'nomineeEndInvestorProfileId',
        String(nomineeEndInvestorProfileId)
    );

    urlInterface.searchParams.set('subscriptionId', String(subscriptionId));

    const response = await fetch(urlInterface.toString(), {
        method: 'GET',
        headers: {
            Accept: 'application/json',
            Authorization: `Bearer ${token}`,
            'Access-Control-Expose-Headers': 'Content-Disposition',
        },
    });

    if (response.ok) {
        downloadFileOnTheClient(response);
    }
};

export const saveUnderlyingInvestors = async (
    nomineeEndInvestorProfileId: string,
    subscriptionId: string
) => {
    const token = getLocalStorageAuthTokens()?.access?.token;
    const url = new URL(`${API_NOMINEE_URL}/underlying-investors-csv`);
    url.searchParams.set(
        'nomineeEndInvestorProfileId',
        String(nomineeEndInvestorProfileId)
    );
    url.searchParams.set('subscriptionId', String(subscriptionId));

    const response = await fetch(url.toString(), {
        method: 'GET',
        headers: {
            Accept: 'application/json',
            Authorization: `Bearer ${token}`,
            'Access-Control-Expose-Headers': 'Content-Disposition',
        },
    });

    if (response.ok) {
        downloadFileOnTheClient(response);
    }
};
