import { useNavigate } from 'react-router-dom';
import { dateShortFormatter, formatDate } from '@helpers/Date.helper';
import { FundData, InvestmentData } from '@interfaces/Api/Dashboard';
import {
    ProductPerformance,
    ReturnHistory,
} from '@interfaces/Api/ProductPerformance';
import { DashboardType, formatMetricsValue } from '../../Dashboard.helper';
import { EndInvestorFundCard } from './EndInvestorFundCard';
import { getFormattedCurrency } from '@helpers/isoCurrencies';
import { useCallback } from 'react';
import { WidgetLineChart } from '@components/Charts';
import { TileBackgroundColor } from '../Tile/Tile';
import { t } from 'i18next';

interface OpenEndedEndInvestorFundCardProps {
    dashboardType: DashboardType;
    fundData: FundData;
    investmentData: InvestmentData;
    performanceData: ProductPerformance[];
    latestPerformanceData: ProductPerformance[];
    valuationDate: Date;
    currentUnitPrice: number;
    averagePurchaseUnitPrice?: number;
    totalReturn?: number;
    returnHistory?: ReturnHistory[];
}

export const OpenEndedEndInvestorFundCard: React.FC<
    OpenEndedEndInvestorFundCardProps
> = ({
    fundData,
    investmentData,
    valuationDate,
    currentUnitPrice,
    averagePurchaseUnitPrice,
    totalReturn,
    returnHistory,
}) => {
    const navigate = useNavigate();

    const getPerformanceChart = useCallback(() => {
        if (returnHistory?.length) {
            return returnHistory?.map(({ date, value }) => {
                // TODO: needs to be formatted
                return {
                    label: formatDate(date.toString()),
                    value,
                };
            });
        }
        return [];
    }, [returnHistory]);

    const getFundSummary = () => {
        const getAmount = (val?: number) => {
            if (val) {
                return getFormattedCurrency(
                    val,
                    investmentData?.shareClass?.currency,
                    true,
                    'code'
                );
            }
            return '-';
        };

        const summaryValues: { label: string; value: string }[] = [
            {
                label: t('client.portfolio.card.cost_basis'),
                value: getAmount(investmentData.commitment),
            },
            {
                label: t('client.portfolio.card.current_unit_price'),
                value: getAmount(currentUnitPrice),
            },
            {
                label: t('client.portfolio.card.average_unit_price'),
                value: getAmount(averagePurchaseUnitPrice),
            },
            {
                label: t('client.portfolio.card.units_held'),
                value: formatMetricsValue({
                    label: 'Units Held',
                    value: investmentData.unitsHeld?.toString() || '-',
                    toFixedNumber: 0,
                }),
            },
        ];

        return (
            <>
                <div className="grid grid-rows-4 grid-flow-col gap-1 mb-10 mr-2 text-xl">
                    {summaryValues.map((summary) => (
                        <div
                            key={summary.label}
                            className="flex justify-between mb-1"
                        >
                            <span className="pr-2">{summary.label}</span>
                            <span>{summary.value}</span>
                        </div>
                    ))}
                </div>
                <p className="font-bold mb-4 lg:mb-0 flex justify-between mr-2 mt-14 text-xl">
                    <span className="pr-2">{t('client.portfolio.card.current_value')}</span>
                    <span>
                        {getAmount(
                            currentUnitPrice * investmentData.unitsHeld!
                        )}
                    </span>
                </p>
            </>
        );
    };

    return (
        <EndInvestorFundCard
            subscriptionId={investmentData.subscriptionId}
            productName={fundData.productName}
            fundType={fundData.liquidityType}
            leftBoxChildren={getFundSummary()}
            onClick={() =>
                navigate(`subscription/${investmentData.subscriptionId}`)
            }
            rightBoxChildren={
                getPerformanceChart().length ? (
                    // TODO: Add chart showing % return over time
                    <>
                        <WidgetLineChart
                            data={{
                                labels: getPerformanceChart().map((a) => a.label),
                                datasets: [
                                    {
                                        label: t('client.portfolio.card.return'),
                                        data: getPerformanceChart().map(
                                            (a) => a.value
                                        ),
                                        borderColor: '#00376E',
                                        backgroundColor: '#00376E',
                                    },
                                ],
                            }}
                            options={{
                                plugins: {
                                    legend: { display: false },
                                },
                                scales: {
                                    return: {
                                        title: {
                                            text: t('client.portfolio.card.return'),
                                            display: true,
                                        },
                                        type: 'linear',
                                        position: 'left',
                                        ticks: {
                                            callback: (value: string | number) => {
                                                return `${Number(
                                                    value
                                                ).toFixed()}%`;
                                            },
                                        },
                                        grid: {
                                            drawOnChartArea: false,
                                            drawTicks: false,
                                        },
                                    },
                                    x: {
                                        type: 'time',
                                        time: {
                                            unit: 'year',
                                        },
                                        grid: {
                                            drawOnChartArea: true,
                                            drawTicks: false,
                                        },
                                    },
                                },
                            }}
                        />
                        <h3 className="text-center pl-8 pb-2 text-sm">{t('client.portfolio.card.fund_performance')}</h3>
                    </>
                ) : (
                    <div />
                )
            }
            subHeaderList={[
                {
                    label: t('client.portfolio.card.fund_inception'),
                    value: fundData.inceptionDate
                        ? dateShortFormatter(fundData.inceptionDate)
                        : '-',
                },
                {
                    label: t('client.portfolio.card.last_valuation'),
                    value: dateShortFormatter(valuationDate) || '-',
                },
            ]}
            tiles={[
                {
                    content: [
                        t('performance.roi'),
                        Number(0).toFixed(2) + '%',
                        // `${totalReturn ? totalReturn.toFixed(2) + '%' : '-'}`,
                    ],
                    backgroundColor: TileBackgroundColor.LIGHTERBLUE,
                },
            ]}
        />
    );
};
