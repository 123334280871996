import { CalendarBody } from './CalendarBody';

interface CalendarProps {
    events: {
        label: string;
        date: Date;
        color: string;
    }[];
    selectedYear: number;
}

export const Calendar = ({ events, selectedYear }: CalendarProps) => (
    <table className="border-collapse table-auto overflow-x-scroll no-scrollbar mb-4">
        <tbody>
            <CalendarBody events={events} selectedYear={selectedYear} />
        </tbody>
    </table>
);
