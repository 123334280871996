import React, { useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';

import { Icons } from '@components/Atoms';

export interface FloatingHamburgerNavProps {
    title?: string;
    steps: {
        id?: number;
        label: string;
        stepUrl?: string;
        endPathUrl?: string;
        showStep?: boolean;
    }[];
    onStepClick?: (id: number) => void;
}

const FloatingHamburgerNav: React.FC<FloatingHamburgerNavProps> = (
    props: FloatingHamburgerNavProps
) => {
    const [isSectionMenuOpen, setIsSectionMenuOpen] = useState<boolean>(false);
    const wrapperRef = useRef(null);

    const useOutsideAlerter = (ref: any, setIsSectionMenuOpen: Function) => {
        useEffect(() => {
            const handleClickOutside = (event: Event): void => {
                if (ref.current && !ref.current.contains(event.target)) {
                    setIsSectionMenuOpen(false);
                }
            };

            document.addEventListener('mousedown', handleClickOutside);
            return () => {
                document.removeEventListener('mousedown', handleClickOutside);
            };
        }, [ref, setIsSectionMenuOpen]);
    };

    useOutsideAlerter(wrapperRef, setIsSectionMenuOpen);
    const onMenuClick = () => {
        setIsSectionMenuOpen(false);
    };
    return (
        <div className="z-40 absolute">
            {/* COLLAPSED */}
            <div className="sm:block fixed bottom-4 left-4">
                <button
                    className="bg-logo-blue hover:logo-blue-900 text-white border-solid border border-white-600 font-regular p-3 rounded-full shadow-lg"
                    onClick={() => {
                        setIsSectionMenuOpen(true);
                    }}
                >
                    <Icons name="Bars3Icon" />
                </button>
            </div>
            {/* EXPANDED */}

            {isSectionMenuOpen && (
                <div
                    ref={wrapperRef}
                    className="fixed bottom-2 left-2 right-2 bg-logo-blue text-white rounded-2xl px-4 py-5"
                >
                    {/* Heading */}
                    <div className="flex justify-between items-center">
                        <h5 className="uppercase">{props.title}</h5>
                        <div
                            className="cursor-pointer"
                            onClick={() => {
                                setIsSectionMenuOpen(false);
                            }}
                        >
                            <Icons name="XMarkIcon" strokeWidth="1.5" />
                        </div>
                    </div>
                    {/* content */}

                    {props.steps
                        .filter((a) => a.showStep)
                        .map((step, i) => {
                            if (step.stepUrl) {
                                return (
                                    <Link
                                        to={step.stepUrl}
                                        key={`${i}-nav`}
                                        onClick={onMenuClick}
                                    >
                                        <div className="border-white border-b py-3 leading-5 pl-1 text-white">
                                            {step.label}
                                        </div>
                                    </Link>
                                );
                            }
                            return (
                                <button
                                    key={`${i}-nav`}
                                    onClick={() => {
                                        if (
                                            typeof step.id === 'number' &&
                                            props.onStepClick
                                        ) {
                                            props.onStepClick(step.id);
                                        } else {
                                            // TODO: should fail silently?
                                            throw Error(
                                                'No id recorded for this step'
                                            );
                                        }
                                    }}
                                    value={i}
                                >
                                    {step.label}
                                </button>
                            );
                        })}
                </div>
            )}
        </div>
    );
};

export default FloatingHamburgerNav;
