export const ResendEmailIcon = () => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            shapeRendering="geometricPrecision"
            textRendering="geometricPrecision"
            imageRendering="optimizeQuality"
            fillRule="evenodd"
            clipRule="evenodd"
            viewBox="0 0 512 371.135"
        >
            <path d="M396.994 141.119c63.504 0 115.006 51.502 115.006 115.006 0 63.508-51.502 115.01-115.006 115.01s-115.006-51.502-115.006-115.01c0-63.504 51.502-115.006 115.006-115.006zM28.797 0H418.06c15.733 0 28.512 12.873 28.495 28.529l-.095 95.748a139.246 139.246 0 00-23.59-6.566l.073-77.046-125.816 98.884 9.12 8.943a141.41 141.41 0 00-18.167 18.425l-10.837-10.642-42.136 35.867c-4.5 3.771-11.463 4.224-16.506.125l-44.233-36.1L39.453 282.691h219.252a139.57 139.57 0 006.7 23.59l-236.906.008C12.744 306.289 0 293.416 0 277.752L.246 28.521C.268 12.843 13.05 0 28.797 0zM23.62 261.888l130.433-122.08L23.827 41.683l-.207 220.205zM42.658 23.602l183.95 141.862L400.682 23.602H42.658zM428.582 227.41a82.428 82.428 0 00-2.515-2.062c-4.012-3.171-8.188-5.932-11.605-7.623-10.613-5.246-22.364-5.695-32.77-2.183-10.362 3.499-19.405 10.966-24.651 21.566-5.255 10.613-5.712 22.365-2.205 32.762a42.745 42.745 0 0021.588 24.676 42.722 42.722 0 0030.946 2.77c10.03-2.912 18.977-9.448 24.698-18.87 2.765-4.568 8.714-6.031 13.283-3.266 4.569 2.766 6.031 8.715 3.266 13.283-8.335 13.732-21.321 23.236-35.855 27.455-14.435 4.185-30.414 3.193-44.914-3.986-15.402-7.632-26.277-20.802-31.381-35.91-5.091-15.078-4.431-32.11 3.188-47.49 7.623-15.397 20.785-26.26 35.885-31.36 15.073-5.09 32.114-4.439 47.498 3.167 4.517 2.239 9.923 5.789 15.022 9.823l2.045 1.652-.427-7.735c-.289-5.345 3.805-9.922 9.15-10.211 5.345-.289 9.923 3.805 10.212 9.15l1.747 31.562c.418 5.345-3.576 10.022-8.922 10.44l-32.869 2.645c-5.345.418-10.022-3.577-10.44-8.922-.418-5.345 3.576-10.021 8.922-10.44l11.104-.893z" />
        </svg>
    );
};
