import { BaseUrl } from '@helpers/Environment';
import {
    ProspectiveInvestorDefaultsRequest,
    ProspectiveInvestorDefaultsResponse,
} from '@interfaces/Api/ProspectiveInvestorDefaults';
import { Http } from '@libs/http';

const API_PROSPECTIVEINVESTORDEFAULTS_URL = `${BaseUrl}/prospective-investor-defaults`;

export const ProspectiveInvestorDefaultsGet =
    async (): Promise<ProspectiveInvestorDefaultsResponse> =>
        Http.get<ProspectiveInvestorDefaultsResponse>(
            `${API_PROSPECTIVEINVESTORDEFAULTS_URL}`
        );

export const ProspectiveInvestorDefaultsPut = async (
    body: ProspectiveInvestorDefaultsRequest
): Promise<ProspectiveInvestorDefaultsResponse> =>
    Http.put<ProspectiveInvestorDefaultsResponse>(
        `${API_PROSPECTIVEINVESTORDEFAULTS_URL}`,
        body
    );
