import React, { useEffect, useMemo, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';

import { AuthRoleEnum, ProductResponse } from '@interfaces/Api';

import { InputBox, Loading } from '@components/Atoms';
import { PillOption, PillSelector } from '@components/Molecules';
import { ProductCard } from '@components/Organisms';
import { getPublishedProducts } from '@helpers/Product.helper';
import { AvailabilityStatusEnum } from '@interfaces/Api/ProductResponse';
import { useTranslation } from 'react-i18next';
import { getStrategies } from '@services/Strategies';
import { ClientEnum } from '@interfaces/Api/ConfigurationResponse';
import { useConfigurationState } from '@contexts/ConfigurationContext';
import { useAuthState } from '@contexts/AuthContext';
import { filterSearchData } from '@components/Organisms/SearchBox/search.helper';
import ProductProspectiveInvestorAlert from './ProductProspectiveInvestorAlert';

interface ProductsListProps {
    products: ProductResponse[];
    isLoading: boolean;
}

type ProductsPillOptionType = `${AvailabilityStatusEnum}`;

const ProductsList: React.FC<ProductsListProps> = (props) => {
    const [searchParams] = useSearchParams();
    const navigate = useNavigate();
    const { configuration } = useConfigurationState();
    const { isLoggedIn, currentUser, hasRoles } = useAuthState();
    const [searchText, setSearchText] = useState<string>();

    const [availabilityStatus, setAvailabilityStatus] =
        React.useState<ProductsPillOptionType>(
            (searchParams.get(
                'availabilityStatus'
            ) as ProductsPillOptionType) ||
                AvailabilityStatusEnum.openForSubscription
        );
    const { t } = useTranslation();

    const pillOptions: PillOption<ProductsPillOptionType>[] = useMemo(() => {
        const contains = (value: AvailabilityStatusEnum) =>
            props.products.find(
                (product) => product.availabilityStatus === value
            );

        return [
            {
                label: t('products.pillselector.openForSubscription'),
                value: AvailabilityStatusEnum.openForSubscription,
                show: true,
            },
            {
                label: t('products.pillselector.upcoming'),
                value: AvailabilityStatusEnum.upcoming,
                show: contains(AvailabilityStatusEnum.upcoming),
            },
            {
                label: t('products.pillselector.closedToSubscription'),
                value: AvailabilityStatusEnum.closedToSubscription,
                show: hasRoles([AuthRoleEnum.endInvestor])
                    ? false
                    : contains(AvailabilityStatusEnum.closedToSubscription),
            },
            {
                label: t('products.pillselector.expired'),
                value: AvailabilityStatusEnum.expired,
                show: contains(AvailabilityStatusEnum.expired),
            },
        ].filter((a) => a.show);
    }, [t, props.products]);

    const filteredProducts = useMemo(() => {
        return getPublishedProducts(props.products)
            .filter(
                (product) => product.availabilityStatus === availabilityStatus
            )
            .filter((product) => {
                if (hasRoles([AuthRoleEnum.endInvestor]))
                    return (
                        product.availabilityStatus !== 'closedToSubscription'
                    );
                return true;
            })
            .filter((product) => {
                return filterSearchData({
                    searchData: product,
                    searchQuery: searchText?.split(' ') || [],
                    omitKeys: [
                        '__v',
                        '_id',
                        'assetManagerId',
                        'availabilityStatus',
                        'capacityAmount',
                        'changeLogs',
                        'checks',
                        'closedEndedInformation',
                        'contact',
                        'created_date',
                        'description',
                        'descriptionMarkdown',
                        'documentFolders',
                        'documents',
                        'eligibility',
                        'groupId',
                        'images',
                        'keyInformation',
                        'logo',
                        'openEndedInformation',
                        'slug',
                        'status',
                        'targetSubscriptionAmount',
                        'thumbnail',
                        'type',
                        'updated_date',
                        'videos',
                    ],
                });
            });
    }, [availabilityStatus, props.products, searchText]);

    const displayPillOptions =
        props.products
            .map((a) => a.availabilityStatus)
            .filter((value, index, self) => self.indexOf(value) === index)
            .length > 1;

    const handleProductPillSelectorChange = (
        availabilityStatus: ProductsPillOptionType
    ) => {
        navigate({
            pathname: '/products/',
            search: `availabilityStatus=${availabilityStatus}`,
        });
        setAvailabilityStatus(availabilityStatus);
    };

    useEffect(() => {
        const { onProductPage } = getStrategies(
            configuration?.client as ClientEnum
        );

        if (onProductPage) {
            onProductPage({
                isLogin: isLoggedIn,
                preferences: configuration?.preferences,
                navigate,
                role: currentUser?.user.role,
            });
        }
    }, [configuration, isLoggedIn, navigate]);

    return (
        <div className="flex flex-col items-stretch xs:container xs:mx-auto max-w-full h-full pb-9">
            <h1 className="text-3xl text-logo-blue font-bold align-middle mb-5">
                {t('products.pagetitle')}
            </h1>

            {/* Some kind of error only if the logged in user is prospective user role */}
            {/* TODO: This component needs to be thought about */}
            {currentUser?.user.role === AuthRoleEnum.prospectiveInvestor && (
                <ProductProspectiveInvestorAlert />
            )}

            {displayPillOptions && (
                <div className="w-full flex">
                    <PillSelector<ProductsPillOptionType>
                        onChange={handleProductPillSelectorChange}
                        options={pillOptions}
                        selectedValue={availabilityStatus}
                    />
                </div>
            )}
            <div className="flex mt-5 w-1/2 self-start z-0">
                <InputBox
                    name="search"
                    placeholder={t('products.search_placeholder')}
                    autoComplete="off"
                    onChange={(val: any) => {
                        setSearchText(val.target.value);
                    }}
                />
            </div>
            <div className="mt-5">
                {props.isLoading ? (
                    <Loading size="large" />
                ) : filteredProducts?.length === 0 ? (
                    <div>
                        <h1 className="text-xl">No products listed</h1>
                    </div>
                ) : (
                    <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-10 w-full">
                        {filteredProducts
                            .sort((a, b) => {
                                // sort by priority
                                if (a.priority === b.priority) {
                                    return 0;
                                }

                                return a.priority < b.priority ? -1 : 1;
                            })
                            .map((product) => (
                                <ProductCard
                                    key={product._id}
                                    searchText={searchText}
                                    product={product}
                                />
                            ))}
                    </div>
                )}
            </div>
        </div>
    );
};

export default ProductsList;
