import { LifecycleNotice } from '@interfaces/Api/SubscriptionNotice';
import { eventListDateSubHeader } from './helpers/eventListHelper';

export interface GenericEventProps {
    notice: LifecycleNotice;
}

export const GenericEvent: React.FC<GenericEventProps> = ({ notice }) => {
    return (
        <>
            <p className="text-sm md:text-base leading-snug text-opacity-100 font-bold">
                {eventListDateSubHeader(notice)}
            </p>
            <p>{notice.comment}</p>
        </>
    );
};
