import { useCallback, useMemo, useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { FormikValues } from 'formik';

import {
    AuthRoleEnum,
    BookingCentreResponse,
    IntermediaryResponse,
    SystemUserResponse,
} from '@interfaces/Api';
import { AssetManager } from '@interfaces/Api/AssetManager';
import { SelectOption } from '@interfaces/InterfaceFormsProps';

import { useAuthState } from '@contexts/AuthContext';
import { Option } from '@components/Molecules/FormMultiselect/FormMultiselect';
import { getRoleName } from '@helpers/SystemUser.helper';
import {
    isAssetManagerRequiredForRoles,
    isAssetManagerRequiredForThisRole,
    isBookingCenterRequiredForRoles,
    isBookingCenterRequiredForThisRole,
    isIntermediaryRequiredForRoles,
    isIntermediaryRequiredForThisRole,
    transformAvailableRolesOptionsToStringArr,
} from './util';
import { useEntityRoles, EntityRolesLabelEnum } from './useEntityRoles';
import { useProfiles } from '@hooks/useProfiles';

export interface Field {
    name: string;
    validationName: string;
    type: 'text' | 'email' | 'selection' | 'multi-selection';
    label: string;
    placeholder: string;
    optionsData?: SelectOption<string>[] | Option[];
    disabled?: (params: {
        values: FormikValues;
        isEdit: boolean;
        role?: AuthRoleEnum;
    }) => boolean;
    handleFieldChange?: (
        e: React.ChangeEvent<HTMLSelectElement>,
        setFieldValue: FormikValues['setFieldValue']
    ) => void;
    visible?: (values?: FormikValues) => boolean;
    required?: boolean;
    requiredWhen?: { targetField: string };
}

interface UseFieldsConfigParams {
    assetManagers: AssetManager[];
    intermediaries: IntermediaryResponse[];
    bookingCentres: BookingCentreResponse[];
    systemUsers: SystemUserResponse[];
    setSelectedIntermediaryId: (id: string) => void;
    isEdit: boolean;
}

const profileUserRoles = [
    AuthRoleEnum.profileManager,
    AuthRoleEnum.productViewer,
];

export const useFieldsConfig = ({
    assetManagers,
    intermediaries,
    bookingCentres,
    systemUsers,
    setSelectedIntermediaryId,
    isEdit,
}: UseFieldsConfigParams) => {
    const { userId } = useParams();
    const { profiles } = useProfiles();
    const user: SystemUserResponse | undefined = systemUsers.find(
        ({ _id }) => _id === userId
    );

    const { entityRoles, entityRoleCombinations } = useEntityRoles({
        user,
    });

    const [roles, setRoles] = useState<AuthRoleEnum[]>([]);
    const [availableRoles, setAvailableRoles] = useState<AuthRoleEnum[]>([]);

    const { currentUser, currentSystemUserRoleActions } = useAuthState();

    const userActions = isEdit
        ? currentSystemUserRoleActions?.update
        : currentSystemUserRoleActions?.create;

    const isProfileUser = profileUserRoles.includes(user?.role as AuthRoleEnum);

    useEffect(() => {
        if (user?.role) {
            const roles = entityRoles.find((item) =>
                item.roles.includes(user.role as AuthRoleEnum)
            )?.roles;

            if (roles) {
                setRoles(roles);
                const availableRoles =
                    entityRoleCombinations.find((item) =>
                        item.roles.includes(user.role as AuthRoleEnum)
                    )?.possibleCombinations || [];

                setAvailableRoles(availableRoles);
            }
        }
    }, [entityRoles, user?.role]);

    const canAssignIntermediary = useCallback(
        (roleEnum: AuthRoleEnum | string) =>
            [
                AuthRoleEnum.superUser,
                AuthRoleEnum.assetManagerAdministrator,
            ].includes(roleEnum as AuthRoleEnum),
        []
    );

    const canAssignBookingCentre = useCallback(
        (roleEnum: AuthRoleEnum | string) =>
            [AuthRoleEnum.superUser, AuthRoleEnum.administrator].includes(
                roleEnum as AuthRoleEnum
            ),
        []
    );

    const canAssignAssetManager = useCallback(
        (roleEnum: AuthRoleEnum | string) =>
            [AuthRoleEnum.superUser].includes(roleEnum as AuthRoleEnum),
        []
    );

    const updateRole = (
        setFieldValue: FormikValues['setFieldValue'],
        role: AuthRoleEnum
    ) => {
        setFieldValue('role', role);
    };

    const fields: Field[] = useMemo(() => {
        return [
            {
                name: 'entityRoles',
                validationName: 'Entity Roles',
                label: 'Entity Roles',
                placeholder: 'Entity Roles',
                type: 'selection',
                optionsData: entityRoles.map((item) => ({
                    label: EntityRolesLabelEnum[item.name],
                    value: item.name,
                })),
                handleFieldChange: ({ target: { value } }, setFieldValue) => {
                    setRoles([]);
                    setAvailableRoles([]);
                    setFieldValue('availableRoles', '');
                    setFieldValue('role', '');

                    const selectedEntityRoles =
                        entityRoles.find((item) => item.name === value)
                            ?.roles || [];
                    console.log(selectedEntityRoles);
                    setRoles(selectedEntityRoles);

                    if (selectedEntityRoles.length === 1) {
                        const onlyRole = selectedEntityRoles[0];
                        updateRole(setFieldValue, onlyRole);

                        const onlyRoleAvailableRoles =
                            entityRoleCombinations.find((item) =>
                                item.roles.includes(onlyRole as AuthRoleEnum)
                            )?.possibleCombinations || [];

                        setAvailableRoles(onlyRoleAvailableRoles);
                    }
                },
            },
            {
                name: 'role',
                validationName: 'Role',
                label: 'Role',
                placeholder: 'Role',
                type: 'selection',
                required: true,
                handleFieldChange: ({ target: { value } }, setFieldValue) => {
                    if (
                        value === AuthRoleEnum.assetManagerAdministrator &&
                        assetManagers.length === 1
                    ) {
                        setFieldValue('assetManagerId', assetManagers[0]._id);
                    } else {
                        setFieldValue('assetManagerId', '');
                        if (
                            currentUser?.user.role === AuthRoleEnum.superUser &&
                            isIntermediaryRequiredForThisRole(value) &&
                            intermediaries.length === 1
                        ) {
                            setFieldValue(
                                'intermediaryId',
                                intermediaries[0]._id,
                                true
                            );
                        }
                    }

                    if (isIntermediaryRequiredForThisRole(value)) {
                        setFieldValue(
                            'intermediaryId',
                            currentUser?.user?.intermediaryId
                        );
                    }

                    if (isBookingCenterRequiredForThisRole(value)) {
                        setFieldValue(
                            'bookingCentreId',
                            bookingCentres?.length === 1
                                ? bookingCentres[0]._id
                                : ''
                        );
                    }

                    const availableRolesBasedOnSelectedRole = (
                        entityRoleCombinations.find((item) =>
                            item.roles.includes(value as AuthRoleEnum)
                        )?.possibleCombinations || []
                    ).filter((item) => item !== value);

                    setAvailableRoles(availableRolesBasedOnSelectedRole);
                },
                optionsData:
                    userActions
                        ?.filter((role) => roles.includes(role))
                        .map((item) => ({
                            label: getRoleName(item),
                            value: item.toString(),
                        })) || [],

                disabled: ({ isEdit, role }) => {
                    if (isEdit && role !== AuthRoleEnum.superUser) {
                        return true;
                    }

                    return false;
                },
            },
            {
                name: 'availableRoles',
                validationName: 'Available Roles',
                label: 'Available Roles',
                placeholder: 'Available Roles',
                type: 'multi-selection',
                required: false,
                visible: (values) => values?.role !== AuthRoleEnum.superUser,
                optionsData:
                    userActions
                        ?.filter((role) => availableRoles.includes(role))
                        .map((item) => ({
                            label: getRoleName(item),
                            value: item.toString(),
                        })) || [],

                disabled: ({ isEdit, role, values }) => {
                    if (
                        values.role === AuthRoleEnum.superUser ||
                        (isEdit && role !== AuthRoleEnum.superUser)
                    ) {
                        return true;
                    }

                    if (availableRoles.length === 0) return true;

                    return false;
                },
            },
            {
                name: 'intermediaryId',
                validationName: 'Intermediary',
                label: 'Intermediary',
                placeholder: 'Intermediary',
                type: 'selection',
                optionsData: intermediaries.map(
                    (item: IntermediaryResponse) => ({
                        label: item?.name || '',
                        value: item?._id || '',
                    })
                ),
                visible: (values) =>
                    ((!isProfileUser &&
                        isIntermediaryRequiredForThisRole(values?.role)) ||
                        isIntermediaryRequiredForRoles(
                            transformAvailableRolesOptionsToStringArr(values)
                        )) &&
                    canAssignIntermediary(currentUser?.user?.role!),
                disabled: () =>
                    currentUser?.user?.role !== AuthRoleEnum.superUser,
                handleFieldChange: (e, setFieldValue) => {
                    setSelectedIntermediaryId(
                        e.target.selectedOptions[0].value
                    );
                    setFieldValue('bookingCentreId', '');
                },
                requiredWhen: isProfileUser
                    ? undefined
                    : {
                          targetField: 'role',
                      },
            },
            {
                name: 'assetManagerId',
                validationName: 'Asset Manager',
                label: 'Asset Manager',
                placeholder: 'Asset Manager',
                type: 'selection',
                optionsData: assetManagers.map((item: AssetManager) => ({
                    label: item.name,
                    value: item._id,
                })),
                visible: (values) =>
                    (isAssetManagerRequiredForThisRole(values?.role) ||
                        isAssetManagerRequiredForRoles(
                            transformAvailableRolesOptionsToStringArr(values)
                        )) &&
                    canAssignAssetManager(currentUser?.user?.role!),
                disabled: ({ role, values }) => {
                    if (
                        isAssetManagerRequiredForThisRole(values.role) ||
                        (isAssetManagerRequiredForRoles(
                            transformAvailableRolesOptionsToStringArr(values)
                        ) &&
                            role == AuthRoleEnum.superUser)
                    ) {
                        return false;
                    }
                    return true;
                },
            },
            {
                name: 'bookingCentreId',
                validationName: 'Booking Centre',
                label: 'Booking Centre',
                placeholder: 'Booking Centre',
                type: 'selection',
                optionsData: bookingCentres.map(
                    (item: BookingCentreResponse) => ({
                        label: item.bookingCentreName || '',
                        value: item._id || '',
                    })
                ),
                visible: (values) =>
                    (isBookingCenterRequiredForThisRole(values?.role) ||
                        isBookingCenterRequiredForRoles(
                            transformAvailableRolesOptionsToStringArr(values)
                        )) &&
                    canAssignBookingCentre(currentUser?.user?.role!),
                requiredWhen: {
                    targetField: 'intermediaryId',
                },
            },
            {
                name: 'profiles',
                validationName: 'Profiles',
                label: 'Profiles',
                placeholder: 'Profiles',
                type: 'selection',
                optionsData: profiles.map((item: any) => ({
                    label: item.name,
                    value: item._id,
                })),
                visible: (values) => {
                    if (values?.availableRoles.length > 0) {
                        return values?.availableRoles?.some(
                            (el: any) =>
                                el.value === AuthRoleEnum.productViewer ||
                                el.value === AuthRoleEnum.profileAdministrator
                        );
                    }

                    if (values?.role) {
                        return (
                            values?.role === AuthRoleEnum.productViewer ||
                            values?.role === AuthRoleEnum.profileAdministrator
                        );
                    }

                    return false;
                },
            },
            {
                name: 'firstName',
                validationName: 'First Name',
                type: 'text',
                label: 'First Name',
                placeholder: 'First Name',
                required: true,
            },
            {
                name: 'surname',
                validationName: 'Surname',
                type: 'text',
                label: 'Surname',
                placeholder: 'Surname',
                required: true,
            },
            {
                name: 'email',
                validationName: 'Email',
                type: 'email',
                label: 'Email',
                placeholder: 'Email',
                required: true,
            },
        ];
    }, [
        entityRoles,
        userActions,
        intermediaries,
        isProfileUser,
        assetManagers,
        bookingCentres,
        profiles,
        entityRoleCombinations,
        currentUser?.user.role,
        currentUser?.user?.intermediaryId,
        roles,
        availableRoles,
        canAssignIntermediary,
        setSelectedIntermediaryId,
        canAssignAssetManager,
        canAssignBookingCentre,
    ]);

    const initialValues = useMemo(() => {
        return fields.reduce(
            (acc, { name }) => {
                const getValue = (): any => {
                    if (!isEdit) return '';

                    if (name === 'entityRoles') {
                        return user?.role
                            ? entityRoles.find((item) =>
                                  item.roles.includes(user.role as AuthRoleEnum)
                              )?.name
                            : '';
                    }

                    if (name === 'availableRoles') {
                        return (user?.availableRoles || []).map((item) => ({
                            value: item,
                            label: getRoleName(item as AuthRoleEnum),
                        }));
                    }

                    if (['assetManagerId'].includes(name)) {
                        return typeof user?.['assetManagerId'] !== 'string'
                            ? user?.['assetManagerId']?._id
                            : undefined;
                    }
                    if (['intermediaryId'].includes(name)) {
                        return typeof user?.['intermediaryId'] !== 'string'
                            ? user?.['intermediaryId']?._id
                            : undefined;
                    } else
                        return user?.[
                            name as keyof SystemUserResponse
                        ]?.toString();
                };

                acc[name] = getValue();
                return acc;
            },
            { ...user } as Record<string, any>
        );
    }, [fields, isEdit, user]);
    return { fields, initialValues };
};
