import { useSubscriptionsStore } from './Subscriptions.store';

const useSubscriptionsState = () =>
    useSubscriptionsStore((state) => ({
        isLoading: state.isLoading,
        subscriptions: state.subscriptions,
        subscription: state.subscription,
    }));

const useSubscriptionsActions = () =>
    useSubscriptionsStore((state) => state.actions);

export { useSubscriptionsState, useSubscriptionsActions };
