import { dateShortFormatter } from '@helpers/Date.helper';
import {
    LifecycleNotice,
    noticeTimelineLabels,
} from '@interfaces/Api/SubscriptionNotice';

export const eventListSubHeader = (notice: LifecycleNotice) => {
    const { uploadedAt, uploadedBy, noticeType } = notice;
    return [
        noticeTimelineLabels[noticeType],
        '-',
        uploadedAt && dateShortFormatter(uploadedAt),
        uploadedBy && 'by',
        uploadedBy?.firstName,
        uploadedBy?.surname,
    ]
        .filter((a) => a)
        .join(' ');
};

export const eventListDateSubHeader = (notice: LifecycleNotice) => {
    const { uploadedAt, uploadedBy, noticeType } = notice;
    return [
        uploadedAt && dateShortFormatter(uploadedAt),
        uploadedBy && 'by',
        uploadedBy?.firstName,
        uploadedBy?.surname,
    ]
        .filter((a) => a)
        .join(' ');
};
