import { Link, useLocation } from 'react-router-dom';
import { Icons } from '@components/Atoms';

import { getLinks } from './Breadcrumbs.helper';

export const BreadCrumbsList = ({
    breadCrumbs,
    isSubscriptionsPath,
    isSubscriptionTemplatePath,
    pathId,
}: any) => {
    const location = useLocation();

    return (
        <ul data-testid="breadcrumbs" className="list-reset flex items-center">
            <li className="mr-2">
                <Link to={'/'}>
                    <Icons name="HomeIcon" size="small" />
                </Link>
            </li>
            {breadCrumbs?.map((breadCrumb: string, index: number) => {
                const to = `${
                    isSubscriptionsPath || isSubscriptionTemplatePath
                        ? `/${pathId}`
                        : getLinks(breadCrumbs, index)
                }`;

                return (
                    <li
                        key={index.toString()}
                        className="capitalize pointer-events-visible"
                    >
                        {index !== breadCrumbs.length - 1 ? (
                            <>
                                {breadCrumb !== 'admin' ? (
                                    <Link
                                        to={to}
                                        state={{ location }}
                                        replace
                                        className="text-sm no-underline hover:underline hover:text-gray-500"
                                    >
                                        {breadCrumb}
                                    </Link>
                                ) : (
                                    <span className="text-sm">
                                        {breadCrumb}
                                    </span>
                                )}
                                <span className="p-1">/</span>
                            </>
                        ) : (
                            <span key={index} className="text-sm">
                                {breadCrumb}
                            </span>
                        )}
                    </li>
                );
            })}
        </ul>
    );
};
