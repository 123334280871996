import {
    ProspectiveInvestorsGetAll,
    ProspectiveInvestorsPutConvert,
} from '@api/ProspectiveInvestors';
import { Button } from '@components/Atoms';
import { CountdownTimer } from '@components/Atoms/CountdownTimer';
import { AlertBox } from '@components/Molecules';
import { useAuthState } from '@contexts/AuthContext';
import {
    ProspectiveInvestorResponse,
    ProspectiveInvestorStatusEnum,
} from '@interfaces/Api/ProspectiveInvestorResponse';
import dayjs from 'dayjs';
import { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
    BusinessEntityDetails,
    EndInvestorType,
    InvestorCategoryType,
} from 'common';
import { ModalService } from '@services/ModalService';
import { BusinessDetail } from '@tenants/Pages/HG/BusinessDetail/BusinessDetail';
import { notifyError } from '@helpers/toastrHelper';
import { errorToString } from '@helpers/error.helper';
import {
    IndividualDetail,
    PersonalDetail,
} from '@tenants/Pages/HG/IndividualDetail/IndividualDetail';
import { EndInvestorTypeSelection } from '@tenants/Pages/HG/EndinvestorTypeSelection/EndInvestorTypeSelection';
import { isBeforeTwentyFourHours } from '@helpers/Product.helper';

const ProductProspectiveInvestorAlert: React.FC = () => {
    const { currentUser, prospectiveInvestor } = useAuthState();
    const navigate = useNavigate();

    const [showUkTimer, setShowUkTimer] = useState<boolean>(false);
    const [ukEndDate, setUkEndDate] = useState<Date>();
    const { getCurrentUserData } = useAuthState();

    const [alertBoxInfo, setAlertBoxInfo] = useState<{
        title: string;
        message: string | React.ReactNode;
        alertType: 'info' | 'warning' | 'success' | 'error';
    }>();

    const finalizeRegistrationHandler = useCallback(
        (prospectiveInvestor: ProspectiveInvestorResponse) => {
            const convertToEndInvestor = (
                extraDetail: BusinessEntityDetails | PersonalDetail,
                endInvestorType: EndInvestorType
            ) => {
                ProspectiveInvestorsPutConvert(
                    prospectiveInvestor._id,
                    extraDetail,
                    endInvestorType
                )
                    .then(async () => {
                        await getCurrentUserData();
                        // Add a single nop
                        setTimeout(() => {
                            navigate('../subscriptions/create');
                        });
                    })
                    .catch((error) => notifyError(errorToString(error)))
                    .finally(() => {
                        ModalService.getInstance().hideModal();
                    });
            };

            const showBusinessDetail = () => {
                ModalService.getInstance()
                    .setShowHeader(true)
                    .setTitle('Business Entity Details')
                    .setBodyStyle({ maxHeight: 'calc(100vh - 78px)' })
                    .setComponent(
                        <div>
                            <BusinessDetail
                                onSubmit={(
                                    businessDetail: BusinessEntityDetails
                                ) => {
                                    convertToEndInvestor(
                                        businessDetail,
                                        EndInvestorType.businessEntity
                                    );
                                }}
                            />
                        </div>
                    )
                    .setShowFooter(false)
                    .showModal();
            };

            const showPersonalDetail = () => {
                ModalService.getInstance()
                    .setShowHeader(true)
                    .setTitle('Personal Detail')
                    .setBodyClassName('max-h-screen')
                    .setComponent(
                        <div>
                            <IndividualDetail
                                onSubmit={(personalDetail: PersonalDetail) => {
                                    convertToEndInvestor(
                                        personalDetail,
                                        EndInvestorType.individual
                                    );
                                }}
                            />
                        </div>
                    )
                    .setShowFooter(false)
                    .showModal();
            };

            if (
                prospectiveInvestor?.investorCategory ===
                InvestorCategoryType.Institutional
            ) {
                showBusinessDetail();
            } else {
                ModalService.getInstance()
                    .setShowHeader(false)
                    .setShowFooter(false)
                    .setComponent(
                        <EndInvestorTypeSelection
                            onSubmit={(endInvestorType: EndInvestorType) => {
                                if (
                                    endInvestorType ===
                                    EndInvestorType.businessEntity
                                ) {
                                    showBusinessDetail();
                                } else {
                                    showPersonalDetail();
                                }
                            }}
                        />
                    )
                    .showModal();
            }
        },
        [getCurrentUserData, navigate]
    );

    useEffect(() => {
        if (prospectiveInvestor) {
            // Need to work out if the investor is from the UK or not
            const isUKInvestor =
                prospectiveInvestor?.countryCodeOfResidence == 'GB';
            let canInvestNow = true;

            if (
                isUKInvestor &&
                prospectiveInvestor?.status ==
                    ProspectiveInvestorStatusEnum.approved
            ) {
                const ukEndDateUtc = dayjs(ukEndDate).add(24, 'hour');

                // if the current date is past the end date, then don't show the timer
                if (isBeforeTwentyFourHours(prospectiveInvestor)) {
                    setUkEndDate(ukEndDateUtc.toDate());
                    setShowUkTimer(true);
                    canInvestNow = false;
                } else {
                    setShowUkTimer(false);
                }
            }

            // TODO: this needs to be updated to take the status on the actual
            // prospective investor

            switch (prospectiveInvestor?.status) {
                case ProspectiveInvestorStatusEnum.awaitingAssessment:
                    setAlertBoxInfo({
                        title: 'Application Incomplete',
                        message: (
                            <div>
                                <p>
                                    Your application has not been fully
                                    completed. Please complete the suitability
                                    questionnaire.{' '}
                                </p>

                                <div className="flex flex-row-reverse">
                                    <Button
                                        label="Complete Application"
                                        onClick={() => {
                                            navigate(
                                                '../suitability-questionnaire'
                                            );
                                        }}
                                    />
                                </div>
                            </div>
                        ),
                        alertType: 'warning',
                    });
                    break;
                case ProspectiveInvestorStatusEnum.awaitingReview:
                    setAlertBoxInfo({
                        title: 'Application Processing',
                        message:
                            'Your application is pending approval. We have received your application and will be in touch shortly.',
                        alertType: 'warning',
                    });
                    break;
                case ProspectiveInvestorStatusEnum.approved: {
                    if (isUKInvestor && !canInvestNow) {
                        setAlertBoxInfo({
                            title: 'Take a moment to think about your investment.',
                            message:
                                'The Financial Conduct Authority (FCA) requires a 24-hour cooling off period for first time Hg investors in order to be sure that you have taken time to consider whether this is the right investment for you. Once you have completed your profile and the cooling off period has passed, you will be able to invest.',
                            alertType: 'warning',
                        });
                    } else {
                        setAlertBoxInfo({
                            title: 'Approved',
                            message: (
                                <div className="flex flex-col md:flex-row ">
                                    <div className="pb-4 md:pr-3 flex-1">
                                        Congratulations, your application has
                                        been approved to invest on the platform.
                                        To complete the registration process and
                                        begin investing, click here:
                                    </div>

                                    {!prospectiveInvestor.endInvestorProfileId && (
                                        <div>
                                            <Button
                                                label="Finalise Registration"
                                                onClick={() =>
                                                    finalizeRegistrationHandler(
                                                        prospectiveInvestor
                                                    )
                                                }
                                            />
                                        </div>
                                    )}
                                </div>
                            ),

                            alertType: 'success',
                        });
                    }
                    break;
                }

                case ProspectiveInvestorStatusEnum.rejected:
                    setAlertBoxInfo({
                        title: 'Application Rejected',
                        message:
                            'Your application has been rejected. Please contact us for more information.',
                        alertType: 'error',
                    });
                    break;
                default:
                    break;
            }
        }
    }, [
        currentUser,
        finalizeRegistrationHandler,
        navigate,
        prospectiveInvestor,
    ]);

    return (
        <div>
            {showUkTimer && (
                <div className="text-right">
                    <p>Time left until you can invest in a fund:</p>
                    <CountdownTimer
                        endDate={ukEndDate}
                        onTimerEnd={() => {
                            setShowUkTimer(false);
                        }}
                    />
                </div>
            )}

            {alertBoxInfo && (
                <AlertBox
                    alertType={alertBoxInfo.alertType}
                    title={alertBoxInfo.title}
                    message={alertBoxInfo.message}
                />
            )}
        </div>
    );
};

export default ProductProspectiveInvestorAlert;
