import classnames from 'classnames';
import { Icons } from '@components/Atoms';

interface SubHeaderListProps {
    list: { label: string; value: string }[];
    layout?: 'vertical' | 'horizontal';
}

export const SubHeaderList = ({
    list,
    layout = 'horizontal',
}: SubHeaderListProps) => {
    const style = classnames('flex flex-col lg:flex-row', {
        'flex-col': layout === 'vertical',
        'flex-row': layout === 'horizontal',
    });

    return (
        <div className={style}>
            {list
                .filter((item) => item.label && item.value)
                .map((item) => (
                    <div
                        key={item.label}
                        className="flex flex-wrap items-center mt-2  mb-2 md:mb-0 md:mr-3 text-md"
                    >
                        <Icons name="CalendarDaysIcon" size="medium" />
                        <span className="font-semibold mr-2 ml-1">
                            {item.label}
                        </span>
                        <span className="mr-2">{item.value}</span>
                    </div>
                ))}
        </div>
    );
};
