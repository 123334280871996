import DesktopTableRow from './DesktopTableRow';
import { RowItem } from './MobileTable';

export const DesktopTableBody = ({
    columnNames,
    rows,
    getSystemUserName,
}: {
    columnNames: string[];
    rows: RowItem[];
    getSystemUserName: (val: string) => void;
}) => {
    return (
        <tbody>
            {(!rows || rows.length === 0) && (
                <tr>
                    <td colSpan={columnNames.length + 1} className="p-2">
                        No redemptions have been created
                    </td>
                </tr>
            )}
            {rows.map((item: RowItem) => (
                <DesktopTableRow
                    key={item._id}
                    columnNames={columnNames}
                    rowItem={item}
                    getSystemUserName={getSystemUserName}
                />
            ))}
        </tbody>
    );
};
