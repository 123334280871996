import React from 'react';
import {
    Field,
    ErrorMessage,
    FieldMetaProps,
    FieldInputProps,
    useField,
} from 'formik';

// import { Multiselect } from '@components/Molecules';
import { notNull } from '@helpers/typeGuards';
import { MultiselectCreatable } from '../MultiselectCreatable';

interface Option {
    value: string;
    label: string;
}

interface FormMultiselectCreatableProps {
    options?: Option[];
    label: string;
    placeholder?: string;
    name: string;
    value?: any;
    isFieldValuesArray?: boolean;
    isClearable?: boolean;
    id?: string;
    disabled?: boolean;
    required?: boolean;
    isSingle?: boolean;
    onChange?: (newValue: any) => void;
}

const FormMultiselectCreatable: React.FC<FormMultiselectCreatableProps> = (
    props
) => {
    const [_, fieldMeta, fieldHelpers] = useField(props.name);

    return (
        <div className="relative w-full cursor-default">
            <Field id={props.id} name={props.name}>
                {({
                    field,
                }: {
                    field: FieldInputProps<any>;
                    meta: FieldMetaProps<any>;
                }) => {
                    const getValue = (data: (Option | string)[]): Option[] => {
                        if (!data) return [];
                        if (props.isFieldValuesArray) {
                            // find the options if data is a string array
                            return data
                                ?.map((a) =>
                                    props?.options?.find((b) => b.value == a)
                                )
                                .filter(notNull);
                        }
                        return data as Option[];
                    };

                    return (
                        <MultiselectCreatable
                            {...field}
                            handleChange={(options: any) => {
                                let tempOptions = props.isSingle
                                    ? options
                                    : [...options];
                                if (props.isFieldValuesArray === true) {
                                    tempOptions = tempOptions.map((el: any) => {
                                        return el.value;
                                    });
                                }
                                fieldHelpers.setValue(tempOptions);

                                if (props.onChange) {
                                    props.onChange(tempOptions);
                                }
                            }}
                            options={props.options || []}
                            label={props.label}
                            defaultValue={getValue(props.value || field.value)}
                            placeholder={props.placeholder || ''}
                            disabled={props.disabled}
                            required={props.required}
                            isSingle={props.isSingle}
                            isClearable={props.isClearable}
                        />
                    );
                }}
            </Field>

            <div className="flex content-start text-red-500 text-sm">
                <ErrorMessage
                    name={props.name}
                    render={(msg) => <p>{msg}</p>}
                />
            </div>
        </div>
    );
};

export default FormMultiselectCreatable;
