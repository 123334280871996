import { Option } from '@components/Molecules';
import { AuthRoleEnum } from '@interfaces/Api';

export const isIntermediaryRequiredForThisRole = (
    roleEnum: AuthRoleEnum | string
): boolean => {
    const isReq = ![
        AuthRoleEnum.assetManagerAdministrator,
        AuthRoleEnum.superUser,
        AuthRoleEnum.fundAdmin,
        AuthRoleEnum.productViewer,
        AuthRoleEnum.profileAdministrator,
    ].includes(roleEnum as AuthRoleEnum);

    return isReq;
};

export const isIntermediaryRequiredForRoles = (
    roles: (AuthRoleEnum | string)[]
): boolean => {
    for (const role of roles) {
        const isReq = isIntermediaryRequiredForThisRole(role);

        if (isReq) {
            return true;
        }
    }

    return false;
};

export const isBookingCenterRequiredForThisRole = (
    roleEnum: AuthRoleEnum | string
): boolean => {
    const isReq = [
        AuthRoleEnum.relationshipManager,
        AuthRoleEnum.externalRelationshipManager,
        AuthRoleEnum.endInvestor,
    ].includes(roleEnum as AuthRoleEnum);

    return isReq;
};

export const isBookingCenterRequiredForRoles = (
    roles: (AuthRoleEnum | string)[]
): boolean => {
    for (const role of roles) {
        const isReq = isBookingCenterRequiredForThisRole(role);

        if (isReq) {
            return true;
        }
    }

    return false;
};

export const isAssetManagerRequiredForThisRole = (
    roleEnum: AuthRoleEnum | string
): boolean =>
    [AuthRoleEnum.assetManagerAdministrator].includes(roleEnum as AuthRoleEnum);

export const isAssetManagerRequiredForRoles = (
    roles: (AuthRoleEnum | string)[]
): boolean => {
    for (const role of roles) {
        const isReq = isAssetManagerRequiredForThisRole(role);

        if (isReq) {
            return true;
        }
    }

    return false;
};

export const transformAvailableRolesOptionsToStringArr = (
    values: any
): AuthRoleEnum[] =>
    (values?.availableRoles || []).map(({ value }: Option) => value);
