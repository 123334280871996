import { useState } from 'react';
import * as Yup from 'yup';
import { useFormikContext } from 'formik';

import { ModalForm } from '@components/Organisms';
import { IModalForm } from '@components/Organisms/ModalForm/ModalForm';
import { BankAccountFields } from './BankAccountFields';
import { BankAccount } from 'common';

interface AddBankAccountProps {
    addBankAccount: (bankAccount: BankAccount) => void;
    disabled?: boolean;
}

export const AddBankAccount: React.FC<AddBankAccountProps> = ({
    addBankAccount,
    disabled,
}) => {
    const { setFieldValue, values: formValues } = useFormikContext();
    const [showModal, toggleModal] = useState(false);

    const validationSchemWithNameSpace: Yup.SchemaOf<{
        [key: string]: string;
    }> = Yup.object().shape(
        BankAccountFields.reduce((acc, field) => {
            return {
                ...acc,
                [field.name]: Yup.string().required('Required'),
            };
        }, {})
    ) as Yup.SchemaOf<{
        [key: string]: string;
    }>;

    const handleSubmit = async (values) => {
        addBankAccount(values);

        toggleModal(false);

        if (formValues['bankAccountName'] === '') {
            setFieldValue('bankAccountName', values.bankName);
        }
    };

    const modalFormProps: IModalForm = {
        fields: BankAccountFields,
        handleSubmit,
        initialValues: {
            bankName: '',
            accountHolderName: '',
            accountCode: '',
            IBAN: '',
            countryCodeOfAccount: '',
            countryCurrencyCode: '',
        },
        label: 'Add a bank account',
        showModal,
        toggleModal,
        validationSchema: validationSchemWithNameSpace,
        disabled,
    };

    return <ModalForm {...modalFormProps} />;
};
