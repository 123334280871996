import { Document, TaskListResponse } from '@interfaces/Api';

export enum NoticeType {
    CapitalCall = 'CapitalCall',
    CapitalCallPaid = 'CapitalCallPaid',
    Distribution = 'Distribution',
    QuarterlyReport = 'QuarterlyReport',
    MiFIDCostsandCharges = 'MiFIDCostsandCharges',
}

export const noticeTypeLabels: { [key in NoticeType]: string } = {
    CapitalCall: 'Capital Call',
    CapitalCallPaid: 'Capital Call Paid',
    Distribution: 'Distribution Notice',
    QuarterlyReport: 'Quarterly Report',
    MiFIDCostsandCharges: 'MiFID Costs and Charges',
};

export const noticeTimelineLabels: { [key in NoticeType]: string } = {
    CapitalCall: 'Capital Called',
    CapitalCallPaid: 'Paid',
    Distribution: 'Distribution',
    QuarterlyReport: 'Quarterly Report',
    MiFIDCostsandCharges: 'MiFID Costs and Charges',
};

export enum EmailDeliveryStatusEnum {
    sent = 'sent',
}

export interface MailingListAdressee {
    email: string;
    firstName?: string;
    lastName?: string;
    status?: EmailDeliveryStatusEnum;
}

export interface SubscriptionNotice {
    amount: number;
    noticeType: NoticeType;
    dueDate?: Date;
    comment: string;
    documents?: Document[];
    uploadedBy?: any;
    uploadedAt?: Date;
    parentEventId?: string;
    eventId?: string;
    mailingList?: MailingListAdressee[];
    tasks?: TaskListResponse[];
}

export interface LifecycleNotice extends SubscriptionNotice {
    children?: SubscriptionNotice[];
}
export interface SubscriptionPaidNotice extends SubscriptionNotice {
    amountPaid: number;
}
