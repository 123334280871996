import React, { useMemo } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import {
    AuthPermissionEnum,
    AuthRoleEnum,
    SubscriptionStatusEnum,
} from '@interfaces/Api';

import { useAuthState } from '@contexts/AuthContext';

import { SubscriptionStatusBadge } from '@components/Organisms';
import { Button, Icons, Tooltip } from '@components/Atoms';
import { getProductDetails } from '@helpers/Product.helper';
import { formatDate } from '@helpers/Date.helper';

interface SubscriptionListItemElemProps {
    subscriptionId: string;
    subscriptionUrl: string;
    subscriptionStatus: SubscriptionStatusEnum;
    endInvestorName?: string;
    shareClassName: string;
    subscriptionAmountFormatted: string;
    productName: string;
    status: SubscriptionStatusEnum;
    onDeleteSubscription?: (subscriptionId: string) => {};
    statusLogLatestDate?: Date;
}

const SubscriptionListItemElem: React.FC<SubscriptionListItemElemProps> = (
    props
) => {
    const { t } = useTranslation();
    const { hasRoles } = useAuthState();
    const isEndInvestorNameVisible =
        !window.location.pathname.includes('/clients') &&
        hasRoles([AuthRoleEnum.fundAdmin]);
    const isStatusLogLatestDateVisible = props.statusLogLatestDate;
    const canDeleteSubscription =
        props.onDeleteSubscription &&
        props.subscriptionStatus === SubscriptionStatusEnum.inProgress;

    const productDetails = useMemo(() => {
        if (props.productName)
            return getProductDetails({
                productName: props.productName,
                shareClassName: props.shareClassName,
                subscriptionAmount: props.subscriptionAmountFormatted,
            });
    }, [
        props.productName,
        props.shareClassName,
        props.subscriptionAmountFormatted,
    ]);

    return (
        <div
            key={props.subscriptionId}
            className="bg-white border-b pb-1 my-4 md:my-0 flex flex-col md:flex-row justify-between items-center"
        >
            <div className="items-center md:items-left md:w-3/12 my-2">
                <Link to={props.subscriptionUrl}>
                    <SubscriptionStatusBadge
                        subscriptionStatus={props.subscriptionStatus}
                    />
                </Link>
            </div>

            {isEndInvestorNameVisible && (
                <div className="text-center md:text-left md:w-4/12 pl-2 my-2">
                    <Link to={props.subscriptionUrl}>
                        <h3 className="text-base pr-3 md:pr-2 cursor-pointer">
                            {props.endInvestorName}
                        </h3>
                    </Link>
                </div>
            )}

            <div
                className={`text-center md:text-left md:${
                    hasRoles([AuthRoleEnum.fundAdmin]) ? 'w-5/12' : 'w-9/12'
                } pl-2 my-2`}
            >
                <Link to={props.subscriptionUrl}>
                    <h3 className="text-base pr-3 md:pr-2 cursor-pointer">
                        {productDetails}
                    </h3>
                </Link>
            </div>

            {isStatusLogLatestDateVisible && (
                <div className="text-center text-sm min-w-220 md:text-right">
                    <p>
                        {t('ui.labels.latest_activity')}:{' '}
                        {formatDate(
                            new Date(props.statusLogLatestDate).toISOString()
                        )}
                    </p>
                </div>
            )}

            <div className="px-2 border-l ml-2 w-10 flex items-center">
                {canDeleteSubscription && (
                    <Button
                        onClick={() => {
                            props.onDeleteSubscription &&
                                props.onDeleteSubscription(
                                    props.subscriptionId!
                                );
                        }}
                        hasIconOnly
                        startIcon={
                            <Tooltip tip="Delete subscription">
                                <Icons name="TrashIcon" />
                            </Tooltip>
                        }
                    />
                )}
            </div>
        </div>
    );
};

export default SubscriptionListItemElem;
