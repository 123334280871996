import { InputHTMLAttributes } from 'react';

type CheckboxProps = InputHTMLAttributes<HTMLInputElement> & {
    name: string;
    label?: string | JSX.Element;
    onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
};

const Checkbox: React.FC<CheckboxProps> = (props) => {
    const { name, label, onChange } = props;

    return (
        <>
            <input
                type="checkbox"
                onChange={onChange}
                data-testid="input-checkbox"
                {...props}
                id={name}
            />
            <label htmlFor={name} className="ml-2 cursor-pointer select-none">
                {label}
            </label>
        </>
    );
};

export default Checkbox;
