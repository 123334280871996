import { ProductResponse, ProductStatusEnum } from '@interfaces/Api';
import {
    CalendarTimeEnum,
    DateFrequencyEnum,
    KeyDate,
} from '@interfaces/Api/ProductResponse';

import dayjs from 'dayjs';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import quarterOfYear from 'dayjs/plugin/quarterOfYear';
import { dateShortFormatter } from './Date.helper';
import { ProspectiveInvestorResponse } from '@interfaces/Api/ProspectiveInvestorResponse';

dayjs.extend(advancedFormat);
dayjs.extend(quarterOfYear);

export const getUmbrellaFundNameAndProductName = (product: ProductResponse) => {
    return [product.umbrellaFundName, product.name].filter(Boolean).join(' - ');
};

/**
 * Finds the next upcoming subscription deadline from a list of key dates
 * @param dates
 * @returns
 */
export const getUpcomingSignedSubscriptionDeadline = (dates: KeyDate[]) => {
    const now = new Date();

    return dates
        .filter(({ signedSubscriptionDeadline }) => {
            if (!signedSubscriptionDeadline) return false;
            return +new Date(signedSubscriptionDeadline) > +now;
        })
        .sort((firstKeyDate, secondKeyDate) => {
            return (
                +new Date(firstKeyDate?.signedSubscriptionDeadline!) -
                +new Date(secondKeyDate?.signedSubscriptionDeadline!)
            );
        })
        .shift();
};

/**
 * Finds the next upcoming subscription deadline from a list of key dates
 * @param dates
 * @returns
 */
export const getUpcomingClosingDate = (dates: KeyDate[]) => {
    const now = new Date();

    return dates
        .filter(({ closingDate }) => {
            if (!closingDate) return false;
            return +new Date(closingDate) > +now;
        })
        .sort((firstKeyDate, secondKeyDate) => {
            return (
                +new Date(firstKeyDate?.closingDate!) -
                +new Date(secondKeyDate?.closingDate!)
            );
        })
        .shift();
};

export const getNextUpcomingClosingDate = (
    dates: KeyDate[]
): KeyDate | undefined => {
    const now = new Date();

    return dates
        .filter(({ closingDate }) => {
            if (!closingDate) return false;
            return +new Date(closingDate) > +now;
        })
        .sort((firstKeyDate, secondKeyDate) => {
            return (
                +new Date(firstKeyDate?.closingDate!) -
                +new Date(secondKeyDate?.closingDate!)
            );
        })
        .splice(1, 1)?.[0];
};

export const getPublishedProducts = (products: ProductResponse[]) =>
    products.filter(({ status }) => status === ProductStatusEnum.published);

export const getProductDetails = ({
    productName,
    shareClassName,
    subscriptionAmount,
}: {
    productName?: string;
    shareClassName?: string;
    subscriptionAmount?: string;
}) =>
    [productName, shareClassName, subscriptionAmount]
        .filter((a) => a)
        .join(' - ');

export const handleProductsFiltering = (val: any, searchTextRaw: string) => {
    if (!searchTextRaw) return true;

    return searchTextRaw!
        .toLocaleLowerCase()
        .split(' ')
        .map((searchText) => {
            return (
                val?.umbrellaFundName
                    ?.toLowerCase()
                    ?.includes(searchText.toLowerCase()) ||
                val?.name?.toLowerCase()?.includes(searchText.toLowerCase()) ||
                val?.status
                    ?.toLowerCase()
                    ?.includes(searchText.toLowerCase()) ||
                val?.availabilityStatus
                    ?.toLowerCase()
                    ?.includes(searchText.toLowerCase())
            );
        })
        .every((a) => a === true);
};

export const getNextDateFrequencyDate = (
    dateFrequency: DateFrequencyEnum,
    calendarTime: CalendarTimeEnum,
    noticeDays?: number
) => {
    let now: dayjs.Dayjs = dayjs();

    // If there are notice days, add them to the current date
    if (noticeDays) {
        now = now.add(noticeDays, 'day');
    }

    const dayjsUnitTypeMap = {
        [DateFrequencyEnum.daily]: 'day',
        [DateFrequencyEnum.weekly]: 'week',
        [DateFrequencyEnum.monthly]: 'month',
        [DateFrequencyEnum.quarterly]: 'quarter',
        [DateFrequencyEnum.annually]: 'year',
    };
    const dayjsUnitType = dayjsUnitTypeMap[dateFrequency];

    let nextDate: dayjs.Dayjs = now;
    if (calendarTime === CalendarTimeEnum.firstDay) {
        nextDate = nextDate
            .add(1, dayjsUnitType as any)
            // @ts-ignore, dayjsUnitType for 'quarter' doesn't register in typescript, but does work functionally
            .startOf(dayjsUnitType);
    } else if (calendarTime === CalendarTimeEnum.lastDay) {
        // @ts-ignore, dayjsUnitType for 'quarter' doesn't register in typescript, but does work functionally
        nextDate = nextDate.endOf(dayjsUnitType);
    }
    return nextDate;
};

export const nextDateString = (
    dateFrequency: DateFrequencyEnum,
    calendarTime: CalendarTimeEnum,
    noticeDays: number
) => {
    const date = getNextDateFrequencyDate(
        dateFrequency,
        calendarTime,
        noticeDays
    );

    return dateShortFormatter(date);
};

export const openEndDateFrequencyString = (
    dateFrequency: DateFrequencyEnum,
    calendarTime: CalendarTimeEnum
) => {
    // should return {calendarTime} of the {dateFrequency}
    // should return "first day of the month" if dateFrequency is monthly and calendarTime is firstDay
    // should return "last day of the quarter" if dateFrequency is quarterly and calendarTime is lastDay
    const calendarTimeStringMap: { [key in CalendarTimeEnum]: string } = {
        [CalendarTimeEnum.firstDay]: 'First',
        [CalendarTimeEnum.lastDay]: 'Last',
    };
    const calendarTimeString = calendarTimeStringMap[calendarTime];

    const dateFrequencyStringMap: { [key in DateFrequencyEnum]: string } = {
        [DateFrequencyEnum.daily]: 'day',
        [DateFrequencyEnum.weekly]: 'week',
        [DateFrequencyEnum.monthly]: 'month',
        [DateFrequencyEnum.quarterly]: 'quarter',
        [DateFrequencyEnum.annually]: 'year',
    };
    const dateFrequencyString = dateFrequencyStringMap[dateFrequency];

    return `${calendarTimeString} day of the ${dateFrequencyString}`;
};

export const isBeforeTwentyFourHours = (prospectiveInvestor: ProspectiveInvestorResponse) => {
    const endDate = prospectiveInvestor?.createdDate;
    const endDateUtc = dayjs(endDate).add(24, 'hour');
    return dayjs().isBefore(endDateUtc);
}

