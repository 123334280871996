import { getRedemptionApplicationsBySubscriptionId } from '@api/RedemptionApplications';

import { useSystemUsers } from '@stores/SystemUsers/useSystemUsers';
import { useCallback, useMemo, useState } from 'react';

import { dateTimeFormatter } from '@helpers/Date.helper';
import { errorToString } from '@helpers/error.helper';
import { getFormattedCurrency } from '@helpers/isoCurrencies';
import { notifyError } from '@helpers/toastrHelper';
import { notNull } from '@helpers/typeGuards';
import { SubscriptionLifecycle, SystemUserResponse } from '@interfaces/Api';
import {
    RedemptionApplicationResponse,
    redemptionStatusEnumLabel,
} from '@interfaces/Api/RedemptionApplicationResponse';
import { DesktopTableHeaders } from './Redemptions/DesktopTableHeaders';
import { DesktopTableBody } from './Redemptions/DesktopTableBody';
import { MobileTable, RowItem } from './Redemptions/MobileTable';
import { useMountEffect } from '@hooks/useMountEffect';
import { sum } from '@helpers/Math.helper';

const columnNames = [
    'Redemption Amount',
    'Fee Amount',
    'Requested At',
    'Requested By',
    'Status',
];

interface RedemptionApplicationsTabContentProps {
    lifeCycleData: SubscriptionLifecycle;
}

const RedemptionApplicationsTabContent: React.FC<
    RedemptionApplicationsTabContentProps
> = ({ lifeCycleData }) => {
    const [rows, setRows] = useState<RowItem[]>([]);

    const { systemUsers } = useSystemUsers();

    const usersMap = useMemo(() => {
        return systemUsers.reduce((acc, user) => {
            acc[user._id] = user;
            return acc;
        }, {} as { [key: string]: SystemUserResponse });
    }, [systemUsers]);

    const getSystemUserName = useCallback(
        (userId: string) => {
            const user = usersMap[userId];
            return [user?.firstName, user?.surname].filter(notNull).join(' ');
        },
        [usersMap]
    );

    const updateRows = useCallback(
        (redemptions: RedemptionApplicationResponse[]) => {
            const rows = redemptions.map((redemption) => {
                const fees = redemption.redeemablePositions.map(
                    (a) => a.redemptionFeeAmount
                );
                const feeAmount = sum(...fees);

                return {
                    columns: {
                        [columnNames[0]]: getFormattedCurrency(
                            redemption.redemptionAmount,
                            lifeCycleData.subscription.subscriptionCurrencyCode
                        ),
                        [columnNames[1]]: getFormattedCurrency(
                            feeAmount,
                            lifeCycleData.subscription.subscriptionCurrencyCode
                        ),
                        [columnNames[2]]: dateTimeFormatter(
                            redemption.requestedAt
                        ),
                        [columnNames[3]]: getSystemUserName(
                            redemption.requestedBy
                        ),
                        [columnNames[4]]:
                            redemptionStatusEnumLabel[redemption.status],
                    },
                    redemption: redemption,
                    _id: redemption._id,
                };
            });

            setRows(rows);
        },
        [lifeCycleData, getSystemUserName]
    );

    useMountEffect(() => {
        if (lifeCycleData.subscription.subscriptionId) {
            getRedemptionApplicationsBySubscriptionId(
                lifeCycleData.subscription.subscriptionId
            )
                .then((redemptions) => {
                    updateRows(redemptions);
                })
                .catch((error) => {
                    notifyError(errorToString(error));
                });
        }
    });

    return (
        <div>
            <table className="table-auto w-full rounded-md data-tables-admin md-max:hidden">
                <DesktopTableHeaders columnNames={columnNames} />

                <DesktopTableBody
                    columnNames={columnNames}
                    rows={rows}
                    getSystemUserName={getSystemUserName}
                />
            </table>

            <table className="w-full text-sm md:hidden">
                <MobileTable columnNames={columnNames} rows={rows} />
            </table>
        </div>
    );
};

export default RedemptionApplicationsTabContent;
