import { BaseUrl } from '@helpers/Environment';
import { notifyError } from '@helpers/toastrHelper';
import { ProductResponse } from '@interfaces/Api';
import { Http } from '@libs/http';
import { mergeProducts } from './Products.helper';

const API_PRODUCTS_URL = `${BaseUrl}/products`;

export const handleFetchProducts = async (
    set,
    endInvestorProfileIds?: string[]
) => {
    try {
        set({
            isLoading: true,
        });

        if (endInvestorProfileIds && endInvestorProfileIds.length > 0) {
            Promise.all(
                endInvestorProfileIds.map((endInvestorProfileId) => {
                    const queryParams = { endInvestorProfileId };
                    Http.get<ProductResponse[]>(
                        API_PRODUCTS_URL,
                        undefined,
                        queryParams
                    ).then((products) => {
                        set({
                            products: mergeProducts(products),
                        });
                    });
                })
            );
        } else {
            const products = await Http.get<ProductResponse[]>(
                API_PRODUCTS_URL,
                undefined
            );

            set({
                products,
            });
        }
    } catch (_) {
        notifyError('Failed to fetch products');
    }
    set({
        isLoading: false,
    });
};

export const handlePrioritiseProducts = async (set, body) => {
    const products = await Http.put<ProductResponse[]>(
        `${API_PRODUCTS_URL}/prioritise`,
        body
    );

    set({ products });
};

export const handleFetchProduct = async (set, productId: string) => {};
