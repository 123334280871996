import React from 'react';

interface CardInfoProps {
    title?: string;
    value?: number;
    percentage?: number;
    children?: React.ReactNode;
}

const WidgetDashCardContent: React.FC<CardInfoProps> = ({
    title,
    value,
    percentage,
    children: icon,
}: CardInfoProps) => {
    return (
        <div className="p-4 flex items-center justify-center">
            {icon}
            <div className="grid justify-items-center">
                <p className="mb-2 text-base font-medium text-gray-600 dark:text-gray-400">
                    {title}
                </p>
                <p className="text-lg font-semibold text-gray-700 dark:text-gray-200">
                    {value}
                </p>
                <p className="text-xl text-green-500 leading-tight">
                    {`${percentage ? '▲' : ''}`} {percentage}{' '}
                    {`${percentage ? '%' : ''}`}
                </p>
            </div>
        </div>
    );
};

export default WidgetDashCardContent;
