import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import {
    AuthPermissionEnum,
    AuthRoleEnum,
    EndInvestorProfileResponse,
    SubscriptionTemplateResponse,
} from '@interfaces/Api';

import { useSubscriptionTemplateState } from '@contexts/SubscriptionTemplatesContext';
import { Badge, Button, Icons } from '@components/Atoms';
import { useConfigurationState } from '@contexts/ConfigurationContext';
import { useAuthState } from '@contexts/AuthContext';
import { handleCreateSubscriptionEvent } from '@services/SubscriptionsHelper';

interface SubscriptionListTemplateItemElemProps {
    endInvestorProfile: EndInvestorProfileResponse;
    subscriptionTemplateId: string;
    subscriptionTemplateUrl: string;
    subscriptionTemplateName: string;
    isMaster: boolean;
}

const SubscriptionListTemplateItemElem: React.FC<
    SubscriptionListTemplateItemElemProps
> = (props: SubscriptionListTemplateItemElemProps) => {
    const { t } = useTranslation();
    const {
        endInvestorProfile,
        subscriptionTemplateId,
        subscriptionTemplateUrl,
        subscriptionTemplateName,
        isMaster,
    } = props;
    const navigate = useNavigate();
    const { preferences } = useConfigurationState();
    const { currentUser, hasPermissions } = useAuthState();

    const isCloneNewSubscriptionAvailable =
        (currentUser?.user.role == AuthRoleEnum.relationshipManager &&
            !preferences?.feature.accessControl
                ?.relationshipManagersCannotAddNewSubscriptions) ||
        (currentUser?.user.role !== AuthRoleEnum.relationshipManager &&
            hasPermissions(AuthPermissionEnum.createSubscription));

    const onCloneTemplateButtonClick = async (
        endInvestorProfile: EndInvestorProfileResponse
    ) => {
        await handleCreateSubscriptionEvent(t, navigate, {
            endInvestorProfileId: endInvestorProfile._id,
            subscriptionTemplateId,
        });
    };

    return (
        <div
            key={subscriptionTemplateId}
            className="hover:bg-gray-100 border-b md:border-b border-gray-100 pb-4 md:pb-0 md:px-2 my-4 md:my-0 flex flex-col md:flex-row justify-between items-center md:items-left cursor-pointer"
        >
            <div className="md:w-8/12 my-2">
                <Link
                    to={subscriptionTemplateUrl}
                    className="flex items-center"
                >
                    <div className="text-xs">
                        <Icons name="IconTemplateSolid" />
                    </div>

                    <span className="pl-2 font-light capitalize">
                        {subscriptionTemplateName}
                    </span>
                    {isMaster && (
                        <div className="pl-3">
                            <Badge
                                label={'Master'}
                                badgeType="primary"
                                sizeType="small"
                            />
                        </div>
                    )}
                </Link>
            </div>
            {isCloneNewSubscriptionAvailable && (
                <div className="w-auto px-2 my-2 md:justify-end">
                    <Button
                        label={t('client.subscription_templates.clone_new_subscription')}
                        onClick={() =>
                            onCloneTemplateButtonClick(endInvestorProfile)
                        }
                    />
                </div>
            )}
        </div>
    );
};

export default SubscriptionListTemplateItemElem;
