import { useSystemUsersActions, useSystemUsersState } from './';
import { handleDeleteSystemUser } from './SystemUsers.services';
import { useMountEffect } from '@hooks/useMountEffect';

export const useSystemUsers = () => {
    const { systemUsers, isLoading } = useSystemUsersState();
    const { fetchSystemUsers } = useSystemUsersActions();

    useMountEffect(() => {
        const loadData = async () => {
            await fetchSystemUsers(false);
        };

        loadData();
    });

    const archiveSystemUser = async (systemUserId: string) => {
        await handleDeleteSystemUser(systemUserId);
        await fetchSystemUsers(false);
    };

    return { archiveSystemUser, isLoading, systemUsers };
};
