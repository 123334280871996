import { Icons } from '@components/Atoms';

export const NoResultsRow = () => (
    <tr className="w-full block">
        <td className="py-2">
            <h1 className="text-2xl flex items-center">
                <Icons
                    name="ExclamationTriangleIconOutline"
                    strokeWidth="1.5"
                />
                <span className="ml-2">No System Users listed found</span>
            </h1>
        </td>
    </tr>
);
