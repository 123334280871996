import { ProspectiveInvestorsGetAll, ProspectiveInvestorsDeleteById } from '@api/ProspectiveInvestors';
import { BasicBreadcrumbs, Button, Icons } from '@components/Atoms';
import { mainLayoutWithBreadcrumbsConfig } from '@components/Layout';
import { useAuthState } from '@contexts/AuthContext';
import { errorToString } from '@helpers/error.helper';
import { getCountryDisplayName } from '@helpers/isoCountries';
import { notifyError } from '@helpers/toastrHelper';
import {
    ProspectiveInvestorResponse,
    ProspectiveInvestorStatusEnumLabels,
} from '@interfaces/Api/ProspectiveInvestorResponse';
import { ModalService } from '@services/ModalService';
import classNames from 'classnames';
import { AuthPermissionEnum } from 'common';
import { t } from 'i18next';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';

const ProspectiveInvestorListPage: React.FC = () => {
    const [prospectiveInvestors, setProspectiveInvestors] = useState<
        ProspectiveInvestorResponse[]
    >([]);
    const navigate = useNavigate();
    const { hasPermissions } = useAuthState();

    const getAllUsers = useCallback(() => {
        ProspectiveInvestorsGetAll().then((investors) => {
            setProspectiveInvestors(investors);
        });
    }, []);

    useEffect(() => {
        getAllUsers();
    }, [getAllUsers]);

    const formattedProspectiveInvestors = useMemo(() => {
        return prospectiveInvestors.map((investor) => {
            return {
                id: investor._id,
                name: [
                    investor.personalDetails.firstName,
                    investor.personalDetails.surname,
                ]
                    .filter((a) => a)
                    .join(' '),
                email: investor.personalDetails.email,
                country: getCountryDisplayName(investor.countryCodeOfResidence),
                status: ProspectiveInvestorStatusEnumLabels[investor.status],
                canInvestInFunds: investor.endInvestorProfileId ? 'Yes' : 'No',
                canBeDeleted: !investor.endInvestorProfileId,
            };
        });
    }, [prospectiveInvestors]);

    const deleteUser = useCallback((prospectiveInvestorId: string) => {
        ModalService.getInstance()
            .setShowFooter(true)
            .setTitle(t('system_user.archive_user'))
            .setComponent(t('system_user.confirm_to_delete_user'))
            .setConfirmationLabel(t('system_user.delete'))
            .setConfirmCallback(() => {
                ProspectiveInvestorsDeleteById(prospectiveInvestorId).then(() => {
                    getAllUsers()
                }).catch(e => notifyError(errorToString(e)))
                .finally(() => {
                    ModalService
                        .getInstance()
                        .hideModal();
                })
            })
            .setShowFooter(true)
            .showModal();
    }, [getAllUsers]);

    return (
        <div className="h-full pb-9">
            <div className="xs:container xs:mx-auto max-w-full">
                <div className="flex flex-col items-stretch">
                    <div className="flex justify-between w-full mb-5">
                        <h1 className="text-3xl text-logo-blue align-middle font-bold">
                            Prospective Investors
                        </h1>
                        <div>
                            <Button
                                label="Set defaults"
                                onClick={() => {
                                    navigate(
                                        '/admin/prospective-investors-defaults'
                                    );
                                }}
                            />
                        </div>
                    </div>

                    <div className="flex flex-row flex-wrap bg-brand-navy-light h-10 p-2 align-middle rounded-md">
                        <div className="w-6/12">
                            <h4 className="text-sm md:text-md font-bold">
                                Name
                            </h4>
                        </div>
                        <div className="hidden sm:block w-2/12">
                            <h4 className="text-sm md:text-md font-bold">
                                Country
                            </h4>
                        </div>
                        <div className="w-3/12 md:w-2/12">
                            <h4 className="text-sm md:text-md font-bold">
                                Status
                            </h4>
                        </div>
                        <div className="w-1/12">
                            <h4 className="text-sm md:text-md font-bold">
                                Can Invest
                            </h4>
                        </div>
                        <div className="w-1/12">
                        </div>
                    </div>

                    <div
                        className={classNames('list p-2', {
                            // 'list-grid': isDetailedView,
                        })}
                    >
                        {prospectiveInvestors.length === 0 ? (
                            <div className="flex flex-col items-center justify-center h-full">
                                <h1 className="text-xl">No investors listed</h1>
                            </div>
                        ) : (
                            formattedProspectiveInvestors.map((investor) => (
                                <div
                                    key={investor.id}
                                    className="bg-white border-b flex flex-wrap justify-between py-2"
                                >
                                    <div className="w-6/12">
                                        <Link to={`${investor.id}`}>
                                            <p className="text-sm md:text-md font-bold">
                                                {investor.name}
                                            </p>
                                            <p className="text-sm md:text-md">
                                                {investor.email}
                                            </p>
                                        </Link>
                                    </div>
                                    <div className="hidden sm:block w-2/12">
                                        <p className="text-sm md:text-md">
                                            {investor.country}
                                        </p>
                                    </div>
                                    <div className="w-1/12 md:w-2/12">
                                        <p className="text-sm md:text-md">
                                            {investor.status}
                                        </p>
                                    </div>
                                    <div className="w-1/12">
                                        <p className="text-sm md:text-md">
                                            {investor.canInvestInFunds}
                                        </p>
                                    </div>
                                    <div className="w-1/12">
                                        {
                                            investor.canBeDeleted && hasPermissions(AuthPermissionEnum.deleteProspectiveInvestor) && <a
                                                className="cursor-pointer"
                                                onClick={() => {
                                                    deleteUser(investor.id)
                                                }}
                                            >
                                                <Icons
                                                    name="TrashIcon"
                                                    stroke="black"
                                                    strokeWidth="1.5"
                                                />
                                            </a>
                                        }
                                    </div>
                                </div>
                            ))
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};

export const ProspectiveInvestorListPageBreadcrumbs: React.FC = () => {
    const items = [
        {
            displayText: 'Prospective Investors',
            navigationUrl: '/admin/prospective-investors',
        },
    ];

    return <BasicBreadcrumbs items={items} />;
};

export default mainLayoutWithBreadcrumbsConfig(
    ProspectiveInvestorListPage,
    undefined,
    <ProspectiveInvestorListPageBreadcrumbs />
);
