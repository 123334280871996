import { Form, Formik } from 'formik';
import * as Yup from 'yup';

import { SuitabilityQuestion } from 'common';
import { Button } from '@components/Atoms';
import {
    FormRadioGroup,
    FormCheckboxWithInputList,
} from '@components/Molecules';

type MultiSelect1Type = {
    value: boolean;
    label: string;
    key: string;
    extraInput: {
        inputsName: string;
        inputLabel: string;
        inputValue: string;
    };
    isSingleSelect: boolean;
};

const QuestionComponent = (props: {
    question: SuitabilityQuestion;
    answer: { answerId: string; extraText?: string }[];
    onNext: (
        answer:
            | { answerId: string }
            | { answerId: string; extraText?: string }[]
    ) => void;
    nextText: string;
    onPrevious: () => void;
}) => {
    const answerOptions =
        props.question.answers.map((answer) => {
            return {
                label: answer.answerText,
                value: answer.answerId,
            };
        }) || [];

    const handleNext = (formValue: {
        answer: string;
        'custom-multiselect-1': MultiSelect1Type[];
    }) => {
        if (formValue.answer) {
            props.onNext({ answerId: formValue.answer });
        } else if (formValue['custom-multiselect-1']) {
            const aIds = formValue['custom-multiselect-1']
                .filter((a) => {
                    return a.value == true;
                })
                .map((a) => {
                    return {
                        answerId: a.key,
                        extraText: a?.extraInput?.inputValue,
                    };
                });

            props.onNext(aIds);
        }
    };

    // some sort of validation schema
    let validationSchema;
    let initialValues;

    if (props.question.questionType === 'single-select') {
        const inputVal = props.answer ? props.answer[0].answerId : undefined;

        initialValues = {
            answer: inputVal,
        };
        validationSchema = Yup.object().shape({
            answer: Yup.string().required('Required'),
        });
    } else if (props.question.questionType === 'custom-multiselect-1') {
        // TODO: need to make sure the below works
        const customMultiselect1Options: MultiSelect1Type[] =
            props.question.answers.map((a) => {
                const currentAnswer = props.answer?.find(
                    (b) => b.answerId == a.answerId
                );

                const extraInput = a.customTextOptions
                    ? {
                          inputsName: 'customText',
                          inputLabel: a.customTextOptions.label,
                          inputValue: currentAnswer
                              ? currentAnswer?.extraText
                              : '',
                      }
                    : undefined;

                return {
                    value: !!currentAnswer?.answerId,
                    label: a.answerText,
                    key: a.answerId,
                    extraInput,
                    isSingleSelect: a.isSingleSelect,
                };
            });
        initialValues = {
            'custom-multiselect-1': customMultiselect1Options,
        };

        validationSchema = Yup.object().shape({
            'custom-multiselect-1': Yup.array()
                .of(
                    Yup.object().shape({
                        value: Yup.boolean(),
                    })
                )
                .test(
                    'at-least-one',
                    'At least one answer is required',
                    (value) => {
                        return value.some((a) => a.value == true);
                    }
                )
                .test(
                    'none-of-the-above',
                    'Can\'t select "None of the above" if other options are selected',
                    (value) => {
                        const isSingleSelect =
                            value.filter(
                                (a) =>
                                    a.isSingleSelect == true && a.value == true
                            ).length == 1;

                        if (!isSingleSelect) {
                            // if there is no single select, then this validation doesn't apply
                            return true;
                        }

                        const isOnlyOneSelected =
                            value.filter((a) => a.value == true).length == 1;

                        const result = isSingleSelect && isOnlyOneSelected;

                        return result;
                    }
                ),
        });
    }

    return (
        <div>
            <h2 className="text-lg logo-blue align-middle pb-4">
                {props.question.questionId}. {props.question.questionText}
            </h2>

            <Formik
                initialValues={initialValues}
                enableReinitialize={true}
                validateOnChange={true}
                onSubmit={handleNext}
                validationSchema={validationSchema}
            >
                {(formikProps) => (
                    <Form>
                        {/* only show the formradiogroup for single-select */}
                        {props.question.questionType === 'single-select' && (
                            <FormRadioGroup
                                name="answer"
                                optionsData={answerOptions}
                            />
                        )}

                        {props.question.questionType ===
                            'custom-multiselect-1' && (
                            <FormCheckboxWithInputList
                                name="custom-multiselect-1"
                                checkboxLabel=""
                            />
                        )}

                        {props.question?.footNote && (
                            <p className="text-sm mt-2">
                                {props.question?.footNote}
                            </p>
                        )}

                        {/* next and back button */}
                        <div className="flex flex-row-reverse justify-between pt-4 pb-8">
                            <Button
                                disabled={!formikProps.isValid}
                                type="submit"
                                buttonType="primary"
                                label={props.nextText}
                            />

                            {props.onPrevious && (
                                <Button
                                    type="button"
                                    buttonType="secondary"
                                    label="Back"
                                    onClick={props.onPrevious}
                                />
                            )}
                        </div>
                        {/* <div>
                            <pre>
                                {JSON.stringify(formikProps.isValid, null, 2)}
                            </pre>
                        </div> */}
                    </Form>
                )}
            </Formik>
        </div>
    );
};

export default QuestionComponent;
