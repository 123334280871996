import { isoCurrenciesOptions } from '@helpers/isoCurrencies';
import { isoCountriesOptions } from '@helpers/isoCountries';

export const BankAccountFields = [
    {
        name: 'bankName',
        label: 'Bank Name',
        placeholder: 'Bank Name',
        type: 'input' as const,
    },
    {
        name: 'countryCurrencyCode',
        label: 'Currency',
        placeholder: 'Currency',
        options: isoCurrenciesOptions,
        type: 'select' as const,
    },
    {
        name: 'countryCodeOfAccount',
        label: 'Country Of Account',
        placeholder: 'Country Of Account',
        options: isoCountriesOptions,
        type: 'select' as const,
    },
    {
        name: 'IBAN',
        label: 'IBAN',
        placeholder: 'IBAN',
        type: 'input' as const,
    },
    {
        name: 'accountHolderName',
        label: 'Account holder name',
        placeholder: 'Account holder name',
        type: 'input' as const,
    },
    {
        name: 'accountCode',
        label: 'Account code',
        placeholder: 'Account code',
        type: 'input' as const,
    },
];
