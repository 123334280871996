import { useState } from 'react';
import classnames from 'classnames';

import { Badge, Button, Icons } from '@components/Atoms';
import { LiquidityTypeEnum } from '@interfaces/Api';

import { SubHeaderList } from '../SubHeaderList';
import { Tile } from '../Tile';
import { PerformanceTileContent } from './ClosedEndedEndInvestorFundCard';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
export interface EndInvestorFundCardProps {
    fundType: LiquidityTypeEnum;
    leftBoxChildren?: JSX.Element;
    onClick?: () => void;
    productName: string;
    rightBoxChildren?: JSX.Element;
    subHeaderList: { label: string; value: string }[];
    tiles: PerformanceTileContent[];
    subscriptionId: string;
}

export const EndInvestorFundCard = (props: EndInvestorFundCardProps) => {
    const { t } = useTranslation();

    const [isCardCollapsed, setIsCardCollapsed] = useState(false);

    const {
        fundType,
        leftBoxChildren,
        onClick,
        productName,
        rightBoxChildren,
        subHeaderList,
        tiles,
        subscriptionId,
    } = props;
    const isFundCloseEnded = fundType.includes('close');

    const style = classnames('border relative rounded-xl pb-3 p-2 shadow-md', {
        'px-6 pt-6 pb-12': !isCardCollapsed,
        'p-6': isCardCollapsed,
    });

    return (
        <div className={style}>
            <header className="mb-5">
                <div className="flex justify-between mb-3 ">
                    <div className="flex flex-col lg:flex-row ">
                        <Link
                            to={`subscription/${subscriptionId}`}
                            title={productName}
                        >
                            <h2 className="text-3xl font-bold">
                                {productName}
                            </h2>
                        </Link>
                        <div className="flex items-center lg:ml-3">
                            <Badge
                                badgeType="supplementary"
                                sizeType="medium"
                                label={
                                    isFundCloseEnded
                                        ? t('liquidity_type.closed_ended')
                                        : t('liquidity_type.open_ended')
                                }
                            />
                        </div>
                    </div>

                    <Button
                        hasIconOnly
                        onClick={() =>
                            setIsCardCollapsed((prevState) => !prevState)
                        }
                        startIcon={
                            <Icons
                                name={
                                    isCardCollapsed
                                        ? 'ChevronDown'
                                        : 'ChevronUp'
                                }
                                size="medium"
                            />
                        }
                    />
                </div>
                <div className="flex flex-col md:flex-row ">
                    <SubHeaderList list={subHeaderList} />
                </div>
            </header>

            {isCardCollapsed ? null : (
                <div className="flex flex-col lg:flex-row justify-between lg:items-end border-t pt-2">
                    {leftBoxChildren && (
                        <div className="flex flex-col lg:mr-2 pr-2 mb-12 flex-1">
                            {leftBoxChildren}
                        </div>
                    )}

                    {tiles.length > 0 && (
                        <div className={`grid grid-rows-2 grid-cols-2 p-2 ${isFundCloseEnded ? `lg:mb-10` : `lg:mb-16`}`}>
                            {tiles.map(
                                (tile: PerformanceTileContent, i: number) => (
                                    <div key={i} className="mr-2 mb-2 align-text-top">
                                        <Tile
                                            iconPos="right"
                                            list={tile.content}
                                            isFixedHeight
                                            isFixedWidth
                                            spaceBetweenItems
                                            backgroundColor={
                                                tile.backgroundColor
                                            }
                                        />
                                    </div>
                                )
                            )}
                        </div>
                    )}

                    {rightBoxChildren && (
                        <div className="px-2 basis-1/2">{rightBoxChildren}</div>
                    )}
                </div>
            )}

            {onClick && !isCardCollapsed && (
                <div className="w-40 absolute -bottom-4 left-2/4 -ml-20">
                    <Button
                        onClick={onClick}
                        label={t('client.portfolio.card.discover_more')}
                        buttonType="secondary"
                        className="w-full shadow-md"
                    />
                </div>
            )}
        </div>
    );
};
