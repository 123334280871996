import { BaseUrl } from '@helpers/Environment';
import { Http } from '../libs/http';

export const STORAGE_TOKEN_URL = `${BaseUrl}/storage-token`;

interface TokenResponse {
    token: string;
}

export const storageTokenGet = () => {
    return Http.get<TokenResponse>(STORAGE_TOKEN_URL);
};
