import { RedemptionApplicationSigningDocument } from './SigningDocument';
import { SubscriptionResponse } from './SubscriptionResponse';
import { SystemUserResponse } from './SystemUserResponse';

export enum RedemptionStatusEnum {
    inProgress = 'inProgress',
    approved = 'approved',
    rejected = 'rejected',
}

export const redemptionStatusEnumLabel: {
    [key in RedemptionStatusEnum]: string;
} = {
    [RedemptionStatusEnum.inProgress]: 'In Progress',
    [RedemptionStatusEnum.approved]: 'Approved',
    [RedemptionStatusEnum.rejected]: 'Rejected',
};

export interface RedeemablePosition {
    subscriptionId: string;
    lotPurchaseDate: Date;
    lotNumber: number;
    redemptionAmount: number;
    redemptionFeePercentage: number;
    redemptionFeeAmount: number;
}

export interface RedemptionApplicationResponse {
    _id: string;
    // positionId: string;
    parentSubscriptionId: SubscriptionResponse['_id'];
    endInvestorProfileId: string;
    redemptionAmount: number;
    redemptionFeeAmount: number;
    redemptionCurrencyCode: string;
    redeemablePositions: RedeemablePosition[];
    status: RedemptionStatusEnum;
    requestedAt: Date;
    requestedBy: SystemUserResponse['_id'];
    redemptionSigningDocuments: RedemptionApplicationSigningDocument[];
}
