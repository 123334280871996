import { useState } from 'react';
import RegistrationFlow from './Components/RegistrationFlow';
import SuccessfulSubmission from './Components/SuccessfulSubmission';
import UnsuccessfulSubmission from './Components/UnsuccessfulSubmission';

const RegisterProspectiveInvestor: React.FC = () => {
    const [page, setPage] = useState(0);

    const handleSuccessfulSubmit = () => {
        setPage(1);
    };

    const handleRejectionSubmit = () => {
        setPage(2);
    };

    return (
        <div>
            {page === 0 && (
                <RegistrationFlow
                    onSuccessfulSubmit={handleSuccessfulSubmit}
                    onRejectionSubmit={handleRejectionSubmit}
                />
            )}
            {page === 1 && <SuccessfulSubmission />}
            {page === 2 && <UnsuccessfulSubmission />}
        </div>
    );
};

export default RegisterProspectiveInvestor;
