export const toCapitalizedWords = (name: string) => {
    if (!name) {
        return ''
    }
    
    let separated = name.replace(/([a-z])([A-Z0-9])/g, '$1 $2');

    separated = separated.charAt(0).toUpperCase() + separated.slice(1);

    return separated;
};
