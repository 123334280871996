import { FieldArray, FormikValues } from 'formik';
import { useCallback, useMemo, useState } from 'react';

import { LiquidityTypeEnum, ShareClass } from '@interfaces/Api';
import {
    InvestorLockUpEnum,
    ProductResponse,
} from '@interfaces/Api/ProductResponse';

import { isoCurrenciesOptions } from '@helpers/isoCurrencies';
import { notifySuccess } from '@helpers/toastrHelper';

import { Button, Icons } from '@components/Atoms';
import {
    FormInputBox,
    FormSelect,
    FormCheckbox,
    MultiselectCreatable,
    Option,
    Modal,
    AlertBox,
} from '@components/Molecules';

import { SoftLockUps } from './SoftLockUps';
import KeyInfoAddValueForm from './KeyInfoAddValueForm';
import { useMountEffect } from '@hooks/useMountEffect';

interface ShareClassInfoItemProps {
    product: ProductResponse;
    shareClass: ShareClass;
    grdItemWrapperStyle: string;
    formField: any;
    i: number;
    values: FormikValues;
    arrayHelpers: any;
    shareClassGroupList: Option[];
    setShareClassGroupList: (shareClassGroupList: Option[]) => void;
}

export const ShareClassInfoItem = (props: ShareClassInfoItemProps) => {
    const {
        product,
        shareClass,
        grdItemWrapperStyle,
        formField,
        i,
        values,
        arrayHelpers,
        shareClassGroupList,
        setShareClassGroupList,
    } = props;
    const { form } = arrayHelpers;
    const [showConfirmationModal, setShowConfirmationModal] =
        useState<boolean>(false);
    const hideModal = () => setShowConfirmationModal(false);
    const shareClassGroups = useMemo(() => {
        const shareClassGroupNames = product?.shareClasses?.reduce(
            (acc: string[], shareClass: ShareClass) => {
                const shareClassGroupName = shareClass.groupName;
                if (shareClassGroupName && !acc.includes(shareClassGroupName)) {
                    acc.push(shareClassGroupName);
                }
                return acc;
            },
            []
        );
        return (
            (shareClassGroupNames &&
                shareClassGroupNames.map((groupName) => ({
                    label: groupName,
                    value: groupName,
                }))) ||
            []
        );
    }, [product?.shareClasses]);

    const defualtShareClassGroup = useMemo(
        () =>
            shareClassGroups.find(
                (group) => group.value === shareClass.groupName
            ),
        [shareClassGroups, shareClass.groupName]
    );

    useMountEffect(() => {
        if (shareClassGroups.length > 0) {
            setShareClassGroupList([
                ...shareClassGroupList,
                ...shareClassGroups,
            ]);
        }
    });

    const investorLockupOptions = Object.values(InvestorLockUpEnum).map(
        (value) => ({
            value,
            label: value,
        })
    );

    const handleProagateLockupSettings = useCallback((arrayHelpers, i) => {
        const { form } = arrayHelpers;
        const { values } = form;
        const { shareClasses } = values;

        const updatedShareClasses = shareClasses.map(
            (shareClass: ShareClass) => ({
                ...shareClass,
                openEndedInformation: shareClasses[i].openEndedInformation,
            })
        );
        form.setFieldValue('shareClasses', updatedShareClasses);
        notifySuccess('Lockup settings updated!');
    }, []);

    return (
        <div
            className="grid md:grid-flow-col gap-1 justyfy-between border-b pb-6 mb-10"
            key={shareClass._id}
        >
            <div className="col-span-11">
                <div>
                    {
                        shareClass.isProxy && <AlertBox 
                            title='Proxy Share Class'
                            message='This is a proxy share class and is not displayed on the product screen however the information is used in subscription process.'
                            alertType='warning'
                        />
                    }
                </div>
                <div className={`${grdItemWrapperStyle} mb-3`}>
                    <div className="col-span-2">
                        <MultiselectCreatable
                            label="Group"
                            placeholder="Select/Create a Group"
                            isSingle={true}
                            defaultValue={
                                defualtShareClassGroup && [
                                    defualtShareClassGroup,
                                ]
                            }
                            options={shareClassGroupList}
                            handleChange={(newValue) => {
                                form.setFieldValue(
                                    `shareClasses[${i}].groupName`,
                                    newValue.value
                                );
                                if (
                                    newValue.__isNew__ &&
                                    !shareClassGroupList.includes(newValue)
                                ) {
                                    setShareClassGroupList([
                                        ...shareClassGroupList,
                                        newValue,
                                    ]);
                                }
                            }}
                        />
                    </div>
                </div>
                <div className={grdItemWrapperStyle}>
                    <div>
                        <FormInputBox
                            name={`shareClasses[${i}].name`}
                            label={formField.shareClasses.name.label}
                        />
                    </div>
                    <div>
                        <FormInputBox
                            name={`shareClasses[${i}].type`}
                            label={formField.shareClasses.type.label}
                        />
                    </div>
                    <div>
                        <FormInputBox
                            name={`shareClasses[${i}].distributionFee`}
                            label={formField.shareClasses.distributionFee.label}
                        />
                    </div>
                    <div>
                        <FormInputBox
                            name={`shareClasses[${i}].managementFee`}
                            label={formField.shareClasses.managementFee.label}
                        />
                    </div>
                </div>
                <div className={grdItemWrapperStyle}>
                    <div>
                        <FormSelect
                            name={`shareClasses[${i}].currency`}
                            label={formField.shareClasses.currency.label}
                            optionsData={isoCurrenciesOptions}
                            defaultValue={`shareClasses[${i}].currency`}
                        />
                    </div>
                    <div>
                        <FormInputBox
                            name={`shareClasses[${i}].minimumInvestment`}
                            label={
                                formField.shareClasses.minimumInvestment.label
                            }
                            type="number"
                        />
                    </div>
                    {values.liquidityType !== LiquidityTypeEnum.closedEnded && (
                        <div className="col-span-2">
                            <FormInputBox
                                name={`shareClasses[${i}].minimumSubsequentSubscriptionAmount`}
                                label={
                                    formField.shareClasses
                                        .minimumSubsequentSubscriptionAmount
                                        .label
                                }
                                type="number"
                            />
                        </div>
                    )}
                </div>
                {values.liquidityType === LiquidityTypeEnum.openEnded &&
                    i === 0 && (
                        <>
                            <div className={grdItemWrapperStyle}>
                                <div>
                                    <FormSelect
                                        name={`shareClasses[${i}].openEndedInformation.investorLockUp`}
                                        label={
                                            formField.shareClasses
                                                .openEndedInformation
                                                .investorLockUp.label
                                        }
                                        optionsData={investorLockupOptions}
                                    />
                                </div>

                                {values.shareClasses[i].openEndedInformation
                                    ?.investorLockUp ===
                                    InvestorLockUpEnum.HardLockUp && (
                                    <div>
                                        <FormInputBox
                                            name={`shareClasses[${i}].openEndedInformation.hardLockUpDetail.lengthOfTimeInMonths`}
                                            label={
                                                formField.shareClasses
                                                    .openEndedInformation
                                                    .hardLockUpDetail
                                                    .lengthOfTimeInMonths.label
                                            }
                                            type="number"
                                        />
                                    </div>
                                )}

                                <div className="col-span-2 flex items-center ml-4">
                                    <Button
                                        label="Use same lockup settings for all shareclasses"
                                        size="small"
                                        className="px-5"
                                        onClick={() =>
                                            handleProagateLockupSettings(
                                                arrayHelpers,
                                                i
                                            )
                                        }
                                    />
                                </div>
                            </div>
                            {values.shareClasses[i].openEndedInformation
                                ?.investorLockUp ===
                                InvestorLockUpEnum.SoftLockUp && (
                                <div className="grid md:grid-flow-col grid-cols-4 gap-1 justyfy-between mb-4">
                                    <div className="col-span-3">
                                        <SoftLockUps shareClassIndex={i} />
                                    </div>
                                </div>
                            )}
                        </>
                    )}

                <FieldArray
                    name={`shareClasses[${i}].additionalTerms`}
                    render={() => (
                        <div className="mb-4">
                            <KeyInfoAddValueForm
                                inputKeyName="keyName"
                                title="Additional Terms"
                                inputKeyNameLabel={
                                    formField.shareClasses.additionalTerms
                                        .keyName.label
                                }
                                inputItem="value"
                                inputItemLabel={
                                    formField.shareClasses.additionalTerms.value
                                        .label
                                }
                                value={values.shareClasses[i].additionalTerms}
                                onChange={(newValue) => {
                                    form.setFieldValue(
                                        `shareClasses[${i}].additionalTerms`,
                                        newValue
                                    );
                                }}
                            />
                        </div>
                    )}
                />
                
                <div className="flex items-center">
                    <div>
                        <FormCheckbox
                            name={`shareClasses[${i}].useProductBaseCurrencyForMinAmount`}
                            disabled={!product?.baseCurrency}
                            actionLabel={`Use product base currency ${
                                product?.baseCurrency
                                    ? `(${product?.baseCurrency})`
                                    : ''
                            } for min amount`}
                            checked={!product?.baseCurrency ? false : undefined}
                        />
                    </div>

                    {i === 0 && (
                        <div className="mb-3 ml-4">
                            <Button
                                onClick={() => {
                                    arrayHelpers.form.values.shareClasses.forEach(
                                        (_: void, j: number) =>
                                            form.setFieldValue(
                                                `shareClasses[${j}].useProductBaseCurrencyForMinAmount`,
                                                true
                                            )
                                    );
                                }}
                            >
                                Apply for all share classes
                            </Button>
                        </div>
                    )}
                </div>
            </div>
            <div>
                <Button
                    size="small"
                    className="bg-red-900"
                    onClick={() => {
                        setShowConfirmationModal(true);
                    }}
                >
                    <Icons
                        name="XMarkIcon"
                        size="small"
                        classList="bg-red-900"
                        strokeWidth="3 "
                    />
                </Button>
            </div>
            <Modal
                show={showConfirmationModal}
                onBackdropClick={hideModal}
                width="w-2/5"
            >
                <Modal.Header onClose={hideModal}>
                    <h4>Delete ShareClass - {shareClass.name}</h4>
                </Modal.Header>
                <Modal.Body>
                    <div className="w-full mx-auto">
                        <div className="flex justify-center">
                            <p>
                                Are you sure you want to delete this share
                                Class?
                            </p>
                        </div>
                        <div className="my-4 p-2 flex justify-between">
                            <Button
                                onClick={hideModal}
                                buttonType="secondary"
                                label="Cancel"
                            ></Button>
                            <Button
                                type="submit"
                                label="Delete"
                                onClick={() => arrayHelpers.remove(i)}
                            ></Button>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <div></div>
                </Modal.Footer>
            </Modal>
        </div>
    );
};
