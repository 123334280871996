import { useEffect, useState } from 'react';

interface CountdownTimerProps {
    endDate: Date;
    onTimerEnd?: () => void;
}

const CountdownTimer: React.FC<CountdownTimerProps> = ({
    endDate,
    onTimerEnd,
}) => {
    const calculateTimeLeft = () => {
        const difference = +endDate - +new Date();
        let timeLeft = {};

        if (difference > 0) {
            timeLeft = {
                days: Math.floor(difference / (1000 * 60 * 60 * 24)),
                hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
                minutes: Math.floor((difference / 1000 / 60) % 60),
                seconds: Math.floor((difference / 1000) % 60),
            };
        }

        return timeLeft;
    };

    const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

    useEffect(() => {
        const timer = setTimeout(() => {
            setTimeLeft(calculateTimeLeft());
        }, 1000);
        // Clear timeout if the component is unmounted
        return () => clearTimeout(timer);
    });

    const timerComponents = [];

    Object.keys(timeLeft).forEach((interval, index) => {
        if (!timeLeft[interval]) {
            return;
        }

        timerComponents.push(
            <span key={index}>
                {timeLeft[interval]} {interval}{' '}
            </span>
        );
    });

    if (timerComponents.length === 0) {
        onTimerEnd && onTimerEnd();
        return <span>Time's up!</span>;
    }

    return <div>{timerComponents}</div>;
};

export default CountdownTimer;
