import { BaseUrl } from '../Helpers/Environment';

import { ProposalResponse } from '@interfaces/Api/ProposalResponse';
import { ProposalRequestBody } from '@interfaces/Api/ProposalRequestBody';

import { Http } from '../libs/http';

export const API_PROPOSALS_URL = `${BaseUrl}/product-proposal`;

export const ProposalsGetAll = async (endInvestorProfileId?: string) => {
    let queryParams;
    if (endInvestorProfileId)
        queryParams = { endInvestorProfileId: endInvestorProfileId };
    const result = await Http.get<ProposalResponse[]>(
        API_PROPOSALS_URL,
        undefined,
        queryParams
    );
    return result;
};

export const ProposalGet = (proposalId: string) => {
    return Http.get<ProposalResponse>(`${API_PROPOSALS_URL}/${proposalId}`);
};

export const ProposalCreatePost = (proposal: ProposalRequestBody) => {
    return Http.post<ProposalRequestBody>(`${API_PROPOSALS_URL}`, proposal);
};

export const ProposalPut = (
    proposal: ProposalRequestBody,
    proposalId: string
) => {
    return Http.put<ProposalResponse>(
        `${API_PROPOSALS_URL}/${proposalId}`,
        proposal
    );
};
