export const getStyling = () => {
    const style = getComputedStyle(document.body);
    const product_a = style.getPropertyValue('--vp-product-a');
    const product_b = style.getPropertyValue('--vp-product-b');
    const product_c = style.getPropertyValue('--vp-product-c');
    const product_d = style.getPropertyValue('--vp-product-d');
    const product_e = style.getPropertyValue('--vp-product-e');
    const product_f = style.getPropertyValue('--vp-product-f');
    const product_g = style.getPropertyValue('--vp-product-g');

    return {
        product_a,
        product_b,
        product_c,
        product_d,
        product_e,
        product_f,
        product_g,
    };
};
