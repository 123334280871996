const capitalCallLabels = {
    CapitalCall: {
        amount: {
            name: 'amount',
            label: 'Capital Call Amount',
            requiredErrorMsg: 'Please type in an Amount!',
        },
        noticeType: {
            name: 'noticeType',
            label: 'Notice Type',
            requiredErrorMsg: 'Please Choose a Type!',
        },
        comment: {
            name: 'comment',
            label: 'Add a Comment',
            requiredErrorMsg: 'Comment required!',
        },
        uploadedBy: { name: 'uploadedBy', label: 'Uploaded By' },
        documents: {
            title: {
                name: 'title',
                label: 'Document Label',
                requiredErrorMsg: 'Document Label is required!',
            },
            url: {
                name: 'url',
                label: 'Document URL',
            },
        },
    },
    CapitalCallPaid: {
        amount: {
            name: 'amountPaid',
            label: 'Capital Call Paid Amount',
            requiredErrorMsg: 'Please type in an Amount!',
        },
        noticeType: {
            name: 'noticeType',
            label: 'Notice Type',
            requiredErrorMsg: 'Please Choose a Type!',
        },
        comment: {
            name: 'comment',
            label: 'Add a Comment',
            requiredErrorMsg: 'Comment required!',
        },
        uploadedBy: { name: 'uploadedBy', label: 'Uploaded By' },
        documents: {
            title: {
                name: 'title',
                label: 'Document Label',
                requiredErrorMsg: 'Document Label is required!',
            },
            url: {
                name: 'url',
                label: 'Document URL',
            },
        },
    },
};
export default capitalCallLabels;
