import { type LabelElement } from './Components/Label';

import { ProductPerformance } from '@interfaces/Api/ProductPerformance';
import {
    EndInvestorPortfolioItem,
    FundData,
    InvestmentData,
} from '@interfaces/Api/Dashboard';

export type DashboardType = 'endInvestor' | 'gp' | 'distributor' | 'wm';
export type FilterType = 'openEnded' | 'closedEnded' | 'all';

export type SortingOrder = 'asc' | 'desc';
export type SortingProperty = 'inceptionDate' | 'nextCapitalCall' | 'endDate';

export type SortType = `${SortingProperty} - ${SortingOrder}`;

export interface TabsData {
    fundData: FundData;
    investmentData: InvestmentData;
    performanceData: ProductPerformance[];
    latestPerformanceData: ProductPerformance[];
    valuationDate: Date;
}

const getSortValuesByProperty = ({
    a,
    b,
    property,
}: {
    a: EndInvestorPortfolioItem;
    b: EndInvestorPortfolioItem;
    property: SortingProperty;
}) => {
    const valA = a.fundData[property as SortingProperty];
    const valB = b.fundData[property as SortingProperty];

    if (!valA && valB) {
        return {
            isAsc: 1,
            isDesc: -1,
        };
    }
    if (valA && !valB) {
        return {
            isAsc: -1,
            isDesc: 1,
        };
    }
    if (!valA || !valB) {
        return {
            isAsc: 0,
            isDesc: 0,
        };
    }

    const isAsc = new Date(valA).getTime() < new Date(valB).getTime() ? -1 : 1;
    const isDesc = isAsc * -1;

    return {
        isAsc,
        isDesc,
    };
};

export const sortFunds = ({
    funds,
    property,
    sortingOrder: sortedBy,
}: {
    funds: EndInvestorPortfolioItem[];
    property: SortingProperty;
    sortingOrder: SortingOrder;
}) =>
    [...funds].sort((a, b) => {
        const { isAsc, isDesc } = getSortValuesByProperty({ a, b, property });
        return sortedBy === 'asc' ? isAsc : isDesc;
    });

export const formatMetricsValue = ({ value, label, toFixedNumber = 2 }: LabelElement) => {
    if (typeof value !== 'string' && isNaN(value)) {
        return '-';
    }
    const specialCharacter = ['MOIC', 'TVPI', 'RVPI', 'DPI'].includes(label)
        ? 'x'
        : ['IRR', '1YReturn', 'DistributionRate'].includes(label)
        ? '%'
        : '';
    const preFormattedValue = `${(Math.round(Number(value) * 100) / 100).toFixed(
        toFixedNumber
    )}${specialCharacter}`;

    const formatter = new Intl.NumberFormat('en-GB', {
        
    });
    
    return  label === 'Units Held' ? formatter.format(Number(preFormattedValue)) : preFormattedValue;
};
