export enum MetricType {
    IRR = 'IRR',
    DPI = 'DPI',
    MOIC = 'MOIC',
    RVPI = 'RVPI',
    TVPI = 'TVPI',
    NAV = 'NAV',
    UNITPRICE = 'UnitPrice',
    CALLED = 'Called',
    YTDRETURN = 'YTDReturn',
    M1RETURN = '1MReturn',
    M3RETURN = '3MReturn',
    Y1RETURN = '1YReturn',
    Y3RETURN = '3YReturn',
    Y5RETURN = '5YReturn',
    Y10RETURN = '10YReturn',
    DISTRIBUTIONRATE = 'DistributionRate',
}

export enum DataType {
    PERCENTAGE = 'percentage',
    CURRENCY = 'currency',
    NUMBER = 'number',
}

export interface ProductPerformance {
    date: Date;
    metricType: MetricType;
    dataType: DataType;
    value: number;
}

export interface ReturnHistory {
    date: Date;
    value: number;
}
