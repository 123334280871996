import { Document } from './Document';
import { SubscriptionSigningDocumentTypeEnum } from './SigningDocumentTypeEnum';

export enum SigningMethodEnum {
    docusign = 'docusign',
    manual = 'manual',
}

export enum SigningDocumentStatusEnum {
    draft = 'draft',
    sent = 'sent',
    signed = 'signed',
}

export interface SigningDocumentStatusLog {
    status: SigningDocumentStatusEnum;
    updatedBy: string;
    createdAt: Date;
}

export enum SigningUserStatusEnum {
    onHold = 'onHold',
    sent = 'sent',
    signed = 'signed',
    declined = 'declined',
}
export interface SigningUserStatusLog {
    status: SigningUserStatusEnum;
    createdAt: Date;
}

export interface SigningUser {
    email: string;
    name: string;
    status: SigningUserStatusEnum;
    createdAt: Date;
    statusLogs: SigningUserStatusLog[];
}

export interface SigningDocument {
    status: SigningDocumentStatusEnum;
    statusLogs: SigningDocumentStatusLog[];
    fileName: string;

    systemUserId: string;
    draftDocument: Document;
    signedDocument?: Document;
    signingUsers: SigningUser[];

    signingDocumentType?: SubscriptionSigningDocumentTypeEnum;
    signingMethod?: SigningMethodEnum;
    signingMethodId?: string;
    signingSystemResponse?: any;
}

export enum RedemptionSigningDocumentTypeEnum {
    RedemptionAgreement = 'RedemptionAgreement',
    NomineeIndemnityRedemptionAgreement = 'NomineeIndemnityRedemptionAgreement',
}

export interface RedemptionApplicationSigningDocument {
    _id?: string;
    status: SigningDocumentStatusEnum;
    statusLogs: SigningDocumentStatusLog[];
    fileName: string;

    systemUserId: string;
    draftDocument: Document;
    signedDocument?: Document;
    signingUsers?: SigningUser[];

    signingDocumentType: RedemptionSigningDocumentTypeEnum;
    signingMethod?: SigningMethodEnum;
    signingMethodId?: string;
    signingSystemResponse?: any;
}
