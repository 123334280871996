import {
    GermanAssetEnum,
    SubscriptionGermanSemiProfessionalCategorisation,
} from '@interfaces/Api';

interface ReviewGermanSemiProfessionalProps {
    germanSemiProfessionalCategorisation?: SubscriptionGermanSemiProfessionalCategorisation;
}

function ReviewGermanSemiProfessional(
    props: ReviewGermanSemiProfessionalProps
) {
    const convertBooleanToGerman = (value: string) => {
        if (value === 'true') {
            return 'Ja';
        } else {
            return 'Nein';
        }
    };

    const convertKnowledgeratingToGerman = (value: string) => {
        switch (value) {
            case GermanAssetEnum.none:
                return 'Keine';
                break;
            case GermanAssetEnum.low:
                return 'Gering';
                break;
            case GermanAssetEnum.medium:
                return 'Erweitert';
                break;
            case GermanAssetEnum.high:
                return 'Hoch';
                break;
        }
        return value;
    };

    const convertLiquidAssetToGerman = (value: string) => {
        if (value === 'UpTo5%') {
            return 'Bis zu 5%.';
        } else {
            return 'Mehr als 5%.';
        }
    };

    const convertHeadersToGerman = (value: string) => {
        const headerStr = Object.keys(
            props?.germanSemiProfessionalCategorisation!
        );
        if (typeof value != 'undefined') {
            switch (String(value)) {
                case String(headerStr[1]): {
                    return '1. Bisherige Anlagen';
                    break;
                }
                case String(headerStr[2]): {
                    return '2. Unternehmerische Erfahrungen';
                    break;
                }
                case String(headerStr[3]): {
                    return '3. Kaufen, Halten Und Verkaufen';
                    break;
                }
                case String(headerStr[4]): {
                    return '4. Alternative Investments, Z.B. Geschlossene Fonds (Außer Private Equity), Mit Blind Pool Charakter';
                    break;
                }
                case String(headerStr[5]): {
                    return '5. Geschlossene Auslandsinvestitionen';
                    break;
                }
                case String(headerStr[6]): {
                    return '6. Alternative Investments, Z.B. Geschlossene Fonds (Außer Private Equity), Mit Feststehenden Investitionsobjekten.';
                    break;
                }
                case String(headerStr[7]): {
                    return 'A. Investments Mit Komplexer Investitionsstruktur';
                    break;
                }
                case String(headerStr[8]): {
                    return 'B. Illiquide Beteiligung';
                    break;
                }
                case String(headerStr[9]): {
                    return 'C. Investition Mit Fremdwährung';
                    break;
                }
                case String(headerStr[10]): {
                    return 'D. Persönliche Haftung';
                    break;
                }
                case String(headerStr[11]): {
                    return 'E. Keine Risikomischung';
                    break;
                }
                case String(headerStr[12]): {
                    return 'F. Fremdfinanzierung';
                    break;
                }
                case String(headerStr[13]): {
                    return 'G. Kapitalabrufe';
                    break;
                }
                default:
                    break;
            }
        }
        return value;
    };

    return (
        <div className="text-base w-full">
            <div className="text-base leading-5 capitalize">
                {props.germanSemiProfessionalCategorisation &&
                    Object.keys(props.germanSemiProfessionalCategorisation)
                        .slice(1, -1)
                        .map((keyName, i) => {
                            const dataSemiProfessionalObj: any =
                                props.germanSemiProfessionalCategorisation as SubscriptionGermanSemiProfessionalCategorisation;
                            const dataObj =
                                props.germanSemiProfessionalCategorisation
                                    ? dataSemiProfessionalObj[keyName]!
                                    : {};
                            const hasAssetsOrExperienceStr =
                                String(dataObj.hasAsset) ||
                                String(dataObj.hasExperience);

                            return (
                                <div key={i.toString()}>
                                    <h5 className="font-bold capitalize">
                                        {convertHeadersToGerman(
                                            keyName as string
                                        )}
                                    </h5>
                                    <div className="ml-8">
                                        <div>
                                            <span className="text-brand-navy">
                                                <strong>Has Asset: </strong>
                                            </span>
                                            <span className="text-logo-blue-900">
                                                {convertBooleanToGerman(
                                                    hasAssetsOrExperienceStr
                                                )}
                                            </span>
                                        </div>
                                        {dataObj?.description ? (
                                            <div className="text-brand-navy">
                                                <strong>Description: </strong>
                                                <span className="text-logo-blue-900">
                                                    {dataObj?.description}
                                                </span>
                                            </div>
                                        ) : null}
                                        <div className="text-brand-navy">
                                            <strong>Knowledge Rating: </strong>
                                            <span className="text-logo-blue-900">
                                                {convertKnowledgeratingToGerman(
                                                    dataObj?.knowledgeRating!
                                                )}
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            );
                        })}

                <div className="mt-2">
                    <strong>
                        Anteil der gesamten freien liquiden Mittel:{' '}
                    </strong>
                    {convertLiquidAssetToGerman(
                        String(
                            props?.germanSemiProfessionalCategorisation
                                ?.proportionOfTotalFreeLiquidAssets
                        )
                    )}
                </div>
            </div>
        </div>
    );
}

export default ReviewGermanSemiProfessional;
