import {
    EndInvestorProfileRequestBody,
    EndInvestorProfileResponse,
} from '@interfaces/Api';
import { StepProps } from './Components/Interfaces';
import { FormikValues } from 'formik';

interface HandleServiceLevelandSubscriptionSelectionProps {
    currentStep?: StepProps;
    values: EndInvestorProfileRequestBody;
}

export const handleServiceLevelandSubscriptionSelection = ({
    currentStep,
    values,
}: HandleServiceLevelandSubscriptionSelectionProps) => {
    let data = {
        serviceLevelType: values.serviceLevelType,
        subscriptionProcessType: values.subscriptionProcessType,
    };

    if (
        values.serviceLevelType ===
        EndInvestorProfileResponse.ServiceLevelTypeEnum.ExecutionOnly
    ) {
        data = {
            ...data,
            subscriptionProcessType:
                EndInvestorProfileResponse.SubscriptionProcessTypeEnum.Direct,
        };
    }

    if (
        values.endInvestorType ===
        EndInvestorProfileResponse.EndInvestorType.nomineeEntity
    ) {
        data = {
            serviceLevelType:
                EndInvestorProfileResponse.ServiceLevelTypeEnum.ExecutionOnly,
            subscriptionProcessType:
                EndInvestorProfileResponse.SubscriptionProcessTypeEnum.Direct,
        };
    }

    if (
        values.endInvestorType !==
            EndInvestorProfileResponse.EndInvestorType.nomineeEntity &&
        currentStep?.name === 'endInvestorType' &&
        values.subscriptionProcessType &&
        values.serviceLevelType
    ) {
        data = {
            serviceLevelType: undefined,
            subscriptionProcessType: undefined,
        };
    }

    if (
        values.serviceLevelType ===
            EndInvestorProfileResponse.ServiceLevelTypeEnum.ExecutionOnly &&
        currentStep?.name === 'serviceLevelType'
    ) {
        data = {
            ...data,
            subscriptionProcessType:
                EndInvestorProfileResponse.SubscriptionProcessTypeEnum.Direct,
        };
    }

    if (
        currentStep?.name === 'serviceLevelType' &&
        values.serviceLevelType === 'advisory' &&
        values.subscriptionProcessType === 'direct'
    ) {
        data = {
            ...data,
            subscriptionProcessType: undefined,
        };
    }

    if (currentStep?.name === 'subscriptionProcessType') {
        data = {
            ...data,
            subscriptionProcessType: values.subscriptionProcessType,
        };
    }

    return data;
};

export const disabledNextStepButton = ({
    currentStep,
    values,
    isEdit,
}: {
    currentStep: StepProps;
    values: FormikValues;
    isEdit: boolean;
}) => {
    let isDisabled = false;

    if (
        currentStep?.name === 'addresses' &&
        values.addresses &&
        values.addresses.length === 0
    ) {
        isDisabled = true;
    }

    if (
        currentStep?.name === 'bankAccounts' &&
        values.bankAccounts &&
        values.bankAccounts.length === 0
    ) {
        isDisabled = true;
    }

    if (currentStep?.name === 'systemUsers' && isEdit) {
        isDisabled = true;
    }

    return isDisabled;
};
