import { useTranslation } from 'react-i18next';
import { useRegisterInvestorState } from '../RegisterInvestor.context';
import { endInvestorTypeLabelMap } from '@interfaces/Api/EndInvestorProfileResponse';

export const EndInvestorTypeSummary = (): JSX.Element => {
    const { t } = useTranslation();
    const { data } = useRegisterInvestorState();

    const value =
        endInvestorTypeLabelMap[
            data['endInvestorType'] as keyof typeof endInvestorTypeLabelMap
        ];
    return (
        <p>
            <span className="font-bold mr-2">{t('client.registration.summary.entity_type')}:</span>
            {value}
        </p>
    );
};
