import React, {
    createContext,
    useState,
    useContext,
    useEffect,
    useCallback,
} from 'react';
import { storageTokenGet } from '@api/StorageToken';
import { Document } from '@interfaces/Api';
import { BaseUrl } from '@helpers/Environment';
import { useAuthState } from './AuthContext';

export interface StorageTokenContextProps {
    token: string;
    withToken: (url: string) => string;
    arrayWithToken: (arr: Document[]) => Document[];
}

const storageTokenDefaultState: StorageTokenContextProps = {
    token: '',
    withToken: (url: string) => url,
    arrayWithToken: (arr: Document[]) => arr,
};

export const StorageTokenContext = createContext<StorageTokenContextProps>(
    storageTokenDefaultState
);

export const useStorageTokenState = () => {
    return useContext(StorageTokenContext);
};

export const StorageTokenProvider: React.FC = ({
    children,
}: React.PropsWithChildren<{}>) => {
    const [token, setToken] = useState<string>('');
    const { isLoggedIn } = useAuthState();
    
    const getStorageToken = useCallback(() => {
        storageTokenGet()
            .then((response) => {
                setToken(response.token);
            })
            .catch(console.error);
    }, []);

    useEffect(() => {
        getStorageToken();
    }, [getStorageToken]);

    useEffect(() => {
        if (!token && isLoggedIn) {
            getStorageToken();
        }
    }, [getStorageToken, isLoggedIn, token])

    const shouldAppendToken = useCallback((url: string) => {
        // check if url is for the storage account
        const removeProtocol = (url: string) => {
            return url.replace(/(^\w+:|^)\/\//, '');
        };

        if (url.includes('?sv') || /^https:\/\/publiccontents.blob.core.windows.net.+$/.test(url) || removeProtocol(url).includes(removeProtocol(BaseUrl))) {
            return false;
        }
        
        return true;
    }, []);

    const withToken = useCallback(
        (url) => {
            if (url && shouldAppendToken(url)) {
                return `${url}${token}`;
            }
            return url;
        },
        [token]
    );
    const arrayWithToken = useCallback(
        (arr: Document[]) =>
            arr.map((f) => ({
                ...f,
                url: f.url ? withToken(f.url) : f.url,
            })),
        [withToken]
    );

    return (
        <StorageTokenContext.Provider
            value={{
                token,
                withToken,
                arrayWithToken,
            }}
        >
            {children}
        </StorageTokenContext.Provider>
    );
};
