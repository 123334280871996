import { SuitabilityQuestionnaireResponseBody } from '@api/SuitabilityQuestionnaire';
import { ReviewFrame } from '@components/Molecules';
import { ProspectiveInvestorResponse } from '@interfaces/Api/ProspectiveInvestorResponse';

interface SuitabilityQuestionnaireSectionProps {
    prospectiveInvestor: ProspectiveInvestorResponse;
    suitabilityQuestionnaire?: SuitabilityQuestionnaireResponseBody;
}

const SuitabilityQuestionnaireSection: React.FC<
    SuitabilityQuestionnaireSectionProps
> = ({ prospectiveInvestor, suitabilityQuestionnaire }) => {
    const headings = ['Question', 'Answer', 'Points'];

    const rows: {
        question: string;
        answers: { answer: string; points: number; extraText: string }[];
        totalPoints: number;
    }[] = suitabilityQuestionnaire?.savedQuestionnaireWithAnswers?.map(
        (questionWithAnswer) => {
            return {
                question: questionWithAnswer.question,
                answers: questionWithAnswer.selectedAnswers.map((a) => ({
                    answer: a.text,
                    points: a.points,
                    extraText: a.customText,
                    // TODO: need to show mifidpro classification
                })),
                totalPoints: questionWithAnswer.selectedAnswers.reduce(
                    (acc, curr) => acc + curr.points,
                    0
                ),
            };
        }
    );

    const totalForRows = rows?.reduce((acc, curr) => acc + curr.totalPoints, 0);

    const footer = {
        totalPoints: totalForRows,
    };

    const isAwaitingAssessment =
        prospectiveInvestor?.requiresSuitabilityAssessment &&
        prospectiveInvestor?.status === 'awaitingAssessment';

    return (
        <ReviewFrame
            title="Investor Suitability Questionnaire"
            expanded={true}
            hideToggleButton={true}
        >
            <div className="w-full lg:w-4/5 flex flex-col justify-start p-5 md:py-10 text-xs">
                {isAwaitingAssessment && (
                    <p>Awaiting Suitability Assessment to be completed</p>
                )}
                {!isAwaitingAssessment && (
                    <div className="text-base">
                        {/* Desktop Version: showing questions and answers and points */}
                        <table className="w-full hidden md:block">
                            <thead>
                                <tr className="border-b border-gray-300">
                                    {headings.map((item, index) => (
                                        <th
                                            key={index}
                                            className="text-left py-2"
                                        >
                                            {item}
                                        </th>
                                    ))}
                                </tr>
                            </thead>
                            <tbody>
                                {rows?.map((row, index) => (
                                    <tr key={index} className="border-b">
                                        <td className="py-2">{row.question}</td>
                                        <td className="py-2">
                                            {row.answers.map(
                                                (answer, index) => (
                                                    <div key={index}>
                                                        {answer.answer}
                                                        {answer.extraText && (
                                                            <div className="text-sm">
                                                                {
                                                                    answer.extraText
                                                                }
                                                            </div>
                                                        )}
                                                    </div>
                                                )
                                            )}
                                        </td>
                                        <td className="py-2">
                                            {row.totalPoints}
                                        </td>
                                    </tr>
                                ))}
                                <tr className="border-b">
                                    <td className="py-2 font-bold">
                                        Total Points
                                    </td>
                                    <td></td>
                                    <td className="py-2 font-bold">
                                        {footer.totalPoints}
                                    </td>
                                </tr>
                            </tbody>
                        </table>

                        {/* Mobile Version: showing questions and answers */}
                        <div className="md:hidden">
                            {rows?.map((row, index) => (
                                <div key={index} className="border-b">
                                    <div className="py-2 font-bold">
                                        {row.question}
                                    </div>
                                    <div className="py-2">
                                        {row.answers.map((answer, index) => (
                                            <div key={index}>
                                                {answer.answer}
                                                {answer.extraText && (
                                                    <div className="text-sm">
                                                        {answer.extraText}
                                                    </div>
                                                )}
                                            </div>
                                        ))}
                                    </div>
                                    <div className="py-2">
                                        {row.totalPoints}
                                    </div>
                                </div>
                            ))}
                            <div className="border-b">
                                <div className="py-2 font-bold">
                                    Total Points
                                </div>
                                <div className="py-2">{footer.totalPoints}</div>
                            </div>
                        </div>
                    </div>
                )}
            </div>
        </ReviewFrame>
    );
};

export default SuitabilityQuestionnaireSection;
