import React, { useCallback, useMemo } from 'react';
import { useLocation, useParams } from 'react-router-dom';

import { productSteps } from '@services/ProductsHelper';
import { WorkflowStepper } from '..';

const ProductSteps: React.FC = () => {
    const location = useLocation();
    const endPathUrl = location.pathname.substring(
        location.pathname.lastIndexOf('/') + 1
    );

    const { productId } = useParams();

    const steps = useMemo(() => {
        return productSteps.map((step) => {
            return {
                ...step,
                stepUrl: `${productId}/${step.endPathUrl}`,
                label: step.stepName,
            };
        });
    }, [productId]);

    return (
        <div>
            <WorkflowStepper
                steps={steps}
                activeStep={{ endPathUrl: endPathUrl }}
            />
        </div>
    );
};

export default ProductSteps;
