import { Http } from '../libs/http';
import { BaseUrl } from '../Helpers/Environment';

export const API_ENDINVESTORPROFILEGROUPS_URL = `${BaseUrl}/end-investor-profile-groups`;

interface EndInvestorProfileGroupRequestBody {
    groupName: string;
    // systemUserId: string;
    endInvestorProfileIds: string[];
}

export interface EndInvestorProfileGroupResponse {
    _id: string;
    groupName: string;
    systemUserId: string;
    endInvestorProfileIds: string[];
}

export const endInvestorProfileGroupsGetAll = () => {
    return Http.get<EndInvestorProfileGroupResponse[]>(
        API_ENDINVESTORPROFILEGROUPS_URL
    );
};

export const endInvestorProfileGroupsPost = (
    endInvestorProfileGroup: EndInvestorProfileGroupRequestBody
) => {
    return Http.post<EndInvestorProfileGroupResponse>(
        API_ENDINVESTORPROFILEGROUPS_URL,
        endInvestorProfileGroup
    );
};

export const endInvestorProfileGroupsPut = (
    id: string,
    preferences: EndInvestorProfileGroupRequestBody
) => {
    return Http.put<EndInvestorProfileGroupResponse>(
        `${API_ENDINVESTORPROFILEGROUPS_URL}/${id}`,
        preferences
    );
};

export const endInvestorProfileGroupsGet = (id: string) => {
    return Http.get<EndInvestorProfileGroupResponse>(
        `${API_ENDINVESTORPROFILEGROUPS_URL}/${id}`
    );
};
