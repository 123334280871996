import { UploadDocumentType } from '@api/Documents';
import { FormRadioGroup } from '@components/Molecules';
import { FileUploadAsync } from '@components/Organisms';
import SubscriptionsFormikSubmitBtns from '@containers/Subscriptions/SubscriptionsFormikSubmitBtns';
import { notifySuccess } from '@helpers/toastrHelper';
import { useSubscriptionPageContext } from '@pages/Subscriptions/EditSubscription';
import { Formik, Form } from 'formik';
import React, { FC, useCallback } from 'react';

const radioGroups: {
    name: string;
    label: string;
    optionsData: {
        id: string;
        label: string;
        value: string;
    }[];
    document: string;
    documentDescription: string;
}[] = [
    {
        name: 'frequencyOf10Transactions',
        label: '1. I have carried out transactions, in significant size, on relevant markets at an average frequency of 10 transactions per quarter over the previous four quarters.',
        optionsData: [
            { id: '1', label: 'Yes', value: 'true' },
            {
                id: '2',
                label: 'No',
                value: 'false',
            },
        ],
        document: 'transactionStatement',
        documentDescription:
            'Please upload transaction statement(s) or contract note(s) as evidence of the above.',
    },
    {
        name: 'portfolioIncludingCashDepositAndFinancialInstruments',
        label: '2. The size of my portfolio exceeds EUR 500,000, where portfolio is defined as including cash deposits and financial instruments.',
        optionsData: [
            { id: '1', label: 'Yes', value: 'true' },
            {
                id: '2',
                label: 'No',
                value: 'false',
            },
        ],
        document: 'accountStatement',
        documentDescription:
            'Please upload account statement(s) or contract note(s) as evidence of the above.',
    },
    {
        name: 'workedInTheFinancialSectorForAtLeastOneYear',
        label: '3. I work or have worked in the financial sector for at least one year in a professional position, which requires knowledge of entering into transactions and/ or obtain investment services.',
        optionsData: [
            { id: '1', label: 'Yes', value: 'true' },
            {
                id: '2',
                label: 'No',
                value: 'false',
            },
        ],
        document: 'proofOfEmployment',
        documentDescription:
            'Please upload proof of employment as evidence of the above.',
    },
];

export const OptUpQuestionnaire: FC = () => {
    const { goToNextStep } = useSubscriptionPageContext();

    const formValues = {};

    const submitForm = useCallback(() => {
        goToNextStep && goToNextStep();
        notifySuccess('questionnaire saved successfully');
    }, [goToNextStep]);

    return (
        <div className="w-full overflow-y-auto">
            <div className="w-full md:w-11/12 pt-4">
                <h1 className="text-3xl text-logo-blue  mb-4">
                    MiFID Professional Opt-Up Questionnaire
                </h1>
                <h2 className="text-2xl text-logo-blue mb-4">
                    Right to Opt-Up and Warnings
                </h2>
                <p className="mb-4">
                    <div className="font-semibold">Your right to Opt-Up</div>
                    If you meet at least two out of the following three criteria
                    you may request to be categorized as a Professional Client.
                    If you are categorized as Professional Client, you will have
                    access to a broader product offerings, but it will have an
                    adverse effect on the level of your investor protection,
                    which will be explained below.
                </p>

                <p className="mb-4">
                    The criteria to be categorized as Professional Client are as
                    follows:
                    <ol className="list-decimal pl-8">
                        <li>
                            {' '}
                            You have carried out transactions, in significant
                            size, on relevant market at an average frequency of
                            10 per quarter over the previous four quarters.
                        </li>
                        <li>
                            {' '}
                            The size of your financial instrument portfolio,
                            defined as including cash deposits and financial
                            instruments, exceeds EUR 500,000.
                        </li>
                        <li>
                            {' '}
                            You work or have worked in the financial sector for
                            at least one year in a professional position which
                            requires knowledge of transactions or services
                            envisaged.
                        </li>
                    </ol>
                </p>

                <p className="mb-4">
                    You must meet at least 2 out of the 3 abovementioned
                    criteria if you request us to be categorized as a
                    Professional Client. On the basis of the information you
                    provide to us, we will assess your knowledge and experience
                    regarding the investment services and financial instruments
                    for which you may qualify as Professional Client.
                </p>
                <p className="mb-4">
                    If we determine that you have the requisite knowledge and
                    experience to adequately take your own investment decisions
                    and with regards to more complex products understand the
                    associated risk assessments, we will categorize you as a
                    Professional Client.
                </p>
                <p className="mb-4">
                    <div className="font-semibold">
                        Reduced level of investor protection as a result of your
                        opt-up request
                    </div>
                    If we, in line with your request, categorize you as a
                    Professional Client, you will be granted fewer protections
                    than if you were categorised as a retail client. In
                    particular:
                    <ol className="list-roman pl-8">
                        <li>
                            i.You are likely to receive less information about
                            us, our services and your investments (for example
                            on costs, commissions, fees and charges).
                        </li>
                        <li>
                            When assessing the appropriateness of a product or
                            service, the regulation allows us to assume that you
                            have sufficient knowledge and experience to
                            understand the relevant investment risks.
                        </li>
                        <li>
                            If we are required to assess the suitability of a
                            personal recommendation, the regulation allows us to
                            assume that you have sufficient experience and
                            knowledge to understand the risks involved.
                        </li>
                        <li>
                            You are likely to not meet the definition of a
                            “consumer”. If this is the case then the
                            requirements relating to distance communications
                            will not apply.
                        </li>
                        <li>
                            You are likely to not meet the definition of a
                            “consumer”. If this is the case then the
                            requirements relating to distance communications
                            will not apply.
                        </li>
                        <li>
                            When applying best execution we are not under all
                            circumstances required to prioritise the total
                            consideration of the transaction as being the most
                            important factor in achieving the best possible
                            result for you.
                        </li>
                        <li>
                            We are not required to inform you about disturbances
                            which may hinder the proper and speedy execution of
                            your order(s).
                        </li>
                        <li>
                            Should we provide you with periodic statements, we
                            are not required to provide them as frequently as
                            for retail clients.
                        </li>
                        <li>
                            You are likely to have fewer rights to compensation
                            of loss in the event of our bankruptcy under any
                            scheme under local law for the payment of
                            compensation.
                        </li>
                    </ol>
                </p>

                <h2 className="text-2xl text-logo-blue">Opt-Up Form</h2>
                <h3 className="text-lg mt-4">
                    By submitting this MiFID Professional Status Request you
                    acknowledge that you are aware that you are granted less
                    protection as explained in the previous screen. In addition
                    you confirm that you meet at least 2 out of the 3 conditions
                    mentioned below. Please check the boxes of the conditions
                    which are applicable to you
                </h3>
                <div className="mt-4">
                    <Formik
                        initialValues={formValues}
                        enableReinitialize={true}
                        onSubmit={submitForm}
                        validateOnBlur={true}
                        validateOnMount={true}
                        validateOnChange={true}
                    >
                        {({ values, isValid, handleChange, isSubmitting }) => {
                            return (
                                <div>
                                    <Form>
                                        {radioGroups.map(
                                            ({
                                                name,
                                                label,
                                                optionsData,
                                                documentDescription,
                                                document,
                                            }) => (
                                                <div
                                                    key={name}
                                                    className="mb-4"
                                                >
                                                    <FormRadioGroup
                                                        name={name}
                                                        label={label}
                                                        justifyContent="justify-start"
                                                        positionLeft={true}
                                                        onChange={(
                                                            e: React.ChangeEvent<any>
                                                        ) => {
                                                            handleChange(e);
                                                        }}
                                                        className="max-w-max"
                                                        optionsData={
                                                            optionsData
                                                        }
                                                        isHorizontal={true}
                                                    />
                                                    <div className="-mt-2">
                                                        <div className="font-semibold">
                                                            {
                                                                documentDescription
                                                            }
                                                        </div>
                                                        <FileUploadAsync
                                                            name={document}
                                                            fileLabel={
                                                                document as UploadDocumentType
                                                            }
                                                            hasLabel={true}
                                                        />
                                                    </div>
                                                </div>
                                            )
                                        )}

                                        <SubscriptionsFormikSubmitBtns
                                            isSubscriptionReadOnly={false}
                                            disabled={isSubmitting || !isValid}
                                        />
                                    </Form>
                                </div>
                            );
                        }}
                    </Formik>
                </div>
            </div>
        </div>
    );
};
