import React, { useContext, useEffect, useState } from 'react';
import { Outlet, useLocation, useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { useConfigurationState } from '@contexts/ConfigurationContext';
import {
    EndInvestorProfileResponse,
    SubscriptionStatusEnum,
} from '@interfaces/Api';

import { useSubscriptionTemplateState } from '@contexts/SubscriptionTemplatesContext';
import { useEndInvestorProfileState } from '@contexts/EndInvestorProfileContext';

import {
    SubscriptionSteps,
    SubscriptionStatusInfo,
} from '@components/Organisms';

import {
    getSubscriptionSteps,
    getSubscriptionTemplateSteps,
    SubscriptionStep,
} from '@services/SubscriptionsHelper';

import { useMountEffect } from '@hooks/useMountEffect';
import { useProduct } from '@stores/Products/useProduct';
import { useSubscription } from '@stores/Subscriptions/useSubscription';
import { useSubscriptionsActions } from '@stores/Subscriptions';

interface SubscriptionPageContext {
    steps: SubscriptionStep[];
    showPreviousStep?: boolean;
    showNextStep?: boolean;
    goToPreviousStep?: () => void;
    goToNextStep?: () => void;
    endInvestorProfile?: EndInvestorProfileResponse;
}

const SubscriptionPageContext = React.createContext<SubscriptionPageContext>({
    steps: [],
    showPreviousStep: false,
    showNextStep: false,
});

export const useSubscriptionPageContext = () =>
    useContext(SubscriptionPageContext);

const EditSubscription: React.FC = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const location = useLocation();
    const { subscriptionId, subscriptionTemplateId } = useParams();

    const { subscription } = useSubscription(subscriptionId);

    const {
        updateSubscriptionBeneficialOwnership,
        updateSubscriptionPoliticalExposure,
        updateSubscriptionContacts,
        updateSubscriptionTaxInformation,
        updateSubscriptionSourceOfWealth,
        updateSubscriptionDistributionOfFunds,
        updateSubscriptionDistributorDetails,
        updateSubscription,
        updateSubscriptionReport,
        updateSubscriptionSupportingDocuments,
    } = useSubscriptionsActions();

    const {
        getSubscriptionTemplate,
        subscriptionTemplate,
        putSubscriptionTemplateDistributorDetails,
        putSubscriptionTemplateSourceOfWealth,
        putSubscriptionTemplateDistributionOfFunds,
        putSubscriptionTemplateBeneficialOwnership,
        putSubscriptionTemplatePoliticalExposure,
        putSubscriptionTemplateContacts,
        putSubscriptionTemplateTaxInformation,
        putSubscriptionTemplateName,
    } = useSubscriptionTemplateState();

    const { preferences } = useConfigurationState();
    const { product } = useProduct(subscription.productId);

    const { getEndInvestorProfile } = useEndInvestorProfileState();
    const [endInvestorProfile, setEndInvestorProfile] =
        useState<EndInvestorProfileResponse>();

    const [steps, setSteps] = useState<SubscriptionStep[]>([]);
    const [showPreviousStep, setShowPreviousStep] = useState<boolean>(false);
    const [previousStep, setPreviousStep] = useState<SubscriptionStep | null>();
    const [showNextStep, setShowNextStep] = useState<boolean>(false);
    const [nextStep, setNextStep] = useState<SubscriptionStep | null>();

    useMountEffect(() => {
        if (subscriptionTemplateId) {
            getSubscriptionTemplate(subscriptionTemplateId).then();
        }
    });

    useEffect(() => {
        const steps = subscriptionId
            ? getSubscriptionSteps(
                  t,
                  endInvestorProfile,
                  subscription,
                  preferences?.feature,
                  product
              )
            : getSubscriptionTemplateSteps(
                  t,
                  endInvestorProfile,
                  subscriptionTemplate,
                  preferences?.feature
              );

        // check if the step in the url is valid
        const currentStepEndPathUrl = location.pathname.substring(
            location.pathname.lastIndexOf('/') + 1
        );
        const currentStepIndex = steps.findIndex(
            (a) => a.endPathUrl == currentStepEndPathUrl
        );

        if (currentStepIndex == -1 && steps.length) {
            navigate(
                {
                    pathname: `${BASE_URL}/${URL_ID}/${steps[0].endPathUrl}`,
                },
                { replace: true }
            );
        }

        setSteps(steps);
    }, [
        endInvestorProfile,
        preferences,
        product,
        subscription,
        subscriptionId,
        subscriptionTemplate,
    ]);

    useEffect(() => {
        const endInvestorProfileId =
            subscription?.endInvestorProfileId ||
            subscriptionTemplate?.endInvestorProfileId;
        if (endInvestorProfileId) {
            getEndInvestorProfile(endInvestorProfileId).then(
                (endInvestorProfile) => {
                    setEndInvestorProfile(endInvestorProfile);
                }
            );
        }
    }, [
        subscription?.endInvestorProfileId,
        subscriptionTemplate.endInvestorProfileId,
        // adding getEndInvestorProfile to the dependency array causes a loop where this is called multiple times
    ]);

    useEffect(() => {
        // find current step
        const currentStepEndPathUrl = location.pathname.substring(
            location.pathname.lastIndexOf('/') + 1
        );
        const currentStepIndex = steps.findIndex(
            (a) => a.endPathUrl == currentStepEndPathUrl
        );
        // set previous step
        const previousStep = steps[currentStepIndex - 1];
        setShowPreviousStep(!!previousStep);
        setPreviousStep(previousStep);
        // set next step
        const nextStep = steps[currentStepIndex + 1];
        setShowNextStep(!!nextStep);
        setNextStep(nextStep);
    }, [steps, location]);

    const BASE_URL = subscriptionId
        ? '/subscriptions'
        : '/subscription-templates';
    const URL_ID = subscriptionId || subscriptionTemplateId;

    const goToNextStep = () =>
        navigate({ pathname: `${BASE_URL}/${URL_ID}/${nextStep?.endPathUrl}` });

    const goToPreviousStep = () =>
        navigate({
            pathname: `${BASE_URL}/${URL_ID}/${previousStep?.endPathUrl}`,
        });

    const handleCancelSubscription = async () => {
        await updateSubscription({
            subscriptionId,
            body: {
                status: 'cancelled' as SubscriptionStatusEnum.cancelled,
            },
        });
    };

    return (
        <div className="container max-w-screen  pb-6">
            {subscription?._id && (
                <SubscriptionStatusInfo
                    subscription={subscription}
                    product={product}
                    onCancelSubscription={handleCancelSubscription}
                />
            )}

            <div className="flex flex-row pt-4">
                <aside className="block md:w-3/12 px-0">
                    <SubscriptionSteps steps={steps} />
                </aside>
                <main role="main" className="w-full md:w-9/12 px-0 md:px-10">
                    <SubscriptionPageContext.Provider
                        value={{
                            steps,
                            showPreviousStep,
                            goToPreviousStep,
                            showNextStep,
                            goToNextStep,
                            endInvestorProfile,
                        }}
                    >
                        <Outlet
                            context={{
                                data: subscriptionId
                                    ? subscription
                                    : subscriptionTemplate,
                                updateTemplateName: putSubscriptionTemplateName,
                                updateDistributorDetails: subscriptionId
                                    ? updateSubscriptionDistributorDetails
                                    : putSubscriptionTemplateDistributorDetails,
                                updateSourceOfWealth: subscriptionId
                                    ? updateSubscriptionSourceOfWealth
                                    : putSubscriptionTemplateSourceOfWealth,
                                updateDistributionOfFunds: subscriptionId
                                    ? updateSubscriptionDistributionOfFunds
                                    : putSubscriptionTemplateDistributionOfFunds,
                                updateBeneficialOwnership: subscriptionId
                                    ? updateSubscriptionBeneficialOwnership
                                    : putSubscriptionTemplateBeneficialOwnership,
                                updatePoliticalExposure: subscriptionId
                                    ? updateSubscriptionPoliticalExposure
                                    : putSubscriptionTemplatePoliticalExposure,
                                updateContacts: subscriptionId
                                    ? updateSubscriptionContacts
                                    : putSubscriptionTemplateContacts,
                                updateTaxInformation: subscriptionId
                                    ? updateSubscriptionTaxInformation
                                    : putSubscriptionTemplateTaxInformation,
                                updateReport: updateSubscriptionReport,
                                updateSupportingDocuments:
                                    updateSubscriptionSupportingDocuments,
                            }}
                        />
                    </SubscriptionPageContext.Provider>
                </main>
            </div>
        </div>
    );
};

export default EditSubscription;
