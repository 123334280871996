import { Formik, Form } from 'formik';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { useProductSubmit } from '@hooks/useProductSubmit';

import { DataReview } from '@components/Molecules';
import { FileUploadAsync } from '@components/Organisms';
import EditProductFormikButtons from '@pages/Admin/ProductsManagement/Components/EditProductFormikButtons';
import { useStorageTokenState } from '@contexts/StorageTokenContext';
import { ProductResponse } from '@interfaces/Api';
import { useProductsState } from '@stores/Products';

export const ProductLogo: React.FC = () => {
    const title = 'Upload Logo';
    const subTitle = 'Please upload an product logo';
    const [product, setProduct] = useState<ProductResponse>();

    const { productId } = useParams();
    const { products } = useProductsState();
    const { withToken } = useStorageTokenState();
    const onSubmit = useProductSubmit(productId, `visibility-settings`);

    useEffect(() => {
        setProduct(products.filter((e) => e._id === productId)[0]);
    }, [productId, products]);

    return (
        <div className="w-full">
            <h1 className="text-xl text-gray-500">{title}</h1>

            <Formik
                initialValues={{
                    logo: product?.logo,
                }}
                onSubmit={(values) =>
                    onSubmit({
                        ...values,
                    })
                }
                enableReinitialize={true}
            >
                {({ values }) => (
                    <Form>
                        <div className="mb-3">
                            <h3 className="text-sm font-normal leading-8 mb-3">
                                {subTitle}
                            </h3>

                            {values.logo?.[0] && (
                                <DataReview>
                                    <p className="text-sm font-bold mb-2">
                                        Your Logo
                                    </p>
                                    <div className="mr-2">
                                        <img
                                            src={withToken(values.logo[0].url)}
                                            alt={
                                                values.logo[0].label ||
                                                values.logo[0].fileName
                                            }
                                            height={80}
                                        />
                                    </div>
                                </DataReview>
                            )}

                            <div className="my-4">
                                <FileUploadAsync
                                    name="logo"
                                    hasLabel={true}
                                    imageOnly
                                    maxFiles={1}
                                />
                            </div>

                            <EditProductFormikButtons
                                productId={productId}
                                isReadOnly={false}
                                pathPrevious="product-documents"
                                pathNext="visibility-settings"
                            />
                        </div>
                    </Form>
                )}
            </Formik>
        </div>
    );
};
