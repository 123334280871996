import { useState } from 'react';
import { Field, Form, Formik } from 'formik';
import { Button, InputBox } from '@components/Atoms';
import { notifySuccess, notifyError } from '@helpers/toastrHelper';
import {
    applicationSettingPut,
    applicationSettingGet,
} from '@api/ApplicationSetting';
import { ApplicationSetting } from '@interfaces/Api/ApplicationSetting';

export const DistributorContactEmail = () => {
    const [defaultDistributorContactEmail, setDefaultDistributorContactEmail] = useState<string>('');

    const getCurrentConfiguration = async () => {
        try {
            const getData = async () => await applicationSettingGet();

            getData().then((r: Pick<ApplicationSetting, 'distributorContactEmail'>) => {
                if (r.distributorContactEmail) setDefaultDistributorContactEmail(r?.distributorContactEmail);
            });
        } catch (error) {
            notifyError(
                'There was an error getting the current distributor contact email. Please try again.'
            );
        }
    };

    getCurrentConfiguration();


    const handleSubmit = ({distributorContactEmail}: { distributorContactEmail: string }) => {
        const updateConfiguration = async () => {
            try {
                await applicationSettingPut({
                    distributorContactEmail,
                });

                notifySuccess('Distributor contact email updated successfully');
            } catch (_) {
                notifyError(
                    'There was an error updating the distributor contact email. Please try again.'
                );
            }
        };

        updateConfiguration();
    };

    return (
        <div>
            <h1 className="text-logo-blue text-3xl font-bold mb-4">
                Distributor Contact Email
            </h1>
            <div className="w-full">
                <Formik
                    initialValues={{
                        distributorContactEmail: defaultDistributorContactEmail,
                    }}
                    onSubmit={handleSubmit}
                    enableReinitialize
                >
                    <Form>
                        <Field name="distributorContactEmail" type="string">
                            {(props: any) => (
                                <InputBox
                                    name='distributorContactEmail'
                                    type='email'
                                    value={props.field.value}
                                    onChange={(e) => {
                                        const { value } = e.target;
                                        props.form.setFieldValue(
                                            'distributorContactEmail',
                                            value
                                        );
                                    }}
                                />
                            )}
                        </Field>
                        <div className="w-full flex justify-end pt-4 mb-2">
                            <Button type="submit" label="Update" />
                        </div>
                    </Form>
                </Formik>
            </div>
        </div>
    );
};
