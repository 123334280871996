interface RoundIconProps {
    icon?: JSX.Element | JSX.Element[] | any;
    iconColorClass?: string;
    bgColorClass?: string;
    className?: string;
}

const RoundIcon = ({
    icon: Icon,
    iconColorClass = 'text-gold-500 dark:text-gold-300',
    bgColorClass = 'bg-gold-300 dark:bg-gold-500',
    className,
}: RoundIconProps) => {
    const baseStyle = 'p-3 rounded-full';
    const cls = `${baseStyle} ${iconColorClass} ${bgColorClass} ${className}`;
    return (
        <div className={cls}>
            <Icon className="w-8 h-8" />
        </div>
    );
};

export default RoundIcon;
