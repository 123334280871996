import { BaseUrl } from '@helpers/Environment';
import { notifyError } from '@helpers/toastrHelper';
import { AuthRoleEnum, SystemUserResponse } from '@interfaces/Api';
import { SystemUserRequestBody } from '@interfaces/Api/SystemUserRequest';
import { Http } from '@libs/http';

const API_SYSTEM_USERS_URL = `${BaseUrl}/system-users`;

export const handleFetchSystemUsers = async (
    set,
    hasAssetManagerAndIntermediaryInfo = false
) => {
    try {
        set({ isLoading: true });

        const systemUsers: SystemUserResponse[] = await Http.get(
            API_SYSTEM_USERS_URL,
            undefined,
            {
                hasAssetManagerAndIntermediaryInfo,
            }
        );
        set({ systemUsers }, false, 'fetchSystemUsers');
    } catch (_) {
        notifyError('An error has occured, system users have not been loaded');
    }

    set({ isLoading: false });
};

export const handleFetchSystemUser = async (set, systemUserId: string) => {
    try {
        set({ isLoading: true });

        const systemUser: SystemUserResponse = await Http.get(
            `${API_SYSTEM_USERS_URL}/${systemUserId}`
        );
        set({ systemUser }, false, 'fetchSystemUser');
    } catch (_) {
        notifyError('An error has occured, system user has not been loaded');
    }

    set({ isLoading: false });
};

export const handleDeleteSystemUser = async (systemUserId: string) => {
    try {
        await Http.delete(`${API_SYSTEM_USERS_URL}/${systemUserId}`);
    } catch (_) {
        notifyError('An error has occured, system user has not been deleted');
    }
};

export const SystemUsersGet = async (systemUserId: string) =>
    Http.get<SystemUserResponse>(`${API_SYSTEM_USERS_URL}/${systemUserId}`);

export const SystemUsersDelete = async (systemUserId: string) =>
    Http.delete(`${API_SYSTEM_USERS_URL}/${systemUserId}`);

export const SystemUsersPost = (systemUser: SystemUserRequestBody) =>
    Http.post(API_SYSTEM_USERS_URL, systemUser);

export const SystemUsersPut = (
    systemUser: SystemUserRequestBody,
    userId: string
) => Http.put(`${API_SYSTEM_USERS_URL}/${userId}`, systemUser);

export const SystemUsersImportPost = (systemUser: SystemUserRequestBody[]) =>
    Http.post(`${API_SYSTEM_USERS_URL}/import`, systemUser);

export const SystemUsersSwitchRolePut = (role: AuthRoleEnum) =>
    Http.put(`${API_SYSTEM_USERS_URL}/switch-user-role`, { role });

export const inviteExternalWealthManager = (
    systemUser: Partial<SystemUserResponse>
) =>
    Http.post(
        `${API_SYSTEM_USERS_URL}/invite-external-wealth-manager`,
        systemUser
    );

export const getAdvisors = (intermediaryId: string) =>
    Http.get<SystemUserResponse[]>(
        `${API_SYSTEM_USERS_URL}/${intermediaryId}/advisors`
    );

export const setLanguagePreference = (
    systemUserId: string,
    languagePreference: string
) =>
    Http.put<SystemUserResponse>(
        `${API_SYSTEM_USERS_URL}/${systemUserId}/set-language-preference/${languagePreference}`,
    );