import { useBookingCentresStore } from './BookingCentres.store';

const useBookingCentresState = () =>
    useBookingCentresStore((state) => ({
        isLoading: state.isLoading,
        bookingCentres: state.bookingCentres,
    }));

const useBookingCentresActions = () =>
    useBookingCentresStore((state) => state.actions);

export { useBookingCentresState, useBookingCentresActions };
