import { ITenantComponentProvider } from '../ITenantComponentProvider';
import RegisterProspectiveInvestor from './RegisterProspectiveInvestor/RegisterProspectiveInvestor';
import ProspectiveInvestorProfilePage from './ProspectiveInvestorProfilePage/ProspectiveInvestorProfilePage';
import SuitabilityQuestionnairePage from './SuitabilityQuestionnaire/SuitabilityQuestionnairePage';
import ProspectiveInvestorListPage from './ProspectiveInvestorsListPage/ProspectiveInvestorsListPage';
import ProspectiveInvestorViewPage from './ProspectiveInvestorsListPage/ProspectiveInvestorViewPage';
import ProspectiveInvestorDefaultsPage from './ProspectiveInvestorDefaultsPage/ProspectiveInvestorDefaultsPage';

export class HGComponents implements ITenantComponentProvider {
    GetB2CClientRegistrationPage = () => {
        return RegisterProspectiveInvestor;
    };
    GetProspectiveInvestorProfilePage = () => {
        return ProspectiveInvestorProfilePage;
    };
    GetSuitabilityQuestionnairePage = () => {
        return SuitabilityQuestionnairePage;
    };
    GetProspectiveInvestorListPage = () => {
        return ProspectiveInvestorListPage;
    };
    GetProspectiveInvestorViewPage = () => {
        return ProspectiveInvestorViewPage;
    };
    GetProspectiveInvestorsDefaults = () => {
        return ProspectiveInvestorDefaultsPage;
    };
}
