/**
 * @export
 * @enum {string}
 */
export enum ProposalStatusEnum {
    proposed = 'proposed',
    accepted = 'accepted',
    rejected = 'rejected',
}

// Status (Proposed (amber)Accepted (Green)/ Rejected (Red))
