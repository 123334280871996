import { Button } from '@components/Atoms';
import { WidgetLineChart } from '@components/Charts';
import {
    formatMetricsValue,
    TabsData,
} from '@components/Organisms/Dashboard/Dashboard.helper';
import { dateShortestFormatter, formatDate } from '@helpers/Date.helper';
import { getFormattedCurrency } from '@helpers/isoCurrencies';
import { MetricType } from '@interfaces/Api/ProductPerformance';
import { useEffect, useState } from 'react';
import { Tile } from './Tile';
import { TileBackgroundColor } from './Tile/Tile';
import { useTranslation } from 'react-i18next';

interface ChartPoint {
    nav?: number;
    date: string;
}

type NAVTimePeriod =
    | 'YTDReturn'
    | '1YReturn'
    | '3YReturn'
    | '5YReturn'
    | '10YReturn';

const timePeriods = [
    { key: '1MReturn', label: '1 Month', abbreviation: '1M' },
    { key: '3MReturn', label: '3 Month', abbreviation: '3M' },
    { key: 'YTDReturn', label: 'YTD', abbreviation: 'YTD' },
    { key: '1YReturn', label: '1 Year', abbreviation: '1Y' },
    { key: '3YReturn', label: '3 Year', abbreviation: '3Y' },
    { key: '5YReturn', label: '5 Year', abbreviation: '5Y' },
    { key: '10YReturn', label: '10 Year', abbreviation: '10Y' },
];

export const OpenEndedPerformanceTabContent = (data: TabsData) => {
    const { t } = useTranslation();
    const { performanceData, valuationDate } = data;
    const [navChartValues, setNavChartValues] = useState<ChartPoint[]>([]);
    const [navChartValuesInRange, setNavChartValuesInRange] = useState<
        ChartPoint[]
    >([]);
    const [selectedTimePeriod, setSelectedTimePeriod] =
        useState<NAVTimePeriod>('10YReturn');

    const latestPerformanceData = data.latestPerformanceData.filter((data) => {
        return (
            data.metricType !== MetricType.NAV &&
            data.metricType !== MetricType.DISTRIBUTIONRATE
        );
    });

    const latestNavData = data.latestPerformanceData.find(
        (data) => data.metricType === MetricType.NAV
    );

    const latestDistributionRate = data.latestPerformanceData.find(
        (data) => data.metricType === MetricType.DISTRIBUTIONRATE
    );

    useEffect(() => {
        const getPerformanceMetric = (metricType: MetricType) => {
            const performanceDataForMetric = performanceData
                .filter((performance) => performance.metricType === metricType)
                .map((performance) => {
                    return {
                        label: formatDate(performance.date.toString()),
                        value: performance.value,
                        metricType: metricType,
                    };
                });

            return performanceDataForMetric;
        };

        const getNAVPerformanceChart = () => {
            const navPoints = getPerformanceMetric(MetricType.NAV);

            let chartPoints: ChartPoint[] = [];

            const allDates = new Set<string>();
            const navPointsMap = new Map<string, number>();
            navPoints.forEach((point) => {
                navPointsMap.set(point.label, point.value);
                allDates.add(point.label);
            });

            allDates.forEach((date) => {
                chartPoints.push({
                    date,
                    nav: navPointsMap.get(date),
                });
            });

            

            // sort chartpoints by date
            chartPoints = chartPoints.sort((a, b) => {
                if (a.date && b.date) {
                    return (
                        new Date(a.date).getTime() - new Date(b.date).getTime()
                    );
                }
                return 0;
            });

            return chartPoints;
        };
        const unfilteredNavChartValues = getNAVPerformanceChart();
        setNavChartValues(unfilteredNavChartValues);
        setNavChartValuesInRange(unfilteredNavChartValues);
    }, [performanceData]);

    const convertDaysToMilliseconds = (days: number) => {
        return (1000 * 60 * 60 * 24) * days;
    };

    const DaysPerYear = 365;

    const daysToGoBack = new Map<string, number>([
        ['1YReturn', 365],
        ['3YReturn', 365 * 3],
        ['5YReturn', 365 * 5],
        ['10YReturn', 365 * 10],
    ]);

    const isInRange = (point: ChartPoint, timePeriod: NAVTimePeriod) => {
        if (timePeriod === 'YTDReturn') {
            const pointDate = new Date(point.date);
            const now = new Date();
            return pointDate.getFullYear() === now.getFullYear();
        }

        const startDate = new Date();
        startDate.setDate(startDate.getDate() - daysToGoBack.get(timePeriod)!);
        
        const pointDate = new Date(point.date);

        return pointDate > startDate;
    };

    const onTimePeriodClick = (timePeriod: NAVTimePeriod) => {
        const filteredNavChartValues = navChartValues.filter((point) => {
            return isInRange(point, timePeriod);
        });

        setSelectedTimePeriod(timePeriod);
        setNavChartValuesInRange(filteredNavChartValues || []);
    };

    const getAnnualisedDistributionRate = (latestDistributionRate?: number) => {
        if (latestDistributionRate) {
            let diffYears =
                (new Date(data.valuationDate).getTime() -
                    new Date(data.fundData.inceptionDate).getTime()) /
                1000;
            diffYears /= 60 * 60 * 24;
            diffYears = Math.abs(Math.round(diffYears / 365.25));
            return latestDistributionRate / diffYears;
        }
    };

    const getNAVChartTimeControls = () => {
        const getButtonType = (timePeriod: NAVTimePeriod) => {
            if (timePeriod === selectedTimePeriod) {
                return 'primary';
            }
            return 'secondary';
        };

        const controlTimePeriods = timePeriods.filter((timePeriod) => {
            return timePeriod.key !== '1MReturn' && timePeriod.key !== '3MReturn';
        });

        return (
            <ul className="mb-8 flex justify-center">
                {controlTimePeriods.map((timePeriod) => (
                    <Button
                        key={timePeriod.key}
                        className="mr-2"
                        buttonType={getButtonType(
                            timePeriod.key as NAVTimePeriod
                        )}
                        onClick={(e) =>
                            onTimePeriodClick(timePeriod.key as NAVTimePeriod)
                        }
                        label={timePeriod.abbreviation}
                    />
                ))}
            </ul>
        );
    };

    return (
        <>
            <h3 className="text-2xl font-bold pb-2">
                {t('subscriptions.add.investment_details.share_class')} {data.investmentData.shareClass.name}
            </h3>
            <span className="text-md text-bg-logo-grey">
                Updated {dateShortestFormatter(valuationDate)}
            </span>

            <div className="flex justify-between mt-6 flex-wrap">
                <div className="mr-2 flex-1">
                    <Tile
                        backgroundColor={TileBackgroundColor.LIGHTBLUE}
                        isFixedHeight
                        isTextLarge
                        spaceBetweenItems
                        list={[
                            '1 Year Annualised Return',
                            formatMetricsValue({
                                value:
                                    latestPerformanceData.find((data) => {
                                        return (
                                            data.metricType ===
                                            MetricType.Y1RETURN
                                        );
                                    })?.value || '-',
                                label: MetricType['Y1RETURN'],
                            }),
                        ]}
                        iconPos="right"
                    />
                </div>

                <div className="flex-1 mr-2">
                    <Tile
                        backgroundColor={TileBackgroundColor.LIGHTERBLUE}
                        spaceBetweenItems
                        isFixedHeight
                        isTextLarge
                        list={[
                            'Annualised Distribution Rate',
                            formatMetricsValue({
                                value:
                                    getAnnualisedDistributionRate(
                                        latestDistributionRate?.value
                                    ) || '-',
                                label: MetricType['DISTRIBUTIONRATE'],
                            }),
                        ]}
                    />
                </div>

                <div className="flex-1 mr-2">
                    <Tile
                        backgroundColor={TileBackgroundColor.LIGHTESTBLUE}
                        spaceBetweenItems
                        isFixedHeight
                        isTextLarge
                        list={[
                            'Nav per Unit',
                            getFormattedCurrency(
                                latestNavData?.value,
                                data.investmentData.shareClass.currency,
                                true 
                            ),
                        ]}
                    />
                </div>
            </div>

            {latestPerformanceData.length > 0 && (
                <div className="flex flex-col mt-8 mb-12">
                    <h4 className="text-2xl font-bold pb-6">{t('client.portfolio.card.return')}</h4>
                    <table className="w-full text-lg hidden lg:table">
                        <thead className="border-b">
                            <tr className="text-brand-navy font-bold">
                                {timePeriods.map((timePeriod) => {
                                    return (
                                        <th
                                            key={timePeriod.key}
                                            className="pr-6 pb-2 text-left"
                                        >
                                            {timePeriod.label}
                                        </th>
                                    );
                                })}
                            </tr>
                        </thead>
                        <tbody className="bg-white">
                            <tr className="whitespace-nowrap border-b">
                                {latestPerformanceData.map((performance, i) => {
                                    return (
                                        <td
                                            key={i}
                                            className="pr-6 py-4 text-left"
                                        >
                                            {formatMetricsValue({
                                                value:
                                                    performance.value.toFixed(
                                                        2
                                                    ) || '-',
                                                label: MetricType['Y1RETURN'],
                                            })}
                                        </td>
                                    );
                                })}
                            </tr>
                        </tbody>
                    </table>
                </div>
            )}

            {navChartValues.length > 0 && (
                <div className="flex flex-col mt-8 mb-10">
                    <h4 className="text-2xl font-bold pb-8">NAV</h4>
                    {getNAVChartTimeControls()}
                    <WidgetLineChart
                        data={{
                            labels: navChartValuesInRange.map((a) => a.date),
                            datasets: [
                                {
                                    label: 'NAV',
                                    data: navChartValuesInRange.map(
                                        (a) => a.nav
                                    ) as any,
                                    borderColor: '#00376E',
                                    backgroundColor: '#00376E',
                                    yAxisID: 'nav',
                                },
                            ],
                        }}
                        options={{
                            plugins: {
                                legend: {
                                    display: true,
                                    position: 'chartArea',
                                    align: 'start',
                                    labels: {
                                        boxWidth: 8,
                                        boxHeight: 8,
                                        padding: 20,
                                        textAlign: 'left',
                                    },
                                },
                            },
                            scales: {
                                nav: {
                                    title: {
                                        text: 'NAV',
                                        display: true,
                                    },
                                    position: 'left',
                                    ticks: {
                                        callback: (value: string | number) => {
                                            return `${getFormattedCurrency(
                                                Number(value),
                                                data.investmentData?.shareClass
                                                    ?.currency
                                            )}`;
                                        },
                                    },
                                    grid: {
                                        drawOnChartArea: false,
                                        drawTicks: false,
                                    },
                                },
                                x: {
                                    type: 'time',
                                    time: {
                                        unit: 'year',
                                    },
                                    grid: {
                                        drawOnChartArea: true,
                                        drawTicks: false,
                                    },
                                },
                            },
                        }}
                    />
                </div>
            )}
        </>
    );
};
