export type LabelElement = {
    label: string;
    value: string | number;
    toFixedNumber?: number;
};

export const Label = ({ label, value }: LabelElement) => (
    <p className="flex flex-1 justify-between flex-wrap text-xl">
        <span>{label}</span>
        <span className="ml-0 lg:ml-2 pl-4">{value}</span>
    </p>
);
