import { useProductsState, useProductsActions } from '@stores/Products';
import { useMountEffect } from '@hooks/useMountEffect';
import { useAuthState } from '@contexts/AuthContext';
import { AuthRoleEnum } from 'common';

export const useProducts = () => {
    const { products, isLoading } = useProductsState();
    const { fetchProducts } = useProductsActions();
    const { currentUser } = useAuthState();

    useMountEffect(() => {
        const loadData = async () => {
            if (
                currentUser?.user.role === AuthRoleEnum.endInvestor &&
                currentUser?.user.endInvestorProfileIds.length > 0
            ) {
                await fetchProducts(currentUser?.user.endInvestorProfileIds);
            } else {
                await fetchProducts();
            }
        };

        loadData();
    });

    return { products, isLoading };
};
