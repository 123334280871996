import { useBreadCrumbs } from './Breadcrumbs.hook';
import { BreadCrumbsList } from './BreadCrumbsList';

const Breadcrumbs: React.FC = () => {
    const {
        breadCrumbs,
        isSubscriptionTemplatePath,
        isSubscriptionsPath,
        pathId,
    } = useBreadCrumbs();

    return (
        <BreadCrumbsList
            breadCrumbs={breadCrumbs}
            isSubscriptionsPath={isSubscriptionsPath}
            isSubscriptionTemplatePath={isSubscriptionTemplatePath}
            pathId={pathId}
        />
    );
};

export default Breadcrumbs;
