import {
    EndInvestorProfileResponse,
    EndInvestorProfileRequestBody,
    SystemUserResponse,
} from '@interfaces/Api';
import { EndInvestorProfilePortfolio } from '@interfaces/EndInvestorProfilePortfolio';

import { Http } from '../libs/http';
import { BaseUrl } from '@helpers/Environment';
import { getLocalStorageAuthTokens } from '@helpers/auth.helper';

const API_ENDINVESTOR_URL = `${BaseUrl}/end-investor-profiles`;

export const EndInvestorProfilesGetAll = async (advisorId?: string) =>
    Http.get<EndInvestorProfileResponse[]>(API_ENDINVESTOR_URL, undefined, {
        advisorId,
    });

export const EndInvestorProfileGet = async (endInvestorProfileId: string) =>
    Http.get<EndInvestorProfileResponse>(
        `${API_ENDINVESTOR_URL}/${endInvestorProfileId}`
    );

export const updateEndInvestorProfile = async ({
    endInvestorProfileId,
    body,
}: {
    endInvestorProfileId: string;
    body: {
        profileId: string;
    };
}) =>
    await Http.put(
        `${API_ENDINVESTOR_URL}/${endInvestorProfileId}/link`,
        body
    );

export const EndInvestorProfilePortfolioGet = async (
    endInvestorProfileId: string
) =>
    Http.get<EndInvestorProfilePortfolio>(
        `${API_ENDINVESTOR_URL}/${endInvestorProfileId}/portfolio`
    );

export const EndInvestorProfileDocumentsGet = async (
    endInvestorProfileId: string,
    subscriptionId?: string
) => {
    const accessToken = getLocalStorageAuthTokens()?.access?.token;

    const url = new URL(
        `${API_ENDINVESTOR_URL}/${endInvestorProfileId}/documents`
    );
    if (subscriptionId) {
        url.searchParams.set('subscriptionId', subscriptionId);
    }

    const response = await fetch(url.toString(), {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
            Authorization: `Bearer ${accessToken}`,
            'Access-Control-Expose-Headers': 'Content-Disposition',
        },
    });

    return response;
};

export const EndInvestorProfilesPost = async (
    body: EndInvestorProfileRequestBody,
    systemUserId?: string
) => {
    return await Http.post(`${API_ENDINVESTOR_URL}`, body, undefined, {
        systemUserId: systemUserId,
    });
};

export const EndInvestorProfilesPut = async (
    body: EndInvestorProfileRequestBody,
    endInvestorProfileId?: string | undefined
): Promise<EndInvestorProfileResponse> => {
    return await Http.put(
        `${API_ENDINVESTOR_URL}/${endInvestorProfileId}`,
        body
    );
};

export const EndInvestorProfilesDelete = async (
    endInvestorProfileId: string
) => {
    return Http.delete(`${API_ENDINVESTOR_URL}/${endInvestorProfileId}`);
};

export const EndInvestorRequestRMToComplete = async () => {
    return Http.put(`${API_ENDINVESTOR_URL}/authorize-rm`);
};

export const inviteNewEndInvestor = (investor: Partial<SystemUserResponse>) => {
    return Http.post(`${API_ENDINVESTOR_URL}/invite`, investor);
};

export const resendEmailInvitation = async (data: {
    id: string;
}): Promise<any> =>
    Http.post(`${API_ENDINVESTOR_URL}/resend-email-invitation`, data);
