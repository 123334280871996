import { ReviewClientSummaryDetailsProps } from '@interfaces/ReviewClientSummaryDetailsProps';
import { EndInvestorProfileResponse } from '@interfaces/Api';

import { DownloadLink } from '@components/Molecules';

interface ReviewKYCDetailsProps {
    isKycCompliant?: EndInvestorProfileResponse;
}

const ReviewClientKYCDetails = (
    props: ReviewClientSummaryDetailsProps & ReviewKYCDetailsProps
) => (
    <div className="leading-5 mb-4">
        <p className="text-base">
            <span className="font-bold">AML/KYC reliance: </span>
            <span className="capitalize">
                {props.data?.isKycCompliant ? 'Yes' : 'No'}
            </span>
        </p>
        {props.data?.kycDocuments?.map((doc, i) => (
            <div key={`${i}-doc`}>
                <DownloadLink document={doc} />
            </div>
        ))}
    </div>
);

export default ReviewClientKYCDetails;
