import { ReviewClientSummaryDetailsProps } from '@interfaces/ReviewClientSummaryDetailsProps';
import { ReviewItem } from './ReviewItem';

const ReviewClientBusinessDetails = (
    props: ReviewClientSummaryDetailsProps
) => (
    <div className="leading-5 mb-4">
        <ReviewItem
            label="Company Name:"
            value={props.data?.businessEntityDetails?.companyName}
        />
        <ReviewItem
            label="Registration Number:"
            value={props.data?.businessEntityDetails?.registrationNumber}
        />
        <ReviewItem
            label="Subscriber's Legal Form Number:"
            value={props.data?.businessEntityDetails?.subscribersLegalForm}
        />

        <ReviewItem
            label="Subscriber's Register:"
            value={props.data?.businessEntityDetails?.subscribersRegister}
        />

        <ReviewItem
            label="Subscriber's Parent Listing Authority:"
            value={
                props.data?.businessEntityDetails
                    ?.subscriberParentListingAuthority
            }
        />

        <ReviewItem
            label="Subscriber's Parent Regulator:"
            value={props.data?.businessEntityDetails?.subscribersRegulator}
        />
    </div>
);

export default ReviewClientBusinessDetails;
