import React, { useCallback, useState } from 'react';
import * as Yup from 'yup';

import { SystemUserRoleActions } from '@interfaces/Api';
import { Button, Icons } from '@components/Atoms';

import { useAuthState } from '@contexts/AuthContext';
import { AlertBox, Modal, MultiselectCreatable } from '@components/Molecules';

import {
    DocumentTemplate,
    DocumentTemplateRow,
    DocumentTemplateTypeEnum,
    DocumentTemplateOption,
    FormDocumentTemplateUpload,
} from '@interfaces/Api/DocumentTemplate';
import { Formik, Form, FormikHelpers } from 'formik';
import {
    DocumentTemplateDelete,
    DocumentTemplateUpload,
} from '@api/DocumentTemplate';
import { notifySuccess, notifyError } from '@helpers/toastrHelper';
import FileDropper from '@components/Organisms/FileUploadAsync/FileDropper';
import { FileUploadLine } from '@components/Organisms/FileUploadAsync/FileUploadLine';
import { humanFileSize } from '@helpers/File.helper';
import { filterSearchData } from '@components/Organisms/SearchBox/search.helper';
import DocumentTemplatesTableRow from './DocumentTemplateTableRow';
import { GetDocumentTemplateLibraryRows } from './DocumentTemplateTable.logic';

import { useDocumentTemplatesLibraryState } from '@contexts/DocumentTemplateLibrary';
import { DocumentTableHeaders } from './DocumentTableHeaders';

export interface ListDocumentTemplateComponentProps {
    items?: Array<DocumentTemplate>;
    currentUserRoleActions?: SystemUserRoleActions;
    requestSort: (key: keyof DocumentTemplate) => void;
    columnSortConfig: { key: string; direction: string };
    searchText: string;
    setShowDocumentModal: (val: boolean) => void;
    showDocumentUploadModal: boolean;
    hideEdit?: boolean;
    selectable?: boolean;
    hideOperations?: boolean;
    onDeleteCallBack?: (documentTemplate: DocumentTemplateRow) => void;
    onSelectCallback?: (documentTemplate: DocumentTemplateRow, checked: boolean) => void;
}

const supportedFileTypes = [
    'application/msword',
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
];

const DocumentTemplateTable: React.FC<ListDocumentTemplateComponentProps> = ({
    showDocumentUploadModal,
    setShowDocumentModal,
    ...props
}) => {
    const { searchText, items } = props;

    const { getDocumentTemplates } = useDocumentTemplatesLibraryState();
    const { currentUser } = useAuthState();

    const documentTemplateList = GetDocumentTemplateLibraryRows({
        documents: items || [],
    });

    const initialVals: FormDocumentTemplateUpload = {
        fileName: '',
        documentType: undefined,
        url: '',
    };
    const [selectedDocument, setSelectedDocument] = useState<
        FormDocumentTemplateUpload | undefined
    >();
    const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
    const [showDeleteDocumentModal, setShowDeleteDocumentModal] =
        useState<boolean>(false);
    const [uploadingFile, setUploadingFile] = useState<File>();

    const isEdit = selectedDocument ? true : false;

    const validationSchema = Yup.object().shape({
        documentType: Yup.string().required('Required'),
    });

    const documentTypeOptions = Object.values(DocumentTemplateTypeEnum).map(
        (a, i) => ({
            value: Object.keys(DocumentTemplateTypeEnum)[i] || '',
            label: a,
        })
    );

    const closeModal = () => {
        setShowDocumentModal(false);
        setSelectedDocument(undefined);
        setUploadingFile(undefined);
    };

    const closeDeleteModal = () => {
        setShowDeleteDocumentModal(false);
        setSelectedDocument(undefined);
    };

    const inputFileChangeHandler = (
        event: React.ChangeEvent<HTMLInputElement>
    ) => {
        const uploadedFileList = event.target.files;
        if (uploadedFileList && uploadedFileList.length > 1) {
            notifyError('Please upload one file at a time');
            return;
        }
        if (!supportedFileTypes.includes(uploadedFileList[0].type)) {
            notifyError('This file type is not supported');
            return;
        }
        setUploadingFile(uploadedFileList[0]);
    };

    const onDrop = useCallback((acceptedFiles: File[]) => {
        if (acceptedFiles && acceptedFiles.length > 1) {
            notifyError('Please upload one file at a time');
            return;
        }
        if (!supportedFileTypes.includes(acceptedFiles[0].type)) {
            notifyError('This file type is not supported');
            return;
        }

        setUploadingFile(acceptedFiles[0]);
    }, []);

    const onSubmit = async (
        values: DocumentTemplateRow,
        { setSubmitting, resetForm }: FormikHelpers<FormDocumentTemplateUpload>
    ) => {
        if (!values._id && !uploadingFile) {
            notifyError('Please upload a file');
            return;
        }

        setIsSubmitting(true);

        const updateDocument = {
            func: DocumentTemplateUpload,
            successMessage: 'Data has been saved successfully!',
            failMessage: 'Failed to save document!',
        };

        const documentData = {
            ...values,
            documentType: values.documentType,
            ...(uploadingFile && {
                fileName: uploadingFile?.name,
            }),
            ...(!isEdit && {
                createdBy: currentUser?.user?._id,
                createdDate: new Date(),
            }),
            ...(isEdit && {
                updatedBy: currentUser?.user?._id,
                updatedDate: new Date(),
            }),
        } as DocumentTemplate;

        updateDocument
            .func(documentData, uploadingFile)
            .then(async () => {
                notifySuccess(updateDocument.successMessage);
                getDocumentTemplates();
            })
            .catch((e) => {
                if (e.status === 409) {
                    notifyError(e.result.message);
                    return;
                }
                notifyError(updateDocument.failMessage);
            })
            .finally(() => {
                resetForm();
                setIsSubmitting(false);
                setTimeout(() => closeModal(), 300);
            });
    };

    const deleteDocument = async () => {
        if (!selectedDocument?._id) {
            notifyError('Document not found');
            return;
        }
        const deleteDocument = {
            func: DocumentTemplateDelete,
            successMessage: 'Document has been deleted successfully!',
            failMessage: 'Failed to delete document!',
        };
        deleteDocument
            .func(selectedDocument._id)
            .then(async () => {
                notifySuccess(deleteDocument.successMessage);
                getDocumentTemplates();
            })
            .catch((e) => {
                notifyError(deleteDocument.failMessage);
            })
            .finally(() => {
                setTimeout(() => closeDeleteModal(), 300);
            });
    };

    return (
        <div className="h-full pb-9 xs:container xs:mx-auto max-w-full">
            <table className="table-auto w-full rounded-md data-tables-admin">
                <DocumentTableHeaders
                    columnSortConfig={props.columnSortConfig}
                    requestSort={props.requestSort}
                />

                <tbody>
                    {documentTemplateList?.length === 0 && (
                        <tr className="w-full block">
                            <td
                                className="border-b py-2"
                                style={{ border: 'none' }}
                            >
                                <h1 className="text-2xl">
                                    <Icons
                                        name="ExclamationTriangleIconOutline"
                                        strokeWidth="1.5"
                                    />{' '}
                                    No Document Templates found
                                </h1>
                            </td>
                        </tr>
                    )}
                    {documentTemplateList
                        ?.filter((document) => {
                            return filterSearchData({
                                searchData: document,
                                searchQuery: props.searchText?.split(' ') || [],
                                omitKeys: ['__v', '_id'],
                            });
                        })
                        .map((document, i) => {
                            const values = DocumentTemplatesTableRow({
                                document,
                                searchText,
                                setSelectedDocument,
                                setShowDocumentModal,
                                setShowDeleteDocumentModal,
                                hideEdit: props.hideEdit,
                                onDeleteCallBack: props.onDeleteCallBack,
                                onSelectCallback: props.onSelectCallback,
                                selectable: props.selectable,
                                hideOperations: props.hideOperations
                            });
                            return (
                                <tr
                                    key={document.fileName + i}
                                    className="text-xs xl:text-base"
                                >
                                    {values.map((value, i) => (
                                        <td
                                            key={i.toString()}
                                            className="border-b p-2 text-xs xl:text-base"
                                        >
                                            {value}
                                        </td>
                                    ))}
                                </tr>
                            );
                        })}
                </tbody>
            </table>

            {/* Modal for uploading or editing a certain document */}
            <Modal
                width="w-2/5"
                show={showDocumentUploadModal || false}
                onBackdropClick={() => closeModal()}
            >
                <Modal.Header onClose={() => closeModal()}>
                    {selectedDocument ? 'Edit' : 'Upload'} Document Template:
                </Modal.Header>
                <Formik
                    initialValues={selectedDocument || initialVals}
                    validationSchema={validationSchema}
                    enableReinitialize
                    onSubmit={onSubmit}
                >
                    {({ values, setFieldValue }) => (
                        <Form>
                            <Modal.Body>
                                {selectedDocument && (
                                    <p>
                                        Current Document:
                                        <br />
                                        <span className="text-sm">
                                            {selectedDocument.fileName}
                                        </span>
                                    </p>
                                )}
                                {isEdit && (
                                    <AlertBox
                                        alertType="info"
                                        title="Notice"
                                        message="If this document is used for any products,
                                    changing the document will mean we use the
                                    new document for these products hereafter."
                                    />
                                )}
                                <div className="mb-8 z-20">
                                    <MultiselectCreatable
                                        label="Select document template type"
                                        placeholder="Select a template type"
                                        isSingle={true}
                                        defaultValue={[
                                            {
                                                ...(documentTypeOptions.find(
                                                    (option) =>
                                                        option.value ===
                                                        values.documentType
                                                ) || {
                                                    value: '',
                                                    label: '',
                                                }),
                                            },
                                        ]}
                                        handleChange={(
                                            option: DocumentTemplateOption
                                        ) => {
                                            setFieldValue(
                                                'documentType',
                                                option.value
                                            );
                                            if (option.__isNew__) {
                                                documentTypeOptions.push(
                                                    option
                                                );
                                            }
                                        }}
                                        options={documentTypeOptions || []}
                                    />
                                </div>
                                {values.documentType && (
                                    <FileDropper
                                        name="documents"
                                        onDrop={onDrop}
                                        imageOnly={false}
                                        inputFileChangeHandler={
                                            inputFileChangeHandler
                                        }
                                        supportedFileTypes={supportedFileTypes}
                                    />
                                )}

                                {uploadingFile && (
                                    <FileUploadLine
                                        key={uploadingFile.name}
                                        fileName={uploadingFile.name}
                                        fieldName={'uploadingFiles'}
                                        fileSize={humanFileSize(
                                            uploadingFile.size,
                                            2
                                        )}
                                        isLoading={false}
                                        onRemoveFileClick={() =>
                                            setUploadingFile(undefined)
                                        }
                                    />
                                )}
                            </Modal.Body>
                            <Modal.Footer>
                                <Button
                                    type="button"
                                    label="Cancel"
                                    buttonType="secondary"
                                    onClick={() => closeModal()}
                                />
                                <Button
                                    type="submit"
                                    label="Save"
                                    buttonType="primary"
                                    endIcon={
                                        isSubmitting && (
                                            <Icons name={'Loading'} />
                                        )
                                    }
                                />
                            </Modal.Footer>
                        </Form>
                    )}
                </Formik>
            </Modal>
            {/* Modal for deleting a certain document */}
            <Modal
                show={showDeleteDocumentModal}
                onBackdropClick={() => closeDeleteModal()}
            >
                <Modal.Header onClose={() => closeDeleteModal()}>
                    Delete Document Template:
                </Modal.Header>
                <Modal.Body>
                    {selectedDocument && (
                        <p className="mb-4">
                            Document:
                            <br />
                            <span className="text-sm">
                                {selectedDocument.fileName}
                            </span>
                        </p>
                    )}
                    <AlertBox
                        alertType="info"
                        title="Notice"
                        message="If this document is used for any products, deleting this document will mean we use the default system document for this product."
                    />
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        type="button"
                        label="Cancel"
                        buttonType="secondary"
                        onClick={() => closeDeleteModal()}
                    />
                    <Button
                        type="button"
                        onClick={() => deleteDocument()}
                        label="Delete"
                        buttonType="primary"
                    />
                </Modal.Footer>
            </Modal>
        </div>
    );
};
export default DocumentTemplateTable;
