import { useEndInvestorProfileState } from '@contexts/EndInvestorProfileContext';
import {
    EndInvestorProfileResponse,
    SystemUserResponse,
} from '@interfaces/Api';
import { EndInvestorType } from '@interfaces/Api/EndInvestorProfileRequestBody';
import { useSystemUsers } from '@stores/SystemUsers/useSystemUsers';
import { AuthRoleEnum } from 'common';

interface getFilteredLoginAccountsProps {
    systemUsers: SystemUserResponse[];
    endInvestorProfiles: EndInvestorProfileResponse[];
    selectedIntermediary: string;
    selectedEndInvestorType: EndInvestorType;
}

export const getFilteredLoginAccounts = ({
    systemUsers,
    endInvestorProfiles,
    selectedIntermediary,
    selectedEndInvestorType,
}: getFilteredLoginAccountsProps) => {
    return systemUsers
        .filter(
            (systemUser) =>
                systemUser.role === AuthRoleEnum.endInvestor &&
                systemUser.intermediaryId === selectedIntermediary
        )
        .filter((systemUser) => {
            const systemUserEndInvestorProfiles =
                systemUser.endInvestorProfileIds as EndInvestorProfileResponse[];
            if (!selectedEndInvestorType) return false;
            return systemUserEndInvestorProfiles.some(
                (systemUserEndInvestorProfile) =>
                    selectedEndInvestorType === EndInvestorType.nomineeEntity
                        ? endInvestorProfiles.find(
                              (endInvestorProfile) =>
                                  systemUserEndInvestorProfile._id ===
                                  endInvestorProfile._id
                          )?.endInvestorType === EndInvestorType.nomineeEntity
                        : endInvestorProfiles.find(
                              (endInvestorProfile) =>
                                  systemUserEndInvestorProfile._id ===
                                  endInvestorProfile._id
                          )?.endInvestorType !== EndInvestorType.nomineeEntity
            );
        });
};
