const UnsuccessfulSubmission: React.FC = () => {
    return (
        <div className="mt-3 flex flex-col items-center">
            <h2 className="text-2xl logo-blue align-middle pb-4">
                Application unsuccessfull
            </h2>
            <p>
                Unfortunately, based on your responses, your application has not
                been approved. If you require any support, please contact us at
                <a href="mailto:support@hgcapital.com">support@hgcapital.com</a>
                .
            </p>
        </div>
    );
};

export default UnsuccessfulSubmission;
