import { useEffect, useRef } from 'react';
import { getInputStyle } from './InputBox.style';

type InputType =
    | 'text'
    | 'number'
    | 'email'
    | 'password'
    | 'radio'
    | 'date'
    | 'tel';

export interface InputBoxProps {
    name: string;
    id?: string;
    placeholder?: string;
    label?: string;
    defaultValue?: any;
    value?: any;
    onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
    onBlur?: React.EventHandler<any>;
    onKeyDown?: React.EventHandler<any>;
    onKeyPress?: React.EventHandler<any>;
    onKeyUp?: React.EventHandler<any>;
    onFocus?: React.EventHandler<any>;
    onFocusOut?: React.EventHandler<any>;
    onClick?: React.EventHandler<any>;
    rightIcon?: React.ReactNode;
    leftIcon?: React.ReactNode;
    autoFocus?: boolean;
    type?: InputType;
    readOnly?: boolean;
    disabled?: boolean;
    minLength?: number | undefined;
    maxLength?: number | undefined;
    hint?: string;
    dataTestId?: string;
    autoComplete?: 'off' | 'on';
    hasError?: boolean;
    checked?: boolean;
    min?: string | number;
    isHidden?: boolean;
}

export const InputBox = (props: InputBoxProps) => {
    const inputRef = useRef<HTMLInputElement>(null);

    useEffect(() => {
        if (props.autoFocus && inputRef.current) {
            inputRef.current.focus();
        }
    }, [props.autoFocus]);

    const {
        autoFocus,
        name,
        label,
        hasError,
        onChange,
        disabled,
        checked,
        placeholder,
        onBlur,
        value,
        type,
        onKeyPress,
        onClick,
        onKeyUp,
        onFocus,
        leftIcon,
        dataTestId,
        autoComplete,
        minLength,
        maxLength,
        min,
        defaultValue,
        isHidden,
    } = props;
    const inputStyle = getInputStyle({
        disabled,
        label,
        error: hasError,
        leftIcon,
        cursor: 'text',
    });

    return (
        <input
            id={name}
            ref={autoFocus ? inputRef : null}
            autoFocus={autoFocus}
            className={type === 'radio' ? '' : inputStyle}
            type={type}
            name={name}
            min={min}
            placeholder={placeholder}
            disabled={disabled}
            onChange={onChange}
            onFocus={onFocus}
            minLength={minLength}
            onKeyPress={onKeyPress}
            onKeyUp={onKeyUp}
            onClick={onClick}
            maxLength={maxLength}
            onBlur={onBlur}
            value={value}
            checked={checked}
            data-testid={dataTestId}
            autoComplete={autoComplete}
            defaultValue={defaultValue}
            hidden={isHidden}
        />
    );
};
