import { WidgetLineChart } from '@components/Charts';
import { TabsData } from '@components/Organisms/Dashboard/Dashboard.helper';
import { formatDate } from '@helpers/Date.helper';
import { MetricType } from '@interfaces/Api/ProductPerformance';
import { useCallback } from 'react';

export const ClosedEndedPerformanceTabContent = (data: TabsData) => {
    const { performanceData } = data;

    const getPerformanceMetric = useCallback(
        (metricType: MetricType) => {
            const performanceDataForMetric = performanceData
                .filter((performance) => performance.metricType === metricType)
                .map((performance) => {
                    // TODO: needs to be formatted
                    return {
                        label: formatDate(performance.date.toString()),
                        value: performance.value,
                        metricType: metricType,
                    };
                });

            return performanceDataForMetric;
        },
        [performanceData]
    );
    const getIRRMOICPerformanceChart = () => {
        const irrPoints = getPerformanceMetric(MetricType.IRR);
        const moicPoints = getPerformanceMetric(MetricType.MOIC);

        interface ChartPoint {
            moic?: number;
            irr?: number;
            date: string;
        }

        let chartPoints: ChartPoint[] = [];

        const allDates = new Set<string>();
        const irrPointsMap = new Map<string, number>();
        irrPoints.forEach((point) => {
            irrPointsMap.set(point.label, point.value);
            allDates.add(point.label);
        });

        const moicPointsMap = new Map<string, number>();
        moicPoints.forEach((point) => {
            moicPointsMap.set(point.label, point.value);
            allDates.add(point.label);
        });

        allDates.forEach((date) => {
            chartPoints.push({
                date,
                irr: irrPointsMap.get(date),
                moic: moicPointsMap.get(date),
            });
        });

        // sort chartpoints by date
        chartPoints = chartPoints.sort((a, b) => {
            if (a.date && b.date) {
                return new Date(a.date).getTime() - new Date(b.date).getTime();
            }
            return 0;
        });

        return chartPoints;
    };

    const getPerformanceChartDPIRVPITVPI = () => {
        const dpiPoints = getPerformanceMetric(MetricType.DPI);
        const rvpiPoints = getPerformanceMetric(MetricType.RVPI);
        const tvpiPoints = getPerformanceMetric(MetricType.TVPI);

        interface ChartPoint {
            dpi: number;
            rvpi: number;
            tvpi: number;
            date: string;
        }

        let chartPoints: ChartPoint[] = [];

        const allDates = new Set<string>();
        const dpiPointsMap = new Map<string, number>();
        dpiPoints.forEach((point) => {
            dpiPointsMap.set(point.label, point.value);
            allDates.add(point.label);
        });

        const rvpiPointsMap = new Map<string, number>();
        rvpiPoints.forEach((point) => {
            rvpiPointsMap.set(point.label, point.value);
            allDates.add(point.label);
        });

        const tvpiPointsMap = new Map<string, number>();
        tvpiPoints.forEach((point) => {
            tvpiPointsMap.set(point.label, point.value);
            allDates.add(point.label);
        });

        allDates.forEach((date) => {
            chartPoints.push({
                date,
                dpi: dpiPointsMap.get(date) || 0,
                rvpi: rvpiPointsMap.get(date) || 0,
                tvpi: tvpiPointsMap.get(date) || 0,
            });
        });

        // sort chartpoints by date
        chartPoints = chartPoints.sort((a, b) => {
            if (a.date && b.date) {
                return new Date(a.date).getTime() - new Date(b.date).getTime();
            }
            return 0;
        });

        return chartPoints;
    };
    return (
        <>
            {getIRRMOICPerformanceChart() ? (
                <div className="flex flex-col mt-4 mb-10">
                    <h3 className="text-2xl font-bold pb-10">IRR & MOIC</h3>

                    <WidgetLineChart
                        data={{
                            labels: getIRRMOICPerformanceChart().map(
                                (a) => a.date
                            ),
                            datasets: [
                                {
                                    label: 'IRR',
                                    data: getIRRMOICPerformanceChart().map(
                                        (a) => a.irr
                                    ) as any,
                                    borderColor: '#00376E',
                                    backgroundColor: '#00376E',
                                    yAxisID: 'percentage',
                                },
                                {
                                    label: 'MOIC',
                                    data: getIRRMOICPerformanceChart().map(
                                        (a) => a.moic
                                    ) as any,
                                    borderColor: '#809BB6',
                                    backgroundColor: '#809BB6',
                                    yAxisID: 'number',
                                },
                            ],
                        }}
                        options={{
                            plugins: {
                                legend: {
                                    display: true,
                                    position: 'chartArea',
                                    align: 'start',
                                    labels: {
                                        boxWidth: 8,
                                        boxHeight: 8,
                                        padding: 20,
                                        textAlign: 'left',
                                    },
                                },
                            },
                            scales: {
                                percentage: {
                                    title: {
                                        text: 'IRR',
                                        display: true,
                                    },
                                    position: 'left',
                                    ticks: {
                                        callback: (value: string | number) => {
                                            return `${Number(
                                                value
                                            ).toFixed()}%`;
                                        },
                                    },
                                    grid: {
                                        drawOnChartArea: false,
                                        drawTicks: false,
                                    },
                                },
                                number: {
                                    title: {
                                        text: 'MOIC',
                                        display: true,
                                    },
                                    position: 'right',
                                    ticks: {
                                        callback: (value: string | number) => {
                                            return `${Number(value).toFixed(
                                                2
                                            )}x`;
                                        },
                                    },
                                    grid: {
                                        drawOnChartArea: false,
                                        drawTicks: false,
                                    },
                                },
                                x: {
                                    type: 'time',
                                    time: {
                                        unit: 'year',
                                    },
                                },
                            },
                        }}
                    />
                </div>
            ) : null}

            <hr className="my-4" />

            {getPerformanceChartDPIRVPITVPI() ? (
                <div className="flex flex-col mb-10 mt-10">
                    <h3 className="text-2xl font-bold pb-10">
                        DPI, RVPI & TVPI
                    </h3>

                    <WidgetLineChart
                        data={{
                            labels: getPerformanceChartDPIRVPITVPI().map(
                                (a) => a.date
                            ),
                            datasets: [
                                {
                                    label: 'DPI',
                                    data: getPerformanceChartDPIRVPITVPI().map(
                                        (a) => a.dpi
                                    ),
                                    borderColor: '#00376E',
                                    backgroundColor: '#00376E',
                                    yAxisID: 'number',
                                },
                                {
                                    label: 'RVPI',
                                    data: getPerformanceChartDPIRVPITVPI().map(
                                        (a) => a.rvpi
                                    ),
                                    borderColor: '#809BB6',
                                    backgroundColor: '#809BB6',
                                    yAxisID: 'number',
                                },
                                {
                                    label: 'TVPI',
                                    data: getPerformanceChartDPIRVPITVPI().map(
                                        (a) => a.tvpi
                                    ),
                                    borderColor: '#3b82f680',
                                    backgroundColor: '#3b82f680',
                                    yAxisID: 'number',
                                },
                            ],
                        }}
                        options={{
                            plugins: {
                                legend: {
                                    display: true,
                                    position: 'chartArea',
                                    align: 'start',
                                    labels: {
                                        boxWidth: 8,
                                        boxHeight: 8,
                                        padding: 20,
                                        textAlign: 'left',
                                    },
                                },
                            },
                            scales: {
                                number: {
                                    type: 'linear',
                                    position: 'left',
                                    ticks: {
                                        callback: (value: string | number) => {
                                            return `${Number(value).toFixed(
                                                1
                                            )}x`;
                                        },
                                    },
                                    grid: {
                                        drawOnChartArea: false,
                                        drawTicks: false,
                                    },
                                },
                                x: {
                                    type: 'time',
                                    time: {
                                        unit: 'year',
                                    },
                                },
                            },
                        }}
                    />
                </div>
            ) : null}
        </>
    );
};
