import { useState } from 'react';
import { FieldArray } from 'formik';

import { Button } from '@components/Atoms';
import {
    DataReview,
    InlineFormFrame,
    FormInputBox,
    FormSelect,
} from '@components/Molecules';

import { isoCountriesOptions } from '@helpers/isoCountries';

import getClientRegistrationFormFields from '../ClientRegistrationFormFields';
import { useRegisterInvestorState } from '../RegisterInvestor.context';
import { useTranslation } from 'react-i18next';

export const AddressSelection = () => {
    const { t } = useTranslation();
    const [showAddAddress, setShowAddAddress] = useState<boolean>(false);
    const toggleAddAddress = () => setShowAddAddress((bool) => !bool);

    const emptyAddress = {
        houseName: '',
        residentialLine1: '',
        residentialLine2: '',
        city: '',
        postCode: '',
        countryCode: '',
    };

    return (
        <div className="text-logo-blue">
            <h3 className="text-2xl font-normal leading-8 my-3">{t('client.registration.addresses.title')}</h3>
            <p className="text-base py-2">
                {t('client.registration.addresses.instruction')}
            </p>

            <FieldArray
                name="addresses"
                render={(arrayHelpers) => (
                    <div>
                        {arrayHelpers.form.values?.addresses?.map(
                            (address: any, i: number, arr: any) =>
                                showAddAddress && i === arr.length - 1 ? (
                                    <InlineFormFrame
                                        key={i.toString()}
                                        primaryAction={t('client.registration.addresses.save_address_button')}
                                        onPrimaryAction={() => {
                                            const isAddressInValid =
                                                arrayHelpers?.form?.errors
                                                    ?.addresses &&
                                                Array.isArray(
                                                    arrayHelpers.form.errors
                                                        .addresses
                                                );
                                            const touchedValues = {
                                                addresses: [
                                                    ...arrayHelpers.form.values.addresses.map(
                                                        (address: any) =>
                                                            Object.keys(
                                                                address
                                                            ).reduce(
                                                                (
                                                                    prev,
                                                                    curr
                                                                ) => {
                                                                    return {
                                                                        ...prev,
                                                                        [curr]: true,
                                                                    };
                                                                },
                                                                {}
                                                            )
                                                    ),
                                                ],
                                            };
                                            arrayHelpers.form.setTouched(
                                                touchedValues,
                                                true
                                            );
                                            if (!isAddressInValid) {
                                                toggleAddAddress();
                                            }
                                        }}
                                        secondaryAction={t('ui.controls.cancel')}
                                        onSecondaryAction={() => {
                                            arrayHelpers.remove(i);
                                            toggleAddAddress();
                                        }}
                                    >
                                        <FormInputBox
                                            name={`addresses[${i}].houseName`}
                                            label={
                                                getClientRegistrationFormFields(t)
                                                    .addresses.houseName.label
                                            }
                                            placeholder={t('client.registration.addresses.house_name_number.placeholder')}
                                        />
                                        <FormInputBox
                                            name={`addresses[${i}].residentialLine1`}
                                            label={
                                                getClientRegistrationFormFields(t)
                                                    .addresses.residentialLine1
                                                    .label
                                            }
                                            placeholder={t('client.registration.addresses.line1.placeholder')}
                                        />
                                        <FormInputBox
                                            name={`addresses[${i}].residentialLine2`}
                                            label={
                                                getClientRegistrationFormFields(t)
                                                    .addresses.residentialLine2
                                                    .label
                                            }
                                            placeholder={t('client.registration.addresses.line2.placeholder')}
                                        />
                                        <FormInputBox
                                            name={`addresses[${i}].city`}
                                            label={
                                                getClientRegistrationFormFields(t)
                                                    .addresses.city.label
                                            }
                                            placeholder={t('client.registration.addresses.city.placeholder')}
                                        />
                                        <FormInputBox
                                            name={`addresses[${i}].postCode`}
                                            label={
                                                getClientRegistrationFormFields(t)
                                                    .addresses.postCode.label
                                            }
                                            placeholder={t('client.registration.addresses.postcode.placeholder')}
                                        />
                                        <FormSelect
                                            name={`addresses[${i}].countryCode`}
                                            label={
                                                getClientRegistrationFormFields(t)
                                                    .addresses.countryCode.label
                                            }
                                            optionsData={isoCountriesOptions}
                                            placeholder={t('client.registration.addresses.country.placeholder')}
                                        />
                                    </InlineFormFrame>
                                ) : (
                                    <DataReview
                                        key={i.toString()}
                                        onDelete={() => arrayHelpers.remove(i)}
                                        onEdit={toggleAddAddress}
                                    >
                                        <p className="text-sm">
                                            {address.houseName}
                                        </p>
                                        <p className="text-sm">
                                            {address.residentialLine1}
                                        </p>
                                        {address.residentialLine2 && (
                                            <p className="text-sm">
                                                {address.residentialLine2}
                                            </p>
                                        )}
                                        <p className="text-sm">
                                            {address.city}{' '}
                                        </p>
                                        <p className="text-sm">
                                            {address.postCode}{' '}
                                        </p>
                                        <p className="text-sm">
                                            {address.countryCode}
                                        </p>
                                    </DataReview>
                                )
                        )}

                        {!showAddAddress && (
                            <Button
                                label={t('client.registration.addresses.add_address_button')}
                                type="button"
                                buttonType="secondary"
                                className="w-full my-2"
                                onClick={() => {
                                    arrayHelpers.push(emptyAddress);
                                    toggleAddAddress();
                                }}
                            />
                        )}
                    </div>
                )}
            />
        </div>
    );
};
