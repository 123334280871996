import { create } from 'zustand';
import { devtools } from 'zustand/middleware';
import { IntermediaryRequestBody, IntermediaryResponse } from '@interfaces/Api';
import {
    handleAddIntermediary,
    handleFetchIntermediaries,
} from './Intermediaries.services';

export interface IntermediariesStore {
    intermediaries: IntermediaryResponse[];
    isLoading: boolean;
    actions: {
        fetchIntermediaries: () => Promise<void>;
        addIntermediary: (
            intermediary: IntermediaryRequestBody
        ) => Promise<IntermediaryResponse | void>;
    };
}

export const useIntermediariesStore = create<IntermediariesStore>()(
    devtools(
        (set): IntermediariesStore => ({
            intermediaries: [],
            isLoading: false,
            actions: {
                fetchIntermediaries: async () => handleFetchIntermediaries(set),
                addIntermediary: async (
                    intermediary: IntermediaryRequestBody
                ) => handleAddIntermediary(set, intermediary),
            },
        })
    )
);
