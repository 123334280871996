import React from 'react';
import { useTranslation } from 'react-i18next';

import {
    ProductResponse,
    ShareClass,
    SubscriptionNotice,
    SubscriptionStatusEnum,
} from '@interfaces/Api';
import { SigningDocument } from '@interfaces/Api/SigningDocument';

import SubscriptionListItemElem from './SubscriptionListItemElem';

export interface SubscriptionListItem {
    notices?: SubscriptionNotice[];
    subscriptionId: string;
    subscriptionUrl: string;
    subscriptionStatus: SubscriptionStatusEnum;
    endInvestorName?: string;
    productName?: string;
    shareClassName?: string;
    shareClass?: ShareClass;
    subscriptionAmountFormatted: string;
    subscriptionAmount?: number;
    subscriptionCurrencyCode?: string;
    acceptedSubscriptionAmount?: number;
    status: SubscriptionStatusEnum;
    product: ProductResponse;
    signingDocuments?: SigningDocument[];
    firstName?: string;
    lastName?: string;
    companyName?: string;
    statusLogLatestDate?: Date;
}

interface SubscriptionListProps {
    subscriptionListItems: SubscriptionListItem[];
    onDeleteSubscription?: (subscriptionId: string) => {};
}

const SubscriptionList: React.FC<SubscriptionListProps> = (props) => {
    const { t } = useTranslation();
    const { subscriptionListItems } = props;
    const subscriptions =
        subscriptionListItems?.length > 0 ? subscriptionListItems : [];

    if (subscriptions.length === 0)
        return <p>{t('client.subscriptions.no_subscriptions')}</p>;

    return (
        <>
            {subscriptions.map((val) => (
                <SubscriptionListItemElem
                    key={val.subscriptionId}
                    subscriptionId={val.subscriptionId}
                    subscriptionUrl={val.subscriptionUrl}
                    subscriptionStatus={val.subscriptionStatus}
                    endInvestorName={val.endInvestorName}
                    shareClassName={val.shareClassName!}
                    subscriptionAmountFormatted={
                        val.subscriptionAmountFormatted
                    }
                    productName={val.productName!}
                    status={val.status}
                    onDeleteSubscription={props.onDeleteSubscription}
                    statusLogLatestDate={val?.statusLogLatestDate}
                />
            ))}
        </>
    );
};

export default SubscriptionList;
