import React, { useState, useRef } from 'react';
import classNames from 'classnames';

import { Icons } from '@components/Atoms';

export interface AccordionProps {
    title: string;
    badge?: React.ReactNode;
    children?: React.ReactNode;
}

const Accordion: React.FC<AccordionProps> = (props: AccordionProps) => {
    const { badge, children, title } = props;
    const [isOpen, setOpen] = useState(false);
    const [height, setHeight] = useState('0px');
    const [rotate, setRotate] = useState('transform duration-200 ease');

    const contentRef = useRef(null);

    const toggleAccordionHandler = () => {
        setOpen(!isOpen);
        // @ts-ignore
        setHeight(isOpen ? '0px' : `${contentRef.current.scrollHeight}px`);
        const rotateStyle = classNames('transform duration-200 ease', {
            'rotate-180': !isOpen,
        });
        setRotate(rotateStyle);
    };

    return (
        <div className="w-full flex flex-col">
            <h2 className="text-xs md:text-base lg:text-lg bg-white my-1">
                <button
                    className="focus:ring-4 focus:ring-gray-200 dark:focus:ring-gray-800 appearance-none cursor-pointer flex items-center justify-between p-5 w-full font-medium border border-gray-200 dark:border-gray-700 border-b text-gray-900 dark:text-white bg-white-50 dark:bg-gray-800 hover:bg-white-50 dark:hover:bg-gray-800 rounded-lg"
                    onClick={toggleAccordionHandler}
                >
                    <span className="inline-block text-footnote light">
                        {title}{' '}
                        <span className="bg-gold-500 rounded-xl inline-block leading-8 px-2 mx-2">
                            {badge}
                        </span>
                    </span>
                    <span className={`${rotate} inline-block`}>
                        <Icons name="ChevronDown" size="small" />
                    </span>
                </button>
            </h2>
            <div
                ref={contentRef}
                style={{ maxHeight: `${height}` }}
                className="overflow-auto transition-max-height duration-200 ease-in-out border-0 rounded-b-xl bg-gray-20 bg-gray-50 dark:bg-gray-900"
            >
                <div className="pb-10">
                    <div className="px-5">{children}</div>
                </div>
            </div>
        </div>
    );
};

export default Accordion;
