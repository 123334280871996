import { useEffect, useState } from 'react';

import { AlertBox } from '@components/Molecules';
import { Button, Icons } from '@components/Atoms';

import { useConfigurationState } from '@contexts/ConfigurationContext';
import { useDocusignState } from '@contexts/DocusignContext';

import { useMountEffect } from '@hooks/useMountEffect';

const DocusignConsent = () => {
    const [
        isGrantDocuSignConsentButtonDisabled,
        setIsGrantDocuSignConsentButtonDisabled,
    ] = useState(true);

    const { getDocusignInfo, hasConsented, docusignConsentLoading } =
        useDocusignState();
    const { configuration } = useConfigurationState();

    const callbackUrl = `${window.location.origin}/admin/docuSignCallback`;

    const onHandleGrantDocuSignConsent = async () => {
        if (configuration) {
            const encodedRedirectUri = encodeURIComponent(callbackUrl);

            const docuSignApplicationConsentUrl = `https://${configuration.docuSignAuthServiceDomain}/oauth/auth?response_type=code&scope=signature%20impersonation&client_id=${configuration.docuSignIntegrationKey}&redirect_uri=${encodedRedirectUri}`;

            window.location.href = docuSignApplicationConsentUrl;
        }
    };

    useMountEffect(() => {
        getDocusignInfo().then();
    });

    useEffect(() => {
        setIsGrantDocuSignConsentButtonDisabled(
            docusignConsentLoading || hasConsented
        );
    }, [hasConsented, docusignConsentLoading]);

    return (
        <div className="h-full">
            <div className="xs:container xs:mx-auto max-w-full">
                <div className="flex flex-wrap items-stretch">
                    {docusignConsentLoading ? (
                        <Icons name="Loading" />
                    ) : (
                        <>
                            <div className="space-x-7">
                                <Button
                                    onClick={onHandleGrantDocuSignConsent}
                                    label="Grant DocuSign Consent"
                                    disabled={
                                        isGrantDocuSignConsentButtonDisabled
                                    }
                                />
                            </div>
                            <div className="w-full">
                                <AlertBox
                                    alertType={hasConsented ? 'info' : 'error'}
                                    title=""
                                    message={
                                        hasConsented
                                            ? 'DocuSign consent granted'
                                            : 'Please grant DocuSign consent to enable DocuSign within the application'
                                    }
                                />
                            </div>
                        </>
                    )}
                </div>
            </div>
        </div>
    );
};

export default DocusignConsent;
