import {
    ProspectiveInvestorDefaultsGet,
    ProspectiveInvestorDefaultsPut,
} from '@api/ProspectiveInvestorDefaults';
import { BasicBreadcrumbs, Button, Icons } from '@components/Atoms';
import { mainLayoutWithBreadcrumbsConfig } from '@components/Layout';
import { FormSelect } from '@components/Molecules';
import { notifySuccess } from '@helpers/toastrHelper';
import {
    ProspectiveInvestorDefaultsRequest,
    ProspectiveInvestorDefaultsResponse,
} from '@interfaces/Api/ProspectiveInvestorDefaults';
import { useBookingCentresActions } from '@stores/BookingCentres';
import { useBookingCentres } from '@stores/BookingCentres/useBookingCentres';
import { useIntermediaries } from '@stores/Intermediaries/useIntermediaries';
import { AuthPermissionEnum, investorCountryRequirementTree } from 'common';
import { FieldArray, Form, Formik } from 'formik';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
import { useProfiles } from '@hooks/useProfiles';
import { useAuthState } from '@contexts/AuthContext';

const ProspectiveInvestorDefaultsPage = () => {
    const { hasPermissions } = useAuthState();
    const [prospectiveInvestorDefaults, setProspectiveInvestorDefaults] =
        useState<ProspectiveInvestorDefaultsResponse>();
    const { profiles } = useProfiles();
    const [profileOptions, setProfileOptions] = useState<
        {
            label: string;
            value: string;
        }[]
    >();

    const { fetchBookingCentres } = useBookingCentresActions();
    const { bookingCentres } = useBookingCentres();

    const { intermediaries } = useIntermediaries();
    const navigate = useNavigate();

    const validationSchema = Yup.object().shape({
        intermediaryId: Yup.string().required('Required'),
        profileId: Yup.string(),
    });

    const onCancel = () => {
        navigate('/admin/prospective-investors');
    };

    const onSubmit = (initialValues: ProspectiveInvestorDefaultsRequest) => {
        // filter out any empty booking centres
        initialValues.bookingCentres = initialValues.bookingCentres.filter(
            (a) => a.countryCode && a.bookingCentreId
        );

        ProspectiveInvestorDefaultsPut(initialValues).then((a) => {
            setProspectiveInvestorDefaults(a);
            notifySuccess('Prospective investor defaults saved');
        });
    };

    const reloadBookingCentres = async (intermediaryId: string) => {
        fetchBookingCentres(intermediaryId);
    };

    useEffect(() => {
        ProspectiveInvestorDefaultsGet().then((a) => {
            if (a) {
                // TODO: make sure that this is updated when the endpoint and models are updated
                setProspectiveInvestorDefaults(a);
                if (a.intermediaryId) {
                    reloadBookingCentres(a.intermediaryId);
                }
            }
        });
    }, []);

    useEffect(() => {
        const profilesOptions = profiles.map((profile) => ({
            label: profile.name,
            value: profile._id,
        }));

        setProfileOptions(profilesOptions);
    }, [profiles]);

    const initialValues = {
        intermediaryId: prospectiveInvestorDefaults?.intermediaryId || '',
        bookingCentreId: prospectiveInvestorDefaults?.bookingCentreId || '',
        bookingCentres: prospectiveInvestorDefaults?.bookingCentres || [
            {
                countryCode: '',
                bookingCentreId: '',
            },
        ],
        profileId: prospectiveInvestorDefaults?.profileId,
    } as ProspectiveInvestorDefaultsResponse;

    const countryCodeOptionsData = investorCountryRequirementTree.map((a) => ({
        label: a.name,
        value: a.countryCode,
    }));

    return (
        <div>
            <div className="flex flex-wrap justify-between">
                <h1 className="text-3xl text-logo-blue align-middle font-bold">
                    Prospective Investor Defaults
                </h1>
            </div>

            <div className="w-full lg:w-1/2">
                <p className="py-3">
                    Here you can set the default intermediary and the default
                    booking centre a prospective investor is allocated to when
                    they register on the platform. You can also set the default
                    booking centre for a prospective investor from a certain
                    country further below.
                </p>

                <Formik
                    initialValues={initialValues}
                    onSubmit={onSubmit}
                    validationSchema={validationSchema}
                    enableReinitialize={true}
                >
                    {({ values, setFieldValue }) => (
                        <Form>
                            <FormSelect
                                label="Intermediary"
                                name="intermediaryId"
                                optionsData={intermediaries.map(
                                    (intermediary) => ({
                                        label: intermediary.name,
                                        value: intermediary._id,
                                    })
                                )}
                                onChange={(a) => {
                                    setFieldValue(
                                        'intermediaryId',
                                        a.target.value
                                    );
                                    setFieldValue('bookingCentreId', undefined);
                                    setFieldValue('bookingCentres', undefined);

                                    if (a.target.value) {
                                        reloadBookingCentres(a.target.value);
                                    }
                                }}
                            />

                            {values.intermediaryId &&
                                bookingCentres.length > 0 && (
                                    <>
                                        <FormSelect
                                            label="Default Booking centre"
                                            name="bookingCentreId"
                                            optionsData={bookingCentres.map(
                                                (bookingCentre) => ({
                                                    label: bookingCentre.bookingCentreName,
                                                    value: bookingCentre._id,
                                                })
                                            )}
                                        />
                                        <div className="border-y py-4 my-4">
                                            <h3 className="text-xl pb-2">
                                                Country specific booking centres
                                            </h3>
                                            <p className="pb-2">
                                                Here you can set the default
                                                booking centre for a prospective
                                                investor from a certain country.
                                                If a prospective investor is
                                                from a country that is not
                                                listed here, they will be
                                                allocated to the default booking
                                                centre above.
                                            </p>

                                            <FieldArray
                                                name="bookingCentres"
                                                render={(arrayHelpers) => {
                                                    const getNonSelectedCountries =
                                                        (currentCountry) => {
                                                            const otherSelectedCountries =
                                                                values?.bookingCentres
                                                                    .map(
                                                                        (a) =>
                                                                            a.countryCode
                                                                    )
                                                                    .filter(
                                                                        (a) =>
                                                                            a !==
                                                                            currentCountry
                                                                    ) || [];

                                                            // remove otherSelectedCountries from the list of countries
                                                            return countryCodeOptionsData.filter(
                                                                (a) =>
                                                                    !otherSelectedCountries.includes(
                                                                        a.value
                                                                    )
                                                            );
                                                        };

                                                    return (
                                                        <div>
                                                            {values.bookingCentres &&
                                                                values
                                                                    .bookingCentres
                                                                    .length >
                                                                    0 &&
                                                                values.bookingCentres.map(
                                                                    (
                                                                        _,
                                                                        index
                                                                    ) => (
                                                                        <div
                                                                            key={
                                                                                index
                                                                            }
                                                                        >
                                                                            <div className="flex flex-row gap-3">
                                                                                <FormSelect
                                                                                    label="Country"
                                                                                    name={`bookingCentres.${index}.countryCode`}
                                                                                    optionsData={getNonSelectedCountries(
                                                                                        values
                                                                                            .bookingCentres[
                                                                                            index
                                                                                        ]
                                                                                            .countryCode
                                                                                    )}
                                                                                />

                                                                                <FormSelect
                                                                                    label="Booking centre"
                                                                                    name={`bookingCentres.${index}.bookingCentreId`}
                                                                                    optionsData={bookingCentres.map(
                                                                                        (
                                                                                            bookingCentre
                                                                                        ) => ({
                                                                                            label: bookingCentre.bookingCentreName,
                                                                                            value: bookingCentre._id,
                                                                                        })
                                                                                    )}
                                                                                />
                                                                                <Button
                                                                                    type="button"
                                                                                    onClick={() =>
                                                                                        arrayHelpers.remove(
                                                                                            index
                                                                                        )
                                                                                    }
                                                                                    hasIconOnly
                                                                                    startIcon={
                                                                                        <Icons name="TrashIcon" />
                                                                                    }
                                                                                ></Button>
                                                                            </div>
                                                                        </div>
                                                                    )
                                                                )}
                                                            <div className="text-right">
                                                                <Button
                                                                    type="button"
                                                                    onClick={() =>
                                                                        arrayHelpers.push(
                                                                            {
                                                                                countryCode:
                                                                                    '',
                                                                                bookingCentreId:
                                                                                    '',
                                                                            }
                                                                        )
                                                                    }
                                                                >
                                                                    {/* show this when user has removed all friends from the list */}
                                                                    New Row +
                                                                </Button>
                                                            </div>
                                                        </div>
                                                    );
                                                }}
                                            />
                                        </div>
                                    </>
                                )}

                            {hasPermissions([
                                AuthPermissionEnum.updateEndInvestorProfileLink,
                            ]) &&
                                profileOptions &&
                                profileOptions.length > 0 && (
                                    <div className="border-b py-4 my-4">
                                        <h3 className="text-xl pb-2">
                                            Default Profile
                                        </h3>
                                        <p className="py-3">
                                            Here you can set the default profile
                                            that a new prospective investor will
                                            be associated to
                                        </p>
                                        <FormSelect
                                            name="profileId"
                                            label="Profile assignment"
                                            optionsData={profileOptions}
                                        />
                                    </div>
                                )}

                            <div className="flex justify-between mt-4 mb-8">
                                <Button
                                    type="button"
                                    buttonType="secondary"
                                    label="Cancel"
                                    onClick={onCancel}
                                />
                                <Button
                                    type="submit"
                                    buttonType="primary"
                                    label="Save"
                                />
                            </div>
                        </Form>
                    )}
                </Formik>
            </div>
        </div>
    );
};

export const ProspectiveInvestorDefaultsPageBreadcrumbs: React.FC = () => {
    const items = [
        {
            displayText: 'Prospective Investors',
            navigationUrl: '/admin/prospective-investors',
        },
        {
            displayText: 'Defaults',
            navigationUrl: '/admin/prospective-investors-defaults',
        },
    ];

    return <BasicBreadcrumbs items={items} />;
};

export default mainLayoutWithBreadcrumbsConfig(
    ProspectiveInvestorDefaultsPage,
    undefined,
    <ProspectiveInvestorDefaultsPageBreadcrumbs />
);
