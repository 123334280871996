import { BaseUrl } from '@helpers/Environment';
import { EndInvestorPortfolioItem } from '@interfaces/Api/Dashboard';

import { Http } from '../libs/http';

const API_DASHBOARD_URL = `${BaseUrl}/dashboard`;

export const PortfolioGet = async (endInvestorProfileId: string) =>
    Http.get<EndInvestorPortfolioItem[]>(
        `${API_DASHBOARD_URL}/portfolio`,
        undefined,
        { endInvestorProfileId }
    );

export const PortfolioGetBySubscriptionId = async (
    endInvestorProfileId: string,
    subscriptionId: string
): Promise<EndInvestorPortfolioItem> =>
    Http.get(`${API_DASHBOARD_URL}/portfolio/${subscriptionId}`, undefined, {
        endInvestorProfileId,
    });
