import { useState, useMemo } from 'react';
import {
    SortConfigProps,
    handleSorting,
    handleSystemUserFiltering,
} from './helper';

interface UseSortableDataProps {
    tableData: any[];
    searchText?: string;
    itemsPerPage: number | string;
}

export const useSortableData = (props: UseSortableDataProps) => {
    const [sortConfig, setSortConfig] = useState<SortConfigProps>(() => {
        const sessionConfig: any = window.sessionStorage.getItem('configSort');
        return JSON.parse(sessionConfig) || { key: '', direction: '' };
    });

    const [currentPageNumber, setCurrentPageNumber] = useState<number>(1);

    const filteredItems = props.searchText
        ? props.tableData?.filter((val) =>
              handleSystemUserFiltering(val, props.searchText)
          )
        : props.tableData;

    const sortedItems = useMemo(() => {
        let sortableItems = [...props.tableData];

        if (sortConfig !== null) {
            sortableItems = sortableItems.sort((a, b) =>
                handleSorting(a, b, sortConfig)
            );
        }

        if (props.searchText) {
            sortableItems = sortableItems?.filter((val) =>
                handleSystemUserFiltering(val, props.searchText)
            );
        }

        if (typeof props.itemsPerPage === 'number') {
            const indexOfLastPost = currentPageNumber * props.itemsPerPage;
            const indexOfFirstPost = indexOfLastPost - props.itemsPerPage;
            sortableItems = sortableItems.slice(
                indexOfFirstPost,
                indexOfLastPost
            );
        }

        return sortableItems;
    }, [
        props.tableData,
        sortConfig,
        props.searchText,
        currentPageNumber,
        props.itemsPerPage,
    ]);

    const requestSort = (key: string) => {
        let newDirection: SortConfigProps['direction'] = 'ascending';

        if (sortConfig && sortConfig.key === key) {
            if (sortConfig.direction === 'ascending') {
                newDirection = 'descending';
            }
        }
        const configSort = { key, direction: newDirection };
        setSortConfig(configSort);

        window.sessionStorage.setItem('configSort', JSON.stringify(configSort));
    };

    const paginate = (pageNumber: number) => setCurrentPageNumber(pageNumber);

    const paginateFront = () => setCurrentPageNumber(currentPageNumber + 1);
    const paginateBack = () => setCurrentPageNumber(currentPageNumber - 1);

    return {
        tableData: sortedItems,
        tableDataLength: filteredItems?.length,
        requestSort,
        sortConfig,
        setCurrentPageNumber,
        currentPageNumber,
        paginate,
        paginateBack,
        paginateFront,
    };
};
