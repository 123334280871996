import { useState, useEffect, ChangeEvent } from 'react';
import { Form, Formik, Field, FieldProps } from 'formik';
import { useTranslation } from 'react-i18next';

import { ProductsGetAll } from '@api/Products';
import { ProposalCreatePost } from '@api/Proposals';

import { ShareClass } from '@interfaces/Api/ProductResponse';

import { getFormattedCurrency } from '@helpers/isoCurrencies';
import { notifySuccess } from '@helpers/toastrHelper';

import { Button, Checkbox } from '@components/Atoms';
import { Modal, FormSelect, FormInputBox } from '@components/Molecules';
import { FileUploadAsync } from '@components/Organisms';

import { getValidation, initialValues, FormData } from './Proposal.util';

interface Product {
    id: string;
    name: string;
    shareClasses: ShareClass[];
}

interface ProposalFormProps {
    endInvestorProfileId: string;
    onHandleRefresh?(): void;
    setSelectedProduct?(product: Product): void;
    selectedProduct?: Product;
    selectedShareClass?: ShareClass;
    clearFormFields?(): void;
    setSelectedShareClass?(shareClass: ShareClass): void;
    handleToggleVisibility?(): void;
}

export const ProposalForm = ({
    endInvestorProfileId,
    onHandleRefresh,
    setSelectedProduct,
    selectedProduct,
    selectedShareClass,
    clearFormFields,
    setSelectedShareClass,
    handleToggleVisibility,
}: ProposalFormProps) => {
    const { t } = useTranslation();
    const [products, setProducts] = useState<Product[]>([]);

    const validation = getValidation(
        selectedShareClass?.minimumInvestment || 0
    );

    useEffect(() => {
        const getData = async () => {
            const data = await ProductsGetAll(endInvestorProfileId);

            const reducedProducts = data.map(({ _id, name, shareClasses }) => ({
                id: _id,
                name,
                shareClasses: shareClasses,
            }));

            setProducts(reducedProducts as Product[]);
        };

        getData();
    }, [endInvestorProfileId]);

    const handleOnSubmit = async (data: FormData) => {
        const { amount, upFrontFeePercentage, agreeTermAndCondition } = data;
        let dataToSubmit;

        dataToSubmit = {
            endInvestorProfileId,
            productId: selectedProduct?.id,
            amount,
            upFrontFeePercentage,
            agreeTermAndCondition,
            shareClass: selectedShareClass,
        };

        if (data.relianceDocument) {
            dataToSubmit = {
                ...dataToSubmit,
                relianceDocument: data.relianceDocument,
            };
        }

        await ProposalCreatePost(dataToSubmit);
        handleToggleVisibility();
        notifySuccess('A proposal has been successfully created.');

        if (onHandleRefresh) {
            await onHandleRefresh();
        }
    };

    const handleSelectProduct = ({
        target: { value },
    }: ChangeEvent<HTMLInputElement>) => {
        const product = products.find(({ name }) => name === value);

        clearFormFields();
        setSelectedProduct(product);
    };

    const handleShareClassSelection = ({
        target: { value },
    }: ChangeEvent<HTMLInputElement>) => {
        const selectedShareClass = selectedProduct?.shareClasses.find(
            ({ name }: ShareClass) => name === value
        );

        setSelectedShareClass(selectedShareClass);
    };

    const productsOptions = products.map(({ name }) => ({
        label: name,
        value: name,
    }));

    const shareClassesOptions = selectedProduct?.shareClasses?.map(
        ({ name }) => ({
            label: name,
            value: name,
        })
    );

    return (
        <Formik
            validationSchema={validation}
            initialValues={initialValues}
            onSubmit={handleOnSubmit}
        >
            {({ isValid, dirty, values, setFieldValue }) => (
                <Form className="flex flex-col">
                    <div className="flex mb-4">
                        <div className="flex flex-1">
                            <FormSelect
                                label={t('client.proposal.product')}
                                name="field_products"
                                dataTestId="field_products"
                                onChange={(e: any) => {
                                    handleSelectProduct(e);
                                    setFieldValue(
                                        'field_products',
                                        e.target.value
                                    );
                                }}
                                optionsData={productsOptions}
                                value={selectedProduct?.name}
                            />
                        </div>

                        <div className="flex flex-1 ml-4">
                            <FormSelect
                                label={t('client.proposal.product_share_class')}
                                name="field_product_share_classes"
                                dataTestId="field_product_share_classes"
                                onChange={(e: any) => {
                                    handleShareClassSelection(e);
                                    setFieldValue(
                                        'field_product_share_classes',
                                        e.target.value
                                    );
                                }}
                                optionsData={shareClassesOptions}
                            />
                        </div>
                    </div>

                    <div className="flex mb-2">
                        <div className="flex flex-1">
                            <FormInputBox
                                label={t('client.proposal.amount')}
                                name="amount"
                                type="number"
                                dataTestId="amount"
                                hint={
                                    values['field_product_share_classes'] &&
                                    `Min amount: ${getFormattedCurrency(
                                        selectedShareClass?.minimumInvestment,
                                        'GBP'
                                    )}`
                                }
                            />
                        </div>

                        <div className="flex flex-1 ml-4">
                            <FormInputBox
                                label={t('client.proposal.upfront_fee')}
                                name="upFrontFeePercentage"
                                type="number"
                                dataTestId="upFrontFeePercentage"
                            />
                        </div>
                    </div>

                    <div className="mb-4 pt-4">
                        <Field name="agreeTermAndCondition">
                            {({ field: { name, onChange } }: FieldProps) => (
                                <Checkbox onChange={onChange} name={name} />
                            )}
                        </Field>
                        <label
                            className="mb-1 text-sm"
                            htmlFor="agreeTermAndCondition"
                        >
                            {t('client.proposal.confirmation_statement')}
                        </label>
                    </div>

                    <div className="flex flex-col mb-4">
                        <label
                            className="pt-2 mb-1  text-sm"
                            htmlFor="field_fee"
                        >
                            {t('client.proposal.optional_supporting_documents')}
                        </label>
                        <Field name="relianceDocument">
                            {({ field: { name } }: FieldProps) => (
                                <FileUploadAsync name={name} />
                            )}
                        </Field>
                    </div>

                    <div className="flex justify-end">
                        <Button
                            buttonType="secondary"
                            className="mr-2"
                            onClick={handleToggleVisibility}
                        >
                            {t('ui.controls.close')}
                        </Button>
                        <Button disabled={!(isValid && dirty)} type="submit">
                            {t('ui.controls.create')}
                        </Button>
                    </div>
                </Form>
            )}
        </Formik>
    );
};
