import { useTranslation } from 'react-i18next';
import { Button, Icons } from '@components/Atoms';

import { IntermediarySummary } from './IntermediarySummary';
import { AdvisorSummary } from './AdvisorSummary';
import { BookingCentresSummary } from './BookingCentresSummary';
import { NomineeSummary } from './NomineeSummary';
import { JurisdictionSummary } from './JurisdictionSummary';
import { KycSummary } from './KycSummary';
import { PersonalDetailsSummary } from './PersonalDetailsSummary';
import { NationalitiesSummary } from './NationalitiesSummary';
import { ContactDetailsSummary } from './ContactDetailsSummary';
import { AddressSummary } from './AddressSummary';
import { BankAccountsSummary } from './BankAccountsSummary';
import { BusinessDetailsSummary } from './BusinessDetailsSummary';
import { useRegisterInvestorState } from '../RegisterInvestor.context';
import { StepProps } from './Interfaces';
import { EndInvestorTypeSummary } from './EndInvestorTypeSummary';
import { ServiceLevelTypeSummary } from './ServiceLevelTypeSummary';
import { ClientTypeSummary } from './ClientTypeSummary';
import { toCapitalizedWords } from '@helpers/ToCapitalizedWords';
import { EndInvestorProfileRequestBody } from '@interfaces/Api';
import { LoginAccountSummary } from './LoginAccountSummary';

interface SummaryProps {
    currentStepName: string;
    steps: StepProps[];
    setCurrentStep: (stepName: string) => void;
}

// enum StepsTitle {
//     subscriptionProcessType = t('client.registration.summary.subscription_process'),
// }

const SubscriptionProcessSummary = () => {
    const { t } = useTranslation();
    const { data } = useRegisterInvestorState();
    return (
        <p>
            <span className="font-bold mr-2">
                {t('client.registration.summary.subscription_process')}:
            </span>
            {toCapitalizedWords(data['subscriptionProcessType'])}
        </p>
    );
};

export const Summary = ({
    currentStepName,
    setCurrentStep,
    steps,
}: SummaryProps): JSX.Element => {
    const { t } = useTranslation();
    const renderStepSummary = (stepName: string) => {
        switch (stepName) {
            case 'subscriptionProcessType':
                return <SubscriptionProcessSummary />;
            case 'clientType':
                return <ClientTypeSummary />;
            case 'serviceLevelType':
                return <ServiceLevelTypeSummary />;
            case 'endInvestorType':
                return <EndInvestorTypeSummary />;
            case 'intermediaryId':
                return <IntermediarySummary />;
            case 'adviserSystemUserId':
                return <AdvisorSummary />;
            case 'bookingCentreIds':
                return <BookingCentresSummary />;
            case 'nomineeEndInvestorProfileId':
                return <NomineeSummary />;
            case 'onboardingJurisdictionCountryCode':
                return <JurisdictionSummary />;
            case 'isKycCompliant':
                return <KycSummary />;
            case 'individualDetails':
                return <PersonalDetailsSummary />;
            case 'nationalities':
                return <NationalitiesSummary />;
            case 'emailAddress':
                return <ContactDetailsSummary />;
            case 'addresses':
                return <AddressSummary />;
            case 'bankAccounts':
                return <BankAccountsSummary />;
            case 'systemUsers':
                return <LoginAccountSummary />;
            case 'businessEntityDetails':
                return <BusinessDetailsSummary />;
        }
    };

    return (
        <div>
            <p className="mb-4 text-2xl">{t('client.registration.summary')}</p>
            {steps.map((step: StepProps) => (
                <div
                    key={step.name}
                    className="flex justify-between border-b py-2 last:border-b-0"
                >
                    {renderStepSummary(step.name)}

                    {currentStepName !== step.name && (
                        <div className="border-l pl-2 flex items-center">
                            <Button
                                hasIconOnly
                                startIcon={
                                    <Icons name="PencilIcon" size="small" />
                                }
                                onClick={() => {
                                    setCurrentStep(step.name);
                                }}
                            />
                        </div>
                    )}
                </div>
            ))}
        </div>
    );
};
