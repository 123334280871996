import { useAuthState } from '@contexts/AuthContext';
import { useConfigurationState } from '@contexts/ConfigurationContext';
import { useEndInvestorProfile } from '@hooks/useEndInvestorProfile';
import { EndInvestorProfileResponse } from '@interfaces/Api';
import { AuthPermissionEnum, AuthRoleEnum } from 'common';

export const useCanAddSubscription = () => {
    const { preferences } = useConfigurationState();
    const { currentUser, hasPermissions } = useAuthState();
    const { endInvestorProfile } = useEndInvestorProfile();

    const canUserAddSubscription = () => {
        if (!hasPermissions(AuthPermissionEnum.createSubscription)) {
            return false;
        }

        if (
            preferences?.feature.accessControl
                ?.relationshipManagersCannotAddNewSubscriptions &&
            currentUser?.user.role === AuthRoleEnum.relationshipManager
        ) {
            return false;
        }

        if (
            preferences?.feature.subscriptionProcess
                .advisoryInvestorCannotAddNewSubscription &&
            currentUser?.user.role === AuthRoleEnum.endInvestor &&
            endInvestorProfile?.serviceLevelType ===
                EndInvestorProfileResponse.ServiceLevelTypeEnum.Advisory
        ) {
            return false;
        }

        return true;
    };

    return {
        canAddSubscription: canUserAddSubscription(),
    };
};
