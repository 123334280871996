import {
    ErrorMessage,
    Field,
    FieldInputProps,
    FieldMetaProps,
    FormikBag,
} from 'formik';
import { CheckboxListOption } from '../CheckboxList';
import classNames from 'classnames';
import { Checkbox, InputBox } from '@components/Atoms';

export interface CheckboxWithInputListOption {
    value: boolean;
    label: string;
    checkboxLabel?: string;
    key: string;
    extraInput?: {
        inputName: string;
        inputLabel: string;
        inputDefaultValue?: string;
        inputValue: string;
    };
}

interface CheckboxListWithInputProps {
    name: string;
    checkboxLabel?: string;
    disabled?: boolean;
    defaultChecked?: boolean;
    options: CheckboxWithInputListOption[];
    onSelectedOptionsChange: (
        selectedOptions: CheckboxWithInputListOption[]
    ) => void;
}

const CheckboxWithInputList: React.FC<CheckboxListWithInputProps> = (props) => {
    const { options, checkboxLabel = 'Agreed', defaultChecked } = props;
    const handleCheckboxChange = (option: CheckboxListOption) => {
        const optionIndex = options.findIndex((val) => val.key === option.key);
        options[optionIndex] = { ...option, value: !option.value };
        props.onSelectedOptionsChange(options);
    };

    const handleInputChange = (
        option: CheckboxWithInputListOption,
        value: string
    ) => {
        if (option.extraInput) {
            const optionIndex = options.findIndex(
                (val) => val.key === option.key
            );
            options[optionIndex] = {
                ...option,
                extraInput: {
                    ...option.extraInput,
                    inputValue: value,
                },
            };
            props.onSelectedOptionsChange(options);
        }
    };

    return (
        <div>
            {options && Array.isArray(options) && options.length > 0 && (
                <div>
                    {options?.map((option) => {
                        return (
                            <div
                                key={option.key}
                                className={classNames([
                                    'relative w-full rounded-lg cursor-pointer',
                                    option.value
                                        ? 'bg-purple-50 '
                                        : 'hover:bg-gray-100 ',
                                    'border p-5 mb-5',
                                ])}
                                onClick={() => {
                                    // if (!disabled) {
                                    handleCheckboxChange(option);
                                    // }
                                }}
                            >
                                <div
                                    className={classNames([
                                        'w-full',
                                        option.label && 'mt-3',
                                    ])}
                                    onClick={(event) => {
                                        event.preventDefault();
                                    }}
                                >
                                    <Checkbox
                                        // below class is correct. This fixes a bug where when the checkbox is clicked,
                                        // the checkbox doesn't change state. (although the state is changed in the parent component)
                                        className="pointer-events-none"
                                        name={option.key}
                                        label={option.label || checkboxLabel}
                                        checked={option.value}
                                    />
                                </div>

                                {option.extraInput && (
                                    <div
                                        onClick={(e) => e.stopPropagation()}
                                        className="relative mt-4"
                                    >
                                        {option.extraInput.inputLabel && (
                                            <label
                                                htmlFor={
                                                    option.extraInput.inputName
                                                }
                                                className="absolute top-2 left-1 text-xs flex items-center px-2 pointer-events-none h-auto"
                                            >
                                                {option.extraInput.inputLabel}
                                            </label>
                                        )}
                                        <InputBox
                                            name={option.extraInput.inputName}
                                            label={option.extraInput.inputLabel}
                                            defaultValue={
                                                option.extraInput.inputValue
                                            }
                                            onChange={(e) => {
                                                //
                                                const val = e.target
                                                    .value as string;
                                                console.log(
                                                    'updating input val'
                                                );
                                                handleInputChange(option, val);
                                            }}
                                        />
                                    </div>
                                )}
                            </div>
                        );
                    })}
                    {/* inputLabel here */}
                </div>
            )}
        </div>
    );
};

interface FormCheckboxWithInputList {
    name: string;
    checkboxLabel?: string;
    disabled?: boolean;
}

const FormCheckboxWithInputList: React.FC<FormCheckboxWithInputList> = (
    props
) => (
    <div>
        <Field name={props.name}>
            {({
                field,
                form,
            }: {
                field: FieldInputProps<CheckboxListOption[]>;
                meta: FieldMetaProps<string>;
                form: FormikBag<CheckboxWithInputListOption, any>;
            }) => {
                const handleCheckboxChange = (
                    options: CheckboxListOption[]
                ) => {
                    form.setFieldValue(props.name, options);

                    console.log('setting field value', options);
                };

                return (
                    <CheckboxWithInputList
                        name={props.name}
                        disabled={props.disabled}
                        checkboxLabel={props.checkboxLabel}
                        options={field.value}
                        onSelectedOptionsChange={handleCheckboxChange}
                    />
                );
            }}
        </Field>
        <div className="flex content-start text-red-500 text-sm">
            <ErrorMessage
                name={props.name}
                render={(msg) => <div>{msg}</div>}
            />
        </div>
    </div>
);

export default FormCheckboxWithInputList;
