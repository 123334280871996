import { useDocumentTemplatesLibraryState } from '@contexts/DocumentTemplateLibrary';
import { useMountEffect } from '@hooks/useMountEffect';

const DocumentLibraryCard = () => {
    const { documentTemplateList, getDocumentTemplates } =
        useDocumentTemplatesLibraryState();
    useMountEffect(() => {
        getDocumentTemplates();
    });

    return (
        <div className="xs:container xs:mx-auto max-w-full">
            <div className="flex flex-wrap items-stretch">
                {documentTemplateList.length > 0 ? (
                    <p>
                        {documentTemplateList.length} Document(s) in the library
                    </p>
                ) : (
                    <p>There are no documents in the library.</p>
                )}
            </div>
        </div>
    );
};

export default DocumentLibraryCard;
