interface Callback {
    (data: any): void;
}

const eventMap: Record<string, Callback[]> = {};

export class NotificationService {
    static sub(eventName: string, callback: Callback, replace = false): void {
        if (!eventMap[eventName]) {
            eventMap[eventName] = [];
        }

        if (replace) {
            eventMap[eventName] = [callback];
        } else {
            eventMap[eventName].push(callback);
        }
    }

    static pub(eventName: string, data?: any): void {
        if (eventMap[eventName]) {
            eventMap[eventName].forEach((cb: Callback) => cb(data));
        }
    }

    static remove(
        eventName: string,
        callback: Callback
    ): Callback[] | undefined {
        if (eventMap[eventName]?.length) {
            eventMap[eventName] = eventMap[eventName].filter(
                (cb) => cb !== callback
            );

            if (!eventMap[eventName]?.length) {
                delete eventMap[eventName];
            }
        }

        return eventMap[eventName];
    }

    static destroy(eventNames: string[]) {
        eventNames.forEach((eventName) => {
            delete eventMap[eventName];
        });
    }
}
