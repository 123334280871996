import {
    ErrorMessage,
    Field,
    FieldHookConfig,
    FieldInputProps,
    FieldMetaProps,
    FormikBag,
    FormikValues,
    useFormikContext,
} from 'formik';
import { useEffect, useState } from 'react';
import { documentsPost, UploadDocumentType } from '@api/Documents';
import { humanFileSize } from '@helpers/File.helper';
import EditLabelModal from './EditLabelModal';
import FileUpload from './FileUpload';
import { notifyError } from '@helpers/toastrHelper';
import { useStorageTokenState } from '@contexts/StorageTokenContext';
import { getFormValuePath } from '@helpers/formik.helper';

export interface UploadDocument {
    fileName: string;
    fileSize?: string;
    url: string;
    label?: string;
}

interface FileUploadAsyncProps {
    name: string;
    fileLabel?: UploadDocumentType;
    /**
     * Set to true to allow a file to use a label field
     */
    hasLabel?: boolean;
    imageOnly?: boolean;
    supportedFileTypes?: string[];
    extraInfo?: string;
    maxFiles?: number;
    hideFileList?: boolean;
}

/**
 * This component provides an easy plug and play solution for document uploads using a
 * Formik Form. It automatically uploads documents to the /documents endpoint
 */
const FileUploadAsync: React.FC<
    FileUploadAsyncProps &
        FieldHookConfig<
            { fileName: string; fileSize: string; label?: string }[]
        >
> = (
    props: FileUploadAsyncProps &
        FieldHookConfig<{ fileName: string; fileSize: string }[]>
) => {
    const { setFieldValue, values } = useFormikContext();

    const [uploadingFiles, setUploadingFiles] = useState<
        { url?: string; fileName: string; fileSize: string }[]
    >([]);

    // reset uploaded files on first load
    useEffect(() => {
        const documents = getFormValuePath(values, props.name);
        if (!documents) {
            setFieldValue(props.name, []);
        }
    }, []);

    const { arrayWithToken } = useStorageTokenState();

    const [showEditFileLabelModal, setShowEditFileLabelModal] =
        useState<boolean>(false);
    const [editModalFileDocument, setEditModalFileDocument] =
        useState<UploadDocument>();

    return (
        <div>
            <Field id={props.name} name={props.name}>
                {({
                    field,
                    form,
                    meta,
                }: {
                    field: FieldInputProps<UploadDocument[]>;
                    form: FormikBag<FileUploadAsyncProps, any>; // should not be any
                    meta: FieldMetaProps<UploadDocument[]>;
                }) => {
                    const handleFileUpload = async (files: File[]) => {
                        const documents = field.value || [];
                    
                        for (let i = 0; i < files.length; i++) {
                            // Add file to uploadingFiles array
                            const file = files[i];

                            // need to check if file is already in the list
                            const fileExist = documents.some(document => document.fileName === file.name);

                            if (fileExist) {
                                notifyError(`The document: '${file.name}' already exists. If it has changed, please delete the existing document and add again.`);
                                return ;
                            }

                            const fileSize = humanFileSize(file.size, 2);
                            setUploadingFiles((x) => [
                                ...x,
                                { fileName: file.name, fileSize },
                            ]);
                            try {
                                console.log(
                                    '[FileUploadAsync] upload file',
                                    file
                                );
                                const uploadDocumentResult =
                                    await documentsPost(props.fileLabel!, file);
                                if (uploadDocumentResult?.url) {
                                    // check if should add label to document here
                                    if (props.hasLabel) {
                                        editFileLabel(uploadDocumentResult);
                                    }

                                    documents.push({
                                        ...uploadDocumentResult,
                                        fileSize,
                                    });
                                }
                            } catch (err: any) {
                                console.log('err', err.result.message);
                                notifyError(
                                    [
                                        `Error uploading file.`,
                                        err.result.message,
                                    ]
                                        .filter((a) => a)
                                        .join(' ')
                                );
                            }

                            // Remove files from uploadingFiles array
                            setUploadingFiles((x) =>
                                x.filter((f) => f.fileName !== file.name)
                            );
                        }

                        form.setFieldValue(field.name, documents);
                    };

                    const editFileLabel = (document: UploadDocument) => {
                        setEditModalFileDocument(document);
                        setShowEditFileLabelModal(true);
                    };

                    const handleDocumentLabelSave = (
                        document: UploadDocument
                    ) => {
                        const documents = field.value || [];

                        // find document in current form element
                        const documentIndex = documents.findIndex(
                            (val) => val.url === document.url
                        );
                        if (documentIndex !== -1) {
                            documents[documentIndex] = document;
                        }

                        form.setFieldValue(field.name, documents);

                        setShowEditFileLabelModal(false);
                        setEditModalFileDocument(undefined);
                    };

                    const handleFileRemove = (fileUrl: string) => {
                        const documents = field.value || [];
                        const newFileUrl =
                            fileUrl.indexOf('?sv=') !== -1
                                ? fileUrl.substring(0, fileUrl.indexOf('?sv='))
                                : fileUrl;
                        const fileRemoveIndex = documents.findIndex(
                            (val) => val.url == newFileUrl
                        );
                        if (fileRemoveIndex !== -1) {
                            documents?.splice(fileRemoveIndex, 1);
                        }
                        form.setFieldValue(field.name, documents);
                    };

                    return (
                        <>
                            <FileUpload
                                name={props.name}
                                onHandleUpload={handleFileUpload}
                                onHandleRemove={handleFileRemove}
                                uploadedFiles={arrayWithToken(
                                    props.hideFileList ? [] : meta.value || []
                                )}
                                uploadingFiles={uploadingFiles}
                                canEditLabel={props.hasLabel}
                                onEditFileLabel={editFileLabel}
                                imageOnly={props.imageOnly}
                                supportedFileTypes={props.supportedFileTypes}
                                maxFiles={props.maxFiles}
                            />
                            <EditLabelModal
                                showModal={showEditFileLabelModal}
                                document={editModalFileDocument}
                                onClose={() => {
                                    setShowEditFileLabelModal(false);
                                    setEditModalFileDocument(undefined);
                                }}
                                onSave={(document) => {
                                    handleDocumentLabelSave(document);
                                }}
                            />
                            {props.extraInfo && (
                                <p className="text-sm -mt-2">
                                    {props.extraInfo}
                                </p>
                            )}
                        </>
                    );
                }}
            </Field>
            <div className="flex content-start text-red-500 text-sm">
                <ErrorMessage
                    name={props.name}
                    render={(msg: any) => <div>{msg}</div>}
                />
            </div>
        </div>
    );
};

export default FileUploadAsync;
