import { FC, ReactNode } from 'react';
import { BreadcrumbsItem } from '@components/Atoms';
import MainLayout from '../MainLayout/MainLayout';

export const mainLayoutWithBreadcrumbsConfig = (
    Component: FC,
    breadcrumbsItems?: BreadcrumbsItem[],
    breadcrumbs?: ReactNode
) => {
    const Wrapper = () => (
        <MainLayout
            breadcrumbsItems={breadcrumbsItems}
            breadcrumbs={breadcrumbs}
        >
            <Component />
        </MainLayout>
    );

    Wrapper.displayName = `MainLayoutWithBreadcrumbsConfig(${
        Component.displayName || Component.name || 'Component'
    })`;
    return Wrapper;
};
