import { useTranslation } from 'react-i18next';
import { Button, Icons } from '@components/Atoms';
import { PopUp } from '@components/Molecules';

export interface ActionsMenuProps {
    actionItems: {
        label: string;
        onClick?: () => void;
        link?: string;
        show?: boolean;
    }[];
}

const ActionsMenu: React.FC<ActionsMenuProps> = ({ actionItems }) => {
    const { t } = useTranslation();
    
    const handleActionClick = (
        actionItem: ActionsMenuProps['actionItems'][number]
    ) => {
        actionItem.onClick && actionItem.onClick();
    };

    // early exit if no action items
    if (!actionItems || actionItems.length === 0) return null;

    return (
        <PopUp
            on="hover"
            trigger={
                <div>
                    <Button
                        label={t('ui.controls.actions')}
                        size="medium"
                        endIcon={<Icons name="ChevronDown" size="small" />}
                    />
                </div>
            }
        >
            {actionItems && actionItems.length > 0 && (
                <ul className="pt-2 pb-2">
                    {actionItems
                        .filter((actionItem) => actionItem.show !== false)
                        .map((actionItem, i) => (
                            <li
                                key={actionItem.label}
                                className="pt-2 pb-2 pl-4 pr-3 border-gray-200 cursor-pointer hover:bg-gray-200 text-sm min-w-max"
                                onClick={() => handleActionClick(actionItem)}
                            >
                                {actionItem.label}
                            </li>
                        ))}
                </ul>
            )}
        </PopUp>
    );
};

export default ActionsMenu;
