import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import {
    SubscriptionTemplateResponse,
    EndInvestorProfileResponse,
} from '@interfaces/Api';

import { getFirstStepSubscriptionTemplate } from '@services/SubscriptionsHelper';

import SubscriptionListTemplate, {
    SubscriptionTemplateListItem,
} from '@containers/Subscriptions/SubscriptionListTemplate';

import { useSubscriptionsAndTemplates } from '@hooks/useSubscriptionsAndTemplates';

export interface ReviewClientSubscriptionTemplateListProps {
    endInvestorProfile: EndInvestorProfileResponse;
}

const ReviewClientSubscriptionTemplateList: React.FC<
    ReviewClientSubscriptionTemplateListProps
> = (props) => {
    const { t } = useTranslation();
    const { endInvestorProfile } = props;

    const { list: templatesData } =
        useSubscriptionsAndTemplates<SubscriptionTemplateResponse>(
            endInvestorProfile?._id,
            'subscriptions-templates'
        );

    const subscriptionListTemplateItems = useCallback(() => {
        return templatesData
            .sort((a, _) => (a.isMaster ? -1 : 1))
            .map(
                (subscriptionTemplate) =>
                    ({
                        subscriptionTemplateId: subscriptionTemplate._id,
                        subscriptionTemplateUrl: `../subscription-templates/${
                            subscriptionTemplate._id
                        }/${getFirstStepSubscriptionTemplate(
                            t,
                            endInvestorProfile!
                        )}`,
                        subscriptionTemplateName:
                            subscriptionTemplate.templateName,
                        isMaster: !!subscriptionTemplate.isMaster,
                    } as SubscriptionTemplateListItem)
            );
    }, [endInvestorProfile, templatesData]);

    return (
        <div className="leading-5 mb-6">
            <SubscriptionListTemplate
                subscriptionTemplateListItems={subscriptionListTemplateItems()}
                endInvestorProfile={endInvestorProfile}
            />
        </div>
    );
};

export default ReviewClientSubscriptionTemplateList;
