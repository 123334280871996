import { Button, Icons } from '@components/Atoms';
import classNames from 'classnames';
import React, { useEffect, useState } from 'react';
import { toCapitalizedWords } from '../../../Helpers/ToCapitalizedWords';

type DataType = { [key: string]: string | number | React.ReactNode };

export interface DataReviewItemMultiColsInterface {
    // Can take in either a single object or an array of objects
    data: DataType | DataType[];
    excludedKeys?: string[];
    excludedColumnNames?: string[];
    onDelete?: () => void;
    hasBorder?: boolean;
    onEdit?: () => void;
    rowAlignment?: 'top' | 'middle' | 'bottom';
}

const ReviewBox = (props: DataReviewItemMultiColsInterface) => {
    const { data, onDelete, onEdit } = props;
    const [headers, setHeaders] = useState<string[]>([]);
    const [rows, setRows] = React.useState<DataType[]>([]);
    const rowClass = classNames({
        'align-top': props.rowAlignment === 'top',
        'align-middle': props.rowAlignment === 'middle',
        'align-bottom': props.rowAlignment === 'bottom',
    });

    useEffect(() => {
        const tempHeaders: string[] = [];
        const tempRows: DataType[] = [];
        const hiddenKeys = ['_id', ...(props.excludedKeys || [])];
        if (data instanceof Array) {
            data.forEach((row) => {
                Object.keys(row).forEach((key) => {
                    if (!tempHeaders.includes(key) && !hiddenKeys.includes(key))
                        tempHeaders.push(key);
                });
            });

            data.forEach((row) => {
                const trow: DataType = {};
                tempHeaders.forEach((theadName) => {
                    trow[theadName] = row[theadName];
                });
                tempRows.push(trow);
            });
        } else if (!(data instanceof Array)) {
            Object.keys(data).forEach((key) => {
                if (!tempHeaders.includes(key) && !hiddenKeys.includes(key))
                    tempHeaders.push(key);
            });

            const trow: DataType = {};

            tempHeaders.forEach((theadName) => {
                trow[theadName] = data[theadName];
            });
            tempRows.push(trow);
        }
        setHeaders(tempHeaders);
        setRows(tempRows);
    }, [data, props.excludedKeys]);

    const showColumnHeading = (heading: string) => {
        if (props.excludedColumnNames) {
            return !props.excludedColumnNames.includes(heading);
        }
        return true;
    };

    const style = classNames('rounded-lg w-full pt-1', {
        'border border-gray-300': props.hasBorder,
    });

    return (
        <div className="mb-5">
            <div className={style}>
                <div className="flex mb-1 relative">
                    <div className="absolute top-1 right-2">
                        {onEdit && (
                            <div>
                                <Button
                                    buttonType="secondary"
                                    onClick={onEdit}
                                    size="small"
                                >
                                    <span className="sr-only">Edit</span>
                                    <Icons
                                        name="PencilIcon"
                                        size="small"
                                        strokeWidth="2"
                                    />
                                </Button>
                            </div>
                        )}
                        {onDelete && (
                            <div>
                                <Button
                                    buttonType="secondary"
                                    onClick={onDelete}
                                    size="small"
                                >
                                    <span className="sr-only">Close menu</span>
                                    <Icons
                                        name="XMarkIcon"
                                        size="small"
                                        strokeWidth="2"
                                    />
                                </Button>
                            </div>
                        )}
                    </div>
                </div>
                <div className="mr-2 pb-2">
                    <div className="w-full">
                        {/* DESKTOP-VIEW */}
                        <table className="table-auto w-full hidden lg:table">
                            <thead>
                                <tr>
                                    {headers.map((key, i) => (
                                        <td
                                            key={i.toString()}
                                            className="font-bold text-sm capitalize p-1"
                                        >
                                            {showColumnHeading(key) &&
                                                toCapitalizedWords(key)}
                                        </td>
                                    ))}
                                </tr>
                            </thead>
                            <tbody>
                                {rows.map((trow, i) => (
                                    <tr key={i.toString()} className={rowClass}>
                                        {headers.map((theadName, j) => (
                                            <td
                                                key={j.toString()}
                                                className="p-1 w-52 whitespace-pre"
                                            >
                                                {trow[theadName]}
                                            </td>
                                        ))}
                                    </tr>
                                ))}
                            </tbody>
                        </table>

                        {/* MOBILE-VIEW */}
                        <div className="w-full text-sm lg:hidden pl-2">
                            {/* Grid of 2 columns */}
                            {rows.map((trow, i) =>
                                headers.map((theadName, k) => (
                                    <div
                                        key={`${i}-${k}`}
                                        className="grid grid-cols-2 gap-x-2"
                                    >
                                        <div>
                                            {showColumnHeading(theadName)
                                                ? toCapitalizedWords(theadName)
                                                : ' '}
                                        </div>
                                        <div>{trow[theadName]}</div>
                                    </div>
                                ))
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
ReviewBox.defaultProps = {
    hasBorder: true,
};

export default ReviewBox;
