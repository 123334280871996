import React from 'react';
import {
    Field,
    ErrorMessage,
    FieldMetaProps,
    FieldInputProps,
    useField,
} from 'formik';

import { Multiselect } from '@components/Molecules';
import { notNull } from '@helpers/typeGuards';

export interface Option {
    value: string;
    label: string;
}

interface FormMultiselectProps {
    options: Option[];
    label: string;
    defaultValue?: any;
    placeholder?: string;
    name: string;
    value?: any;
    isFieldValuesArray?: boolean;
    id: string;
    disabled?: boolean;
}

const FormMultiselect: React.FC<FormMultiselectProps> = (props) => {
    const [_, fieldMeta, fieldHelpers] = useField(props.name);

    return (
        <div className="relative mb-3 w-full cursor-default">
            <Field id={props.id} name={props.name}>
                {({
                    field,
                    meta,
                }: {
                    field: FieldInputProps<any>;
                    meta: FieldMetaProps<any>;
                }) => {
                    const getValue = (data: (Option | string)[]): Option[] => {
                        if (!data) return [];
                        if (props.isFieldValuesArray) {
                            // find the options if data is a string array
                            return data
                                ?.map((a) =>
                                    props.options.find((b) => b.value == a)
                                )
                                .filter(notNull);
                        }
                        return data as Option[];
                    };

                    return (
                        <Multiselect
                            {...field}
                            onChange={(options) => {
                                let tempOptions = [...options];
                                if (props.isFieldValuesArray === true) {
                                    tempOptions = tempOptions.map((el: any) => {
                                        return el.value;
                                    });
                                }
                                fieldHelpers.setValue(tempOptions);
                            }}
                            options={props.options}
                            label={props.label}
                            value={getValue(props.value || field.value)}
                            placeholder={props.placeholder}
                            hasError={!!(fieldMeta.error && fieldMeta.touched)}
                            disabled={props.disabled}
                        />
                    );
                }}
            </Field>

            <div className="flex content-start text-red-500 text-sm">
                <ErrorMessage
                    name={props.name}
                    render={(msg) => <p>{msg}</p>}
                />
            </div>
        </div>
    );
};

export default FormMultiselect;
