import React, { createContext, useContext, useState } from 'react';

import { DocusignInfo, DocusignConsentRequestBody } from '@interfaces/Api';

import { docusignConsentPost, docusignInformationGet } from '@api/Docusign';

interface DocusignContextProps {
    hasConsented: boolean;
    docusignConsentLoading: boolean;
    getDocusignInfo: () => Promise<DocusignInfo | undefined>;
    recordDocusignConsent: (
        docuSignConsent: DocusignConsentRequestBody
    ) => Promise<DocusignInfo | undefined>;
}

const docusignContextDefaultState: DocusignContextProps = {
    hasConsented: true,
    docusignConsentLoading: true,
    getDocusignInfo: async () => undefined,
    recordDocusignConsent: async (
        docuSignConsent: DocusignConsentRequestBody
    ) => undefined,
};

export const DocusignContext = createContext<DocusignContextProps>(
    docusignContextDefaultState
);

export const useDocusignState = () => {
    return useContext(DocusignContext);
};

export const DocusignAuthProvider: React.FC = ({
    children,
}: React.PropsWithChildren<{}>) => {
    const [hasConsented, setHasConsented] = useState<boolean>(false);
    const [docusignConsentLoading, setDocusignConsentLoading] =
        useState<boolean>(false);

    const getDocusignInfo = async () => {
        setDocusignConsentLoading(true);
        try {
            const result = await docusignInformationGet();
            setHasConsented(!!result?.consent?.consentDate);
            setDocusignConsentLoading(false);
            return result;
        } catch (error) {
            console.log(
                'Error occurred on getting docusign status data!',
                error
            );
            setDocusignConsentLoading(false);
        }
    };

    const recordDocusignConsent = async (body: DocusignConsentRequestBody) => {
        try {
            const result = await docusignConsentPost(body);
            return result;
        } catch (error) {
            console.log(
                'Error occurred on getting docusign status data!',
                error
            );
        }
    };

    return (
        <DocusignContext.Provider
            value={{
                hasConsented,
                docusignConsentLoading,
                getDocusignInfo,
                recordDocusignConsent,
            }}
        >
            {children}
        </DocusignContext.Provider>
    );
};
