import React from 'react';
import { useTranslation } from 'react-i18next';
import { FundTransaction, FundTransactionType } from '@interfaces/Api';


import { RoundIcon, IconUser, IconFolder } from '@components/Atoms';
import { WidgetDashCardContent } from '@components/Charts';

interface SummaryDataPortfolioProps {
    label?: string;
    icon?: React.ReactNode;
    value?: number;
    percentage?: number;
    iconColorClass?: string;
    bgColorClass?: string;
}

export interface SubscriptionInvestmentReportSummaryProps {
    fundTransactions: FundTransaction[];
}

const SubscriptionInvestmentReportSummary: React.FC<
    SubscriptionInvestmentReportSummaryProps
> = (props: SubscriptionInvestmentReportSummaryProps) => {
    const { t } = useTranslation();
    const summaryInfoData: SummaryDataPortfolioProps[] = [
        {
            label: t('client.investment_reports.total_capital_calls'),
            icon: IconFolder,
            value: props.fundTransactions.reduce(
                (acc, cur) =>
                    cur.type === FundTransactionType?.capitalCall ? ++acc : acc,
                0
            ),
            iconColorClass: 'text-gold-500 dark:text-gold-300',
            bgColorClass: 'bg-gold-300 dark:bg-gold-300',
        },
        {
            label: t('client.investment_reports.total_distributions'),
            icon: IconUser,
            value: props.fundTransactions.reduce(
                (acc, cur) =>
                    cur.type === FundTransactionType?.distribution
                        ? ++acc
                        : acc,
                0
            ),
            iconColorClass: 'text-gold-500 dark:text-gold-300',
            bgColorClass: 'bg-gold-300 dark:bg-gold-300',
        },
    ];

    return (
        <div className="flex flex-col md:flex-row justify-center py-5 md:py-0 md:px-5">
            {summaryInfoData?.map((val, index) => (
                <div className="w-full md:w-1/2 md:p-5" key={index.toString()}>
                    <article className="bg-white mb-4 border border-gray-200 min-w-0 rounded-lg shadow-xs overflow-hidden dark:bg-gray-800">
                        <WidgetDashCardContent
                            title={val.label}
                            value={val.value}
                        >
                            <RoundIcon
                                icon={val.icon}
                                iconColorClass={val.iconColorClass}
                                bgColorClass={val.bgColorClass}
                                className="mr-4"
                            />
                        </WidgetDashCardContent>
                    </article>
                </div>
            ))}
        </div>
    );
};

export default SubscriptionInvestmentReportSummary;
