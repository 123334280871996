import classNames from 'classnames';

interface ImgTextProps {
    name: string;
    description?: string | React.ReactNode;
    img: {
        url: string;
        label?: string;
    };
}

export const ImgText = (props: ImgTextProps) => {
    const {
        name,
        description,
        img: { url, label },
    } = props;

    const FALLBACK_URL =
        'https://via.placeholder.com/800X400.png?text=Logo+Not+Found';

    const handleImgOnError = ({ currentTarget }: any) => {
        currentTarget.onerror = null;
        currentTarget.src = FALLBACK_URL;
    };

    const isUrlValid = () => {
        let URL_HOLDER;

        try {
            URL_HOLDER = new URL(url);
        } catch (_) {
            return false;
        }
    };

    return (
        <div className="flex mb-14 border-b pb-6">
            <div
                className={classNames('flex-1 mr-4 max-w-400 max-h-200', {
                    'p-4 border border-logo-blue': isUrlValid(),
                })}
            >
                <img
                    className="w-full h-full"
                    src={url || FALLBACK_URL}
                    alt={label || ''}
                    onError={handleImgOnError}
                />
            </div>
            <div className="flex-2 pl-2">
                <p className="text-2xl text-logo-blue mb-2">{name}</p>
                {description && typeof description === 'string' ? (
                    <p>{description}</p>
                ) : (
                    description
                )}
            </div>
        </div>
    );
};
