import { useState } from 'react';
import { Link } from 'react-router-dom';
import { applicationSettingGet } from '@api/ApplicationSetting';
import { ApplicationSetting } from '@interfaces/Api/ApplicationSetting';

const DistributorContact = () => {
    const [contactEmail, setContactEmail] = useState<string>('');
    const [contactEmailUnavailable, setContactEmailUnavailable] =
        useState<boolean>(false);

    const getContactEmail = async () => {
        try {
            const getData = async () => await applicationSettingGet();

            getData().then(
                (r: Pick<ApplicationSetting, 'distributorContactEmail'>) => {
                    if (r.distributorContactEmail)
                        setContactEmail(r?.distributorContactEmail);
                }
            );
        } catch (error) {
            setContactEmailUnavailable(true);
        }
    };

    getContactEmail();

    return (
        <div>
            <h1 className="text-2xl text-logo-blue font-bold">
                Investing with Hg as a Distributor
            </h1>
            <h2 className="py-4">
                Please reach out if you are interested in accessing Fusion for
                your clients.
            </h2>
            {!contactEmailUnavailable && (
                <p>
                    <Link to={`mailto:${contactEmail}`} className="underline">
                        {contactEmail}
                    </Link>
                </p>
            )}
        </div>
    );
};

export default DistributorContact;
