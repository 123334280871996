import { useTranslation } from 'react-i18next';
import { BasicBreadcrumbs } from '@components/Atoms';
import { mainLayoutWithBreadcrumbsConfig } from '@components/Layout';
import ProductsList from '@containers/Products/ProductsList';
import { useProducts } from '@stores/Products/useProducts';

const Products = () => {
    const { products, isLoading } = useProducts();

    return <ProductsList products={products} isLoading={isLoading} />;
};

const ProductsBreadcrumbs = () => {
    const { t } = useTranslation();

    const getItems = (t: (key) => string) => [
        {
            displayText: t('navbar.products'),
            navigationUrl: '',
        },
    ]

    return <BasicBreadcrumbs items={getItems(t)} />;
};

export default mainLayoutWithBreadcrumbsConfig(
    Products,
    undefined,
    <ProductsBreadcrumbs />
);
