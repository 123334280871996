const FormikLabels = {
    formField: {
        availabilityStatus: {
            name: 'availabilityStatus',
            label: 'Availability Status',
            requiredErrorMsg: 'Availability status required',
        },
        visibleWithoutLogin: {
            name: 'visibleWithoutLogin',
            label: 'Visible Without Login',
        },
        assetManagerId: {
            name: 'assetManagerId',
            label: 'Asset Manager',
        },
        name: {
            name: 'name',
            label: 'Product Legal Name',
            requiredErrorMsg: 'Product Legal name required',
        },
        umbrellaFundName: {
            name: 'umbrellaFundName',
            label: 'Umbrella Name',
        },
        fundInterestsName: {
            name: 'fundInterestsName',
            label: 'Fund Interests Name',
        },
        groupId: {
            name: 'groupId',
            label: 'Product Common Name',
        },
        shortDescription: {
            name: 'shortDescription',
            label: 'Short Description',
            requiredErrorMsg: 'Short Description required',
        },
        targetSubscriptionAmount: {
            name: 'targetSubscriptionAmount',
            label: 'Target Subscription Amount',
            requiredErrorMsg: 'Target subscription amount required',
        },
        capacityAmount: {
            name: 'capacityAmount',
            label: 'Capacity Amount',
            requiredErrorMsg: 'Capacity amount required',
        },
        baseCurrency: {
            name: 'baseCurrency',
            label: 'Base Currency',
            requiredErrorMsg: 'Base Currency required',
        },
        inceptionDate: {
            name: 'inceptionDate',
            label: 'Inception Date',
            requiredErrorMsg: 'Inception Date required',
        },
        keyInformation: {
            keyName: {
                name: 'keyName',
                label: 'Title',
                requiredErrorMsg: 'Key Name required',
            },
            value: {
                name: 'value',
                label: 'Description',
                requiredErrorMsg: 'Key Information value required',
            },
        },
        keyInformationMarkdown: {
            name: 'keyInformationMarkdown',
            label: 'Additional Information',
        },
        shareClasses: {
            name: {
                name: 'name',
                label: 'Name',
                requiredErrorMsg: 'ShareClass name required',
            },
            type: {
                name: 'type',
                label: 'Type',
                requiredErrorMsg: 'ShareClass Type required',
            },
            currency: {
                name: 'currency',
                label: 'Currency',
                requiredErrorMsg: 'Currency Code required',
            },
            distributionFee: {
                name: 'distributionFee',
                label: 'Distribution Fee*',
                requiredErrorMsg: 'Distribution Fee* required',
            },
            managementFee: {
                name: 'managementFee',
                label: 'Management Fee',
            },
            minimumInvestment: {
                name: 'minimumInvestment',
                label: 'Minimum Investment',
                requiredErrorMsg: 'Minimum Investment required',
            },
            minimumSubsequentSubscriptionAmount: {
                name: 'minimumSubsequentSubscriptionAmount',
                label: 'Minimum Subsequent Subscription Amount',
            },
            openEndedInformation: {
                investorLockUp: {
                    name: 'openEndedInformation.investorLockUp',
                    label: 'Investor Lock Up',
                },
                hardLockUpDetail: {
                    lengthOfTimeInMonths: {
                        name: 'openEndedInformation.hardLockUpDetail.lengthOfTimeInMonths',
                        label: 'Length of time (months)',
                    },
                },
            },
            additionalTerms: {
                keyName: {
                    name: 'keyName',
                    label: 'Title',
                },
                value: {
                    name: 'value',
                    label: 'Description',
                },
            },
        },
        documents: {
            fileName: {
                name: 'title',
                label: 'Document Label',
                requiredErrorMsg: 'Document Label is required',
            },
            url: {
                name: 'url',
                label: 'Document URL',
            },
        },
        thumbnail: {
            name: 'thumbnail',
            label: 'Thumbnail Link',
        },
        images: {
            fileName: {
                name: 'title',
                label: 'Image Title',
                requiredErrorMsg: 'Image Title is required',
            },
            url: {
                name: 'url',
                label: 'Image URL',
            },
        },
        videos: {
            title: {
                name: 'title',
                label: 'Video Label',
                requiredErrorMsg: 'Document Label is required',
            },
            url: {
                name: 'url',
                label: 'Video URL',
                requiredErrorMsg: 'Document Label is required',
            },
            embed_url: {
                name: 'embed_url',
                label: 'Embed URL',
                requiredErrorMsg: 'Embed iFrame URL is required',
            },
        },
        assetClassType: {
            name: 'assetClassType',
            label: 'Asset Class',
            requiredErrorMsg: 'Asset Class Type is required',
        },
        liquidityType: {
            name: 'liquidityType',
            label: 'Liquidity *',
            requiredErrorMsg: 'Liquidity Type is required',
        },
        closedEndedInformation: {
            coolingOffDays: {
                name: 'closedEndedInformation.coolingOffDays',
                label: 'Cooling Off Period (days)',
            },
            keyDates: {
                name: 'closedEndedInformation.keyDates',
                label: 'Key Dates',
            },
        },
        openEndedInformation: {
            redemptionFrequency: {
                name: 'openEndedInformation.redemptionFrequency',
                label: 'Redemption Frequency',
            },
            redemptionFrequencyDeadline: {
                name: 'openEndedInformation.redemptionFrequencyDeadline',
                label: 'Redemption Reference Day',
            },
            redemptionNoticeDays: {
                name: 'openEndedInformation.redemptionNoticeDays',
                label: 'Redemption Notice Days',
            },
            redemptionQuantityAvailablePercentage: {
                name: 'openEndedInformation.redemptionQuantityAvailablePercentage',
                label: 'Redemption Quantity Available (%)',
            },
            businessDayCountryCodeList: {
                name: 'openEndedInformation.businessDayCountryCodeList',
                label: 'Business Day Countries',
            },
            subscriptionFrequency: {
                name: 'openEndedInformation.subscriptionFrequency',
                label: 'Subscription Frequency',
            },
            subscriptionFrequencyDeadline: {
                name: 'openEndedInformation.subscriptionFrequencyDeadline',
                label: 'Subscription Reference Day',
            },
            subscriptionNoticeDays: {
                name: 'openEndedInformation.subscriptionNoticeDays',
                label: 'Subscription Notice Days',
            },
        },
    },
} as const;

export default FormikLabels;
