import { SubscriptionResponse, SubscriptionStatusEnum } from '@interfaces/Api';
import {
    InvestorLockUpEnum,
    ProductResponse,
} from '@interfaces/Api/ProductResponse';
import dayjs from 'dayjs';

export interface RedemptionLotItem {
    subscriptionId: string;
    subscriptionAmount: number;
    maximumRedeemableAmount: number;
    redeemedAmount: number;
    subscriptionApprovedDate: Date;
    lotNumber?: number;
    currencyCode: string;
    hardLockup: {
        unlockDate?: Date;
    };
    softLockup: {
        feePercentage?: number;
    };
}

export const getRedemptionLotItem = (
    subscription: SubscriptionResponse,
    product: ProductResponse
): RedemptionLotItem => {
    const originalSubscriptionStatusApproved = subscription.statusLogs.find(
        (a) => a.status == SubscriptionStatusEnum.approved
    );
    const subscriptionApprovedDate =
        originalSubscriptionStatusApproved?.createdAt || new Date();

    // TODO: Need to probably subtract the position amount by the redeemed amount, although this will
    // need to change once the position work makes it in
    const subscriptionAmount =
        subscription.acceptedSubscriptionAmount ||
        subscription.subscriptionAmount ||
        0;
    let maximumRedeemableAmount = subscriptionAmount;

    const productShareClass = product.shareClasses.find(
        (a) => a.name == subscription.shareClass?.name
    );

    let hardLockup: {
        unlockDate?: Date;
    } = {};

    let softLockup: {
        feePercentage?: number;
    } = {};

    if (
        productShareClass?.openEndedInformation?.investorLockUp ==
        InvestorLockUpEnum.HardLockUp
    ) {
        // check if the lockup period has passed
        const lockUpMonths =
            productShareClass?.openEndedInformation?.hardLockUpDetail
                ?.lengthOfTimeInMonths;

        const lockUpDate = dayjs(
            originalSubscriptionStatusApproved?.createdAt
        ).add(lockUpMonths || 0, 'month');
        if (dayjs().isBefore(lockUpDate)) {
            maximumRedeemableAmount = 0;
            hardLockup = { unlockDate: lockUpDate.toDate() };
        }
    }

    if (
        productShareClass?.openEndedInformation?.investorLockUp ==
        InvestorLockUpEnum.SoftLockUp
    ) {
        // check if we're in a soft lockup period and if so,
        // get the fee percentage
        const softLockUpDetails =
            productShareClass?.openEndedInformation.softLockUpDetails;
        if (softLockUpDetails) {
            let monthsAccumulator = 0;
            for (const softLockUpDetail of softLockUpDetails) {
                const lockUpMonths = softLockUpDetail.lengthOfTimeInMonths || 0;
                const lockUpDate = dayjs(subscriptionApprovedDate).add(
                    monthsAccumulator + lockUpMonths,
                    'month'
                );
                if (dayjs().isBefore(lockUpDate)) {
                    softLockup = {
                        feePercentage:
                            +softLockUpDetail.redemptionFeePercentage,
                    };
                    break;
                } else {
                    softLockup = {
                        feePercentage: 0,
                    };
                }
                monthsAccumulator += lockUpMonths;
            }
        }
    }

    return {
        subscriptionId: subscription._id,
        // TODO: it is known that this is not the correct amount, but it is the only one we have at the moment
        subscriptionAmount: subscriptionAmount,
        maximumRedeemableAmount: maximumRedeemableAmount,
        redeemedAmount: 0,
        subscriptionApprovedDate: subscriptionApprovedDate,
        currencyCode: subscription.subscriptionCurrencyCode || '',
        hardLockup: hardLockup,
        softLockup: softLockup,
    };
};
