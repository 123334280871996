import {
    LifecycleNotice,
    MailingListAdressee,
} from '@interfaces/Api/SubscriptionNotice';
import { eventListSubHeader } from './helpers/eventListHelper';
import { Button } from '@components/Atoms';
import { Fragment, useState } from 'react';
import { useAuthState } from '@contexts/AuthContext';
import { AuthRoleEnum, TaskListResponse } from '@interfaces/Api';
import { TaskStatusEnum, TaskTypeEnum } from '@interfaces/tasks.enum';
import { dateShortFormatter } from '@helpers/Date.helper';
import { VerificationOfReceiptModal } from './VerificationOfReceiptModal';
import { useStorageTokenState } from '@contexts/StorageTokenContext';
interface CapitalCallEventProps {
    notice: LifecycleNotice;
}
export const CapitalCallEvent: React.FC<CapitalCallEventProps> = ({
    notice,
}) => {
    const { withToken } = useStorageTokenState();
    const { mailingList = [], children } = notice;
    const { hasRoles } = useAuthState();
    const [showConfirmationModal, setShowConfirmationModal] =
        useState<boolean>(false);

    const [selectedTask, setSelectedTask] = useState<TaskListResponse>();

    return (
        <>
            <p className="text-sm md:text-base leading-snug text-opacity-100 mb-2">
                <span className="font-bold">{eventListSubHeader(notice)}</span>
            </p>
            <p className="text-sm md:text-base leading-snug text-opacity-100 mb-2">
                {notice.documents?.[0]?.url ? (
                    <a
                        href={withToken(notice.documents?.[0]?.url)}
                        target="_new"
                    >
                        {notice.comment}
                    </a>
                ) : (
                    notice.comment
                )}
            </p>
            <p className="text-sm md:text-base leading-snug text-opacity-100 mb-2">
                {mailingList?.length > 0 && (
                    <>
                        The following investors have been notified:
                        <br />
                    </>
                )}
                {mailingList?.map((adressee: MailingListAdressee, i) => (
                    <Fragment key={adressee.email + i}>
                        <span className="text-sm" key={`${adressee.email}${i}`}>
                            {adressee.email}
                        </span>
                        <br />
                    </Fragment>
                ))}
            </p>

            {children &&
                children?.map((childNotice: LifecycleNotice, i) => {
                    const {
                        mailingList: childMailingList = [],
                        tasks,
                        eventId,
                    } = childNotice;
                    return (
                        <Fragment key={eventId}>
                            <p className="text-sm md:text-base leading-snug text-opacity-100">
                                <span className="font-bold">
                                    {eventListSubHeader(childNotice)}
                                </span>
                            </p>
                            <p className="text-sm md:text-base leading-snug text-opacity-100 mb-2">
                                {childNotice.documents?.[0]?.url ? (
                                    <a
                                        href={withToken(
                                            childNotice.documents?.[0]?.url
                                        )}
                                        target="_new"
                                    >
                                        {childNotice.comment}
                                    </a>
                                ) : (
                                    childNotice.comment
                                )}
                            </p>
                            {childMailingList.length > 0 && (
                                <p>
                                    The following investors have been notified:
                                </p>
                            )}
                            {childMailingList.map(
                                (adressee: MailingListAdressee, i) => (
                                    <Fragment key={`${adressee.email}${i}`}>
                                        <span className="text-sm">
                                            {adressee.email}
                                        </span>
                                        <br />
                                    </Fragment>
                                )
                            )}
                            {hasRoles([
                                AuthRoleEnum.superUser,
                                AuthRoleEnum.fundAdmin,
                            ]) &&
                                tasks?.map(
                                    (task: TaskListResponse, i) =>
                                        task.taskType ===
                                            TaskTypeEnum.VERIFICATION_OF_RECEIPT &&
                                        task.completionStatus ===
                                            TaskStatusEnum.INCOMPLETE && (
                                            <Button
                                                label={`Verify receipt`}
                                                key={task._id}
                                                className="mt-4"
                                                buttonType="primary"
                                                type="button"
                                                onClick={() => {
                                                    setShowConfirmationModal(
                                                        true
                                                    );
                                                    setSelectedTask(task);
                                                }}
                                                id="create-button"
                                            />
                                        )
                                )}
                            {tasks?.map(
                                (task: TaskListResponse, i) =>
                                    task.taskType ===
                                        TaskTypeEnum.VERIFICATION_OF_RECEIPT &&
                                    task.completionStatus ===
                                        TaskStatusEnum.COMPLETED && (
                                        <p
                                            className="font-bold"
                                            key={task?._id}
                                        >
                                            Verification of receipt by{' '}
                                            {task.approvalList.map((approval) =>
                                                [
                                                    approval?.approver
                                                        ?.firstName,
                                                    approval?.approver?.surname,
                                                    'on',
                                                    dateShortFormatter(
                                                        approval?.approvalDate
                                                    ),
                                                ]
                                                    .filter((a) => a)
                                                    .join(' ')
                                            )}
                                        </p>
                                    )
                            )}
                        </Fragment>
                    );
                })}
            {selectedTask && (
                <VerificationOfReceiptModal
                    notice={notice}
                    task={selectedTask}
                    showConfirmationModal={showConfirmationModal}
                    setShowConfirmationModal={setShowConfirmationModal}
                />
            )}
        </>
    );
};
