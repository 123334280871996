const SuccessfulSubmission: React.FC = () => {
    return (
        <div className="mt-3 flex flex-col items-center">
            <h2 className="text-2xl logo-blue align-middle pb-4">
                Welcome to the Hg Fusion investment portal.
            </h2>
            <p>
                We’ve sent a verification link to your email address. If you do
                not receive the link please reach out to us at{' '}
                <a href="mailto:support@hgcapital.com">support@hgcapital.com</a>
                .
            </p>
        </div>
    );
};

export default SuccessfulSubmission;
