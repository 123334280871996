import { roleMap } from '@helpers/SystemUser.helper';
import { AuthRoleEnum } from '@interfaces/Api';

export interface SortConfigProps {
    key: string;
    direction: 'ascending' | 'descending' | '';
}

const handleSortConfigKey = ({ a, b, sortConfig }) => {
    switch (sortConfig?.key) {
        case 'lastLoginDate':
            return {
                itemA: new Date(a?.currentLoginDate).getTime(),
                itemB: new Date(b?.currentLoginDate).getTime(),
            };
        case 'invitationLog': {
            const aLastInvited = new Date(
                a?.invitationLog[a?.invitationLog?.length - 1]?.sentAt
            ).getTime();

            const bLastInvited = new Date(
                b?.invitationLog[b?.invitationLog?.length - 1]?.sentAt
            ).getTime();
            return {
                itemA: aLastInvited,
                itemB: bLastInvited,
            };
        }
        case 'role':
            return {
                itemA: roleMap[a[sortConfig?.key] as AuthRoleEnum],
                itemB: roleMap[b[sortConfig?.key] as AuthRoleEnum],
            };

        default:
            return {
                itemA: a[sortConfig?.key],
                itemB: b[sortConfig?.key],
            };
    }
};

export const handleSorting = (a: any, b: any, sortConfig: SortConfigProps) => {
    const { itemA, itemB } = handleSortConfigKey({ a, b, sortConfig });

    if ((itemA || '') < (itemB || '')) {
        return sortConfig?.direction === 'ascending' ? -1 : 1;
    }
    if ((itemA || '') > (itemB || '')) {
        return sortConfig?.direction === 'descending' ? -1 : 1;
    }
    return 0;
};

export const handleSystemUserFiltering = (val: any, searchTextRaw?: string) => {
    const eachWordFilter = searchTextRaw!
        .toLocaleLowerCase()
        .split(' ')
        .map((searchText) => {
            const findInSlug = val?.slug
                ?.toLowerCase()
                ?.includes(searchText!.toLowerCase());
            const findInRole = roleMap[val.role as AuthRoleEnum]
                .toLocaleLowerCase()
                .includes(searchText!.toLocaleLowerCase() as string);
            const findInEmail = val?.email
                .toLocaleLowerCase()
                .includes(searchText!.toLocaleLowerCase() as string);
            const findInOwner = val.owner
                ?.toLocaleLowerCase()
                .includes(searchText!.toLocaleLowerCase());
            return (
                findInSlug ||
                findInRole ||
                findInOwner ||
                (findInEmail as boolean)
            );
        });

    return eachWordFilter.every((a) => a === true);
};
