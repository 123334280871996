import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { Button } from '@components/Atoms';
import { SubscriptionListItem } from '@containers/Subscriptions/SubscriptionList';
import { SubscriptionStatusBadge } from '@components/Organisms';
import { getProductDetails } from '@helpers/Product.helper';
interface SubscriptionListsubscription {
    subscriptionListItems: SubscriptionListItem[];
    onDeleteSubscription?: (subscriptionId: string) => {};
    buttonAction: (subscription: SubscriptionListItem) => void;
}

const SubscriptionList: React.FC<SubscriptionListsubscription> = (
    subscription
) => {
    const { t } = useTranslation();
    const { subscriptionListItems, buttonAction } = subscription;
    const subscriptions =
        subscriptionListItems?.length > 0 ? subscriptionListItems : [];

    if (subscriptions.length === 0)
        return <p>{t('client.subscriptions.no_subscriptions')}</p>;

    return (
        <>
            {subscriptions.map((subscription, i) => (
                <div
                    key={`${subscription.subscriptionId} ${i}`}
                    className="bg-white border-b pb-1 my-4 md:my-0 flex flex-col md:flex-row justify-between items-center"
                >
                    <div className="items-center md:items-left md:w-3/12 my-2">
                        <Link
                            to={`/subscriptions/${subscription.subscriptionUrl}`}
                        >
                            <SubscriptionStatusBadge
                                subscriptionStatus={
                                    subscription.subscriptionStatus!
                                }
                            />
                        </Link>
                    </div>
                    <div className={`text-left md:w-6/12 my-2`}>
                        <Link
                            to={`/subscriptions/${subscription.subscriptionUrl}`}
                        >
                            <h3 className="text-base pr-3 md:pr-2 cursor-pointer">
                                {getProductDetails({
                                    productName: subscription.productName,
                                    shareClassName: subscription.shareClassName,
                                    subscriptionAmount:
                                        subscription.subscriptionAmountFormatted,
                                })}
                            </h3>
                        </Link>
                    </div>
                    <Button
                        onClick={() => buttonAction(subscription)}
                        label="Raise Capital Call Notice"
                    />
                </div>
            ))}
        </>
    );
};

export default SubscriptionList;
