import { Form, Formik } from 'formik';
import { useMemo, useState } from 'react';
import * as Yup from 'yup';
import { Button } from '@components/Atoms';
import { FormSelect, Modal } from '@components/Molecules';
import { ClientEnum } from '@interfaces/Api/ConfigurationResponse';
import { useConfigurationState } from '@contexts/ConfigurationContext';
import { getStrategies } from '@services/Strategies';
import MDEditor from '@uiw/react-md-editor';
import { useNavigate } from 'react-router-dom';

export type NoticeSelection = {
    investorType: string;
    countryCode: string;
};

// CountryName.InvestorType.IsValid or CountryName.IsValid
const countryNameInvestorTypeIsValid: {
    [key: string]: { [key: string]: boolean } | boolean;
} = {
    Austria: { 'Mifid professional': true, 'Non-Mifid professional': false },
    Belgium: { 'Mifid professional': true, 'Non-Mifid professional': false },
    Bulgaria: { 'Mifid professional': true, 'Non-Mifid professional': false },
    Croatia: { 'Mifid professional': true, 'Non-Mifid professional': false },
    Cyprus: { 'Mifid professional': true, 'Non-Mifid professional': false },
    'Czech Republic': {
        'Mifid professional': true,
        'Non-Mifid professional': false,
    },
    Denmark: { 'Mifid professional': true, 'Non-Mifid professional': false },
    Estonia: { 'Mifid professional': true, 'Non-Mifid professional': false },
    Finland: { 'Mifid professional': true, 'Non-Mifid professional': false },
    France: { 'Mifid professional': true, 'Non-Mifid professional': false },
    Germany: { 'Mifid professional': true, 'Non-Mifid professional': false },
    Greece: { 'Mifid professional': true, 'Non-Mifid professional': false },
    Hungary: { 'Mifid professional': true, 'Non-Mifid professional': false },
    Iceland: { 'Mifid professional': true, 'Non-Mifid professional': false },
    Ireland: { 'Mifid professional': true, 'Non-Mifid professional': false },
    Italy: { 'Mifid professional': true, 'Non-Mifid professional': false },
    Latvia: { 'Mifid professional': true, 'Non-Mifid professional': false },
    Liechtenstein: {
        'Mifid professional': true,
        'Non-Mifid professional': false,
    },
    Lithuania: { 'Mifid professional': true, 'Non-Mifid professional': false },
    Luxembourg: { 'Mifid professional': true, 'Non-Mifid professional': false },
    Malta: { 'Mifid professional': true, 'Non-Mifid professional': false },
    Netherlands: {
        'Mifid professional': true,
        'Non-Mifid professional': false,
    },
    Norway: { 'Mifid professional': true, 'Non-Mifid professional': false },
    Poland: { 'Mifid professional': true, 'Non-Mifid professional': false },
    Portugal: { 'Mifid professional': true, 'Non-Mifid professional': false },
    Romania: { 'Mifid professional': true, 'Non-Mifid professional': false },
    Slovakia: { 'Mifid professional': true, 'Non-Mifid professional': false },
    Slovenia: { 'Mifid professional': true, 'Non-Mifid professional': false },
    Spain: { 'Mifid professional': true, 'Non-Mifid professional': false },
    Sweden: { 'Mifid professional': true, 'Non-Mifid professional': false },
    Other: false,
};

export const getConfig = (client: ClientEnum | '') => {
    const config: Record<string, any> = {
        [ClientEnum.HG]: {
            countries: [],
            validationSchema: undefined,
        },
    };

    if (!config?.[client]) {
        return {
            countries: countryNameInvestorTypeIsValid,
            validationSchema: Yup.object().shape({
                countryCode: Yup.string().required('Required'),
                investorType: Yup.string().when('countryCode', {
                    is: (countryName: string) => {
                        // might not be right
                        return countryNameInvestorTypeIsValid[countryName];
                    },
                    then: Yup.string().required('Required'),
                }),
            }),
        };
    }
};

const initialValues = {
    investorType: '',
    countryCode: '',
};

const ProductDisclaimerPage: React.FC = () => {
    const [errorMessage, setErrorMessage] = useState<string | undefined>();
    const { configuration, themeName } = useConfigurationState();
    const navigate = useNavigate();

    const config = useMemo(() => {
        return getConfig(configuration?.client || '');
    }, [configuration?.client]);

    const countries = config?.countries;
    const validationSchema = config?.validationSchema;

    const handleSubmit = (values: NoticeSelection) => {
        const strategy = getStrategies(configuration?.client as ClientEnum);

        if (!config?.validationSchema) {
            strategy.onSubmitDisclaimer(values, navigate);
        }

        if (
            isCountryCodeValid(values.countryCode) &&
            isInvestorTypeValid(values.countryCode, values.investorType)
        ) {
            setErrorMessage(undefined);
            strategy.onSubmitDisclaimer(values, navigate);
        } else {
            setErrorMessage('You are not eligible to view these products');
        }
    };

    const disclaimerText =
        configuration?.branding?.product?.anonymousDisclaimerText ??
        'Insert text here';

    const isCountryCodeValid = (countryCode?: string) => {
        if (countryCode) {
            // needs to be an object
            return typeof countries?.[countryCode] == 'object';
        }
        return false;
    };

    const isInvestorTypeValid = (
        countryCode?: string,
        investorType?: string
    ) => {
        if (countryCode && investorType) {
            // needs to be true
            const countryInvestorTypes = countries?.[countryCode];
            if (typeof countryInvestorTypes == 'object') {
                return countryInvestorTypes[investorType];
            }
        }
        return false;
    };

    const getCountryNames = () => {
        return Object.keys(countries || {});
    };

    const getInvestorTypes = (countryCode: string) => {
        return Object.keys(countries?.[countryCode] || {});
    };

    return (
        <Modal show width="w-1/2" className="max-h-screen" hideBackground>
            <Modal.Header>
                <div>Important notice</div>
            </Modal.Header>
            <Modal.Body className="h-v-70 overflow-y-scroll overflow-x-hidden">
                {errorMessage ? (
                    <>
                        <div>Sorry, you do not qualify.</div>
                        <br />

                        <div>{errorMessage}</div>
                    </>
                ) : (
                    <>
                        <div>
                            <MDEditor.Markdown source={disclaimerText} />
                        </div>

                        <br />

                        <Formik
                            initialValues={initialValues}
                            onSubmit={(values) => {
                                handleSubmit(values);
                            }}
                            validationSchema={validationSchema}
                        >
                            {(formik) => (
                                <Form>
                                    {config?.validationSchema && (
                                        <FormSelect
                                            key="countryCode"
                                            name="countryCode"
                                            placeholder="Select ..."
                                            label="Country"
                                            optionsData={getCountryNames().map(
                                                (a) => ({ label: a, value: a })
                                            )}
                                        />
                                    )}
                                    {config?.validationSchema &&
                                        isCountryCodeValid(
                                            formik.values.countryCode
                                        ) && (
                                            <FormSelect
                                                key="investorType"
                                                name="investorType"
                                                placeholder="Select ..."
                                                label="Investor Type"
                                                optionsData={getInvestorTypes(
                                                    formik.values.countryCode
                                                ).map((a) => ({
                                                    label: a,
                                                    value: a,
                                                }))}
                                            />
                                        )}

                                    <div className="flex flex-row-reverse">
                                        <Button
                                            disabled={
                                                config?.validationSchema &&
                                                !(
                                                    formik.isValid &&
                                                    formik.dirty
                                                )
                                            }
                                            label="Submit"
                                            buttonType="primary"
                                            type="submit"
                                        />
                                    </div>
                                </Form>
                            )}
                        </Formik>
                    </>
                )}
            </Modal.Body>
        </Modal>
    );
};

export default ProductDisclaimerPage;
