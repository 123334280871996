export enum EventTypeEnum {
    RESOLVE_EVENT = 'ResolveEvent',
    CAPITAL_CALL_NOTICE_EVENT = 'CAPITAL_CALL_NOTICE_EVENT',
    CAPITAL_CALL_PAID_EVENT = 'CAPITAL_CALL_PAID_EVENT',
    UKNOWN = 'Uknown',
}

export enum EventTypeLabelEnum {
    RESOLVE_EVENT = 'ResolveEvent',
    CAPITAL_CALL_NOTICE_EVENT = 'CapitalCallNoticeEvent',
    CAPITAL_CALL_PAID_EVENT = 'CapitalCallPaidEvent',
    UKNOWN = 'Uknown',
}
