import React from 'react';
import SubscriptionListTemplateItemElem from './SubscriptionListTemplateItemElem';

export interface SubscriptionTemplateListItem {
    subscriptionTemplateId: string;
    subscriptionTemplateUrl: string;
    subscriptionTemplateName: string;
    isMaster: boolean;
}

interface SubscriptionListTemplateProps {
    subscriptionTemplateListItems: SubscriptionTemplateListItem[];
    endInvestorProfile: any;
}
const SubscriptionListTemplate: React.FC<SubscriptionListTemplateProps> = (
    props
) => (
    <>
        {props?.subscriptionTemplateListItems?.length > 0 ? (
            props.subscriptionTemplateListItems.map((val) => (
                <SubscriptionListTemplateItemElem
                    key={val.subscriptionTemplateId}
                    endInvestorProfile={props.endInvestorProfile}
                    subscriptionTemplateId={val.subscriptionTemplateId}
                    subscriptionTemplateUrl={val.subscriptionTemplateUrl}
                    subscriptionTemplateName={val.subscriptionTemplateName}
                    isMaster={val.isMaster}
                />
            ))
        ) : (
            <p>There are no preconfigured subscription templates.</p>
        )}
    </>
);

export default SubscriptionListTemplate;
