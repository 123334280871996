/**
 *
 * @export
 * @interface LoginResponse
 */

import { AuthorizationTokens } from './AuthorizationTokens';

export interface LoginResponse {
    status: LoginStatus;
    authorizationTokens?: AuthorizationTokens;
}

export enum LoginStatus {
    EmailSent = 'emailSent',
    Success = 'success',
}
