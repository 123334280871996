import { useTranslation } from 'react-i18next';
import { useRegisterInvestorState } from '../RegisterInvestor.context';
import { useBookingCentres } from '@stores/BookingCentres/useBookingCentres';

export const BookingCentresSummary = () => {
    const { t } = useTranslation();
    const { data } = useRegisterInvestorState();
    const { bookingCentres } = useBookingCentres();

    return (
        <p>
            <span className="font-bold mr-4">{t('client.registration.summary.booking_centres')}:</span>
            {Array.isArray(data['bookingCentreIds'])
                ? data['bookingCentreIds'].length > 0 &&
                  data['bookingCentreIds'].map((bcId) => {
                      const bc = bookingCentres.find((bc) => bc._id === bcId);

                      return (
                          <span className="mr-2" key={bc?._id}>
                              {bc?.description}
                          </span>
                      );
                  })
                : data['bookingCentreIds']}
        </p>
    );
};
