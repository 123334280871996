export enum TaskTypeEnum {
    VERIFICATION_OF_RECEIPT = 'VERIFICATION_OF_RECEIPT',
    CAPITAL_CALL_NOTICE_NOTIFICATIONS = 'CAPITAL_CALL_NOTICE_NOTIFICATIONS',
    CAPITAL_CALL_PAID_NOTIFICATIONS = 'CAPITAL_CALL_PAID_NOTIFICATIONS',
}

export enum TaskTypeLabelEnum {
    VERIFICATION_OF_RECEIPT = 'Verification Of Receipt',
    CAPITAL_CALL_NOTICE_NOTIFICATIONS = 'Capital Call Notifications',
    CAPITAL_CALL_PAID_NOTIFICATIONS = 'Capital Call Paid Notifications',
}

export enum TaskStatusEnum {
    INCOMPLETE = 'Incomplete',
    COMPLETED = 'Completed',
}
