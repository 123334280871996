import React, { useCallback, useMemo } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { WorkflowStepper } from '..';

interface SubscriptionStepsProps {
    steps: {
        endPathUrl: string;
        stepName: string;
        canClick?: boolean;
        showStep: boolean;
    }[];
}

const SubscriptionSteps: React.FC<SubscriptionStepsProps> = (
    props: SubscriptionStepsProps
) => {
    const location = useLocation();
    const endPathUrl = location.pathname.substring(
        location.pathname.lastIndexOf('/') + 1
    );

    const { subscriptionId, subscriptionTemplateId } = useParams();

    const id = subscriptionId || subscriptionTemplateId;

    const baseUrl = subscriptionId
        ? '/subscriptions'
        : '/subscription-templates';

    const steps = useMemo(() => {
        return props.steps.map((step) => {
            return {
                ...step,
                stepUrl: `${baseUrl}/${id}/${step.endPathUrl}`,
                label: step.stepName,
            };
        });
    }, [props.steps, baseUrl, id]);

    return (
        <div>
            <WorkflowStepper
                steps={steps}
                activeStep={{ endPathUrl: endPathUrl }}
            />
        </div>
    );
};

export default SubscriptionSteps;
