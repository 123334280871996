export const IconSortAsc = ({
    className,
    strokeWidth,
}: {
    className: string;
    strokeWidth?: string;
}) => {
    return (
        <svg
            className={className}
            viewBox="0 0 24 24"
            stroke="currentColor"
            strokeWidth={strokeWidth}
        >
            <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M3 4h13M3 8h9m-9 4h6m4 0l4-4m0 0l4 4m-4-4v12"
            />
        </svg>
    );
};
