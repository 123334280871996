import { useEffect } from 'react';
import { useSubscriptionsActions, useSubscriptionsState } from './';

export const useSubscription = (subscriptionId?: string) => {
    const { subscription } = useSubscriptionsState();
    const { getSubscription, resetSubscription } = useSubscriptionsActions();

    useEffect(() => {
        const loadData = async () => {
            await getSubscription(subscriptionId);
        };
        if (subscriptionId) {
            loadData();
        }

        return () => {
            resetSubscription();
        };
    }, [subscriptionId]);

    return { subscription };
};
