import { useState } from 'react';
import { FieldArray } from 'formik';

import { Button } from '@components/Atoms';
import {
    DataReview,
    InlineFormFrame,
    FormInputBox,
    FormSelect,
} from '@components/Molecules';

import getClientRegistrationFormFields from '../ClientRegistrationFormFields';

import { isoCountriesOptions } from '@helpers/isoCountries';
import { isoCurrenciesOptions } from '@helpers/isoCurrencies';
import { useTranslation } from 'react-i18next';

export const BankAccountsSelection = () => {
    const { t } = useTranslation();
    const [showAddBankAccount, setShowAddBankAccount] = useState<boolean>(true);

    const emptyBankDetails = {
        bankName: '',
        countryCurrencyCode: '',
        countryCodeOfAccount: '',
        IBAN: '',
    };

    const toggleBankAccount = () =>
        setShowAddBankAccount((prevState) => !prevState);

    return (
        <div className="text-logo-blue">
            <h3 className="text-2xl font-normal leading-8 my-2 mt-8">
                {t('client.registration.bank_accounts.title')}
            </h3>
            <p className="text-base py-1">
                {t('client.registration.bank_accounts.instruction')}
            </p>

            <FieldArray
                name="bankAccounts"
                render={(arrayHelpers) => (
                    <div>
                        {arrayHelpers.form.values?.bankAccounts?.map(
                            (bankAccount: any, i: number, arr: any) =>
                                !showAddBankAccount && i === arr.length - 1 ? (
                                    <InlineFormFrame
                                        key={i.toString()}
                                        primaryAction={t('client.registration.bank_accounts.save_bank_account_button')}
                                        onPrimaryAction={() => {
                                            const isBankAccountInvalid =
                                                arrayHelpers?.form?.errors
                                                    ?.bankAccounts &&
                                                Array.isArray(
                                                    arrayHelpers.form.errors
                                                        .bankAccounts
                                                );
                                            const touchedValues = {
                                                bankAccounts: [
                                                    ...arrayHelpers.form.values.bankAccounts.map(
                                                        (address: any) =>
                                                            Object.keys(
                                                                address
                                                            ).reduce(
                                                                (
                                                                    prev,
                                                                    curr
                                                                ) => {
                                                                    return {
                                                                        ...prev,
                                                                        [curr]: true,
                                                                    };
                                                                },
                                                                {}
                                                            )
                                                    ),
                                                ],
                                            };
                                            arrayHelpers.form.setTouched(
                                                touchedValues,
                                                true
                                            );
                                            if (!isBankAccountInvalid) {
                                                toggleBankAccount();
                                            }
                                        }}
                                        secondaryAction={t('ui.controls.cancel')}
                                        onSecondaryAction={() => {
                                            arrayHelpers.remove(i);
                                            toggleBankAccount();
                                        }}
                                    >
                                        <FormInputBox
                                            name={`bankAccounts[${i}].bankName`}
                                            label={
                                                getClientRegistrationFormFields(t)
                                                    .bankAccounts.bankName.label
                                            }
                                            placeholder={t('client.registration.bank_accounts.account_nickname.placeholder')}
                                        />

                                        <div className="bg-white rounded-lg">
                                            <FormSelect
                                                name={`bankAccounts[${i}].countryCurrencyCode`}
                                                label={
                                                    getClientRegistrationFormFields(t)
                                                        .bankAccounts
                                                        .countryCurrencyCode
                                                        .label
                                                }
                                                optionsData={
                                                    isoCurrenciesOptions
                                                }
                                            />
                                        </div>

                                        <div className="bg-white rounded-lg">
                                            <FormSelect
                                                name={`bankAccounts[${i}].countryCodeOfAccount`}
                                                label={
                                                    getClientRegistrationFormFields(t)
                                                        .bankAccounts
                                                        .countryCodeOfAccount
                                                        .label
                                                }
                                                optionsData={
                                                    isoCountriesOptions
                                                }
                                            />
                                        </div>

                                        <FormInputBox
                                            name={`bankAccounts[${i}].IBAN`}
                                            label={
                                                getClientRegistrationFormFields(t)
                                                    .bankAccounts.IBAN.label
                                            }
                                            placeholder={t('client.registration.bank_accounts.iban.placeholder')}
                                        />
                                        <FormInputBox
                                            name={`bankAccounts[${i}].confirmIBAN`}
                                            label={t('client.registration.bank_accounts.confirm_iban')}
                                            placeholder={t('client.registration.bank_accounts.confirm_iban.placeholder')}
                                        />
                                    </InlineFormFrame>
                                ) : (
                                    /* eslint-disable */
                                    <DataReview
                                        key={i.toString()}
                                        onDelete={() => arrayHelpers.remove(i)}
                                        onEdit={toggleBankAccount}
                                    >
                                        <p className="text-sm">
                                            {bankAccount.bankName}
                                        </p>
                                        <p className="text-sm">
                                            {bankAccount.countryCurrencyCode}
                                        </p>
                                        <p className="text-sm">
                                            {bankAccount.countryCodeOfAccount}
                                        </p>
                                        <p className="text-sm">
                                            {bankAccount.IBAN}
                                        </p>
                                    </DataReview>
                                )
                        )}
                        {showAddBankAccount && (
                            <Button
                                label={t('client.registration.bank_accounts.add_bank_account_button')}
                                type="button"
                                buttonType="secondary"
                                className="w-full my-2"
                                onClick={() => {
                                    arrayHelpers.push(emptyBankDetails);
                                    toggleBankAccount();
                                }}
                            />
                        )}
                    </div>
                )}
            />
        </div>
    );
};
