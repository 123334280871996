import React from 'react';

import CreatableSelect from 'react-select/creatable';
import {
    ActionMeta,
    InputActionMeta,
    MultiValue,
    StylesConfig,
} from 'react-select';
import { Option } from '@components/Molecules';

type IsMulti = true;

// https://react-select.com/styles
const customStyles: StylesConfig<Option, IsMulti> = {
    control: (provided, state) => ({
        ...provided,
        borderRadius: '8px',

        minHeight: '67px',
        borderWidth: state.isFocused ? '2px' : '1px',
        borderColor: state.isFocused
            ? 'rgba(73, 119, 221, 1)'
            : 'rgba(215, 215, 224, 1)',
        boxShadow: 'unset',
    }),
    menu: (provided, state) => ({
        ...provided,
    }),
    input: (provided, state) => ({
        ...provided,
    }),
    valueContainer: (provided, state) => ({
        ...provided,
        paddingTop: '20px',
    }),
};

interface MultiselectCreatSearch {
    options?: Option[];
    label?: string;
    defaultValue?: Option[];
    inputValue?: string;
    placeholder: string;
    disabled?: boolean;
    required?: boolean;
    handleChange: (
        newValue: MultiValue<Option>,
        actionMeta: ActionMeta<any>
    ) => void;
    handleKeyDown?: (event: React.KeyboardEvent<HTMLInputElement>) => void;
    handleInputChange?:
        | ((newValue: string, actionMeta: InputActionMeta) => void)
        | undefined;
}

const MultiselectSearch: React.FC<MultiselectCreatSearch> = (props) => {
    const {
        options,
        label,
        defaultValue,
        inputValue,
        placeholder,
        handleChange,
        handleKeyDown,
        handleInputChange,
        disabled,
        required = false,
    } = props;

    return (
        <div className="relative">
            <CreatableSelect
                styles={customStyles}
                isMulti
                isClearable
                value={defaultValue}
                inputValue={inputValue}
                onChange={handleChange}
                onInputChange={handleInputChange}
                options={options}
                placeholder={placeholder}
                isDisabled={disabled}
                menuIsOpen={false}
                onKeyDown={handleKeyDown}
            />
            {label && (
                <div className="absolute left-1 top-2 text-xs px-2">
                    {label} {required && '*'}
                </div>
            )}
        </div>
    );
};

export default MultiselectSearch;
