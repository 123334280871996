import { Button } from '@components/Atoms';
import { getPillStyle } from './Pill.style';
export interface PillOption<T> {
    value: T;
    label: string;
}
export interface PillSelectorProps<T> {
    selectedValue: any;
    onChange: (value: T) => void;
    options: PillOption<T>[];
}

function PillSelector<T>(props: PillSelectorProps<T>) {
    return (
        <div className="flex rounded-full bg-pill-bg">
            {props.options.map((opt, index) => (
                <Button
                    label={opt.label}
                    key={`${opt.value}-${index}`}
                    buttonType="pill"
                    className={getPillStyle(opt.value === props.selectedValue)}
                    onClick={() => props.onChange(opt.value)}
                />
            ))}
        </div>
    );
}

export default PillSelector;
