import { FieldArray, Formik, Form } from 'formik';
import React, { useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import * as Yup from 'yup';

import { ShareClass } from '@interfaces/Api';
import {
    InvestorLockUpEnum,
    ProductResponse,
} from '@interfaces/Api/ProductResponse';

import { useProductSubmit } from '@hooks/useProductSubmit';

import { Button } from '@components/Atoms';
import { FormCheckbox, Option } from '@components/Molecules';

import EditProductFormikButtons from './EditProductFormikButtons';
import FormikLabels from './ModelProducts';
import { ShareClassInfoItem } from './ShareClassInformatonItem';
import { SortableList } from '@components/Organisms';
import { ModalService } from '@services/ModalService';
import { useProductsState } from '@stores/Products';

const ShareClassInformation: React.FC = () => {
    const { formField } = FormikLabels;

    const initialVals: Partial<ShareClass> = {
        name: '',
        type: '',
        distributionFee: 0,
        currency: '',
        minimumInvestment: 0,
        additionalTerms: [],
        openEndedInformation: {
            investorLockUp: InvestorLockUpEnum.None,
            softLockUpDetails: undefined,
            hardLockUpDetail: undefined,
        },
        isProxy: false,
    };

    const [formValue, setFormValue] = useState<any>(initialVals);

    const { productId } = useParams();
    const { products } = useProductsState();
    const product = products.filter((e) => e._id === productId)[0];

    const [shareClassGroupList, setShareClassGroupList] = useState<Option[]>([
        { value: '', label: 'None' },
    ]);

    const numberErrorMessageShareClass =
        'Shareclass fee must only be a valid numeric value';

    useEffect(() => {
        if (productId && product) {
            setFormValue(product);
        }
    }, [product, productId]);

    const selectedValidationSchema = Yup.object().shape({
        shareClasses: Yup.array().of(
            Yup.object().shape({
                [formField.shareClasses.name.name]: Yup.string().required(
                    `${formField.shareClasses.name.requiredErrorMsg}`
                ),
                [formField.shareClasses.type.name]: Yup.string().required(
                    `${formField.shareClasses.type.requiredErrorMsg}`
                ),
                [formField.shareClasses.distributionFee.name]: Yup.number()
                    .typeError(numberErrorMessageShareClass)
                    .required(
                        `${formField.shareClasses.distributionFee.requiredErrorMsg}`
                    ),
                [formField.shareClasses.managementFee.name]:
                    Yup.number().typeError(numberErrorMessageShareClass),
                [formField.shareClasses.minimumInvestment.name]: Yup.number()
                    .typeError(numberErrorMessageShareClass)
                    .positive('Minimum fee must be more than 0')
                    .required(
                        `${formField.shareClasses.minimumInvestment.requiredErrorMsg}`
                    ),
                [formField.shareClasses.minimumSubsequentSubscriptionAmount
                    .name]: Yup.number()
                    .typeError(numberErrorMessageShareClass)
                    .positive(
                        'Minimum subsequent subscription amount must be more than 0'
                    ),
                [formField.shareClasses.currency.name]: Yup.string().required(
                    `${formField.shareClasses.currency.requiredErrorMsg}`
                ),
            })
        ),
    });

    const emptyShareClassDetails = {
        name: '',
        type: '',
        distributionFee: undefined,
        managementFee: undefined,
        currency: '',
        minimumInvestment: undefined,
        additionalTerms: [],
        openEndedInformation: {
            investorLockUp: InvestorLockUpEnum.None,
            softLockUpDetails: [
                {
                    lengthOfTimeInMonths: undefined,
                    redemptionFeePercentage: undefined,
                },
            ],
        },
    };

    const onSubmit = useProductSubmit(productId, `product-images`);

    const grdItemWrapperStyle =
        'grid md:grid-flow-col grid-cols-4 gap-1 justyfy-between';

    if (!(productId === 'new' || (productId && formValue._id))) return null;

    return (
        <div className="w-full">
            <h1 className="text-xl text-gray-500">ShareClass Information</h1>
            <Formik
                initialValues={formValue}
                onSubmit={(values, actions) => onSubmit(values, actions)}
                validationSchema={selectedValidationSchema}
                enableReinitialize
            >
                {({ values, setFieldValue }) => {
                    const containsProxyClass = values?.shareClasses?.some(
                        (shareClass: ShareClass) => shareClass.isProxy
                    );

                    return (
                        <Form>
                            <div className="mb-3">
                                <div className="mt-4">
                                    <FormCheckbox
                                        name="hideDistributionFee"
                                        actionLabel="Do not show distribution fee on the product page and on subscriptions"
                                    />
                                </div>
                                <Button
                                    label="Use Proxy ShareClass"
                                    buttonType="secondary"
                                    className="w-full my-2"
                                    onClick={() => {
                                        ModalService.getInstance()
                                            .setTitle('Add Proxy ShareClass')
                                            .setComponent(
                                                <div>
                                                    <div className="font-bold text-red-500	">
                                                        Warning!
                                                    </div>
                                                    <p>
                                                        Proxy share classes are
                                                        used for products with
                                                        no shareclasses. This
                                                        shareclass is not
                                                        displayed on the product
                                                        screen however the
                                                        information is used in
                                                        subscription process.
                                                        You will not be able to
                                                        add any other
                                                        shareclasses if there is
                                                        a Proxy
                                                    </p>
                                                    {product?.shareClasses
                                                        .length > 0 && (
                                                        <p className="font-bold mt-3">
                                                            If you want to use a
                                                            proxy, all existing
                                                            shareclasses will be
                                                            removed. Do you want
                                                            to remove all
                                                            existing
                                                            shareclasses and use
                                                            a proxy instead?
                                                        </p>
                                                    )}
                                                </div>
                                            )
                                            .setConfirmationLabel('Confirm')
                                            .setShowFooter(true)
                                            .setConfirmCallback(() => {
                                                setFieldValue('shareClasses', [
                                                    {
                                                        ...emptyShareClassDetails,
                                                        isProxy: true,
                                                    },
                                                ]);

                                                ModalService.getInstance().hideModal();
                                            })
                                            .showModal();
                                    }}
                                />
                                <h3 className="text-sm font-normal leading-8 mb-3">
                                    Please add a ShareClass
                                </h3>
                                <div className="text-logo-blue">
                                    <FieldArray
                                        name="shareClasses"
                                        render={(arrayHelpers) => (
                                            <>
                                                {values.shareClasses &&
                                                    values.shareClasses.length >
                                                        0 && (
                                                        <div>
                                                            <SortableList
                                                                onSorted={(
                                                                    items
                                                                ) =>
                                                                    setFieldValue(
                                                                        'shareClasses',
                                                                        items,
                                                                        false
                                                                    )
                                                                }
                                                                handleClassName="relative -inset-x-5 inset-y-10"
                                                                listItems={
                                                                    values.shareClasses
                                                                }
                                                                listElements={values.shareClasses.map(
                                                                    (
                                                                        shareClass: ShareClass,
                                                                        i: number
                                                                    ) => (
                                                                        <ShareClassInfoItem
                                                                            key={
                                                                                shareClass._id
                                                                            }
                                                                            product={
                                                                                product
                                                                            }
                                                                            shareClass={
                                                                                shareClass
                                                                            }
                                                                            grdItemWrapperStyle={
                                                                                grdItemWrapperStyle
                                                                            }
                                                                            shareClassGroupList={
                                                                                shareClassGroupList
                                                                            }
                                                                            setShareClassGroupList={
                                                                                setShareClassGroupList
                                                                            }
                                                                            formField={
                                                                                formField
                                                                            }
                                                                            i={
                                                                                i
                                                                            }
                                                                            values={
                                                                                values
                                                                            }
                                                                            arrayHelpers={
                                                                                arrayHelpers
                                                                            }
                                                                        />
                                                                    )
                                                                )}
                                                            />
                                                        </div>
                                                    )}
                                                <div>
                                                    {!containsProxyClass && (
                                                        <Button
                                                            label="+ Add ShareClass"
                                                            buttonType="secondary"
                                                            className="w-full my-2"
                                                            onClick={() => {
                                                                arrayHelpers.push(
                                                                    emptyShareClassDetails
                                                                );
                                                            }}
                                                        />
                                                    )}
                                                    <h4 className="w-full flex py-4 mb-4 justify-end text-xs text-brand-navy">
                                                        <strong>
                                                            <sup className="text-blue-500 text-sm">
                                                                *
                                                            </sup>
                                                            Distribution Fee for
                                                            Intermediary
                                                        </strong>
                                                    </h4>
                                                </div>
                                            </>
                                        )}
                                    />
                                </div>
                                <EditProductFormikButtons
                                    productId={productId}
                                    isReadOnly={false}
                                    pathPrevious="product-eligibility-exclusion"
                                    pathNext="product-images"
                                />
                            </div>
                        </Form>
                    );
                }}
            </Formik>
        </div>
    );
};

export default ShareClassInformation;
