import { Button, Icons } from '@components/Atoms';

interface DataReviewInterface {
    onDelete?: () => void;
    onEdit?: () => void;
    children?: JSX.Element | JSX.Element[];
    bgColor?: string;
}

export const DataReview = (props: DataReviewInterface) => (
    <div
        className={`border border-gray-300 rounded-lg w-full px-3 py-2 relative mb-5 ${props.bgColor}`}
    >
        <div className="flex justify-end">
            {props.onEdit && (
                <div className="mr-2">
                    <Button
                        buttonType="secondary"
                        onClick={props.onEdit}
                        size="small"
                    >
                        <span className="sr-only">Close menu</span>
                        <Icons name="PencilIcon" size="small" strokeWidth="2" />
                    </Button>
                </div>
            )}
            {props.onDelete && (
                <div className="ml-2">
                    <Button
                        buttonType="secondary"
                        onClick={props.onDelete}
                        size="small"
                    >
                        <span className="sr-only">Close menu</span>
                        <Icons name="XMarkIcon" size="small" strokeWidth="2" />
                    </Button>
                </div>
            )}
        </div>

        <div className="mr-2 pb-2">{props.children}</div>
    </div>
);
