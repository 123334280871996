import {
    ChangePasswordRequestBody,
    CurrentUser,
    CurrentUserResponse,
    ForgotPasswordRequestBody,
    LoginResponse,
    SetPasswordRequestBody,
} from '@interfaces/Api';

import { notifyError } from '@helpers/toastrHelper';

import { BaseUrl } from '@helpers/Environment';
import { AuthorizationTokens } from '@interfaces/Api/AuthorizationTokens';
import { VerifyRequestBody } from '@interfaces/Api/VerifyRequestBody';
import { Http } from '../libs/http';
import { getLocalStorageAuthTokens } from '@helpers/auth.helper';

const API_AUTHENTICATION = `${BaseUrl}/authentication`;

export const authenticationLogin = async (
    email: string,
    password: string
): Promise<LoginResponse> => {
    try {
        const response = await Http.post<LoginResponse>(
            `${API_AUTHENTICATION}/login`,
            {
                email,
                password,
            }
        );
        return response;
    } catch (err: any) {
        if (err?.status === 401) {
            throw new Error('Incorrect username/password');
        }

        if ([500, 503].includes(err?.status)) {
            notifyError(
                'Could not connect to the service, please ensure the service is running.'
            );
        }

        throw err;
    }
};

export const authenticationVerify = async (
    verifyRequestBody: VerifyRequestBody
): Promise<AuthorizationTokens | undefined> => {
    try {
        const response = await Http.post<AuthorizationTokens>(
            `${API_AUTHENTICATION}/verify`,
            {
                email: verifyRequestBody.email,
                password: verifyRequestBody.password,
                mfaType: verifyRequestBody.mfaType,
                mfaCode: verifyRequestBody.mfaCode,
            }
        );
        return response;
    } catch (err: any) {
        if (err?.status === 401) {
            throw new Error('Incorrect username/password');
        }

        if ([500, 503].includes(err?.status)) {
            notifyError(
                'Could not connect to the service, please ensure the service is running.'
            );
        }

        throw err;
    }
};

export const authenticationRefreshTokensPost = async (refreshToken: string) => {
    const response = await fetch(`${API_AUTHENTICATION}/refresh-tokens`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
        },
        body: JSON.stringify({
            refreshToken,
        }),
    });
    return response;
};

export const authenticationVerifySetPasswordPost = async (
    body: SetPasswordRequestBody
): Promise<any> => {
    return Http.post(`${API_AUTHENTICATION}/set-password`, body);
};

export const authenticationUserPasswordTokenGet = async (
    passwordToken: string
): Promise<CurrentUser> => {
    return Http.get(
        `${API_AUTHENTICATION}/user?passwordToken=${passwordToken}`
    );
};

export const authenticationCurrentUserGet =
    async (): Promise<CurrentUserResponse> => {
        return Http.get(`${API_AUTHENTICATION}/current-user`);
    };

export const authenticationChangePasswordPost = async (body: {
    password: string;
    confirmPassword: string;
}): Promise<void> => {
    return Http.post(`${API_AUTHENTICATION}/update-password`, body);
};

export const authenticationResendPasswordEmailPost = async (
    passwordToken: string
) => {
    return Http.post(`${API_AUTHENTICATION}/resend-password-email`, {
        passwordToken,
    });
};

export const authenticationForgotPassword = async (
    body: ForgotPasswordRequestBody
): Promise<any> => {
    return Http.post(`${API_AUTHENTICATION}/forgot-password`, body);
};

export const authenticationLogout = async (): Promise<void> => {
    // get the refresh token from local storage
    const refreshToken = getLocalStorageAuthTokens()?.refresh?.token;

    return Http.post(`${API_AUTHENTICATION}/logout`, { refreshToken });
};
