import { BaseUrl } from '@helpers/Environment';

import { Http } from '../libs/http';
import { TaskApprovalRequest } from '@interfaces/Api/TaskApprovalRequest';
import { EventTypeEnum } from '@interfaces/eventType.enum';

const API_EVENT_TASKS_URL = `${BaseUrl}/tasks`;

export const taskApprovalPost = async ({
    taskId,
    taskApprovalData,
}: {
    taskId: string;
    taskApprovalData: TaskApprovalRequest;
}): Promise<TaskApprovalRequest> =>
    Http.post(`${API_EVENT_TASKS_URL}/${taskId}/approve`, taskApprovalData);
