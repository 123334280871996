import { ReviewFrame } from '@components/Molecules';
import {
    ProspectiveInvestorResponse,
    ProspectiveInvestorStatusEnumLabels,
} from '@interfaces/Api/ProspectiveInvestorResponse';

interface ManualReviewSectionProps {
    prospectiveInvestor: ProspectiveInvestorResponse;
}

const ManualReviewSection: React.FC<ManualReviewSectionProps> = ({
    prospectiveInvestor,
}) => {
    const previousStatus =
        prospectiveInvestor.statusLogs?.[
            prospectiveInvestor.statusLogs.length - 2
        ];

    const currentStatus =
        prospectiveInvestor.statusLogs?.[
            prospectiveInvestor.statusLogs.length - 1
        ];

    return (
        <ReviewFrame
            title="Manual Review"
            expanded={true}
            hideToggleButton={true}
        >
            <p className="p-2">
                This application was previously in status{' '}
                <span className="font-bold">
                    {
                        ProspectiveInvestorStatusEnumLabels[
                            previousStatus?.status
                        ]
                    }
                </span>{' '}
                and was changed to status{' '}
                <span className="font-bold">
                    {ProspectiveInvestorStatusEnumLabels[currentStatus.status]}
                </span>{' '}
                on{' '}
                <span className="font-bold">
                    {new Date(currentStatus.date).toLocaleString()}
                </span>{' '}
                with the following reason:
            </p>
            <div className="border rounded p-5 whitespace-pre-line">
                {currentStatus.reason}
            </div>
        </ReviewFrame>
    );
};

export default ManualReviewSection;
