import React from 'react';
import { useTranslation } from 'react-i18next';
import { ActionPoint } from '@interfaces/Api/Dashboard';
import {
    dateShortestFormatter,
    dateShortFormatter,
} from '@helpers/Date.helper';
import { NoticeType } from '@interfaces/Api';
import { Icons } from '@components/Atoms';
import { getFormattedCurrency } from '@helpers/isoCurrencies';

interface EventSummaryProps {
    actionPoints: ActionPoint[];
    currencyCode: string;
}

const EventSummary: React.FC<EventSummaryProps> = ({
    actionPoints,
    currencyCode,
}) => {
    const { t } = useTranslation();
    const capitalCalls: ActionPoint[] = [];
    const distributions: ActionPoint[] = [];

    actionPoints.forEach((actionPoint, i) => {
        actionPoint.noticeType === NoticeType.CapitalCall
            ? capitalCalls.push(actionPoint)
            : distributions.push(actionPoint);
    });

    const renderEvent = (event: ActionPoint, index: number) => (
        <div
            className="grid grid-rows-2 justify-items-start w-full pt-2"
            key={index}
        >
            <div className="flex">
                <div className="mr-2">
                    <Icons name="CalendarDaysIcon" size="medium" />
                </div>
                <span>{event.productName}</span>
            </div>
            <div className="flex justify-between w-full">
                <div className="pl-8">
                    {dateShortestFormatter(event.dueDate!)}
                </div>
                <div className="flex items-end">
                    <p>
                        {getFormattedCurrency(
                            event.amount,
                            currencyCode,
                            undefined,
                            'code'
                        )}
                    </p>
                </div>
            </div>
        </div>
    );

    return (
        <section className="mb-10">
            <div className="border p-4 rounded-xl">
                <h2 className="text-xl text-logo-blue font-bold m-4 mt-2">
                    {t('client.portfolio.events')}
                </h2>
                <div className="grid grid-cols-2 gap-x-8 gap-y-4 mx-4">
                    <div>
                        <h3 className="text-lg mb-4 font-medium">
                            {t('client.portfolio.open_capital_calls')} ({0})
                        </h3>
                    </div>
                    <div>
                        <h3 className="text-lg mb-4 font-medium">
                            {t('client.portfolio.recent_distributions')} ({0})
                        </h3>
                    </div>
                    <div>
                        <h3 className="text-lg mb-4 font-medium">
                            {t('client.portfolio.projected_capital_calls')} ({capitalCalls.length})
                        </h3>
                        <div className="grid gap-4">
                            {capitalCalls.map(renderEvent)}
                        </div>
                    </div>
                    <div>
                        <h3 className="text-lg mb-4 font-medium">
                            {t('client.portfolio.projected_distributions')} ({distributions.length})
                        </h3>
                        <div className="grid gap-4">
                            {distributions.map(renderEvent)}
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default EventSummary;
