import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { EndInvestorProfileResponse } from '@interfaces/Api';

import { useEndInvestorProfileState } from '@contexts/EndInvestorProfileContext';
import { useAuthState } from '@contexts/AuthContext';

export const useEndInvestorProfile = () => {
    const { endInvestorProfileId: endInvestorProfileIdParam } = useParams();
    const { getEndInvestorProfile } = useEndInvestorProfileState();
    const { currentUser } = useAuthState();

    const endInvestorProfileId =
        (currentUser?.user.endInvestorProfileIds?.length === 1 &&
            currentUser?.user.endInvestorProfileIds?.[0]) ||
        endInvestorProfileIdParam;

    const [isEndInvestorProfileLoading, setIsEndInvestorProfileLoading] =
        useState(true);
    const [endInvestorProfile, setEndInvestorProfile] =
        useState<EndInvestorProfileResponse>();

    useEffect(() => {
        async function RunAsync() {
            setIsEndInvestorProfileLoading(true);

            if (endInvestorProfileId) {
                const endInvestorProfile = await getEndInvestorProfile(
                    endInvestorProfileId
                );
                if (endInvestorProfile) {
                    setEndInvestorProfile(endInvestorProfile);
                }
            } else {
                console.error(
                    '[ClientSummary.tsx], the component cannot find an endInvestorProfileId to load information in this component'
                );
            }
            setIsEndInvestorProfileLoading(false);
        }

        RunAsync();
    }, [endInvestorProfileId]);

    return {
        isEndInvestorProfileLoading,
        endInvestorProfile,
        endInvestorProfileId,
    };
};
