import {
    ErrorMessage,
    Field,
    FieldInputProps,
    FieldMetaProps,
    FormikBag,
} from 'formik';
import { CheckboxList, CheckboxVariant } from '../CheckboxList';

interface CheckboxListOption {
    value: boolean;
    label: string;
    key: string;
    image?: string;
    group?: string;
}

interface FormCheckboxListProps {
    name: string;
    checkboxLabel?: string;
    disabled?: boolean;
    variant?: CheckboxVariant;
    renderExtra?: (option: CheckboxListOption) => React.ReactNode;
    defaultChecked?: boolean;
}

const FormCheckboxList: React.FC<FormCheckboxListProps> = (props) => (
    <div>
        <Field name={props.name} type="checkbox">
            {({
                field,
                form,
            }: {
                field: FieldInputProps<CheckboxListOption[]>;
                meta: FieldMetaProps<string>;
                form: FormikBag<FormCheckboxListProps, any>;
            }) => {
                const handleCheckboxChange = (
                    options: CheckboxListOption[]
                ) => {
                    form.setFieldValue(props.name, options);
                };

                const onSelectAllChanged = (
                    options: CheckboxListOption[],
                    selectAll: boolean
                ) => {
                    const allOptions = field.value.map((option) => {
                        if (option.group === options[0].group) {
                            return {
                                ...option,
                                value: selectAll,
                            };
                        }
                        return option;
                    });
                    form.setFieldValue(props.name, allOptions);
                };

                return (
                    <>
                        <CheckboxList
                            name={props.name}
                            disabled={props.disabled}
                            checkboxLabel={props.checkboxLabel}
                            onSelectAllChanged={onSelectAllChanged}
                            options={field.value}
                            variant={props.variant}
                            onSelectedOptionsChange={handleCheckboxChange}
                            renderExtra={props.renderExtra}
                            defaultChecked={props.defaultChecked}
                        />
                    </>
                );
            }}
        </Field>
        <div className="flex content-start text-red-500 text-sm">
            <ErrorMessage
                name={props.name}
                render={(msg) => <div>{msg}</div>}
            />
        </div>
    </div>
);

export default FormCheckboxList;
