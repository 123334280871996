import { useState, useEffect } from 'react';

import { Icons, SelectBox } from '@components/Atoms';
import { FormFieldWrapper } from '@components/Molecules';

import { IntermediaryResponse } from '@interfaces/Api';

interface IntermediariesSelectProps {
    intermediaries: IntermediaryResponse[];
    selectIntermediaryId: (val: string) => void;
}

export const IntermediariesSelect: React.FC<IntermediariesSelectProps> = ({
    intermediaries,
    selectIntermediaryId,
}) => {
    const intermediaries_selection_options = intermediaries.map(
        (item: IntermediaryResponse) => ({
            label: item.name || '',
            value: item._id || '',
        })
    );

    const handleIntermediariesOnChange = (
        e: React.ChangeEvent<HTMLSelectElement>
    ) => {
        const selectedIntermediaryId = e.target.value;
        const intermediaryId = intermediaries.find(
            (intermediary: any) => intermediary._id === selectedIntermediaryId
        )?._id;

        if (intermediaryId) selectIntermediaryId(intermediaryId);
    };

    return (
        <div className="relative mb-8 md:w-2/3 lg:w-1/3 mr-4">
            <FormFieldWrapper
                label="Intermediaries selection"
                rightIcon={<Icons name="ChevronDown" size="small" />}
            >
                <SelectBox
                    name="intermediaries"
                    optionsData={intermediaries_selection_options}
                    onChange={handleIntermediariesOnChange}
                />
            </FormFieldWrapper>
        </div>
    );
};
