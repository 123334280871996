import { useState } from 'react';
import { FieldArray } from 'formik';

import { Nationality } from '@interfaces/Api';

import { Button } from '@components/Atoms';
import {
    DataReview,
    InlineFormFrame,
    FormInputBox,
    FormSelect,
} from '@components/Molecules';

import {
    getCountryDisplayName,
    isoCountriesOptions,
} from '@helpers/isoCountries';
import { useTranslation } from 'react-i18next';

export const NationalitiesSelection = () => {
    const { t } = useTranslation();
    const [showAddNationalityForm, setShowAddNationalityForm] =
        useState<boolean>(false);

    const emptyNationality: Nationality = {
        countryCode: '',
        nationalIdNumber: '',
    };

    return (
        <>
            <h3 className="text-xl font-normal leading-8 my-2 mt-8">
                {t('client.registration.nationalities.title')}
            </h3>
            <p className="text-base py-1">
                {t('client.registration.nationalities.instruction')}
            </p>
            <FieldArray
                name="nationalities"
                render={(arrayHelpers) => (
                    <div>
                        {arrayHelpers.form.values?.nationalities?.map(
                            (nationality: any, i: number, arr: any) =>
                                showAddNationalityForm &&
                                i === arr.length - 1 ? (
                                    <InlineFormFrame
                                        key={i.toString()}
                                        primaryAction={t('client.registration.nationalities.save_nationality')}
                                        onPrimaryAction={() =>
                                            setShowAddNationalityForm(false)
                                        }
                                        secondaryAction={t('ui.controls.cancel')}
                                        onSecondaryAction={() =>
                                            arrayHelpers.remove(i)
                                        }
                                    >
                                        <FormSelect
                                            name={`nationalities[${i}].countryCode`}
                                            label={t('client.registration.nationalities.country') + '*'}
                                            optionsData={isoCountriesOptions}
                                        />
                                        <FormInputBox
                                            name={`nationalities[${i}].nationalIdNumber`}
                                            label={t('client.registration.nationalities.national_id')}
                                            placeholder={t('client.registration.nationalities.national_id')}
                                        />
                                    </InlineFormFrame>
                                ) : (
                                    <DataReview
                                        key={i.toString()}
                                        onDelete={() => arrayHelpers.remove(i)}
                                    >
                                        <p className="text-sm">
                                            {getCountryDisplayName(
                                                nationality.countryCode
                                            )}
                                        </p>
                                        <p className="text-sm">
                                            {nationality.nationalIdNumber}
                                        </p>
                                    </DataReview>
                                )
                        )}

                        <Button
                            label={`+ ${t('client.registration.nationalities.add_nationality')}`}
                            type="button"
                            buttonType="secondary"
                            className="w-full my-2"
                            onClick={() => {
                                arrayHelpers.push(emptyNationality);
                                setShowAddNationalityForm(true);
                            }}
                        />
                    </div>
                )}
            />
        </>
    );
};
