import { useSystemUsers } from '@stores/SystemUsers/useSystemUsers';
import { dateShortFormatter } from '@helpers/Date.helper';
import { getSystemUserFullName } from '@helpers/SystemUser.helper';
import {
    DocumentTemplate,
    DocumentTemplateRow,
} from '@interfaces/Api/DocumentTemplate';

export const GetDocumentTemplateLibraryRows = ({
    documents,
}: {
    documents: DocumentTemplate[];
}): DocumentTemplateRow[] => {
    const { systemUsers } = useSystemUsers();
    return documents.map((document) => {
        const createdBy = systemUsers.find(
            (user) => user._id === document.createdBy
        );
        const updatedBy = systemUsers.find(
            (user) => user._id === document.updatedBy
        );
        return {
            ...document,
            createdDate: document?.createdDate
                ? dateShortFormatter(document?.createdDate)
                : '',
            createdBy: createdBy ? getSystemUserFullName(createdBy) : '',
            updatedBy: updatedBy ? getSystemUserFullName(updatedBy) : '',
            updatedDate: document?.updatedDate
                ? dateShortFormatter(document?.updatedDate)
                : '',
        };
    });
};
