import { useState, useEffect } from 'react';
import { useParams, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { AuthRoleEnum } from '@interfaces/Api';

import { useAuthState } from '@contexts/AuthContext';
import { useEndInvestorProfileState } from '@contexts/EndInvestorProfileContext';
import { useSubscriptionTemplateState } from '@contexts/SubscriptionTemplatesContext';

import { useProductsStore } from '@stores/Products/Products.store';

import { getEndInvestorName } from '@helpers/EndInvestorHelper';
import { useSubscriptions } from '@stores/Subscriptions/useSubscriptions';
import { useSubscription } from '@stores/Subscriptions/useSubscription';

export const useBreadCrumbs = () => {
    const { t } = useTranslation();
    const params = useParams();
    const { pathname } = useLocation();
    const { endInvestorProfiles } = useEndInvestorProfileState();

    const { subscriptionTemplate } = useSubscriptionTemplateState();
    const { hasRoles, currentUser } = useAuthState();
    const { products } = useProductsStore();

    const [breadCrumbs, setBreadCrumbs] = useState<string[]>([]);
    const [pathId, setPathId] = useState<string>();

    const isSubscriptionsPath = pathname.includes('subscriptions');
    const { subscriptionId } = params;
    const isSubscriptionTemplatePath = pathname.includes(
        'subscription-templates'
    );

    const { subscriptions } = useSubscriptions();
    const { subscription } = useSubscription(subscriptionId);

    const getInvestorProfile = (id?: string) =>
        endInvestorProfiles.find((a) => a._id === id);

    const updateBreadcrumbsWithLastPathName = (list: any) => {
        if (pathname.split('/')?.[3]) {
            list.push(pathname.split('/')[3].replaceAll('-', ' '));
        }
    };

    const endInvestorProfileId = pathname.includes('clients')
        ? pathname.split('/')?.[2]
        : subscription.endInvestorProfileId ||
          subscriptionTemplate.endInvestorProfileId;

    const endInvestorProfile = getInvestorProfile(endInvestorProfileId);

    useEffect(() => {
        const breadCrumbs: Array<any> = [];

        if (
            isSubscriptionsPath &&
            !hasRoles([AuthRoleEnum.fundAdmin]) &&
            !hasRoles([AuthRoleEnum.endInvestor])
        ) {
            breadCrumbs.push(t('navbar.subscriptions'));

            if (endInvestorProfile) {
                breadCrumbs.push(getEndInvestorName(endInvestorProfile));
            }
            setPathId('clients/' + String(subscription.endInvestorProfileId));
            updateBreadcrumbsWithLastPathName(breadCrumbs);
        } else if (isSubscriptionsPath && hasRoles([AuthRoleEnum.fundAdmin])) {
            breadCrumbs.push(
                t('navbar.subscriptions'),
                getEndInvestorName(endInvestorProfile)
            );
            setPathId('subscriptions/');
        } else if (
            isSubscriptionTemplatePath &&
            hasRoles([AuthRoleEnum.superUser])
        ) {
            breadCrumbs.push(
                t('client.pill_selector.subscription_templates'),
                getEndInvestorName(endInvestorProfile)
            );

            updateBreadcrumbsWithLastPathName(breadCrumbs);

            setPathId(
                'clients/' + String(subscriptionTemplate.endInvestorProfileId)
            );
        } else if (
            (isSubscriptionTemplatePath || isSubscriptionsPath) &&
            hasRoles([AuthRoleEnum.endInvestor])
        ) {
            const path = isSubscriptionsPath
                ? 'subscriptions'
                : 'subscription-templates';
            breadCrumbs.push(path, currentUser?.user?.firstName);
            updateBreadcrumbsWithLastPathName(breadCrumbs);
            setPathId('portfolio');
        }

        const data = breadCrumbs.map((item) => item?.replaceAll('-', ' '));
        setBreadCrumbs(data);
    }, [
        pathname,
        endInvestorProfiles,
        products,
        subscription,
        subscriptions,
        subscriptionTemplate.endInvestorProfileId,
        hasRoles,
        params,
        isSubscriptionsPath,
        isSubscriptionTemplatePath,
    ]);

    return {
        breadCrumbs,
        isSubscriptionsPath,
        isSubscriptionTemplatePath,
        pathId,
    };
};
