import { FC, useCallback, useMemo, useState } from 'react';
import {
    ChanelOfCommunications,
    SubscriptionReport as Report,
    StatementFormat,
    StatementPeriodicity,
} from 'common';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import { Formik, Form } from 'formik';
import { Document } from 'common';
import { SubscriptionOutletContext } from '@contexts/OutletContext';
import { useOutletContext } from 'react-router-dom';
import {
    DownloadLink,
    FormCheckbox,
    FormInputBox,
    FormSelect,
} from '@components/Molecules';
import { Button } from '@components/Atoms';
import { FileUploadAsync } from '@components/Organisms';
import { notifyError } from '@helpers/toastrHelper';
import { SubscriptionStatusEnum } from '@interfaces/Api';
import { useSubscriptionPageContext } from '@pages/Subscriptions/EditSubscription';
import SubscriptionsFormikSubmitBtns from './SubscriptionsFormikSubmitBtns';

export const SubscriptionReport: FC = () => {
    const { t } = useTranslation();
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const { data, updateReport } =
        useOutletContext<SubscriptionOutletContext>();
    const { goToNextStep } = useSubscriptionPageContext();
    const isSubscription = data?.status;

    const areInputsDisabled = data?.status
        ? data?.status !== SubscriptionStatusEnum.inProgress
        : false;

    const handleSubmit = useCallback(
        async (values) => {
            setIsLoading(true);

            try {
                await updateReport({
                    subscriptionId: data._id,
                    body: values,
                });

                goToNextStep();
            } catch (e) {
                notifyError(
                    'There is an error on updating subscription report'
                );
            } finally {
                setIsLoading(false);
            }
        },
        [data._id, goToNextStep, updateReport]
    );

    const validationSchema = useMemo(
        () =>
            Yup.object().shape({
                statementPeriodicity: Yup.string().required(),
                statementFormat: Yup.string().required(),
                chanelOfCommunications: Yup.string().required(),
            }),
        []
    );

    const simplePdf: Document = {
        fileName: 'HgFusion_TAForm_Reporting_Appendix7.pdf',
        url: '/assets/HgFusion_TAForm_Reporting_Appendix7.pdf',
        label: 'HgFusion_TAForm_Reporting_Appendix7.pdf',
    };

    const defaultReportValue = {
        statementPeriodicity: '',
        statementFormat: '',
        chanelOfCommunications: '',
        sendingOrdersViaSwift: false,
    };

    const statementPeriodicityOptions = useMemo(
        () => [
            {
                label: t('subscriptions.add.subscription_report.add.monthly'),
                value: StatementPeriodicity.Monthly,
            },
            {
                label: t('subscriptions.add.subscription_report.add.quarterly'),
                value: StatementPeriodicity.Quarterly,
            },
            {
                label: t(
                    'subscriptions.add.subscription_report.add.halfYearly'
                ),
                value: StatementPeriodicity.HalfYearly,
            },
            {
                label: t('subscriptions.add.subscription_report.add.yearly'),
                value: StatementPeriodicity.Yearly,
            },
        ],
        [t]
    );

    const statementFormatOptions = useMemo(
        () => [
            {
                label: t(
                    'subscriptions.add.subscription_report.add.public_statement'
                ),
                value: StatementFormat.PeriodicStatement,
            },
            {
                label: t(
                    'subscriptions.add.subscription_report.add.consolidated_statement'
                ),
                value: StatementFormat.ConsolidatedStatement,
            },
        ],
        [t]
    );

    const channelOfCommunications = useMemo(
        () => [
            {
                label: t('subscriptions.add.subscription_report.add.post'),
                value: ChanelOfCommunications.Post,
            },
            {
                label: t('subscriptions.add.subscription_report.add.fax'),
                value: ChanelOfCommunications.Fax,
            },
            {
                label: t(
                    'subscriptions.add.subscription_report.add.swift_mt535'
                ),
                value: ChanelOfCommunications.SwiftMT535,
            },
            {
                label: t(
                    'subscriptions.add.subscription_report.add.swift_mt536'
                ),
                value: ChanelOfCommunications.SwiftMT536,
            },
            {
                label: t('subscriptions.add.subscription_report.add.swift_xml'),
                value: ChanelOfCommunications.SwiftXML,
            },
            {
                label: t(
                    'subscriptions.add.subscription_report.add.email_statement'
                ),
                value: ChanelOfCommunications.emailStatement,
            },
            {
                label: t(
                    'subscriptions.add.subscription_report.add.email_contract_notes'
                ),
                value: ChanelOfCommunications.emailContractNotes,
            },
        ],
        [t]
    );

    return (
        <div className="w-full overflow-y-auto">
            <div className="flex flex-col md:flex-row justify-between items-start w-full md:w-11/12 xl:w-4/5">
                <div className="w-4/12 sm:w-6/12 md:w-6/12 lg:w-7/12 xl:w-7/12 2xl:w-8/12">
                    <h1 className="text-3xl text-logo-blue ">
                        {t('subscriptions.add.subscription_report.add.title')}
                    </h1>
                    <p className="text-base py-3">
                        {t(
                            'subscriptions.add.subscription_report.add.instruction'
                        )}
                    </p>
                </div>
            </div>
            <div className="w-full md:w-11/12 xl:w-4/5">
                <Formik
                    initialValues={data.report || defaultReportValue}
                    onSubmit={handleSubmit}
                    validationSchema={validationSchema}
                    enableReinitialize
                    validateOnMount
                >
                    {(formikProps) => (
                        <Form>
                            <FormSelect
                                name="statementPeriodicity"
                                label={t(
                                    'subscriptions.add.subscription_report.add.statement_periodicity'
                                )}
                                placeholder={t(
                                    'subscriptions.add.subscription_report.add.statement_periodicity'
                                )}
                                optionsData={statementPeriodicityOptions}
                                disabled={areInputsDisabled}
                            />
                            <FormSelect
                                name="statementFormat"
                                label={t(
                                    'subscriptions.add.subscription_report.add.statement_format'
                                )}
                                placeholder={t(
                                    'subscriptions.add.subscription_report.add.statement_format'
                                )}
                                optionsData={statementFormatOptions}
                                disabled={areInputsDisabled}
                            />
                            <FormSelect
                                name="chanelOfCommunications"
                                label={t(
                                    'subscriptions.add.subscription_report.add.channel_of_communication'
                                )}
                                placeholder={t(
                                    'subscriptions.add.subscription_report.add.channel_of_communication'
                                )}
                                optionsData={channelOfCommunications}
                                disabled={areInputsDisabled}
                            />
                            {formikProps.values.chanelOfCommunications ===
                                ChanelOfCommunications.Fax && (
                                <FormInputBox
                                    name="fax"
                                    label={t(
                                        'subscriptions.add.subscription_report.add.fax'
                                    )}
                                    placeholder={t(
                                        'subscriptions.add.subscription_report.add.fax'
                                    )}
                                    disabled={areInputsDisabled}
                                />
                            )}
                            {[
                                ChanelOfCommunications.SwiftMT535,
                                ChanelOfCommunications.SwiftMT536,
                                ChanelOfCommunications.SwiftXML,
                            ].includes(
                                formikProps.values
                                    .chanelOfCommunications as ChanelOfCommunications
                            ) && (
                                <FormInputBox
                                    name="swiftCode"
                                    label={t(
                                        'subscriptions.add.subscription_report.add.swift_code'
                                    )}
                                    placeholder={t(
                                        'subscriptions.add.subscription_report.add.please_provide_swift_code_below'
                                    )}
                                    disabled={areInputsDisabled}
                                />
                            )}
                            {formikProps.values.chanelOfCommunications ===
                                ChanelOfCommunications.emailStatement && (
                                <FormInputBox
                                    name="statementEmail"
                                    label={t(
                                        'subscriptions.add.subscription_report.add.email_statement'
                                    )}
                                    placeholder={t(
                                        'subscriptions.add.subscription_report.add.please_provide_email_below'
                                    )}
                                    disabled={areInputsDisabled}
                                />
                            )}
                            {formikProps.values.chanelOfCommunications ===
                                ChanelOfCommunications.emailContractNotes && (
                                <FormInputBox
                                    name="contractNotesEmail"
                                    label={t(
                                        'subscriptions.add.subscription_report.add.email_contract_notes'
                                    )}
                                    placeholder={t(
                                        'subscriptions.add.subscription_report.add.please_provide_email_below'
                                    )}
                                    disabled={areInputsDisabled}
                                />
                            )}
                            <FormCheckbox
                                name="sendingOrdersViaSwift"
                                actionLabel={t(
                                    'subscriptions.add.subscription_report.add.will_you_be_sending_orders_via_swift'
                                )}
                                disabled={areInputsDisabled}
                            />
                            <div className="text-sm mb-4">
                                {t(
                                    'subscriptions.add.subscription_report.add.sending_orders_via_swift_instruction'
                                )}
                            </div>
                            {formikProps.values.sendingOrdersViaSwift && (
                                <div>
                                    <p>
                                        Download the sample PDF file
                                        demonstrating the required format:
                                    </p>

                                    <div className="mb-4">
                                        <DownloadLink document={simplePdf} />
                                    </div>
                                    <FileUploadAsync
                                        name="swiftForm"
                                        hasLabel={false}
                                        maxFiles={1}
                                    />
                                </div>
                            )}
                            <SubscriptionsFormikSubmitBtns
                                isSubscriptionReadOnly={
                                    data.status !==
                                    SubscriptionStatusEnum.inProgress
                                }
                                saveLabel={
                                    isSubscription
                                        ? undefined
                                        : t('ui.controls.save')
                                }
                                disabled={
                                    !formikProps.isValid ||
                                    isLoading ||
                                    areInputsDisabled
                                }
                            />
                        </Form>
                    )}
                </Formik>
            </div>
        </div>
    );
};
