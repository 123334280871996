import { Document } from './Document';
import { BusinessEntityDetails } from './BusinessEntityDetails';
import { IndividualDetails } from './IndividualDetails';
import { SystemUserResponse } from './SystemUserResponse';
import { loginAccountChoiceType } from './LoginAccountChoice';

/**
 *
 * @export
 * @interface EndInvestorProfileRequestBody
 */

export enum EndInvestorType {
    individual = 'individual',
    businessEntity = 'businessEntity',
    nomineeEntity = 'nomineeEntity',
}

export interface EndInvestorProfileRequestBody {
    /**
     *
     * @type {string}
     * @memberof EndInvestorProfileRequestBody
     */
    adviserSystemUserId?: string;
    /**
     *
     * @type {string}
     * @memberof EndInvestorProfileRequestBody
     */
    intermediaryId: string;
    /**
     *
     * @type {string}
     * @memberof EndInvestorProfileRequestBody
     */
    bookingCentreIds?: string[];
    /**
     * Service level
     * @type {string}
     * @memberof EndInvestorProfileRequestBody
     */
    serviceLevelType?: EndInvestorProfileRequestBody.ServiceLevelTypeEnum;
    /**
     * Subscription process
     * @type {string}
     * @memberof EndInvestorProfileRequestBody
     */
    subscriptionProcessType?: EndInvestorProfileRequestBody.SubscriptionProcessTypeEnum;
    /**
     * Alpha-2 two letter country code
     * @type {string}
     * @memberof EndInvestorProfileRequestBody
     */
    onboardingJurisdictionCountryCode?: string;
    /**
     *
     * @type {string}
     * @memberof EndInvestorProfileRequestBody
     */
    clientType?: string;
    /**
     *
     * @type {boolean}
     * @memberof EndInvestorProfileRequestBody
     */
    isKycCompliant?: boolean;
    /**
     *
     * @type {Array<Document>}
     * @memberof EndInvestorProfileRequestBody
     */
    kycDocuments?: Array<Document>;
    /**
     *
     * @type {string}
     * @memberof EndInvestorProfileRequestBody
     */
    endInvestorType?: EndInvestorType;
    /**
     *
     * @type {IndividualDetails}
     * @memberof EndInvestorProfileRequestBody
     */
    nomineeEndInvestorProfileId?: string;
    /**
     *
     * @type {IndividualDetails}
     * @memberof EndInvestorProfileRequestBody
     */
    individualDetails?: IndividualDetails;
    /**
     *
     * @type {BusinessEntityDetails}
     * @memberof EndInvestorProfileRequestBody
     */
    businessEntityDetails?: BusinessEntityDetails;
    /**
     *
     * @type {Partial<SystemUserResponse>}
     * @memberof EndInvestorProfileRequestBody
     */
    systemUsers?: Partial<SystemUserResponse>[];
    /**
     *
     * @type {loginAccountChoiceType}
     * @memberof EndInvestorProfileRequestBody
     */
    loginAccountChoice?: loginAccountChoiceType;
}

/**
 * @export
 * @namespace EndInvestorProfileRequestBody
 */

export namespace EndInvestorProfileRequestBody {
    /**
     * @export
     * @enum {string}
     */
    export enum ServiceLevelTypeEnum {
        ExecutionOnly = 'executionOnly',
        Advisory = 'advisory',
    }
    /**
     * @export
     * @enum {string}
     */
    export enum SubscriptionProcessTypeEnum {
        Direct = 'direct',
        OnBehalf = 'onBehalf',
        Nominee = 'nominee',
    }
}
