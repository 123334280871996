import {
    useIntermediariesState,
    useIntermediariesActions,
} from '@stores/Intermediaries';
import { useMountEffect } from '@hooks/useMountEffect';

export const useIntermediaries = () => {
    const { intermediaries, isLoading } = useIntermediariesState();
    const { fetchIntermediaries } = useIntermediariesActions();

    useMountEffect(() => {
        const loadData = async () => {
            await fetchIntermediaries();
        };

        loadData();
    });

    return { intermediaries, isLoading };
};
