import { dateTimeFormatter } from '@helpers/Date.helper';
import { notNull } from '@helpers/typeGuards';
import {
    SigningDocumentStatusEnum,
    SigningUserStatusEnum,
} from '@interfaces/Api';
import { RedemptionApplicationResponse } from '@interfaces/Api/RedemptionApplicationResponse';

export const getRedemptionSigningDocumentLogs = (
    redemption: RedemptionApplicationResponse,
    getSystemUserName: (val: string) => void
) => {
    const signingDocument = redemption.redemptionSigningDocuments?.[0];
    if (!signingDocument) return [];
    const signingDocumentStatus = signingDocument.status;
    const statusLog = signingDocument.statusLogs.find(
        (a) => a.status == signingDocumentStatus
    )!;

    const informationLines: string[] = [];
    if (signingDocumentStatus == SigningDocumentStatusEnum.draft) {
        const createdBy = `Created by: ${getSystemUserName(
            statusLog.updatedBy
        )}`;
        const updatedAt = `Created at: ${dateTimeFormatter(
            statusLog.createdAt
        )}`;
        informationLines.push(createdBy, updatedAt);
    }
    if (signingDocumentStatus == SigningDocumentStatusEnum.sent) {
        const sentBy = `Sent by: ${getSystemUserName(statusLog.updatedBy)}`;
        const sentAt = `Sent at: ${dateTimeFormatter(statusLog.createdAt)}`;
        informationLines.push(sentBy, sentAt);
    }
    if (signingDocumentStatus == SigningDocumentStatusEnum.signed) {
        const completedAt = `Completed at: ${dateTimeFormatter(
            statusLog.createdAt
        )}`;
        informationLines.push(completedAt);
    }

    if (
        [
            SigningDocumentStatusEnum.sent,
            SigningDocumentStatusEnum.signed,
        ].includes(signingDocumentStatus) &&
        signingDocument.signingUsers &&
        signingDocument.signingUsers.length > 0
    ) {
        // add lines for each signer
        const signerLines = signingDocument.signingUsers
            .map((signingUser) => {
                // add lines for each update created by signer
                return signingUser.statusLogs
                    .map((log) => {
                        switch (log.status) {
                            case SigningUserStatusEnum.onHold:
                                if (
                                    signingUser.status ==
                                    SigningUserStatusEnum.onHold
                                )
                                    return `Waiting for further confirmation to send to ${signingUser.name} (${signingUser.email})`;
                                break;
                            case SigningUserStatusEnum.sent:
                                return `Sent to ${signingUser.name} (${
                                    signingUser.email
                                }) at ${dateTimeFormatter(log.createdAt)}`;
                            case SigningUserStatusEnum.signed:
                                return `Signed by ${signingUser.name} (${
                                    signingUser.email
                                }) at ${dateTimeFormatter(log.createdAt)}`;
                            case SigningUserStatusEnum.declined:
                                return `Rejected by ${signingUser.name} (${
                                    signingUser.email
                                }) at ${dateTimeFormatter(log.createdAt)}`;
                            default:
                                break;
                        }
                    })
                    .filter(notNull);
            })
            .flat(2);
        informationLines.push(...signerLines);
    }

    return informationLines;
};
