import {
    FormInputBox,
    FormMultiselect,
    FormSelect,
} from '@components/Molecules';
import { useProduct } from '@stores/Products/useProduct';
import { useProductSubmit } from '@hooks/useProductSubmit';
import {
    LiquidityTypeEnum,
    ProductResponse,
} from '@interfaces/Api';
import { SelectOption } from '@interfaces/InterfaceFormsProps';
import dayjs from 'dayjs';
import { Form, Formik, FormikHelpers } from 'formik';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import * as Yup from 'yup';
import EditProductFormikButtons from './EditProductFormikButtons';
import { KeyDatesInterface, ProductKeyDatesForm } from './ProductKeyDatesForm';
import FormikLabels from './ModelProducts';
import {
    CalendarTimeEnum,
    calendarTimeLabels,
    DateFrequencyEnum,
    dateFrequencyLabels,
} from '@interfaces/Api/ProductResponse';
import { isoCountriesOptions } from '@helpers/isoCountries';
import { t } from 'i18next';

const liquidityTypeOptions: SelectOption<string>[] = [
    {
        label: t('liquidity_type.closed_ended'),
        value: LiquidityTypeEnum.closedEnded,
    },
    {
        label: t('liquidity_type.open_ended'),
        value: LiquidityTypeEnum.openEnded,
    },
];

const dateFreqencyOptions: SelectOption<DateFrequencyEnum>[] = Object.entries(
    dateFrequencyLabels
)
    .map(([enumType, description]) => ({
        value: enumType as DateFrequencyEnum,
        label: description,
    }))
    .filter((a) =>
        // only show monthly, quarterly and annually
        [
            DateFrequencyEnum.monthly,
            DateFrequencyEnum.quarterly,
            DateFrequencyEnum.annually,
        ].includes(a.value)
    );

const calendarTimeOptions: SelectOption<CalendarTimeEnum>[] = Object.entries(
    calendarTimeLabels
).map(([enumType, description]) => ({
    value: enumType as CalendarTimeEnum,
    label: description,
}));

const LiquidityInformation = () => {
    const { formField } = FormikLabels;

    const { productId } = useParams();
    const { product } = useProduct(productId);

    const [formValue, setFormValue] = useState<Partial<ProductResponse>>({});

    useEffect(() => {
        if (productId && product) {
            if (product.closedEndedInformation?.coolingOffDays === null) {
                delete product.closedEndedInformation.coolingOffDays;
            }

            if (product.closedEndedInformation?.keyDates === null) {
                delete product.closedEndedInformation.keyDates;
            }

            setFormValue(product);
        }
    }, [product, productId]);

    const minNumberErrorMessage = 'Negative numbers not allowed';

    const validationSchema = Yup.object().shape({
        [formField.liquidityType.name]: Yup.string().required(
            formField.liquidityType.requiredErrorMsg
        ),
        closedEndedInformation: Yup.object().when(
            ['liquidityType'],
            (liquidityType: LiquidityTypeEnum) => {
                if (liquidityType === LiquidityTypeEnum.closedEnded) {
                    return Yup.object().shape({
                        // default for coolingOffDays is 0
                        coolingOffDays: Yup.number().min(
                            0,
                            minNumberErrorMessage
                        ),
                        keyDates: Yup.array()
                            .of(
                                Yup.object().shape({
                                    signedSubscriptionDeadline: Yup.string(),
                                    closingDate: Yup.string(),
                                })
                            )
                            .when(
                                ['coolingOffDays'],
                                (coolingOffDays: number) => {
                                    // sometimes isNaN is true even though it is a number, strange yup thing
                                    if (isNaN(coolingOffDays)) {
                                        coolingOffDays = 0;
                                    }

                                    return Yup.array()
                                        .of(
                                            Yup.object().shape({
                                                signedSubscriptionDeadline:
                                                    Yup.string(),
                                                closingDate: Yup.string(),
                                            })
                                        )
                                        .test(
                                            'higherIndexLaterDate',
                                            [
                                                '1. Closing date must be greater then signed subscription dead line.',
                                                '2. The new closing Date should be greater than the previous closing date.',
                                                '3. The new Subscription End Date should be greater than the previous subscription end date',
                                                '4. closing date must be greater then deadline date plus cooling off days',
                                            ].join('\n'),
                                            (
                                                val:
                                                    | KeyDatesInterface[]
                                                    | undefined,
                                                context
                                            ) => {
                                                if (!val || val?.length === 0) {
                                                    return true;
                                                }

                                                if (
                                                    coolingOffDays === undefined
                                                ) {
                                                    return false;
                                                }

                                                let laterKeyDates: KeyDatesInterface =
                                                    val?.[0];

                                                for (
                                                    let i = 1;
                                                    i <= val.length;
                                                    i++
                                                ) {
                                                    const signedSubscriptionDeadline =
                                                        +dayjs(
                                                            laterKeyDates.signedSubscriptionDeadline
                                                        );
                                                    const earliestClosingDate =
                                                        +dayjs(
                                                            laterKeyDates.signedSubscriptionDeadline
                                                        ).add(
                                                            +coolingOffDays,
                                                            'day'
                                                        );
                                                    const closingDate = +dayjs(
                                                        laterKeyDates.closingDate
                                                    );

                                                    if (
                                                        closingDate <
                                                        earliestClosingDate
                                                    ) {
                                                        return false;
                                                    }

                                                    if (i >= val.length) {
                                                        return true;
                                                    }

                                                    const currentSignedSubscriptionDeadline =
                                                        +dayjs(
                                                            val[i]
                                                                .signedSubscriptionDeadline
                                                        );
                                                    const currentEarliestClosingDate =
                                                        +dayjs(
                                                            val[i]
                                                                .signedSubscriptionDeadline
                                                        ).add(
                                                            +coolingOffDays,
                                                            'day'
                                                        );
                                                    const currentClosingDate =
                                                        +dayjs(
                                                            val[i].closingDate
                                                        );

                                                    if (
                                                        currentClosingDate <
                                                        currentEarliestClosingDate
                                                    ) {
                                                        return false;
                                                    }

                                                    if (
                                                        currentClosingDate <=
                                                            closingDate ||
                                                        currentSignedSubscriptionDeadline <=
                                                            signedSubscriptionDeadline
                                                    ) {
                                                        return false;
                                                    }

                                                    laterKeyDates = val[i];
                                                }

                                                return true;
                                            }
                                        );
                                }
                            ),
                    });
                }
                return Yup.object();
            }
        ),
        openEndedInformation: Yup.object().when(
            ['liquidityType'],
            (liquidityType: LiquidityTypeEnum) => {
                if (liquidityType === LiquidityTypeEnum.openEnded) {
                    return Yup.object().shape({
                        redemptionFrequency: Yup.string().required(
                            'Redemption Frequency is required'
                        ),
                        redemptionNoticeDays: Yup.number().min(
                            0,
                            minNumberErrorMessage
                        ),
                        redemptionQuantityAvailablePercentage: Yup.number()
                            .min(0, minNumberErrorMessage)
                            .max(100),
                        subscriptionNoticeDays: Yup.number().min(
                            0,
                            minNumberErrorMessage
                        ),
                        hardLockUpDetail: Yup.object().shape({
                            lengthOfTimeInMonths: Yup.number().min(
                                0,
                                minNumberErrorMessage
                            ),
                        }),
                    });
                }
                return Yup.object();
            }
        ),
    });

    const onSubmit = useProductSubmit(productId, `product-fees`);

    const handleSubmit = (values: Partial<ProductResponse>) => {
        if (values.liquidityType === 'openEnded') {
            values['closedEndedInformation'] = {};
        } else if (values.liquidityType === 'closedEnded') {
            values['openEndedInformation'] = {};
        }

        onSubmit(values);
    };

    return (
        <div className="w-full">
            <h1 className="text-xl text-gray-500">Liquidity information</h1>
            <div className="my-4">
                {productId && (
                    <Formik
                        initialValues={formValue}
                        onSubmit={handleSubmit}
                        validationSchema={validationSchema}
                        enableReinitialize
                    >
                        {({ values, isValid, setFieldValue, ...actions }) => (
                            <Form>
                                <FormSelect
                                    name={formField.liquidityType.name}
                                    label={formField.liquidityType.label}
                                    optionsData={liquidityTypeOptions}
                                />
                                {values.liquidityType ==
                                    LiquidityTypeEnum.openEnded && (
                                    <>
                                        {/* select box for redemptionFrequency */}
                                        <FormSelect
                                            name={
                                                formField.openEndedInformation
                                                    .redemptionFrequency.name
                                            }
                                            label={
                                                formField.openEndedInformation
                                                    .redemptionFrequency.label
                                            }
                                            optionsData={dateFreqencyOptions}
                                        />
                                        {/* date box for openEndedInformation.redemptionFrequencyDeadline */}
                                        <FormSelect
                                            name={
                                                formField.openEndedInformation
                                                    .redemptionFrequencyDeadline
                                                    .name
                                            }
                                            label={
                                                formField.openEndedInformation
                                                    .redemptionFrequencyDeadline
                                                    .label
                                            }
                                            optionsData={calendarTimeOptions}
                                        />

                                        {/* input box for openEndedInformation.redemptionNoticeDays */}
                                        <FormInputBox
                                            name={
                                                formField.openEndedInformation
                                                    .redemptionNoticeDays.name
                                            }
                                            label={
                                                formField.openEndedInformation
                                                    .redemptionNoticeDays.label
                                            }
                                            type="number"
                                        />
                                        {/* percentage box for openEndedInformation.redemptionQuantityAvailablePercentage */}
                                        <FormInputBox
                                            name={
                                                formField.openEndedInformation
                                                    .redemptionQuantityAvailablePercentage
                                                    .name
                                            }
                                            label={
                                                formField.openEndedInformation
                                                    .redemptionQuantityAvailablePercentage
                                                    .label
                                            }
                                            type="number"
                                        />
                                        {/* multi select box for businessDayCountryCodeList*/}
                                        <FormMultiselect
                                            name={
                                                formField.openEndedInformation
                                                    .businessDayCountryCodeList
                                                    .name
                                            }
                                            label={
                                                formField.openEndedInformation
                                                    .businessDayCountryCodeList
                                                    .label
                                            }
                                            options={isoCountriesOptions}
                                            isFieldValuesArray
                                            id="businessDayCountryCodeList"
                                        />

                                        {/* select box for subscriptionFrequency */}
                                        <FormSelect
                                            name={
                                                formField.openEndedInformation
                                                    .subscriptionFrequency.name
                                            }
                                            label={
                                                formField.openEndedInformation
                                                    .subscriptionFrequency.label
                                            }
                                            optionsData={dateFreqencyOptions}
                                        />
                                        {/* select for subscriptionFrequencyDeadline */}
                                        <FormSelect
                                            name={
                                                formField.openEndedInformation
                                                    .subscriptionFrequencyDeadline
                                                    .name
                                            }
                                            label={
                                                formField.openEndedInformation
                                                    .subscriptionFrequencyDeadline
                                                    .label
                                            }
                                            optionsData={calendarTimeOptions}
                                        />

                                        {/* number box for subscriptionNoticeDays */}
                                        <FormInputBox
                                            name={
                                                formField.openEndedInformation
                                                    .subscriptionNoticeDays.name
                                            }
                                            label={
                                                formField.openEndedInformation
                                                    .subscriptionNoticeDays
                                                    .label
                                            }
                                            type="number"
                                        />
                                    </>
                                )}
                                {values.liquidityType ==
                                    LiquidityTypeEnum.closedEnded && (
                                    <>
                                        <FormInputBox
                                            name={
                                                formField.closedEndedInformation
                                                    .coolingOffDays.name
                                            }
                                            label={
                                                formField.closedEndedInformation
                                                    .coolingOffDays.label
                                            }
                                            placeholder={
                                                formField.closedEndedInformation
                                                    .coolingOffDays.label
                                            }
                                            type="number"
                                        />
                                        <ProductKeyDatesForm />
                                    </>
                                )}

                                <EditProductFormikButtons
                                    productId={productId}
                                    isReadOnly={false}
                                    pathPrevious="product-information"
                                    pathNext="product-fees"
                                />
                            </Form>
                        )}
                    </Formik>
                )}
            </div>
        </div>
    );
};

export default LiquidityInformation;
