import { createContext, PropsWithChildren, useContext, useState } from 'react';

import { DocumentTemplatesGet } from '@api/DocumentTemplate';
import { DocumentTemplate } from '@interfaces/Api/DocumentTemplate';
import { notifyError } from '@helpers/toastrHelper';

export interface DocumentTemplateLibraryContextProps {
    documentTemplateList: DocumentTemplate[];
    getDocumentTemplates: () => Promise<void>;
}

const DocumentTemplateLibraryDefaultState: DocumentTemplateLibraryContextProps =
    {
        documentTemplateList: [],
        getDocumentTemplates: async () => {},
    };

const DocumentTemplateLibraryContext =
    createContext<DocumentTemplateLibraryContextProps>(
        DocumentTemplateLibraryDefaultState
    );

export default DocumentTemplateLibraryContext;

export const useDocumentTemplatesLibraryState = () => {
    return useContext(DocumentTemplateLibraryContext);
};

export const DocumentTemplateLibraryProvider = ({
    children,
}: PropsWithChildren<{}>) => {
    const [documentTemplateList, setDocumentTemplateList] = useState<
        DocumentTemplate[]
    >([]);

    const getDocumentTemplates = async (): Promise<void> => {
        try {
            const result = await DocumentTemplatesGet();
            setDocumentTemplateList(result);
        } catch (err) {
            notifyError('Could not get document templates');
        }
    };

    return (
        <DocumentTemplateLibraryContext.Provider
            value={{
                documentTemplateList,
                getDocumentTemplates,
            }}
        >
            {children}
        </DocumentTemplateLibraryContext.Provider>
    );
};
