import { FC, useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Formik, Form } from 'formik';

import { importProduct } from '@api/Products';

import { AlertBox } from '@components/Molecules';
import { Button } from '@components/Atoms';
import { ModalWrapper } from '@components/Organisms';
import FileUpload from '@components/Organisms/FileUploadAsync/FileUpload';

import { useProductsStore } from '@stores/Products/Products.store';
import { useProductsActions } from '@stores/Products';

import { errorToString } from '@helpers/error.helper';
import { notifyError, notifySuccess } from '@helpers/toastrHelper';

import { ModalService } from '@services/ModalService';

export const UploadProduct: FC = () => {
    const [uploadFile, setUploadFile] = useState<File>();
    const [productData, setProductData] = useState<any>();
    const { products } = useProductsStore();
    const { fetchProducts: getProductData } = useProductsActions();
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const navigate = useNavigate();

    useEffect(() => {
        if (uploadFile) {
            const reader = new FileReader();

            reader.onload = (e) => {
                setProductData(JSON.parse(e.target?.result as string));
            };

            reader.readAsText(uploadFile);
        }
    }, [uploadFile]);

    const uploadHandler = useCallback(() => {
        const uploadProduct = () => {
            setIsLoading(true);

            importProduct(productData)
                .then(async (response) => {
                    // refresh the product list
                    await getProductData();
                    notifySuccess(
                        `Product - ${response.name} has been imported successfully.`
                    );
                    navigate(
                        `/admin/products/${response._id}/product-information`
                    );
                    ModalService.getInstance().hideModal();
                })
                .catch((error) => {
                    notifyError(errorToString(error));
                })
                .finally(() => {
                    setIsLoading(false);
                });
        };

        // need to check if the product already exist
        const matchedProduct = products.find(
            (product) =>
                product.name === productData?.name &&
                product.umbrellaFundName === productData?.umbrellaFundName
        );

        if (matchedProduct) {
            ModalService.getInstance('warning')
                .setTitle('Product Already Exists!')
                .setShowHeader(true)
                .setComponent(
                    <div>
                        <div className="font-bold text-red-500	">Warning!</div>
                        Product with name "{matchedProduct.name}" already exists
                        in the system, do you want to overwrite the existing
                        product? All product data including any documents will
                        be overwritten. Any subscriptions/ links/ bookmarks to
                        this product will not be affected
                        <AlertBox
                            alertType="warning"
                            message={
                                <div>
                                    This will also affect any profile specific
                                    mappings and restrictions. We will try to
                                    remap where possible,
                                    <b>
                                        {' '}
                                        however please manually verify the
                                        profile specific mappings after the
                                        import.
                                    </b>
                                </div>
                            }
                        />
                    </div>
                )
                .setShowFooter(true)
                .setConfirmationLabel('Overwrite')
                .setConfirmCallback(() => {
                    uploadProduct();
                    ModalService.getInstance('warning').hideModal();
                })
                .showModal();
        } else {
            uploadProduct();
        }
    }, [getProductData, productData, products]);

    return (
        <div>
            <Formik initialValues={{}} onSubmit={() => {}}>
                <Form>
                    <FileUpload
                        name="product"
                        onHandleUpload={(uploadFiles) => {
                            // need to check if it's json file
                            if (uploadFiles[0].type !== 'application/json') {
                                notifyError(
                                    'Please upload a valid product data file in json format.'
                                );
                            } else {
                                setUploadFile(uploadFiles[0]);
                            }
                        }}
                        onHandleRemove={() => {
                            setUploadFile(undefined);
                            setProductData(undefined);
                        }}
                        uploadedFiles={
                            uploadFile
                                ? [
                                      {
                                          fileName: uploadFile.name,
                                          fileSize: uploadFile.size.toString(),
                                          url: '',
                                      },
                                  ]
                                : []
                        }
                    />
                </Form>
            </Formik>

            <ModalWrapper msgPrefix="warning" />
            <div className="flex justify-between">
                <Button
                    buttonType="secondary"
                    onClick={() => {
                        ModalService.getInstance().hideModal();
                    }}
                    label="cancel"
                ></Button>

                <Button
                    label="Upload"
                    disabled={isLoading || !productData}
                    onClick={uploadHandler}
                    isLoading={isLoading}
                ></Button>
            </div>
        </div>
    );
};
