import React from 'react';
import { Link } from 'react-router-dom';
import { useFormikContext } from 'formik';

import { Button } from '@components/Atoms';

interface StepperFormikSubmitProps {
    isReadOnly: boolean;
    hasPreview?: boolean;
    previewLinkTo?: string;
    productId?: string;
    disabled?: boolean;
    pathPrevious?: string;
    pathNext?: string | undefined;
}

const EditProductFormikButtons: React.FC<StepperFormikSubmitProps> = (
    props: StepperFormikSubmitProps
) => {
    const { isSubmitting, isValid } = useFormikContext();
    const backUrl = `../${props.productId || 'new'}/${props.pathPrevious}`;

    return (
        <div className="flex flex-row flex-wrap w-full mt-10">
            <div className="w-3/12 text-sm md:text-base">
                {props.pathPrevious && (
                    <Link to={backUrl}>
                        <Button
                            buttonType="secondary"
                            type="button"
                            label="Back"
                        />
                    </Link>
                )}
            </div>
            <div className="w-9/12 justify-end">
                <div className="flex flex-row justify-end">
                    {props.isReadOnly ? (
                        <div className="w-1/3 text-sm md:text-base">
                            <Link
                                to={`../${props.productId}/${props.pathNext}`}
                            >
                                <Button
                                    type="button"
                                    label="Next"
                                    disabled={props.disabled}
                                />
                            </Link>
                        </div>
                    ) : (
                        <>
                            {props.hasPreview && (
                                <Link
                                    className="text-sm md:text-base"
                                    to={`${props.previewLinkTo}`}
                                >
                                    <Button
                                        buttonType="preview"
                                        type="button"
                                        label="Preview"
                                        className="mx-2"
                                    />
                                </Link>
                            )}

                            <div className="text-sm md:text-base">
                                <Button
                                    type="submit"
                                    label="Save"
                                    disabled={isSubmitting || !isValid}
                                />
                            </div>
                        </>
                    )}
                </div>
            </div>
        </div>
    );
};

export default EditProductFormikButtons;
