import './Loading.css';

interface LoadingProps {
    size?: 'small' | 'medium' | 'large';
    className?: string;
}

const sizes = {
    small: 24,
    medium: 48,
    large: 96,
};

export const Loading = ({ size, className }: LoadingProps) => (
    <div className="svg-loader">
        <svg
            className={`svg-container ${className}`}
            height={sizes[size || 'small']}
            width={sizes[size || 'small']}
            viewBox="0 0 100 100"
        >
            <circle className="loader-svg bg" cx="50" cy="50" r="40"></circle>
            <circle
                className="loader-svg animate"
                cx="50"
                cy="50"
                r="40"
            ></circle>
        </svg>
    </div>
);
