import { useState } from 'react';
import { Button, Icons, InputBox } from '@components/Atoms';
import { SortableList } from '@components/Organisms/';

interface KeyInfoProps {
    todo: keyValueProps;
    index: number;
    removeKeyInfo(index: number): void;
    inputItemValue?: string;
    inputKeyNameValue?: string;
}

interface keyValueProps {
    keyName: string;
    value: string[];
}

interface TypeProps {
    inputKeyName?: string;
    inputKeyNameLabel?: string;
    inputKeyNameValue?: string;
    inputItem?: string;
    inputItemLabel?: string;
    inputItemValue?: string;
}
interface KeyInfoFormProps extends TypeProps {
    addKeyInfo(value: keyValueProps): void;
    value?: keyValueProps[];
}

interface KeyInfoAddValueFormProps extends TypeProps {
    value?: keyValueProps[];
    onChange?(value: keyValueProps[]): void;
    title?: string;
}

const KeyInfo: React.FC<KeyInfoProps> = ({
    todo,
    index,
    removeKeyInfo,
}: KeyInfoProps) => (
    <div className="w-full flex flex-wrap justify-between h-auto">
        <div className="w-3/12">{todo.keyName}</div>
        <div className="w-8/12">{todo.value?.join(',')}</div>
        <div className="w-1/12 text-right pr-1">
            <Button
                onClick={(event) => {
                    event.stopPropagation();
                    removeKeyInfo(index)
                }}
                hasIconOnly
                endIcon={<Icons name="XMarkIcon" />}
            />
        </div>
    </div>
);

const KeyInfoForm: React.FC<KeyInfoFormProps> = (props: KeyInfoFormProps) => {
    const [keyName, setKeyName] = useState<string>('');
    const [value, setValue] = useState<string>('');

    const handleKeyPress = (e: React.KeyboardEvent) =>
        (e.key === 'Enter' || e.keyCode == 13) && e.preventDefault();

    const handleSubmit = (e: { preventDefault: () => void }) => {
        e.preventDefault();
        if (!keyName) return;
        if (!value) return;

        props.addKeyInfo({
            keyName,
            value: [value],
        });
        setKeyName('');
        setValue('');
    };

    return (
        <div className="w-full flex flex-wrap justify-between my-4">
            <div className="w-5/12 md:w-4/12">
                <InputBox
                    type="text"
                    label={props.inputKeyName}
                    name={props.inputKeyName || ''}
                    value={keyName}
                    placeholder={props.inputKeyNameLabel}
                    onKeyPress={handleKeyPress}
                    onChange={({ target: { value } }) => setKeyName(value)}
                />
            </div>
            <div className="w-4/12 md:w-5/12">
                <InputBox
                    type="text"
                    label={props.inputItem}
                    name={props.inputItem || ''}
                    value={value}
                    placeholder={props.inputItemLabel}
                    onKeyPress={handleKeyPress}
                    onChange={({ target: { value } }) => setValue(value)}
                />
            </div>
            <div className="w-2/12">
                <Button
                    onClick={handleSubmit}
                    type="button"
                    className="mx-2 w-16 h-16 float-right"
                >
                    <Icons name="PlusIcon" size="large" />
                </Button>
            </div>
        </div>
    );
};

export const KeyInfoAddValueForm = (props: KeyInfoAddValueFormProps) => {
    const [keyInfoArr, setKeyInfoArr] = useState<keyValueProps[]>(
        props.value || []
    );

    const addKeyInfo = (value: keyValueProps) => {
        const newKeyInfoArr: keyValueProps[] = [...keyInfoArr, value];
        setKeyInfoArr(newKeyInfoArr);
        props.onChange && props.onChange(newKeyInfoArr);
    };

    const removeKeyInfo = (id: any) => {
        const newKeyInfoArr: keyValueProps[] = [...keyInfoArr];
        newKeyInfoArr.splice(id, 1);
        setKeyInfoArr(newKeyInfoArr);
        props.onChange && props.onChange(newKeyInfoArr);
    };

    const onSorted = (listItems: keyValueProps[]) => {
        setKeyInfoArr(listItems);
        props.onChange && props.onChange(listItems);
    };

    return (
        <div className="w-full">
            <div className="my-2">
                <h3 className="text-xl text-gray-500 pb-2">
                    {props.title || 'Key Information'}
                </h3>
                <SortableList
                    onSorted={onSorted}
                    handleClassName='relative inset-y-1'
                    listItems={keyInfoArr}
                    listElements={keyInfoArr.map((todo, index) => (
                        <div key={index} className="w-full">
                            <KeyInfo
                                index={index}
                                todo={todo}
                                removeKeyInfo={removeKeyInfo}
                                inputKeyNameValue={
                                    props.inputKeyNameValue || 'KeyName'
                                }
                                inputItemValue={props.inputItemValue || 'Value'}
                            />
                        </div>
                    ))}
                />

                <KeyInfoForm
                    addKeyInfo={addKeyInfo}
                    inputKeyName={props.inputKeyName}
                    inputKeyNameLabel={props.inputKeyNameLabel}
                    inputKeyNameValue={props.inputKeyNameValue}
                    inputItem={props.inputItem}
                    inputItemLabel={props.inputItemLabel}
                    inputItemValue={props.inputItemValue}
                    value={props.value}
                />
            </div>
        </div>
    );
};

export default KeyInfoAddValueForm;
