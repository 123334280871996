import { TabsData } from '@components/Organisms/Dashboard/Dashboard.helper';

import { PdfViewer } from '@components/Organisms/PdfViewer';
import { useStorageTokenState } from '@contexts/StorageTokenContext';
import { toCapitalizedWords } from '@helpers/ToCapitalizedWords';
import { NoticeType } from '@interfaces/Api';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { useState } from 'react';

export const ReportsTabContent = (data: TabsData) => {
    const { t } = useTranslation();
    const { investmentData } = data;
    const { subscriptionNotices } = investmentData;
    const { withToken } = useStorageTokenState();
    const subscriptionNoticeDocuments = subscriptionNotices.filter(
        (notice) =>
            notice.documents &&
            notice.documents.length > 0 &&
            notice.noticeType === NoticeType.QuarterlyReport
    );

    const preloadedDocumentURL =
        subscriptionNoticeDocuments
            .map((notice) => notice.documents)
            .flat()
            .find((document) => document?.url)?.url || '';

    const [selectedNoticeKey, setNoticeKey] = useState('');
    const [fileURL, setFileUrl] = useState(preloadedDocumentURL);

    const updateSelectedDocument = (noticeKey: string, doc: any) => {
        setFileUrl(doc.url);
        setNoticeKey(noticeKey);
    };

    const getButtonStyle = (el: string) =>
        classNames('block text-left', {
            'font-semibold': el === el,
            'text-lg': el === el,
        });

    return (
        <div className="flex">
            <ul className="md:flex-1 max-w-xs list-disc mt-4 list-none">
                {preloadedDocumentURL ? (
                    subscriptionNoticeDocuments.map((notice, i) => {
                        return (
                            notice.documents &&
                            notice.documents.length > 0 &&
                            notice.documents.map((doc, i) => (
                                <li key={`${notice.noticeType}-${i}`}>
                                    <button
                                        disabled={fileURL === doc.url}
                                        onClick={() =>
                                            notice.documents &&
                                            setFileUrl(doc.url)
                                        }
                                        className={getButtonStyle(doc.url)}
                                    >
                                        {notice.comment.length < 50
                                            ? notice.comment
                                            : toCapitalizedWords(
                                                  notice.noticeType
                                              )}
                                    </button>
                                </li>
                            ))
                        );
                    })
                ) : (
                    <p>{t('client.portfolio.product.reports.no_documents_found')}</p>
                )}
            </ul>
            <div className="flex-3">
                {preloadedDocumentURL ? (
                    <PdfViewer
                        fileURL={withToken(fileURL)}
                        width={700}
                        compact={true}
                        align={'left'}
                    />
                ) : null}
            </div>
        </div>
    );
};
