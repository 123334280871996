import { useEffect, useState } from 'react';
import {
    useFormikContext,
    Field,
    ErrorMessage,
    FieldInputProps,
    FieldMetaProps,
} from 'formik';
import { Checkbox } from '@components/Atoms';
import classNames from 'classnames';

interface FormCheckboxProps {
    name: string;
    label?: string;
    description?: string | JSX.Element;
    defaultValue?: any;
    value?: any;
    actionLabel?: string | JSX.Element;
    disabled?: boolean;
    checked?: boolean;
}

const FormCheckbox: React.FC<FormCheckboxProps> = (
    props: FormCheckboxProps
) => {
    const [isSelected, setIsSelected] = useState<boolean>(false);

    const formikProps = useFormikContext();

    useEffect(() => {
        setIsSelected(formikProps.getFieldProps(props.name).value);
    }, [props.name, formikProps]);

    return (
        <div
            className={classNames([
                'relative w-full border p-5 mb-3 rounded-lg',
                isSelected ? 'bg-purple-50 ' : 'hover:bg-gray-100 ',
            ])}
        >
            {props.label && (
                <p className="flex items-center pointer-events-none font-bold">
                    {props.label}
                </p>
            )}

            {props.description && (
                <p className="text-sm flex items-center mt-2 whitespace-pre-line">
                    {props.description}
                </p>
            )}
            <div
                className={classNames([
                    'w-full',
                    (props.label || props.description) && 'mt-3',
                ])}
            >
                <Field name={props.name} type="checkbox">
                    {({
                        field,
                    }: {
                        field: FieldInputProps<string>;
                        meta: FieldMetaProps<string>;
                    }) => (
                        <Checkbox
                            {...field}
                            label={props.actionLabel}
                            disabled={props.disabled}
                            checked={
                                props.checked !== undefined
                                    ? props.checked
                                    : field.checked
                            }
                        />
                    )}
                </Field>
            </div>

            <div className="flex content-start text-red-500">
                <ErrorMessage
                    name={props.name}
                    component="text"
                    className="text-red-500 justify-self-start items-left text-left"
                    render={(msg) => <div>{msg}</div>}
                />
            </div>
        </div>
    );
};

export default FormCheckbox;
