import {
    Field,
    FieldArray,
    FieldInputProps,
    Form,
    Formik,
    FormikBag,
} from 'formik';
import React, { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import * as Yup from 'yup';

import { AssetClassTypeLabel } from '@interfaces/Api';
import { SelectOption } from '@interfaces/InterfaceFormsProps';

import { assetManagerPost, getAllAssetManagers } from '@services/AssetManagers';

import { useProductSubmit } from '@hooks/useProductSubmit';
import { isoCurrenciesOptions } from '@helpers/isoCurrencies';

import {
    FormCheckbox,
    FormDatePicker,
    FormInputBox,
    FormMultiselectCreatable,
    FormSelect,
} from '@components/Molecules';
import formModel from './ModelProducts';
import EditProductFormikButtons from '@pages/Admin/ProductsManagement/Components/EditProductFormikButtons';

import KeyInfoAddValueForm from './KeyInfoAddValueForm';
import {
    AvailabilityStatusEnum,
    FundInterestsNameEnum,
    AvailabilityStatusLabel,
    ProductResponse,
} from '@interfaces/Api/ProductResponse';
import { useProduct } from '@stores/Products/useProduct';
import { productGroupsGet, productGroupsPost } from '@api/ProductGroup';
import { ProductGroup } from '@interfaces/Api/ProductGroup';
import { notifyError, notifySuccess } from '@helpers/toastrHelper';
import { errorToString } from '@helpers/error.helper';
import MDEditor from '@uiw/react-md-editor';
import { AssetManagerResponse } from '@interfaces/AssetManager';
import { Option } from '@components/Molecules/MultiselectCreatable';

const availabilityStatusOptions: SelectOption<AvailabilityStatusEnum>[] = [
    {
        label: AvailabilityStatusLabel[
            AvailabilityStatusEnum.openForSubscription
        ],
        value: AvailabilityStatusEnum.openForSubscription,
    },
    {
        label: AvailabilityStatusLabel[AvailabilityStatusEnum.upcoming],
        value: AvailabilityStatusEnum.upcoming,
    },
    {
        label: AvailabilityStatusLabel[
            AvailabilityStatusEnum.closedToSubscription
        ],
        value: AvailabilityStatusEnum.closedToSubscription,
    },
    {
        label: AvailabilityStatusLabel[AvailabilityStatusEnum.expired],
        value: AvailabilityStatusEnum.expired,
    },
];

const fundInterestNameOptions: SelectOption<FundInterestsNameEnum>[] = [
    {
        label: FundInterestsNameEnum.Share,
        value: FundInterestsNameEnum.Share,
    },
    {
        label: FundInterestsNameEnum.Unit,
        value: FundInterestsNameEnum.Unit,
    },
];

const assetClassTypeOptions: SelectOption<string>[] = Object.entries(
    AssetClassTypeLabel
).map(([enumType, description]) => ({ value: enumType, label: description }));

const ProductInformation: React.FC = () => {
    const { formField } = formModel;
    const [assetManagers, setAssetManagers] = useState<Option[]>([]);
    const [groups, setGroups] = useState<ProductGroup[]>();
    const [formValue, setFormValue] = useState<Partial<ProductResponse>>({
        name: '',
        shortDescription: '',
        visibleWithoutLogin: false,
        keyInformation: [],
    });

    const { productId } = useParams();
    const { product } = useProduct(productId);

    const fetchGroups = useCallback(async () => {
        const results = await productGroupsGet();
        setGroups(results);
        return results;
    }, []);

    const fetchAssetManagers = useCallback(async () => {
        const data = await getAllAssetManagers();
        const list: Option[] = data.map((item: AssetManagerResponse) => ({
            label: item.name,
            value: item._id,
        }));
        setAssetManagers(list);
        setFormValue({
            ...product,
            assetManagerId: list.find(
                ({ value }) => value === product?.assetManagerId
            ) as any,
        });
        return list;
    }, [product]);

    useEffect(() => {
        if (productId && product) {
            setFormValue(product);
        }
    }, [product, productId]);

    useEffect(() => {
        if (productId === 'new' || (productId && product)) {
            fetchAssetManagers();
            fetchGroups();
        }
    }, [fetchAssetManagers, fetchGroups, product, productId]);

    const minNumberErrorMessage = 'Negative numbers not allowed';

    const selectedValidationSchema = Yup.object().shape({
        [formField.availabilityStatus.name]: Yup.string().required(
            formField.availabilityStatus.requiredErrorMsg
        ),
        [formField.name.name]: Yup.string().required(
            formField.name.requiredErrorMsg
        ),
        [formField.umbrellaFundName.name]: Yup.string().optional(),
        [formField.shortDescription.name]: Yup.string().required(
            formField.shortDescription.requiredErrorMsg
        ),
        [formField.assetClassType.name]: Yup.string().required(
            formField.assetClassType.requiredErrorMsg
        ),
        [formField.targetSubscriptionAmount.name]: Yup.number()
            .nullable()
            .min(0, minNumberErrorMessage),
        [formField.capacityAmount.name]: Yup.number()
            .nullable()
            .min(0, minNumberErrorMessage)
            .when(
                [formField.targetSubscriptionAmount.name],
                (targetSubscriptionAmount, schema: any) => {
                    if (targetSubscriptionAmount < 0) {
                        return schema.min(0, minNumberErrorMessage);
                    }

                    if (targetSubscriptionAmount > 0) {
                        return schema.min(
                            targetSubscriptionAmount,
                            'Capacity amount should be higher than target subscription amount'
                        );
                    }
                }
            ),
        [formField.baseCurrency.name]: Yup.string().required(),
        keyInformation: Yup.array().of(
            Yup.object().shape({
                [formField.keyInformation.keyName.name]: Yup.string().required(
                    `${formField.keyInformation.keyName.requiredErrorMsg}`
                ),
                value: Yup.array().of(
                    Yup.string().required(
                        `${formField.keyInformation.value.requiredErrorMsg}`
                    )
                ),
            })
        ),
    });

    const onSubmit = useProductSubmit(productId, `liquidity-information`);

    const handleGroupChange = useCallback(
        (newGroup, setFieldValue) => {
            const findInGroups = groups?.find(
                ({ value }) => value === newGroup.value
            );

            if (!findInGroups) {
                productGroupsPost(newGroup)
                    .then(async (result) => {
                        const newGroups = await fetchGroups();
                        const newGroupOnServer = newGroups.find(
                            ({ value }) => value === newGroup.value
                        );

                        if (newGroupOnServer) {
                            setFieldValue(
                                formField.groupId.name,
                                newGroupOnServer
                            );
                        }

                        notifySuccess(`${result.label} has been created!`);
                    })
                    .catch((error) => notifyError(errorToString(error)));
            }
        },
        [fetchGroups, formField.groupId.name, groups]
    );

    const handleAssetManagerChange = useCallback(
        (newAssetManager, setFieldValue) => {
            const findInAssetManagers = assetManagers?.find(
                ({ value }) => value === newAssetManager.value
            );

            if (!findInAssetManagers) {
                assetManagerPost({
                    name: newAssetManager.value as string,
                })
                    .then(async (result) => {
                        const assetManagers = await fetchAssetManagers();
                        const newAssetManagerOnServer = assetManagers.find(
                            ({ label }) => label === newAssetManager.label
                        );

                        if (newAssetManagerOnServer) {
                            setFieldValue(
                                formField.assetManagerId.name,
                                newAssetManagerOnServer
                            );
                        }

                        notifySuccess(`${result.name} has been created!`);
                    })
                    .catch((error) => notifyError(errorToString(error)));
            }
        },
        [assetManagers, fetchAssetManagers, formField.assetManagerId.name]
    );

    if (!(productId === 'new' || (productId && formValue._id))) return null;

    return (
        <div className="w-full">
            <h1 className="text-xl text-gray-500">
                Product and key Information
            </h1>

            <Formik
                initialValues={formValue}
                onSubmit={onSubmit}
                validationSchema={selectedValidationSchema}
                enableReinitialize
            >
                {({ values, setFieldValue }) => (
                    <Form className="mb-3">
                        <h3 className="text-sm font-normal leading-8 mb-3">
                            Please enter all product and key information data...
                        </h3>
                        <div className='my-4'>
                            <FormCheckbox
                                name={formField.visibleWithoutLogin.name}
                                actionLabel={formField.visibleWithoutLogin.label}
                            />
                        </div>
                        <div className="my-4">
                            <FormMultiselectCreatable
                                name={formField.assetManagerId.name}
                                label={formField.assetManagerId.label}
                                options={assetManagers}
                                isSingle={true}
                                onChange={(newAssetManager) => {
                                    handleAssetManagerChange(
                                        newAssetManager,
                                        setFieldValue
                                    );
                                }}
                            />
                        </div>
                        <div className="my-4">
                            <FormMultiselectCreatable
                                name={formField.groupId.name}
                                label={formField.groupId.label}
                                options={groups}
                                isSingle={true}
                                placeholder={formField.groupId.label}
                                onChange={(newGroup) => {
                                    handleGroupChange(newGroup, setFieldValue);
                                }}
                            />
                        </div>
                        <div className="my-4">
                            <FormInputBox
                                name={formField.umbrellaFundName.name}
                                label={formField.umbrellaFundName.label}
                                placeholder={formField.umbrellaFundName.label}
                            />
                        </div>
                        <div className="my-4">
                            <FormSelect
                                name={formField.fundInterestsName.name}
                                label={formField.fundInterestsName.label}
                                optionsData={fundInterestNameOptions}
                                value={values?.fundInterestsName}
                            />
                        </div>
                        <div className="my-4">
                            <FormInputBox
                                name={formField.name.name}
                                label={formField.name.label}
                                placeholder={formField.name.label}
                            />
                        </div>
                        <div className="my-4">
                            <FormInputBox
                                name={formField.shortDescription.name}
                                label={formField.shortDescription.label}
                                placeholder={formField.shortDescription.label}
                            />
                        </div>
                        <div className="my-4">
                            <FormSelect
                                name={formField.availabilityStatus.name}
                                label={formField.availabilityStatus.label}
                                optionsData={availabilityStatusOptions}
                                value={values?.availabilityStatus}
                            />
                        </div>

                        <div className="my-4">
                            <FormSelect
                                name={formField.assetClassType.name}
                                label={formField.assetClassType.label}
                                optionsData={assetClassTypeOptions}
                                value={values.assetClassType}
                            />
                        </div>

                        <div className="my-4">
                            <FormInputBox
                                name={formField.targetSubscriptionAmount.name}
                                type="number"
                                label={formField.targetSubscriptionAmount.label}
                                placeholder={
                                    formField.targetSubscriptionAmount.label
                                }
                            />
                        </div>
                        <div className="my-4">
                            <FormInputBox
                                name={formField.capacityAmount.name}
                                type="number"
                                label={formField.capacityAmount.label}
                                placeholder={formField.capacityAmount.label}
                            />
                        </div>
                        <div className="my-4">
                            <FormSelect
                                name={formField.baseCurrency.name}
                                label={formField.baseCurrency.label}
                                optionsData={isoCurrenciesOptions}
                                value={values.baseCurrency}
                            />
                        </div>

                        <div className="my-4">
                            <FormDatePicker
                                name={formField.inceptionDate.name}
                                label={formField.inceptionDate.label}
                                value={values.inceptionDate as Date}
                            />
                        </div>

                        <FieldArray
                            name="keyInformation"
                            render={() => (
                                <div className="my-4">
                                    <KeyInfoAddValueForm
                                        inputKeyName="keyName"
                                        inputKeyNameLabel={
                                            formField.keyInformation.keyName
                                                .label
                                        }
                                        inputItem="value"
                                        inputItemLabel={
                                            formField.keyInformation.value.label
                                        }
                                        value={values.keyInformation}
                                        onChange={(newValue) => {
                                            setFieldValue(
                                                'keyInformation',
                                                newValue
                                            );
                                        }}
                                    />
                                </div>
                            )}
                        />
                        <div className="my-4">
                            <h3 className="text-xl text-gray-500 mt-8 pb-2">
                                Key Information - Additional Information
                            </h3>
                            <div className="pb-3">
                                This is a free text section which appears at the
                                bottom of the Key Information section on product
                                page
                            </div>
                            {/* The below needs to be in it's own formiks based component */}
                            <Field
                                id="keyInformationMarkdown"
                                name="keyInformationMarkdown"
                            >
                                {({
                                    field,
                                    form,
                                }: {
                                    field: FieldInputProps<string>;
                                    form: FormikBag<any, any>;
                                }) => (
                                    <MDEditor
                                        value={field.value}
                                        onChange={(value) => {
                                            form.setFieldValue(
                                                'keyInformationMarkdown',
                                                value
                                            );
                                        }}
                                        height={480}
                                    />
                                )}
                            </Field>
                        </div>

                        <EditProductFormikButtons
                            productId={productId}
                            isReadOnly={false}
                            pathNext="liquidity-information"
                        />
                    </Form>
                )}
            </Formik>
        </div>
    );
};

export default ProductInformation;
