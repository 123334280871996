const getClientRegistrationFormFields = (t: (key) => string) => ({
    adviserSystemUserId: {
        name: 'adviserSystemUserId' as const,
        label: t('client.registration.advisor.client_adviser'),
    },
    intermediaryId: {
        name: 'intermediaryId' as const,
        label: t('client.registration.summary.intermediary'),
    },
    bookingCentreIds: {
        name: 'bookingCentreIds' as const,
        label: t('client.registration.booking_centres.title'),
    },
    onboardingJurisdictionCountryCode: {
        name: 'onboardingJurisdictionCountryCode',
        label: t('client.registration.summary.onboarding_jurisdiction'),
    },
    clientType: {
        name: 'clientType',
        label: t('client.registration.client_type.title'),
    },
    serviceLevelType: {
        name: 'serviceLevelType' as const,
        label: t('client.registration.service_level_type.title'),
    },
    subscriptionProcessType: {
        name: 'subscriptionProcessType',
        label: 'Subscription Process Type',
    },
    isKycCompliant: {
        name: 'isKycCompliant',
        label: 'Is KYC Compliant?',
    },
    kycDocument: {
        fileName: {
            name: 'fileName',
            label: 'KYC Document',
        },
        url: {
            name: 'url',
            label: 'Document URL',
        },
    },
    salutation: {
        name: 'salutation',
        label: `${t('client.registration.personal_details.salutation')} *`,
        placeHolder: `${t(
            'client.registration.personal_details.salutation_placeholder'
        )} *`,
    },
    firstName: {
        name: 'firstName',
        label: `${t(
            'client.registration.personal_details.legal_first_name'
        )} *`,
    },
    lastName: {
        name: 'lastName',
        label: `${t('client.registration.personal_details.legal_last_name')} *`,
    },
    dateOfBirth: {
        name: 'dateOfBirth',
        label: `${t('client.registration.personal_details.date_of_birth')} *`,
    },
    townOfBirth: {
        name: 'townOfBirth',
        label: `${t('client.registration.personal_details.town_of_birth')} *`,
    },
    countryCodeOfBirth: {
        name: 'countryCodeOfBirth',
        label: `${t(
            'client.registration.personal_details.country_of_birth'
        )} *`,
    },
    nationalityCountryCode: {
        name: 'nationalityCountryCode',
        label: t('client.registration.nationalities.country'),
    },
    emailAddress: {
        name: 'emailAddress',
        label: `${t('client.registration.contact_details.email_address')}  *`,
        requiredErrorMsg: t(
            'client.registration.contact_details.email_address.required_validation_message'
        ),
    },
    phoneDiallingCode: {
        name: 'phoneDiallingCode',
        label: `${t('client.registration.contact_details.country_code')} *`,
        requiredErrorMsg: t(
            'client.registration.contact_details.country_code.required_validation_message'
        ),
    },
    phoneNumber: {
        name: 'phoneNumber',
        label: `${t('client.registration.contact_details.phone_number')} *`,
        requiredErrorMsg: t(
            'client.registration.contact_details.phone_number.required_validation_message'
        ),
    },
    endInvestorType: {
        name: 'endInvestorType' as const,
        label: 'Investor Type',
    },
    companyName: {
        name: 'companyName',
        label: 'Company Name *',
    },
    registrationNumber: {
        name: 'registrationNumber',
        label: 'Registration Number *',
    },
    subscribersLegalForm: {
        name: 'subscribersLegalForm',
        label: 'Subscribers Legal Form *',
    },
    subscribersRegister: {
        name: 'subscribersRegister',
        label: "Name of Subscriber's Register *",
    },
    subscriberParentListingAuthority: {
        name: 'subscriberParentListingAuthority',
        label: 'Subscriber or Parent Listing Authority *',
    },
    subscribersRegulator: {
        name: 'subscribersRegulator',
        label: "Name of Subscriber's Regulator *",
    },
    corporateType: {
        name: 'corporateType',
        label: "Corporate type",
    },
    otherCorporateType: {
        name: 'otherCorporateType',
        label: "Please Specify Other Corporate Type",
    },
    parentCompany: {
        name: 'parentCompany',
        label: "Parent Company/Head Office",
    },
    dateOfIncorporation: {
        name: 'dateOfIncorporation',
        label: "Date of Incorporation",
    },
    countryCodeOfIncorporation: {
        name: 'countryCodeOfIncorporation',
        label: "Country of Incorporation",
    },
    principalPlaceOfBusinessCountryCode: {
        name: 'principalPlaceOfBusinessCountryCode',
        label: "Principal Place of Business",
    },
    areaOfBusinessActivityType: {
        name: 'areaOfBusinessActivityType',
        label: "Area of Activity",
    },
    isListedOnStockExchange: {
        name: 'isListedOnStockExchange',
        label: "Is Listed on a Stock Exchange",
    },
    listedOnStockExchangeListingCode: {
        name: 'listedOnStockExchangeListingCode',
        label: "Listing Code",
    },
    isRegulatedEntity: {
        name: 'isRegulatedEntity',
        label: "Is a Regulated Entity",
    },
    licenseNumber: {
        name: 'licenseNumber',
        label: "License Number",
    },
    regulatorWebsiteAddress: {
        name: 'regulatorWebsiteAddress',
        label: "Regulator Website Address",
    },
    addresses: {
        houseName: {
            name: 'houseName',
            label: `${t('client.registration.addresses.house_name_number')} *`,
        },
        residentialLine1: {
            name: 'residentialLine1',
            label: `${t('client.registration.addresses.line1')} *`,
        },
        residentialLine2: {
            name: 'residentialLine2',
            label: t('client.registration.addresses.line2'),
        },
        city: {
            name: 'city',
            label: `${t('client.registration.addresses.city')} *`,
        },
        postCode: {
            name: 'postCode',
            label: `${t('client.registration.addresses.postcode')} *`,
        },
        countryCode: {
            name: 'countryCode',
            label: `${t('client.registration.addresses.country')} *`,
        },
    },
    bankAccounts: {
        bankName: {
            name: 'bankName',
            label: `${t(
                'client.registration.bank_accounts.account_nickname'
            )} *`,
        },
        countryCurrencyCode: {
            name: 'countryCurrencyCode',
            label: `${t('client.registration.bank_accounts.currency')} *`,
        },
        countryCodeOfAccount: {
            name: 'countryCodeOfAccount',
            label: `${t(
                'client.registration.bank_accounts.country_of_bank_account'
            )} *`,
        },
        IBAN: {
            name: 'IBAN',
            label: `${t('client.registration.bank_accounts.iban')} *`,
        },
    },
    taxInformations: {
        countryCode: {
            name: 'countryCode',
            label: 'Taxation Country *',
        },
        taxId: {
            name: 'taxId',
            label: 'Tax ID',
        },
    },
    loginAccount: {
        name: 'loginAccount',
        label: t('client.registration.login_account.title'),
        id: {
            name: 'systemUsers[0]._id',
            label: t('client.registration.login_account.title'),
        },
        firstName: {
            name: 'systemUsers[0].firstName',
            label: `${t(
                'client.registration.login_account.legal_first_name'
            )} *`,
        },
        surname: {
            name: 'systemUsers[0].surname',
            label: `${t(
                'client.registration.login_account.legal_last_name'
            )} *`,
        },
        email: {
            name: 'systemUsers[0].email',
            label: `${t('client.registration.login_account.email_address')} *`,
        },
    },
});

export default getClientRegistrationFormFields;
