import { isoCountries } from '@helpers/isoCountries';
import { ReviewClientSummaryDetailsProps } from '@interfaces/ReviewClientSummaryDetailsProps';
import { ReviewItem } from './ReviewItem';

const ReviewClientAddressesDetails = (
    props: ReviewClientSummaryDetailsProps
) => (
    <div className="leading-5 mb-4">
        {props.data?.individualDetails?.addresses?.map((addr, i) => (
            <div className="pb-3" key={`${i}-address`}>
                <ReviewItem
                    label="House / Apartment Name or Number:"
                    value={addr?.houseName}
                />

                <ReviewItem
                    label="Address Line 1:"
                    value={addr?.residentialLine1}
                />

                {addr?.residentialLine2 !== '' && (
                    <ReviewItem
                        label="Address Line 2:"
                        value={addr?.residentialLine2}
                    />
                )}

                <ReviewItem value={addr?.city} label="City:" />

                <ReviewItem value={addr?.postCode} label="Postocde:" />

                <ReviewItem
                    label="Country:"
                    value={isoCountries[addr?.countryCode]}
                />
            </div>
        ))}
    </div>
);

export default ReviewClientAddressesDetails;
