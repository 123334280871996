import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { ShareClass } from '@interfaces/Api/ProductResponse';

import { Button } from '@components/Atoms';
import { Modal } from '@components/Molecules';
import { ProposalForm } from './Proposal.form';

interface ProposalProps {
    endInvestorProfileId: string;
    onHandleRefresh?(): void;
}

interface Product {
    id: string;
    name: string;
    shareClasses: ShareClass[];
}

export const Proposal = ({
    endInvestorProfileId,
    onHandleRefresh,
}: ProposalProps) => {
    const { t } = useTranslation();
    const [show, toggleModal] = useState(false);
    const [selectedProduct, setSelectedProduct] = useState<
        Product | undefined
    >();
    const [selectedShareClass, setSelectedShareClass] = useState<
        ShareClass | undefined
    >();

    const clearFormFields = () => {
        setSelectedShareClass(undefined);
        setSelectedProduct(undefined);
    };

    const handleToggleVisibility = () => {
        clearFormFields();
        toggleModal(!show);
    };

    return (
        <>
            <div>
                <Button
                    onClick={handleToggleVisibility}
                    label={t('clients.create_proposal')}
                    dataTestId="proposal-create-button"
                />
            </div>
            <Modal width="w-2/3 lg:w-2/4" show={show}>
                <div className="p-6">
                    <h2 className="text-2xl mb-3 py-2 text-logo-blue">
                        {t('clients.create_proposal')}
                    </h2>
                    <ProposalForm
                        onHandleRefresh={onHandleRefresh}
                        endInvestorProfileId={endInvestorProfileId}
                        setSelectedProduct={setSelectedProduct}
                        selectedProduct={selectedProduct}
                        setSelectedShareClass={setSelectedShareClass}
                        selectedShareClass={selectedShareClass}
                        clearFormFields={clearFormFields}
                        handleToggleVisibility={handleToggleVisibility}
                    />
                </div>
            </Modal>
        </>
    );
};
