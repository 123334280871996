import React from 'react'
import { Document } from '@interfaces/Api/Document';
import { useStorageTokenState } from '@contexts/StorageTokenContext';
import { Icons } from '@components/Atoms';
import { Link } from 'react-router-dom';

type LinkTextType = 'label' | 'fileName';

interface DownloadLinkProps {
  document: Document;
  linkTextType?: LinkTextType;
  linkType?:
  | 'primary'
  | 'secondary';
  iconPosition?: 'left' | 'right';
  handleDownload?: () => void;
}

const getLinkText = (document: Document, linkTextType: LinkTextType) => {
  switch (linkTextType) {
    case 'fileName':
      return document.fileName;
    default:
      return document.label;
  }
}

export const DownloadLink: React.FC<DownloadLinkProps> = ({ document, linkTextType, linkType, iconPosition, handleDownload }) => {
  const { withToken } = useStorageTokenState();

  const icon = (
    <span className="inline-block top-1 relative ml-3">
      <Icons name="ArrowDownTrayIcon" />
    </span>
  );

  return (
    <a
      href={withToken(document.url)}
      target="_blank"
      rel="noreferrer"
      className={`hover:underline font-bold ${linkType === 'secondary' ? 'text-brand-navy' : ''}`}
      onClick={(e) => {
        if (handleDownload) {
          e.preventDefault();
          handleDownload();
        }
      }}
    >
      {iconPosition === 'left' && icon}
      {getLinkText(document, linkTextType!)}
      {iconPosition === 'right' && icon}
    </a>
  )
};

DownloadLink.defaultProps = {
  linkTextType: 'label',
  linkType: 'primary',
  iconPosition: 'left',
};
