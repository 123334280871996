import { useProductsStore } from './Products.store';

const useProductsState = () =>
    useProductsStore((state) => ({
        isLoading: state.isLoading,
        products: state.products,
    }));

const useProductsActions = () => useProductsStore((state) => state.actions);

export { useProductsState, useProductsActions };
