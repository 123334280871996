import * as Yup from 'yup';
import {
    ProductResponse,
    SubscriptionResponse,
    SubscriptionStatusEnum,
} from '@interfaces/Api';
import { nextDateString } from '@helpers/Product.helper';
import { ProductLargeThumbnail } from '@components/Organisms';
import { getFormattedCurrency } from '@helpers/isoCurrencies';
import {
    Field,
    FieldArray,
    FieldInputProps,
    FieldMetaProps,
    Form,
    Formik,
} from 'formik';
import { AlertBox, FormInputBox } from '@components/Molecules';
import { Button, Checkbox, Icons } from '@components/Atoms';
import { useMountEffect } from '@hooks/useMountEffect';
import { GetRelatedSubscriptions } from '@api/RelatedSubscriptions';
import { useState } from 'react';
import { dateShortestFormatter } from '@helpers/Date.helper';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import { useStorageTokenState } from '@contexts/StorageTokenContext';
import { RedemptionLotItem, getRedemptionLotItem } from './CreateStep.logic';
import { getFormattedPercentage } from '@helpers/Math.helper';
import { useNavigate } from 'react-router-dom';
dayjs.extend(customParseFormat);

export interface RedemptionForm {
    totalRedemptionAmount: number;
    canEditLots: boolean;
    redemptions: RedemptionLotItem[];
}
interface CreateStepProps {
    subscription: SubscriptionResponse;
    product: ProductResponse;
    redemptionForm?: RedemptionForm;
    setRedemptionForm: (redemptionForm: RedemptionForm) => void;
    nextStep: () => void;
}

const CreateStep: React.FC<CreateStepProps> = ({
    subscription,
    product,
    redemptionForm,
    setRedemptionForm,
    nextStep,
}) => {
    const [redemptions, setRedemptions] = useState<RedemptionLotItem[]>([]);
    const { withToken } = useStorageTokenState();
    const navigate = useNavigate();

    const [totalInvestmentAmount, setTotalInvestmentAmount] =
        useState<number>(0);
    // TODO: will have a calculate this based on hard/soft lockups
    const [availableRedemptionAmount, setAvailableRedemptionAmount] =
        useState<number>(0);
    const userCantRedeem = availableRedemptionAmount <= 0;

    const [showLots, setShowLots] = useState<boolean>(
        redemptionForm?.canEditLots || false
    );

    const createStepFormInitialValues = {
        totalRedemptionAmount: redemptionForm?.totalRedemptionAmount || 0,
        canEditLots: redemptionForm?.canEditLots || false,
        redemptions: redemptions,
    };

    useMountEffect(() => {
        async function RunAsync() {
            let redemps: RedemptionLotItem[] = [];
            if (redemptionForm?.redemptions) {
                redemps = redemptionForm.redemptions;
            } else {
                // The below should be reworked to take a 'Position' into consideration.
                const relatedSubs = await GetRelatedSubscriptions(
                    subscription._id
                );

                redemps.push(
                    getRedemptionLotItem(
                        relatedSubs.originalSubscription,
                        product
                    )
                );

                for (const latterSubscription of relatedSubs.latterSubscriptions.filter(
                    (a) => a.status === SubscriptionStatusEnum.approved
                )) {
                    redemps.push(
                        getRedemptionLotItem(latterSubscription, product)
                    );
                }
                // order by lastDate ASC
                redemps = redemps.sort((a, b) => {
                    const dateA = dayjs(a.subscriptionApprovedDate);
                    const dateB = dayjs(b.subscriptionApprovedDate);
                    return dateA.unix() - dateB.unix();
                });
                // set the lot number
                redemps.forEach((redemption, index) => {
                    redemption.lotNumber = index + 1;
                });
            }
            setRedemptions(redemps);

            setTotalInvestmentAmount(
                redemps.reduce((a, b) => a + (b.subscriptionAmount || 0), 0)
            );
            setAvailableRedemptionAmount(
                redemps.reduce(
                    (a, b) => a + (b.maximumRedeemableAmount || 0),
                    0
                )
            );
        }

        RunAsync();
    });

    const calculateAndSetLotRedemptions = (
        setFieldValue: (field: string, value: any, touched: boolean) => void,
        totalAmountToRedeem: number,
        canEditLots: boolean
    ) => {
        if (canEditLots) {
            return;
        }
        // calculate the amount for each lot based on a FIFO basis
        let tempAvailableRedemptionAmount = availableRedemptionAmount;
        let leftOverAmount = totalAmountToRedeem;
        const tempRedemptions = redemptions;

        tempRedemptions.forEach((redemption, index) => {
            if (tempAvailableRedemptionAmount <= 0) {
                return;
            }
            const amount =
                Math.round(
                    Math.min(
                        leftOverAmount,
                        redemption.maximumRedeemableAmount
                    ) * 100
                ) / 100;

            setFieldValue(
                `redemptions[${index}].redeemedAmount`,
                amount,
                false
            );

            leftOverAmount -= amount;
            tempAvailableRedemptionAmount -= amount;
        });
    };

    const submitHandler = (values: RedemptionForm) => {
        setRedemptionForm(values);
        nextStep();
    };

    const validationSchema = Yup.object({
        totalRedemptionAmount: Yup.number()
            .typeError('Must be a number')
            .required('Required')
            .min(1, 'Must be greater than 0')
            .max(
                availableRedemptionAmount,
                `Must be less than or equal to your available redemption amount, which is ${getFormattedCurrency(
                    availableRedemptionAmount,
                    subscription.subscriptionCurrencyCode
                )}.${
                    availableRedemptionAmount > 0 &&
                    availableRedemptionAmount < totalInvestmentAmount
                        ? ' The available redemption amount may be less than your total position size due to a hard lockup which has not yet expired'
                        : ''
                }`
            ),
        redemptions: Yup.array().when(
            ['totalRedemptionAmount'],
            (totalRedemptionAmount: number) => {
                return Yup.array()
                    .of(
                        Yup.object().shape({
                            redeemedAmount: Yup.number()
                                .typeError('Must be a number')
                                .required('Required')
                                .min(0, 'Must be greater than 0')
                                .max(
                                    Yup.ref('maximumRedeemableAmount'),
                                    ({ max }) =>
                                        `Must be less than or equal to ${getFormattedCurrency(
                                            max,
                                            subscription.subscriptionCurrencyCode
                                        )}`
                                ),
                        })
                    )
                    .test(
                        'nonExclusiveTest1',
                        'The sum of the redemption amounts for each lot must match the Total Redemption Amount. Please review and adjust the redemption amount values accordingly',
                        (a?: any[]) => {
                            const redemptions = a as RedemptionLotItem[];
                            if (
                                !totalRedemptionAmount ||
                                isNaN(totalRedemptionAmount)
                            ) {
                                return true;
                            }

                            const test =
                                redemptions &&
                                redemptions.reduce(
                                    (a, b) =>
                                        a +
                                        (b.redeemedAmount
                                            ? b.redeemedAmount
                                            : 0),
                                    0
                                ) == totalRedemptionAmount;

                            return !!test;
                        }
                    );
            }
        ),
    });

    const readableNextRedemptionDate =
        product.openEndedInformation?.redemptionFrequency &&
        product.openEndedInformation.redemptionFrequencyDeadline &&
        nextDateString(
            product.openEndedInformation.redemptionFrequency,
            product.openEndedInformation.redemptionFrequencyDeadline,
            product.openEndedInformation.redemptionNoticeDays || 0
        );

    const hasAvailableRedemptionAmount =
        availableRedemptionAmount > 0 &&
        redemptions.some((a) => a.maximumRedeemableAmount > 0);

    return (
        <div>
            <h1 className="text-3xl text-logo-blue pb-5">Create Redemption</h1>

            <ProductLargeThumbnail
                productName={product.name}
                productThumbnailUrl={withToken(
                    product?.thumbnail || product?.images?.[0].url || ''
                )}
                shareClassName={subscription.shareClass?.name}
                shareClassType={subscription.shareClass?.type}
                assetClassType={product?.assetClassType}
            />

            {!hasAvailableRedemptionAmount && (
                <AlertBox
                    alertType={'error'}
                    title="A redemption cannot be created."
                    message="All positions in this fund are currently in a hard lock up period. There is no amount that can redeemed until the hard lock-up expires."
                />
            )}

            <p className="text-base pt-3">
                Total investment amount:{' '}
                <strong>
                    {getFormattedCurrency(
                        totalInvestmentAmount,
                        subscription.subscriptionCurrencyCode
                    )}
                </strong>
            </p>
            <p className="text-base pt-3">
                Available redemption amount:{' '}
                <strong>
                    {getFormattedCurrency(
                        availableRedemptionAmount,
                        subscription.subscriptionCurrencyCode
                    )}
                </strong>
            </p>
            <p className="text-base pt-2 pb-1">
                Next redemption date:{' '}
                <strong>{readableNextRedemptionDate}</strong>
            </p>

            <Formik
                initialValues={createStepFormInitialValues}
                onSubmit={submitHandler}
                validationSchema={validationSchema}
                enableReinitialize
            >
                {({ values, setFieldValue, errors, isValid, isSubmitting }) => (
                    <Form>
                        <div className="pb-3"></div>
                        <FormInputBox
                            name={`totalRedemptionAmount`}
                            label="Total Redemption Amount"
                            min={0}
                            placeholder="Redemption Amount"
                            rightIcon={subscription.shareClass?.currency}
                            type="number"
                            onChange={(e) => {
                                const redemptionAmount = e.target.valueAsNumber;

                                calculateAndSetLotRedemptions(
                                    setFieldValue,
                                    redemptionAmount,
                                    values.canEditLots
                                );

                                setFieldValue(
                                    'totalRedemptionAmount',
                                    redemptionAmount,
                                    false
                                );
                            }}
                            disabled={userCantRedeem}
                        />
                        <p className="text-base pt-2 pb-2">
                            <strong>Note:</strong> Holdings will by default be
                            redeemed on a FIFO basis, unless lots are
                            specifically selected below:
                        </p>
                        <div>
                            <p
                                className="blue cursor-pointer inline-flex"
                                onClick={() => {
                                    setShowLots((a) => !a);
                                }}
                            >
                                Show lots &nbsp;
                                <Icons
                                    name={
                                        showLots ? 'ChevronUp' : 'ChevronDown'
                                    }
                                />
                            </p>
                        </div>

                        {showLots && (
                            <>
                                <div className="flex flex-row-reverse ">
                                    <div>
                                        <Field
                                            name={`canEditLots`}
                                            type="checkbox"
                                            label="Edit Lots"
                                        >
                                            {({
                                                field,
                                            }: {
                                                field: FieldInputProps<string>;
                                                meta: FieldMetaProps<string>;
                                            }) => (
                                                <Checkbox
                                                    {...field}
                                                    label="Edit Lots"
                                                />
                                            )}
                                        </Field>
                                    </div>
                                </div>
                                <FieldArray
                                    name="redemptions"
                                    render={() => {
                                        return (
                                            <div className="w-full">
                                                {values.redemptions &&
                                                    values.redemptions.length >
                                                        0 &&
                                                    values?.redemptions.map(
                                                        (redemption, i) => (
                                                            <div
                                                                key={
                                                                    redemption.subscriptionId
                                                                }
                                                                className="grid grid-cols-6 gap-2 border-t-2 border-gray-200 pt-4 pb-2"
                                                            >
                                                                <div className="col-span-3">
                                                                    <p>
                                                                        Lot{' '}
                                                                        {
                                                                            redemption.lotNumber
                                                                        }
                                                                    </p>
                                                                    <p>
                                                                        Subscription
                                                                        Approved
                                                                        Date:{' '}
                                                                        <strong>
                                                                            {dateShortestFormatter(
                                                                                redemption.subscriptionApprovedDate
                                                                            )}
                                                                        </strong>
                                                                    </p>
                                                                    <p className="pt-2">
                                                                        Subscription
                                                                        amount:{' '}
                                                                        <strong>
                                                                            {getFormattedCurrency(
                                                                                redemption.subscriptionAmount,
                                                                                redemption.currencyCode
                                                                            )}
                                                                        </strong>
                                                                    </p>
                                                                    <p className="pt-2">
                                                                        Available
                                                                        amount:{' '}
                                                                        <strong>
                                                                            {getFormattedCurrency(
                                                                                redemption.maximumRedeemableAmount,
                                                                                redemption.currencyCode
                                                                            )}
                                                                        </strong>
                                                                    </p>

                                                                    {Number.isFinite(
                                                                        redemption
                                                                            .softLockup
                                                                            .feePercentage
                                                                    ) && (
                                                                        <p className="pt-2">
                                                                            Fee:{' '}
                                                                            <strong>
                                                                                {getFormattedPercentage(
                                                                                    redemption
                                                                                        .softLockup
                                                                                        .feePercentage ||
                                                                                        0
                                                                                )}
                                                                            </strong>
                                                                        </p>
                                                                    )}
                                                                </div>
                                                                <div className="col-span-3">
                                                                    <FormInputBox
                                                                        min={0}
                                                                        name={`redemptions.[${i}].redeemedAmount`}
                                                                        label="Redemption Amount"
                                                                        placeholder="Redemption Amount"
                                                                        rightIcon={
                                                                            subscription
                                                                                .shareClass
                                                                                ?.currency
                                                                        }
                                                                        type="number"
                                                                        disabled={
                                                                            !values.canEditLots
                                                                        }
                                                                        onChange={(
                                                                            e
                                                                        ) => {
                                                                            const redeemedAmount =
                                                                                e
                                                                                    .target
                                                                                    .valueAsNumber;

                                                                            setFieldValue(
                                                                                `redemptions.[${i}].redeemedAmount`,
                                                                                redeemedAmount,
                                                                                false
                                                                            );
                                                                        }}
                                                                    />
                                                                    {Number.isFinite(
                                                                        redemption
                                                                            .softLockup
                                                                            .feePercentage
                                                                    ) && (
                                                                        <p className="pt-2">
                                                                            Redemption
                                                                            Fee:{' '}
                                                                            <strong>
                                                                                {getFormattedCurrency(
                                                                                    (redemption.redeemedAmount *
                                                                                        (redemption
                                                                                            .softLockup
                                                                                            .feePercentage ||
                                                                                            0)) /
                                                                                        100,
                                                                                    redemption.currencyCode
                                                                                )}
                                                                            </strong>
                                                                        </p>
                                                                    )}
                                                                </div>
                                                                {!hasAvailableRedemptionAmount &&
                                                                    redemption
                                                                        .hardLockup
                                                                        ?.unlockDate && (
                                                                        <div className="col-span-6">
                                                                            <AlertBox
                                                                                alertType={
                                                                                    'warning'
                                                                                }
                                                                                title="Not redeemable"
                                                                                message={`This position is not redeemable as it is still in the hard lock-up period. The lock-up period will be over on the ${dateShortestFormatter(
                                                                                    redemption
                                                                                        .hardLockup
                                                                                        .unlockDate
                                                                                )}`}
                                                                            />
                                                                        </div>
                                                                    )}
                                                            </div>
                                                        )
                                                    )}
                                            </div>
                                        );
                                    }}
                                />
                            </>
                        )}

                        {typeof errors?.redemptions == 'string' && (
                            <div className="flex content-start text-red-500 text-sm mb-4">
                                {errors?.redemptions}
                            </div>
                        )}
                        {/* <div>errors:</div>
                        <div className="pb-3">
                            {JSON.stringify(errors, null, 2)}
                        </div> */}

                        {/* 
                        <div>values:</div>
                        <div className="pb-3">
                            {JSON.stringify(values, null, 2)}
                        </div> */}

                        <div className="flex flex-row justify-between w-full mt-10">
                            <Button
                                buttonType="secondary"
                                type="button"
                                label="Cancel"
                                onClick={() => {
                                    navigate(-1);
                                }}
                            />
                            <Button
                                buttonType="primary"
                                type="submit"
                                label="Save"
                                disabled={
                                    userCantRedeem || !isValid || isSubmitting
                                }
                            />
                        </div>
                    </Form>
                )}
            </Formik>
        </div>
    );
};

export default CreateStep;
