import React, {
    createContext,
    useState,
    useEffect,
    useContext,
    useCallback,
} from 'react';
import { useNavigate } from 'react-router-dom';

import {
    SubscriptionTemplatesGetAll,
    SubscriptionsTemplateGet,
    SubscriptionsTemplatePost,
    SubscriptionsTemplatePut,
    SubscriptionsTemplateNamePut,
    SubscriptionsTemplatePoliticalExposurePut,
    SubscriptionsTemplateBeneficialOwnershipPut,
    SubscriptionsTemplateDistributorDetailsPut,
    SubscriptionsTemplateContactsPut,
    SubscriptionsTemplateSourceOfWealthPut,
    SubscriptionsTemplateDistributionOfFundsPut,
    SubscriptionsTemplateGermanSemiProfessionalCategorisationPut,
    SubscriptionTemplateTaxInformationPut,
} from '@api/SubscriptionTemplates';

import {
    BeneficialOwnership,
    DistributionOfFunds,
    DistributorDetailsRequestBody,
    PoliticalExposure,
    SourceOfFundsAndWealth,
    SubscriptionGermanSemiProfessionalCategorisation,
    SubscriptionTemplateRequestBody,
    SubscriptionTemplateResponse,
    TaxInformation,
} from '@interfaces/Api';
import { SubscriptionContact } from 'common';
export interface SubscriptionTemplateStateContextProps {
    subscriptionTemplates: SubscriptionTemplateResponse[];
    subscriptionTemplate: SubscriptionTemplateResponse;
    getSubscriptionTemplates: (
        endInvestorProfileId?: string
    ) => Promise<SubscriptionTemplateResponse[] | undefined>;
    putSubscriptionTemplate: (
        id: string,
        body: SubscriptionTemplateResponse
    ) => Promise<void>;
    createSubscriptionTemplate: (
        bodyObj: any
    ) => Promise<SubscriptionTemplateResponse | undefined>;
    getSubscriptionTemplate: (
        id: string
    ) => Promise<SubscriptionTemplateResponse | undefined>;

    putSubscriptionTemplateBeneficialOwnership: (
        subscriptionTemplateId: string,
        bodyObj: BeneficialOwnership
    ) => Promise<BeneficialOwnership | undefined>;
    putSubscriptionTemplatePoliticalExposure: (
        subscriptionTemplateId: string,
        bodyObj: PoliticalExposure
    ) => Promise<PoliticalExposure | undefined>;
    putSubscriptionTemplateContacts: (
        subscriptionTemplateId: string,
        bodyObj: SubscriptionContact[]
    ) => Promise<SubscriptionContact[] | undefined>;
    putSubscriptionTemplateTaxInformation: (
        subscriptionTemplateId: string,
        bodyObj: TaxInformation
    ) => Promise<TaxInformation | undefined>;
    putSubscriptionTemplateSourceOfWealth: (
        subscriptionTemplateId: string,
        bodyObj: SourceOfFundsAndWealth
    ) => Promise<SourceOfFundsAndWealth | undefined>;
    putSubscriptionTemplateDistributionOfFunds: (
        subscriptionTemplateId: string,
        bodyObj: DistributionOfFunds
    ) => Promise<DistributionOfFunds | undefined>;
    putSubscriptionTemplateDistributorDetails: (
        subscriptionTemplateId: string,
        bodyObj: DistributorDetailsRequestBody
    ) => Promise<DistributorDetailsRequestBody | undefined>;
    putSubscriptionTemplateGermanSemiProfessionalCategorisation: (
        subscriptionTemplateId: string,
        bodyObj: SubscriptionGermanSemiProfessionalCategorisation
    ) => Promise<SubscriptionGermanSemiProfessionalCategorisation | undefined>;
    putSubscriptionTemplateName: (
        subscriptionTemplateId: string,
        bodyObj: any
    ) => Promise<SubscriptionTemplateRequestBody | undefined>;
}

const subscriptionTemplatesDefaultState: SubscriptionTemplateStateContextProps =
    {
        subscriptionTemplates: [],
        subscriptionTemplate: { _id: '' },
        getSubscriptionTemplates: async (endInvestorProfileId) => undefined,
        putSubscriptionTemplate: async (subscriptionTemplateId, body) => {},
        createSubscriptionTemplate: async (body) => undefined,
        getSubscriptionTemplate: async (subscriptionTemplateId) => undefined,

        putSubscriptionTemplateBeneficialOwnership: async (
            subscriptionTemplateId,
            body
        ) => undefined,
        putSubscriptionTemplatePoliticalExposure: async (
            subscriptionTemplateId,
            body
        ) => undefined,
        putSubscriptionTemplateContacts: async (subscriptionTemplateId, body) =>
            undefined,
        putSubscriptionTemplateTaxInformation: async (
            subscriptionTemplateId,
            body
        ) => undefined,
        putSubscriptionTemplateSourceOfWealth: async (
            subscriptionTemplateId,
            body
        ) => undefined,
        putSubscriptionTemplateDistributionOfFunds: async (
            subscriptionTemplateId,
            body
        ) => undefined,
        putSubscriptionTemplateDistributorDetails: async (
            subscriptionTemplateId,
            body
        ) => undefined,
        putSubscriptionTemplateGermanSemiProfessionalCategorisation: async (
            subscriptionTemplateId,
            body
        ) => undefined,
        putSubscriptionTemplateName: async (subscriptionTemplateId, body) =>
            undefined,
    };

export const SubscriptionTemplateStateContext =
    createContext<SubscriptionTemplateStateContextProps>(
        subscriptionTemplatesDefaultState
    );

export const useSubscriptionTemplateState = () => {
    return useContext(SubscriptionTemplateStateContext);
};

export const SubscriptionTemplatesProvider: React.FC = ({
    children,
}: React.PropsWithChildren<{}>) => {
    const navigate = useNavigate();

    const [subscriptionTemplates, setSubscriptionTemplates] = useState<
        SubscriptionTemplateResponse[]
    >(subscriptionTemplatesDefaultState.subscriptionTemplates);

    const [subscriptionTemplate, setSubscriptionTemplate] =
        useState<SubscriptionTemplateResponse>(
            subscriptionTemplatesDefaultState.subscriptionTemplate
        );

    const getSubscriptionTemplates = useCallback(
        async (endInvestorProfileId?: string) => {
            try {
                const result = await SubscriptionTemplatesGetAll(
                    endInvestorProfileId
                );
                setSubscriptionTemplates(result);
                return result;
            } catch (error: any) {
                console.log(
                    'Error occurred on Get All Subscriptions data!',
                    error
                );
            }
        },
        []
    );

    const getSubscriptionTemplate = async (subscriptionTemplateId: string) => {
        try {
            const result = await SubscriptionsTemplateGet(
                subscriptionTemplateId
            );
            setSubscriptionTemplate(result);
            return result;
        } catch (error) {
            console.error(
                'Cannot find subscription template with id:' +
                    subscriptionTemplateId,
                error
            );
            navigate('not-found');
        }
    };

    const createSubscriptionTemplate = async (
        body: SubscriptionTemplateRequestBody
    ) => {
        const cloneObj = await SubscriptionsTemplatePost(body);

        setSubscriptionTemplates((val) => [...val, cloneObj]);
        return cloneObj;
    };

    const putSubscriptionTemplate = async (
        subscriptionTemplateId: string,
        body: any
    ): Promise<any | void> => {
        const putObj = await SubscriptionsTemplatePut(
            subscriptionTemplateId,
            body
        );
        setSubscriptionTemplate((val) => ({ ...val, ...putObj }));

        return putObj;
    };

    const putSubscriptionTemplateBeneficialOwnership = async (
        subscriptionTemplateId: string,
        body: BeneficialOwnership
    ): Promise<any | void> => {
        const putObj = await SubscriptionsTemplateBeneficialOwnershipPut(
            subscriptionTemplateId,
            body
        );
        setSubscriptionTemplate({
            ...subscriptionTemplate,
            beneficialOwnership: putObj,
        });
        return putObj;
    };

    const putSubscriptionTemplatePoliticalExposure = async (
        subscriptionTemplateId: string,
        body: PoliticalExposure
    ): Promise<any | void> => {
        const putObj = await SubscriptionsTemplatePoliticalExposurePut(
            subscriptionTemplateId,
            body
        );
        setSubscriptionTemplate({
            ...subscriptionTemplate,
            politicalExposure: putObj,
        });
        return putObj;
    };

    const putSubscriptionsTemplateDistributionOfFunds = async (
        subscriptionTemplateId: string,
        body: DistributionOfFunds
    ) => {
        const putObj = await SubscriptionsTemplateDistributionOfFundsPut(
            subscriptionTemplateId,
            body
        );
        setSubscriptionTemplate((val) => ({
            ...val,
            distributionOfFunds: putObj,
        }));
        return putObj;
    };

    const putSubscriptionTemplateName = async (
        subscriptionTemplateId: string,
        body: SubscriptionTemplateRequestBody
    ) => {
        const putObj = await SubscriptionsTemplateNamePut(
            subscriptionTemplateId,
            body
        );

        setSubscriptionTemplate((val) => ({ ...val, ...putObj }));
        return putObj;
    };

    const putSubscriptionTemplateDistributorDetails = async (
        subscriptionTemplateId: string,
        body: DistributorDetailsRequestBody
    ) => {
        const putObj = await SubscriptionsTemplateDistributorDetailsPut(
            subscriptionTemplateId,
            body
        );
        setSubscriptionTemplate((val) => ({
            ...val,
            distributorDetails: putObj,
        }));
        return putObj;
    };

    const putSubscriptionTemplateContacts = async (
        subscriptionTemplateId: string,
        contacts: SubscriptionContact[]
    ) => {
        const putObj = await SubscriptionsTemplateContactsPut(
            subscriptionTemplateId,
            contacts
        );
        setSubscriptionTemplate({ ...subscriptionTemplate, contacts: putObj });
        return putObj;
    };

    const putSubscriptionTemplateTaxInformation = async (
        subscriptionTemplateId: string,
        taxInformation: TaxInformation
    ) => {
        const putObj = await SubscriptionTemplateTaxInformationPut(
            subscriptionTemplateId,
            taxInformation
        );
        setSubscriptionTemplate({
            ...subscriptionTemplate,
            taxInformation: putObj,
        });
        return putObj;
    };

    const putSubscriptionTemplateSourceOfWealth = async (
        subscriptionTemplateId: string,
        body: SourceOfFundsAndWealth
    ) => {
        const putObj = await SubscriptionsTemplateSourceOfWealthPut(
            subscriptionTemplateId,
            body
        );
        setSubscriptionTemplate({
            ...subscriptionTemplate,
            sourceOfFundsAndWealth: body,
        });
        return putObj;
    };

    const putSubscriptionTemplateDistributionOfFunds = async (
        subscriptionTemplateId: string,
        body: DistributionOfFunds
    ) => {
        const putObj = await SubscriptionsTemplateDistributionOfFundsPut(
            subscriptionTemplateId,
            body
        );
        setSubscriptionTemplate((val) => ({
            ...val,
            distributionOfFunds: putObj,
        }));
        return putObj;
    };

    const putSubscriptionTemplateGermanSemiProfessionalCategorisation = async (
        subscriptionTemplateId: string,
        body: SubscriptionGermanSemiProfessionalCategorisation
    ) => {
        const putObj =
            await SubscriptionsTemplateGermanSemiProfessionalCategorisationPut(
                subscriptionTemplateId,
                body
            );
        setSubscriptionTemplate({
            ...subscriptionTemplate,
            germanSemiProfessionalCategorisation: body,
        });
        return putObj;
    };

    useEffect(() => {
        getSubscriptionTemplates();
    }, [getSubscriptionTemplates]);

    return (
        <SubscriptionTemplateStateContext.Provider
            value={{
                subscriptionTemplates,
                subscriptionTemplate,
                getSubscriptionTemplate,
                putSubscriptionTemplate,
                getSubscriptionTemplates,
                createSubscriptionTemplate,
                putSubscriptionTemplateBeneficialOwnership,
                putSubscriptionTemplatePoliticalExposure,
                putSubscriptionTemplateContacts,
                putSubscriptionTemplateTaxInformation,
                putSubscriptionTemplateSourceOfWealth,
                putSubscriptionTemplateDistributionOfFunds,
                putSubscriptionTemplateDistributorDetails,
                putSubscriptionTemplateGermanSemiProfessionalCategorisation,
                putSubscriptionTemplateName,
            }}
        >
            {children}
        </SubscriptionTemplateStateContext.Provider>
    );
};
