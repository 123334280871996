import { create } from 'zustand';
import { devtools } from 'zustand/middleware';
import {
    SubscriptionAttestations,
    SubscriptionGermanSemiProfessionalCategorisation,
    SubscriptionRequestBody,
    SubscriptionResponse,
    SubscriptionStatusRequestBody,
    Document,
} from '@interfaces/Api';
import {
    handleDeleteSubscription,
    handleFetchSubscriptions,
    handleGetSubscription,
    handleUpdateSubscription,
    handleUpdateSubscriptionStatus,
    handleUpdateSubscriptionBeneficialOwnership,
    handleUpdateSubscriptionPoliticalExposure,
    handleUpdateSubscriptionContacts,
    handleUpdateSubscriptionTaxInformation,
    handleUpdateSubscriptionSourceOfWealth,
    handleUpdateSubscriptionDistributionOfFunds,
    handleUpdateSubscriptionDistributorDetails,
    handleUpdateSubscriptionAttestations,
    handleUpdateSubscriptionGermanSemiProfessional,
    handleCreateSubscriptionStatus,
    handleUpdateManualSignedSubscriptionAgreement,
    handleUpdateSubscriptionReport,
    handleUpdateSubscriptionSupportingDocuments,
} from './Subscriptions.services';

type SubscriptionUpdateRequestPaylod = {
    subscriptionId: string;
    body: Partial<SubscriptionRequestBody>;
};

interface SubscriptionsStore {
    isLoading: boolean;
    subscriptions: SubscriptionResponse[];
    subscription: SubscriptionResponse;
    actions: {
        deleteSubscription: (subscriptionIdToDelete: string) => Promise<void>;
        fetchSubscriptions: (endInvestorProfileId?: string) => Promise<void>;
        getSubscription: (subscriptionId: string) => Promise<void>;
        resetSubscriptions: () => void;
        resetSubscription: () => void;
        updateSubscription: ({
            subscriptionId,
            body,
        }: {
            subscriptionId: string;
            body:
                | SubscriptionStatusRequestBody
                | Partial<SubscriptionRequestBody>;
        }) => Promise<void>;
        updateSubscriptionBeneficialOwnership: ({
            subscriptionId,
            body,
        }: {
            subscriptionId: string;
            body: Partial<SubscriptionRequestBody>;
        }) => Promise<void>;
        updateSubscriptionPoliticalExposure: ({
            subscriptionId,
            body,
        }: SubscriptionUpdateRequestPaylod) => Promise<void>;
        updateSubscriptionStatus: ({
            body,
            subscriptionId,
        }: {
            subscriptionId: string;
            body: SubscriptionStatusRequestBody;
        }) => Promise<void>;
        updateSubscriptionReport: ({
            subscriptionId,
            body,
        }: SubscriptionUpdateRequestPaylod) => Promise<void>;
        updateSubscriptionSupportingDocuments: ({
            subscriptionId,
            body,
        }: SubscriptionUpdateRequestPaylod) => Promise<void>;
        updateSubscriptionContacts: ({
            subscriptionId,
            body,
        }: SubscriptionUpdateRequestPaylod) => Promise<void>;
        updateSubscriptionTaxInformation: ({
            subscriptionId,
            body,
        }: SubscriptionUpdateRequestPaylod) => Promise<void>;
        updateSubscriptionSourceOfWealth: ({
            subscriptionId,
            body,
        }: SubscriptionUpdateRequestPaylod) => Promise<void>;
        updateSubscriptionDistributionOfFunds: ({
            subscriptionId,
            body,
        }: SubscriptionUpdateRequestPaylod) => Promise<void>;
        updateSubscriptionDistributorDetails: ({
            subscriptionId,
            body,
        }: SubscriptionUpdateRequestPaylod) => Promise<void>;
        updateSubscriptionAttestations: ({
            subscriptionId,
            body,
        }: {
            subscriptionId: string;
            body: SubscriptionAttestations;
        }) => Promise<void>;
        updateSubscriptionGermanSemiProfessional: ({
            subscriptionId,
            body,
        }: {
            subscriptionId: string;
            body: SubscriptionGermanSemiProfessionalCategorisation;
        }) => Promise<void>;
        createSubscriptionStatus: ({
            subscriptionId,
            body,
        }: {
            subscriptionId: string;
            body: SubscriptionStatusRequestBody;
        }) => Promise<void>;
        updateManualSignedSubscriptionAgreement: ({
            subscriptionId,
            body,
        }: {
            subscriptionId: string;
            body: Document;
        }) => Promise<void>;
    };
}

export const useSubscriptionsStore = create<SubscriptionsStore>()(
    devtools(
        (set, get): SubscriptionsStore => ({
            subscriptions: [],
            subscription: {} as SubscriptionResponse,
            isLoading: false,
            actions: {
                deleteSubscription: async (subscriptionIdToDelete) =>
                    await handleDeleteSubscription(set, subscriptionIdToDelete),
                fetchSubscriptions: async (endInvestorProfileId?: string) =>
                    await handleFetchSubscriptions(set, endInvestorProfileId),
                getSubscription: async (subscriptionId) =>
                    await handleGetSubscription(set, subscriptionId),
                resetSubscriptions: () => set({ subscriptions: [] }),
                resetSubscription: () =>
                    set({ subscription: {} as SubscriptionResponse }),
                updateSubscription: async ({
                    subscriptionId,
                    body,
                }: {
                    subscriptionId: string;
                    body:
                        | SubscriptionStatusRequestBody
                        | Partial<SubscriptionRequestBody>;
                }) =>
                    await handleUpdateSubscription({
                        get,
                        set,
                        subscriptionId,
                        body,
                    }),
                updateSubscriptionBeneficialOwnership: async ({
                    subscriptionId,
                    body,
                }) =>
                    await handleUpdateSubscriptionBeneficialOwnership({
                        get,
                        set,
                        subscriptionId,
                        body,
                    }),
                updateSubscriptionPoliticalExposure: async ({
                    subscriptionId,
                    body,
                }) =>
                    await handleUpdateSubscriptionPoliticalExposure({
                        get,
                        set,
                        subscriptionId,
                        body,
                    }),
                updateSubscriptionStatus: async ({ subscriptionId, body }) =>
                    await handleUpdateSubscriptionStatus({
                        set,
                        subscriptionId,
                        body,
                    }),
                updateSubscriptionContacts: async ({ subscriptionId, body }) =>
                    await handleUpdateSubscriptionContacts({
                        get,
                        set,
                        subscriptionId,
                        body,
                    }),
                updateSubscriptionReport: async ({ subscriptionId, body }) =>
                    await handleUpdateSubscriptionReport({
                        get,
                        set,
                        subscriptionId,
                        body,
                    }),
                updateSubscriptionSupportingDocuments: async ({
                    subscriptionId,
                    body,
                }) =>
                    await handleUpdateSubscriptionSupportingDocuments({
                        get,
                        set,
                        subscriptionId,
                        body,
                    }),
                updateSubscriptionTaxInformation: async ({
                    subscriptionId,
                    body,
                }) =>
                    await handleUpdateSubscriptionTaxInformation({
                        get,
                        set,
                        subscriptionId,
                        body,
                    }),
                updateSubscriptionSourceOfWealth: async ({
                    subscriptionId,
                    body,
                }) =>
                    await handleUpdateSubscriptionSourceOfWealth({
                        get,
                        set,
                        subscriptionId,
                        body,
                    }),
                updateSubscriptionDistributionOfFunds: async ({
                    subscriptionId,
                    body,
                }) =>
                    await handleUpdateSubscriptionDistributionOfFunds({
                        get,
                        set,
                        subscriptionId,
                        body,
                    }),
                updateSubscriptionDistributorDetails: async ({
                    subscriptionId,
                    body,
                }) =>
                    await handleUpdateSubscriptionDistributorDetails({
                        get,
                        set,
                        subscriptionId,
                        body,
                    }),
                updateSubscriptionAttestations: async ({
                    subscriptionId,
                    body,
                }) =>
                    await handleUpdateSubscriptionAttestations({
                        get,
                        set,
                        subscriptionId,
                        body,
                    }),
                updateSubscriptionGermanSemiProfessional: async ({
                    subscriptionId,
                    body,
                }) =>
                    await handleUpdateSubscriptionGermanSemiProfessional({
                        get,
                        set,
                        subscriptionId,
                        body,
                    }),
                createSubscriptionStatus: async ({ subscriptionId, body }) =>
                    await handleCreateSubscriptionStatus({
                        set,
                        subscriptionId,
                        body,
                    }),
                updateManualSignedSubscriptionAgreement: async ({
                    subscriptionId,
                    body,
                }) =>
                    await handleUpdateManualSignedSubscriptionAgreement({
                        get,
                        set,
                        subscriptionId,
                        body,
                    }),
            },
        })
    )
);
