import { AssetManagerResponse, AssetManagerRequest } from '@interfaces/AssetManager';
import { BaseUrl } from '@helpers/Environment';
import { Http } from '../libs/http';

const BASE_URL = `${BaseUrl}`;
const APIV2 = `/v2`;

export const getAllAssetManagers = async (): Promise<any> =>
    Http.get(`${BASE_URL}${APIV2}/asset-managers`);

export const getAssetManager = async (assetManagerId: string): Promise<any> =>
    Http.get(`${BASE_URL}${APIV2}/asset-managers/${assetManagerId}`);

export const assetManagerPost = async (assetManager: AssetManagerRequest): Promise<AssetManagerResponse> =>
    Http.post(`${BASE_URL}${APIV2}/asset-managers`, assetManager);


export const getAssetManagerProducts = async (
    assetManagerId: string
): Promise<AssetManagerResponse> =>
    Http.get(`${BASE_URL}/products/asset-manager/${assetManagerId}`);
