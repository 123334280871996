import * as Yup from 'yup';
import { Button, Icons, Tooltip } from '@components/Atoms';
import { Modal } from '@components/Molecules';
import { BasicForm } from '@components/Organisms';
import { Formik } from 'formik';

export interface IModalForm {
    label: string;
    showModal: boolean;
    toggleModal: (show: boolean) => void;
    handleSubmit: (values: any) => void;
    validationSchema: Yup.SchemaOf<{
        [key: string]: string;
    }>;
    initialValues: {
        [key: string]: string;
    };
    fields: {
        name: string;
        label: string;
        placeholder: string;
        type?: 'input' | 'select';
        options?: { label: string; value: string }[];
    }[];
    disabled?: boolean;
}

export const ModalForm = (props: IModalForm) => {
    const {
        fields,
        handleSubmit,
        initialValues,
        label,
        showModal,
        toggleModal,
        validationSchema,
        disabled,
    } = props;

    if (disabled) {
        return null;
    }

    if (!showModal)
        return (
            <Tooltip tip={label}>
                <Button
                    dataTestId="add-bank-account-button"
                    hasIconOnly
                    endIcon={<Icons name="PlusIcon" size="small" />}
                    onClick={() => toggleModal(true)}
                    disabled={disabled}
                />
            </Tooltip>
        );

    return (
        <Modal className="px-10 py-6" width="w-2/3" show={showModal}>
            <h2 className="text-3xl">{label}</h2>

            <div className="w-full mt-2">
                <Formik
                    validationSchema={validationSchema}
                    initialValues={initialValues}
                    onSubmit={handleSubmit}
                >
                    <BasicForm
                        fields={fields}
                        handleSubmit={handleSubmit}
                        onCancel={() => toggleModal(false)}
                    />
                </Formik>
            </div>
        </Modal>
    );
};
