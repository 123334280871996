import { useTranslation } from 'react-i18next';
import { AuthRoleEnum, EndInvestorProfileResponse, getEndInvestorTypeLabels } from '@interfaces/Api';
import { Badge } from '@components/Atoms';
import { getEndInvestorName } from '@helpers/EndInvestorHelper';
import { useAuthState } from '@contexts/AuthContext';

const EndInvestorNameHeadingComponent: React.FC<{
    endInvestorProfile: EndInvestorProfileResponse;
}> = (props) => {
    {
        const { t } = useTranslation();
        const { currentUser } = useAuthState();

        const isEndInvestor =
            currentUser?.user.role === AuthRoleEnum.endInvestor;

        const title = isEndInvestor
            ? 'Portfolio'
            : getEndInvestorName(props.endInvestorProfile);

        return (
            <h1 className="text-3xl text-logo-blue">
                <span className="pr-3 font-bold">{title}</span>
                <span className="md:inline-block align-middle">
                    {props.endInvestorProfile?.endInvestorType &&
                        !isEndInvestor && (
                            <Badge
                                label={
                                    getEndInvestorTypeLabels(t,
                                        props.endInvestorProfile
                                            ?.endInvestorType
                                    ) || ''
                                }
                                badgeType="primary"
                                sizeType="small"
                            />
                        )}
                </span>
            </h1>
        );
    }
};

export default EndInvestorNameHeadingComponent;
