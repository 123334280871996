import React, { useMemo } from 'react';

interface PaginationProps {
    rowsPerPage: number | string;
    itemsLength: number;
    paginate: Function;
    currentPage: number;
    paginateFront: () => void;
    paginateBack: () => void;
    className?: string;
    onPageSizeChange?(newPageSize: number | string): void;
}

const ALL = 'All';
const DEFAULT_SIZE = 50;
const PAGE_SIZES = [20, 50, 100, ALL];

export const Pagination: React.FC<PaginationProps> = ({
    rowsPerPage = DEFAULT_SIZE,
    itemsLength,
    paginate,
    currentPage,
    paginateFront,
    paginateBack,
    className,
    onPageSizeChange = () => {},
}) => {
    const pageNumbers = useMemo(() => {
        const result = [];

        if (typeof rowsPerPage === 'number') {
            for (let i = 1; i <= Math.ceil(itemsLength / rowsPerPage); i++) {
                result.push(i);
            }
        } else {
            result.push(1);
        }

        return result;
    }, [itemsLength, rowsPerPage]);

    return (
        <div
            className={`${className} z-[1] sm:flex-1 sm:flex sm:items-center sm:justify-between py-2`}
        >
            <div className="pb-3 md:pb-0">
                {typeof rowsPerPage === 'number' && (
                    <p className="text-sm text-gray-700">
                        Showing
                        <span className="font-medium">
                            {' '}
                            {currentPage * rowsPerPage - rowsPerPage + 1}{' '}
                        </span>
                        to
                        <span className="font-medium">
                            {' '}
                            {currentPage * rowsPerPage > itemsLength
                                ? itemsLength
                                : currentPage * rowsPerPage}{' '}
                        </span>
                        of
                        <span className="font-medium"> {itemsLength} </span>
                        results
                    </p>
                )}

                {rowsPerPage === ALL ? (
                    <p className="text-sm text-gray-700">
                        Showing <span className="font-medium">1</span> to
                        <span className="font-medium"> {itemsLength} </span> of
                        <span className="font-medium"> {itemsLength} </span>
                        results
                    </p>
                ) : (
                    <></>
                )}
            </div>
            <nav
                className="block relative z-0 inline-flex rounded-md  -space-x-px"
                aria-label="Pagination"
            >
                <div className="flex mr-4">
                    <select
                        className="border rounded-lg bg-white"
                        value={rowsPerPage}
                        onChange={(event) => {
                            const value = event.target.value;
                            paginate(1);
                            onPageSizeChange(
                                value === ALL ? ALL : Number(value)
                            );
                        }}
                    >
                        {PAGE_SIZES.map((item) => (
                            <option key={item} value={item}>
                                {item}
                            </option>
                        ))}
                    </select>
                </div>
                <ul className="flex pl-0 rounded list-none flex-wrap">
                    <li>
                        <button
                            onClick={() => {
                                if (currentPage > 1) paginateBack();
                            }}
                            className={`relative inline-flex items-center px-2 py-2 rounded-l-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50 ${
                                currentPage == 1 ? 'disabled' : ''
                            }`}
                        >
                            <span>Previous</span>
                        </button>

                        {pageNumbers.map((number, i) => (
                            <button
                                key={i.toString()}
                                onClick={() => {
                                    paginate(number);
                                }}
                                className={
                                    currentPage === number
                                        ? 'bg-brand-navy-light border-brand-navy-light text-logo-blue hover:bg-brand-navy-light relative inline-flex items-center px-4 py-2 border text-sm font-medium'
                                        : 'bg-white border-gray-300 text-gray-500 hover:bg-brand-navy-light relative inline-flex items-center px-4 py-2 border text-sm font-medium'
                                }
                            >
                                {number}
                            </button>
                        ))}

                        <button
                            onClick={() => {
                                if (currentPage < pageNumbers.length)
                                    paginateFront();
                            }}
                            className="relative inline-flex items-center px-2 py-2 rounded-r-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50"
                        >
                            <span>Next</span>
                        </button>
                    </li>
                </ul>
            </nav>
        </div>
    );
};
