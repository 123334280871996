import {
    BeneficialOwnership,
    DistributionOfFunds,
    DistributorDetailsRequestBody,
    DistributorDetailsResponse,
    PoliticalExposure,
    SourceOfFundsAndWealth,
    SubscriptionTemplateResponse,
    SubscriptionGermanSemiProfessionalCategorisation,
    SubscriptionTemplateRequestBody,
    TaxInformation,
} from '@interfaces/Api';
import { SubscriptionContact } from 'common';

import { BaseUrl } from '@helpers/Environment';
import { Http } from '../libs/http';

export const API_SUBSCRIPTION_TEMPLATES_URL = `${BaseUrl}/subscription-templates`;

export const SubscriptionTemplatesGetAll = async (
    endInvestorProfileId?: string
) =>
    Http.get<SubscriptionTemplateResponse[]>(
        API_SUBSCRIPTION_TEMPLATES_URL,
        undefined,
        {
            endInvestorProfileId,
        }
    );

export const SubscriptionsTemplateGet = async (
    subscriptionTemplateId: string
) =>
    Http.get<SubscriptionTemplateResponse>(
        `${API_SUBSCRIPTION_TEMPLATES_URL}/${subscriptionTemplateId}`
    );

export const SubscriptionsTemplatePost = async (
    body: SubscriptionTemplateRequestBody
) =>
    Http.post<SubscriptionTemplateResponse>(
        API_SUBSCRIPTION_TEMPLATES_URL,
        body
    );

// Updating Template
export const SubscriptionsTemplatePut = async (
    subscriptionTemplateId: string,
    body: SubscriptionTemplateResponse
) =>
    Http.put<SubscriptionTemplateResponse>(
        `${API_SUBSCRIPTION_TEMPLATES_URL}/${subscriptionTemplateId}`,
        body
    );

export const SubscriptionsTemplateDelete = async (
    subscriptionTemplateId: string
) => Http.delete(`${API_SUBSCRIPTION_TEMPLATES_URL}/${subscriptionTemplateId}`);

export const SubscriptionsTemplatePoliticalExposurePut = async (
    subscriptionTemplateId: string,
    politicalPersonExposure: PoliticalExposure
) =>
    Http.put<PoliticalExposure>(
        `${API_SUBSCRIPTION_TEMPLATES_URL}/${subscriptionTemplateId}/political-exposure`,
        politicalPersonExposure
    );

export const SubscriptionsTemplateBeneficialOwnershipPut = async (
    subscriptionTemplateId: string,
    beneficialOwnership: BeneficialOwnership
) =>
    Http.put<BeneficialOwnership>(
        `${API_SUBSCRIPTION_TEMPLATES_URL}/${subscriptionTemplateId}/beneficial-ownership`,
        beneficialOwnership
    );

export const SubscriptionsTemplateNamePut = async (
    subscriptionTemplateId: string,
    templateNameBody: any
) =>
    Http.put<SubscriptionTemplateResponse>(
        `${API_SUBSCRIPTION_TEMPLATES_URL}/${subscriptionTemplateId}`,
        templateNameBody
    );

export const SubscriptionsTemplateDistributorDetailsGet = async (
    subscriptionTemplateId: string
) =>
    Http.get<DistributorDetailsResponse>(
        `${API_SUBSCRIPTION_TEMPLATES_URL}/${subscriptionTemplateId}/distributor-details`
    );

export const SubscriptionsTemplateDistributorDetailsPut = async (
    subscriptionTemplateId: string,
    distributorDetails: DistributorDetailsRequestBody
) =>
    Http.put<DistributorDetailsResponse>(
        `${API_SUBSCRIPTION_TEMPLATES_URL}/${subscriptionTemplateId}/distributor-details`,
        distributorDetails
    );

export const SubscriptionsTemplateContactsPut = async (
    subscriptionTemplateId: string,
    subscriptionContacts: SubscriptionContact[]
) =>
    Http.put<SubscriptionContact[]>(
        `${API_SUBSCRIPTION_TEMPLATES_URL}/${subscriptionTemplateId}/contacts`,
        subscriptionContacts
    );

export const SubscriptionsTemplateSourceOfWealthPut = async (
    subscriptionTemplateId: string,
    sourceOfWealth: SourceOfFundsAndWealth
) =>
    Http.put<SourceOfFundsAndWealth>(
        `${API_SUBSCRIPTION_TEMPLATES_URL}/${subscriptionTemplateId}/source-of-funds-and-wealth`,
        sourceOfWealth
    );

export const SubscriptionsTemplateDistributionOfFundsPut = async (
    subscriptionTemplateId: string,
    distributionOfFunds: DistributionOfFunds
) =>
    Http.put<DistributionOfFunds>(
        `${API_SUBSCRIPTION_TEMPLATES_URL}/${subscriptionTemplateId}/distribution-of-funds`,
        distributionOfFunds
    );

export const SubscriptionsTemplateGermanSemiProfessionalCategorisationPut =
    async (
        subscriptionTemplateId: string,
        body: SubscriptionGermanSemiProfessionalCategorisation
    ) =>
        Http.put<SubscriptionGermanSemiProfessionalCategorisation>(
            `${API_SUBSCRIPTION_TEMPLATES_URL}/${subscriptionTemplateId}/german-semi-professional-categorisation`,
            body
        );

export const SubscriptionTemplateTaxInformationPut = (
    subscriptionTemplateId: string,
    taxInformation: TaxInformation
) => {
    return Http.put<TaxInformation>(
        `${API_SUBSCRIPTION_TEMPLATES_URL}/${subscriptionTemplateId}/tax-information`,
        taxInformation
    );
};
