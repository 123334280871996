import { getAllAmlKycRequirements } from '@api/KycAmlRequirements';
import { useMountEffect } from '@hooks/useMountEffect';
import { EndInvestorType } from 'common';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

interface KYCDocumentsInfoProps {
    endInvestorType: EndInvestorType;
}

const KYCDocumentsList: React.FC<KYCDocumentsInfoProps> = ({
    endInvestorType,
}) => {
    const { t } = useTranslation();
    const [documentList, setDocumentList] = useState<
        { documentName: string; furtherDetails?: string }[]
    >([]);

    useEffect(() => {
        getAllAmlKycRequirements().then((amlKycRequirements) => {
            const endInvestorRequirements = amlKycRequirements[endInvestorType];
            if (endInvestorRequirements) {
                setDocumentList(endInvestorRequirements);
            }
        });
    }, [endInvestorType]);

    return (
        <ul className="list-disc my-4 ml-4">
            {documentList.map((item, index) => (
                <li key={index} className="pb-1">
                    <p className="text-sm ">{item.documentName}</p>
                    {item.furtherDetails && (
                        <p className="text-xs pl-4 ">{item.furtherDetails}</p>
                    )}
                </li>
            ))}
        </ul>
    );
};

export default KYCDocumentsList;
