interface Environment {
    REACT_APP_S64_API_URL: string;
    REACT_APP_BUILD: string;
}

export const getEnvironmentVariable = (environmentKey: keyof Environment) => {
    return (
        process.env[environmentKey] ||
        // @ts-ignore;
        window?.customEnvironmentKeys?.[environmentKey] ||
        `<< Environment Key: ${environmentKey} NOT SET >>`
    );
};

export const BaseUrl = getEnvironmentVariable('REACT_APP_S64_API_URL');
