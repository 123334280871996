import { useTranslation } from 'react-i18next';
import { useRegisterInvestorState } from '../RegisterInvestor.context';
import { getServiceLevelTypeRadioOptions } from './ServiceLevelSelection';

export const ServiceLevelTypeSummary = (): JSX.Element => {
    const { t } = useTranslation();
    const { data } = useRegisterInvestorState();
    const value =
        getServiceLevelTypeRadioOptions(t).find(
            ({ value }) => value === data['serviceLevelType']
        )?.label || '';

    return (
        <p>
            <span className="font-bold mr-2">{t('client.registration.summary.service_level_type')}:</span>
            {value}
        </p>
    );
};
