import {
    createContext,
    PropsWithChildren,
    useContext,
    useEffect,
    useState,
} from 'react';

import { ConfigurationResponse } from '@interfaces/Api';

import { configurationGet } from '@api/Configuration';

import { Route, Routes } from 'react-router-dom';
import HealthCheck from '@pages/HealthCheck';
import {
    ClientEnum,
    EnvironmentEnum,
} from '@interfaces/Api/ConfigurationResponse';
import { useTheme } from '@hooks/useTheme';

export interface ConfigurationContextProps {
    configuration?: ConfigurationResponse;
    configurationLoading: boolean;
    themeName?: ClientEnum;
    preferences?: ConfigurationResponse['preferences'];
    isEnvironment: (environment: EnvironmentEnum) => boolean;
    isClient: (client: ClientEnum) => boolean;
    refreshConfiguration: () => void;
}

const configurationDefaultState: ConfigurationContextProps = {
    configuration: undefined,
    configurationLoading: false,
    isEnvironment: () => false,
    isClient: () => false,
    refreshConfiguration: () => {},
};

const ConfigurationContext = createContext<ConfigurationContextProps>(
    configurationDefaultState
);

const isS64Logo = (url: string) => url.includes('s64_logo.png');

export default ConfigurationContext;

export const useConfigurationState = () => {
    return useContext(ConfigurationContext);
};

export const ConfigurationProvider = ({ children }: PropsWithChildren<{}>) => {
    const [configuration, setConfiguration] = useState<ConfigurationResponse>();
    const [isConfigurationLoading, setIsConfigurationLoading] =
        useState<boolean>(true);

    const { themeName } = useTheme({configuration});

    useEffect(() => {
        setIsConfigurationLoading(true);
        refreshConfiguration();
    }, []);

    const refreshConfiguration = () => {
        const favicon = document.getElementById('favicon');
        configurationGet()
            .then((val) => {
                if (
                    val.branding.images?.navbarLogo?.url &&
                    isS64Logo(val.branding.images.navbarLogo?.url)
                ) {
                    val.branding.images.navbarLogo.url = '/assets/s64_logo.png';
                }

                if (
                    val.branding.images?.loginPageLogo?.url &&
                    isS64Logo(val.branding.images.loginPageLogo?.url)
                ) {
                    val.branding.images.loginPageLogo.url =
                        '/assets/s64_logo.png';
                }

                setConfiguration(val);

                if (val?.branding?.images?.favicon?.url) {
                    favicon?.setAttribute(
                        'href',
                        val.branding.images.favicon?.url
                    );
                } else {
                    favicon?.setAttribute('href', '/favicon.ico');
                }
            })
            .catch((err) => {
                console.error('Error loading api/configuration:', err);
            })
            .finally(() => {
                setIsConfigurationLoading(false);
            });
    };

    const isEnvironment = (environment: EnvironmentEnum) => {
        return environment === configuration?.environment;
    };

    const isClient = (client: ClientEnum) => {
        return client === configuration?.client;
    };

    return (
        <ConfigurationContext.Provider
            value={{
                configuration,
                configurationLoading: isConfigurationLoading,
                themeName,
                isEnvironment,
                isClient,
                preferences: configuration?.preferences,
                refreshConfiguration,
            }}
        >
            {!isConfigurationLoading && (
                <>
                    {configuration && (
                        <div data-theme={themeName} className="bg-white">
                            {children}
                        </div>
                    )}

                    {/* If the request to the configuration endpoint has
                    finished loading and there's no configuration,
                     assume that the configuration endpoint is down */}
                    {!configuration && (
                        <Routes>
                            <Route
                                path="health-check/"
                                element={<HealthCheck />}
                            />
                            <Route
                                path="*"
                                element={
                                    <div className="absolute inset-0 bg-white w-full h-screen flex flex-col justify-center items-center text-lg">
                                        <p>
                                            Configuration could not be loaded.
                                        </p>
                                        <p>Please contact support.</p>
                                    </div>
                                }
                            />
                        </Routes>
                    )}
                </>
            )}
        </ConfigurationContext.Provider>
    );
};
