import { Http } from '../libs/http';
import { BaseUrl } from '../Helpers/Environment';
import { RedemptionApplicationRequestBody } from '@interfaces/Api/RedemptionApplicationRequestBody';
import { RedemptionApplicationResponse } from '@interfaces/Api/RedemptionApplicationResponse';

const REDEMPTION_URL = `${BaseUrl}/redemption-applications`;

export const postRedemptionApplication = async (
    body: RedemptionApplicationRequestBody
) => {
    return await Http.post<RedemptionApplicationResponse>(
        `${REDEMPTION_URL}/`,
        body
    );
};

export const getRedemptionApplicationsBySubscriptionId = (
    subscriptionId: string
) => {
    return Http.get<RedemptionApplicationResponse[]>(
        `${REDEMPTION_URL}/subscription/${subscriptionId}`
    );
};

export const getRedemptionApplication = async (
    redemptionApplicationId: string
) => {
    return await Http.get<RedemptionApplicationResponse>(
        `${REDEMPTION_URL}/${redemptionApplicationId}`
    );
};
