import { Form, Formik } from 'formik';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import * as Yup from 'yup';

import { ProductResponse } from '@interfaces/Api';

import { useProductSubmit } from '@hooks/useProductSubmit';

import EditProductFormikButtons from '@pages/Admin/ProductsManagement/Components/EditProductFormikButtons';

import { FormInputBox, PhoneNumberBox } from '@components/Molecules';
import { useProducts } from '@stores/Products/useProducts';

const ContactInformation: React.FC = () => {
    const [formValue, setFormValue] = useState<Partial<ProductResponse>>({
        contact: {
            firstName: '',
            lastName: '',
            email: '',
        },
    });

    const { productId } = useParams();
    const { products } = useProducts();
    const product = products.filter((e) => e._id === productId)[0];

    useEffect(() => {
        if (productId && product) {
            setFormValue(product);
        }
    }, [product, productId]);

    const selectedValidationSchema = Yup.object().shape({
        contact: Yup.object().shape({
            firstName: Yup.string().required('First name is required'),
            lastName: Yup.string().required('Last name is required'),
            email: Yup.string()
                .required('Email is required')
                .email('Invalid email'),
        }),
        subscriptionAgreementCountersignature: Yup.string()
            .required('Subscription Agreement Countersignature is required')
            .email('Invalid email'),
    });

    const onSubmit = useProductSubmit(productId, `product-eligibility`);

    return (
        <div className="w-full">
            <h1 className="text-xl text-gray-500">Contact Information</h1>
            {(productId === 'new' || productId) && (
                <Formik
                    initialValues={formValue}
                    onSubmit={(values) => onSubmit({ ...product, ...values })}
                    validationSchema={selectedValidationSchema}
                    validateOnMount
                    enableReinitialize
                >
                    <Form className="mb-3">
                        <h3 className="text-sm font-normal leading-8 mb-3">
                            Please enter contact details for the product
                        </h3>
                        <FormInputBox
                            name="contact.salutation"
                            label="Salutation"
                            placeholder="Mr/Mrs"
                        />
                        <FormInputBox
                            name="contact.firstName"
                            label="First Name"
                        />
                        <FormInputBox
                            name="contact.lastName"
                            label="Last Name"
                        />
                        <FormInputBox name="contact.email" label="Email" />

                        <PhoneNumberBox
                            phoneDiallingCodeName="contact.phoneNumberDiallingCode"
                            phoneDiallingCodeLabel="Country Code"
                            phoneNumberName="contact.phoneNumber"
                            phoneNumberLabel="Phone Number"
                        />

                        <FormInputBox
                            name="subscriptionAgreementCountersignature"
                            label="Subscription Agreement Countersignature"
                            placeholder="Email"
                        />

                        <EditProductFormikButtons
                            productId={productId}
                            isReadOnly={false}
                            pathPrevious="product-description"
                            pathNext="product-eligibility"
                        />
                    </Form>
                </Formik>
            )}
        </div>
    );
};

export default ContactInformation;
