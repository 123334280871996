export enum ProspectiveInvestorStatusEnum {
    awaitingAssessment = 'awaitingAssessment',
    awaitingReview = 'awaitingReview',
    approved = 'approved',
    rejected = 'rejected',
}

export const ProspectiveInvestorStatusEnumLabels: {
    [key in ProspectiveInvestorStatusEnum]: string;
} = {
    awaitingAssessment: 'Awaiting Assessment',
    awaitingReview: 'Awaiting Review',
    approved: 'Approved',
    rejected: 'Rejected',
};

export interface ProspectiveInvestorResponse {
    _id: string;
    systemUserId: string;
    personalDetails: {
        firstName: string;
        surname: string;
        email: string;
        phoneDiallingCode: string;
        phoneNumber: string;
    };
    countryCodeOfResidence: string;
    investorCategory: string;
    investorCategoryType: string;
    subInvestorCategoryType?: string;
    investorType: string;
    createdDate: Date;
    assessmentReason: string;
    status: ProspectiveInvestorStatusEnum;
    statusLogs: {
        status: ProspectiveInvestorStatusEnum;
        reason?: string;
        date: Date;
        systemUserId: string;
    }[];
    requiresSuitabilityAssessment: boolean;
    endInvestorProfileId?: string;
}
