const isStringType = (value: any) =>
    typeof value === 'string' || value instanceof String;
const isNumberType = (value: any) =>
    typeof value === 'number' || value instanceof Number;
const isBooleanType = (value: any) =>
    typeof value === 'boolean' || value instanceof Boolean;
const isObjectType = (value: any) =>
    Object.prototype.toString.call(value) === '[object Object]';
const stringQuerySearch = (value: string, query: string) =>
    value.toLowerCase().includes(query.toLowerCase()) || false;

const recursiveSearch = (value: any, query: string): boolean | void => {
    if (isObjectType(value)) {
        return Object.values(value).some((v: any) => {
            if (isObjectType(v) || Array.isArray(v)) {
                return recursiveSearch(v, query);
            }
            return isStringType(v) && stringQuerySearch(`${v}`, query);
        });
    }
    if (Array.isArray(value)) {
        return value.some((v) => {
            if (isObjectType(v) || Array.isArray(v)) {
                return recursiveSearch(v, query);
            }
            return isStringType(v) && stringQuerySearch(`${v}`, query);
        });
    }
};

export const filterSearchData = ({
    searchData,
    searchQuery,
    omitKeys,
}: {
    searchData: { [key: string]: any };
    searchQuery: string[];
    omitKeys?: string[];
}) => {
    if (searchQuery.length > 0) {
        return searchQuery
            .map((query) => {
                return Object.values(searchData).some((value, i) => {
                    if (
                        omitKeys &&
                        omitKeys.includes(Object.keys(searchData)[i])
                    )
                        return false;

                    if (!value) return false;
                    if (isObjectType(value) || Array.isArray(value)) {
                        return recursiveSearch(value, query);
                    }
                    return (
                        (isStringType(value) ||
                            isBooleanType(value) ||
                            isNumberType(value)) &&
                        stringQuerySearch(`${value}`, query)
                    );
                });
            })
            .every((a) => a === true);
    }
    return true;
};
