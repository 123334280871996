export const IconSortDesc = ({
    className,
    strokeWidth,
}: {
    className: string;
    strokeWidth?: string;
}) => {
    return (
        <svg
            className={className}
            viewBox="0 0 24 24"
            stroke="currentColor"
            strokeWidth={strokeWidth}
        >
            <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M3 4h13M3 8h9m-9 4h9m5-4v12m0 0l-4-4m4 4l4-4"
            />
        </svg>
    );
};
