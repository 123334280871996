import { ChangeEvent } from 'react';

import { Icons } from '@components/Atoms';
import { FormInputBox } from '@components/Molecules';

export interface PhoneNumberBoxProps {
    id?: string;
    disabled?: boolean;

    phoneDiallingCodeName: string;
    phoneDiallingCodeLabel?: string;
    phoneDiallingCodeValue?: number;
    phoneDiallingCodeDefaultValue?: string;
    phoneDiallingCodePlaceholder?: string;
    onPhoneDiallingCodeChange?: (e: ChangeEvent<HTMLInputElement>) => void;

    phoneNumberName: string;
    phoneNumberLabel?: string;
    phoneNumberValue?: string;
    phoneNumberDefaultValue?: string;
    phoneNumberPlaceholder?: string;
    onPhoneNumberChange?: (e: ChangeEvent<HTMLInputElement>) => void;
}

const PhoneNumberBox = (props: PhoneNumberBoxProps) => (
    <div className="lg:w-full flex flex-column gap-x-4">
        <div>
            <FormInputBox
                name={props.phoneDiallingCodeName}
                label={props.phoneDiallingCodeLabel}
                defaultValue={props.phoneDiallingCodeDefaultValue}
                placeholder={`${props.phoneDiallingCodePlaceholder || '44'}`}
                leftIcon={
                    <div className="mt-1">
                        <Icons name="PlusIcon" size="xsmall" strokeWidth=".5" />
                    </div>
                }
                minLength={0}
                maxLength={5}
                value={props.phoneDiallingCodeValue}
                type="number"
                disabled={props.disabled}
            />
        </div>
        <div className="w-full">
            <FormInputBox
                name={props.phoneNumberName}
                label={props.phoneNumberLabel}
                value={props.phoneNumberValue}
                defaultValue={props.phoneNumberDefaultValue}
                placeholder={props.phoneNumberPlaceholder || '7900 123456'}
                onChange={props.onPhoneNumberChange}
                type="tel"
                disabled={props.disabled}
            />
        </div>
    </div>
);

export default PhoneNumberBox;
