import { NoticeType } from '@interfaces/Api';
import { LifecycleNotice } from '@interfaces/Api/SubscriptionNotice';
import { CapitalCallEvent } from './CapitalCallEvent';
import { DistributionEvent } from './DistributionEvent';
import { QuarterlyReportEvent } from './QuarterlyReportEvent';
import { ReactElement } from 'react';
import { GenericEvent } from './GenericEvent';

export interface EventsListProps {
    notice: LifecycleNotice;
    currency: string;
}

const getEvent = (notice: LifecycleNotice) => {
    const components: { [key in NoticeType]: () => ReactElement<any, any> } = {
        [NoticeType.CapitalCall]: () => <CapitalCallEvent notice={notice} />,
        [NoticeType.CapitalCallPaid]: () => <></>,
        [NoticeType.QuarterlyReport]: () => (
            <QuarterlyReportEvent notice={notice} />
        ),
        [NoticeType.Distribution]: () => <DistributionEvent notice={notice} />,
        [NoticeType.MiFIDCostsandCharges]: () => (
            <GenericEvent notice={notice} />
        ),
    };
    return (
        components[notice.noticeType]?.() || <GenericEvent notice={notice} />
    );
};

export const EventItem: React.FC<EventsListProps> = ({ notice }) => {
    return getEvent(notice);
};
