import { useTranslation } from 'react-i18next';
import { useRegisterInvestorState } from '../RegisterInvestor.context';

import { useSystemUsers } from '@stores/SystemUsers/useSystemUsers';

export const AdvisorSummary = () => {
    const { t } = useTranslation();
    const { data: currentState } = useRegisterInvestorState();
    const { systemUsers } = useSystemUsers();
    const advisory = systemUsers.find(
        (user) => user._id === currentState['adviserSystemUserId']
    );

    return (
        <p>
            <span className="font-bold mr-2">{t('client.registration.summary.advisor')}:</span>
            {advisory?.firstName} {advisory?.surname}
        </p>
    );
};
