import React, { useMemo } from 'react';
import { TabsData } from '@components/Organisms/Dashboard/Dashboard.helper';

import SubscriptionInvestmentReportsDocs, { ReportDocument } from '@containers/Subscriptions/InvestmentReports/SubscriptionInvestmentReportsDocs';
import {
    SigningDocument,
    SigningDocumentStatusEnum,
    SigningDocumentTypeEnum,
} from '@interfaces/Api';
import { FundData } from '@interfaces/Api/Dashboard';
import { Link } from 'react-router-dom';
import { useStorageTokenState } from '@contexts/StorageTokenContext';
import { useTranslation } from 'react-i18next';

const ProductDocumentsLink: React.FC<{ fund: FundData }> = ({ fund }) =>
    <Link to={`/products/${fund.productSlug}`} className='underline'>
        {fund.productName.trimEnd()}
    </Link>

export const DocumentTabContent = (data: TabsData) => {
    const { t } = useTranslation();
    const { fundData, investmentData } = data;
    const { subscriptionSigningDocuments } = investmentData;
    const { subscriptionNotices } = investmentData;
    const { withToken } = useStorageTokenState();

    const noticeDocuments: ReportDocument[] = useMemo(() => {
        return subscriptionNotices.map((notice) => {
            return notice.documents!.map((doc) => {
                return {
                    document: doc,
                    createdAt: notice.uploadedAt,
                    sectionName: 'Notices',
                } as ReportDocument;
            });
        }).flat();
    }, [subscriptionNotices]);

    const signingDocuments: ReportDocument[] = subscriptionSigningDocuments.filter(
        (doc) => doc.status == SigningDocumentStatusEnum.signed
    ).map((doc: SigningDocument) => {

        const createdAt = doc.statusLogs.find(
            (log) =>
                log.status == SigningDocumentStatusEnum.signed
        )?.createdAt || fundData?.inceptionDate;

        switch (doc.signingDocumentType) {
            case SigningDocumentTypeEnum.Subscription:
                doc.signedDocument!.label = 'Subscription Agreement';
                break;
            case SigningDocumentTypeEnum.AmlKyc:
                doc.signedDocument!.label = 'AML KYC';
                break;
            case SigningDocumentTypeEnum.GermanSemiProfessionalQuestionnaire:
                doc.signedDocument!.label = 'German Semi-Professional Questionnaire';
                break;
            case SigningDocumentTypeEnum.NomineeIndemnitySubscriptionAgreement:
                doc.signedDocument!.label = 'Nominee Indemnity Subscription Agreement';
                break;
            case SigningDocumentTypeEnum.NomineeIndemnitySubscriptionAdditionalSharesRequest:
                doc.signedDocument!.label = 'Nominee Indemnity Subscription Additional Shares Request';
                break;
            case SigningDocumentTypeEnum.SubscriptionAdditionalSharesRequest:
                doc.signedDocument!.label = 'Subscription Additional Shares Request';
                break;
            default:
                break;
        }

        return {
            document: doc.signedDocument!,
            createdAt: createdAt,
            sectionName: t('client.portfolio.product.documents.signing_documents'),
        }
    });

    const productDocuments: ReportDocument[] = useMemo(() => fundData?.productDocuments?.map((doc) => {
        return {
            document: doc,
            createdAt: fundData?.inceptionDate,
            sectionName: t('client.portfolio.product.documents.fund_documents'),
        }
    }) || [], [fundData?.inceptionDate, fundData?.productDocuments]);

    const reportDocuments = useMemo(() => [...noticeDocuments, ...signingDocuments, ...productDocuments].map(reportDocument => {
        return {
            ...reportDocument,
            document: {
                ...reportDocument.document,
                url: withToken(reportDocument.document.url),
            }
        }
    }), [noticeDocuments, productDocuments, signingDocuments, withToken]);

    return (
        <>
            {
                signingDocuments.length > 0 ? (
                    <SubscriptionInvestmentReportsDocs
                        reportDocuments={reportDocuments}
                    />
                ) : (
                    <p className="my-10 text-left">{t('client.portfolio.product.documents.none_available')}</p>
                )
            }
            <p>{t('client.portfolio.product.documents.for_additional_information')} <ProductDocumentsLink fund={fundData} /></p>
        </>
    )
};
