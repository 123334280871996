export interface RegPatternsProps {
    reg: RegExp;
    msg: string;
    isCompleted: boolean;
}

export const regPatterns: RegPatternsProps[] = [
    {
        reg: /.{10,}/,
        msg: 'Password must be minimum 10 characters long.',
        isCompleted: false,
    },
    {
        reg: /(?=.*[A-Z])/,
        msg: 'Must contain at least one upper case character.',
        isCompleted: false,
    },
    {
        reg: /(?=.*[a-z])/,
        msg: 'Must contain at least one lower case character.',
        isCompleted: false,
    },
    {
        reg: /(?=.*\d)/,
        msg: 'Must contain at least one number.',
        isCompleted: false,
    },
    {
        reg: /(?=.*[@$!%*?&])/,
        msg: 'Must contain at least one special character(@, $, !, %, *, ?, &).',
        isCompleted: false,
    },
];
