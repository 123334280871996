import { FundData, InvestmentData } from '@interfaces/Api/Dashboard';
import {
    ProductPerformance,
    ReturnHistory,
} from '@interfaces/Api/ProductPerformance';
import { DashboardType } from '../Dashboard/Dashboard.helper';
import { LiquidityTypeEnum } from '@interfaces/Api';
import { OpenEndedEndInvestorFundCard } from './Components/EndInvestorFundCard/OpenEndedEndInvestorFundCard';
import { ClosedEndedEndInvestorFundCard } from './Components/EndInvestorFundCard/ClosedEndedEndInvestorFundCard';

interface DashboardFundProps {
    dashboardType: DashboardType;
    fundData: FundData;
    investmentData: InvestmentData;
    performanceData: ProductPerformance[];
    latestPerformanceData: ProductPerformance[];
    valuationDate: Date;
    currentUnitPrice: number;
    averagePurchaseUnitPrice?: number;
    totalReturn?: number;
    returnHistory?: ReturnHistory[];
}

export const DashboardFund: React.FC<DashboardFundProps> = (props) => {
    const getFundDetails = () => {
        switch (props.dashboardType) {
            case 'endInvestor':
                if (
                    props.fundData.liquidityType ===
                    LiquidityTypeEnum.closedEnded
                ) {
                    return <ClosedEndedEndInvestorFundCard {...props} />;
                } else if (
                    props.fundData.liquidityType === LiquidityTypeEnum.openEnded
                ) {
                    return <OpenEndedEndInvestorFundCard {...props} />;
                } else {
                    return null;
                }
        }
    };

    return <div className="py-4 mb-10">{getFundDetails()}</div>;
};
