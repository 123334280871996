import { useTranslation } from 'react-i18next';
import { useRegisterInvestorState } from '../RegisterInvestor.context';
import { useIntermediaries } from '@stores/Intermediaries/useIntermediaries';

export const IntermediarySummary = (): JSX.Element => {
    const { t } = useTranslation();
    const { data } = useRegisterInvestorState();
    const { intermediaries } = useIntermediaries();

    return (
        <p>
            <span className="font-bold mr-2">{t('client.registration.summary.intermediary')}:</span>
            {
                intermediaries.find(
                    (intermediary) =>
                        intermediary._id === data['intermediaryId']
                )?.name
            }
        </p>
    );
};
