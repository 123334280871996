import { FC, useCallback, useMemo, useState } from 'react';
import { IdType, IndividualDetails, Nationality } from 'common';
import { Button } from '@components/Atoms';
import { ModalService } from '@services/ModalService';
import { Formik, Form, FieldArray } from 'formik';
import { t } from 'i18next';
import * as Yup from 'yup';
import { DatePicker } from '@components/Organisms';
import {
    DataReview,
    FormInputBox,
    FormSelect,
    InlineFormFrame,
} from '@components/Molecules';
import getClientRegistrationFormFields from '@pages/Clients/Clients/Components/Registration/ClientRegistrationFormFields';
import {
    getCountryDisplayName,
    isoCountriesOptions,
} from '@helpers/isoCountries';

export type PersonalDetail = Pick<
    IndividualDetails,
    | 'dateOfBirth'
    | 'occupation'
    | 'countryCodeOfBirth'
    | 'nationalities'
    | 'salutation'
>;

const idTypeOptions = [
    {
        label: 'Passport',
        value: IdType.Passport,
    },
    {
        label: 'ID',
        value: IdType.ID,
    },
];

interface IndividualDetailProps {
    onSubmit: (personalDetail: PersonalDetail) => void;
}

export const IndividualDetail: FC<IndividualDetailProps> = ({ onSubmit }) => {
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [showAddNationalityForm, setShowAddNationalityForm] =
        useState<boolean>(false);
    const handleSubmit = useCallback(
        (value: PersonalDetail) => {
            setIsLoading(true);
            onSubmit(value);
        },
        [onSubmit]
    );

    const validationSchema = useMemo(
        () =>
            Yup.object().shape({
                dateOfBirth: Yup.string().required(
                    t(
                        'client.registration.personal_details.date_of_birth.required_validation_message'
                    )
                ),
                countryCodeOfBirth: Yup.string().required(
                    t(
                        'client.registration.personal_details.country_of_birth.required_validation_message'
                    )
                ),
                townOfBirth: Yup.string().required(
                    t(
                        'client.registration.personal_details.town_of_birth.required_validation_message'
                    )
                ),
            }),
        []
    );

    const emptyNationality: Nationality = {
        countryCode: '',
        nationalIdNumber: '',
    };

    return (
        <div>
            <Formik
                onSubmit={handleSubmit}
                initialValues={{}}
                validateOnMount
                validationSchema={validationSchema}
                enableReinitialize
            >
                {(formProps) => (
                    <Form>
                        <FormInputBox
                            name={
                                getClientRegistrationFormFields(t).salutation
                                    .name
                            }
                            label={
                                getClientRegistrationFormFields(t).salutation
                                    .label
                            }
                            placeholder={
                                getClientRegistrationFormFields(t).salutation
                                    .placeHolder
                            }
                        />
                        <DatePicker
                            name={
                                getClientRegistrationFormFields(t).dateOfBirth
                                    .name
                            }
                            label={
                                getClientRegistrationFormFields(t).dateOfBirth
                                    .label
                            }
                        />
                        <FormInputBox
                            name={
                                getClientRegistrationFormFields(t).townOfBirth
                                    .name
                            }
                            label={
                                getClientRegistrationFormFields(t).townOfBirth
                                    .label
                            }
                            placeholder={
                                getClientRegistrationFormFields(t).townOfBirth
                                    .label
                            }
                        />
                        <FormSelect
                            name={
                                getClientRegistrationFormFields(t)
                                    .countryCodeOfBirth.name
                            }
                            label={
                                getClientRegistrationFormFields(t)
                                    .countryCodeOfBirth.label
                            }
                            optionsData={isoCountriesOptions}
                        />
                        <FormInputBox
                            name="occupation"
                            label="Occupation"
                            placeholder="Occupation"
                        />
                        <FieldArray
                            name="nationalities"
                            render={(arrayHelpers) => (
                                <div>
                                    {arrayHelpers.form.values?.nationalities?.map(
                                        (
                                            nationality: any,
                                            i: number,
                                            arr: any
                                        ) =>
                                            showAddNationalityForm &&
                                            i === arr.length - 1 ? (
                                                <div key={i.toString()}>
                                                    <InlineFormFrame
                                                        primaryAction={t(
                                                            'client.registration.nationalities.save_nationality'
                                                        )}
                                                        onPrimaryAction={() =>
                                                            setShowAddNationalityForm(
                                                                false
                                                            )
                                                        }
                                                        secondaryAction={t(
                                                            'ui.controls.cancel'
                                                        )}
                                                        onSecondaryAction={() =>
                                                            arrayHelpers.remove(
                                                                i
                                                            )
                                                        }
                                                    >
                                                        <FormSelect
                                                            name={`nationalities[${i}].countryCode`}
                                                            label="Country *"
                                                            optionsData={
                                                                isoCountriesOptions
                                                            }
                                                        />
                                                        <FormSelect
                                                            name={`nationalities[${i}].idType`}
                                                            label="ID Type"
                                                            optionsData={
                                                                idTypeOptions
                                                            }
                                                        />
                                                        <FormInputBox
                                                            name={`nationalities[${i}].nationalIdNumber`}
                                                            label="ID Number"
                                                            placeholder="ID Number"
                                                        />
                                                        <DatePicker
                                                            name={`nationalities[${i}].expiryDate`}
                                                            label="Expiry Date"
                                                        />
                                                    </InlineFormFrame>
                                                </div>
                                            ) : (
                                                <div key={i.toString()}>
                                                    <DataReview
                                                        onDelete={() =>
                                                            arrayHelpers.remove(
                                                                i
                                                            )
                                                        }
                                                    >
                                                        <p className="text-sm">
                                                            Country:{' '}
                                                            {getCountryDisplayName(
                                                                nationality.countryCode
                                                            )}
                                                        </p>
                                                        <p className="text-sm">
                                                            Id Type:{' '}
                                                            {nationality.idType}
                                                        </p>
                                                        <p className="text-sm">
                                                            Id Number:{' '}
                                                            {
                                                                nationality.nationalIdNumber
                                                            }
                                                        </p>
                                                        <p className="text-sm">
                                                            Expiry Date:{' '}
                                                            {
                                                                nationality.expiryDate
                                                            }
                                                        </p>
                                                    </DataReview>
                                                </div>
                                            )
                                    )}

                                    <Button
                                        label={`+ ${t(
                                            'client.registration.nationalities.add_nationality'
                                        )}`}
                                        type="button"
                                        buttonType="secondary"
                                        className="w-full my-2"
                                        onClick={() => {
                                            arrayHelpers.push(emptyNationality);
                                            setShowAddNationalityForm(true);
                                        }}
                                    />
                                </div>
                            )}
                        />
                        <div className="flex justify-between mt-4 border-t pt-4 border-sky-100">
                            <Button
                                buttonType="secondary"
                                onClick={() => {
                                    ModalService.getInstance().hideModal();
                                }}
                                label={t('ui.controls.cancel')}
                            />
                            <Button
                                label={t('ui.controls.next')}
                                type="submit"
                                isLoading={isLoading}
                                disabled={!formProps.isValid || isLoading}
                            />
                        </div>
                    </Form>
                )}
            </Formik>
        </div>
    );
};
