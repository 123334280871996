import { useTranslation } from 'react-i18next';
import { Badge, BadgeType, Icons } from '@components/Atoms';
import { toCapitalizedWords } from '@helpers/ToCapitalizedWords';
import { SubscriptionStatusEnum } from '@interfaces/Api';
import { getSubscriptionStatusTypeLabels } from '@interfaces/Api/SubscriptionStatus.enum';

interface SubscriptionStatusBadgeProps {
    subscriptionStatus: SubscriptionStatusEnum;
}

const badgeTypeMap: { [key in SubscriptionStatusEnum]: BadgeType } = {
    [SubscriptionStatusEnum.inProgress]: 'secondary',
    [SubscriptionStatusEnum.approved]: 'success',
    [SubscriptionStatusEnum.readyForReview]: 'primary',
    [SubscriptionStatusEnum.rejected]: 'error',
    [SubscriptionStatusEnum.cancelled]: 'error',
};

const endIconMap: { [key in SubscriptionStatusEnum]: React.ReactNode } = {
    [SubscriptionStatusEnum.inProgress]: (
        <div className="text-logo-blue-dark">
            <Icons name="ClockIcon" size="small" />
        </div>
    ),
    [SubscriptionStatusEnum.approved]: <Icons name="CheckIcon" size="small" />,
    [SubscriptionStatusEnum.readyForReview]: (
        <Icons name="ClockIcon" size="small" />
    ),
    [SubscriptionStatusEnum.rejected]: <Icons name="XMarkIcon" size="small" />,
    [SubscriptionStatusEnum.cancelled]: <Icons name="XMarkIcon" size="small" />,
};

const SubscriptionStatusBadge: React.FC<SubscriptionStatusBadgeProps> = ({
    subscriptionStatus,
}) => {
    const { t } = useTranslation();
    return (
        <Badge
            label={toCapitalizedWords(getSubscriptionStatusTypeLabels(t, subscriptionStatus!))}
            badgeType={badgeTypeMap[subscriptionStatus]}
            sizeType="large"
            endIcon={endIconMap[subscriptionStatus]}
        />
    );
};

export default SubscriptionStatusBadge;
