import { useTranslation } from 'react-i18next';
import { useRegisterInvestorState } from '../RegisterInvestor.context';

export const KycSummary = (): JSX.Element => {
    const { t } = useTranslation();
    const { data } = useRegisterInvestorState();

    const getKycValue = () => {
        if (data['isKycCompliant'] !== undefined) {
            if (data['isKycCompliant']) {
                return 'Yes';
            } else {
                return 'No';
            }
        }
        return null;
    };
    return (
        <>
            <div>
                <p>
                    <span className="font-bold mr-2">{t('client.registration.summary.kyc_compliance')}:</span>

                    {getKycValue()}
                </p>
            </div>
        </>
    );
};
