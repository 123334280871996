import React, { useLayoutEffect, useMemo, useRef, useState } from 'react';
import { Link } from 'react-router-dom';

import { AuthPermissionEnum, BookingCentreResponse } from '@interfaces/Api';

import { notifySuccess } from '@helpers/toastrHelper';

import { Button, Icons } from '@components/Atoms';
import { Modal } from '@components/Molecules';

import { dateShortFormatter } from '@helpers/Date.helper';
import { getCountryDisplayName } from '@helpers/isoCountries';
import { useAuthState } from '@contexts/AuthContext';

export interface BookingCentreListProps {
    items: Array<BookingCentreResponse>;
    selectedIntermediaryId: string;
    searchItem?: any;
    deleteBookingCentre: (id: any) => void;
}

const BookingCentreListGrid: React.FC<BookingCentreListProps> = (
    props: BookingCentreListProps
) => {
    const containerRef = useRef<HTMLDivElement>(null);
    const [selectedItem, setSelectedItem] = useState<string>();
    const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);
    const { hasPermissions } = useAuthState();

    const prodNotifyMeta = {
        successMessage: 'Booking centre has been deleted',
        failMessage: 'Failed to delete Booking centre',
    };

    const hideModal = () => setShowDeleteModal(false);

    const closeModalOnDelete = async () => {
        setShowDeleteModal(false);
        if (selectedItem !== 'undefined') {
            notifySuccess(prodNotifyMeta.successMessage);
        }
        await props.deleteBookingCentre(selectedItem);
    };

    const findFirstElementInViewPort = (elements: any): any => {
        Array.prototype.find.call(
            elements,
            (element) => element.getBoundingClientRect().y >= 85
        );
    };

    const scrollTo = useMemo(() => {
        const nodeElements =
            containerRef.current?.querySelectorAll('[data-item]');

        if (nodeElements) {
            return findFirstElementInViewPort(nodeElements);
        }

        return undefined;
    }, []);

    useLayoutEffect(() => {
        if (scrollTo) {
            scrollTo.scrollIntoView();
            window.scrollBy(0, -85);
        }
    }, [scrollTo]);
    return (
        <>
            {hasPermissions(AuthPermissionEnum.createBookingCentre) && (
                <div className="flex justify-end w-full mb-8 mt-3">
                    <Link to="/admin/booking-centres/add-booking-centre">
                        <Button label="Add a Booking Centre" />
                    </Link>
                </div>
            )}

            <div className="xs:container xs:mx-auto max-w-full w-full mt-5">
                <div className="flex flex-wrap bg-brand-navy-light h-10 p-2 justify-between rounded-md">
                    {['Name', 'Description', 'Country', 'Date added'].map(
                        (item) => (
                            <div key={item} className="w-3/12">
                                <h4 className="text-sm md:text-md font-bold">
                                    {item}
                                </h4>
                            </div>
                        )
                    )}
                </div>
                <div className="list p-2" ref={containerRef}>
                    {props.items && props.items.length === 0 ? (
                        <div className="container mx-auto">
                            <Icons name="ExclamationTriangleIconOutline" />
                            <p>No Booking centres to display.</p>
                            <p>Add a Booking Centre to get started.</p>
                        </div>
                    ) : (
                        props.items.map(
                            (filteredBookingCentre: BookingCentreResponse) => (
                                <div
                                    key={filteredBookingCentre._id}
                                    className="bg-white border-b my-2 flex flex-row flex-wrap justify-between"
                                >
                                    <div className="w-3/12 my-2">
                                        <h4 className="text-md cursor-pointer">
                                            {
                                                filteredBookingCentre.bookingCentreName
                                            }
                                        </h4>
                                    </div>
                                    <div className="w-3/12 my-2">
                                        {filteredBookingCentre.description}
                                    </div>
                                    <div className="w-3/12 my-2">
                                        <span className="capitalize">
                                            {getCountryDisplayName(
                                                filteredBookingCentre.countryCode
                                            )}
                                        </span>
                                    </div>
                                    <div className="w-2/12 my-2">
                                        <p>
                                            {dateShortFormatter(
                                                filteredBookingCentre.createdDate
                                            )}
                                        </p>
                                    </div>
                                    <div className="w-1/12 md:w-1/12 my-1 text-sm justify-items-end flex flex-col">
                                        <div className="flex flex-row float-right">
                                            <div className="w-1/2">&nbsp;</div>
                                            {hasPermissions(
                                                AuthPermissionEnum.deleteBookingCentre
                                            ) && (
                                                <div className="w-1/2">
                                                    <Button
                                                        onClick={() => {
                                                            setSelectedItem(
                                                                () =>
                                                                    String(
                                                                        filteredBookingCentre?._id
                                                                    )
                                                            );
                                                            setShowDeleteModal(
                                                                true
                                                            );
                                                        }}
                                                        className="cursor-pointer"
                                                        disabled={false}
                                                        hasIconOnly
                                                        startIcon={
                                                            <Icons
                                                                name="TrashIcon"
                                                                strokeWidth="1.5"
                                                                stroke="black"
                                                            />
                                                        }
                                                    />
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            )
                        )
                    )}
                </div>
            </div>

            <Modal show={showDeleteModal} onBackdropClick={hideModal}>
                <Modal.Header onClose={hideModal}>
                    <h2 className="text-2xl">Delete Booking centre</h2>
                </Modal.Header>
                <Modal.Body>
                    Are you sure you want to delete this Booking centre? <br />
                    Any references to this booking centre will be invalidated.
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        onClick={hideModal}
                        buttonType="secondary"
                        label="Cancel"
                    />
                    <Button onClick={closeModalOnDelete} label="Delete" />
                </Modal.Footer>
            </Modal>
        </>
    );
};

export default BookingCentreListGrid;
