import { authenticationRefreshTokensPost } from '@api/Authentication';

export const LOCAL_STORAGE_AUTH_TOKENS = 'AUTH_TOKENS';

interface AuthTokens {
    access: {
        token: string;
        expires: Date;
    };
    refresh: {
        token: string;
        expires: Date;
    };
}

export const getLocalStorageAuthTokens = (): AuthTokens | undefined => {
    const jwtToken = localStorage.getItem(LOCAL_STORAGE_AUTH_TOKENS);
    return jwtToken && JSON.parse(jwtToken);
};

export const setLocalStorageAuthTokens = (tokens: AuthTokens) => {
    localStorage.setItem(LOCAL_STORAGE_AUTH_TOKENS, JSON.stringify(tokens));
};

export const removeLocalStorageAuthTokens = () => {
    localStorage.removeItem(LOCAL_STORAGE_AUTH_TOKENS);
};

export const refreshAuthTokens = async () => {
    const refreshToken = getLocalStorageAuthTokens()?.refresh?.token;
    
    if (!refreshToken) {
        return false;
    }

    try {
        const response = await authenticationRefreshTokensPost(refreshToken);
        if (response.ok) {
            // save new token and refresh local storage
            const authTokens = await response.json();
            setLocalStorageAuthTokens(authTokens);
            return true;
        }
    } catch (e) {
        console.log(e);
    }

    removeLocalStorageAuthTokens();
    return false;
};
