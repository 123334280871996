import { Button, Loading } from '@components/Atoms';
import { Modal } from '@components/Molecules';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

interface SuitabilitySubmissionModalProps {
    isOpen: boolean;
    suitabilitySubmissionResult: {
        mifidProStatus: 'approved' | 'manual' | 'rejected';
        status: 'approved' | 'manual' | 'rejected';
    };
    onClose: () => void;
    onSubmitted: () => void;
}

const SuitabilitySubmissionModal: React.FC<SuitabilitySubmissionModalProps> = ({
    isOpen,
    suitabilitySubmissionResult,
    onClose,
    onSubmitted,
}) => {
    // Fake loading screen
    const [isLoading, setIsLoading] = useState(true);

    const navigate = useNavigate();

    const onContinueNavigate = () => {
        navigate('../products');
    };

    useEffect(() => {
        setInterval(() => {
            setIsLoading(false);
        }, 3000);
    }, []);

    const loading = () => {
        return (
            <div className="pt-6 pb-6">
                <h2 className="text-2xl text-center">Assessing Application</h2>
                <p className="text-center pb-8 ">
                    Please wait while we process your application
                </p>
                <Loading size="large" />
            </div>
        );
    };

    const resultModal = () => {
        let heading = '';
        let message = '';

        if (suitabilitySubmissionResult.status === 'approved') {
            heading = 'Application Approved';
        } else if (suitabilitySubmissionResult.status === 'manual') {
            heading = 'Application Pending';
            message = 'Your application is pending approval';
        } else if (suitabilitySubmissionResult.status === 'rejected') {
            heading = 'Application Rejected';
            message = 'Your application has been rejected';
        }

        return (
            <div className="pt-6 pb-6">
                <h2 className="text-2xl text-center">{heading}</h2>
                {message && <p className="text-center py-8 ">{message}</p>}
            </div>
        );
    };

    return (
        <Modal show={isOpen} width="w-1/2">
            <Modal.Header>Suitability Questionnaire Submission</Modal.Header>
            <Modal.Body>{isLoading ? loading() : resultModal()}</Modal.Body>
            <Modal.Footer className="flex-row-reverse">
                {/* <Button
                    buttonType="secondary"
                    onClick={onNavigationLeavePge}
                    label="Leave this page"
                ></Button> */}
                {!isLoading && (
                    <Button
                        onClick={onContinueNavigate}
                        label="Continue"
                    ></Button>
                )}
            </Modal.Footer>
        </Modal>
    );
};

export default SuitabilitySubmissionModal;
