import { FormInputBox, PhoneNumberBox } from '@components/Molecules';
import getClientRegistrationFormFields from '../ClientRegistrationFormFields';
import { useTranslation } from 'react-i18next';

export const ContactDetailsSelection = () => { 
    const { t } = useTranslation();
    return (
        <>
            <h3 className="text-2xl font-normal leading-8 my-3">{t('client.registration.contact_details.title')}</h3>
            <p className="text-base py-2">{t('client.registration.contact_details.instruction')}</p>
            <FormInputBox
                name={getClientRegistrationFormFields(t).emailAddress.name}
                label={getClientRegistrationFormFields(t).emailAddress.label}
                placeholder={t('client.registration.contact_details.email_address.placeholder')}
            />
            <PhoneNumberBox
                phoneDiallingCodeName={
                    getClientRegistrationFormFields(t).phoneDiallingCode.name
                }
                phoneDiallingCodeLabel={
                    getClientRegistrationFormFields(t).phoneDiallingCode.label
                }
                phoneNumberName={getClientRegistrationFormFields(t).phoneNumber.name}
                phoneNumberLabel={getClientRegistrationFormFields(t).phoneNumber.label}
                phoneNumberPlaceholder={t('client.registration.contact_details.phone_number.placeholder')}
            />
        </>
    );
}
