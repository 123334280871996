import React from 'react';

import { Icons } from '@components/Atoms';

interface BurgerNavProps {
    className: string;
    setOpenNav(): void;
}

const BurgerNav: React.FC<BurgerNavProps> = (props: BurgerNavProps) => (
    <div className={props.className}>
        <label
            onClick={props.setOpenNav}
            className="cursor-pointer md:hidden inline"
        >
            <Icons name="Bars2Icon" />
        </label>
    </div>
);

export default BurgerNav;
