import { useTranslation } from 'react-i18next';
import { useRegisterInvestorState } from '../RegisterInvestor.context';
import { useEndInvestorProfileState } from '@contexts/EndInvestorProfileContext';

export const JurisdictionSummary = (): JSX.Element => {
    const { t } = useTranslation();
    const { data } = useRegisterInvestorState();
    const { investorJurisdictions = [] } = useEndInvestorProfileState();

    return (
        <p>
            <span className="font-bold mr-2">{t('client.registration.summary.onboarding_jurisdiction')}:</span>
            {
                investorJurisdictions.find(
                    (j) =>
                        j.countryCode ===
                        data['onboardingJurisdictionCountryCode']
                )?.jurisdictionCountry
            }
        </p>
    );
};
