import { postRedemptionApplication } from '@api/RedemptionApplications';
import { Button } from '@components/Atoms';
import { dateShortestFormatter } from '@helpers/Date.helper';
import { getFormattedCurrency } from '@helpers/isoCurrencies';
import { notifyError, notifySuccess } from '@helpers/toastrHelper';
import { ProductResponse, SubscriptionResponse } from '@interfaces/Api';
import { RedemptionApplicationRequestBody } from '@interfaces/Api/RedemptionApplicationRequestBody';
import { RedemptionApplicationResponse } from '@interfaces/Api/RedemptionApplicationResponse';
import { useState } from 'react';
import { AttestationsForm } from './AttestationsStep';
import { RedemptionForm } from './CreateStep';
import { getFormattedPercentage } from '@helpers/Math.helper';

interface ReviewStepProps {
    subscriptionId: string;
    currencyCode: string;
    product: ProductResponse;
    redemptionForm: RedemptionForm;
    attestationsForm: AttestationsForm;
    previousStep?: () => void;
    nextStep?: (redemptionApplication: RedemptionApplicationResponse) => void;
}

const ReviewStep: React.FC<ReviewStepProps> = ({
    subscriptionId,
    currencyCode,
    redemptionForm,
    attestationsForm,
    previousStep,
    nextStep,
}) => {
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [showSubmitButton, setShowSubmitButton] = useState(true);

    const handleSubmit = () => {
        setIsSubmitting(true);
        const requestBody: RedemptionApplicationRequestBody = {
            // TODO: the below subscriptionId could be wrong, need to check
            parentSubscriptionId: subscriptionId,
            totalRedemptionAmount: redemptionForm.totalRedemptionAmount,
            redeemablePositions: redemptionForm.redemptions.map(
                (redemption) => ({
                    subscriptionId: redemption.subscriptionId,
                    lotPurchaseDate: redemption.subscriptionApprovedDate,
                    redemptionAmount: redemption.redeemedAmount,
                    lotNumber: redemption.lotNumber,
                })
            ),
            attestations: attestationsForm,
        };
        postRedemptionApplication(requestBody)
            .then((response) => {
                notifySuccess('Redemption created successfully');
                setTimeout(() => {
                    setShowSubmitButton(false);
                    nextStep?.(response);
                }, 1000);
            })
            .catch((a) => {
                notifyError(
                    'Redemption created unsuccessfully' + JSON.stringify(a)
                );
            })
            .finally(() => {
                setIsSubmitting(false);
            });
    };

    return (
        <div>
            <h1 className="text-3xl text-logo-blue pb-5">Review Redemption</h1>
            <p className="text-base py-6">
                Please ensure all details below are correct. Once approved, the
                redemption will be sent to the Fund Administrator for review.
            </p>
            <p className="pt-1 pb-2">
                <strong>Total Redemption amount:</strong>&nbsp;
                {getFormattedCurrency(redemptionForm.totalRedemptionAmount, currencyCode)}
            </p>
            {redemptionForm.redemptions.map((redemption, i) => (
                <div className="border-t py-2" key={i}>
                    <p>Lot {redemption.lotNumber}</p>
                    <p>
                        <strong>Subscription Approved Date: </strong>&nbsp;
                        {dateShortestFormatter(
                            redemption.subscriptionApprovedDate
                        )}
                    </p>
                    <p>
                        <strong>Available amount:</strong>&nbsp;
                        {getFormattedCurrency(
                            redemption.maximumRedeemableAmount,
                            redemption.currencyCode
                        )}
                    </p>
                    <p>
                        <strong>Amount to redeem:</strong>&nbsp;
                        {getFormattedCurrency(
                            redemption.redeemedAmount,
                            redemption.currencyCode
                        )}
                    </p>
                    {Number.isFinite(redemption.softLockup.feePercentage) && (
                        <>
                            <p>
                                <strong>Fee: </strong>
                                {getFormattedPercentage(
                                    redemption.softLockup.feePercentage || 0
                                )}
                            </p>
                            <p>
                                <strong>Fee Amount: </strong>
                                {getFormattedCurrency(
                                    (redemption.redeemedAmount *
                                        (redemption.softLockup.feePercentage || 0)) /
                                        100,
                                    redemption.currencyCode
                                )}
                            </p>
                        </>
                    )}
                </div>
            ))}
            <hr />

            {/* Buttons that allows a user to submit the above   */}
            <div className="flex flex-row justify-between w-full mt-10">
                <Button
                    buttonType="secondary"
                    type="button"
                    label="Back"
                    onClick={previousStep}
                />
                {showSubmitButton && (
                    <Button
                        buttonType="primary"
                        type="submit"
                        label="Create"
                        onClick={handleSubmit}
                        isLoading={isSubmitting}
                    />
                )}
            </div>
        </div>
    );
};

export default ReviewStep;
