import React from 'react';
import { LineChartProps } from '@interfaces/InterfaceChartProps';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
    TimeScale,
} from 'chart.js';
import { Line } from 'react-chartjs-2';
import 'chartjs-adapter-moment';

const WidgetLineChart: React.FC<LineChartProps> = (props: LineChartProps) => {
    ChartJS.register(
        CategoryScale,
        LinearScale,
        PointElement,
        LineElement,
        Title,
        Tooltip,
        Legend,
        TimeScale
    );

    return <Line options={props.options} data={props.data} />;
};

export default WidgetLineChart;
