import React, { useState, useCallback } from 'react';

import { useProducts } from '@stores/Products/useProducts';
import ProductListGrid from '@containers/Products/ProductListGrid';
import { Pagination } from '@components/Organisms';
import { useSortableData } from '@hooks/useSortableData/useSortableData';

const ProductListAdmin: React.FC = () => {
    const [currentView, setCurrentView] = useState('grid');
    const [productsPerPage, setProductsPerPage] = useState<number | string>(50);

    const { products } = useProducts();

    const handleToggleCurrentView = useCallback(() => {
        setCurrentView((view) => (view === 'grid' ? 'list' : 'grid'));
    }, [setCurrentView]);

    const {
        tableData: currentProducts,
        tableDataLength,
        setCurrentPageNumber: setCurrentPage,
        currentPageNumber: currentPage,
        paginate,
        paginateBack,
        paginateFront,
    } = useSortableData({
        tableData: products,
        searchText: '',
        itemsPerPage: productsPerPage,
    });

    return (
        <div className="w-full relative">
            <ProductListGrid
                items={currentProducts}
                currentView={currentView}
                onToggleCurrentView={handleToggleCurrentView}
            />

            <Pagination
                rowsPerPage={productsPerPage}
                itemsLength={tableDataLength}
                paginate={paginate}
                currentPage={currentPage}
                paginateBack={paginateBack}
                paginateFront={paginateFront}
                className="p-2"
                onPageSizeChange={setProductsPerPage}
            />
        </div>
    );
};

export default ProductListAdmin;
