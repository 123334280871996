import {
    HeaderEndInvestorLayout,
    HeaderEndInvestorLayoutProps,
} from './HeaderLayout';

import { DashboardType } from '../Dashboard.helper';

interface HeaderProps {
    dashboardType: DashboardType;
    data: HeaderEndInvestorLayoutProps;
}

export const Header: React.FC<HeaderProps> = ({ dashboardType, data }) => {
    const getHeaderLayout = () => {
        switch (dashboardType) {
            case 'endInvestor':
                return <HeaderEndInvestorLayout {...data} />;
        }
    };

    const headerLayout = getHeaderLayout();

    return <>{headerLayout}</>;
};
