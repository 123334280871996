import { isoCountries } from '@helpers/isoCountries';
import { ReviewClientSummaryDetailsProps } from '@interfaces/ReviewClientSummaryDetailsProps';
import { ReviewItem } from './ReviewItem';

const ReviewClientBankDetails = (props: ReviewClientSummaryDetailsProps) => (
    <div className="leading-5 mb-4">
        {props.data?.individualDetails?.bankAccounts?.map((bankAcc, i) => (
            <div className="pb-3" key={`${i}-bank`}>
                <ReviewItem label="Bank Name:" value={bankAcc?.bankName} />:
                <ReviewItem
                    label="Country Currency:"
                    value={bankAcc?.countryCurrencyCode}
                />
                :
                <ReviewItem
                    label="Country of Account:"
                    value={isoCountries[bankAcc?.countryCodeOfAccount]}
                />
                :
                <ReviewItem label="IBAN:" value={bankAcc?.IBAN} />
            </div>
        ))}
    </div>
);

export default ReviewClientBankDetails;
