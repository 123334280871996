import { RedemptionApplicationResponse } from '@interfaces/Api/RedemptionApplicationResponse';

export interface RowItem {
    columns: { [key: string]: string | number | JSX.Element };
    redemption: RedemptionApplicationResponse;
    _id: string;
}

export const MobileTable = ({
    columnNames,
    rows,
}: {
    columnNames: string[];
    rows: RowItem[];
}) => (
    <>
        {rows.map((row: RowItem) => (
            <tbody
                key={row._id}
                className="bg-white text-left text-sm overflow-x-auto"
            >
                {columnNames.map((columnName: string) => (
                    <tr className="whitespace-nowrap" key={columnName}>
                        <td className="border-b font-bold px-3 md:pr-6 md:pl-0 py-1 w-1/3 md:w-1/2">
                            {columnName}
                        </td>
                        <td className="border-b px-3 md:pr-6 md:pl-0 py-1 text-clip overflow-hidden">
                            <span className="text-ellipsis overflow-hidden">
                                {row.columns[columnName]}
                            </span>
                        </td>
                    </tr>
                ))}
                <tr>
                    <td className="py-1" colSpan={2}>
                        <div className="mb-2"></div>
                    </td>
                </tr>
            </tbody>
        ))}
    </>
);
