import { useTranslation } from 'react-i18next';
import { useRegisterInvestorState } from '../RegisterInvestor.context';

export const PersonalDetailsSummary = (): JSX.Element => {
    const { t } = useTranslation();
    const { data } = useRegisterInvestorState();
    const {
        countryCodeOfBirth,
        dateOfBirth,
        firstName,
        lastName,
        townOfBirth,
    } = data['individualDetails'] || {};

    return (
        <div>
            <span className="font-bold mr-2">{t('client.registration.summary.personal_details')}:</span>
            <p>
                {firstName} {lastName}
            </p>
            <p>{dateOfBirth}</p>
            <p>{townOfBirth}</p>
            <p>{countryCodeOfBirth}</p>
        </div>
    );
};
