import { EndInvestorPortfolioItem, NoticeType } from '@interfaces/Api';
import {
    MetricType,
    ProductPerformance,
} from '@interfaces/Api/ProductPerformance';
import { EndInvestorFundSummary } from './EndInvestorFundCard/EndInvestorFundSummary';
import { getFormattedCurrency } from '@helpers/isoCurrencies';
import { Tile } from './Tile';
import { Icons } from '@components/Atoms';
import { OverviewCard } from './OverviewCard/OverviewCard';
import { WidgetLineChart } from '@components/Charts';
import { TileBackgroundColor } from './Tile/Tile';
import { formatMetricsValue } from '../Dashboard.helper';
import { dateShortestFormatter } from '@helpers/Date.helper';
import { useTranslation } from 'react-i18next';

interface OverviewTabsContentProps {
    endInvestorPortfolioItem: EndInvestorPortfolioItem;
}

const OverviewTabsContent: React.FC<OverviewTabsContentProps> = ({
    endInvestorPortfolioItem,
}) => {
    const { t } = useTranslation();
    const currencyCode =
        endInvestorPortfolioItem?.investmentData?.shareClass?.currency || 'GBP';

    const filteredChartData = endInvestorPortfolioItem?.performanceData.filter(
        (el: ProductPerformance) => el.metricType === MetricType.NAV
    );

    const overviewCardPropsCommitment = {
        headerText: t('client.portfolio.card.commitment'),
        label: t('client.portfolio.product.lifecycle.capital_called'),
        value: `${getFormattedCurrency(
            endInvestorPortfolioItem?.investmentData?.calledCapital,
            currencyCode,
            false,
            'code'
        )} / ${getFormattedCurrency(
            endInvestorPortfolioItem?.investmentData?.commitment,
            currencyCode,
            false,
            'code'
        )}`,
        chartProps: {
            data: {
                datasets: [
                    {
                        data: [
                            endInvestorPortfolioItem?.investmentData
                                ?.calledCapital ?? 0,
                            endInvestorPortfolioItem?.investmentData
                                ?.commitment ?? 0,
                        ],
                        backgroundColor: ['#819BB6', '#abc1d8'],
                        borderWidth: 1,
                        updateMode: 'resize',
                    },
                ],
            },
        },
    };
    const getMetricValue = (metricType: MetricType) =>
        endInvestorPortfolioItem.latestPerformanceData.find(
            (el) => el.metricType === metricType
        );

    const IRRValue = getMetricValue(MetricType.IRR)?.value!;
    const MOICValue = getMetricValue(MetricType.MOIC)?.value!.toFixed(2);
    const DPIValue = getMetricValue(MetricType.DPI)?.value!;
    const RVPIValue = getMetricValue(MetricType.RVPI)?.value!;
    return (
        <>
            <header className="flex flex-col lg:flex-row justify-between mb-8 lg:items-end">
                <div className="flex-1 mb-4 lg:mb-0">
                    <EndInvestorFundSummary
                        fundData={endInvestorPortfolioItem.fundData}
                        investmentData={endInvestorPortfolioItem.investmentData}
                    />
                </div>
                <div className="flex-1">
                    <div className="grid grid-cols-1">
                        {endInvestorPortfolioItem.investmentData.actionPoints.map(
                            (actionPoint, i) => (
                                <div key={i}>
                                    <div className="flex justify-between items-center">
                                        {actionPoint.noticeType ===
                                            NoticeType.CapitalCall && (
                                            <div className="flex-3">
                                                <Tile
                                                    list={[t('client.portfolio.product.next_capital_call')]}
                                                    iconPos="left"
                                                    hAlign="left"
                                                    icon={
                                                        <Icons
                                                            name="CalendarDaysIcon"
                                                            size="medium"
                                                        />
                                                    }
                                                    spaceBetweenItems
                                                />
                                            </div>
                                        )}
                                        {actionPoint.noticeType ===
                                            NoticeType.Distribution && (
                                            <div className="flex-3">
                                                <Tile
                                                    list={[t('client.portfolio.product.next_distribution')]}
                                                    iconPos="left"
                                                    hAlign="left"
                                                    icon={
                                                        <Icons
                                                            name="CalendarDaysIcon"
                                                            size="medium"
                                                            strokeWidth="1.2"
                                                        />
                                                    }
                                                    spaceBetweenItems
                                                />
                                            </div>
                                        )}

                                        <div className="flex-1">
                                            <Tile
                                                list={[
                                                    getFormattedCurrency(
                                                        actionPoint.amount,
                                                        currencyCode,
                                                        false,
                                                        'code'
                                                    ),
                                                ]}
                                                hAlign="right"
                                                listDirection="column"
                                            />
                                        </div>

                                        <div className="flex-1">
                                            <Tile
                                                list={[
                                                    dateShortestFormatter(
                                                        actionPoint.dueDate ||
                                                            new Date()
                                                    ),
                                                ]}
                                                hAlign="right"
                                                listDirection="column"
                                            />
                                        </div>
                                    </div>
                                </div>
                            )
                        )}
                    </div>
                </div>
            </header>

            <section className="flex flex-col justify-between lg:flex-row">
                <div className="lg:max-w-xs">
                    <OverviewCard {...overviewCardPropsCommitment} />
                </div>
                <div className="flex flex-2 mx-0 lg:ml-6 my-2 lg:my-0 self-stretch">
                    {filteredChartData && filteredChartData.length > 0 && (
                        <WidgetLineChart
                            options={{
                                plugins: {
                                    legend: {
                                        display: true,
                                        position: 'chartArea',
                                        align: 'start',
                                        labels: {
                                            boxWidth: 20,
                                            boxHeight: 20,
                                            padding: 30,
                                            textAlign: 'left',
                                        },
                                        onClick: () => false,
                                    },
                                },
                            }}
                            data={{
                                labels: filteredChartData.map((el) =>
                                    new Date(el.date).getFullYear()
                                ),
                                datasets: [
                                    {
                                        label: 'NAV',
                                        data: filteredChartData?.map(
                                            (el) => el.value
                                        ),
                                        borderColor: '#00376E',
                                        backgroundColor: '#819BB6',
                                    },
                                ],
                            }}
                        />
                    )}
                </div>
            </section>
            <div className="flex  justify-between mt-6 flex-wrap">
                <div className="mr-2 flex-1">
                    <Tile
                        backgroundColor={TileBackgroundColor.LIGHTBLUE}
                        isFixedHeight
                        isTextLarge
                        spaceBetweenItems
                        list={[
                            'IRR',
                            formatMetricsValue({
                                value: IRRValue,
                                label: MetricType['IRR'],
                            }),
                        ]}
                        iconPos="right"
                    />
                </div>

                <div className="flex-1 mr-2">
                    <Tile
                        backgroundColor={TileBackgroundColor.BRIGHTBLUE}
                        isFixedHeight
                        spaceBetweenItems
                        isTextLarge
                        list={[
                            'MOIC',
                            formatMetricsValue({
                                value: MOICValue as string,
                                label: MetricType['MOIC'],
                            }),
                        ]}
                        iconPos="right"
                    />
                </div>

                <div className="flex-1 mr-2">
                    <Tile
                        spaceBetweenItems
                        isFixedHeight
                        isTextLarge
                        list={[
                            'DPI',
                            formatMetricsValue({
                                value: DPIValue,
                                label: MetricType['DPI'],
                            }),
                        ]}
                    />
                </div>

                <div className="flex-1 mr-2">
                    <Tile
                        backgroundColor={TileBackgroundColor.LIGHTERBLUE}
                        isFixedHeight
                        spaceBetweenItems
                        isTextLarge
                        list={[
                            'RVPI',
                            formatMetricsValue({
                                value: RVPIValue,
                                label: MetricType['RVPI'],
                            }),
                        ]}
                    />
                </div>

                <div className="flex-1">
                    <Tile
                        isFixedHeight
                        backgroundColor={TileBackgroundColor.LIGHTESTBLUE}
                        spaceBetweenItems
                        isTextLarge
                        list={[
                            'TVPI',
                            formatMetricsValue({
                                value: DPIValue + RVPIValue,
                                label: MetricType['TVPI'],
                            }),
                        ]}
                    />
                </div>
            </div>
        </>
    );
};

export default OverviewTabsContent;
