import { ProductResponse } from '@interfaces/Api';

export const mergeProducts = (products: ProductResponse[]) => {
    const mergedProducts = products.reduce((acc, product) => {
        const existingProduct = acc.find((p) => p._id === product._id);

        if (
            existingProduct &&
            product.shareClasses.length > 0 &&
            product.documents.length > 0
        ) {
            existingProduct.shareClasses = [
                ...existingProduct.shareClasses,
                ...product.shareClasses,
            ];

            existingProduct.documents = [
                ...existingProduct.documents,
                ...product.documents,
            ];
        } else {
            acc.push(product);
        }

        return acc;
    }, [] as ProductResponse[]);

    return mergedProducts;
};
