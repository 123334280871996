import { Tile } from './Tile';

export interface HeaderEndInvestorLayoutProps {
    header: {
        tile1: string[];
        tile2: string[];
        tile3: React.ReactNode;
        tile4: React.ReactNode;
        tile5: React.ReactNode;
    };
}

export const HeaderEndInvestorLayout: React.FC<
    HeaderEndInvestorLayoutProps
> = ({ header: { tile1, tile2, tile3, tile4, tile5 } }) => (
    <header className="mb-12 flex flex-col lg:flex-row">
        <div className="flex flex-1 mb-2 lg:mb-0">
            <div className="w-full mr-2">
                <Tile list={tile1} isFixedHeight spaceBetweenItems />
            </div>
            <div className="w-full lg:mr-2">
                <Tile list={tile2} isFixedHeight spaceBetweenItems />
            </div>
        </div>

        <div className="flex lg:flex-2 mb-2 lg:mb-0">
            <div className="flex flex-col justify-between flex-1 lg:flex-2">
                <div className="mb-2 lg:mb-0">{tile3}</div>
                <div>{tile4}</div>
            </div>
        </div>

        <div className="lg:ml-2 flex flex-1 basis-8">{tile5}</div>
    </header>
);
