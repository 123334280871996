export const ReviewItem = ({
    label,
    value,
}: {
    label: string;
    value: string;
}) => (
    <p className="text-base">
        <strong>{label} </strong>
        <span> {value}</span>
    </p>
);
