import { BeneficialOwnerDetail, BeneficialOwnership } from '@interfaces/Api';

interface ReviewBeneficialOwnersProps {
    owners?: BeneficialOwnership;
}

function ReviewBeneficialOwners(props: ReviewBeneficialOwnersProps) {
    return (
        <div className="text-base grid grid-cols-3 w-full">
            {props.owners?.beneficialOwners?.map(
                (owner: BeneficialOwnerDetail, index: number) => (
                    <div className="leading-5" key={index}>
                        <div>
                            <strong>Name: </strong>
                            {`${owner.firstName} ${owner.lastName}`}
                        </div>
                        <div>
                            <strong>Address: </strong>
                            {owner.address}
                        </div>
                        <div>
                            <strong>Holdings: </strong>
                            {owner.percentageHolding}%
                        </div>
                        <div>
                            <strong>Control: </strong>
                            {owner.typeOfControl}
                        </div>
                        <div>
                            <strong>D.O.B: </strong>
                            {owner.dateOfBirth}
                        </div>
                        <div>
                            <strong>Place Of Birth: </strong>
                            {owner.placeOfBirth}
                        </div>
                        <div>
                            <strong>Nationalities: </strong>
                            {owner.nationalities}
                        </div>
                        <div>
                            <strong>NI Number: </strong>
                            {owner.nationalIdNumber}
                        </div>
                    </div>
                )
            )}
        </div>
    );
}

export default ReviewBeneficialOwners;
