import { useMemo, useState } from 'react';

import { ReviewFrame } from '@components/Molecules';
import ReviewClientSubscriptionsList from './ReviewClientSubscriptionsList';
import { EndInvestorProfileResponse } from '@interfaces/Api';
import { isDirectSubscriptionProcessType } from '@helpers/EndInvestorHelper';
import { isEndInvestorNomineeAccount } from '@helpers/EndInvestorHelper';
import { EndInvestorSubscriptionsProfile } from '@interfaces/Api/EndInvestorProfileResponse';

interface EndInvestorsListProps {
    visibleEndInvestorProfiles: EndInvestorSubscriptionsProfile[];
}

export const EndInvestorsList: React.FC<EndInvestorsListProps> = (props) => {
    const { visibleEndInvestorProfiles } = props;
    const [isExpanded] = useState<boolean>(false);

    const endInvestors = useMemo(
        () =>
            visibleEndInvestorProfiles.filter(
                (endInvestor: EndInvestorProfileResponse) =>
                    !isEndInvestorNomineeAccount(endInvestor) &&
                    isDirectSubscriptionProcessType(endInvestor)
            ),
        [visibleEndInvestorProfiles]
    );

    return (
        <div className="mb-4">
            <h2 className="text-2xl logo-blue align-middle">
                Investors ({endInvestors.length})
            </h2>
            {endInvestors.map(
                (endInvestorProfile: EndInvestorSubscriptionsProfile) =>
                    endInvestorProfile && (
                        <ReviewFrame
                            key={endInvestorProfile._id}
                            title={`${endInvestorProfile?.individualDetails?.firstName} ${endInvestorProfile?.individualDetails?.lastName}`}
                            className="-my-4"
                            expanded={isExpanded}
                        >
                            <ReviewClientSubscriptionsList
                                endInvestorProfile={endInvestorProfile}
                            />
                        </ReviewFrame>
                    )
            )}
        </div>
    );
};
