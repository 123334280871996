import { SystemUserResponse } from '@interfaces/Api';
import { useConfigurationState } from '@contexts/ConfigurationContext';
import { Icons } from '@components/Atoms';

import { columns, getTableHeaderClass } from '../UserTable.helper';

interface UserTableHeaders {
    requestSort: (key: keyof SystemUserResponse) => void;
    columnSortConfig: { key: string; direction: string };
}

export const UserTableHeaders: React.FC<UserTableHeaders> = (props) => {
    const { columnSortConfig, requestSort } = props;
    const { preferences } = useConfigurationState();
    const isOwnerColumnVisible =
        preferences?.feature?.userManagement?.displayOwnerEntity;

    const columnHeaders = isOwnerColumnVisible
        ? [{ title: 'Owner', key: 'owner' }, ...columns]
        : columns;

    return (
        <thead className="bg-brand-navy-light h-10 p-2">
            <tr>
                {columnHeaders?.map((column, i) => {
                    return (
                        <th
                            data-testid={column.key}
                            key={i.toString()}
                            onClick={() =>
                                column.key &&
                                requestSort(
                                    column.key as keyof SystemUserResponse
                                )
                            }
                            className={`text-left ${getTableHeaderClass(
                                column.title,
                                columnSortConfig
                            )} p-2 relative ${
                                column.key && 'cursor-pointer'
                            } text-sm md:text-xs xl:text-base`}
                        >
                            {column.title}{' '}
                            {column.key && (
                                <>
                                    {column.key !== columnSortConfig?.key && (
                                        <Icons name="IconSort" size="small" />
                                    )}
                                    {column.key === columnSortConfig?.key && (
                                        <>
                                            {columnSortConfig?.direction ===
                                                'ascending' && (
                                                <div className="inline-block align-middle">
                                                    <Icons
                                                        name="IconSortAsc"
                                                        size="small"
                                                        strokeWidth="2"
                                                    />
                                                </div>
                                            )}
                                            {props.columnSortConfig
                                                ?.direction ===
                                                'descending' && (
                                                <div className="inline-block align-middle">
                                                    <Icons
                                                        name="IconSortDesc"
                                                        size="small"
                                                        strokeWidth="2"
                                                    />
                                                </div>
                                            )}
                                        </>
                                    )}
                                </>
                            )}
                        </th>
                    );
                })}
            </tr>
        </thead>
    );
};
