import { useTranslation } from 'react-i18next';
import { FundData, InvestmentData } from '@interfaces/Api/Dashboard';
import { getFormattedCurrency } from '@helpers/isoCurrencies';
import { Label } from '../Label';

interface EndInvestorFundSummaryProps {
    fundData: FundData;
    investmentData: InvestmentData;
}

export const EndInvestorFundSummary = ({
    investmentData: {
        calledCapital,
        distributedCapital,
        commitment,
        shareClass: { currency },
        currentValue,
        remaining,
    },
}: EndInvestorFundSummaryProps) => {
    const { t } = useTranslation();
    const currencyCode = currency || 'GBP';
    return (
        <>
            <p className="font-bold mb-4 lg:mb-4 text-xl">
                {t('client.portfolio.value')}
                <span className="ml-4 ">
                    {getFormattedCurrency(
                        currentValue,
                        currencyCode,
                        false,
                        'code'
                    )}
                </span>
            </p>
            <div className="flex flex-col lg:flex-row">
                <div className="flex lg:flex-col gap-2 justify-between pr-10">
                    <Label
                        label={t('client.portfolio.card.commitment')}
                        value={getFormattedCurrency(
                            commitment,
                            currencyCode,
                            false,
                            'code'
                        )}
                    />
                    <Label
                        label={t('client.portfolio.card.called')}
                        value={
                            getFormattedCurrency(
                                calledCapital,
                                currencyCode,
                                false,
                                'code'
                            ) || '-'
                        }
                    />
                </div>

                <div className="flex lg:ml-4 lg:flex-col pr-4">
                    <Label
                        label={t('client.portfolio.card.distributed')}
                        value={
                            getFormattedCurrency(
                                distributedCapital,
                                currencyCode,
                                false,
                                'code'
                            ) || '-'
                        }
                    />
                    <Label
                        label={t('client.portfolio.card.remaining')}
                        value={
                            getFormattedCurrency(
                                remaining,
                                currencyCode,
                                false,
                                'code'
                            ) || '-'
                        }
                    />
                </div>
            </div>
        </>
    );
};
