import React, { useState } from 'react';
import { RegPatternsProps } from '../../Helpers/Password.helper';

interface RequirementsProps {
    validMessage: string;
    isValid: boolean;
}

interface PasswordCheckListProps {
    regPatterns: RegPatternsProps[];
    contains10C: boolean;
    uppercaseRegex: boolean;
    lowercaseRegex: boolean;
    numericRegex: boolean;
    specialRegex: boolean;
}

export const Requirement = (props: RequirementsProps) => (
    <div className={'flex flex-row py-1'}>
        <div className={'checkbox w-4 h-4'}>
            {!props.isValid ? (
                <p className="text-2xl -mt-2.5 px-0.3 text-red-500">&times;</p>
            ) : (
                <p className="text-lg -mt-2 px-0.5 text-green-500">&#x2714;</p>
            )}
        </div>
        <div className="w-11/12">{props.validMessage}</div>
    </div>
);

const PasswordCheckList: React.FC<PasswordCheckListProps> = (
    props: PasswordCheckListProps
) => {
    const [regexVals, setRegexVals] = useState(props.regPatterns);

    return (
        <section className="w-full">
            <Requirement
                isValid={props.contains10C}
                validMessage={props.regPatterns[0].msg}
            />
            <Requirement
                isValid={props.uppercaseRegex}
                validMessage={props.regPatterns[1].msg}
            />
            <Requirement
                isValid={props.lowercaseRegex}
                validMessage={props.regPatterns[2].msg}
            />
            <Requirement
                isValid={props.numericRegex}
                validMessage={props.regPatterns[3].msg}
            />
            <Requirement
                isValid={props.specialRegex}
                validMessage={props.regPatterns[4].msg}
            />
        </section>
    );
};

export default PasswordCheckList;
