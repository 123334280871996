export const columns = [
    { title: 'Name', key: 'firstName' },
    { title: 'Role', key: 'role' },
    { title: 'Last Login Date', key: 'lastLoginDate' },
    { title: 'Invited On', key: 'invitationLog' },
    { title: 'Registered', key: 'registered' },
    { title: '' },
];

export const getTableHeaderClass = (name: string, columnSortConfig: any) => {
    if (!columnSortConfig) {
        return;
    }
    return columnSortConfig.key === name ? columnSortConfig.direction : '';
};
