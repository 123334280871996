/**
 * @export
 * @enum {string}
 */
export enum GermanKnowledgeAssessmentEnum {
    none = 'none',
    low = 'low',
    medium = 'medium',
    high = 'high',
}
