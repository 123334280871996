import { SubscriptionNotice } from '@interfaces/Api';

import SubscriptionInvestmentReportsLifeCycle from '@containers/Subscriptions/InvestmentReports/SubscriptionInvestmentReportsLifeCycle';

interface ReviewNoticesProps {
    notices?: SubscriptionNotice[] | undefined;
}

const ReviewLifeCycleNotices = (props: ReviewNoticesProps) => (
    <div className="w-full">
        <SubscriptionInvestmentReportsLifeCycle
            lifecycleDocuments={props.notices}
        />
    </div>
);

export default ReviewLifeCycleNotices;
