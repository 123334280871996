import { ReviewFrame } from '@components/Molecules';
import { getCountryDisplayName } from '@helpers/isoCountries';
import { investorCountryRequirementTree } from 'common';

const InvestorDetailsSection = ({ prospectiveInvestor }) => {
    const countryTree = investorCountryRequirementTree.find(
        (a) => a.countryCode === prospectiveInvestor?.countryCodeOfResidence
    );
    const investorTypeLabel = countryTree?.investorTypes.find(
        (a) => a.value == prospectiveInvestor?.investorType
    )?.name;

    const investorCategory = countryTree?.investorCategories.find(
        (a) => a.name == prospectiveInvestor?.investorCategory
    );
    const investorCategoryType = investorCategory?.investorCategoryTypes?.find(
        (a) => a.value == prospectiveInvestor?.investorCategoryType
    );
    const investorCategoryTypeName = investorCategoryType?.name;

    const subInvestorCategory =
        investorCategoryType?.type == 'subcategorySubmission' &&
        investorCategoryType?.subInvestorCategoryTypes?.find(
            (a) => a.value == prospectiveInvestor?.subInvestorCategoryType
        );
    const subInvestorCategoryName = subInvestorCategory?.name;

    const investorDetails = [
        {
            label: 'Country of Residence',
            value: getCountryDisplayName(
                prospectiveInvestor?.countryCodeOfResidence
            ),
        },
        {
            label: 'Investor Type',
            value: investorTypeLabel,
        },
        {
            label: 'Investor Category',
            value: investorCategory?.name,
        },
        {
            label: 'Investor Category Type',
            value: [investorCategoryTypeName, subInvestorCategoryName]
                .filter((a) => a)
                .join(' - '),
        },
    ];
    return (
        <ReviewFrame
            title="Investor Details"
            className="-my-4"
            expanded={true}
            hideToggleButton={true}
        >
            <div className="w-full lg:w-4/5 flex items-center justify-start p-5 md:py-10 text-xs">
                <div className="flex flex-col">
                    {investorDetails.map((el) => (
                        <p className="text-base font-bold" key={el.label}>
                            {el.label}
                        </p>
                    ))}
                </div>
                <div className="flex flex-col pl-4">
                    {investorDetails.map((el) => (
                        <p className="text-base" key={el.value}>
                            {el.value}
                        </p>
                    ))}
                </div>
            </div>
        </ReviewFrame>
    );
};

export default InvestorDetailsSection;
