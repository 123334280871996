import dayjs from 'dayjs';

export const dateShortFormatter = (date: Date | dayjs.Dayjs) => {
    if (date) {
        return dayjs(date).format('DD MMMM YYYY');
    }
    return '';
};

export const dateShortestFormatter = (date: Date | dayjs.Dayjs) => {
    if (date) {
        return dayjs(date).format('DD/MM/YYYY');
    }
    return '';
};

export const dateMonthYearFormatter = (date: Date | dayjs.Dayjs) => {
    if (date) {
        return dayjs(date).format('MMM YYYY');
    }
    return '';
};

export const dateTimeFormatter = (date: Date, humanReadable?: boolean) => {
    if (humanReadable) {
        return dayjs(date).format('H:mm on D MMM YYYY');
    }
    return dayjs(date).format('HH:mm:ss DD/MM/YYYY');
};

export const formatDateTimeString = (date: Date | undefined): string =>
    date ? dayjs(date).format('DD/MM/YYYY HH:mm') : '';

export const convertToDateIsoString = (dateString: string) => {
    return new Date(dateString).toISOString();
};

export const formatDate = (dateString?: string) => {
    if (dateString) {
        return dayjs(dateString).format('YYYY-MM-DD');
    }

    return '';
};

export const areDatesEqual = (date1: Date, date2: Date) =>
    date1.getTime() === date2.getTime();

export const getLastDayOfMonth = (year: number, month: number) =>
    new Date(year, month + 1, 0);
