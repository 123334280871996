import { useNavigate } from 'react-router-dom';
import { dateShortestFormatter, formatDate } from '@helpers/Date.helper';
import { FundData, InvestmentData } from '@interfaces/Api/Dashboard';
import {
    DataType,
    MetricType,
    ProductPerformance,
} from '@interfaces/Api/ProductPerformance';

import { EndInvestorFundCard } from './EndInvestorFundCard';

import { getFormattedCurrency } from '@helpers/isoCurrencies';
import { WidgetLineChart } from '@components/Charts';
import { useCallback, useMemo } from 'react';
import { TileBackgroundColor } from '../Tile/Tile';
import { formatMetricsValue } from '@components/Organisms/Dashboard/Dashboard.helper';
import { useTranslation } from 'react-i18next';

interface ClosedEndedEndInvestorFundCardProps {
    fundData: FundData;
    investmentData: InvestmentData;
    performanceData: ProductPerformance[];
    latestPerformanceData: ProductPerformance[];
}

export interface PerformanceTileContent {
    backgroundColor?: TileBackgroundColor;
    content: string[];
}

export const ClosedEndedEndInvestorFundCard: React.FC<
    ClosedEndedEndInvestorFundCardProps
> = ({ fundData, investmentData, performanceData, latestPerformanceData }) => {
    const { t } = useTranslation();
    const navigate = useNavigate();

    const getFundSummary = () => {
        const getAmount = (val?: number) => {
            if (val) {
                return getFormattedCurrency(
                    val,
                    investmentData?.shareClass?.currency,
                    false,
                    'code'
                );
            }
            return '-';
        };

        const summaryValues: { label: string; value: string }[] = [
            {
                label: t('client.portfolio.card.commitment'),
                value: getAmount(investmentData.commitment),
            },
            {
                label: t('client.portfolio.card.called'),
                value: getAmount(investmentData.calledCapital),
            },
            {
                label: t('client.portfolio.card.distributed'),
                value: getAmount(investmentData.distributedCapital),
            },
            {
                label: t('client.portfolio.card.remaining'),
                value: getAmount(investmentData.remaining),
            },
        ];

        return (
            <>
                <div className="grid grid-rows-4 grid-flow-col gap-1 mb-4 mr-2 text-xl">
                    {summaryValues.map((summary) => (
                        <div
                            key={summary.label}
                            className="flex justify-between mb-1"
                        >
                            <span className="pr-2">{summary.label}</span>
                            <span>{summary.value}</span>
                        </div>
                    ))}
                </div>
                <p className="font-bold mb-4 lg:mb-0 flex justify-between mr-2 mt-14 text-xl">
                    <span className="pr-2">{t('client.portfolio.card.total_value')}</span>
                    <span>{getAmount(investmentData.currentValue)}</span>
                </p>
            </>
        );
    };

    const getPerformanceTiles = (): PerformanceTileContent[] => {
        const performanceValues: PerformanceTileContent[] =
            latestPerformanceData
                .filter(
                    (latestPerformance) =>
                        latestPerformance.metricType === MetricType.IRR ||
                        latestPerformance.metricType === MetricType.MOIC ||
                        latestPerformance.metricType === MetricType.DPI ||
                        latestPerformance.metricType === MetricType.RVPI
                )
                .map((latestPerformance) => {
                    switch (latestPerformance.dataType) {
                        case DataType.PERCENTAGE:
                            return {
                                content: [
                                    latestPerformance.metricType,
                                    formatMetricsValue({
                                        value: latestPerformance.value,
                                        label: latestPerformance.metricType,
                                    }),
                                ],
                            };
                        case DataType.CURRENCY:
                            return {
                                content: [
                                    latestPerformance.metricType,
                                    getFormattedCurrency(
                                        latestPerformance.value,
                                        investmentData?.shareClass?.currency
                                    ),
                                ],
                            };

                        case DataType.NUMBER:
                            return {
                                content: [
                                    latestPerformance.metricType,
                                    formatMetricsValue({
                                        value: latestPerformance.value,
                                        label: latestPerformance.metricType,
                                    }),
                                ],
                            };
                        default:
                            return {
                                content: [
                                    latestPerformance.metricType,
                                    latestPerformance.value.toString(),
                                ],
                            };
                    }
                })
                .map((performance) => {
                    const backgroundColors = new Map<
                        string,
                        TileBackgroundColor
                    >([
                        [MetricType.IRR, TileBackgroundColor.BRIGHTBLUE],
                        [MetricType.MOIC, TileBackgroundColor.LIGHTERBLUE],
                        [MetricType.DPI, TileBackgroundColor.LIGHTESTBLUE],
                        [MetricType.RVPI, TileBackgroundColor.LIGHTBLUE],
                    ]);

                    const backgroundColor = backgroundColors.get(
                        performance.content[0]
                    );

                    return {
                        ...performance,
                        backgroundColor,
                    };
                });
        return performanceValues;
    };

    const getPerformanceMetric = useCallback(
        (metricType: MetricType) => {
            const performanceDataForMetric = performanceData
                .filter((performance) => performance.metricType === metricType)
                .map((performance) => {
                    // TODO: needs to be formatted
                    return {
                        label: formatDate(performance.date.toString()),
                        value: performance.value,
                        metricType: metricType,
                    };
                });

            return performanceDataForMetric;
        },
        [performanceData]
    );

    const chartPoints = useMemo(() => {
        const irrPoints = getPerformanceMetric(MetricType.IRR);
        const moicPoints = getPerformanceMetric(MetricType.MOIC);

        interface ChartPoint {
            moic?: number;
            irr?: number;
            date: string;
        }

        let chartPoints: ChartPoint[] = [];

        const allDates = new Set<string>();
        const irrPointsMap = new Map<string, number>();
        irrPoints.forEach((point) => {
            irrPointsMap.set(point.label, point.value);
            allDates.add(point.label);
        });

        const moicPointsMap = new Map<string, number>();
        moicPoints.forEach((point) => {
            moicPointsMap.set(point.label, point.value);
            allDates.add(point.label);
        });

        allDates.forEach((date) => {
            chartPoints.push({
                date,
                irr: irrPointsMap.get(date),
                moic: moicPointsMap.get(date),
            });
        });

        // sort chartpoints by date
        chartPoints = chartPoints.sort((a, b) => {
            if (a.date && b.date) {
                return new Date(a.date).getTime() - new Date(b.date).getTime();
            }
            return 0;
        });

        return chartPoints;
    }, [getPerformanceMetric]);

    return (
        <EndInvestorFundCard
            subscriptionId={investmentData.subscriptionId}
            productName={fundData.productName}
            fundType={fundData.liquidityType}
            subHeaderList={[
                {
                    label: t('client.portfolio.card.launched'),
                    value: fundData.inceptionDate
                        ? dateShortestFormatter(fundData.inceptionDate)
                        : '-',
                },
                {
                    label: t('client.portfolio.card.committed'),
                    value: investmentData.commitmentDate
                        ? dateShortestFormatter(investmentData.commitmentDate)
                        : '-',
                },
                {
                    label: t('client.portfolio.card.ends'),
                    value: fundData.endDate
                        ? dateShortestFormatter(fundData.endDate)
                        : '-',
                },
            ]}
            tiles={getPerformanceTiles()}
            leftBoxChildren={getFundSummary()}
            rightBoxChildren={
                chartPoints.length ? (
                    <WidgetLineChart
                        data={{
                            labels: chartPoints.map((a) => a.date),
                            datasets: [
                                {
                                    label: 'IRR',
                                    data: chartPoints.map((a) => a.irr) as any,
                                    borderColor: '#00376E',
                                    backgroundColor: '#00376E',
                                    yAxisID: 'percentage',
                                },
                                {
                                    label: 'MOIC',
                                    data: chartPoints.map((a) => a.moic) as any,
                                    borderColor: '#809BB6',
                                    backgroundColor: '#809BB6',
                                    yAxisID: 'number',
                                },
                            ],
                        }}
                        options={{
                            plugins: {
                                legend: {
                                    display: true,
                                    position: 'chartArea',
                                    align: 'start',
                                    labels: {
                                        boxWidth: 8,
                                        boxHeight: 8,
                                        padding: 10,
                                        textAlign: 'left',
                                    },
                                },
                            },
                            scales: {
                                percentage: {
                                    title: {
                                        text: 'IRR',
                                        display: true,
                                    },
                                    position: 'left',
                                    ticks: {
                                        callback: (value: string | number) => {
                                            return `${Number(
                                                value
                                            ).toFixed()}%`;
                                        },
                                    },
                                    grid: {
                                        drawOnChartArea: false,
                                        drawTicks: false,
                                    },
                                },
                                number: {
                                    title: {
                                        text: 'MOIC',
                                        display: true,
                                    },
                                    position: 'right',
                                    type: 'linear',
                                    ticks: {
                                        callback: (value: string | number) => {
                                            return `${Number(value).toFixed(
                                                1
                                            )}x`;
                                        },
                                    },
                                    grid: {
                                        drawOnChartArea: false,
                                        drawTicks: false,
                                    },
                                },
                                x: {
                                    type: 'time',
                                    time: {
                                        unit: 'year',
                                    },
                                    grid: {
                                        drawOnChartArea: true,
                                        drawTicks: false,
                                    },
                                },
                            },
                        }}
                    />
                ) : (
                    <div />
                )
            }
            onClick={() =>
                navigate(`subscription/${investmentData.subscriptionId}`)
            }
        />
    );
};
