import * as Yup from 'yup';

import {
    LifecycleNotice,
    SubscriptionNotice,
    noticeTypeLabels,
} from '@interfaces/Api/SubscriptionNotice';
import { Button } from '@components/Atoms';
import { useMemo } from 'react';
import { taskApprovalPost } from '@api/EventTask';
import { notifySuccess, notifyError } from '@helpers/toastrHelper';
import { TaskApprovalRequest } from '@interfaces/Api/TaskApprovalRequest';
import { EventTypeEnum } from '@interfaces/eventType.enum';
import { useAuthState } from '@contexts/AuthContext';
import {
    AuthRoleEnum,
    ProductResponse,
    ShareClass,
    SigningDocument,
    SubscriptionStatusEnum,
    TaskListResponse,
} from '@interfaces/Api';
import { TaskTypeLabelEnum } from '@interfaces/tasks.enum';
import { usePortfolioState } from '@contexts/PortfolioContext';
import { Modal, FormCheckbox } from '@components/Molecules';
import { Form, Formik, FormikHelpers } from 'formik';
import { getProductDetails } from '@helpers/Product.helper';
import { getFormattedCurrency } from '@helpers/isoCurrencies';
import { useTranslation } from 'react-i18next';

interface VerificationOfReceiptModalProps {
    notice: LifecycleNotice;
    task: TaskListResponse;
    showConfirmationModal: boolean;
    setShowConfirmationModal: (show: boolean) => void;
}
interface FormValues {
    verifyFunds?: boolean;
}
interface PayloadSubscriptionDetails {
    notices?: SubscriptionNotice[];
    subscriptionId: string;
    subscriptionUrl: string;
    subscriptionStatus: SubscriptionStatusEnum;
    endInvestorName?: string;
    productName?: string;
    shareClassName?: string;
    subscriptionAmountFormatted: string;
    shareClass?: ShareClass;
    subscriptionAmount?: number;
    subscriptionCurrencyCode?: string;
    acceptedSubscriptionAmount?: number;
    status: SubscriptionStatusEnum;
    product: ProductResponse;
    signingDocuments?: SigningDocument[];
    firstName?: string;
    lastName?: string;
    companyName?: string;
}

interface Field {
    name: string;
    type: 'checkbox';
    label: string;
    value?: string | boolean;
    description?: string;
    key?: string;
    disabled?: (params: {
        values: FormValues;
        isEdit: boolean;
        role?: AuthRoleEnum;
    }) => boolean;
}

export const VerificationOfReceiptModal: React.FC<
    VerificationOfReceiptModalProps
> = ({ notice, task, showConfirmationModal, setShowConfirmationModal }) => {
    const { t } = useTranslation();
    const { currentUser, hasRoles } = useAuthState();
    const hideModal = () => setShowConfirmationModal(false);
    const { getLifecycleData, subscriptionLifecycleData } = usePortfolioState();
    const { payload = {} } = task;
    const {
        subscriptionDetails,
    }: { subscriptionDetails: PayloadSubscriptionDetails } = payload;
    const noticeAmount = getFormattedCurrency(
        notice?.amount,
        subscriptionDetails?.subscriptionCurrencyCode
    );

    const fields: Field[] = useMemo(() => {
        const fields: Field[] = [
            {
                name: 'verifyFunds',
                value: false,
                description: `Please verify that ${noticeAmount} has been deposited from ${subscriptionDetails?.endInvestorName}`,
                label: 'Verify receipt of funds',
                type: 'checkbox',
                key: 'verifyFunds',
            },
        ];

        return fields;
    }, [noticeAmount, subscriptionDetails?.endInvestorName]);

    const convertFieldsToSchema = (
        fields: Record<string, any>[]
    ): Record<string, any> =>
        fields.reduce((acc: { [key: string]: Yup.AnySchema }, current) => {
            if (current.required) {
                acc[current.name] = Yup.boolean()
                    .required(`This field is required`)
                    .oneOf([true], `This field must be checked`);
            }
            return acc;
        }, {});

    const validationSchema = Yup.object().shape(convertFieldsToSchema(fields));

    const initialValues = useMemo(() => {
        return fields.reduce((acc, { name, value }) => {
            acc[name] = value;
            return acc;
        }, {} as Record<string, string | boolean | undefined>);
    }, [fields]);

    const submitHandler = async (
        values: FormValues,
        { setSubmitting, resetForm }: FormikHelpers<FormValues>
    ) => {
        setSubmitting(true);
        const { _id: taskId } = task;
        const taskApproval = {
            func: taskApprovalPost,
            successMessage: 'Approved successfully!',
            failMessage: 'Failed to approve. Please try again.',
        };
        if (!taskId) return notifyError(taskApproval.failMessage);
        const taskApprovalData: TaskApprovalRequest = {
            eventType: EventTypeEnum.CAPITAL_CALL_PAID_EVENT,
            approverDetails: {
                approver: currentUser?.user?._id || '',
                approvalDate: new Date(),
            },
        };

        taskApproval
            .func({ taskId, taskApprovalData })
            .then(async () => {
                notifySuccess(taskApproval.successMessage);
                getLifecycleData(
                    subscriptionLifecycleData?.subscription.subscriptionId
                );
            })
            .catch((e) => {
                notifyError(taskApproval.failMessage);
            })
            .finally(() => {
                setSubmitting(false);
                hideModal();
                resetForm();
            });
    };

    return (
        <Modal
            show={showConfirmationModal}
            onBackdropClick={hideModal}
            width="w-2/5"
        >
            <Modal.Header onClose={hideModal}>
                {TaskTypeLabelEnum.VERIFICATION_OF_RECEIPT}
            </Modal.Header>
            <Modal.Body>
                <Formik
                    onSubmit={submitHandler}
                    initialValues={initialValues}
                    validationSchema={validationSchema}
                >
                    {({ values, dirty, isSubmitting, isValid }: any) => (
                        <Form className="text-logo-blue mb-10 w-full text-left">
                            <div
                                data-testid="payload-text"
                                className="text-base text-logo-blue mb-8"
                            >
                                {notice && (
                                    <>
                                        <p className="pt-1">
                                            <strong>Notice Type:</strong>{' '}
                                            {noticeTypeLabels.CapitalCall}
                                        </p>
                                        <p className="pt-1">
                                            <strong>Client:</strong>{' '}
                                            {
                                                subscriptionDetails?.endInvestorName
                                            }
                                        </p>
                                        <p className="pt-1">
                                            <strong>Amount:</strong>{' '}
                                            {noticeAmount}
                                        </p>
                                        <p className="pt-1">
                                            <strong>Subscription:</strong>{' '}
                                            {getProductDetails({
                                                productName:
                                                    subscriptionDetails?.productName,
                                                shareClassName:
                                                    subscriptionDetails?.shareClassName,
                                                subscriptionAmount:
                                                    subscriptionDetails?.subscriptionAmountFormatted,
                                            })}
                                        </p>
                                        <p className="pt-1">
                                            <strong>Comments:</strong>{' '}
                                            {notice.comment}
                                        </p>
                                        <p className="pt-1">
                                            <strong>Status:</strong>{' '}
                                            {task?.completionStatus}
                                        </p>
                                    </>
                                )}
                            </div>
                            <div>
                                <FormCheckbox
                                    name={'verifyFunds'}
                                    description={`Please verify that ${noticeAmount} has been deposited from ${subscriptionDetails?.endInvestorName}`}
                                    key={'verifyFunds'}
                                    label={'Verify receipt of funds'}
                                    value={false}
                                    disabled={false}
                                />
                            </div>
                            <div className="w-full mx-auto">
                                <div className="my-4 p-2 flex justify-between">
                                    <Button
                                        onClick={hideModal}
                                        buttonType="secondary"
                                        label={t('ui.controls.cancel')}
                                    ></Button>
                                    <Button
                                        type="submit"
                                        label="Submit"
                                        disabled={
                                            !dirty || isSubmitting || !isValid
                                        }
                                    ></Button>
                                </div>
                            </div>
                        </Form>
                    )}
                </Formik>
            </Modal.Body>
        </Modal>
    );
};
