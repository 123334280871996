import { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import classNames from 'classnames';

export interface MenuItemProps {
    name: string;
    link?: string;
    exitNav?(): void;
    subMenuitems?: MenuItemProps[];
}

const MenuItem = (props: MenuItemProps) => {
    const [isSubMenuVisible, setIsSubMenuVisible] = useState(false);
    const pathName = useLocation().pathname;
    const location = pathName.split('/')[1];
    const hasSubMenu = props.subMenuitems && props.subMenuitems.length > 0;

    const linkStyle = classNames(
        'border-b-2 text-logo-blue hover:border-logo-blue',
        {
            'border-logo-blue': location === props.link?.replace('/', ''),
            'border-header-bg': location !== props.link?.replace('/', ''),
            'text-xs': hasSubMenu,
        }
    );

    const subMenuItemsHTML = props.subMenuitems?.map(
        (item) =>
            item.link && (
                <li
                    key={item.name}
                    className={`border-b py-2 hover:border-logo-blue ${
                        item.link === pathName
                            ? 'border-logo-blue'
                            : 'border-gray-200'
                    }`}
                    onClick={props.exitNav}
                >
                    <Link
                        className="text-sm"
                        to={item.link}
                        onClick={item.exitNav}
                    >
                        {item.name}
                    </Link>
                </li>
            )
    );

    return (
        <li
            className="md:px-2 lg:px-4 pt-2 pb-2 text-2xl md:text-base leading-8 md:leading-none relative pb-2 h-full flex items-center"
            onMouseEnter={() => setIsSubMenuVisible(true)}
            onMouseLeave={() => setIsSubMenuVisible(false)}
        >
            {hasSubMenu ? (
                <div className="w-full">
                    <p
                        className={`md:border-b-2 md:border-header-bg md:hover:border-logo-blue cursor-pointer ${
                            props.subMenuitems?.some(
                                (subMenuItem: MenuItemProps) =>
                                    subMenuItem.link === pathName
                            )
                                ? 'md:border-logo-blue'
                                : ''
                        }`}
                    >
                        {props.name}
                    </p>
                    <ul
                        className="md:hidden p-5 bg-white w-full
                        "
                    >
                        {subMenuItemsHTML}
                    </ul>
                </div>
            ) : (
                props.link && (
                    <Link
                        className={linkStyle}
                        to={props.link}
                        onClick={props.exitNav}
                    >
                        {props.name}
                    </Link>
                )
            )}

            {hasSubMenu && isSubMenuVisible && (
                <div className="absolute top-72px bg-white w-6 left-50 -ml-3 z-20 hidden md:block">
                    <div className="w-4 h-1 border-logo-grey-200 border-b -rotate-45 relative left-9px top-4px" />
                    <div className="w-4 h-1 border-logo-grey-200 border-b rotate-45 relative -left-1px" />
                </div>
            )}
            {hasSubMenu && isSubMenuVisible && (
                <ul
                    onMouseEnter={() => setIsSubMenuVisible(true)}
                    className="hidden md:block p-5 border-l border-t border-r border-b border-gray-200 bg-white absolute -left-96px lg:left-0 top-72px min-w-220 shadow z-10"
                >
                    {subMenuItemsHTML}
                </ul>
            )}
        </li>
    );
};

export default MenuItem;
