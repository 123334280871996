import { AuthRoleEnum } from '@interfaces/Api';
import { ClientEnum } from '@interfaces/Api/ConfigurationResponse';
import { NavigateFunction } from 'react-router-dom';

const LOCAL_STORAGE_NOTICE_SELECTION = 'NOTICE_SELECTION';

const onProductPage = ({ isLogin, preferences, navigate }: any) => {
    if (
        !preferences?.feature?.authentication
            ?.enableProductsPageWithoutLoggingIn &&
        !isLogin
    ) {
        navigate('/login');
        return;
    }

    if (
        preferences?.feature?.authentication?.enableProductsPageWithoutLoggingIn
    ) {
        const hasSubmitted = localStorage.getItem(
            LOCAL_STORAGE_NOTICE_SELECTION
        );

        if (!hasSubmitted) {
            navigate('/disclaimer-page');
        }
    }
};

const onSubmitDisclaimer = (
    data: {
        investorType: string;
        countryCode: string;
    },
    navigate: NavigateFunction
) => {
    localStorage.setItem(LOCAL_STORAGE_NOTICE_SELECTION, JSON.stringify(data));
    navigate('/products');
};

export const strategies: {
    [key: string]: {
        onProductPage(arg: any): void;
        onSubmitDisclaimer(arg: any, navigate: NavigateFunction): void;
        onLogout?(arg?: any): void;
    };
} = {
    [ClientEnum.HG]: {
        onProductPage: ({ isLogin, preferences, navigate, role }: any) => {
            if (
                !preferences?.feature?.authentication
                    ?.enableProductsPageWithoutLoggingIn &&
                !isLogin
            ) {
                navigate('/login');
                return;
            }

            // should not display disclaimer if user is not a profile viewer
            if (![AuthRoleEnum.productViewer, AuthRoleEnum.profileManager].includes(role)) {
                return ;
            }

            const hasSubmitted = localStorage.getItem(
                LOCAL_STORAGE_NOTICE_SELECTION
            );

            if (!hasSubmitted) {
                navigate('/disclaimer-page');
            }
        },
        onSubmitDisclaimer,
        onLogout: () => {
            localStorage.removeItem(LOCAL_STORAGE_NOTICE_SELECTION);
        },
    },
    default: {
        onProductPage,
        onSubmitDisclaimer,
    },
};

export const getStrategies = (client: ClientEnum) => {
    const strategy = strategies[client];
    return strategy ?? strategies['default'];
};
