import { useFormikContext } from 'formik';

import { EndInvestorProfileResponse, getServiceLevelTypeLabels } from '@interfaces/Api';
import { RadioOption } from '@interfaces/InterfaceFormsProps';
import { useConfigurationState } from '@contexts/ConfigurationContext';
import { FormRadioGroup } from '@components/Molecules';

import getClientRegistrationFormFields from '../ClientRegistrationFormFields';
import { useRegisterInvestorState } from '../RegisterInvestor.context';
import { useTranslation } from 'react-i18next';
import { get } from 'cypress/types/lodash';

export const getServiceLevelTypeRadioOptions = (t: (key) => string): RadioOption<string>[] => [
    {
        label: getServiceLevelTypeLabels(t, EndInvestorProfileResponse.ServiceLevelTypeEnum.Advisory),
        value: EndInvestorProfileResponse.ServiceLevelTypeEnum.Advisory,
    },
    {
        label: getServiceLevelTypeLabels(t, EndInvestorProfileResponse.ServiceLevelTypeEnum.ExecutionOnly),
        value: EndInvestorProfileResponse.ServiceLevelTypeEnum.ExecutionOnly,
    },
];

export const ServiceLevelSelection = () => {
    const { t } = useTranslation();
    const { preferences } = useConfigurationState();

    const { data } = useRegisterInvestorState();

    const isDirectSelectable = data.endInvestorType === 'nomineeEntity';

    const serviceLevelOptionsData = preferences?.feature
        .investorRegistrationProcess?.enableAdvisoryClients
        ? getServiceLevelTypeRadioOptions(t)
        : getServiceLevelTypeRadioOptions(t).filter(
              ({ label }) => label !== getServiceLevelTypeLabels(
                t,
                EndInvestorProfileResponse.ServiceLevelTypeEnum.Advisory)
          );

    return (
        <>
            <h2 className="mb-4 text-2xl">
                {t('client.registration.service_level_type.title')}
            </h2>

            <FormRadioGroup
                name={getClientRegistrationFormFields(t).serviceLevelType.name}
                disabled={isDirectSelectable}
                optionsData={serviceLevelOptionsData}
            />
        </>
    );
};
