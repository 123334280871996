import { Http } from '../libs/http';
import { BaseUrl } from '@helpers/Environment';
import { ProfilePayload } from '@interfaces/Api/Profile';
import { SystemUserResponse } from '@interfaces/Api';

const PROFILE_URL = `${BaseUrl}/profile`;

export const postProfile = async (body: ProfilePayload) => {
    return await Http.post(`${PROFILE_URL}/`, body);
};

export const putProfile = async (body: ProfilePayload) => {
    return await Http.put(`${PROFILE_URL}/${body._id}`, body);
};

export const getProfiles = async (): Promise<ProfilePayload[]> => {
    return await Http.get(PROFILE_URL);
};

export const getProfile = async (id: string): Promise<ProfilePayload> => {
    return await Http.get(`${PROFILE_URL}/${id}`);
};

export const deleteProfile = async (id: string): Promise<any> => {
    return await Http.delete(`${PROFILE_URL}/${id}`);
};

export const getProfileUsers = async (
    id: string
): Promise<SystemUserResponse[]> => {
    return await Http.get(`${PROFILE_URL}/${id}/users`);
};
