import { Navigate, Route, Routes } from 'react-router-dom';
import { AuthPermissionEnum } from '@interfaces/Api';
import { useAuthState } from '@contexts/AuthContext';
import DocuSignCallback from './Containers/DocuSignCallback';
import { MutateBookingCentre } from './BookingCentres/MutateBookingCentre';

import Eligibility from './ProductsManagement/Components/Eligibility';
import ProductInformation from './ProductsManagement/Components/ProductInformation';
import ProductDescription from './ProductsManagement/Components/ProductDescription';
import ContactInformation from './ProductsManagement/Components/ContactInformation';
import EligibilityExclusion from './ProductsManagement/Components/EligibilityExclusion';
import ShareClassInformation from './ProductsManagement/Components/ShareClassInformation';
import Images from './ProductsManagement/Components/Images';
import Videos from './ProductsManagement/Components/Videos';
import Documents from './ProductsManagement/Components/Documents';
import ProductAddEdit from './ProductsManagement/ProductAddEdit';
import LiquidityInformation from './ProductsManagement/Components/LiquidityInformation';
import ProductListAdmin from './ProductsManagement/ProductListAdmin';

import ApplicationSettings from './ApplicationSettings/ApplicationSettings';
import { PreferenceSettings } from './PreferenceSettings';
import { ListSystemUsers } from './UserManagement/ListSystemUsers/Page';
import { MutateSystemUser } from './UserManagement/MuateSystemUsers/MutateSystemUsers';
import ListBookingCentres from './BookingCentres/BookingCentreList';
import { EventSimulator } from './Containers/EventSimulator/EventSimulator';
import CapitalCall from './Containers/EventSimulator/Templates/CapitalCall';
import ProductFees from './ProductsManagement/Components/ProductFees';
import { VisibilitySetting } from './ProductsManagement/Components/VisibilitySetting';
import { ProductLogo } from './ProductsManagement/Components/ProductLogo';
import { DisclaimerText } from './ApplicationSettings/DisclaimerText';
import DocumentTemplatesLibrary from './Containers/DocumentTemplateLibrary/Page';
import SubscriptionSigningDocumentTemplates from './ProductsManagement/Components/SubscriptionSigningDocumentTemplates';
import { DistributorContactEmail } from './ApplicationSettings/DistributorContactEmail';

export const AdminRoutes: React.FC = () => {
    const { hasPermissions } = useAuthState();

    return (
        <Routes>
            <Route path="admin">
                <Route path="" element={<Navigate replace to="users" />} />
                {hasPermissions(AuthPermissionEnum.updateConfiguration) && (
                    <>
                        <Route
                            path="application-settings"
                            element={<ApplicationSettings />}
                        />
                        <Route
                            path="application-settings/disclaimer-text"
                            element={<DisclaimerText />}
                        />
                        <Route
                            path="application-settings/document-library"
                            element={<DocumentTemplatesLibrary />}
                        />
                        <Route
                            path="application-settings/distributor-contact-email"
                            element={<DistributorContactEmail />}
                        />
                    </>
                )}

                {hasPermissions(AuthPermissionEnum.updatePreference) && (
                    <Route
                        path="preference-settings"
                        element={<PreferenceSettings />}
                    />
                )}

                <Route path="docuSignCallback" element={<DocuSignCallback />} />

                {hasPermissions([AuthPermissionEnum.getSystemUsers]) && (
                    <Route path="users" element={<ListSystemUsers />} />
                )}
                {hasPermissions([
                    AuthPermissionEnum.createSystemUser,
                    AuthPermissionEnum.updateSystemUser,
                ]) && (
                    <>
                        <Route
                            path="users/create-user"
                            element={<MutateSystemUser />}
                        />
                        <Route
                            path="users/update-user/:userId"
                            element={<MutateSystemUser />}
                        />
                    </>
                )}

                {hasPermissions([
                    AuthPermissionEnum.createProduct,
                    AuthPermissionEnum.deleteProduct,
                    AuthPermissionEnum.updateProduct,
                ]) && (
                    <>
                        <Route path="products" element={<ProductListAdmin />} />
                        <Route path="products" element={<ProductAddEdit />}>
                            <Route
                                path=":productId/"
                                element={
                                    <Navigate
                                        replace
                                        to="product-information"
                                    />
                                }
                            />
                            <Route
                                path=":productId/product-information"
                                element={<ProductInformation />}
                            />
                            <Route
                                path=":productId/liquidity-information"
                                element={<LiquidityInformation />}
                            />
                            <Route
                                path=":productId/product-fees"
                                element={<ProductFees />}
                            />
                            <Route
                                path=":productId/product-description"
                                element={<ProductDescription />}
                            />
                            <Route
                                path=":productId/contact-information"
                                element={<ContactInformation />}
                            />
                            <Route
                                path=":productId/product-eligibility"
                                element={<Eligibility />}
                            />
                            <Route
                                path=":productId/product-eligibility-exclusion"
                                element={<EligibilityExclusion />}
                            />
                            <Route
                                path=":productId/shareclass-information"
                                element={<ShareClassInformation />}
                            />
                            <Route
                                path=":productId/product-images"
                                element={<Images />}
                            />
                            <Route
                                path=":productId/product-videos"
                                element={<Videos />}
                            />
                            <Route
                                path=":productId/product-documents"
                                element={<Documents />}
                            />
                            <Route
                                path=":productId/visibility-settings"
                                element={<VisibilitySetting />}
                            />
                            <Route
                                path=":productId/subscription-signing-documents-templates"
                                element={
                                    <SubscriptionSigningDocumentTemplates />
                                }
                            />
                            <Route
                                path=":productId/logo"
                                element={<ProductLogo />}
                            />
                        </Route>
                    </>
                )}
                <Route
                    path="booking-centres"
                    element={<ListBookingCentres />}
                />
                {hasPermissions([
                    AuthPermissionEnum.createBookingCentre,
                    AuthPermissionEnum.deleteBookingCentre,
                ]) && (
                    <>
                        <Route
                            path="booking-centres/add-booking-centre"
                            element={<MutateBookingCentre />}
                        />
                    </>
                )}
                {hasPermissions([AuthPermissionEnum.viewEventSimulator]) && (
                    <>
                        <Route
                            path="event-simulator"
                            element={<EventSimulator />}
                        />
                        <Route path="event" element={<EventSimulator />} />
                        <Route
                            path="event-simulator/:eventType"
                            element={<CapitalCall />}
                        />
                    </>
                )}
            </Route>
        </Routes>
    );
};
