import React, { useCallback } from 'react';
import { Link } from 'react-router-dom';

import {
    AuthRoleEnum,
    ProposalStatusEnum,
    EndInvestorProfileResponse,
    ProposalResponse,
} from '@interfaces/Api';

import { Badge, Button, Icons } from '@components/Atoms';

import { ProposalPut } from '@api/Proposals';

import { useSystemUsers } from '@stores/SystemUsers/useSystemUsers';
import { useAuthState } from '@contexts/AuthContext';

import { notifyError, notifySuccess } from '@helpers/toastrHelper';
import { getFormattedCurrency } from '@helpers/isoCurrencies';
import { toCapitalizedWords } from '@helpers/ToCapitalizedWords';

import { useProduct } from '@stores/Products/useProduct';

interface SubscriptionProposalsItemElemProps {
    proposal: ProposalResponse;
    endInvestorProfile: EndInvestorProfileResponse;
    onProposalUpdate?(): void;
}

const SubscriptionProposalsItemElem: React.FC<
    SubscriptionProposalsItemElemProps
> = (props: SubscriptionProposalsItemElemProps) => {
    const { proposal, endInvestorProfile, onProposalUpdate } = props;

    const { hasRoles } = useAuthState();
    const { systemUsers } = useSystemUsers();

    const { product } = useProduct(proposal.productId);

    const getSystemUserName = useCallback(
        (systemUserId?: string) => {
            if (!systemUserId) return '';
            const systemUser = systemUsers.find(
                (val) => val._id === systemUserId
            );
            return [systemUser?.firstName, systemUser?.surname]
                .filter((a) => a)
                .join(' ');
        },
        [systemUsers]
    );

    const updateProposalStatus = async (
        status: ProposalStatusEnum,
        successMessage: string,
        errorMessage: string
    ) => {
        try {
            await ProposalPut({ status }, proposal._id);
            notifySuccess(successMessage);
            if (onProposalUpdate) {
                onProposalUpdate();
            }
        } catch (error) {
            notifyError(`${errorMessage} ${error}`);
        }
    };

    const handleAccept = async () => {
        await updateProposalStatus(
            ProposalStatusEnum.accepted,
            'Proposal has been Accepted',
            'Failed to update proposal'
        );
    };

    const handleReject = async () => {
        await updateProposalStatus(
            ProposalStatusEnum.rejected,
            'Proposal has been Rejected',
            'Failed to update proposal'
        );
    };

    if (!proposal || !endInvestorProfile) {
        return <div></div>;
    }

    return (
        <div
            key={proposal._id}
            className="bg-white hover:bg-gray-100 border-b md:border-b border-gray-100 pb-4 md:pb-0 md:px-2 my-4 md:my-0 flex flex-col md:flex-row justify-between items-center"
        >
            <div className="items-center md:items-left md:auto my-2">
                <Badge
                    label={toCapitalizedWords(proposal.status)}
                    badgeType={
                        proposal.status === ProposalStatusEnum.proposed
                            ? 'secondary'
                            : proposal.status === ProposalStatusEnum.accepted
                            ? 'success'
                            : proposal.status === ProposalStatusEnum.rejected
                            ? 'primary'
                            : 'error'
                    }
                    sizeType="large"
                >
                    {proposal.status === ProposalStatusEnum.proposed ? (
                        <div className="text-logo-blue-dark">
                            <Icons name="ClockIcon" size="small" />
                        </div>
                    ) : (
                        <Icons
                            name={
                                proposal.status === ProposalStatusEnum.accepted
                                    ? 'CheckIcon'
                                    : 'XMarkIcon'
                            }
                            size="small"
                        />
                    )}
                </Badge>
            </div>
            <div className="text-left md:text-left md:w-4/12 pl-2 my-2">
                <p>
                    <b>Product:</b> {product?.name}
                </p>
                <div>
                    <span className="inline-block">
                        <Badge
                            sizeType="small"
                            badgeType="preview"
                            label={proposal.shareClass?.name}
                        />
                    </span>{' '}
                    -{' '}
                    {getFormattedCurrency(
                        proposal.amount,
                        proposal.shareClass?.currency
                    )}
                </div>
            </div>
            <div className="md:w-3/12 px-2 my-2">
                {proposal.statusLogs?.map((statusLog, i) => (
                    <div className="flex flex-col" key={i.toString()}>
                        {proposal.statusLogs &&
                            i == proposal.statusLogs.length - 1 && (
                                <div className="ml-4 my-2">
                                    <div className="text-md text-gray-900">
                                        <strong className="font-medium">
                                            By:{' '}
                                        </strong>
                                        <span>
                                            <span className="font-bold text-logo-blue">
                                                {getSystemUserName(
                                                    statusLog.updatedBy
                                                )}{' '}
                                            </span>
                                            on{' '}
                                            {new Date(
                                                String(statusLog.createdAt)
                                            ).toLocaleDateString()}
                                        </span>
                                    </div>
                                </div>
                            )}
                    </div>
                ))}
            </div>
            <div className="md:w-3/12 px-2 my-2 md:justify-end">
                {proposal.status === ProposalStatusEnum.proposed &&
                    hasRoles([AuthRoleEnum.endInvestor]) && (
                        <>
                            <Button
                                label="Reject"
                                buttonType="preview"
                                onClick={handleReject}
                                className="ml-2"
                            />
                            <Button
                                label="Accept"
                                buttonType="primary"
                                onClick={handleAccept}
                                className="ml-2"
                            />
                        </>
                    )}
            </div>
            <div className="md:w-2/12">
                {proposal.subscriptionId && (
                    <Link to={`/subscriptions/${proposal.subscriptionId}/`}>
                        <Button
                            label="View Subscription"
                            buttonType="primary"
                            size="small"
                        />
                    </Link>
                )}
            </div>
        </div>
    );
};

export default SubscriptionProposalsItemElem;
