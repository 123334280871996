import { useTranslation } from 'react-i18next';
import { SelectOption } from '@interfaces/InterfaceFormsProps';
import { getInputStyle } from '@components/Atoms';

interface SelectBoxProps {
    name: string;
    value?: any;
    optionsData?: SelectOption<any>[];
    getOptionsData?(): Promise<SelectOption<any>[]>;
    onClick?: (e: React.MouseEvent<HTMLElement>) => void;
    onChange: (e: React.ChangeEvent<HTMLSelectElement>) => void;
    onBlur?: (e: React.FocusEvent<HTMLInputElement> | any) => void;
    disabled?: boolean;
    multiple?: boolean;
    placeholder?: string;
    dataTestId?: string;
    error?: boolean;
    showDefault?: boolean;
    showLabel?: boolean;
}

export const SelectBox = (props: SelectBoxProps) => {
    const { t } = useTranslation();
    const { showDefault = true, showLabel = true } = props;

    const selectStyle = getInputStyle({
        disabled: props.disabled,
        error: props.error,
        rightIcon: true,
        label: showLabel,
        cursor: 'pointer',
        isSelect: true,
    });

    return (
        <select
            className={selectStyle}
            placeholder={props.placeholder}
            onChange={props.onChange}
            onBlur={props.onBlur}
            value={props.value}
            onClick={props.onClick}
            name={props.name}
            id={props.name}
            disabled={props.disabled}
            multiple={props.multiple}
            data-testid={props.dataTestId}
        >
            {showDefault && <option value="">{t('ui.controls.please_select')}</option>}
            {props.optionsData?.map((option, i) => (
                <option key={`${option.value}-${i}`} value={option.value}>
                    {option.label}
                </option>
            ))}
        </select>
    );
};
