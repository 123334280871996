import { Document } from '@interfaces/Api';
import {
    LifecycleNotice,
    MailingListAdressee,
} from '@interfaces/Api/SubscriptionNotice';
import { eventListDateSubHeader } from './helpers/eventListHelper';
import { useStorageTokenState } from '@contexts/StorageTokenContext';
import { DownloadLink } from '@components/Molecules';

export interface DistributionEventProps {
    notice: LifecycleNotice;
}

export const DistributionEvent: React.FC<DistributionEventProps> = ({
    notice,
}) => {
    const { mailingList = [] } = notice;
    const { withToken } = useStorageTokenState();
    
    return (
        <>
            <p className="text-sm md:text-base leading-snug text-opacity-100">
                <span className="font-bold">
                    {eventListDateSubHeader(notice)}
                </span>
                <br />
                {mailingList?.length > 0 && (
                    <>The following investors have been notified:</>
                )}
                {mailingList?.map((adressee: MailingListAdressee, i) => (
                    <>
                        <span className="text-sm" key={`${adressee.email}${i}`}>
                            {adressee.email}
                        </span>
                        <br />
                    </>
                ))}
            </p>
            <p>{notice.comment}</p>
            {notice.documents?.map((document: Document, i) => (
                <DownloadLink key={document.fileName + i} document={document} iconPosition='right' />
            ))}
        </>
    );
};
