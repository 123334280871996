import React from 'react';
import { Button } from '@components/Atoms';

export interface InlineFormFrameInterface {
    title?: string;
    subtitle?: string;
    children?: React.ReactNode;
    primaryAction?: string;
    secondaryAction?: string;
    onPrimaryAction?: () => void;
    onSecondaryAction?: () => void;
    isPrimaryActionDisabled?: boolean;
}

const InlineFormFrame = (props: InlineFormFrameInterface) => (
    <>
        {props.title && (
            <h3 className="text-2xl font-normal leading-8 mt-10">
                {props.title}
            </h3>
        )}
        {props.subtitle && <p className="text-base py-2">{props.subtitle}</p>}

        <div className="bg-gold-300 border border-gray-300 rounded-xl my-4 px-4 py-5">
            <div>{props.children}</div>

            {props.primaryAction && (
                <Button
                    type="button"
                    buttonType="primary"
                    className="w-full mt-4"
                    onClick={props.onPrimaryAction}
                    disabled={props.isPrimaryActionDisabled}
                    label={props.primaryAction}
                />
            )}
            {props.secondaryAction && (
                <Button
                    type="button"
                    buttonType="secondary"
                    className="w-full mt-4"
                    onClick={props.onSecondaryAction}
                    label={props.secondaryAction}
                />
            )}
        </div>
    </>
);

export default InlineFormFrame;
