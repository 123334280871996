import { Icons } from '@components/Atoms';
import { ReviewFrame } from '@components/Molecules';
import { ProspectiveInvestorResponse } from '@interfaces/Api/ProspectiveInvestorResponse';

interface PersonalDetailsSectionProps {
    prospectiveInvestor: ProspectiveInvestorResponse;
}

const PersonalDetailsSection: React.FC<PersonalDetailsSectionProps> = ({
    prospectiveInvestor,
}) => {
    const personalDetails = [
        {
            label: 'First Name',
            value: prospectiveInvestor?.personalDetails?.firstName,
        },
        {
            label: 'Last Name',
            value: prospectiveInvestor?.personalDetails?.surname,
        },
        {
            label: 'Email',
            value: prospectiveInvestor?.personalDetails?.email,
        },
        {
            label: 'Phone Number',
            value: [
                prospectiveInvestor?.personalDetails?.phoneDiallingCode?.toString(),
                prospectiveInvestor?.personalDetails?.phoneNumber?.toString(),
            ].join(' '),
        },
    ];

    const name = [
        prospectiveInvestor?.personalDetails?.firstName,
        prospectiveInvestor?.personalDetails?.surname,
    ].join(' ');

    return (
        <ReviewFrame
            title="Personal Details"
            expanded={true}
            hideToggleButton={true}
        >
            <div className="flex flex-col md:flex-row w-full leading-5 mb-4">
                <div className="w-full lg:w-2/5 md:mr-4">
                    <div className="w-full h-full bg-white rounded-lg p-5 flex flex-col justify-center items-center">
                        <div className="mb-4">
                            <div className="flex flex-col justify-center items-center p-4 shadow-sm bg-gray-500 w-36 h-36 rounded-full text-white">
                                <Icons name="UserIcon" size="xxl" />
                            </div>
                        </div>

                        <p className="text-xl text-gray-700 font-bold mb-2 text-center">
                            {name}
                        </p>
                    </div>
                </div>
                <div className="w-full lg:w-4/5 flex items-center justify-start p-5 md:py-10 text-xs">
                    <div className="flex flex-col">
                        {personalDetails.map((el) => (
                            <p className="text-base font-bold" key={el.label}>
                                {el.label}
                            </p>
                        ))}
                    </div>
                    <div className="flex flex-col pl-4">
                        {personalDetails.map((el) => (
                            <p className="text-base" key={el.value}>
                                {el.value}
                            </p>
                        ))}
                    </div>
                </div>
            </div>
        </ReviewFrame>
    );
};

export default PersonalDetailsSection;
