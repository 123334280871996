import { t } from 'i18next';

import {
    EndInvestorProfileResponse,
    SystemUserResponse,
} from '@interfaces/Api';

export interface ReviewClientSummaryDetailsProps {
    data?: EndInvestorProfileResponse;
    linkedSystemUser?: SystemUserResponse;
}

export const businessRelationShipsReviewTypeLabelMap: {
    [key in
        | EndInvestorProfileResponse.ServiceLevelTypeEnum
        | EndInvestorProfileResponse.SubscriptionProcessTypeEnum]: string;
} = {
    executionOnly: t('service_level_type.execution_only'),
    advisory: t('service_level_type.advisory'),
    direct: t('subscription_process_type.direct'),
    onBehalf: t('subscription_process_type.on_behalf'),
    nominee: t('subscription_process_type.nominee'),
};
