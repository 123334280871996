import React from 'react';
import classNames from 'classnames';
import { dateShortestFormatter } from '@helpers/Date.helper';
import { TimelineProps } from './interfaces';

const Timeline: React.FC<TimelineProps> = (props: TimelineProps) => {
    const { header, footer, eventsList } = props;
    const borderStyle = classNames([
        'border-2 border-logo-blue border-solid absolute h-full border',
    ]);

    return (
        <>
            <div className="flex flex-col w-full sticky top-30 lg:w-[19%] pl-8">
                <ul className="list-disc">
                    {eventsList.map((item, i) => {
                        const { heading } = item;
                        return (
                            <li className="mb-2" key={`${heading}${i}`}>
                                <a
                                    href={`#${heading
                                        .replace(' ', '-')
                                        .trim()}`}
                                    className="hover:underline text-sm"
                                >
                                    {heading}
                                </a>
                            </li>
                        );
                    })}
                </ul>
            </div>
            <div className="m-0 lg:w-[81%] sticky">
                {header}
                <div className="container mx-auto w-full h-full">
                    <div className="wrap overflow-hidden pr-10 h-full">
                        <div
                            className={classNames([borderStyle, 'right-[50%]'])}
                        ></div>
                        <div
                            className={classNames([borderStyle, 'left-[50%]'])}
                        ></div>

                        {eventsList.map((item, i) => {
                            const { children, date, heading } = item;
                            return (
                                <div
                                    key={`${date}${i}`}
                                    data-testid="event-item"
                                    className={classNames(
                                        `mb-8 flex justify-between items-center w-full ${
                                            i % 2 !== 0 ? 'right' : 'left'
                                        }-timeline`,
                                        {
                                            'flex-row-reverse': i % 2 !== 0,
                                        }
                                    )}
                                >
                                    <div className="order-1 w-5/12"></div>
                                    <div
                                        className={classNames(
                                            ` relative order-1 w-5/12 px-1 py-4 ${
                                                i % 2 !== 0
                                                    ? 'text-right'
                                                    : 'text-left md:pl-8'
                                            }`
                                        )}
                                    >
                                        <p className="mb-3 text-base font-bold text-sky-500">
                                            {date &&
                                                dateShortestFormatter(date)}
                                        </p>
                                        <h4
                                            id={`${heading
                                                .replace(' ', '-')
                                                .trim()}`}
                                            className={classNames(
                                                `mb-3 font-bold text-lg md:text-2xl before:block before:absolute before:bg-sky-500 before:w-10 before:h-10 before:rounded-full ${
                                                    i % 2 !== 0
                                                        ? 'before:-right-[38%] md:before:-right-[45%] lg:before:-right-[33%] xl:before:-right-[30%] 2xl:before:-right-[28.2%]'
                                                        : 'before:-left-[20%]'
                                                }`
                                            )}
                                        >
                                            {heading}
                                        </h4>
                                        {children}
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                </div>
                <hr className="w-1/2 relative left-1/4" />
                {footer}
            </div>
        </>
    );
};

export default Timeline;
