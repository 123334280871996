import { useTranslation } from 'react-i18next';
import { useRegisterInvestorState } from '../RegisterInvestor.context';

export const AddressSummary = (): JSX.Element => {
    const { t } = useTranslation();
    const { data } = useRegisterInvestorState();

    return (
        <p>
            <span className="font-bold mr-2">{t('client.registration.summary.addresses')}:</span>
            {data.individualDetails?.addresses?.map((address) => (
                <div className="mr-2" key={address.houseName}>
                    <p>{address.houseName}</p>
                    <p>{address.residentialLine1}</p>
                    <p>{address.residentialLine2}</p>
                    <p>{address.city}</p>
                    <p>{address.postCode}</p>
                    <p>{address.countryCode}</p>
                </div>
            ))}
        </p>
    );
};
