import { InvestorJurisdiction } from '@interfaces/Api/InvestorJurisdictionsResponse';

export const transformJurisdictionsToInvestorTypesMap = (
    investorJurisdictions: InvestorJurisdiction[]
) => {
    const investorTypeMap: any = {};

    investorJurisdictions.forEach(
        (investorJurisdiction: InvestorJurisdiction) => {
            investorJurisdiction.investorTypes.forEach(({ value, label }) => {
                if (!investorTypeMap[value]) {
                    investorTypeMap[value] = {
                        countries: [],
                        label,
                        value,
                    };
                }

                investorTypeMap[value].countries.push({
                    countryCode: investorJurisdiction.countryCode,
                    jurisdictionCountry:
                        investorJurisdiction.jurisdictionCountry,
                });
            });
        }
    );

    return investorTypeMap;
};
