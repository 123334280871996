import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';
import { BarChartProps } from '@interfaces/InterfaceChartProps';

ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
);

export const BarChart = ({ labels, datasets, options }: BarChartProps) => (
    <Bar
        options={options}
        data={{
            labels,
            datasets,
        }}
    />
);
