import { useState } from 'react';
import { Pagination } from '@components/Organisms';
import { TopBar } from './components/TopBar';
import { useSortableData } from '@hooks/useSortableData/useSortableData';
import DocumentTemplateTable from './components/DocumentTemplateTable';
import { useDocumentTemplatesLibraryState } from '@contexts/DocumentTemplateLibrary';
import { useMountEffect } from '@hooks/useMountEffect';

const DocumentTemplatesLibrary = () => {
    const { documentTemplateList, getDocumentTemplates } =
        useDocumentTemplatesLibraryState();
    const [searchText, setSearchText] = useState<string>();
    const [itemsPerPage, setItemsPerPage] = useState<number | string>(50);
    const [showDocumentUploadModal, setShowDocumentModal] =
        useState<boolean>(false);

    useMountEffect(() => {
        getDocumentTemplates();
    });

    const {
        tableData: templateDocuments,
        tableDataLength,
        requestSort,
        sortConfig,
        setCurrentPageNumber,
        currentPageNumber,
        paginate,
        paginateBack,
        paginateFront,
    } = useSortableData({
        tableData: documentTemplateList,
        itemsPerPage,
    });

    const pagination = (
        <Pagination
            rowsPerPage={itemsPerPage}
            itemsLength={tableDataLength}
            paginate={paginate}
            currentPage={currentPageNumber}
            paginateBack={paginateBack}
            paginateFront={paginateFront}
            onPageSizeChange={setItemsPerPage}
        />
    );

    return (
        <div className="w-full relative">
            <TopBar
                setSearchText={setSearchText}
                showDocumentUploadModal={showDocumentUploadModal}
                setShowDocumentModal={setShowDocumentModal}
                setCurrentPageNumber={setCurrentPageNumber}
            />

            {pagination}

            <DocumentTemplateTable
                searchText={searchText || ''}
                items={templateDocuments}
                requestSort={requestSort}
                columnSortConfig={sortConfig}
                setShowDocumentModal={setShowDocumentModal}
                showDocumentUploadModal={showDocumentUploadModal}
            />

            {pagination}
        </div>
    );
};

export default DocumentTemplatesLibrary;
