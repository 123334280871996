import React, { useCallback } from 'react';
import { UploadDocument } from './FileUploadAsync';

import { FileUploadLine } from './FileUploadLine';
import FileDropper from './FileDropper';
import { useField } from 'formik';
import { SortableList } from '../SortableList';
import { t } from 'i18next';



interface FileUploadProps {
    name: string;
    canEditLabel?: boolean;
    onHandleUpload: (file: File[]) => void;
    onHandleRemove?: (url: string) => void;
    uploadingFiles?: { fileName: string; fileSize: string }[];
    uploadedFiles?: UploadDocument[];
    onEditFileLabel?: (document: UploadDocument) => void;
    imageOnly?: boolean;
    supportedFileTypes?: string[];
    maxFiles?: number;
}

const FileUpload: React.FC<FileUploadProps> = (props: FileUploadProps) => {
    const {
        name,
        onHandleUpload,
        onHandleRemove,
        uploadedFiles = [],
        uploadingFiles,
        supportedFileTypes,
        imageOnly = false,
        maxFiles = 0,
    } = props;

    const [field, meta, helpers] = useField(props.name);

    const uploadDocuments = useCallback(
        (files: File[]) => {
            // remove files which have already been uploaded
            // remove the file which is not supported by the backend
            const newFiles = files.filter(
                (uploadFile) =>
                    !uploadedFiles?.find(
                        (val) => val.fileName === uploadFile.name
                    )
            );

            onHandleUpload(newFiles);
        },
        [onHandleUpload, uploadedFiles]
    );

    const inputFileChangeHandler = (
        event: React.ChangeEvent<HTMLInputElement>
    ) => {
        const uploadedFileList = event.target.files;
        if (uploadedFileList && uploadedFileList.length > 0) {
            const uploadedFiles = Array.from(uploadedFileList);
            uploadDocuments(uploadedFiles);
        }
    };

    const onDrop = useCallback(
        (acceptedFiles: File[]) => {
            if (acceptedFiles && acceptedFiles.length > 0) {
                uploadDocuments(acceptedFiles);
            }
        },
        [uploadDocuments]
    );

    const removeUploadingFile = (index: number) => {
        if (index > -1 && uploadedFiles) {
            uploadedFiles?.splice(index, 1);
        }
    };

    return (
        <div className="w-full">
            <FileDropper
                name={name}
                onDrop={onDrop}
                imageOnly={imageOnly}
                supportedFileTypes={supportedFileTypes} 
                inputFileChangeHandler={inputFileChangeHandler}
            />

            {((uploadedFiles && uploadedFiles.length > 0) ||
                (uploadingFiles && uploadingFiles.length > 0)) && (
                <h5 className="mt-3 text-sm">{t('file_upload.uploaded_files')}</h5>
            )}

            {
                <SortableList
                    onSorted={helpers.setValue}
                    listItems={uploadedFiles!}
                    handleClassName='relative -inset-x-5'
                    listElements={
                        uploadedFiles!.map((val, index) => (
                            <FileUploadLine
                                key={index + val.url + val.label}
                                fileName={val.fileName}
                                fieldName={name}
                                fileList={uploadedFiles}
                                label={val.label}
                                fileSize={val.fileSize}
                                url={val.url}
                                isLoading={false}
                                canEditLabel={props.canEditLabel}
                                onEditFileClick={() => {
                                    if (props.onEditFileLabel) props.onEditFileLabel(val);
                                }}
                                onRemoveFileClick={() => {
                                    onHandleRemove && onHandleRemove(val.url);
                                }}
                            />
                        ))
                    }
                />
            }

            {uploadingFiles?.map((val, index) => (
                <FileUploadLine
                    key={val.fileName + index}
                    fileName={val.fileName}
                    fieldName={name}
                    fileSize={val.fileSize}
                    isLoading={true}
                    onRemoveFileClick={() => {
                        removeUploadingFile(index);
                    }}
                />
            ))}
        </div>
    );
};

export default FileUpload;
