import { nomineeUnderlyingInvestorsGet } from '@api/Nominee';
import { getEndInvestorName } from '@helpers/EndInvestorHelper';
import { EndInvestorProfileResponse } from '@interfaces/Api';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

export interface UnderlyingClientsProps {
    endInvestorProfile: EndInvestorProfileResponse;
}

const UnderlyingClients: React.FC<UnderlyingClientsProps> = (props) => {
    const { endInvestorProfile } = props;
    const [underlyingAdvisoryClients, setUnderlyingAdvisoryClients] = useState<
        EndInvestorProfileResponse[]
    >([]);

    useEffect(() => {
        if (
            endInvestorProfile.endInvestorType ===
            EndInvestorProfileResponse.EndInvestorType.nomineeEntity
        ) {
            nomineeUnderlyingInvestorsGet(endInvestorProfile._id).then(
                (res) => {
                    const endInvestors = res.reduce(
                        (
                            endInvestors: EndInvestorProfileResponse[],
                            underlyingInvestor
                        ) => {
                            // check if endInvestor is already in the array
                            if (
                                !endInvestors.find(
                                    (endInvestor) =>
                                        endInvestor._id ===
                                        underlyingInvestor.endInvestorProfile
                                            ._id
                                )
                            ) {
                                endInvestors.push(
                                    underlyingInvestor.endInvestorProfile
                                );
                            }

                            return endInvestors;
                        },
                        []
                    );

                    setUnderlyingAdvisoryClients(endInvestors);
                }
            );
        }
    }, [endInvestorProfile]);

    return (
        <div className="my-8">
            {underlyingAdvisoryClients.map((underlyingAdvisoryClient, i) => (
                <Link
                    key={i}
                    className="bg-white border-b pb-1 my-4 md:my-0 hover:bg-gray-100 flex flex-col md:flex-row justify-between items-center cursor-pointer"
                    to={`/clients/${underlyingAdvisoryClient._id}?section=ClientDetails`}
                >
                    <p className="text-center md:text-left md:w-9/12 pl-2 my-2">
                        {getEndInvestorName(underlyingAdvisoryClient)}
                    </p>
                </Link>
            ))}
        </div>
    );
};

export default UnderlyingClients;
