import { useState } from 'react';
import { Field, FieldInputProps, FieldMetaProps } from 'formik';

import { InputBox, InputBoxProps } from '@components/Atoms';
import { FormFieldWrapper } from '../FormFieldWrapper';

interface IInputToSubmit {
    isInputHidden: boolean;
    hideIcons?: boolean;
    onToggleBlur?: (e) => void;
    autoFocus?: boolean;
}

interface IInputBoxProps extends InputBoxProps {
    currency?: string;
}

const FormInputBox: React.FC<IInputBoxProps> = (props) => {
    const InputToSubmit = ({
        isInputHidden,
        hideIcons = false,
        onToggleBlur,
        autoFocus = false,
    }: IInputToSubmit) => (
        <FormFieldWrapper
            label={props.label}
            leftIcon={hideIcons ? null : props.leftIcon}
            rightIcon={hideIcons ? null : props.rightIcon}
            name={props.name}
            isInputHidden={isInputHidden}
        >
            <Field name={props.name} onChange={props.onChange}>
                {({
                    field,
                    meta,
                }: {
                    field: FieldInputProps<string>;
                    meta: FieldMetaProps<string>;
                }) => (
                    <InputBox
                        {...field}
                        id={props.id}
                        placeholder={props.placeholder}
                        type={props.type}
                        leftIcon={props.leftIcon}
                        label={props.label}
                        defaultValue={props.defaultValue}
                        readOnly={props.readOnly}
                        disabled={props.disabled}
                        minLength={props.minLength}
                        maxLength={props.maxLength}
                        isHidden={isInputHidden}
                        value={props.value || field.value}
                        onBlur={(e) => {
                            if (onToggleBlur) {
                                onToggleBlur(e);
                            } else {
                                props.onBlur && props.onBlur(e);
                            }

                            field.onBlur(e);
                        }}
                        onChange={props.onChange || field.onChange}
                        dataTestId={props.dataTestId}
                        autoComplete={props.autoComplete}
                        hasError={(meta.touched && !!meta.error) || false}
                        autoFocus={autoFocus}
                    />
                )}
            </Field>
        </FormFieldWrapper>
    );

    const [isEditMode, toggleEditMode] = useState(false);

    return (
        <div className="relative w-full mb-3">
            {props.currency && (
                <>
                    {isEditMode
                        ? InputToSubmit({
                              isInputHidden: false,
                              hideIcons: true,
                              onToggleBlur: (e) => {
                                  toggleEditMode(false);
                              },
                              autoFocus: true,
                          })
                        : InputToSubmit({
                              isInputHidden: true,
                              hideIcons: true,
                          })}
                    {!isEditMode && (
                        <FormFieldWrapper label={props.label} name={props.name}>
                            <Field name={props.name}>
                                {({ field }) => (
                                    <InputBox
                                        name={props.name}
                                        value={new Intl.NumberFormat('en-EN', {
                                            style: 'currency',
                                            currency: props.currency,
                                        }).format(field.value)}
                                        onFocus={() => toggleEditMode(true)}
                                        label={props.label}
                                    />
                                )}
                            </Field>
                        </FormFieldWrapper>
                    )}
                </>
            )}

            {!props.currency && InputToSubmit({ isInputHidden: false })}

            {props.hint && (
                <div className="text-xs pt-3 pl-0.5">{props.hint}</div>
            )}
        </div>
    );
};

export default FormInputBox;
