import { Formik, Form } from 'formik';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { useProductsState } from '@stores/Products';

import { useProductSubmit } from '@hooks/useProductSubmit';

import { FormCheckbox } from '@components/Molecules';
import EditProductFormikButtons from './EditProductFormikButtons';
import { VisibilitySettings as VisibilitySettingsType } from '@interfaces/Api/ProductResponse';

export const VisibilitySetting: React.FC = () => {
    const initialVals: VisibilitySettingsType = {
        hideAssetClassBadge: false,
        hideLiquidityBadge: false,
        hideCurrencies: false,
        hideDistributionFeeOnShareclasses: false,
    };

    const [formValue, setFormValue] = useState<any>(initialVals);

    const { productId } = useParams();

    const { products } = useProductsState();
    const product = products.filter((e) => e._id === productId)[0];

    useEffect(() => {
        if (productId && product) {
            setFormValue(product);
        }
    }, [product, productId]);

    const onSubmit = useProductSubmit(
        productId,
        `subscription-signing-documents-templates`
    );

    return (
        <div className="w-full">
            <h1 className="text-xl text-gray-500">Visibility Setting</h1>
            {(productId === 'new' || (productId && formValue._id)) && (
                <Formik
                    initialValues={formValue}
                    onSubmit={(values) => {
                        return onSubmit(values);
                    }}
                    enableReinitialize
                >
                    <Form>
                        <div className="my-3">
                            <FormCheckbox
                                name="visibilitySettings.hideAssetClassBadge"
                                actionLabel="Do not show asset class badge on the header of product page"
                            />
                        </div>
                        <div className="my-3">
                            <FormCheckbox
                                name="visibilitySettings.hideLiquidityBadge"
                                actionLabel="Do not show liquidity badge badge on the header of product page"
                            />
                        </div>
                        <div className="my-3">
                            <FormCheckbox
                                name="visibilitySettings.hideCurrencies"
                                actionLabel="Do not show currencies badge on the header of product page"
                            />
                        </div>
                        <div className="my-3">
                            <FormCheckbox
                                name="visibilitySettings.hideDistributionFeeOnShareclasses"
                                actionLabel="Do not show distribution fee on the product page and on subscriptions"
                            />
                        </div>
                        <div className="my-3">
                            <EditProductFormikButtons
                                productId={productId}
                                isReadOnly={false}
                                hasPreview
                                pathPrevious="logo"
                                previewLinkTo={`../../../products/${product?.slug}`}
                            />
                        </div>
                    </Form>
                </Formik>
            )}
        </div>
    );
};
