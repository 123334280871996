import dayjs from 'dayjs';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Button } from '@components/Atoms';
import { Modal } from '@components/Molecules';

import { useAuthState } from '@contexts/AuthContext';

import { useCountdown } from './UseCountdown';
import { useIdleTimer } from 'react-idle-timer';
import { useNavigate } from 'react-router-dom';

const TIMEOUT_MILLISECONDS_INACTIVITY_MODAL = 5 * 60 * 1000; // number of milliseconds to show session timeout modal
const TIMEOUT_MILLISECONDS_LOGOUT_USER = 5 * 60 * 1000; // number of milliseconds to logout from showing the session modal

const SessionTimeout: React.FC = () => {
    const { t } = useTranslation();
    const [showInactivityModal, setShowInactivityModal] =
        useState<boolean>(false);
    const navigate = useNavigate();

    const {
        minutes,
        seconds,
        setEndDate: setCountdownEndDate,
    } = useCountdown();

    const { logout } = useAuthState();

    const onPrompt = () => {
        setShowInactivityModal(true);
        setCountdownEndDate(
            dayjs()
                .add(TIMEOUT_MILLISECONDS_LOGOUT_USER, 'milliseconds')
                .toDate()
        );
    };

    const onIdle = () => {
        logoutHandler();
    };

    const {
        reset,
        message, // send a message to all tabs
    } = useIdleTimer({
        // onActive,
        // onAction,
        timeout: TIMEOUT_MILLISECONDS_INACTIVITY_MODAL,
        onPrompt, // this is called after timeout is done, open modal
        promptTimeout: TIMEOUT_MILLISECONDS_LOGOUT_USER,
        onIdle, // method called after promptTimeout is done, logout user
        events: [
            'mousemove',
            'keydown',
            'wheel',
            'DOMMouseScroll',
            'mousewheel',
            'mousedown',
            'touchstart',
            'touchmove',
            'MSPointerDown',
            'MSPointerMove',
            'visibilitychange',
        ],
        immediateEvents: [],
        eventsThrottle: 200,
        element: document,
        stopOnIdle: true,

        crossTab: true,
        name: 'idle-timer',
        syncTimers: 0, // could do something with this
        leaderElection: false,
        onMessage: (message: 'CONTINUE_WORKING') => {
            if (message === 'CONTINUE_WORKING') {
                setShowInactivityModal(false);
                setCountdownEndDate();
            } else {
                console.error('[SessionTimeout.tsx], Unknown message', message);
            }
        },
    });

    const continueWorkingHandler = () => {
        reset();
        message('CONTINUE_WORKING', true);
    };

    const logoutHandler = () => {
        logout();
        navigate('/login');
    };

    return (
        <Modal show={showInactivityModal}>
            <Modal.Header>{t('session_timeout.title')}</Modal.Header>
            <Modal.Body>
                {t('session_timeout.session_will_expire_soon')}{' '}
                {`${minutes?.toString().padStart(2, '0')}:${seconds
                    ?.toString()
                    .padStart(2, '0')}`}
            </Modal.Body>
            <Modal.Footer>
                <Button
                    buttonType="secondary"
                    onClick={logoutHandler}
                    label={t('logout')}
                ></Button>
                <Button
                    onClick={continueWorkingHandler}
                    label={t('session_timeout.continue_working')}
                ></Button>
            </Modal.Footer>
        </Modal>
    );
};

export default SessionTimeout;
