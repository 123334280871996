import { useState } from 'react';
import { SuitabilityQuestionnairePost } from '@api/SuitabilityQuestionnaire';
import { notifySuccess } from '@helpers/toastrHelper';

import SuitabilitySubmissionModal from './SuitabilitySubmissionModal';
import { suitabilityQuestions } from 'common';
import { ProgressBar } from '@components/Atoms';
import QuestionComponent from './QuestionComponent';
import { mainLayoutWithBreadcrumbsConfig } from '@components/Layout';

const SuitabilityQuestionnairePage: React.FC = () => {
    const questions = suitabilityQuestions;
    const [currentQuestion, setCurrentQuestion] = useState(questions[0]);
    const [answers, setAnswers] = useState<{
        [questionId: string]: { answerId: string; extraText?: string }[];
    }>({});
    const [isSubmissionModalOpen, setIsSubmissionModalOpen] = useState(false);
    const [submissionResult, setSubmissionResult] = useState<{
        mifidProStatus: 'approved' | 'manual' | 'rejected';
        status: 'approved' | 'manual' | 'rejected';
    }>();

    const currentQuestionIndex = questions.findIndex(
        (q) => q.questionId === currentQuestion.questionId
    );

    const handleOnNext = (
        answer:
            | { answerId: string }
            | { answerId: string; extraText?: string }[]
    ) => {
        // check is answer is an array or not
        const isAnswerArray = Array.isArray(answer);
        const tempAnswer = isAnswerArray ? answer : [answer];

        const newAnswer = {
            ...answers,
            [currentQuestion.questionId]: tempAnswer,
        };
        setAnswers(newAnswer);

        // if last slide, then submit:
        const isLastQuestion =
            questions[currentQuestionIndex + 1] === undefined;
        if (isLastQuestion) {
            // TODO: submission here
            console.log('submit', newAnswer);
            SuitabilityQuestionnairePost(newAnswer).then((a) => {
                console.log('SuitabilityQuestionnairePost', a);
                setSubmissionResult({
                    mifidProStatus: a.mifidProStatus,
                    status: a.status,
                });
                setIsSubmissionModalOpen(true);
                notifySuccess('Successfully submitted questionnaire');
            });
        } else {
            const currentIndex = questions.findIndex(
                (q) => q.questionId === currentQuestion.questionId
            );
            setCurrentQuestion((q) => questions[currentIndex + 1]);
        }
    };

    const goPrevious = () => {
        // find current index, set current index - 1
        const currentIndex = questions.findIndex(
            (q) => q.questionId === currentQuestion.questionId
        );
        setCurrentQuestion((q) => questions[currentIndex - 1]);
    };

    //if there aren't any previous questions, disable the back button
    const handleOnPrevious = questions[currentQuestionIndex - 1] && goPrevious;
    const nextText = questions[currentQuestionIndex + 1] ? 'Next' : 'Submit';

    const currentAnswer = answers[currentQuestion?.questionId];

    // start at 1 so that user sees 1/10 instead of 0/10
    // add 1 to questions.length so that user sees 10/10 instead of a totally filled bar
    const currentProgress =
        ((currentQuestionIndex + 1) / (questions.length + 1)) * 100;

    return (
        <div className="md:w-2/3 lg:w-1/2 h-auto mx-auto">
            <h3 className="text-3xl mt-4">Suitability Questionnaire</h3>
            <p className="mb-10">
                In order to complete your profile, please complete this short
                suitability questionnaire. This will help us to determine
                whether you are eligible to invest in the Hg Fusion fund.
            </p>
            <div className="pb-6 mb-4">
                <ProgressBar progress={currentProgress} />
            </div>

            <QuestionComponent
                question={currentQuestion}
                answer={currentAnswer}
                onNext={handleOnNext}
                nextText={nextText}
                onPrevious={handleOnPrevious}
            />
            {submissionResult && (
                <SuitabilitySubmissionModal
                    isOpen={isSubmissionModalOpen}
                    suitabilitySubmissionResult={submissionResult}
                    onClose={() => {
                        setSubmissionResult(undefined);
                        setIsSubmissionModalOpen(false);
                    }}
                    onSubmitted={() => {}}
                />
            )}
        </div>
    );
};

export default mainLayoutWithBreadcrumbsConfig(
    SuitabilityQuestionnairePage,
    [
        {
            displayText: 'Application',
            navigationUrl: '/application',
        },
        {
            displayText: 'Suitability Questionnaire',
            navigationUrl: '/suitability-questionnaire',
        },
    ],
    undefined
);
