import { Button, Icons } from '@components/Atoms';

interface SummaryProps {
    currentStepName: string;
    steps: { stepLabel: string; canEdit: boolean; isCompleted: boolean }[];
    setCurrentStep: (stepName: string) => void;
}

export const Summary = ({
    currentStepName,
    setCurrentStep,
    steps,
}: SummaryProps): JSX.Element => {
    return (
        <div>
            <p className="mb-4 text-2xl">Summary</p>
            {steps.map((step) => {
                const isStepChecked = currentStepName === step.stepLabel;

                return (
                    <div
                        key={step.stepLabel}
                        className="flex justify-between border-b py-2 last:border-b-0"
                    >
                        <div className="mr-2">
                            {step.stepLabel}

                            {step.isCompleted && (
                                <span className="inline-flex">
                                    &nbsp;
                                    <Icons name="CheckIcon" size="small" />
                                </span>
                            )}
                        </div>

                        {currentStepName !== step.stepLabel && step.canEdit && (
                            <div className="border-l pl-2 flex items-center">
                                <Button
                                    hasIconOnly
                                    startIcon={
                                        <Icons name="PencilIcon" size="small" />
                                    }
                                    onClick={() => {
                                        setCurrentStep(step.stepLabel);
                                    }}
                                />
                            </div>
                        )}
                    </div>
                );
            })}
        </div>
    );
};
