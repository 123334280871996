import { configurationGet } from '@api/Configuration';
import { getEnvironmentVariable } from '@helpers/Environment';
import { useMountEffect } from '@hooks/useMountEffect';
import { ConfigurationResponse } from '@interfaces/Api';
import { useState } from 'react';

const HealthCheck = () => {
    const [backendhealthStatus, setBackendStatus] = useState<
        'loading' | '✅ online' | '❌ offline'
    >('loading');
    const [configuration, setConfiguration] = useState<ConfigurationResponse>();

    useMountEffect(() => {
        configurationGet()
            .then((a) => {
                setBackendStatus('✅ online');
                setConfiguration(a);
            })
            .catch((a) => {
                setBackendStatus('❌ offline');
            });
    });

    return (
        <div className="p-8">
            <h1 className="text-2xl text-logo-blue"> AltoFlex Health Check</h1>

            <p className="p-2 pt-10">
                <b>UI BUILD: </b>
                {getEnvironmentVariable('REACT_APP_BUILD')}
            </p>
            <p className="p-2 pt-10">
                <b>API status:</b> {backendhealthStatus}
            </p>
            <p className="p-2">
                <b>API url: </b>
                {getEnvironmentVariable('REACT_APP_S64_API_URL')} {}
            </p>
            <p className="p-2">
                <b>API build: </b>
                {configuration?.build}
            </p>
            <p className="p-2">
                <b>API environment: </b>
                {configuration?.environment}
            </p>
            <p className="p-2">
                <b>API client: </b>
                {configuration?.client}
            </p>
        </div>
    );
};

export default HealthCheck;
