import { ReactNode, useEffect } from 'react';

import { useAuthState } from '@contexts/AuthContext';
import { useConfigurationState } from '@contexts/ConfigurationContext';

import { Breadcrumbs, Logo } from '@components/Molecules';
import { Footer, Navbar } from '@components/Organisms';
import { BasicBreadcrumbs, BreadcrumbsItem } from '@components/Atoms';

interface MainLayoutProps {
    children: ReactNode;
    breadcrumbsItems?: BreadcrumbsItem[];
    breadcrumbs?: ReactNode;
}

const MainLayout: React.FC<MainLayoutProps> = (props) => {
    const { children } = props;
    const { isLoggedIn } = useAuthState();
    const { configuration } = useConfigurationState();

    useEffect(() => {
        document.title = 'S64 AltoFlex';

        if (configuration?.platformName && configuration?.platformOwnerName) {
            document.title = `${configuration?.platformOwnerName} ${configuration?.platformName}`;
        }
    }, [configuration]);

    return (
        <div className="flex flex-col min-h-screen bg-white">
            <header className="border-b border-gray-200 bg-header-bg">
                {isLoggedIn ? (
                    <Navbar />
                ) : (
                    <div className="container mx-auto px-4 sm:px-8 h-20 bg-header-bg z-30">
                        <div className="w-full h-full flex items-center justify-end md:justify-start py-2">
                            <Logo className="w-full lg:w-20 flex items-start justify-start py-2" />
                        </div>
                    </div>
                )}
            </header>
            <div className="bg-white-50 grow min-h-content container mx-auto px-4 sm:px-8">
                <main className="h-full">
                    {isLoggedIn ? (
                        <div className="mx-2 md:mx-0 mt-2 pt-5 mb-5">
                            {props.breadcrumbsItems ? (
                                <BasicBreadcrumbs
                                    items={props.breadcrumbsItems}
                                />
                            ) : props.breadcrumbs ? (
                                props.breadcrumbs
                            ) : (
                                <Breadcrumbs />
                            )}
                        </div>
                    ) : (
                        <div className="h-5" />
                    )}

                    <div className="h-full mx-2 md:mx-0">
                        <div className="xs:container xs:mx-auto max-w-full">
                            {children}
                        </div>
                    </div>
                </main>
            </div>
            <Footer />
        </div>
    );
};

export default MainLayout;
