import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { Field, FieldInputProps, Form, Formik } from 'formik';
import { useParams } from 'react-router-dom';

import EditProductFormikButtons from '@pages/Admin/ProductsManagement/Components/EditProductFormikButtons';
import { Checkbox } from '@components/Atoms';

import { useMountEffect } from '@hooks/useMountEffect';
import { useProductSubmit } from '@hooks/useProductSubmit';

import { InvestorJurisdiction } from '@interfaces/Api/InvestorJurisdictionsResponse';
import { Eligibility as EligiblityType } from '@interfaces/Api/ProductResponse';

import { InvestorJurisdictionsGetAll } from '@api/InvestorJurisdictions';
import { transformJurisdictionsToInvestorTypesMap } from '@helpers/eligibility.helper';
import { FormCheckbox, ToggleSwitch } from '@components/Molecules';
import { useProductsState } from '@stores/Products';

const InvestorTypeCountryList: FC<{
    investorTypesMap: Record<string, any>;
}> = ({ investorTypesMap }) => {
    return (
        <>
            {Object.values(investorTypesMap)?.map(
                ({ label, value, countries }: any, i) => (
                    <div key={i} className="py-2">
                        <h5 className="text-md">{label}</h5>

                        <div className="text-sm pl-4">
                            {countries.map(
                                (
                                    { countryCode, jurisdictionCountry }: any,
                                    i: number
                                ) => (
                                    <div key={i} className="mt-3">
                                        <Field
                                            name={`eligibleInvestorJurisdictions.${countryCode}-${value}`}
                                            type="checkbox"
                                        >
                                            {({
                                                field,
                                            }: {
                                                field: FieldInputProps<string>;
                                            }) => (
                                                <Checkbox
                                                    {...field}
                                                    label={jurisdictionCountry}
                                                />
                                            )}
                                        </Field>
                                    </div>
                                )
                            )}
                        </div>
                    </div>
                )
            )}
        </>
    );
};

const CountryInvestorTypeList: FC<{
    investorJurisdictions: InvestorJurisdiction[];
}> = ({ investorJurisdictions }) => {
    return (
        <>
            {investorJurisdictions?.map((investorJurisdiction, i) => (
                <div key={i} className="py-2">
                    <h5 className="text-md">
                        {investorJurisdiction.jurisdictionCountry}
                    </h5>

                    <div className="text-sm pl-4">
                        {investorJurisdiction.investorTypes.map(
                            (investorType, i) => (
                                <div key={i} className="mt-3">
                                    <Field
                                        name={`eligibleInvestorJurisdictions.${investorJurisdiction.countryCode}-${investorType.value}`}
                                        type="checkbox"
                                    >
                                        {({
                                            field,
                                        }: {
                                            field: FieldInputProps<string>;
                                        }) => (
                                            <Checkbox
                                                {...field}
                                                label={investorType.label}
                                            />
                                        )}
                                    </Field>
                                </div>
                            )
                        )}
                    </div>
                </div>
            ))}
        </>
    );
};

const Eligibility = (): JSX.Element => {
    const title = 'Eligibility';
    const subTitle =
        'Please select which jurisdictions apply to this product. If none are selected, it is assumed that the product is available in all jurisdictions.';

    const [formValue, setFormValue] = useState<{
        eligibleInvestorJurisdictions: { [key: string]: boolean };
    }>({
        eligibleInvestorJurisdictions: {},
    });

    const [investorTypeFirst, setInvestorTypeFirst] = useState<boolean>(true);
    const [investorTypeFirstLabel, setInvestorTypeFirstLabel] =
        useState<string>('Arranged by Investor Type');

    const [investorJurisdictions, setInvestorJurisdictions] =
        useState<InvestorJurisdiction[]>();

    const { productId } = useParams();
    const { products } = useProductsState();
    const product = products.filter((e) => e._id === productId)[0];

    const formModelToApiModel = (values: typeof formValue): EligiblityType => {
        // map form keys into
        const eligibleInvestorJurisdictions = Object.entries(
            values.eligibleInvestorJurisdictions
        )
            .filter(([, value]) => {
                // only show selections which are true
                return !!value;
            })
            .map(([key]) => {
                const [countryCode, investorTypeValue] = key.split('-');
                return { countryCode, investorTypeValue };
            })
            .reduce((prev, curr) => {
                const currentJurisdiction: InvestorJurisdiction = JSON.parse(
                    JSON.stringify(
                        investorJurisdictions?.find(
                            (a) => a.countryCode == curr.countryCode
                        ) ?? null
                    )
                );
                const previousJurisdiction = prev.find(
                    (a) => a.countryCode == curr.countryCode
                );
                if (previousJurisdiction && currentJurisdiction) {
                    const newInvestorTypes =
                        currentJurisdiction.investorTypes.filter(
                            (a) => a.value == curr.investorTypeValue
                        );
                    previousJurisdiction.investorTypes = [
                        ...previousJurisdiction.investorTypes,
                        ...newInvestorTypes,
                    ];
                } else if (currentJurisdiction) {
                    currentJurisdiction.investorTypes =
                        currentJurisdiction.investorTypes.filter(
                            (a) => a.value == curr.investorTypeValue
                        );
                    return [...prev, currentJurisdiction];
                }
                return prev;
            }, [] as InvestorJurisdiction[]);

        return {
            ...values,
            eligibleInvestorJurisdictions,
        };
    };

    const apiModelToFormModel = useCallback(
        (eligibility: EligiblityType): typeof formValue => {
            // map nested object into keys for eligibleInvestorJurisdictions
            const eligibleInvestorJurisdictions =
                eligibility.eligibleInvestorJurisdictions
                    ?.flatMap((jurisdiction) =>
                        jurisdiction.investorTypes?.map((investorType) => ({
                            key: `${jurisdiction.countryCode}-${investorType.value}`,
                            value: true,
                        }))
                    )
                    .reduce(
                        (prev, curr) => ({ ...prev, [curr.key]: curr.value }),
                        {}
                    );

            return {
                ...eligibility,
                eligibleInvestorJurisdictions:
                    eligibleInvestorJurisdictions || {},
            };
        },
        []
    );

    useEffect(() => {
        if (productId && product && product.eligibility) {
            const formValue = apiModelToFormModel(product.eligibility);
            setFormValue(formValue);
        }
    }, [product, productId, apiModelToFormModel]);

    useMountEffect(() => {
        InvestorJurisdictionsGetAll().then((result) => {
            setInvestorJurisdictions(result || []);
        });
    });

    const onSubmit = useProductSubmit(
        productId,
        `product-eligibility-exclusion`
    );

    const investorTypesMap = useMemo(() => {
        return transformJurisdictionsToInvestorTypesMap(
            investorJurisdictions || []
        );
    }, [investorJurisdictions]);

    return (
        <div className="w-full">
            <h1 className="text-xl text-gray-500">{title}</h1>
            {(productId === 'new' || productId) && (
                <Formik
                    initialValues={formValue}
                    onSubmit={(values) => {
                        const eligibility = formModelToApiModel(values);

                        const updatedProduct = {
                            ...product,
                            eligibility: {
                                ...product.eligibility,

                                ...eligibility,
                            },
                        };

                        onSubmit(updatedProduct);
                    }}
                    validateOnMount
                    enableReinitialize
                >
                    {({ values, isValid, setFieldValue, ...actions }) => (
                        <Form className="mb-3">
                            <h3 className="text-sm font-normal leading-2 mb-3">
                                {subTitle}
                            </h3>
                            <div className="text-logo-blue">
                                <FormCheckbox
                                    name="hideEligibilityFromProductPage"
                                    description="Hide eligibility information on product page. This will not affect the client eligibility criteria in the subscription process"
                                    actionLabel="Hide eligibility information on product page"
                                />
                                <div className="flex justify-left pt-4 pb-6">
                                    <ToggleSwitch
                                        title={investorTypeFirstLabel}
                                        checked={investorTypeFirst}
                                        onChange={(event) => {
                                            setInvestorTypeFirst(
                                                !investorTypeFirst
                                            );
                                            setInvestorTypeFirstLabel(
                                                investorTypeFirst
                                                    ? 'Arranged by Country'
                                                    : 'Arranged by Investor Type'
                                            );
                                        }}
                                    />
                                </div>
                                {investorTypeFirst ? (
                                    <InvestorTypeCountryList
                                        investorTypesMap={investorTypesMap}
                                    />
                                ) : (
                                    <CountryInvestorTypeList
                                        investorJurisdictions={
                                            investorJurisdictions || []
                                        }
                                    />
                                )}
                            </div>
                            <EditProductFormikButtons
                                productId={productId}
                                isReadOnly={false}
                                pathPrevious="contact-information"
                                pathNext="shareclass-information"
                            />
                        </Form>
                    )}
                </Formik>
            )}
        </div>
    );
};

export default Eligibility;
