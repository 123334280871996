import React from 'react';
import { SvgIcon } from './SvgIcon.type';

export const IconUpload: SvgIcon = ({ width = 49, height = 41 }) => {
    return (
        <svg
            width={width}
            height={height}
            viewBox={`0 0 ${width} ${height}`}
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <g filter="url(#filter0_d_187_1156)">
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M4 5.51252C4 5.31207 4.00444 5.11114 4.0412 4.91409C4.18971 4.11799 4.85243 2 7.7002 2C11.207 2 10.644 2 13.9712 2C17.2983 2 19.4194 5.21178 23.2876 5.21178C25.9895 5.21178 35.5101 5.21178 41.0056 5.21178C43.2147 5.21178 45 7.00264 45 9.21178V30.2315C45 32.4406 43.2091 34.2315 41 34.2315H8C5.79086 34.2315 4 32.4406 4 30.2315V7.5V5.51252Z"
                    fill="url(#paint0_linear_187_1156)"
                />
            </g>
            <rect
                x="4"
                y="8.63184"
                width="41"
                height="25.6"
                rx="4"
                fill="url(#paint1_linear_187_1156)"
            />
            <defs>
                <filter
                    id="filter0_d_187_1156"
                    x="0"
                    y="0"
                    width="49"
                    height="40.2314"
                    filterUnits="userSpaceOnUse"
                    colorInterpolationFilters="sRGB"
                >
                    <feFlood floodOpacity="0" result="BackgroundImageFix" />
                    <feColorMatrix
                        in="SourceAlpha"
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                        result="hardAlpha"
                    />
                    <feOffset dy="2" />
                    <feGaussianBlur stdDeviation="2" />
                    <feColorMatrix
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0"
                    />
                    <feBlend
                        mode="normal"
                        in2="BackgroundImageFix"
                        result="effect1_dropShadow_187_1156"
                    />
                    <feBlend
                        mode="normal"
                        in="SourceGraphic"
                        in2="effect1_dropShadow_187_1156"
                        result="shape"
                    />
                </filter>
                <linearGradient
                    id="paint0_linear_187_1156"
                    x1="27.4259"
                    y1="9.36793"
                    x2="27.4259"
                    y2="2.84144"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#847BB6" />
                    <stop offset="0.0167034" stopColor="#857CB7" />
                    <stop offset="1" stopColor="#A097DB" />
                </linearGradient>
                <linearGradient
                    id="paint1_linear_187_1156"
                    x1="48.1445"
                    y1="34.2318"
                    x2="48.1445"
                    y2="8.63184"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#E0DDF4" />
                    <stop offset="1" stopColor="#F1EFFE" />
                </linearGradient>
            </defs>
        </svg>
    );
};
