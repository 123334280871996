import { useState, useEffect, useRef } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import classNames from 'classnames';
import { Formik, Form, FormikValues } from 'formik';
import { useTranslation } from 'react-i18next';

import { BasicBreadcrumbs, Button } from '@components/Atoms';
import { mainLayoutWithBreadcrumbsConfig } from '@components/Layout';
import {
    AuthRoleEnum,
    EndInvestorProfileRequestBody,
    EndInvestorProfileResponse,
} from '@interfaces/Api';

import { isAdvisoryEnabled } from '@helpers/EndInvestorHelper';
import { notifySuccess } from '@helpers/toastrHelper';

import { useAuthState } from '@contexts/AuthContext';
import { useEndInvestorProfileState } from '@contexts/EndInvestorProfileContext';
import { useConfigurationState } from '@contexts/ConfigurationContext';

import { useCreateTemplate } from '@hooks/useCreateTemplate';

import { Summary } from './Components';
import { StepProps } from './Components/Interfaces';
import { getStepsConfig } from './useClientRegistrationSteps';
import { useRegisterInvestorState } from './RegisterInvestor.context';
import getClientRegistrationFormFields from './ClientRegistrationFormFields';
import { ClientRegistrationErrors } from './ClientRegistrationErrors';
import {
    handleServiceLevelandSubscriptionSelection,
    disabledNextStepButton,
} from './ClientRegistration.helper';
import { useSystemUsersActions } from '@stores/SystemUsers';

const ClientRegistration = () => {
    const { t } = useTranslation();
    const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
    const { hasRoles, currentUser, getCurrentUserData } = useAuthState();
    const { data, setData } = useRegisterInvestorState();
    
    const { fetchSystemUsers } = useSystemUsersActions();
    const { preferences } = useConfigurationState();
    const { create: createSubscriptionTemplate } = useCreateTemplate();
    const [errors, setErrors] = useState<string[]>();
    const { sendEndInvestorData, updateEndInvestorData } =
        useEndInvestorProfileState();
    const { systemUserId, endInvestorProfileId } = useParams();
    const navigate = useNavigate();

    const getSteps = (formValues?: EndInvestorProfileRequestBody) => {
        const currentValues = {
            ...data,
            ...formValues,
        };

        const dynamicSteps: any = {
            [getClientRegistrationFormFields(t).intermediaryId.name]: hasRoles([
                AuthRoleEnum.superUser,
                AuthRoleEnum.assetManagerAdministrator,
            ]),
            [getClientRegistrationFormFields(t).adviserSystemUserId.name]:
                (currentValues?.endInvestorType &&
                    isAdvisoryEnabled(currentValues?.endInvestorType)) ||
                false,
            nomineeEndInvestorProfileId:
                currentValues?.subscriptionProcessType ===
                EndInvestorProfileResponse.SubscriptionProcessTypeEnum.Nominee,
            clientType:
                !!currentValues?.onboardingJurisdictionCountryCode || false,
            businessEntityDetails: currentValues?.endInvestorType
                ? currentValues?.endInvestorType !==
                  EndInvestorProfileResponse.EndInvestorType.individual
                : false,
            nationalities:
                currentValues?.endInvestorType ===
                EndInvestorProfileResponse.EndInvestorType.individual,
        };

        const { steps } = getStepsConfig();

        return steps.map((step: StepProps) => ({
            ...step,
            isEditable:
                dynamicSteps[step.name] !== undefined
                    ? dynamicSteps[step.name]
                    : true,
        }));
    };
    const isEdit = !!endInvestorProfileId;
    const isStepEditable = (step: StepProps) => step.isEditable;
    const editableSteps = getSteps().filter(isStepEditable);

    const defaultStepName = editableSteps[0].name;
    const [stepName, setStepName] = useState<string>(defaultStepName);

    const [steps, setSteps] = useState<StepProps[]>(editableSteps);

    const currentStep = steps?.find((el: StepProps) => el.name === stepName);
    const submitRefButton = useRef<HTMLDivElement>(null);

    const handleBackStep = () => {
        const prevStepName =
            steps[
                steps.findIndex(
                    (el: StepProps) => el.name === currentStep?.name
                ) - 1
            ].name;

        setStepName(prevStepName);
    };

    const isIndividualDetails =
        stepName === 'individualDetails' ||
        stepName === 'nationalities' ||
        stepName === 'emailAddress' ||
        stepName === 'addresses' ||
        stepName === 'bankAccounts';

    const isBusinessEntityDetails =
        stepName === 'businessEntityDetails' ||
        stepName === 'companyName' ||
        stepName === 'registrationNumber' ||
        stepName === 'subscribersLegalForm' ||
        stepName === 'subscribersRegister' ||
        stepName === 'subscribersRegulator';

    const handleNextStep = (values: any) => {
        if (isBusinessEntityDetails) {
            setData({
                ...data,
                businessEntityDetails: {
                    ...data.businessEntityDetails,
                    ...values,
                },
            });
        }

        if (isIndividualDetails) {
            setData({
                ...data,
                individualDetails: {
                    ...data.individualDetails,
                    ...values,
                },
                systemUsers: [
                    {
                        email: values.emailAddress,
                        firstName: values.firstName,
                        surname: values.lastName,
                    },
                ],
            });
        }

        if (!isIndividualDetails && !isBusinessEntityDetails) {
            setData({
                ...values,
                isKycCompliant: values.isKycCompliant
                    ? values.isKycCompliant === 'false'
                        ? false
                        : true
                    : undefined,
                ...handleServiceLevelandSubscriptionSelection({
                    currentStep,
                    values,
                }),
            });

            disabledNextStepButton(values);
        }

        const editableSteps = getSteps(values).filter(isStepEditable);
        setSteps(editableSteps);

        const nextStepName =
            editableSteps[
                editableSteps.findIndex((el) => el.name === currentStep?.name) +
                    1
            ]?.name;

        if (nextStepName) setStepName(nextStepName);
        else if (submitRefButton.current)
            submitRefButton?.current.scrollIntoView({ behavior: 'smooth' });
    };

    useEffect(() => {
        if (endInvestorProfileId) {
            const editableSteps = getSteps().filter(isStepEditable);
            setSteps(editableSteps);
        }
    }, [endInvestorProfileId]);

    const isCurrentStepNotFirst =
        steps.find((el: StepProps) => el.name === currentStep?.name)?.name !==
        steps[0]?.name;

    const isCurrentStepNotLast =
        steps.find((el: StepProps) => el.name === currentStep?.name)?.name !==
        steps.slice(-1)[0].name;

    const handleRegistrationSubmission = async () => {
        setErrors(undefined);

        const updatedData = {
            ...data,
            individualDetails: {
                ...data.individualDetails,
                bankAccounts: data.individualDetails?.bankAccounts?.filter(
                    (a) =>
                        a.bankName ||
                        a.countryCurrencyCode ||
                        a.countryCodeOfAccount ||
                        a.IBAN
                ),
                addresses: data.individualDetails?.addresses?.filter(
                    (a) =>
                        a.houseName ||
                        a.residentialLine1 ||
                        a.residentialLine2 ||
                        a.city ||
                        a.postCode ||
                        a.countryCode
                ),
                nationalities: data.individualDetails?.nationalities?.filter(
                    (a) => a.countryCode || a.nationalIdNumber
                ),
            },
        };

        try {
            setIsSubmitting(true);
            const endInvestorProfile = isEdit
                ? await updateEndInvestorData(updatedData, endInvestorProfileId)
                : await sendEndInvestorData(updatedData, systemUserId);

            await fetchSystemUsers(false);

            notifySuccess(
                `${isEdit ? t('client.registration.update_success_notification') : 
                t('client.registration.registration_success_notification')}`
            );

            setTimeout(async () => {
                if (
                    preferences?.feature.investorRegistrationProcess
                        ?.navigateToCreateTemplateAfterClientRegistration &&
                    endInvestorProfile &&
                    !isEdit
                ) {
                    createSubscriptionTemplate(endInvestorProfile);
                } else {
                    if (currentUser?.user.role === AuthRoleEnum.endInvestor) {
                        await getCurrentUserData();
                        navigate(`/portfolio`);
                    }
                }
            }, 300);
        } catch (err: any) {
            setErrors(err.result.errors);
        }

        setIsSubmitting(false);
    };

    const setInitialValues = () => {
        if (isIndividualDetails) return data.individualDetails;
        if (isBusinessEntityDetails) return data.businessEntityDetails;
        return data;
    };

    const initialValues = setInitialValues();

    return (
        <section>
            <div>
                <h1 className="text-logo-blue text-3xl mb-12 mt-8 font-bold">
                    {t('client.registration.title')}
                </h1>
            </div>

            <div className="flex justify-between">
                <div className="flex-1 max-w-xl">
                    <Formik
                        onSubmit={handleNextStep}
                        initialValues={initialValues as FormikValues}
                        validationSchema={currentStep?.validationSchema || null}
                        validateOnMount
                        enableReinitialize
                    >
                        {(formProps) => (
                            <Form>
                                {currentStep?.component && (
                                    <currentStep.component />
                                )}
                                <div
                                    className={classNames('flex my-4', {
                                        'justify-end':
                                            isCurrentStepNotLast &&
                                            !isCurrentStepNotFirst,
                                        'justify-between':
                                            isCurrentStepNotFirst,
                                    })}
                                >
                                    {isCurrentStepNotFirst && (
                                        <Button
                                            onClick={handleBackStep}
                                            label={t('client.registration.previous_step')}
                                        />
                                    )}

                                    <div ref={submitRefButton}>
                                        <Button
                                            type="submit"
                                            label={t('client.registration.save_details')}
                                            disabled={disabledNextStepButton({
                                                values: formProps.values,
                                                currentStep,
                                                isEdit,
                                            })}
                                        />
                                    </div>
                                </div>
                            </Form>
                        )}
                    </Formik>
                </div>

                <div className="w-4/5 max-w-xl flex-1 border p-6 shadow-md mb-4 self-start">
                    <Summary
                        steps={steps.filter(isStepEditable)}
                        setCurrentStep={setStepName}
                        currentStepName={stepName}
                    />

                    {errors && errors.length > 0 && (
                        <ClientRegistrationErrors errors={errors} />
                    )}

                    <div className="flex justify-end mt-4">
                        <Button
                            label={t('client.registration.summary.submit_details')}
                            onClick={handleRegistrationSubmission}
                            disabled={isSubmitting}
                        />
                    </div>
                </div>
            </div>
        </section>
    );
};

const ClientRegistrationBreadcrumbs = () => {
    const { t } = useTranslation();

    const getItems = (t: (key) => string) => [
        {
            displayText: t('navbar.clients'),
            navigationUrl: '',
        },
    ]

    return <BasicBreadcrumbs items={getItems(t)} />;
};

export default mainLayoutWithBreadcrumbsConfig(
    ClientRegistration,
    undefined,
    <ClientRegistrationBreadcrumbs />
);
