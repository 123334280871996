import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import {
    FundTransaction,
    PerformanceSummary,
    ProductResponse,
    ShareClass,
    SubscriptionResponse,
    SigningDocumentTypeEnum,
    SigningDocumentStatusEnum,
    SubscriptionPerformancePoint,
} from '@interfaces/Api';

import SubscriptionInvestmentReportsHeader from './SubscriptionInvestmentReportsHeader';
import SubscriptionInvestmentReportSummary from './SubscriptionInvestmentReportSummary';
import SubscriptionInvestmentReportsBreakdown from './SubscriptionInvestmentReportsBreakdown';
import SubscriptionInvestmentReportsPerformance from './SubscriptionInvestmentReportsPerformance';
import SubscriptionInvestmentReportsLifeCycle from './SubscriptionInvestmentReportsLifeCycle';
import SubscriptionInvestmentReportsDocs from './SubscriptionInvestmentReportsDocs';

export interface SubscriptionInvestmentReportsProps {
    title: string;
    productData?: ProductResponse;

    performanceSummary: PerformanceSummary;
    chartDataPerformance: SubscriptionPerformancePoint[];
    shareClass?: ShareClass | undefined;
    subscription: SubscriptionResponse;
    fundTransactions: FundTransaction[];
}

const SubscriptionInvestmentReportsPage: React.FC<
    SubscriptionInvestmentReportsProps
> = (props: SubscriptionInvestmentReportsProps) => {
    const { t } = useTranslation();
    const convertArrayOfObjectsToCSV = (
        array: SubscriptionPerformancePoint[]
    ) => {
        let result: string;
        
        const columnDelimiter = ',';
        const lineDelimiter = '\n';
        const keys = Object.keys(props.chartDataPerformance[0]);

        result = '';
        result += keys.join(columnDelimiter);
        result += lineDelimiter;

        array &&
            array.forEach((item: any) => {
                let ctr = 0;
                keys.forEach((key) => {
                    if (ctr > 0) result += columnDelimiter;
                    result += item[key];
                    // eslint-disable-next-line no-plusplus
                    ctr++;
                });
                result += lineDelimiter;
            });

        return result;
    };

    const downloadCSV = (array: SubscriptionPerformancePoint[]) => {
        const link = document.createElement('a');
        let csv = convertArrayOfObjectsToCSV(array);
        if (csv == null) return;

        const filename = 'export.csv';

        if (!csv.match(/^data:text\/csv/i)) {
            csv = `data:text/csv;charset=utf-8,${csv}`;
        }

        link.setAttribute('href', encodeURI(csv));
        link.setAttribute('download', filename);
        link.click();
    };

    const reportDocuments = useMemo(() => {
        if (!props.productData?.documents) return [];

        const documents =
            props.productData?.documents.map((document) => ({
                document,
                createdAt: props.productData?.created_date,
            })) || [];

        if (
            props.subscription.signingDocuments &&
            props.subscription.signingDocuments.length > 0
        ) {
            const signedSubscriptionAgreement =
                props.subscription.signingDocuments?.find(
                    (a) =>
                        a.signingDocumentType ==
                            SigningDocumentTypeEnum.Subscription &&
                        a.status == SigningDocumentStatusEnum.signed
                );

            if (signedSubscriptionAgreement) {
                documents.push({
                    document: {
                        ...signedSubscriptionAgreement.signedDocument!,
                        label: 'Subscription Agreement',
                    },
                    createdAt: signedSubscriptionAgreement.statusLogs.find(
                        (log) => log.status == SigningDocumentStatusEnum.signed
                    )?.createdAt,
                });
            }
        }

        return documents;
    }, [props.productData, props.subscription.signingDocuments]);

    return (
        <>
            <div>
                <SubscriptionInvestmentReportsHeader
                    title={props.title}
                    shareClassInfo={`${props.subscription.shareClass?.name} - ${props.subscription.shareClass?.type}`}
                    dataExport={() => downloadCSV(props.chartDataPerformance)}
                />
                <SubscriptionInvestmentReportSummary
                    fundTransactions={props.fundTransactions}
                />
                <SubscriptionInvestmentReportsBreakdown
                    title={t('client.investment_reports.commitment_breakdown')}
                    chartData={props.performanceSummary}
                    subscriptionAmount={
                        props.performanceSummary.allocatedAmount
                    }
                    subscriptionCurrency={
                        props.subscription.subscriptionCurrencyCode
                    }
                />
                <SubscriptionInvestmentReportsPerformance
                    title={t('client.investment_reports.performance_breakdown')}
                    chartData={props.chartDataPerformance}
                />
                <SubscriptionInvestmentReportsLifeCycle
                    title={t('client.investment_reports.lifecycle_managment')}
                    lifecycleDocuments={props.subscription.notices}
                    shareClassFromReports={props.subscription.shareClass}
                />
                <SubscriptionInvestmentReportsDocs
                    title={t('client.portfolio.product.pill_selector.documents')}
                    reportDocuments={reportDocuments}
                />
            </div>
        </>
    );
};

export default SubscriptionInvestmentReportsPage;
