import {
    EndInvestorProfileResponse,
    IntermediaryResponse,
    SystemUserResponse,
} from '@interfaces/Api';
import { EndInvestorProfileGroupResponse } from '@api/EndInvestorProfileGroups';

export const getEndInvestorName = (
    endInvestorProfile?: EndInvestorProfileResponse
) =>
    endInvestorProfile?.businessEntityDetails?.companyName ||
    [
        endInvestorProfile?.individualDetails?.firstName,
        endInvestorProfile?.individualDetails?.lastName,
    ]
        .filter((a) => a)
        .join(' ');

export const isEndInvestorNomineeAccount = (
    endInvestor: EndInvestorProfileResponse
) =>
    endInvestor?.endInvestorType ===
    EndInvestorProfileResponse.EndInvestorType.nomineeEntity;

export const isAdvisoryEnabled = (investorType: string) =>
    investorType !== EndInvestorProfileResponse.EndInvestorType.nomineeEntity;

export const isDirectSubscriptionProcessType = (
    endInvestor: EndInvestorProfileResponse
) =>
    endInvestor.subscriptionProcessType ===
    EndInvestorProfileResponse.SubscriptionProcessTypeEnum.Direct;

interface getIntermediaryNameParams {
    endInvestorProfileId: string;
    systemUsers?: SystemUserResponse[];
    intermediaries?: IntermediaryResponse[];
}

export const getIntermediaryName = ({
    systemUsers,
    endInvestorProfileId,
    intermediaries,
}: getIntermediaryNameParams) => {
    const intermediaryId = systemUsers?.find(
        (systemUser: SystemUserResponse) =>
            systemUser.endInvestorProfileIds?.includes(endInvestorProfileId)
    )?.intermediaryId;

    const systemUserIntermediaryId =
        typeof intermediaryId === 'string'
            ? intermediaryId
            : intermediaryId?._id;

    return intermediaries?.find(
        (intermediary: any) => intermediary._id === systemUserIntermediaryId
    )?.name;
};

export const filterProfiles = (
    endInvestorProfile: EndInvestorProfileResponse,
    systemUsers?: SystemUserResponse[],
    intermediaries?: IntermediaryResponse[],
    selectedGroupIds?: string[],
    endInvestorProfileGroups?: EndInvestorProfileGroupResponse[],
    nameSearch?: string
) => {
    if (nameSearch) {
        const doesValueMatch = [
            endInvestorProfile.individualDetails?.firstName,
            endInvestorProfile.individualDetails?.lastName,
            endInvestorProfile.businessEntityDetails?.companyName,
            getIntermediaryName({
                endInvestorProfileId: endInvestorProfile._id,
                systemUsers,
                intermediaries,
            }),
        ]
            .filter((a) => a)
            .join(' ')
            .toLowerCase()
            .includes(nameSearch.toLowerCase());

        if (doesValueMatch == false) return false;
    }

    if (selectedGroupIds?.length) {
        for (let i = 0; i < selectedGroupIds.length; i++) {
            const groupId = selectedGroupIds[i];
            const group = endInvestorProfileGroups?.find(
                (a) => a._id == groupId
            );
            const isInGroup = group?.endInvestorProfileIds.includes(
                endInvestorProfile._id
            );
            if (!isInGroup) return false;
        }
    }

    return true;
};
