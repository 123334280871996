import { AuthRoleEnum, CurrentUser, SystemUserResponse } from '@interfaces/Api';

export const roleMap: { [key in AuthRoleEnum]: string } = {
    superUser: 'Super User',
    administrator: 'System Administrator',
    systemViewer: 'System Viewer',
    fundAdmin: 'Fund Administrator',
    relationshipManager: 'Relationship Manager',
    endInvestor: 'End Investor',
    externalRelationshipManager: 'External Relationship Manager',
    assetManagerAdministrator: 'Asset Manager Administrator',
    productViewer: 'Product Viewer',
    profileManager: 'Profile Manager',
    profileAdministrator: 'Profile Administrator',
    prospectiveInvestor: 'Prospective Investor',
};

export const getRoleName = (role: AuthRoleEnum): string => roleMap[role] ?? '';

export const getSystemUserFullName = (systemUser: SystemUserResponse): string =>
    [systemUser.firstName, systemUser.surname].filter((a) => a).join(' ');

export const endInvestorContainsMoreThenTwoProfiles = (
    systemUser?: CurrentUser
) =>
    Boolean(
        systemUser?.role === AuthRoleEnum.endInvestor &&
            systemUser?.endInvestorProfileIds &&
            systemUser?.endInvestorProfileIds.length >= 2
    );
