import { Formik, Form } from 'formik';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import * as Yup from 'yup';

import { ProductResponse } from '@interfaces/Api';

import { useProductSubmit } from '@hooks/useProductSubmit';

import { DataReview } from '@components/Molecules';
import { FileUploadAsync } from '@components/Organisms';
import EditProductFormikButtons from '@pages/Admin/ProductsManagement/Components/EditProductFormikButtons';
import { useStorageTokenState } from '@contexts/StorageTokenContext';
import { useProductsState } from '@stores/Products';

const Images: React.FC = () => {
    const { productId } = useParams();
    const { products } = useProductsState();
    const { withToken } = useStorageTokenState();
    const product = products.filter((e) => e._id === productId)[0];

    const initialVals: Partial<ProductResponse> = {
        thumbnail: product?.thumbnail || '',
        images: [],
    };

    const [formValue, setFormValue] =
        useState<Partial<ProductResponse>>(initialVals);

    useEffect(() => {
        if (productId && product) {
            setFormValue(product);
        }
    }, [product, productId]);

    const selectedValidationSchema = Yup.object().shape({
        thumbnail: Yup.string(),
        images: Yup.array().min(
            1,
            'At least one document needs to be uploaded'
        ),
    });

    const onSubmit = useProductSubmit(productId, `product-videos`);

    return (
        <div className="w-full">
            <h1 className="text-xl text-gray-500">Upload Images</h1>

            <Formik
                initialValues={formValue}
                onSubmit={(values) =>
                    onSubmit({
                        ...values,
                        thumbnail: values.images?.[0]?.url || '',
                    })
                }
                enableReinitialize={true}
                validationSchema={selectedValidationSchema}
            >
                {({ values }) => (
                    <Form>
                        <div className="mb-3">
                            <h3 className="text-sm font-normal leading-8 mb-3">
                                Please upload an Image...
                            </h3>

                            {values.images && values.images.length > 0 && (
                                <DataReview>
                                    <p className="text-sm font-bold mb-2">
                                        Your Thumbnail
                                    </p>
                                    <div className="mr-2">
                                        <img
                                            src={withToken(
                                                values.images[0].url
                                            )}
                                            alt={
                                                values.images[0].label ||
                                                values.images[0].fileName
                                            }
                                            width={150}
                                            height={100}
                                        />
                                    </div>
                                </DataReview>
                            )}

                            <div className="my-4">
                                <FileUploadAsync
                                    name="images"
                                    hasLabel
                                    imageOnly
                                />
                            </div>

                            <EditProductFormikButtons
                                productId={productId}
                                isReadOnly={false}
                                pathPrevious="shareclass-information"
                                pathNext="product-videos"
                            />
                        </div>
                    </Form>
                )}
            </Formik>
        </div>
    );
};

export default Images;
