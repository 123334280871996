import classNames from 'classnames';

export enum TileBackgroundColor {
    'LIGHTGREY' = 'bg-container-grey',
    'BRIGHTBLUE' = 'bg-data-bright-blue',
    'LIGHTBLUE' = 'bg-data-light-blue',
    'LIGHTERBLUE' = 'bg-data-lighter-blue',
    'LIGHTESTBLUE' = 'bg-data-lightest-blue',
}

interface TileProps {
    listDirection?: 'row' | 'column';
    iconPos?: 'left' | 'right';
    icon?: React.ReactNode;
    list: string[];
    isFixedHeight?: boolean;
    isFixedWidth?: boolean;
    hAlign?: 'left' | 'right';
    spaceBetweenItems?: boolean;
    backgroundColor?: TileBackgroundColor;
    isTextLarge?: boolean;
}

export const Tile = ({
    listDirection = 'column',
    iconPos,
    icon,
    list,
    isFixedHeight = false,
    isFixedWidth = false,
    hAlign,
    spaceBetweenItems = false,
    backgroundColor = TileBackgroundColor.LIGHTGREY,
    isTextLarge = false,
}: TileProps) => {
    const wrapperClassName = classNames(
        `${backgroundColor} p-2 w-full h-full`,
        {
            'flex-row': icon && iconPos,
            flex: icon && iconPos,
            'justify-between': iconPos === 'right',
        }
    );

    const innerWrapperClassName = classNames('flex h-full', {
        'h-24': isFixedHeight,
        'w-24': isFixedWidth,
        'flex-col': listDirection === 'column',
        'flex-row': listDirection === 'row',
        'justify-end': listDirection === 'row' && hAlign === 'right',
        'justify-start': listDirection === 'row' && hAlign === 'left',
        'items-end': listDirection === 'column' && hAlign === 'right',
        'items-start': listDirection === 'column' && hAlign === 'left',
        'justify-between': spaceBetweenItems,
    });

    const setTextStyle = (i: number) =>
        classNames('font-semibold pl-1', {
            'text-3xl': isTextLarge && i === 1,
            'text-xl ': !isTextLarge,
        });

    return (
        <div className={wrapperClassName}>
            {icon && iconPos === 'left' && <div>{icon}</div>}
            <div className={innerWrapperClassName}>
                {list.map((el, i) => (
                    <p className={setTextStyle(i)} key={el}>
                        {el}
                    </p>
                ))}
            </div>
            {icon && iconPos === 'right' && <div className="pl-1">{icon}</div>}
        </div>
    );
};
