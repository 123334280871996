import { Form, Formik } from 'formik';
import { useEffect, useState } from 'react';
import * as Yup from 'yup';

import { Button } from '@components/Atoms';
import { Modal, FormInputBox } from '@components/Molecules';

import { UploadDocument } from './FileUploadAsync';

interface EditLabelModalProps {
    showModal: boolean;
    document?: UploadDocument;
    onClose: () => void;
    onSave: (document: UploadDocument) => void;
}

const EditLabelModal = (props: EditLabelModalProps) => {
    const [showModal, setShowModal] = useState(props.showModal);
    const filename = props.document?.fileName || '';

    useEffect(() => {
        setShowModal(props.showModal);
    }, [props.showModal]);

    const handleSave = (label: string) => {
        props.onSave({ ...props.document!, label });
    };

    const handleClose = () => {
        props.onClose();
        setShowModal(false);
    };

    const handleSubmit = async (values: { label: string }) => {
        if (values.label) {
            handleSave(values.label);
        }
    };

    const validationSchema = Yup.object().shape({
        label: Yup.string(),
    });

    return (
        <Modal show={showModal}>
            <Formik
                initialValues={{
                    label:
                        props.document?.label ||
                        filename.slice(0, filename.lastIndexOf('.')),
                }}
                enableReinitialize
                onSubmit={handleSubmit}
                validationSchema={validationSchema}
            >
                {(form) => (
                    <Form>
                        <Modal.Header onClose={handleClose}>
                            Edit file label
                        </Modal.Header>
                        <Modal.Body>
                            <p>What should the label of the file be?</p>
                            <p className="pb-4">
                                <code>"{props.document?.fileName}"</code>
                            </p>
                            <div>
                                <FormInputBox
                                    name="label"
                                    placeholder="Label"
                                    value={form.values.label}
                                />
                            </div>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button
                                type="button"
                                onClick={(e) => {
                                    e.preventDefault();
                                    handleSubmit(form.values);
                                }}
                            >
                                Save
                            </Button>
                        </Modal.Footer>
                    </Form>
                )}
            </Formik>
        </Modal>
    );
};

export default EditLabelModal;
