import classNames from 'classnames';
import { useMemo } from 'react';
import { Link } from 'react-router-dom';

import { Icons } from '@components/Atoms';
import FloatingHamburgerNav from './FloatingHamburgerNav';

export interface Step {
    id?: number; // only needed if no stepUrl is provided
    label: string;
    stepUrl?: string; // only needed if no id is provided
    isValid?: boolean;
    showStep: boolean;
    canClick?: boolean;
}

interface WorkflowStepperProps {
    steps: Step[];
    // allows a user to pass either an id or endPathUrl to show that the current step is active
    activeStep: { id: number } | { endPathUrl: string };
    // onStepClick only needed if activeStep.id is provided
    onStepClick?: (id: number) => void;
}

const getEndPathUrl = (step: Step) => {
    if (step.stepUrl) {
        return step.stepUrl.substring(step.stepUrl.lastIndexOf('/') + 1);
    }
    return '';
};

const WorkflowStepper: React.FC<WorkflowStepperProps> = (props) => {
    const { steps, onStepClick, activeStep } = props;

    const stepsWithEndPathUrl = useMemo(() => {
        return steps.map((step) => ({
            ...step,
            endPathUrl: getEndPathUrl(step),
        }));
    }, [steps]);

    return (
        <>
            <ul className="hidden md:block">
                {stepsWithEndPathUrl
                    .filter((a) => a.showStep)
                    .map((step, i) => {
                        if (!step.label) return null;
                        const canClickStep = step.canClick ?? true;

                        return (
                            <li
                                key={i}
                                className={classNames(
                                    'flex items-center border-l-4 pl-5 leading-6 lg:leading-8',
                                    {
                                        'border-logo-blue':
                                            ('id' in activeStep &&
                                                activeStep.id === step.id) ||
                                            ('endPathUrl' in activeStep &&
                                                activeStep.endPathUrl ===
                                                    step.endPathUrl),
                                        'opacity-50 cursor-not-allowed':
                                            !canClickStep,
                                    }
                                )}
                            >
                                {!canClickStep && <div>{step.label}</div>}
                                {canClickStep &&
                                    (step.stepUrl ? (
                                        <Link to={`${step.stepUrl}`}>
                                            {step.label}
                                        </Link>
                                    ) : (
                                        <button
                                            onClick={() => {
                                                if (
                                                    typeof step.id ===
                                                        'number' &&
                                                    onStepClick
                                                ) {
                                                    onStepClick(step.id);
                                                } else {
                                                    // TODO: should fail silently?
                                                    throw Error(
                                                        'No id recorded for this step'
                                                    );
                                                }
                                            }}
                                            value={i}
                                        >
                                            {step.label}
                                        </button>
                                    ))}

                                {step.isValid && (
                                    <div className="logo-blue-dark ml-2">
                                        <Icons
                                            name="CheckIcon"
                                            size="small"
                                            strokeWidth=".1"
                                        />
                                    </div>
                                )}
                            </li>
                        );
                    })}
            </ul>
            <div className="md:hidden">
                <FloatingHamburgerNav
                    title="Review Steps"
                    steps={stepsWithEndPathUrl}
                />
            </div>
        </>
    );
};

export default WorkflowStepper;
