import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import {
    EndInvestorProfileResponse,
    NoticeType,
    SubscriptionStatusEnum,
    noticeTypeLabels,
} from '@interfaces/Api';
import { EndInvestorSubscriptionsProfile } from '@interfaces/Api/EndInvestorProfileResponse';
import { EventTypeLabelEnum } from '@interfaces/eventType.enum';
import { endInvestorProfileGroupsGetAll } from '@api/EndInvestorProfileGroups';

import { useSystemUsers } from '@stores/SystemUsers/useSystemUsers';
import { useIntermediaries } from '@stores/Intermediaries/useIntermediaries';

import { useClientsFilter } from '@pages/Clients/Clients/useClientsFilter';
import { Option } from '@components/Molecules';

import { EndInvestorsList } from '../../Components/EndInvestorsList';
import { NomineeList } from '../../Components/NomineeList';
import { TopBar } from '../../TopBar';
import { useSubscriptions } from '@stores/Subscriptions/useSubscriptions';

const CapitalCall: React.FC = () => {
    const params = useParams();
    const { eventType } = params;
    const isCapitalCall =
        eventType === EventTypeLabelEnum.CAPITAL_CALL_NOTICE_EVENT;
    const capitalCallType = isCapitalCall
        ? NoticeType.CapitalCall
        : NoticeType.CapitalCallPaid;
    const { systemUsers } = useSystemUsers();
    const { intermediaries } = useIntermediaries();
    const { subscriptions } = useSubscriptions();
    const [endInvestorProfileGroupOptions, setEndInvestorProfileGroupOptions] =
        useState<Option[]>([]);
    const [nameSearch, setNameSearch] = useState<string>();
    const [
        endInvestorSubscriptionsProfile,
        setEndInvestorSubscriptionsProfile,
    ] = useState<EndInvestorSubscriptionsProfile[]>([]);
    const {
        setSelectedGroupIds,
        visibleEndInvestorProfiles,
        setEndInvestorProfileGroups,
    } = useClientsFilter(nameSearch, systemUsers, intermediaries);

    useEffect(() => {
        let FilteredEndinvestorProfiles = visibleEndInvestorProfiles
            .map((endInvestorProfile: EndInvestorProfileResponse) => ({
                ...endInvestorProfile,
                subscriptions: subscriptions?.filter(
                    (subscription) =>
                        subscription.endInvestorProfileId ===
                            endInvestorProfile._id &&
                        subscription?.status === SubscriptionStatusEnum.approved
                ),
            }))
            .filter(
                (endInvestorProfile) =>
                    endInvestorProfile.subscriptions.length > 0
            );
        if (!isCapitalCall) {
            FilteredEndinvestorProfiles = FilteredEndinvestorProfiles.filter(
                (endInvestorProfile) =>
                    endInvestorProfile.subscriptions.filter(
                        (subscription) =>
                            subscription.notices &&
                            subscription.notices.length > 0
                    ).length > 0
            );
        }

        setEndInvestorSubscriptionsProfile(FilteredEndinvestorProfiles);
    }, [subscriptions, visibleEndInvestorProfiles]);

    useEffect(() => {
        endInvestorProfileGroupsGetAll().then((groups) => {
            setEndInvestorProfileGroups(groups);
            const groupOptions =
                groups.map((a) => ({
                    label: a.groupName,
                    value: a._id,
                })) || [];

            setEndInvestorProfileGroupOptions(groupOptions);
        });
    }, []);

    return (
        <>
            <div className="mb-4">
                <h1 className="text-2xl text-logo-blue">
                    {noticeTypeLabels[capitalCallType]} Event
                </h1>
            </div>
            <TopBar
                nameSearch={nameSearch}
                setNameSearch={setNameSearch}
                endInvestorProfileGroupOptions={endInvestorProfileGroupOptions}
                setSelectedGroupIds={setSelectedGroupIds}
            />

            <EndInvestorsList
                visibleEndInvestorProfiles={endInvestorSubscriptionsProfile}
            />
            <NomineeList
                visibleEndInvestorProfiles={endInvestorSubscriptionsProfile}
            />
        </>
    );
};

export default CapitalCall;
