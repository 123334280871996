import { BaseUrl } from '@helpers/Environment';

import { Http } from '../libs/http';
import { EventStreamRequest } from '@interfaces/Api/EventStreamRequest';

const API_EVENT_STREAM_URL = `${BaseUrl}/event-stream`;

export const eventStreamPost = async (
    eventData: EventStreamRequest
): Promise<boolean> => Http.post(`${API_EVENT_STREAM_URL}`, eventData);
