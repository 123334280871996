import { useEffect, useState } from 'react';

import { EndInvestorProfileGroupResponse } from '@api/EndInvestorProfileGroups';
import {
    EndInvestorProfileResponse,
    IntermediaryResponse,
    SystemUserResponse,
} from '@interfaces/Api';

import { filterProfiles } from '@helpers/EndInvestorHelper';

import { useEndInvestorProfileState } from '@contexts/EndInvestorProfileContext';
import { useAuthState } from '@contexts/AuthContext';

export const useClientsFilter = (
    nameSearch: string | undefined,
    systemUsers: SystemUserResponse[],
    intermediaries: IntermediaryResponse[]
) => {
    const { endInvestorProfiles } = useEndInvestorProfileState();
    const { currentUser } = useAuthState();

    const [selectedGroupIds, setSelectedGroupIds] = useState<string[]>([]);
    const [endInvestorProfileGroups, setEndInvestorProfileGroups] = useState<
        EndInvestorProfileGroupResponse[]
    >([]);
    const [visibleEndInvestorProfiles, setVisibleEndInvestorProfiles] =
        useState<EndInvestorProfileResponse[]>([]);

    useEffect(() => {
        const allEndInvestorProfiles = endInvestorProfiles?.filter((val) =>
            filterProfiles(
                val,
                systemUsers,
                intermediaries,
                selectedGroupIds,
                endInvestorProfileGroups,
                nameSearch
            )
        );

        setVisibleEndInvestorProfiles(allEndInvestorProfiles || []);
    }, [
        currentUser,
        endInvestorProfiles,
        nameSearch,
        endInvestorProfileGroups,
        selectedGroupIds,
        systemUsers,
        intermediaries,
    ]);

    return {
        setSelectedGroupIds,
        visibleEndInvestorProfiles,
        setEndInvestorProfileGroups,
    };
};
