import { useEffect, useState } from 'react';

export const useHandleBrowserBackButton = (): boolean => {
    const [isBack, setIsBack] = useState(false);
    const modalHash = '#modal';

    useEffect(() => {
        const handleOnHashChange = () => {
            const isHashMatch = window.location.hash === modalHash;
            setIsBack(isHashMatch);

            if (!isBack) {
                if (isHashMatch) {
                    setIsBack(true);
                } else {
                    window.history.pushState(
                        null,
                        '',
                        window.location.pathname
                    );
                    setIsBack(false);
                }
            }
        };

        window.addEventListener('popstate', handleOnHashChange);
        return () => window.removeEventListener('popstate', handleOnHashChange);
    }, [isBack, modalHash]);

    return isBack;
};
