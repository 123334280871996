import { Checkbox } from '@components/Atoms';
import classNames from 'classnames';
import groupBy from 'lodash.groupby';
import { ChangeEvent, useCallback, useEffect, useState } from 'react';

export interface CheckboxListOption {
    value: boolean;
    label: string;
    checkboxLabel?: string;
    key: string;
    image?: string;
    group?: string;
}

export type CheckboxVariant = 'Box' | 'Normal';

interface CheckboxListProps {
    name: string;
    options: CheckboxListOption[];
    onSelectAllChanged?: (
        options: CheckboxListOption[],
        selectAll: boolean
    ) => void;
    disabled?: boolean;
    checkboxLabel?: string;
    renderExtra?: (option: CheckboxListOption) => React.ReactNode;
    onSelectedOptionsChange: (selectedOptions: CheckboxListOption[]) => void;
    variant?: CheckboxVariant;
    enableCollapse?: boolean;
    defaultChecked?: boolean;
}

export interface CheckboxOptionsWithGroupLabelProps extends CheckboxListProps {
    groupLabel?: string;
    allOptions: CheckboxListOption[];
    defaultChecked?: boolean;
}

const CheckboxOptionsWithGroupLabel: React.FC<
    CheckboxOptionsWithGroupLabelProps
> = (props) => {
    const { onSelectAllChanged, name, groupLabel, options } = props;

    const [show, setShow] = useState<boolean>(false);

    const handleToggle = useCallback(() => {
        setShow((pre) => !pre);
    }, []);

    const handleSelectAllChange = useCallback(
        (e: ChangeEvent<HTMLInputElement>) => {
            if (onSelectAllChanged) {
                onSelectAllChanged(options, e.target.checked);
            }
        },
        [options, onSelectAllChanged]
    );

    return (
        <div>
            <div className="py-3 flex justify-between">
                {groupLabel}
                {groupLabel && (
                    <div>
                        <Checkbox
                            name={`${groupLabel}.showAll${name}`}
                            label={'Select All'}
                            checked={options.every((option) => option.value)}
                            onChange={handleSelectAllChange}
                        />
                    </div>
                )}
                {props.enableCollapse && (
                    <span onClick={handleToggle}>{show ? 'Hide' : 'Show'}</span>
                )}
            </div>
            {props.enableCollapse ? (
                show && <CheckboxOptions {...props} />
            ) : (
                <CheckboxOptions {...props} />
            )}
        </div>
    );
};

const CheckboxOptions: React.FC<CheckboxOptionsWithGroupLabelProps> = ({
    options,
    onSelectedOptionsChange,
    disabled,
    checkboxLabel,
    renderExtra,
    allOptions,
    variant = 'Box',
    defaultChecked,
}) => {
    const handleCheckboxChange = (option: CheckboxListOption) => {
        const optionIndex = allOptions.findIndex(
            (val) => val.key === option.key
        );
        allOptions[optionIndex] = { ...option, value: !option.value };
        onSelectedOptionsChange(allOptions);
    };

    useEffect(() => {
        if (defaultChecked) {
            allOptions.forEach((option) => {
                option.value = true;
            });
            onSelectedOptionsChange(allOptions);
        }
    }, [defaultChecked]);

    const stopBubbling = useCallback((event) => {
        // stops the label click event from bubbling up to the parent div
        event.stopPropagation();
    }, []);

    return (
        <>
            {options?.map((option) => (
                <div
                    key={option.key}
                    className={classNames([
                        'relative w-full rounded-lg cursor-pointer',
                        option.value ? 'bg-purple-50 ' : 'hover:bg-gray-100 ',
                        variant === 'Box' ? 'border p-5 mb-5' : 'px-5 mb-2',
                    ])}
                    onClick={() => {
                        if (!disabled) {
                            handleCheckboxChange(option);
                        }
                    }}
                >
                    {option.label && (
                        <p className="text-sm flex items-center pointer-events-none">
                            {option.label}
                        </p>
                    )}

                    <div
                        className={classNames([
                            'w-full',
                            option.label && 'mt-3',
                        ])}
                        onClick={(event) => {
                            event.preventDefault();
                        }}
                    >
                        <Checkbox
                            // below class is correct. This fixes a bug where when the checkbox is clicked,
                            // the checkbox doesn't change state. (although the state is changed in the parent component)
                            className="pointer-events-none"
                            name={option.key}
                            label={option.checkboxLabel || checkboxLabel}
                            checked={option.value}
                            disabled={disabled}
                        />
                    </div>
                    {option.image && (
                        <div className="bg-logo-blue rounded-xl mt-4">
                            <img
                                src={option.image}
                                className=" object-cover h-64 w-full rounded-xl"
                            />
                        </div>
                    )}

                    {renderExtra && (
                        <div onClick={stopBubbling}>{renderExtra(option)}</div>
                    )}
                </div>
            ))}
        </>
    );
};

const CheckboxList: React.FC<CheckboxListProps> = (props) => {
    const { options, checkboxLabel = 'Agreed', defaultChecked } = props;

    const checkBoxes = (options: CheckboxListOption[]) => {
        if (options?.[0]?.group !== undefined) {
            const groupOptions = groupBy(options, 'group');

            return (
                <ul>
                    {Object.entries(groupOptions).map(
                        (entry: [string, CheckboxListOption[]]) => {
                            const [key, optionsInGroup] = entry;
                            return (
                                <li key={key}>
                                    <CheckboxOptionsWithGroupLabel
                                        {...props}
                                        options={optionsInGroup}
                                        checkboxLabel={checkboxLabel}
                                        groupLabel={key}
                                        allOptions={options}
                                    />
                                </li>
                            );
                        }
                    )}
                </ul>
            );
        }

        return (
            <CheckboxOptions
                {...props}
                checkboxLabel={checkboxLabel}
                allOptions={options}
                defaultChecked={defaultChecked}
            />
        );
    };

    return options &&
        Array.isArray(options) &&
        options.length > 0 &&
        options[0].image ? (
        <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-10 w-full">
            {checkBoxes(options)}
        </div>
    ) : (
        <div className="block">{checkBoxes(options)}</div>
    );
};

export default CheckboxList;
