import { EndInvestorProfileGet } from '@api/EndInvestorProfiles';
import { getRedemptionApplication } from '@api/RedemptionApplications';
import { BasicBreadcrumbs, Loading } from '@components/Atoms';
import { mainLayoutWithBreadcrumbsConfig } from '@components/Layout';
import DocumentSigningSummary from '@containers/Subscriptions/DocumentSigningSummary';
import { dateShortestFormatter } from '@helpers/Date.helper';
import { getEndInvestorName } from '@helpers/EndInvestorHelper';
import { getFormattedCurrency } from '@helpers/isoCurrencies';
import { useMountEffect } from '@hooks/useMountEffect';
import { useProduct } from '@stores/Products/useProduct';
import { RedemptionApplicationResponse } from '@interfaces/Api/RedemptionApplicationResponse';
import { t } from 'i18next';
import { useState } from 'react';
import { useParams } from 'react-router-dom';
import { useSubscription } from '@stores/Subscriptions/useSubscription';
import { useSubscriptionsActions } from '@stores/Subscriptions';

const RedemptionApplicationReviewPage: React.FC = () => {
    const { redemptionApplicationId } = useParams();

    const { subscription } = useSubscription();
    const { getSubscription } = useSubscriptionsActions();
    const { product } = useProduct(subscription.productId);

    const [redemptionApplication, setRedemptionApplication] =
        useState<RedemptionApplicationResponse>();

    useMountEffect(() => {
        redemptionApplicationId &&
            getRedemptionApplication(redemptionApplicationId).then((a) => {
                setRedemptionApplication(a);
                getSubscription(a.parentSubscriptionId).then();
            });
    });

    if (!redemptionApplication || !product) {
        return <Loading />;
    }

    return (
        <div>
            <h1 className="text-3xl text-logo-blue pb-8">
                Redemption for {product.name} - {subscription.shareClass?.name}
            </h1>

            <p className="py-1">
                <strong>Total redemption amount:</strong>&nbsp;
                {getFormattedCurrency(
                    redemptionApplication.redemptionAmount,
                    redemptionApplication.redemptionCurrencyCode
                )}
            </p>
            {redemptionApplication.redemptionFeeAmount && (
                <p className="py-1 pb-2">
                    <strong>Total redemption fee:</strong>&nbsp;
                    {getFormattedCurrency(
                        redemptionApplication.redemptionFeeAmount,
                        redemptionApplication.redemptionCurrencyCode
                    )}
                </p>
            )}

            {redemptionApplication.redeemablePositions.map(
                (redeemablePosition, i) => (
                    <div
                        className="border-t py-2"
                        key={
                            redeemablePosition.lotNumber +
                            redeemablePosition.subscriptionId
                        }
                    >
                        <p>Lot {redeemablePosition.lotNumber}</p>
                        <p>
                            <strong>Lot Purchased Date: </strong>&nbsp;
                            {dateShortestFormatter(
                                redeemablePosition.lotPurchaseDate
                            )}
                        </p>
                        <p>
                            <strong>Redemption amount:</strong>&nbsp;
                            {getFormattedCurrency(
                                redeemablePosition.redemptionAmount,
                                redemptionApplication.redemptionCurrencyCode
                            )}
                        </p>
                        {!!redeemablePosition.redemptionFeeAmount && (
                            <p>
                                <strong>Redemption fee:</strong>&nbsp;
                                {getFormattedCurrency(
                                    redeemablePosition.redemptionFeeAmount,
                                    redemptionApplication.redemptionCurrencyCode
                                )}
                            </p>
                        )}
                    </div>
                )
            )}

            <h3 className="text-2xl text-logo-blue mt-5 pb-2">Document</h3>
            <div>
                <DocumentSigningSummary
                    showHeader={false}
                    signingDocuments={
                        redemptionApplication.redemptionSigningDocuments
                    }
                />
            </div>
        </div>
    );
};

const RedemptionApplicationReviewPageWithBreadcrumbs: React.FC = () => {
    const { redemptionApplicationId } = useParams();

    const [endInvestorProfileName, setEndInvestorProfileName] =
        useState<string>('');
    const [endInvestorProfileId, setEndInvestorProfileId] =
        useState<string>('');
    const [subscriptionId, setSubscriptionId] = useState<string>('');

    useMountEffect(() => {
        async function RunAsync() {
            if (!redemptionApplicationId) return;
            const redemption = await getRedemptionApplication(
                redemptionApplicationId
            );
            setSubscriptionId(redemption.parentSubscriptionId);

            setEndInvestorProfileId(redemption.endInvestorProfileId);

            const endInvestorProfile = await EndInvestorProfileGet(
                redemption.endInvestorProfileId
            );
            setEndInvestorProfileName(getEndInvestorName(endInvestorProfile));
        }
        RunAsync();
    });

    return (
        <BasicBreadcrumbs
            items={[
                {
                    displayText: t('navbar.clients'),
                    navigationUrl: '/clients',
                },
                {
                    displayText: endInvestorProfileName,
                    navigationUrl: `/clients/${endInvestorProfileId}/subscription/${subscriptionId}`,
                },
                {
                    displayText: 'Redemptions',
                    navigationUrl: '',
                },
            ]}
        />
    );
};

export default mainLayoutWithBreadcrumbsConfig(
    RedemptionApplicationReviewPage,
    undefined,
    <RedemptionApplicationReviewPageWithBreadcrumbs />
);
