import { Button } from '@components/Atoms';
import { Modal } from '@components/Molecules';

interface BackButtonBoxProps {
    showModal: boolean;
    title: string;
    icon: any;
    message: string;
    onNavigationLeavePge: () => void;
    setShowModal: (bool: boolean) => void;
}

export const BackButtonBox = (props: BackButtonBoxProps) => {
    const { icon, message, onNavigationLeavePge, setShowModal, showModal } =
        props;

    return (
        <Modal show={showModal} width="w-1/2 lg:w-3/12">
            <Modal.Header>{props.title}</Modal.Header>
            <Modal.Body>
                <div className="p-10 flex flex-col items-center text-center group md:lg:xl:border-r md:lg:xl:border-b hover:bg-slate-50 cursor-pointer">
                    {icon && (
                        <span className="p-5 rounded-full bg-yellow-400">
                            {icon}
                        </span>
                    )}
                    <p className="text-xl font-medium text-slate-700 mt-3">
                        {message}
                    </p>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <Button
                    buttonType="secondary"
                    onClick={onNavigationLeavePge}
                    label="Leave this page"
                ></Button>
                <Button
                    onClick={() => setShowModal(false)}
                    label="Stay on this page"
                ></Button>
            </Modal.Footer>
        </Modal>
    );
};
