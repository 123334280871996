import { useTranslation } from 'react-i18next';
import { Badge } from '@components/Atoms';
import { ActionsMenu } from '@components/Molecules';
import { SubHeaderList } from '@components/Organisms/Dashboard/Components/SubHeaderList';
import { LiquidityTypeEnum, getLiquidityTypeLabels } from '@interfaces/Api';
import { Link } from 'react-router-dom';

const ProductHeaderWithDates: React.FC<{
    subHeaderList: { label: string; value: string }[];
    productName: string;
    productSlug: string;
    liquidityType: LiquidityTypeEnum;
    actionItems?: { label: string; onClick: () => void }[];
}> = (props) => {
    const { t } = useTranslation();
    return (
        <>
            <div className="flex flex-col lg:flex-row justify-between">
                <div>
                    <div className="flex flex-col lg:flex-row">
                        <h2 className="text-2xl"><Link to={`/products/${props.productSlug}`}>{props.productName}</Link></h2>
                        <div className="flex items-center lg:ml-4">
                            <Badge
                                badgeType="preview"
                                sizeType="small"
                                label={getLiquidityTypeLabels(t, props.liquidityType)}
                            />
                        </div>
                    </div>
                    <SubHeaderList list={props.subHeaderList} />
                </div>
                {props.actionItems && props.actionItems.length > 0 && (
                    <ActionsMenu actionItems={props.actionItems} />
                )}
            </div>
        </>
    );
};

export default ProductHeaderWithDates;
