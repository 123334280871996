import { Icons } from '@components/Atoms';
import { Logo } from '@components/Molecules';

interface MobileNavMenuProps {
    setOpenNav: (open: boolean) => void;
    menuItems: React.ReactNode;
}

export const MobileNavMenu: React.FC<MobileNavMenuProps> = ({
    menuItems,
    setOpenNav,
}) => (
    <div className="absolute z-40 top-0 left-0 w-full h-full bg-white opacity-95 p-3">
        <div
            onClick={() => setOpenNav(false)}
            className="absolute right-5 top-4 cursor-pointer"
        >
            <Icons name="XMarkIcon" size="large" strokeWidth="1.5" />
        </div>
        <div className="p-0">
            <Logo className="w-full lg:w-20  mt-0 px-1 md:px-0 py-1" />
            <h3 className="p-3 text-sm mt-7 text-logo-blue">MENU</h3>
            <nav>
                <ul className="md:flex items-center justify-between text-2xl text-logo-blue p-3 pt-3 md:pt-0">
                    {menuItems}
                </ul>
            </nav>
        </div>
    </div>
);
