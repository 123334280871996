import classNames from 'classnames';
import { Tooltip } from '@components/Atoms';
import { PopUp } from '@components/Molecules';
import { daysLetters } from './Calendar.utils';

import { areDatesEqual } from '@helpers/Date.helper';

;

export const CalendarRow = (props: any) => {
    const { day, month, events, findEvent, getDayBgStyle } = props;

    if (day === 'month') {
        return (
            <td className="p-2">
                <h2 className="mr-3 font-bold w-2">{month}</h2>
            </td>
        );
    }

    const content = (
        <p className="flex flex-col items-center text-xs">
            <span>{daysLetters[day.getDay()]}</span>
            {day.getDate()}
        </p>
    );

    const event = findEvent(day);
    const label = event?.label;

    // find mathcing events
    const matchingEvents = events.filter((event: any) =>
        areDatesEqual(event.date, day)
    );

    const renderRow = () => {
        if (matchingEvents.length > 1) {
            return (
                <td className="border border-gray-300 w-8 relative">
                    <PopUp
                        trigger={
                            <div className="font-bold cursor-pointer pt-2">
                                <div className="relative z-10">{content}</div>

                                <div className="absolute top-0 w-full h-full">
                                    {matchingEvents.map(
                                        (event: any, i: number) => (
                                            <div
                                                key={i}
                                                className={`w-full h-2/4 ${event.color}`}
                                            />
                                        )
                                    )}
                                </div>
                            </div>
                        }
                        position="right center"
                        on="hover"
                    >
                        {matchingEvents.map((event: any, i: number) => (
                            <div
                                key={`${event.label}-${i}   `}
                                className={classNames(
                                    'p-2 text-xs border-b border-bg-white',
                                    event.color
                                )}
                            >
                                {event.label}
                            </div>
                        ))}
                    </PopUp>
                </td>
            );
        }

        if (matchingEvents.length === 1) {
            return (
                <td className={getDayBgStyle(day)}>
                    <Tooltip tip={label || ''}>
                        <div>{content}</div>
                    </Tooltip>
                </td>
            );
        }

        return <td className="border border-gray-300 w-8 p-2">{content}</td>;
    };

    return renderRow();
};
