import { FormInputBox, FormSelect } from '@components/Molecules';
import { DatePicker } from '@components/Organisms';
import getClientRegistrationFormFields from '../ClientRegistrationFormFields';

import { isoCountriesOptions } from '@helpers/isoCountries';
import { useTranslation } from 'react-i18next';

export const PersonalDetailsSelection = () => {
    const { t } = useTranslation();
    return (
        <>
            <h2 className="mb-4 text-2xl">{t('client.registration.personal_details.title')}</h2>
            <FormInputBox
                name="firstName"
                label={getClientRegistrationFormFields(t).firstName.label}
                placeholder={getClientRegistrationFormFields(t).firstName.label}
            />
            <FormInputBox
                name="lastName"
                label={getClientRegistrationFormFields(t).lastName.label}
                placeholder={getClientRegistrationFormFields(t).lastName.label}
            />
            <DatePicker
                name={getClientRegistrationFormFields(t).dateOfBirth.name}
                label={getClientRegistrationFormFields(t).dateOfBirth.label}
            />

            <FormInputBox
                name={getClientRegistrationFormFields(t).townOfBirth.name}
                label={getClientRegistrationFormFields(t).townOfBirth.label}
                placeholder={getClientRegistrationFormFields(t).townOfBirth.label}
            />
            <FormSelect
                name={getClientRegistrationFormFields(t).countryCodeOfBirth.name}
                label={getClientRegistrationFormFields(t).countryCodeOfBirth.label}
                optionsData={isoCountriesOptions}
            />
        </>
    );
}
