import { FC, useState } from 'react';
import { Button, Checkbox } from '@components/Atoms';
import { useTranslation } from 'react-i18next';

interface VPRiskWarningProps {
    onAbort(): void;
    onAccept(): void;
}

export const VPRiskWarning: FC<VPRiskWarningProps> = ({
    onAbort,
    onAccept,
}) => {
    const { t } = useTranslation();
    const [acceptRisk, setAcceptRisk] = useState<boolean>(false);

    return (
        <div>
            <div>
                <span className="font-bold">{t('client.risk_warning.warning')}&nbsp;</span>
                {t('client.risk_warning.warning_text')}
            </div>
            <div className="mt-8">
                <Checkbox
                    label={t('client.risk_warning.risk_acceptance')}
                    name="acceptRisk"
                    checked={acceptRisk}
                    onChange={(e) => {
                        setAcceptRisk(e.target.checked);
                    }}
                />
            </div>

            <div className="mt-8 flex justify-between">
                <Button
                    buttonType="secondary"
                    onClick={onAbort}
                    label={t('client.risk_warning.abort')}
                />

                <Button
                    label={t('client.risk_warning.execute')}
                    disabled={!acceptRisk}
                    onClick={onAccept}
                />
            </div>
        </div>
    );
};
