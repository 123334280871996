import React from 'react';
import { Link, useParams } from 'react-router-dom';

import { Button } from '@components/Atoms';
import { SubscriptionListItem } from '@containers/Subscriptions/SubscriptionList';
import { getFormattedCurrency } from '@helpers/isoCurrencies';
import { getProductDetails } from '@helpers/Product.helper';
import {
    NoticeType,
    SubscriptionNotice,
} from '@interfaces/Api/SubscriptionNotice';

interface CapitalCallNoticeListProps {
    subscriptionListItems: SubscriptionListItem[];
    onDeleteSubscription?: (subscriptionId: string) => {};
    buttonAction: (
        notice: SubscriptionNotice,
        subscription: SubscriptionListItem
    ) => void;
}

const CapitalCallNoticeList: React.FC<CapitalCallNoticeListProps> = (
    subscription
) => {
    const { subscriptionListItems, buttonAction } = subscription;
    const subscriptions =
        subscriptionListItems?.length > 0 ? subscriptionListItems : [];

    if (subscriptions.length === 0)
        return <p>The are currently no capital calls to display</p>;

    return (
        <>
            {subscriptions.map((subscription) => (
                <>
                    {subscription.notices?.map((notice, i) => {
                        return (
                            notice.noticeType !==
                                NoticeType.CapitalCallPaid && (
                                <div
                                    key={i}
                                    className="bg-white border-b py-1 my-4 md:my-0 flex flex-col md:flex-row justify-between items-center"
                                >
                                    <div className="md:w-3/12 my-2">
                                        Capital Call Notice:
                                    </div>
                                    <div className={`text-left md:w-6/12 my-2`}>
                                        <Link
                                            to={`/subscriptions/${subscription.subscriptionUrl}`}
                                        >
                                            <h3 className="text-base pr-3 md:pr-2 cursor-pointer">
                                                {getProductDetails({
                                                    productName:
                                                        subscription.productName,
                                                    shareClassName:
                                                        subscription.shareClassName,
                                                })}
                                                &nbsp;
                                                {getFormattedCurrency(
                                                    notice.amount,
                                                    subscription.subscriptionCurrencyCode
                                                )}
                                            </h3>
                                        </Link>
                                    </div>
                                    <Button
                                        onClick={() =>
                                            buttonAction(notice, subscription)
                                        }
                                        label="Raise Capital Call Paid Event"
                                    />
                                </div>
                            )
                        );
                    })}
                </>
            ))}
        </>
    );
};

export default CapitalCallNoticeList;
