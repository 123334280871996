import { getProfiles } from '@api/Profile';
import { errorToString } from '@helpers/error.helper';
import { notifyError } from '@helpers/toastrHelper';
import { ProfilePayload } from '@interfaces/Api/Profile';
import { useCallback, useEffect, useState } from 'react';

export const useProfiles = () => {
    const [profiles, setProfiles] = useState<ProfilePayload[]>([]);

    const fetchProfiles = useCallback(() => {
        getProfiles()
            .then((response) => setProfiles(response))
            .catch(() => {});
    }, []);

    useEffect(() => {
        fetchProfiles();
    }, [fetchProfiles]);

    return { fetchProfiles, profiles };
};
