import { WidgetDoughnutChart } from '@components/Charts';
import { DoughnutChartProps } from '@interfaces/InterfaceChartProps';

export interface OverviewCardProps {
    headerText?: string;
    footerText?: string;
    chartProps: DoughnutChartProps;
    label: string;
    value: string;
}

export const OverviewCard: React.FC<OverviewCardProps> = ({
    headerText,
    footerText,
    chartProps,
    label,
    value,
}) => {
    const commonStyle =
        'border-brand-navy w-full justify-center p-2 text-center';

    return (
        <section className="flex flex-col items-center border border-brand-navy px-6 lg:px-12 h-full w-full lg:max-w-xs">
            {headerText && (
                <header className={`${commonStyle} border-b`}>
                    <p className="font-bold text-lg">{headerText}</p>
                </header>
            )}

            <div className="flex flex-col flex-3 justify-center items-center">
                <WidgetDoughnutChart {...chartProps} />
                <p className="font-bold">{label}</p>
                <p>{value}</p>
            </div>

            {footerText && (
                <footer className={`${commonStyle} border-t`}>
                    <p className="font-bold text-lg">{footerText}</p>
                </footer>
            )}
        </section>
    );
};
