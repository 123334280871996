import { useState } from 'react';
import { useCallback } from 'react';
import { RedemptionApplicationResponse } from '@interfaces/Api/RedemptionApplicationResponse';
import { RowItem } from './MobileTable';
import { Button, Icons } from '@components/Atoms';
import { docusignEnvelopeGet } from '@api/Docusign';
import { downloadFileOnTheClient } from '@helpers/File.helper';
import { notifyError } from '@helpers/toastrHelper';
import { Link } from 'react-router-dom';
import { getRedemptionSigningDocumentLogs } from './DesktopTableRow.logic';

const DesktopTableRow = ({
    columnNames,
    rowItem,
    getSystemUserName,
}: {
    columnNames: string[];
    rowItem: RowItem;
    getSystemUserName: (val: string) => void;
}) => {
    const [showMore, setShowMore] = useState(false);
    const [isDownloading, setIsDownloading] = useState(false);

    const handleDocumentClick = useCallback(async (envelopeId: string) => {
        setIsDownloading(true);
        try {
            const response = await docusignEnvelopeGet(envelopeId);
            downloadFileOnTheClient(response);
        } catch (err) {
            notifyError(
                'Could not download the document. Error:' + JSON.stringify(err)
            );
        }
        setIsDownloading(false);
    }, []);

    const getRedemptionLogs = useCallback(
        (redemption: RedemptionApplicationResponse) =>
            getRedemptionSigningDocumentLogs(redemption, getSystemUserName),
        [getSystemUserName]
    );

    return (
        <>
            <tr className="text-xs xl:text-base cursor-pointer">
                <td>
                    <Button
                        onClick={() => {
                            setShowMore((a) => !a);
                        }}
                        hasIconOnly
                        startIcon={
                            <Icons
                                name={showMore ? 'ChevronDown' : 'ChevronRight'}
                                size="small"
                            />
                        }
                    ></Button>
                </td>
                {columnNames.map((columnName: string) => (
                    <td className="p-2 text-xs xl:text-base" key={columnName}>
                        <Link to={`/redemption-applications/${rowItem._id}`}>
                            {rowItem.columns[columnName]}
                        </Link>
                    </td>
                ))}
            </tr>
            {showMore && (
                <tr>
                    <td colSpan={columnNames.length + 1} className="p-2">
                        <Button
                            buttonType="secondary"
                            label="Download Application"
                            endIcon={
                                <Icons name="ArrowDownTrayIcon" size="small" />
                            }
                            size="small"
                            onClick={(e) => {
                                const envelopeId =
                                    rowItem.redemption
                                        .redemptionSigningDocuments?.[0]
                                        ?.signingMethodId;
                                envelopeId && handleDocumentClick(envelopeId);
                            }}
                            isLoading={isDownloading}
                            disabled={isDownloading}
                        />

                        <h5 className="pt-2">Logs:</h5>

                        {getRedemptionLogs(rowItem.redemption).map((a, i) => (
                            <p key={i} className="text-sm">
                                {a}
                            </p>
                        ))}
                    </td>
                </tr>
            )}
        </>
    );
};

export default DesktopTableRow;
