import { BaseUrl } from '../Helpers/Environment';
import { Preferences } from '@interfaces/Api/Preferences';
import { Http } from '../libs/http';

export const API_PREFERENCES_URL = `${BaseUrl}/preference`;

export const preferenceGet = () => {
    return Http.get<Preferences>(API_PREFERENCES_URL);
};

export const preferencePut = (preferences: Preferences) => {
    return Http.put<Preferences>(API_PREFERENCES_URL, preferences);
};

export const portalTypesGet = () => {
    return Http.get<Record<string, Preferences>>(
        `${API_PREFERENCES_URL}/portal-types`
    );
};
