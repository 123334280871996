import { Document } from './Document';
import { Preferences } from './Preferences';

/**
 *
 * @export
 * @interface ConfigurationResponse
 */
export interface ConfigurationResponse {
    /**
     *
     * @type {string}
     * @memberof ConfigurationResponse
     */
    version: string;
    /**
     *
     * @type {string}
     * @memberof ConfigurationResponse
     */
    build: string;
    /**
     *
     * @type {string}
     * @memberof ConfigurationResponse
     */
    docuSignAuthServiceDomain: string;
    /**
     *
     * @type {string}
     * @memberof ConfigurationResponse
     */
    docuSignIntegrationKey: string;
    /**
     *
     * @type {string}
     * @memberof ConfigurationResponse
     */
    gtmId: string;
    /**
     *
     * @type {string}
     * @memberof ConfigurationResponse
     */
    gtmAuthId: string;
    /**
     *
     * @type {string}
     * @memberof ConfigurationResponse
     */
    gtmPreviewId: string;
    /**
     *
     * @type {string}
     * @memberof ConfigurationResponse
     */
    gtmDataLayerName: string;

    branding: {
        images: {
            navbarLogo?: Document;
            loginPageLogo?: Document;
            favicon?: Document;
        };
        product?: {
            anonymousDisclaimerText?: string;
        };
    };

    platformName: string;
    platformOwnerName: string;
    /**
     *
     * @type {string}
     * @memberof ConfigurationResponse
     */
    preferences: Preferences;

    environment: EnvironmentEnum;

    client: ClientEnum;
}

// We have way to many environment types
export enum EnvironmentEnum {
    TEST = 'TEST',
    LOCAL = 'LOCAL',
    DEMO = 'DEMO',
    DEV = 'DEV',
    UAT = 'UAT',
    PROD = 'PROD',
}

export enum ClientEnum {
    S64 = 'S64',
    VP = 'VP',
    DB = 'DB',
    LGT = 'LGT',
    APOLLO = 'APOLLO',
    HG = 'HG',
    ODDO = 'ODDO',
}
