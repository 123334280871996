import { ContentRenderer, Popover, PopoverPosition, PopoverAlign } from "react-tiny-popover";

interface PopOverProps {
  isOpen: boolean;
  positions?: PopoverPosition[];
  align?: PopoverAlign;
  containerClassName: string;
  onClickOutside?: () => void;
  content: ContentRenderer | JSX.Element;
  children: JSX.Element;
}

const PopOver: React.FC<PopOverProps> = ({ 
  isOpen = false,
  positions = ['top', 'right', 'bottom', 'left'],
  align = 'start',
  containerClassName = 'w-1/2',
  onClickOutside = () => {},
  content,
  children,
}) => {
  return (
    <Popover
        isOpen={isOpen}
        positions={positions} // preferred positions by priority
        align={align}
        containerClassName={containerClassName}
        content={
          <div 
            style={{ backgroundColor: 'white' }} 
            className="p-5 shadow border-t border-b border-gray-200 text-logo-blue"
          >
           { content }
          </div>
        }
        onClickOutside={() => onClickOutside()}
    >
      { children }
    </Popover>
  );
};

export default PopOver;