import React, { FC, useState, useEffect, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useField, useFormikContext } from 'formik';
import { Button, InputBox } from '@components/Atoms';
import dayjs from 'dayjs';
import { FormFieldWrapper } from '@components/Molecules';
import { convertToDateIsoString, formatDate } from '@helpers/Date.helper';
import FormikLabels from './ModelProducts';
export interface KeyDatesInterface {
    signedSubscriptionDeadline?: string;
    closingDate?: string;
}

const DateInput: FC<{
    value?: string;
    defaultValue?: string;
    label?: string;
    onChange?(event: React.ChangeEvent<HTMLInputElement>): void;
    onBlur?(event: React.ChangeEvent<HTMLInputElement>): void;
}> = ({ value, defaultValue, label, onChange, onBlur }) => (
    <div className="relative w-full mb-3 cursor-default">
        <FormFieldWrapper label={label} name={''}>
            <InputBox
                type="date"
                min="1900-01-01"
                value={value}
                defaultValue={defaultValue}
                onChange={onChange}
                onBlur={onBlur}
                label={label}
                name=""
            />
        </FormFieldWrapper>
    </div>
);

const FIELD_NAME = FormikLabels.formField.closedEndedInformation.keyDates.name;

export const ProductKeyDatesForm: FC = () => {
    const [field, meta] = useField<KeyDatesInterface[]>({
        name: FIELD_NAME,
    });
    const { t } = useTranslation();
    const { setFieldValue } = useFormikContext();
    const [internalKeyDates, setInternalKeyDates] = useState<
        KeyDatesInterface[]
    >([]);

    useEffect(() => {
        const keyDates = field.value || [];

        if (keyDates && keyDates.length) {
            setInternalKeyDates(keyDates);
        }
    }, [field.value]);

    // when user change date, it just change something internally
    const dateChangeHandler = useCallback(
        (
            fieldName: keyof KeyDatesInterface,
            index: number,
            newValue: string
        ) => {
            if (!dayjs(newValue).isValid()) {
                return;
            }

            const copyDates = [...internalKeyDates];
            copyDates[index][fieldName] = convertToDateIsoString(newValue);
            setInternalKeyDates(copyDates);
            setFieldValue(FIELD_NAME, copyDates);
        },
        [internalKeyDates]
    );

    // add new date
    const handleNewDate = useCallback(() => {
        const newDates = [
            ...internalKeyDates,
            {
                signedSubscriptionDeadline: '',
                closingDate: '',
            },
        ];

        setInternalKeyDates(newDates);
    }, [internalKeyDates]);

    const handleDelete = useCallback(
        (index) => {
            const newDates = [...internalKeyDates];
            newDates.splice(index, 1);
            setInternalKeyDates(newDates);
            setFieldValue(FIELD_NAME, newDates);
        },
        [internalKeyDates, setFieldValue]
    );

    return (
        <div>
            {internalKeyDates.map((internalKeyDate, index) => (
                <div key={internalKeyDate.signedSubscriptionDeadline}>
                    <div className="flex justify-between">
                        <label>Key Date {index + 1}</label>
                        <Button
                            label={`Remove Key Date ${index + 1}`}
                            type="button"
                            onClick={() => handleDelete(index)}
                        />
                    </div>
                    <div className="my-4">
                        <DateInput
                            defaultValue={formatDate(
                                internalKeyDate.signedSubscriptionDeadline
                            )}
                            label={t('product.signed_subscription_deadline')}
                            onBlur={(event) => {
                                dateChangeHandler(
                                    'signedSubscriptionDeadline',
                                    index,
                                    event.target.value
                                );
                            }}
                        />
                    </div>
                    <div className="my-4">
                        <DateInput
                            defaultValue={formatDate(
                                internalKeyDate.closingDate
                            )}
                            label="Closing Date"
                            onBlur={(event) => {
                                dateChangeHandler(
                                    'closingDate',
                                    index,
                                    event.target.value
                                );
                            }}
                        />
                    </div>
                </div>
            ))}
            {meta.error && (
                <div className="flex content-start text-red-500 text-sm mb-4">
                    {meta.error}
                </div>
            )}
            <div>
                <Button
                    label="Add Key Date"
                    type="button"
                    onClick={handleNewDate}
                />
            </div>
        </div>
    );
};
