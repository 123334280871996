import { DataReview } from '@components/Molecules';
import { useRegisterInvestorState } from '../RegisterInvestor.context';

export const LoginAccountReview = () => {
    const { data } = useRegisterInvestorState();

    return (
        <>
            <p className="text-base py-3">Assigned account details:</p>
            {data?.systemUsers &&
                data?.systemUsers.length > 0 &&
                data?.systemUsers.map((systemUser, i) => (
                    <DataReview key={systemUser?._id + i}>
                        <p className="text-sm">
                            <span className="font-bold mr-2">First name:</span>
                            {systemUser?.firstName}
                        </p>
                        <p className="text-sm">
                            <span className="font-bold mr-2">Last name:</span>
                            {systemUser.surname}
                        </p>
                        <p className="text-sm">
                            <span className="font-bold mr-2">
                                Email address:
                            </span>
                            {systemUser.email}
                        </p>
                    </DataReview>
                ))}
        </>
    );
};
