import { Button } from '@components/Atoms';
import { FormCheckbox } from '@components/Molecules';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import { RedemptionForm } from './CreateStep';

export interface AttestationsForm {
    hasReviewedProspectus: boolean;
    requestNotGuaranteed: boolean;
}

interface AttestationsStepProps {
    attestationsForm?: AttestationsForm;
    setAttestationsForm: (attestationsForm: AttestationsForm) => void;
    previousStep: () => void;
    nextStep: () => void;
}

const AttestationsStep: React.FC<AttestationsStepProps> = ({
    attestationsForm,
    setAttestationsForm,
    previousStep,
    nextStep,
}) => {
    const requiredCheckboxes = [
        {
            label: 'Has Reviewed Prospectus',
            description:
                'I/we have reviewed the prospectus and supplements for the time deadlines for the processing of subscriptions',
            actionLabel: 'Agreed',
            name: 'hasReviewedProspectus',
        },
        {
            label: 'Request Not Guaranteed',
            description:
                'I/we acknowledge that subscription requests are not guaranteed to be honoured and may be refused for any reason',
            actionLabel: 'Agreed',
            name: 'requestNotGuaranteed',
        },
    ];

    const validation = Yup.object({
        hasReviewedProspectus: Yup.boolean().oneOf(
            [true],
            'Please agree to the attestation'
        ),
        requestNotGuaranteed: Yup.boolean().oneOf(
            [true],
            'Please agree to the attestation'
        ),
    });
    const initialVals = {
        hasReviewedProspectus: attestationsForm?.hasReviewedProspectus || false,
        requestNotGuaranteed: attestationsForm?.requestNotGuaranteed || false,
    };

    const onSubmit = (attestations: AttestationsForm) => {
        setAttestationsForm(attestations);
        nextStep();
    };

    return (
        <div>
            <h1 className="text-3xl text-logo-blue pb-5">Attestations</h1>

            <Formik
                initialValues={initialVals}
                validationSchema={validation}
                onSubmit={onSubmit}
            >
                {({ isValid, isSubmitting }) => {
                    return (
                        <Form>
                            {requiredCheckboxes.map((checkbox, index) => (
                                <FormCheckbox
                                    key={index}
                                    label={checkbox.label}
                                    description={checkbox.description}
                                    actionLabel={checkbox.actionLabel}
                                    name={checkbox.name}
                                />
                            ))}

                            <div className="flex justify-between w-full mt-10">
                                <Button
                                    buttonType="secondary"
                                    type="button"
                                    label="Back"
                                    onClick={() => {
                                        previousStep();
                                    }}
                                />
                                <Button
                                    buttonType="primary"
                                    type="submit"
                                    label="Save"
                                    disabled={!isValid || isSubmitting}
                                />
                            </div>
                        </Form>
                    );
                }}
            </Formik>
        </div>
    );
};
export default AttestationsStep;
