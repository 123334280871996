import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

import { useConfigurationState } from '@contexts/ConfigurationContext';
import { useAuthState } from '@contexts/AuthContext';
import { useStorageTokenState } from '@contexts/StorageTokenContext';

interface LogoProps {
    className: string;
}

const Logo: React.FC<LogoProps> = ({ className }: LogoProps) => {
    const { configuration, configurationLoading } = useConfigurationState();
    const [logoUrl, setLogoUrl] = useState<string>('/assets/s64_logo.png');
    const { currentUser } = useAuthState();
    const { withToken } = useStorageTokenState();

    useEffect(() => {
        if (configuration?.branding?.images?.navbarLogo?.url) {
            setLogoUrl(
                withToken(configuration?.branding?.images?.navbarLogo?.url)
            );
        }
    }, [configuration]);

    const LinkWrapper: React.FC = (props) => {
        if (!currentUser) {
            return <a href="https://www.s64capital.com">{props.children}</a>;
        }
        return <Link to="/">{props.children}</Link>;
    };

    return (
        <div className={className}>
            <LinkWrapper>
                {configurationLoading ? (
                    <div className="logo" />
                ) : (
                    <img src={logoUrl} className="logo" alt="S64" />
                )}
            </LinkWrapper>
        </div>
    );
};
export default Logo;
