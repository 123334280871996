import { useEffect, useState } from 'react';

import {
    EndInvestorProfileGroupResponse,
    endInvestorProfileGroupsGetAll,
    endInvestorProfileGroupsPost,
    endInvestorProfileGroupsPut,
} from '@api/EndInvestorProfileGroups';

import { Button } from '@components/Atoms';
import { Modal, MultiselectCreatable, Option } from '@components/Molecules';

import { notifyError, notifySuccess } from '@helpers/toastrHelper';
import { useTranslation } from 'react-i18next';

interface EditClientGroupModalProps {
    show: boolean;
    endInvestorProfileId?: string;
    onClose: () => void;
}

const EditClientGroupModal: React.FC<EditClientGroupModalProps> = (props) => {
    const { t } = useTranslation();
    const { show, endInvestorProfileId, onClose } = props;
    const [selectedGroups, setSelectedGroups] = useState<
        (Option & { __isNew__?: boolean })[]
    >([]);
    const [endInvestorProfileGroups, setEndInvestorProfileGroups] = useState<
        EndInvestorProfileGroupResponse[]
    >([]);
    const [endInvestorProfileGroupOptions, setEndInvestorProfileGroupOptions] =
        useState<Option[]>([]);

    useEffect(() => {
        if (show && endInvestorProfileId) {
            endInvestorProfileGroupsGetAll().then((groups) => {
                setEndInvestorProfileGroups(groups);
                const groupOptions =
                    groups.map((a) => ({
                        label: a.groupName,
                        value: a._id,
                    })) || [];

                setEndInvestorProfileGroupOptions(groupOptions);

                if (endInvestorProfileId) {
                    const selectedGroup =
                        groups
                            .filter((a) =>
                                a.endInvestorProfileIds.includes(
                                    endInvestorProfileId!
                                )
                            )
                            .map((a) => ({
                                label: a.groupName,
                                value: a._id,
                            })) || [];

                    setSelectedGroups(selectedGroup);
                }
            });
        }
    }, [show, endInvestorProfileId]);

    const onSubmit = async () => {
        try {
            // add new groups for client
            const newGroups = selectedGroups?.filter((a) => a.__isNew__) || [];
            for (let i = 0; i < newGroups.length; i++) {
                const newGroup = newGroups[i];
                await endInvestorProfileGroupsPost({
                    groupName: newGroup.label,
                    endInvestorProfileIds: [endInvestorProfileId!],
                });
            }

            // need to find groups which already exist for the current end investor
            const currentGroups =
                selectedGroups?.filter((a) => !a.__isNew__) || [];
            // 1. add profiles into groups that already exist
            for (let i = 0; i < currentGroups.length; i++) {
                const currentGroup = currentGroups[i];
                // find if end investor was previously in group
                const oldGroup = endInvestorProfileGroups.find(
                    (a) => a._id == currentGroup.value
                );
                if (
                    !oldGroup?.endInvestorProfileIds.includes(
                        endInvestorProfileId!
                    ) &&
                    oldGroup?._id
                ) {
                    // update group to include current user
                    const updatedGroup = {
                        ...oldGroup,
                        endInvestorProfileIds: [
                            ...(oldGroup?.endInvestorProfileIds || []),
                            endInvestorProfileId!,
                        ],
                    };
                    await endInvestorProfileGroupsPut(
                        updatedGroup._id,
                        updatedGroup
                    );
                }
            }
            // 2. remove profiles from groups that exist
            const oldGroups = endInvestorProfileGroups.filter((a) =>
                a.endInvestorProfileIds.includes(endInvestorProfileId!)
            );
            const removeEndInvestorFromGroups = oldGroups
                .filter((a) => {
                    const removeGroup = !currentGroups.find(
                        (b) => b.value == a._id
                    );
                    if (removeGroup) {
                        return true;
                    }
                    return false;
                })
                .map((a) => ({
                    ...a,
                    endInvestorProfileIds: a.endInvestorProfileIds.filter(
                        (b) => b !== endInvestorProfileId
                    ),
                }));
            for (let i = 0; i < removeEndInvestorFromGroups.length; i++) {
                const group = removeEndInvestorFromGroups[i];
                await endInvestorProfileGroupsPut(group._id, group);
            }
            notifySuccess('Groups have been saved');
            onClose();
        } catch (err) {
            notifyError(
                'An error has occured, your groups have not been saved'
            );
        }
    };

    return (
        <Modal show={show}>
            <Modal.Header onClose={() => onClose()}>
                <p>Add to Group</p>
            </Modal.Header>
            <Modal.Body>
                <div>
                    <MultiselectCreatable
                        label="Selected Groups"
                        placeholder="Select/Create a Group"
                        options={endInvestorProfileGroupOptions}
                        defaultValue={selectedGroups}
                        handleChange={(newValue) =>
                            setSelectedGroups([...newValue])
                        }
                    />

                    <div className="pt-4 flex justify-between">
                        <Button
                            type="button"
                            onClick={() => onClose()}
                            buttonType="secondary"
                            label={t('ui.controls.cancel')}
                        />
                        <Button
                            type="button"
                            onClick={() => onSubmit()}
                            label="Save"
                        />
                    </div>
                </div>
            </Modal.Body>
        </Modal>
    );
};

export default EditClientGroupModal;
