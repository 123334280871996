import { BaseUrl } from '@helpers/Environment';
import { notifyError, notifySuccess } from '@helpers/toastrHelper';
import {
    BeneficialOwnership,
    DistributionOfFunds,
    DistributorDetailsRequestBody,
    DistributorDetailsResponse,
    NomineeInvestor,
    PoliticalExposure,
    SigningDocument,
    SourceOfFundsAndWealth,
    SubscriptionAttestations,
    SubscriptionContact,
    SubscriptionGermanSemiProfessionalCategorisation,
    SubscriptionLifecycle,
    SubscriptionNotice,
    SubscriptionRequestBody,
    SubscriptionResponse,
    SubscriptionStatusRequestBody,
    TaxInformation,
} from '@interfaces/Api';
import { ValidatedNomineeInvestor } from '@interfaces/Api/NomineeInvestor';
import { Http } from '@libs/http';
import { SubscriptionReport } from 'common';

export const API_SUBSCRIPTIONS_URL = `${BaseUrl}/subscriptions`;

const SubscriptionsGetAll = async (endInvestorProfileId) =>
    await Http.get<SubscriptionResponse[]>(API_SUBSCRIPTIONS_URL, undefined, {
        endInvestorProfileId,
    });

const SubscriptionsDelete = async (subscriptionId: string) =>
    await Http.delete(`${API_SUBSCRIPTIONS_URL}/${subscriptionId}`);

const SubscriptionsGet = async (subscriptionId: string) =>
    await Http.get<SubscriptionResponse>(
        `${API_SUBSCRIPTIONS_URL}/${subscriptionId}`
    );

const SubscriptionStatusPut = async (
    subscriptionId: string,
    body: SubscriptionStatusRequestBody
) =>
    Http.put<SubscriptionResponse>(
        `${API_SUBSCRIPTIONS_URL}/${subscriptionId}/status`,
        body
    );

const SubscriptionsPut = async (id: string, body: SubscriptionRequestBody) =>
    Http.put<SubscriptionResponse>(`${API_SUBSCRIPTIONS_URL}/${id}`, body);

const SubscriptionsBeneficialOwnershipPut = async (
    subscriptionId: string,
    beneficialOwnership: BeneficialOwnership
) =>
    await Http.put<BeneficialOwnership>(
        `${API_SUBSCRIPTIONS_URL}/${subscriptionId}/beneficial-ownership`,
        beneficialOwnership
    );

const SubscriptionPoliticalExposurePut = async (
    subscriptionId: string,
    politicalPersonExposure: PoliticalExposure
) =>
    await Http.put<PoliticalExposure>(
        `${API_SUBSCRIPTIONS_URL}/${subscriptionId}/political-exposure`,
        politicalPersonExposure
    );

const SubscriptionContactsPut = async (
    subscriptionId: string,
    subscriptionContacts: SubscriptionContact[]
) =>
    await Http.put<SubscriptionContact[]>(
        `${API_SUBSCRIPTIONS_URL}/${subscriptionId}/contacts`,
        subscriptionContacts
    );

const SubscriptionReportPut = async (
    subscriptionId: string,
    subscriptionReport: SubscriptionReport
) =>
    await Http.put<SubscriptionReport>(
        `${API_SUBSCRIPTIONS_URL}/${subscriptionId}/report`,
        subscriptionReport
    );

const SubscriptionSupportingDocumentsPut = async (
    subscriptionId: string,
    supportingDocuments: Document[]
) =>
    await Http.put<Document[]>(
        `${API_SUBSCRIPTIONS_URL}/${subscriptionId}/supporting-documents`,
        supportingDocuments
    );

const SubscriptionTaxInformationPut = (
    subscriptionId: string,
    taxInformation: TaxInformation
) =>
    Http.put<TaxInformation>(
        `${API_SUBSCRIPTIONS_URL}/${subscriptionId}/tax-information`,
        taxInformation
    );

const SubscriptionSourceOfWealthPut = async (
    subscriptionId: string,
    sourceOfWealth: SourceOfFundsAndWealth
) =>
    await Http.put<SourceOfFundsAndWealth>(
        `${API_SUBSCRIPTIONS_URL}/${subscriptionId}/source-of-funds-and-wealth`,
        sourceOfWealth
    );

const SubscriptionDistributionOfFundsPut = async (
    subscriptionId: string,
    distributionOfFunds: DistributionOfFunds
) =>
    await Http.put<DistributionOfFunds>(
        `${API_SUBSCRIPTIONS_URL}/${subscriptionId}/distribution-of-funds`,
        distributionOfFunds
    );

const SubscriptionsDistributorDetailsPut = async (
    subscriptionId: string,
    distributorDetails: DistributorDetailsRequestBody
) =>
    await Http.put<DistributorDetailsResponse>(
        `${API_SUBSCRIPTIONS_URL}/${subscriptionId}/distributor-details`,
        distributorDetails
    );

const SubscriptionAttestationsPut = async (
    subscriptionId: string,
    attestations: SubscriptionAttestations
) =>
    await Http.put<SubscriptionAttestations>(
        `${API_SUBSCRIPTIONS_URL}/${subscriptionId}/attestations`,
        attestations
    );

const SubscriptionGermanSemiProfessionalPut = async (
    subscriptionId: string,
    body: SubscriptionGermanSemiProfessionalCategorisation
) =>
    await Http.put<SubscriptionGermanSemiProfessionalCategorisation>(
        `${API_SUBSCRIPTIONS_URL}/${subscriptionId}/german-semi-professional-categorisation`,
        body
    );

const SubscriptionManualDraftSubscriptionAgreementGet = async (
    subscriptionId: string
): Promise<SigningDocument> =>
    Http.get(
        `${API_SUBSCRIPTIONS_URL}/${subscriptionId}/render-manual-draft-subscription-agreement`
    );

const SubscriptionManualSignedSubscriptionAgreementPut = async (
    subscriptionId: string,
    body: Document
): Promise<SigningDocument> =>
    Http.put<SigningDocument>(
        `${API_SUBSCRIPTIONS_URL}/${subscriptionId}/manual-signed-subscription-agreement`,
        body
    );

export const SubscriptionsPost = async (
    body: SubscriptionRequestBody,
    queryParams?: { subscriptionTemplateId?: string }
) =>
    Http.post<SubscriptionResponse>(
        API_SUBSCRIPTIONS_URL,
        body,
        undefined,
        queryParams
    );

export const validateNomineeUnderlyingInvestors = async (
    subscriptionId: string,
    nomineeUnderlyingInvestors: NomineeInvestor[]
): Promise<ValidatedNomineeInvestor[]> =>
    Http.post<ValidatedNomineeInvestor[]>(
        `${API_SUBSCRIPTIONS_URL}/${subscriptionId}/validate-nominee-underlying-investors`,
        nomineeUnderlyingInvestors
    );

export const LifecycleGetBySubscriptionId = async (
    subscriptionId: string
): Promise<SubscriptionLifecycle> =>
    Http.get(`${API_SUBSCRIPTIONS_URL}/${subscriptionId}/lifecycle`);

export const SubscriptionNoticePut = (
    notices: SubscriptionNotice[],
    subscriptionId: string
) => Http.put(`${API_SUBSCRIPTIONS_URL}/${subscriptionId}/notices`, notices);

export const handleFetchSubscriptions = async (set, endInvestorProfileId) => {
    try {
        set({ isLoading: true });
        const subscriptions = await SubscriptionsGetAll(endInvestorProfileId);

        set({
            subscriptions,
        });
    } catch (error) {
        notifyError('Subscriptions have not been loaded');
    } finally {
        set({ isLoading: false });
    }
};

export const handleDeleteSubscription = async (set, subscriptionIdToDelete) => {
    try {
        await SubscriptionsDelete(subscriptionIdToDelete);
        set((state) => ({
            subscriptions: state.subscriptions.filter(
                (subscription) => subscription._id !== subscriptionIdToDelete
            ),
        }));
        notifySuccess('Subscription deleted successfully');
    } catch (error) {
        notifyError(
            `Subscription ${subscriptionIdToDelete} was not deleted, message: ${error}`
        );
    }
};

export const handleGetSubscription = async (set, subscriptionId) => {
    try {
        const subscription = await SubscriptionsGet(subscriptionId);
        set({
            subscription,
        });
    } catch (error) {
        notifyError('Subscription has not been loaded');
    }
};

export const handleUpdateSubscriptionStatus = async ({
    set,
    subscriptionId,
    body,
}) => {
    try {
        const putObj = await SubscriptionStatusPut(subscriptionId, body);
        set({
            subscription: {
                ...putObj,
                status: putObj.status,
                statusComment: putObj.statusComment,
                acceptedSubscriptionAmount: putObj.acceptedSubscriptionAmount,
            },
        });
    } catch (error) {
        notifyError(
            `Subscription ${subscriptionId} was not updated, message: ${error}`
        );
    }
};

export const handleUpdateSubscription = async ({
    get,
    set,
    subscriptionId,
    body,
}) => {
    try {
        const putObj = await SubscriptionsPut(subscriptionId, body);
        const currentSubscription = get().subscription;
        set({
            subscription: {
                ...currentSubscription,
                ...putObj,
            },
        });
    } catch (error) {
        notifyError(
            `Subscription ${subscriptionId} was not updated, message: ${error}`
        );
    }
};

export const handleUpdateSubscriptionBeneficialOwnership = async ({
    get,
    set,
    subscriptionId,
    body,
}) => {
    try {
        const putObj = await SubscriptionsBeneficialOwnershipPut(
            subscriptionId,
            body
        );
        const currentSubscription = get().subscription;
        set({
            subscription: {
                ...currentSubscription,
                beneficialOwnership: putObj,
            },
        });
    } catch (error) {
        notifyError(
            `Subscription ${subscriptionId} was not updated, message: ${error}`
        );
    }
};

export const handleUpdateSubscriptionPoliticalExposure = async ({
    get,
    set,
    subscriptionId,
    body,
}) => {
    try {
        const politicalExposure: PoliticalExposure =
            await SubscriptionPoliticalExposurePut(subscriptionId, body);
        const currentSubscription = get().subscription;

        set({
            subscription: {
                ...currentSubscription,
                politicalExposure,
            },
        });
    } catch (error) {
        notifyError(
            `Subscription ${subscriptionId} was not updated, message: ${error}`
        );
    }
};

export const handleUpdateSubscriptionContacts = async ({
    get,
    set,
    subscriptionId,
    body,
}) => {
    try {
        const subscriptionContacts = await SubscriptionContactsPut(
            subscriptionId,
            body
        );
        const currentSubscription = get().subscription;
        set({
            subscription: {
                ...currentSubscription,
                contacts: subscriptionContacts,
            },
        });
    } catch (error) {
        notifyError(
            `Subscription ${subscriptionId} was not updated, message: ${error}`
        );
    }
};

export const handleUpdateSubscriptionReport = async ({
    get,
    set,
    subscriptionId,
    body,
}) => {
    const subscriptionReport = await SubscriptionReportPut(
        subscriptionId,
        body
    );
    const currentSubscription = get().subscription;
    set({
        subscription: {
            ...currentSubscription,
            report: subscriptionReport,
        },
    });
};

export const handleUpdateSubscriptionSupportingDocuments = async ({
    get,
    set,
    subscriptionId,
    body,
}) => {
    const supportingDocuments = await SubscriptionSupportingDocumentsPut(
        subscriptionId,
        body
    );
    const currentSubscription = get().subscription;
    set({
        subscription: {
            ...currentSubscription,
            supportingDocuments: supportingDocuments,
        },
    });
};

export const handleUpdateSubscriptionTaxInformation = async ({
    get,
    set,
    subscriptionId,
    body,
}) => {
    try {
        const taxInformation = await SubscriptionTaxInformationPut(
            subscriptionId,
            body
        );
        const currentSubscription = get().subscription;
        set({
            subscription: {
                ...currentSubscription,
                taxInformation,
            },
        });
    } catch (error) {
        notifyError(
            `Subscription ${subscriptionId} was not updated, message: ${error}`
        );
    }
};

export const handleUpdateSubscriptionSourceOfWealth = async ({
    get,
    set,
    subscriptionId,
    body,
}) => {
    try {
        const sourceOfFundsAndWealth: SourceOfFundsAndWealth =
            await SubscriptionSourceOfWealthPut(subscriptionId, body);
        const currentSubscription = get().subscription;
        set({
            subscription: {
                ...currentSubscription,
                sourceOfFundsAndWealth,
            },
        });
    } catch (error) {
        notifyError(
            `Subscription ${subscriptionId} was not updated, message: ${error}`
        );
    }
};

export const handleUpdateSubscriptionDistributionOfFunds = async ({
    get,
    set,
    subscriptionId,
    body,
}) => {
    try {
        const distributionOfFunds = await SubscriptionDistributionOfFundsPut(
            subscriptionId,
            body
        );
        const currentSubscription = get().subscription;
        set({
            subscription: {
                ...currentSubscription,
                distributionOfFunds,
            },
        });
    } catch (error) {
        notifyError(
            `Subscription ${subscriptionId} was not updated, message: ${error}`
        );
    }
};

export const handleUpdateSubscriptionDistributorDetails = async ({
    get,
    set,
    subscriptionId,
    body,
}) => {
    try {
        const distributorDetails = await SubscriptionsDistributorDetailsPut(
            subscriptionId,
            body
        );
        const currentSubscription = get().subscription;
        set({
            subscription: {
                ...currentSubscription,
                distributorDetails,
            },
        });
    } catch (error) {
        notifyError(
            `Subscription ${subscriptionId} was not updated, message: ${error}`
        );
    }
};

export const handleUpdateSubscriptionAttestations = async ({
    get,
    set,
    subscriptionId,
    body,
}) => {
    try {
        const attestations = await SubscriptionAttestationsPut(
            subscriptionId,
            body
        );
        const currentSubscription = get().subscription;
        set({
            subscription: {
                ...currentSubscription,
                attestations,
            },
        });
    } catch (error) {
        notifyError(
            `Subscription ${subscriptionId} was not updated, message: ${error}`
        );
    }
};

export const handleUpdateSubscriptionGermanSemiProfessional = async ({
    get,
    set,
    subscriptionId,
    body,
}) => {
    try {
        const germanSemiProfessionalCategorisation =
            await SubscriptionGermanSemiProfessionalPut(subscriptionId, body);
        const currentSubscription = get().subscription;
        set({
            subscription: {
                ...currentSubscription,
                germanSemiProfessionalCategorisation,
            },
        });
    } catch (error) {
        notifyError(
            `Subscription ${subscriptionId} was not updated, message: ${error}`
        );
    } finally {
        set({ isLoading: false });
    }
};

export const handleCreateSubscriptionStatus = async ({
    set,
    subscriptionId,
    body,
}) => {
    try {
        const putObj = await SubscriptionStatusPut(subscriptionId, body);
        set({
            subscription: {
                ...putObj,
                status: putObj.status,
                statusComment: putObj.statusComment,
                updatedBy: putObj.updatedBy,
                createdAt: putObj.createdAt,
            },
        });
    } catch (error) {
        notifyError(
            `Subscription ${subscriptionId} was not updated, message: ${error}`
        );
    }
};

export const handleGetManualDraftSubscriptionAgreement = async (
    subscriptionId
) => {
    const result = await SubscriptionManualDraftSubscriptionAgreementGet(
        subscriptionId
    );
    return result;
};

export const handleUpdateManualSignedSubscriptionAgreement = async ({
    get,
    set,
    subscriptionId,
    body,
}) => {
    try {
        const putObj = await SubscriptionManualSignedSubscriptionAgreementPut(
            subscriptionId,
            body
        );
        const currentSubscription = get().subscription;
        set({
            subscription: {
                ...currentSubscription,
                signingDocuments: [
                    ...(currentSubscription.signingDocuments || []),
                    putObj,
                ],
            },
        });
    } catch (error) {
        notifyError(`${error}`);
    }
};
