import { useNavigate } from 'react-router-dom';

import { ProductsPost, UpdateProduct } from '@api/Products';

import { useProductsActions } from '@stores/Products';
import { parseAndStringifyJson } from '@helpers/json.helper';
import { notifyError, notifySuccess } from '@helpers/toastrHelper';
import { ProductResponse } from '@interfaces/Api';
import { InvestorLockUpEnum } from '@interfaces/Api/ProductResponse';

export const useProductSubmit = (
    productId: string | undefined,
    endPathUrl?: string,
    successMessage?: string
) => {
    const navigate = useNavigate();
    const { fetchProducts: getProductData } = useProductsActions();
    const baseUrl = '/admin/products';

    return (values: Partial<ProductResponse>, actions?: any) => {
        const saveProduct =
            productId !== 'new'
                ? {
                      func: UpdateProduct,
                      successMessage: 'Product has been updated',
                      failMessage: 'Failed to update product.',
                  }
                : {
                      func: ProductsPost,
                      successMessage: 'A new product has been created',
                      failMessage: 'Failed to create product!',
                  };

        const copyValues: Partial<ProductResponse> =
            parseAndStringifyJson(values);

        if (
            copyValues.assetManagerId &&
            typeof copyValues.assetManagerId === 'object'
        ) {
            copyValues.assetManagerId = (
                copyValues.assetManagerId as any
            ).value;
        }

        copyValues.shareClasses?.forEach((shareClass, i) => {
            if (
                shareClass.openEndedInformation?.investorLockUp ===
                InvestorLockUpEnum.None
            ) {
                shareClass.openEndedInformation.hardLockUpDetail = undefined;
                shareClass.openEndedInformation.softLockUpDetails = undefined;
            } else if (
                shareClass.openEndedInformation?.investorLockUp ===
                InvestorLockUpEnum.HardLockUp
            ) {
                shareClass.openEndedInformation.softLockUpDetails = undefined;
            } else if (
                shareClass.openEndedInformation?.investorLockUp ===
                InvestorLockUpEnum.SoftLockUp
            ) {
                shareClass.openEndedInformation.hardLockUpDetail = undefined;
            }
        });

        saveProduct
            .func(copyValues, productId as string)
            .then(async (result: any) => {
                await getProductData();

                if (endPathUrl) {
                    navigate(`${baseUrl}/${result._id}/${endPathUrl}`);
                }

                notifySuccess(successMessage ?? saveProduct.successMessage);
            })
            .catch((error) => {
                if (error.status === 400) {
                    if (
                        error?.result?.message ===
                        'ONLY_ONE_PROXY_SHARE_CLASS_IS_ALLOWED'
                    ) {
                        notifyError(
                            'Product should contain only one share class when proxy is true'
                        );
                    } else {
                        notifyError(
                            saveProduct.failMessage +
                                '\n The product may have been modified recently. Please refresh and try again.'
                        );
                        setTimeout(() => navigate(0), 1000);
                    }
                } else {
                    notifyError(saveProduct.failMessage);
                }
            })
            .finally(() => {
                if (actions) {
                    actions?.setSubmitting(false);
                }
            });
    };
};
