import { useCallback, useEffect, useState } from 'react';

import { useSubscriptionTemplateState } from '@contexts/SubscriptionTemplatesContext';
import { useAuthState } from '@contexts/AuthContext';

import {
    AuthRoleEnum,
    SubscriptionResponse,
    SubscriptionTemplateResponse,
} from '@interfaces/Api';
import { useSubscriptions } from '@stores/Subscriptions/useSubscriptions';

export function useSubscriptionsAndTemplates<
    T extends SubscriptionResponse | SubscriptionTemplateResponse
>(
    endInvestorProfileId: string,
    feature: 'subscriptions' | 'subscriptions-templates'
): {
    list: T[];
    reloadList: () => Promise<void>;
    isSubscriptionsLoading: boolean;
} {
    const [isSubscriptionsLoading, setIsSubscriptionsLoading] = useState(false);

    const { subscriptions } = useSubscriptions(endInvestorProfileId);
    const { getSubscriptionTemplates } = useSubscriptionTemplateState();
    const { hasRoles } = useAuthState();

    const [list, setList] = useState<any[]>([]);

    const reloadList = useCallback(async () => {
        const getSubscriptionData = async (
            feature: 'subscriptions' | 'subscriptions-templates'
        ) =>
            feature === 'subscriptions'
                ? subscriptions
                : await getSubscriptionTemplates(endInvestorProfileId);

        setIsSubscriptionsLoading(true);
        let data: SubscriptionResponse | SubscriptionTemplateResponse[] =
            await getSubscriptionData(feature);

        if (data && hasRoles([AuthRoleEnum.fundAdmin])) {
            data = data.filter((s) => s._id !== endInvestorProfileId);
        }
        setList(data || []);
    }, [
        hasRoles,
        endInvestorProfileId,
        feature,
        subscriptions,
        getSubscriptionTemplates,
    ]);

    useEffect(() => {
        reloadList().then();
    }, [
        hasRoles,
        endInvestorProfileId,
        feature,
        subscriptions,
        getSubscriptionTemplates,
        reloadList,
    ]);

    return {
        isSubscriptionsLoading,
        list,
        reloadList,
    };
}
