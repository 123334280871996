import { CreateProductRequestBody, ProductResponse } from '@interfaces/Api';
import { BaseUrl } from '@helpers/Environment';

import { Http } from '../libs/http';
import { ProductPriorities } from '@interfaces/Api/ProductPriorities';

const API_PRODUCTS_URL = `${BaseUrl}/products`;

export const ProductsGetAll = async (endInvestorProfileId?: string) => {
    let queryParams;
    if (endInvestorProfileId) queryParams = { endInvestorProfileId };
    const result = await Http.get<ProductResponse[]>(
        API_PRODUCTS_URL,
        undefined,
        queryParams
    );
    return result;
};

export const ProductsGetAllPublished = async (
    endInvestorProfileId?: string
) => {
    const queryParams = {
        onlyPublished: 'true',
    };

    return await Http.get<ProductResponse[]>(
        API_PRODUCTS_URL,
        undefined,
        queryParams
    );
};

export const productsGetById = async (id: string): Promise<ProductResponse> =>
    Http.get(`${API_PRODUCTS_URL}/id/${id}`);

export const ProductGetBySlug = async (
    slug: string
): Promise<ProductResponse> => Http.get(`${API_PRODUCTS_URL}/name/${slug}`);

export const ProductsPost = async (body: CreateProductRequestBody) => {
    const result = await Http.post(API_PRODUCTS_URL, body);
    return result;
};

export const ProductsPrioritise = async (body: ProductPriorities[]) => {
    const result = await Http.put<ProductResponse[]>(
        `${API_PRODUCTS_URL}/prioritise`,
        body
    );
    return result;
};

export const UpdateProduct = (product: Partial<ProductResponse>, id: string) =>
    Http.put(`${API_PRODUCTS_URL}/id/${id}`, product);

export const DeleteProduct = (id: string) =>
    Http.delete(`${API_PRODUCTS_URL}/id/${id}`);

export const ProductPublish = (product: ProductResponse) =>
    Http.post<ProductResponse>(`${API_PRODUCTS_URL}/publish`, product);

export const importProduct = async (body: CreateProductRequestBody) => {
    const result = await Http.post<ProductResponse>(
        `${API_PRODUCTS_URL}/import`,
        body
    );
    return result;
};
