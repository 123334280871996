import { Checkbox } from '@components/Atoms';

import { toCapitalizedWords } from '@helpers/ToCapitalizedWords';

export const renderPreferencesTree = (obj: any, callback = () => {}) => {
    if (!obj) {
        return <></>;
    }

    return (
        <ul>
            {Object.keys(obj).map((key) => {
                if (typeof obj[key] === 'object') {
                    return (
                        <li className="pt-5" key={key}>
                            <h3 className="bold text-lg font-medium">
                                {toCapitalizedWords(key)}:
                            </h3>
                            {renderPreferencesTree(obj[key], callback)}
                        </li>
                    );
                } else {
                    return (
                        <li className="pl-5 pt-2" key={key}>
                            <Checkbox
                                label={toCapitalizedWords(key)}
                                name={key}
                                data-testid={key}
                                defaultChecked={obj[key]}
                                checked={obj[key]}
                                onChange={(event: any) => {
                                    obj[key] = event.target.checked;
                                    callback();
                                }}
                            />
                        </li>
                    );
                }
            })}
        </ul>
    );
};
