import { Link } from 'react-router-dom';
import { AuthPermissionEnum } from '@interfaces/Api';
import { useAuthState } from '@contexts/AuthContext';
import DocusignConsent from '../Containers/DocuSignConsent';
import DocumentLibraryCount from '../Containers/DocumentTemplateLibrary/components/DocumentLibraryCount';
import { DistributorContactEmail } from './DistributorContactEmail';

const ApplicationSettings = () => {
    const { hasPermissions } = useAuthState();

    const cardsConfig = [
        {
            title: 'DocuSign consent',
            description:
                'This is the consent text that will be displayed to the user when they are asked to sign a document.',
            component: hasPermissions([
                AuthPermissionEnum.createDocuSignConsent,
            ]) && <DocusignConsent />,
        },
        {
            title: 'Disclaimer text',
            description:
                'This is the disclaimer text that will be displayed before accessing the product page.',
            href: 'disclaimer-text',
        },
        {
            title: 'Document Templates Library',
            component: <DocumentLibraryCount />,
            href: 'document-library',
        },
        {
            title: 'Distributor Contact Email',
            description: 'Email address distributors can reach out regarding on boarding. Used if \'enableContinueAsDistributor\' pref switch is on.',
            href: 'distributor-contact-email',
        },
    ];

    return (
        <div>
            <div className="w-full mb-5">
                <h1 className="text-3xl text-logo-blue font-bold">
                    Application Settings
                </h1>
            </div>
            <div className="flex flex-wrap">
                {cardsConfig.map((card) => {
                    const cardContent = (
                        <>
                            <div className="w-full mb-5">
                                <h2 className="text-2xl text-logo-blue font-bold">
                                    {card.title}
                                </h2>
                            </div>
                            <p>{card.component || card.description}</p>
                        </>
                    );
                    return card.href ? (
                        <Link
                            className="w-full mb-5 border-2 p-4 mr-2 flex-1"
                            to={card.href}
                            key={card.title}
                        >
                            {cardContent}
                        </Link>
                    ) : (
                        <div className="w-full mb-5 border-2 p-4 mr-2 flex-1">
                            {cardContent}
                        </div>
                    );
                })}
            </div>
        </div>
    );
};

export default ApplicationSettings;
