import { useTranslation } from 'react-i18next';
import { SourceOfFundsAndWealth } from '@interfaces/Api';

import { Icons } from '@components/Atoms';

interface ReviewSourceOfFundsAndWealthProps {
    sourceOfFundsAndWealth?: SourceOfFundsAndWealth;
}

type LinkInformation = {
    url: string;
    fileName: string;
};

const LinkToDownload = (val: LinkInformation) => (
    <a
        href={val.url}
        target="_blank"
        rel="noreferrer"
        className="flex items-center"
    >
        <Icons name="ArrowDownTrayIcon" size="small" />
        <p className="ml-2">{val.fileName}</p>
    </a>
);

const ReviewSourceOfFundsAndWealth = (
    props: ReviewSourceOfFundsAndWealthProps
) => {
    const { t } = useTranslation();
    return (
        <div className="text-base">
            <div>
                <strong>{t('subscriptions.add.subscription.review_subscription.describe_source_of_funds')}</strong>
                {props.sourceOfFundsAndWealth?.describeSourceOfFunds}
            </div>

            <div>
                <strong>{t('subscriptions.add.subscription.review_subscription.describe_source_of_funds.documents')}</strong>
                {props.sourceOfFundsAndWealth?.sourceOfFundsDocuments.map(
                    (val, i) => (
                        <LinkToDownload key={val.fileName + i} {...val} />
                    )
                )}
            </div>

            <div>
                <strong>{t('subscriptions.add.subscription.review_subscription.describe_source_of_wealth')}</strong>
                {props.sourceOfFundsAndWealth?.describeSourceOfWealth}
            </div>

            <div>
                <strong>{t('subscriptions.add.subscription.review_subscription.describe_source_of_wealth.documents')}</strong>
                {props.sourceOfFundsAndWealth?.sourceOfWealthDocuments.map(
                    (val, i) => (
                        <LinkToDownload key={val.fileName + i} {...val} />
                    )
                )}
            </div>
        </div>
    );
}

export default ReviewSourceOfFundsAndWealth;
