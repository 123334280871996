import { useCallback, useEffect, useState } from 'react';

import {
    AuthRoleEnum,
    EndInvestorProfileResponse,
    ProposalResponse,
} from '@interfaces/Api';

import { ProposalsGetAll } from '@api/Proposals';

import { useAuthState } from '@contexts/AuthContext';

import { notifyError, notifySuccess } from '@helpers/toastrHelper';

import { Proposal } from '@pages/Clients/Clients/Components/Proposal/Proposal';

import SubscriptionProposals, {
    SubscriptionProposalsListItem,
} from '@containers/Subscriptions/SubscriptionProposals';
import { AuthPermissionEnum } from '@interfaces/Api';
import { useMountEffect } from '@hooks/useMountEffect';

export interface ReviewClientProposalsListProps {
    endInvestorProfile: EndInvestorProfileResponse;
}

const ReviewClientProposalsList: React.FC<ReviewClientProposalsListProps> = (
    props: ReviewClientProposalsListProps
) => {
    const { endInvestorProfile } = props;
    const { hasRoles, hasPermissions } = useAuthState();

    const [subscriptionProposals, setSubscriptionProposals] = useState<
        ProposalResponse[]
    >([]);

    useMountEffect(() => {
        async function RunAsync() {
            let subscriptionProposal = (await ProposalsGetAll(
                endInvestorProfile._id
            )) as ProposalResponse[];
            if (hasRoles([AuthRoleEnum.fundAdmin])) {
                subscriptionProposal = subscriptionProposals?.filter(
                    (s) => s._id !== endInvestorProfile._id
                );
            }
            setSubscriptionProposals(subscriptionProposal || []);
        }

        RunAsync();
    });

    const refreshProposals = async () => {
        try {
            const userProposals = (await ProposalsGetAll(
                endInvestorProfile._id
            )) as ProposalResponse[];
            setSubscriptionProposals(userProposals);
            setTimeout(
                () => notifySuccess('Proposal list is now updated!'),
                3000
            );
        } catch (err: any) {
            notifyError(err.message);
        }
    };

    const SubscriptionProposalsListItems = useCallback(() => {
        return subscriptionProposals.map<SubscriptionProposalsListItem>(
            (proposal) => ({
                proposal: proposal,
                endInvestorProfile: endInvestorProfile,
            })
        );
    }, [endInvestorProfile, subscriptionProposals]);

    return (
        <div className="leading-5 mb-6">
            <SubscriptionProposals
                subscriptionProposalsListItems={SubscriptionProposalsListItems()}
                onProposalUpdate={refreshProposals}
            />
            <div className="w-full flex flex-row-reverse pt-2">
                {hasPermissions(AuthPermissionEnum.createProductProposal) &&
                    endInvestorProfile.serviceLevelType ===
                        EndInvestorProfileResponse.ServiceLevelTypeEnum
                            .Advisory && (
                        <Proposal
                            endInvestorProfileId={endInvestorProfile._id}
                            onHandleRefresh={refreshProposals}
                        />
                    )}
            </div>
        </div>
    );
};

export default ReviewClientProposalsList;
