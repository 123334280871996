import React, { useEffect } from 'react';

import CreatableSelect from 'react-select/creatable';
import { ActionMeta, OnChangeValue, StylesConfig } from 'react-select';

export interface Option {
    value: string;
    label: string;
}
type IsMulti = true;

// https://react-select.com/styles
const customStyles: StylesConfig<Option, IsMulti> = {
    control: (provided, state) => ({
        ...provided,
        borderRadius: '8px',
        minHeight: '56px',
        borderWidth: '1px',
        borderColor: state.selectProps.hasError
            ? 'rgba(187, 6, 6, 1)'
            : state.isFocused
            ? 'rgba(73, 119, 221, 1)'
            : 'rgba(215, 215, 224, 1)',
        boxShadow: 'unset',
        backgroundColor: state.selectProps.hasError
            ? 'rgba(187, 6, 6, 0.08)'
            : 'unset',
    }),
    valueContainer: (provided, state) => ({
        ...provided,
        paddingTop: '28px',
        paddingBottom: '4px',
        paddingLeft: '12px',
    }),
    indicatorSeparator: (provided, state) => ({
        ...provided,
        backgroundColor: '#00376e',
    }),
    dropdownIndicator: (provided, state) => ({
        ...provided,
        color: '#00376e',
        padding: '0 16px',
    }),
    placeholder: (provided, state) => ({
        ...provided,
        color: '#00376e',
    }),
    singleValue: (provided, state) => ({
        ...provided,
        color: '#00376e',
    }), 
};

interface MultiselectCreatableProps {
    options: Option[];
    label?: string;
    defaultValue?: Option[];
    placeholder: string;
    disabled?: boolean;
    required?: boolean;
    isSingle?: boolean;
    isClearable?: boolean;
    handleChange: (
        newValue: OnChangeValue<any, true | false>,
        actionMeta: ActionMeta<any>
    ) => void;
}

const MultiselectCreatable: React.FC<MultiselectCreatableProps> = (props) => {
    const {
        options,
        label,
        defaultValue,
        placeholder,
        handleChange,
        disabled,
        required = false,
        isSingle = false,
        isClearable = false,
    } = props;

    const creatableSelectProps = {
        styles: customStyles,
        value: defaultValue,
        onChange: handleChange,
        options: options,
        placeholder: placeholder,
        isDisabled: disabled,
        isClearable: isClearable,
    };

    return (
        <div className="relative">
            {isSingle ? (
                <CreatableSelect {...creatableSelectProps} />
            ) : (
                <CreatableSelect {...creatableSelectProps} isMulti />
            )}

            {label && (
                <div className="absolute left-1 top-2 text-xs px-2">
                    {label} {required && '*'}
                </div>
            )}
        </div>
    );
};

export default MultiselectCreatable;
