import { BaseUrl } from '@helpers/Environment';
import { RelatedSubscriptionsResponse } from '@interfaces/Api/RelatedSubscriptionResponse';
import { Http } from '../libs/http';

const API_RELATED_SUBSCRIPTIONS_URL = `${BaseUrl}/related-subscriptions`;

export const GetRelatedSubscriptions = (subscriptionId: string) => {
    return Http.get<RelatedSubscriptionsResponse>(
        `${API_RELATED_SUBSCRIPTIONS_URL}`,
        undefined,
        {
            subscriptionId,
        }
    );
};
