import React, { Dispatch, KeyboardEventHandler, useState } from 'react';
import { MultiselectSearch, Option } from '@components/Molecules';
import { MultiValue } from 'react-select';

interface SearchBoxProps {
    setSearchQuery: Dispatch<React.SetStateAction<string[]>>;
}

const createOption = (label: string) => ({
    label,
    value: label,
});
const SearchBox: React.FC<SearchBoxProps> = ({ setSearchQuery }) => {
    const [inputValue, setInputValue] = useState('');
    const [value, setValue] = useState<Option[]>([]);

    const handleKeyDown: KeyboardEventHandler = (event) => {
        if (!inputValue) return;
        switch (event.key) {
            case 'Enter':
            case 'Tab': {
                const selectedTermOption = createOption(inputValue);
                setValue((prev) => [...prev, selectedTermOption]);
                if (value.length < 1 && selectedTermOption) {
                    setSearchQuery(
                        [selectedTermOption].flatMap((a) => a.value)
                    );
                } else {
                    setSearchQuery(
                        [...value, selectedTermOption].flatMap((a) => a.value)
                    );
                }
                setInputValue('');
                event.preventDefault();
            }
        }
    };

    return (
        <MultiselectSearch
            handleChange={(options: MultiValue<Option>) => {
                //set search query on removal of search terms
                setSearchQuery(options.flatMap((a: Option) => a.value));
                setValue(options as Option[]);
            }}
            handleKeyDown={handleKeyDown}
            handleInputChange={setInputValue}
            defaultValue={value}
            inputValue={inputValue}
            label={'Search'}
            placeholder={'Enter a Search term...'}
        />
    );
};

export default SearchBox;
