import { useTranslation } from 'react-i18next';
import { DistributorDetailsResponse } from '@interfaces/Api';

interface ReviewDistributorDetailsProps {
    distributorDetails?: DistributorDetailsResponse;
}

function ReviewDistributorDetails(props: ReviewDistributorDetailsProps) {
    const { t } = useTranslation();
    return (
        <div className="text-base grid grid-cols-3 w-full leading-5">
            <div>
                <strong>{t('subscriptions.add.subscription.review_subscription.distributors_name')} </strong>
                {props.distributorDetails?.distributorName}
            </div>
        </div>
    );
}

export default ReviewDistributorDetails;
