import React from 'react';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Doughnut } from 'react-chartjs-2';
import { DoughnutChartProps } from '@interfaces/InterfaceChartProps';

const WidgetDoughnutChart: React.FC<DoughnutChartProps> = (
    props: DoughnutChartProps
) => {
    ChartJS.register(ArcElement, Tooltip, Legend);

    return (
        <div className="my-4 flex flex-col">
            {props.title && props.abstract && (
                <div className="flex flex-col items-center my-4">
                    <h3 className="text-xl text-logo-blue my-2">
                        {props.title}
                    </h3>
                    <h4 className="text-lg my-2">{props.abstract}</h4>
                </div>
            )}
            <div className="flex justify-center">
                <Doughnut
                    data={props.data}
                    className="canvas-fluid"
                    options={{
                        responsive: true,
                        plugins: {
                            legend: {
                                display: true,
                                position: 'bottom' as const,
                            },
                        },
                    }}
                />
            </div>
        </div>
    );
};

export default WidgetDoughnutChart;
