import { PoliticalExposure, PoliticalPersonDetail } from '@interfaces/Api';

interface ReviewPoliticalProps {
    political?: PoliticalExposure;
}

function ReviewPoliticallyExposedPeople(props: ReviewPoliticalProps) {
    return (
        <div className="text-base grid grid-cols-3 w-full">
            {props.political?.politicalPersons?.map(
                (person: PoliticalPersonDetail, index: number) => (
                    <div className="leading-5" key={index}>
                        <div>
                            <strong>Name: </strong>
                            {`${person.firstName} ${person.lastName}`}
                        </div>
                        <div>
                            <strong>Position: </strong>
                            {person.positionOrName}
                        </div>
                        <div>
                            <strong>Registered Address: </strong>
                            {person.registeredAddress}
                        </div>
                        <div>
                            <strong>D.O.B: </strong>
                            {person.dateOfBirth}
                        </div>
                        <div>
                            <strong>Place Of Birth: </strong>
                            {person.placeOfBirth}
                        </div>
                        <div>
                            <strong>Nationality: </strong>
                            {person.nationalities}
                        </div>
                        <div>
                            <strong>NI Number: </strong>
                            {person.nationalIdNumber}
                        </div>
                    </div>
                )
            )}
        </div>
    );
}

export default ReviewPoliticallyExposedPeople;
