export const columns = [
    { title: 'Document Type', key: 'documentType' },
    { title: 'Document Name', key: 'fileName' },
    { title: 'Created By', key: 'createdBy' },
    { title: 'Created Date', key: 'createdDate' },
    { title: 'Updated By', key: 'updatedBy' },
    { title: 'Updated Date', key: 'updated Date' },
    { title: '' },
];

export const getTableHeaderClass = (name: string, columnSortConfig: any) => {
    if (!columnSortConfig) {
        return;
    }
    return columnSortConfig.key === name ? columnSortConfig.direction : '';
};
