import { ProductResponse } from '@interfaces/Api';
import { useEffect, useState } from 'react';
import { useProducts } from '@stores/Products/useProducts';

export const useProduct = (productId?: string) => {
    const { products } = useProducts();
    const [product, setProduct] = useState<ProductResponse | undefined>();

    useEffect(() => {
        const product = products.find((product) => product._id === productId);

        setProduct(product);
    }, [products, productId]);

    return {
        product,
    };
};
