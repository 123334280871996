import { SearchBar } from '@pages/Clients/Clients/Components/SearchBar';

interface TopBarProps {
    nameSearch: string | undefined;
    setNameSearch: (name: string) => void;
    endInvestorProfileGroupOptions: { label: string; value: string }[];
    setSelectedGroupIds: (list: string[]) => void;
}

export const TopBar: React.FC<TopBarProps> = (props) => {
    const {
        nameSearch,
        setNameSearch,
        setSelectedGroupIds,
        endInvestorProfileGroupOptions,
    } = props;

    return (
        <>
            <SearchBar
                nameSearch={nameSearch}
                setNameSearch={setNameSearch}
                endInvestorProfileGroupOptions={endInvestorProfileGroupOptions}
                setSelectedGroupIds={setSelectedGroupIds}
            />
        </>
    );
};
