import { useTranslation } from 'react-i18next';
import { AreaOfBusinessActivityType, CorporateType } from 'common';
import { useFormikContext } from 'formik';
import getClientRegistrationFormFields from '../ClientRegistrationFormFields';
import { FormCheckbox, FormInputBox, FormSelect } from '@components/Molecules';
import { DatePicker } from '@components/Organisms';
import { isoCountriesOptions } from '@helpers/isoCountries';

const corporateTypeOptions = Object.values(CorporateType).map((value) => ({
    label: value,
    value,
}));
const areaOfBusinessActivityTypeOptions = Object.values(
    AreaOfBusinessActivityType
).map((value) => ({
    label: value,
    value,
}));

export const BusinessDetailsSelection = () => {
    const { t } = useTranslation();
    const { values } = useFormikContext();
    return (
        <div className="py-2">
            <FormInputBox
                name={getClientRegistrationFormFields(t).companyName.name}
                label={getClientRegistrationFormFields(t).companyName.label}
                placeholder={
                    getClientRegistrationFormFields(t).companyName.label
                }
            />
            <FormInputBox
                name={
                    getClientRegistrationFormFields(t).registrationNumber.name
                }
                label={
                    getClientRegistrationFormFields(t).registrationNumber.label
                }
                placeholder={
                    getClientRegistrationFormFields(t).registrationNumber.label
                }
            />
            <FormInputBox
                name={
                    getClientRegistrationFormFields(t).subscribersLegalForm.name
                }
                label={
                    getClientRegistrationFormFields(t).subscribersLegalForm
                        .label
                }
                placeholder={
                    getClientRegistrationFormFields(t).subscribersLegalForm
                        .label
                }
            />
            <FormInputBox
                name={
                    getClientRegistrationFormFields(t).subscribersRegister.name
                }
                label={
                    getClientRegistrationFormFields(t).subscribersRegister.label
                }
                placeholder={
                    getClientRegistrationFormFields(t).subscribersRegister.label
                }
            />
            <FormInputBox
                name={
                    getClientRegistrationFormFields(t)
                        .subscriberParentListingAuthority.name
                }
                label={
                    getClientRegistrationFormFields(t)
                        .subscriberParentListingAuthority.label
                }
                placeholder={
                    getClientRegistrationFormFields(t)
                        .subscriberParentListingAuthority.label
                }
            />
            <FormInputBox
                name={
                    getClientRegistrationFormFields(t).subscribersRegulator.name
                }
                label={
                    getClientRegistrationFormFields(t).subscribersRegulator
                        .label
                }
                placeholder={
                    getClientRegistrationFormFields(t).subscribersRegulator
                        .label
                }
            />
            <FormSelect
                name={getClientRegistrationFormFields(t).corporateType.name}
                label={getClientRegistrationFormFields(t).corporateType.label}
                optionsData={corporateTypeOptions}
            />
            {(values as any)?.corporateType === CorporateType.Other && (
                <FormInputBox
                    name={
                        getClientRegistrationFormFields(t).otherCorporateType
                            .name
                    }
                    label={
                        getClientRegistrationFormFields(t).otherCorporateType
                            .label
                    }
                    placeholder={
                        getClientRegistrationFormFields(t).otherCorporateType
                            .label
                    }
                />
            )}
            <FormInputBox
                name={getClientRegistrationFormFields(t).parentCompany.name}
                label={getClientRegistrationFormFields(t).parentCompany.label}
                placeholder={
                    getClientRegistrationFormFields(t).parentCompany.label
                }
            />
            <DatePicker
                name={
                    getClientRegistrationFormFields(t).dateOfIncorporation.name
                }
                label={
                    getClientRegistrationFormFields(t).dateOfIncorporation.label
                }
            />
            <FormSelect
                name={
                    getClientRegistrationFormFields(t)
                        .countryCodeOfIncorporation.name
                }
                label={
                    getClientRegistrationFormFields(t)
                        .countryCodeOfIncorporation.label
                }
                optionsData={isoCountriesOptions}
            />
            <FormSelect
                name={
                    getClientRegistrationFormFields(t)
                        .principalPlaceOfBusinessCountryCode.name
                }
                label={
                    getClientRegistrationFormFields(t)
                        .principalPlaceOfBusinessCountryCode.label
                }
                optionsData={isoCountriesOptions}
            />
            <FormSelect
                name={
                    getClientRegistrationFormFields(t)
                        .areaOfBusinessActivityType.name
                }
                label={
                    getClientRegistrationFormFields(t)
                        .areaOfBusinessActivityType.label
                }
                optionsData={areaOfBusinessActivityTypeOptions}
            />
            <FormCheckbox
                name={
                    getClientRegistrationFormFields(t).isListedOnStockExchange
                        .name
                }
                actionLabel={
                    getClientRegistrationFormFields(t).isListedOnStockExchange
                        .label
                }
            />
            {(values as any).isListedOnStockExchange && (
                <FormInputBox
                    name={
                        getClientRegistrationFormFields(t)
                            .listedOnStockExchangeListingCode.name
                    }
                    label={
                        getClientRegistrationFormFields(t)
                            .listedOnStockExchangeListingCode.label
                    }
                    placeholder={
                        getClientRegistrationFormFields(t)
                            .listedOnStockExchangeListingCode.label
                    }
                />
            )}
            <FormCheckbox
                name={getClientRegistrationFormFields(t).isRegulatedEntity.name}
                actionLabel={
                    getClientRegistrationFormFields(t).isRegulatedEntity.label
                }
            />
            <FormInputBox
                name={
                    getClientRegistrationFormFields(t).licenseNumber
                        .name
                }
                label={
                    getClientRegistrationFormFields(t).licenseNumber
                        .label
                }
                placeholder={
                    getClientRegistrationFormFields(t).licenseNumber
                        .label
                }
            />
            <FormInputBox
                name={
                    getClientRegistrationFormFields(t).regulatorWebsiteAddress
                        .name
                }
                label={
                    getClientRegistrationFormFields(t).regulatorWebsiteAddress
                        .label
                }
                placeholder={
                    getClientRegistrationFormFields(t).regulatorWebsiteAddress
                        .label
                }
            />
        </div>
    );
};
