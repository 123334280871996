import { BaseUrl } from '@helpers/Environment';
import { Http } from '../libs/http';
import { ProspectiveInvestorRequest } from '@interfaces/Api/ProspectiveInvestorRequest';
import { ProspectiveInvestorResponse } from '@interfaces/Api/ProspectiveInvestorResponse';
import { BusinessEntityDetails, EndInvestorType } from 'common';
import { PersonalDetail } from '@tenants/Pages/HG/IndividualDetail/IndividualDetail';

const API_PROSPECTIVEINVESTORS_URL = `${BaseUrl}/prospective-investors`;

export const ProspectiveInvestorsPost = async (
    body: ProspectiveInvestorRequest
) => Http.post<ProspectiveInvestorRequest>(API_PROSPECTIVEINVESTORS_URL, body);

export const ProspectiveInvestorsGetAll = async () =>
    Http.get<ProspectiveInvestorResponse[]>(API_PROSPECTIVEINVESTORS_URL);

export const ProspectiveInvestorsGetById = async (
    prospectiveInvestorId: string
) =>
    Http.get<ProspectiveInvestorResponse>(
        `${API_PROSPECTIVEINVESTORS_URL}/${prospectiveInvestorId}`
    );

export const ProspectiveInvestorsDeleteById = async (
    prospectiveInvestorId: string
) =>
    Http.delete<ProspectiveInvestorResponse>(
        `${API_PROSPECTIVEINVESTORS_URL}/${prospectiveInvestorId}`
    );
    

export const ProspectiveInvestorsPutStatus = async (
    prospectiveInvestorId: string,
    body: {
        status: string;
        assessmentReason: string;
    }
) =>
    Http.put<ProspectiveInvestorResponse>(
        `${API_PROSPECTIVEINVESTORS_URL}/${prospectiveInvestorId}/status`,
        body
    );

export const ProspectiveInvestorsPutConvert = async (
    prospectiveInvestorId: string,
    extraDetail?: BusinessEntityDetails | PersonalDetail,
    endInvestorType?: EndInvestorType
) =>{
    let data;

    if (extraDetail && endInvestorType) {
        data = {
            extraDetail,
            endInvestorType
        }
    }
    
    return Http.put<ProspectiveInvestorResponse>(
        `${API_PROSPECTIVEINVESTORS_URL}/${prospectiveInvestorId}/convert`,
        data
    );
}
    
