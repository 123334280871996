import {
    getLocalStorageAuthTokens,
    refreshAuthTokens,
} from '@helpers/auth.helper';

export class Http {
    static get<T>(
        url: string,
        headers?: Record<string, any>,
        queryParams?: { [key: string]: string | boolean | number | undefined }
    ): Promise<T> {
        return this.send(url, 'GET', undefined, headers, queryParams);
    }

    static post<T>(
        url: string,
        data?: any,
        headers?: Record<string, any>,
        queryParams?: { [key: string]: string | boolean | number | undefined }
    ): Promise<T> {
        return this.send(url, 'POST', data, headers, queryParams);
    }

    static delete<T>(url: string, headers?: Record<string, any>): Promise<T> {
        return this.send(url, 'DELETE', undefined, headers);
    }

    static put<T>(
        url: string,
        data?: any,
        headers?: Record<string, any>
    ): Promise<T> {
        return this.send(url, 'PUT', data, headers);
    }

    static patch<T>(
        url: string,
        data?: any,
        headers?: Record<string, any>
    ): Promise<T> {
        return this.send(url, 'PATCH', data, headers);
    }

    static async send<T>(
        url: string,
        method: string,
        data: any,
        headers?: Record<string, any>,
        queryParams?: { [key: string]: string | boolean | number | undefined }
    ): Promise<T> {
        const isFormData = data instanceof FormData;
        const token = getLocalStorageAuthTokens()?.access?.token;
        const urlInterface = new URL(url);

        if (queryParams) {
            Object.entries(queryParams).forEach(([key, value]) => {
                if (value) {
                    urlInterface.searchParams.set(key, String(value));
                }
            });
        }

        const httpHeaders = headers || {
            'Content-Type': 'application/json',
            Accept: 'application/json',
            Authorization: `Bearer ${token}`,
        }

        if (isFormData) {
            delete httpHeaders['Content-Type'];
        }

        const body =  isFormData || typeof data !== 'object' ? data : JSON.stringify(data);

        const response = await fetch(urlInterface.toString(), {
            method,
            headers: httpHeaders,
            body,
        });

        // if 401, then try the refresh token flow
        if (response.status === 401) {
            const isRefreshSuccessful = await refreshAuthTokens();

            // try again
            if (isRefreshSuccessful === true) {
                return this.send(url, method, data, headers, queryParams);
            } else {
                // The below is a log out error
                // Should force a user to logout
                throw {
                    status: response.status,
                    statusText: response.statusText,
                };
            }
        }

        const hasJsonContentType = response.headers
            .get('content-type')
            ?.includes('application/json');

        if (response.ok) {
            return hasJsonContentType
                ? await response.json()
                : ((await response.text()) as any);
        } else {
            const result = hasJsonContentType ? await response.json() : '';
            throw {
                status: response.status,
                statusText: response.statusText,
                result,
            };
        }
    }
}
