import { Timeline } from '@components/Organisms';
import { EventItem } from './Lifecycle/EventItem';
import { LiquidityTypeEnum } from '@interfaces/Api';
import { TimelineEventsList } from '@components/Organisms/Timeline/interfaces';
import { TimelineEnd, TimelineEndType } from '../../Timeline/TimelineEnd';
import {
    LifecycleProduct,
    LifecycleSubscription,
} from './Lifecycle/interfaces/subscriptionLifecycle';
import { getFormattedCurrency } from '@helpers/isoCurrencies';
import {
    NoticeType,
    noticeTimelineLabels,
} from '@interfaces/Api/SubscriptionNotice';
import { dateMonthYearFormatter } from '@helpers/Date.helper';
import { useTranslation } from 'react-i18next';
interface LifecycleTabContentProps {
    subscription: LifecycleSubscription;
    product: LifecycleProduct;
}

export const LifecycleTabContent: React.FC<LifecycleTabContentProps> = ({
    subscription,
    product,
}) => {
    const { t } = useTranslation();
    const { subscriptionCurrencyCode = '', notices }: LifecycleSubscription =
        subscription;
    const { liquidityType, inceptionDate } = product;
    const fundEndYear =
        inceptionDate &&
        new Date(inceptionDate).setFullYear(
            new Date(inceptionDate).getFullYear() + 10
        );
    const fundEndDate =
        liquidityType === LiquidityTypeEnum.closedEnded && fundEndYear
            ? new Date(fundEndYear)
            : undefined;

    const eventsList: TimelineEventsList[] =
        notices
            .map((notice) => {
                return {
                    date:
                        notice?.noticeType === NoticeType.CapitalCall
                            ? notice?.dueDate
                            : notice?.uploadedAt,
                    heading: [
                        noticeTimelineLabels[notice?.noticeType],
                        notice?.amount > 0 &&
                            getFormattedCurrency(
                                notice?.amount,
                                subscriptionCurrencyCode
                            ) + ' - ',
                        notice?.uploadedAt &&
                            dateMonthYearFormatter(notice?.uploadedAt),
                    ]
                        .filter((a) => a)
                        .join(' '),
                    children: (
                        <EventItem
                            notice={notice}
                            currency={subscriptionCurrencyCode}
                        />
                    ),
                };
            })
            .sort((a: TimelineEventsList, b: TimelineEventsList) => {
                const dateA = new Date(a.date || '');
                const dateB = new Date(b.date || '');
                return dateB.getTime() - dateA.getTime();
            }) || [];

    return (
        <>
            <section className="w-full pb-20">
                <div className="container mx-auto flex flex-col items-start md:flex-row my-12 md:my-24 justify-center ">
                    <Timeline
                        eventsList={eventsList}
                        header={
                            liquidityType === LiquidityTypeEnum.closedEnded ? (
                                <TimelineEnd
                                    title={t('client.portfolio.product.lifecycle.expected_term')}
                                    date={fundEndDate}
                                    endType={TimelineEndType.header}
                                />
                            ) : null
                        }
                        footer={
                            <TimelineEnd
                                iconURL="/assets/rocket.png"
                                title={t('client.portfolio.product.lifecycle.launched')}
                                date={inceptionDate}
                                endType={TimelineEndType.footer}
                            />
                        }
                    />
                </div>
            </section>
        </>
    );
};
