import { useTranslation } from 'react-i18next';
import {
    crsFinancialInstitutionTypeLabel,
    crsNonFinancialInstitutionTypeLabel,
    fatcaFinancialInstitutionTypeLabel,
    fatcaNonFinancialInstitutionTypeLabel,
    legalEntityTypeLabel,
    noGIINReasonTypeLabel,
    TaxInformation,
} from '@interfaces/Api';

import { isoCountries } from '@helpers/isoCountries';

interface ReviewTaxDeclarationProps {
    taxInformation?: TaxInformation;
}

const ReviewTaxDeclaration = (props: ReviewTaxDeclarationProps) => {
    const { t } = useTranslation();
    return (
        <div>
            <div className="text-base  w-full leading-5">
                {/* individual information */}
                {props.taxInformation?.individual && (
                    <div>
                        <strong>{t('subscriptions.add.tax_declaration.country_of_residence.heading')}</strong>
                        {props.taxInformation.individual?.taxCountries
                            ?.map((a) => isoCountries[a.countryCode])
                            .join(' - ')}
                    </div>
                )}

                {/* legal entity information */}
                {props.taxInformation?.entity &&
                    [
                        {
                            label: t('subscriptions.add.tax_declaration.country_of_residence.heading'),
                            value: props.taxInformation.entity.taxCountries
                                ?.map((a) => isoCountries[a.countryCode])
                                .join(' - '),
                        },
                        {
                            label: 'Legal Entity Type',
                            value:
                                props.taxInformation.entity.legalEntityType &&
                                legalEntityTypeLabel[
                                    props.taxInformation.entity.legalEntityType
                                ],
                        },

                        {
                            label: 'Fatca Financial Institution Type',
                            value:
                                props.taxInformation.entity.fatca
                                    ?.fatcaFinancialInstitutionType &&
                                fatcaFinancialInstitutionTypeLabel[
                                    props.taxInformation.entity.fatca
                                        .fatcaFinancialInstitutionType
                                ],
                        },
                        {
                            label: 'Global Intermediary Identification number (GIIN)',
                            value: props.taxInformation.entity.fatca?.GIIN,
                        },
                        {
                            label: 'No GIIN reason type',
                            value:
                                props.taxInformation.entity.fatca
                                    ?.noGIINReasonType &&
                                noGIINReasonTypeLabel[
                                    props.taxInformation.entity.fatca
                                        .noGIINReasonType
                                ],
                        },
                        {
                            label: "Sponsor's GIIN",
                            value: props.taxInformation.entity.fatca
                                ?.sponsorsGIIN,
                        },
                        {
                            label: "Sponsor's name",
                            value: props.taxInformation.entity.fatca
                                ?.sponsorsName,
                        },
                        {
                            label: 'Non-Financial Institutions under FATCA',
                            value:
                                props.taxInformation.entity.fatca
                                    ?.nonFinancialInstitutionType &&
                                fatcaNonFinancialInstitutionTypeLabel[
                                    props.taxInformation.entity.fatca
                                        ?.nonFinancialInstitutionType
                                ],
                        },

                        {
                            label: 'Is Entity not a tax resident',
                            value: props.taxInformation.entity.crs
                                ?.isEntityNotATaxResident,
                        },
                        {
                            label: 'Not a tax resident descriptiont',
                            value: props.taxInformation.entity.crs
                                ?.entityNotATaxResidentDescription,
                        },
                        {
                            label: 'Financial Institutions under CRS',
                            value:
                                props.taxInformation.entity.crs
                                    ?.financialInstitutionType &&
                                crsFinancialInstitutionTypeLabel[
                                    props.taxInformation.entity.crs
                                        ?.financialInstitutionType
                                ],
                        },
                        {
                            label: 'Non-Financial Institutions under CRS',
                            value:
                                props.taxInformation.entity.crs
                                    ?.nonFinancialInstitutionType &&
                                crsNonFinancialInstitutionTypeLabel[
                                    props.taxInformation.entity.crs
                                        ?.nonFinancialInstitutionType
                                ],
                        },
                        {
                            label: 'Controlling persons for entity',
                            value: props.taxInformation.entity
                                .controllingPersons.length,
                        },
                    ]
                        .filter((a) => a.value)
                        .map((a, i) => (
                            <div key={i}>
                                <strong>{a.label}: </strong>
                                {a.value}
                            </div>
                        ))}
            </div>
        </div>
    );
};

export default ReviewTaxDeclaration;
