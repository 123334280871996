import React from 'react';

import { noticeTypeLabels, SubscriptionNotice } from '@interfaces/Api';
import { ShareClass } from '@interfaces/Api';

import { Icons } from '@components/Atoms';

import { getFormattedCurrency } from '@helpers/isoCurrencies';
import { toCapitalizedWords } from '@helpers/ToCapitalizedWords';
import { useStorageTokenState } from '@contexts/StorageTokenContext';

export interface SubscriptionInvestmentReportsLifecycleProps {
    title?: string;
    lifecycleDocuments: SubscriptionNotice[] | undefined;
    shareClassFromReports?: ShareClass | undefined;
}

const SubscriptionInvestmentReportsLifeCycle: React.FC<
    SubscriptionInvestmentReportsLifecycleProps
> = (props: SubscriptionInvestmentReportsLifecycleProps) => {
    const message = 'No Life Cycle documents currently available';
    const { withToken } = useStorageTokenState();

    if (!props?.lifecycleDocuments)
        return (
            <div>
                <h1 className="text-base my-2">{message}</h1>
            </div>
        );

    return (
        <div id="documentsLifecycle" className="w-full mb-8 flex flex-col">
            <div className="overflow-x-auto sm:-mx-6 lg:-mx-8">
                {props.lifecycleDocuments &&
                    props.lifecycleDocuments.length > 0 && (
                        <div className="py-2 inline-block min-w-full sm:px-6 lg:px-8">
                            {props.title && (
                                <div className="py-4 border-b border-gray-200">
                                    <h2 className="text-xl">{props.title}</h2>
                                </div>
                            )}
                            <div className="my-6 overflow-hidden">
                                <table className="min-w-full bg-white">
                                    <thead className="bg-white text-left">
                                        <tr className="font-bold whitespace-nowrap border-b align-top">
                                            <th
                                                scope="col"
                                                className="p-3 w-full md:w-1/12"
                                            >
                                                Type
                                            </th>
                                            <th
                                                scope="col"
                                                className="p-3 w-full md:w-1/12"
                                            >
                                                Date
                                            </th>
                                            <th
                                                scope="col"
                                                className="p-3 w-full md:w-1/12"
                                            >
                                                Amount
                                            </th>
                                            <th
                                                scope="col"
                                                className="p-3 w-full md:w-1/12"
                                            >
                                                Due Date
                                            </th>
                                            <th
                                                scope="col"
                                                className="p-3 w-full md:w-1/12"
                                            >
                                                Documents
                                            </th>
                                            <th
                                                scope="col"
                                                className="p-3 w-full md:w-6/12"
                                            >
                                                Comments
                                            </th>
                                            <th
                                                scope="col"
                                                className="p-3 w-full md:w-1/12"
                                            >
                                                Uploaded By
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody className="bg-white text-left flex-1 sm:flex-none">
                                        {props.lifecycleDocuments &&
                                            props.lifecycleDocuments.map(
                                                (val, i: number) => (
                                                    <tr
                                                        key={`${i.toString()}-docs`}
                                                        className="transition duration-300 ease-in-out text-brand-navy whitespace-nowrap border-b align-top h-6 md:h-20 hover:bg-gray-100"
                                                    >
                                                        <td className="p-3 w-full md:w-1/12 text-sm">
                                                            {toCapitalizedWords(
                                                                noticeTypeLabels[
                                                                    val
                                                                        .noticeType
                                                                ] ||
                                                                    val.noticeType
                                                            )}
                                                        </td>

                                                        <td className="p-3 w-full md:w-1/12 text-sm">
                                                            {new Date(
                                                                String(
                                                                    val.uploadedAt
                                                                )
                                                            ).toLocaleDateString()}
                                                        </td>

                                                        <td className="p-3 w-full md:w-1/12 text-sm">
                                                            {val.amount
                                                                ? getFormattedCurrency(
                                                                      val.amount,
                                                                      props
                                                                          ?.shareClassFromReports
                                                                          ?.currency
                                                                  )
                                                                : ''}
                                                        </td>
                                                        <td className="p-3 w-full md:w-1/12 text-sm text-center">
                                                            {val.dueDate
                                                                ? new Date(
                                                                      val.dueDate
                                                                  ).toLocaleDateString()
                                                                : '-'}
                                                        </td>
                                                        <td className="p-3 w-full md:w-1/12 text-sm">
                                                            {val.documents?.map(
                                                                (
                                                                    document: any,
                                                                    i
                                                                ) => (
                                                                    <div
                                                                        key={`${i}-${document._id}`}
                                                                    >
                                                                        <a
                                                                            href={
                                                                                withToken(document.url)
                                                                            }
                                                                            target="_blank"
                                                                            rel="noreferrer"
                                                                        >
                                                                            {
                                                                                document?.label
                                                                            }{' '}
                                                                            <span className="inline-block top-1 relative ml-3">
                                                                                <Icons name="ArrowDownTrayIcon" />
                                                                            </span>
                                                                        </a>
                                                                    </div>
                                                                )
                                                            )}
                                                        </td>

                                                        <td className="p-3 w-full md:w-6/12 text-sm whitespace-pre-line">
                                                            {val.comment}
                                                        </td>

                                                        <td className="p-3 w-full md:w-1/12 text-sm">
                                                            {
                                                                val.uploadedBy
                                                                    ?.firstName
                                                            }{' '}
                                                            {
                                                                val.uploadedBy
                                                                    ?.surname
                                                            }
                                                        </td>
                                                    </tr>
                                                )
                                            )}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    )}
            </div>
        </div>
    );
};

export default SubscriptionInvestmentReportsLifeCycle;
