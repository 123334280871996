import { useTranslation } from 'react-i18next';
import { useRegisterInvestorState } from '../RegisterInvestor.context';

export const BankAccountsSummary = (): JSX.Element => {
    const { t } = useTranslation();
    const { data } = useRegisterInvestorState();

    return (
        <p>
            <span className="font-bold mr-2">{t('client.registration.summary.bank_accounts')}:</span>
            {data.individualDetails?.bankAccounts?.map((bankAccount) => (
                <div className="mr-2" key={bankAccount.IBAN}>
                    <p>{bankAccount.bankName}</p>
                    <p>{bankAccount.countryCurrencyCode}</p>
                    <p>{bankAccount.countryCodeOfAccount}</p>
                    <p>{bankAccount.IBAN}</p>
                </div>
            ))}
        </p>
    );
};
