import { useEndInvestorProfileState } from '@contexts/EndInvestorProfileContext';
import { FormSelect } from '@components/Molecules';

import { isEndInvestorNomineeAccount } from '@helpers/EndInvestorHelper';
import { EndInvestorProfileResponse } from '@interfaces/Api';
import { useMemo } from 'react';
import { useRegisterInvestorState } from '../RegisterInvestor.context';

export const NomineeSelection = () => {
    const { endInvestorProfiles } = useEndInvestorProfileState();

    const { data } = useRegisterInvestorState();
    const nomineeOptions = useMemo(() => {
        return endInvestorProfiles
            .filter(isEndInvestorNomineeAccount)
            .filter((nomineeProfile: EndInvestorProfileResponse) => {
                return (
                    nomineeProfile.bookingCentreIds &&
                    nomineeProfile.bookingCentreIds.some(
                        (bookingCentre) =>
                            data.bookingCentreIds &&
                            data.bookingCentreIds.includes(bookingCentre._id)
                    )
                );
            })
            .map((nomineeProfile: EndInvestorProfileResponse) => ({
                label: nomineeProfile?.businessEntityDetails?.companyName || '',
                value: nomineeProfile._id,
            }));
    }, [endInvestorProfiles, data.bookingCentreIds]);

    return (
        <div className="mt-4">
            <FormSelect
                label="Nominees"
                name="nomineeEndInvestorProfileId"
                optionsData={nomineeOptions}
                value={data.nomineeEndInvestorProfileId}
            />
        </div>
    );
};
