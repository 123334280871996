import { useNavigate, useSearchParams } from 'react-router-dom';

import { useAuthState } from '@contexts/AuthContext';
import { useDocusignState } from '@contexts/DocusignContext';
import { useMountEffect } from '@hooks/useMountEffect';
import { notifySuccess } from '@helpers/toastrHelper';

const DocuSignCallback = () => {
    const { currentUser } = useAuthState();
    const [searchParams] = useSearchParams();
    const { recordDocusignConsent } = useDocusignState();
    const navigate = useNavigate();

    const code = searchParams.get('code') as string;

    useMountEffect(() => {
        if (code) {
            const body = {
                consentDate: new Date(),
                userId: currentUser?.user?._id,
            };
            recordDocusignConsent(body).then((res: any) => {
                notifySuccess('Recorded Docusign consent');
                navigate('/admin/application-settings');
            });
        }
    });

    return <div>Redirecting...</div>;
};

export default DocuSignCallback;
