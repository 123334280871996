import { FC } from 'react';
import { FieldArray, useField } from 'formik';
import { Button } from '@components/Atoms';
import {
    ProductOpenEndedInformation,
    ShareClass,
    ShareClassOpenEndedInformation,
} from '@interfaces/Api/ProductResponse';
import { FormInputBox } from '@components/Molecules';

interface SoftLockUpsProps {
    shareClassIndex?: number;
}

export const SoftLockUps: FC<SoftLockUpsProps> = (props) => {
    const { shareClassIndex } = props;
    const FIELD_NAME = `shareClasses[${shareClassIndex}].openEndedInformation.softLockUpDetails`;
    const [field, meta] = useField<
        ShareClassOpenEndedInformation['softLockUpDetails']
    >({
        name: FIELD_NAME,
    });

    return (
        <div>
            <FieldArray
                name={FIELD_NAME}
                render={(arrayHelpers) => (
                    <div>
                        {field.value &&
                            field.value?.map((_, index: number) => {
                                return (
                                    <div key={index}>
                                        <div className="flex justify-between">
                                            <label>Period {index + 1}</label>
                                            <Button
                                                label={`Remove Period ${
                                                    index + 1
                                                }`}
                                                type="button"
                                                onClick={() =>
                                                    arrayHelpers.remove(index)
                                                }
                                            />
                                        </div>
                                        <div className="my-4">
                                            <div className="relative w-full mb-3">
                                                <FormInputBox
                                                    name={`${FIELD_NAME}[${index}].lengthOfTimeInMonths`}
                                                    label="Length of time (months)"
                                                    placeholder="Length of time (months)"
                                                    type="number"
                                                />
                                            </div>
                                        </div>
                                        <div className="my-4">
                                            <div className="relative w-full mb-3">
                                                <FormInputBox
                                                    name={`${FIELD_NAME}[${index}].redemptionFeePercentage`}
                                                    label="Redemption fee (%)"
                                                    placeholder="Redemption fee (%)"
                                                    type="number"
                                                />
                                            </div>
                                        </div>
                                    </div>
                                );
                            })}

                        {meta.error && (
                            <div className="flex content-start text-red-500 text-sm mb-4">
                                {meta.error}
                            </div>
                        )}
                        <div>
                            <Button
                                label="Add additional period"
                                type="button"
                                onClick={() => {
                                    arrayHelpers.push({
                                        lengthOfTimeInMonths: 0,
                                        redemptionFeePercentage: 0,
                                    });
                                }}
                            />
                        </div>
                    </div>
                )}
            />
        </div>
    );
};
