import {
    ConfigurationResponse,
    ConfigurationRequestBody,
} from '@interfaces/Api';

import { Http } from '../libs/http';
import { BaseUrl } from '@helpers/Environment';

const API_CONFIGURATION_URL = `${BaseUrl}/configuration`;

export const configurationGet = async () =>
    Http.get<ConfigurationResponse>(API_CONFIGURATION_URL);

export const configurationUpdate = async (body: ConfigurationRequestBody) =>
    Http.put<null>(API_CONFIGURATION_URL, body);
