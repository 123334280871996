import React, { useEffect, useState, FC } from 'react';

import { NotificationService } from '@services/NotificationService';
import {
    ModalInterface,
    ModalService,
    UPDATE_MODAL,
} from '@services/ModalService';

import { Modal } from '@components/Molecules';
import { Button } from '@components/Atoms';

export interface ModalWrapperProps {
    msgPrefix?: string;
}

export const ModalWrapper: FC<ModalWrapperProps> = ({ msgPrefix = 'main' }) => {
    const [modalConfig, setModalConfig] = useState<
        ModalInterface | undefined
    >();

    useEffect(() => {
        const updateModalConfig = (newConfig: ModalInterface) => {
            setModalConfig(newConfig);
        };

        NotificationService.sub(
            `${msgPrefix}${UPDATE_MODAL}`,
            updateModalConfig
        );

        return () => {
            NotificationService.remove(
                `${msgPrefix}${UPDATE_MODAL}`,
                updateModalConfig
            );
        };
    }, [msgPrefix]);

    if (!modalConfig) {
        return <></>;
    }

    const {
        show,
        title,
        component,
        showFooter,
        cancelLabel,
        confirmLabel,
        cancelCallback,
        confirmationCallback,
        width,
        description,
        showHeader,
        bodyClassName,
        bodyStyle,
        hideCancelBtn
    } = modalConfig;

    const instance = ModalService.getInstance(msgPrefix);

    return (
        <Modal show={show} width={width ? width : "w-1/2 lg:w-5/12 xl:w-4/12"}>
            {showHeader && (
                <Modal.Header>
                    {title}
                    {description && <div>{description}</div>}
                </Modal.Header>
            )}
            <Modal.Body className={bodyClassName} style={bodyStyle}>{component}</Modal.Body>
            {showFooter && (
                <Modal.Footer>
                    {hideCancelBtn ? <div></div> : <Button
                        buttonType="secondary"
                        onClick={() => {
                            instance.hideModal();
                            cancelCallback();
                        }}
                        label={cancelLabel}
                    ></Button>}

                    <Button
                        label={confirmLabel}
                        onClick={() => {
                            if (confirmationCallback) {
                                confirmationCallback();
                            } else {
                                instance.hideModal();
                            }
                        }}
                    ></Button>
                </Modal.Footer>
            )}
        </Modal>
    );
};
