import { useRegisterInvestorState } from '../RegisterInvestor.context';
import { useEndInvestorProfileState } from '@contexts/EndInvestorProfileContext';

export const NomineeSummary = (): JSX.Element => {
    const { data } = useRegisterInvestorState();
    const { endInvestorProfiles } = useEndInvestorProfileState();
    const profile = endInvestorProfiles.find(
        (profile) => profile._id === data['nomineeEndInvestorProfileId']
    );

    return (
        <p>
            <span className="font-bold mr-4">Nominee:</span>
            {profile?.businessEntityDetails?.companyName}
        </p>
    );
};
