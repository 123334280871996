import React from 'react';
import {
    Field,
    ErrorMessage,
    FieldHookConfig,
    FieldInputProps,
    FieldMetaProps,
} from 'formik';
import classNames from 'classnames';

import { RadioOption } from '@interfaces/InterfaceFormsProps';
import { InputBox } from '@components/Atoms';

export interface RadioGroupProps {
    name: string;
    label?: string;
    disabled?: boolean;
    onClick?: () => void;
    onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
    onBlur?: any;
    value?: any;
    optionsData?: RadioOption<any>[];
    isHorizontal?: boolean;
    className?: string;
    justifyContent?: string;
    positionLeft?: boolean;
    dataTestId?: string;
}

const FormRadioGroup = (props: RadioGroupProps & FieldHookConfig<any>) => {
    const optionsLength = props.optionsData?.length;
    const { justifyContent, className, positionLeft } = props;

    return (
        <>
            {props.label && (
                <label htmlFor={props.id} className="-mb-4">
                    {props.label}
                </label>
            )}
            <div
                className={classNames(
                    'relative w-full border border-gray-300 rounded-xl',
                    props.isHorizontal
                        ? 'lg:flex lg:flex-row text-center m-8 p-3 w-full xl:w-7/12'
                        : '',
                    justifyContent ? justifyContent : 'justify-between',
                    positionLeft ? '' : 'mx-auto',
                    className
                )}
            >
                {props.optionsData?.length === 0 && (
                    <p>Sorry! No data loaded!</p>
                )}

                {props.optionsData?.map((option, i) => (
                    <div
                        className={`${
                            props.isHorizontal ? 'p-3 lg:border-none' : 'p-3'
                        } ${
                            optionsLength === i + 1
                                ? ''
                                : 'border-b border-gray-300 '
                        } `}
                        role="group"
                        aria-labelledby={props.name}
                        key={`${i + option.value}`}
                    >
                        <label className="block text-gray-800 cursor-pointer">
                            <Field name={props.name} value={option.value}>
                                {({
                                    field,
                                }: {
                                    field: FieldInputProps<string>;
                                    meta: FieldMetaProps<string>;
                                }) => (
                                    <InputBox
                                        {...field}
                                        type="radio"
                                        id={props.id}
                                        value={option.value}
                                        checked={
                                            String(field.value) ==
                                                option.value ||
                                            props.value == option.value
                                        }
                                        disabled={
                                            props.disabled || option.disabled
                                        }
                                        onChange={
                                            props.onChange || field.onChange
                                        }
                                        onBlur={props.onBlur}
                                        dataTestId={props.dataTestId}
                                    />
                                )}
                            </Field>

                            <span className="ml-2">{option.label}</span>

                            <p className="text-sm pl-6 whitespace-pre-wrap">
                                {option.abstract}
                            </p>
                        </label>
                    </div>
                ))}
            </div>
            <div className="flex content-start text-red-500">
                <ErrorMessage
                    name={props.name}
                    component="radio"
                    className="text-red-500 justify-self-start items-left text-left"
                    render={(msg) => <div>{msg}</div>}
                />
            </div>
        </>
    );
};

export default FormRadioGroup;
