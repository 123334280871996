/**
 * Added the below two lines because the Document viewer wasn't working
 * when a prod build was run. The following comment from comment which
 * recommended the below lines solved it:
 * https://github.com/wojtekmaj/react-pdf/issues/677#issuecomment-944216379
 */
import { useState } from 'react';
import { pdfjs, Document, Page } from 'react-pdf';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

import { Button } from '@components/Atoms';

interface PdfViewerProps {
    fileURL: string;
    width?: number;
    compact?: boolean;
    enableControls?: boolean;
    align?: 'center' | 'left' | 'right';
}

const PdfViewer: React.FC<PdfViewerProps> = (props) => {
    const { fileURL, width, compact, align, enableControls = true } = props;

    const [numPages, setNumPages] = useState(null);
    const [pageNumber, setPageNumber] = useState(1);

    const handleOnDocumentLoadSuccess = (props: any) => {
        setNumPages(props.numPages);
    };

    const handleOnNext = () => setPageNumber((prev) => prev + 1);
    const handleOnPrev = () => setPageNumber((prev) => prev - 1);

    const customWidth = compact ? 'w-11/12' : '';

    const getAlignment = () => {
        switch (align) {
            case 'center':
            default:
                return 'items-center';
            case 'left':
                return 'items-start';
            case 'right':
                return 'items-end';
        }
    };

    const customMargin = compact ? '' : 'mx-auto';
    const pages = Array.from(Array(numPages).keys());

    return (
        <div
            className={`${customWidth} ${customMargin} flex flex-col ${getAlignment()}`}
        >
            <Document
                className="flex flex-col items-center w-full"
                renderMode="canvas"
                file={fileURL}
                onLoadError={(error) => {
                    console.log('[PdfViewer.tsx], onLoadError', error);
                }}
                onLoadSuccess={handleOnDocumentLoadSuccess}
                onSourceSuccess={() => null}
            >
                {enableControls ? (
                    <Page
                        className="pdf-page"
                        pageNumber={pageNumber}
                        width={width}
                        scale={1}
                    />
                ) : (
                    pages?.map((page: any) => (
                        <Page
                            key={page}
                            className="pdf-page"
                            pageNumber={page + 1}
                            width={width}
                            scale={2}
                        />
                    ))
                )}

                {enableControls && numPages && numPages > 1 && (
                    <div className="flex items-center justify-around">
                        <Button
                            disabled={pageNumber === 1}
                            onClick={handleOnPrev}
                            label="<"
                            buttonType="secondary"
                        />

                        <p className="mx-4">
                            Page {pageNumber} of {numPages}
                        </p>
                        <Button
                            disabled={pageNumber === numPages}
                            onClick={handleOnNext}
                            label=">"
                            buttonType="secondary"
                        />
                    </div>
                )}
            </Document>
        </div>
    );
};

export default PdfViewer;
