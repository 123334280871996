import React, {
    createContext,
    useState,
    useEffect,
    useContext,
    useCallback,
} from 'react';
import { useNavigate } from 'react-router';

import {
    AuthRoleEnum,
    EndInvestorProfileResponse,
    EndInvestorProfileRequestBody,
} from '@interfaces/Api';
import { SourceFundsProps } from '@interfaces/InterfaceFormsClientProps';
import { EndInvestorProfilePortfolio } from '@interfaces/EndInvestorProfilePortfolio';
import { InvestorJurisdiction } from '@interfaces/Api/InvestorJurisdictionsResponse';

import {
    EndInvestorProfilesDelete,
    EndInvestorProfileGet,
    EndInvestorProfilesGetAll,
    EndInvestorProfilesPost,
    EndInvestorProfilePortfolioGet,
    EndInvestorProfileDocumentsGet,
    EndInvestorProfilesPut,
} from '@api/EndInvestorProfiles';
import { InvestorJurisdictionsGetAll } from '@api/InvestorJurisdictions';
import { sourceOfFundsGet } from '@api/SourceOfFunds';

import { notifyError } from '@helpers/toastrHelper';
import { downloadFileOnTheClient } from '@helpers/File.helper';

import { useConfigurationState } from '@contexts/ConfigurationContext';

import { useAuthState } from './AuthContext';

export interface EndInvestorProfileStateContextProps {
    endInvestorProfiles: EndInvestorProfileResponse[];
    loadingEndInvestorProfiles: boolean;
    investorJurisdictions: InvestorJurisdiction[];
    sourceFunds: SourceFundsProps[];
    sendEndInvestorData: (
        body: EndInvestorProfileRequestBody,
        systemUserId: string | undefined
    ) => Promise<EndInvestorProfileResponse | undefined>;
    updateEndInvestorData: (
        body: EndInvestorProfileRequestBody,
        endInvestorProfileId: string | undefined
    ) => Promise<EndInvestorProfileResponse | undefined>;
    getEndInvestorProfile: (
        id: string
    ) => Promise<EndInvestorProfileResponse | undefined>;
    getEndInvestorProfilePortfolio: (
        id: string
    ) => Promise<EndInvestorProfilePortfolio | undefined>;
    downloadInvestorProfileDocuments: (
        endInvestorProfileId: string,
        subscriptionId?: string
    ) => Promise<any>; // TODO: change this any type to something that makes sense
    deleteEndInvestorProfile: (id: string) => Promise<void>;
    getAllEndInvestorProfileData: () => Promise<void>;
}

const endInvestorDefaultState: EndInvestorProfileStateContextProps = {
    endInvestorProfiles: [],
    loadingEndInvestorProfiles: true,
    investorJurisdictions: [],
    sourceFunds: [],
    getEndInvestorProfile: async (endInvestorProfileId: string) => undefined,
    getEndInvestorProfilePortfolio: async (endInvestorProfileId: string) =>
        undefined,
    downloadInvestorProfileDocuments: async (
        endInvestorProfileId: string,
        subscriptionId?: string
    ) => undefined,
    sendEndInvestorData: async () => undefined,
    updateEndInvestorData: async () => undefined,
    deleteEndInvestorProfile: async (endInvestorProfileId: string) => {},
    getAllEndInvestorProfileData: async () => {},
};

export const EndInvestorStateContext =
    createContext<EndInvestorProfileStateContextProps>(endInvestorDefaultState);

export const useEndInvestorProfileState = () => {
    return useContext(EndInvestorStateContext);
};

export const EndInvestorProfileProvider: React.FC = ({
    children,
}: React.PropsWithChildren<{}>) => {
    const navigate = useNavigate();
    const { preferences } = useConfigurationState();
    const { currentUser } = useAuthState();

    const [endInvestorProfiles, setEndInvestorProfiles] = useState(
        endInvestorDefaultState.endInvestorProfiles
    );
    const [loadingEndInvestorProfiles, setLoadingEndInvestorProfiles] =
        useState<boolean>(true);
    const [investorJurisdictions, setInvestorJurisdictions] = useState(
        endInvestorDefaultState.investorJurisdictions
    );

    const [sourceFunds, setSourceFunds] = useState(
        endInvestorDefaultState.sourceFunds
    );

    const getAllEndInvestorProfileData = React.useCallback(async () => {
        if (!preferences) {
            return;
        }

        try {
            let adviserSystemUserId;

            if (
                currentUser?.user.role === AuthRoleEnum.relationshipManager &&
                preferences?.feature.accessControl
                    ?.relationshipManagersCanSeeOnlyTheirClients
            ) {
                adviserSystemUserId = currentUser.user._id;
            }

            const result = await EndInvestorProfilesGetAll(adviserSystemUserId);

            setEndInvestorProfiles(result);
            setLoadingEndInvestorProfiles(false);
        } catch (error: any) {
            console.log('Error occurred on Get All Investor data!', error);
        }
    }, [preferences, currentUser]);

    const getAllInvestorJurisdictionsData = React.useCallback(async () => {
        try {
            const result = await InvestorJurisdictionsGetAll();
            setInvestorJurisdictions(result);
        } catch (error: any) {
            console.log('Error occurred on Get All Investor Type data!', error);
        }
    }, []);

    const getAllSourceFundsData = useCallback(async () => {
        try {
            const result = await sourceOfFundsGet();
            setSourceFunds(result);
        } catch (error: any) {
            console.log(
                'Error occurred on Get All Source of Funds data!',
                error
            );
        }
    }, []);

    const getEndInvestorProfile = async (endInvestorProfileId: string) => {
        try {
            const result = await EndInvestorProfileGet(endInvestorProfileId);
            return result;
        } catch (error) {
            console.error(
                'Cannot find end investor with id:' + endInvestorProfileId,
                error
            );
            navigate('not-found');
        }
    };

    const getEndInvestorProfilePortfolio = async (
        endInvestorProfileId: string
    ) => {
        try {
            const result = await EndInvestorProfilePortfolioGet(
                endInvestorProfileId
            );
            return result;
        } catch (error) {
            console.log('!', error);
        }
    };

    const downloadInvestorProfileDocuments = async (
        endInvestorProfileId: string,
        subscriptionId?: string
    ) => {
        try {
            const response = await EndInvestorProfileDocumentsGet(
                endInvestorProfileId,
                subscriptionId
            );
            downloadFileOnTheClient(response);
        } catch (err) {
            notifyError('Could not download document pack');
            console.error('Could not download document pack:', err);
        }
    };

    const sendEndInvestorData = async (
        body: EndInvestorProfileRequestBody,
        systemUserId: string | undefined
    ): Promise<any> => {
        const data = await EndInvestorProfilesPost(body, systemUserId);

        if (data) {
            setEndInvestorProfiles((val) => [
                ...val,
                data as EndInvestorProfileResponse,
            ]);
            return data;
        }
    };

    const updateEndInvestorData = async (
        body: EndInvestorProfileRequestBody,
        endInvestorProfileId: string | undefined
    ): Promise<any> => {
        const data: EndInvestorProfileResponse = await EndInvestorProfilesPut(
            body,
            endInvestorProfileId
        );

        if (data) {
            setEndInvestorProfiles((val) => {
                const matchedIndex = val.findIndex(
                    ({ _id }) => _id === endInvestorProfileId
                );

                if (matchedIndex !== -1) {
                    val[matchedIndex] = data;
                }

                return [...val];
            });
            return data;
        }
    };

    const deleteEndInvestorProfile = async (endInvestorProfileId: string) => {
        await EndInvestorProfilesDelete(endInvestorProfileId);
    };

    useEffect(() => {
        getAllEndInvestorProfileData();
        getAllInvestorJurisdictionsData();
        getAllSourceFundsData();
    }, [
        getAllEndInvestorProfileData,
        getAllInvestorJurisdictionsData,
        getAllSourceFundsData,
    ]);

    return (
        <EndInvestorStateContext.Provider
            value={{
                loadingEndInvestorProfiles,
                endInvestorProfiles,
                investorJurisdictions,
                sourceFunds,
                getEndInvestorProfile,
                getEndInvestorProfilePortfolio,
                downloadInvestorProfileDocuments,
                sendEndInvestorData,
                updateEndInvestorData,
                deleteEndInvestorProfile,
                getAllEndInvestorProfileData,
            }}
        >
            {children}
        </EndInvestorStateContext.Provider>
    );
};
