import { Dispatch, SetStateAction, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import {
    AuthRoleEnum,
    EndInvestorPortfolioItem,
    EndInvestorProfileResponse,
    SubscriptionResponse,
} from '@interfaces/Api';
import { useAuthState } from '@contexts/AuthContext';
import { useEndInvestorProfile } from '@hooks/useEndInvestorProfile';
import { useConfigurationState } from '@contexts/ConfigurationContext';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useSubscriptionsAndTemplates } from '@hooks/useSubscriptionsAndTemplates';
import { useSubscriptionsStore } from '@stores/Subscriptions/Subscriptions.store';

export type PortfolioPillOptionType =
    | 'Portfolio'
    | 'ClientDetails'
    | 'InvestmentReportsDetails'
    | 'Subscriptions'
    | 'UnderlyingClients'
    | 'SubscriptionTemplates';

export const usePortfolioPills = (
    setSelectedPill: Dispatch<
        SetStateAction<PortfolioPillOptionType | undefined>
    >,
    portfolioData?: EndInvestorPortfolioItem[]
) => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { preferences } = useConfigurationState();
    const { currentUser } = useAuthState();
    const { endInvestorProfile, endInvestorProfileId } =
        useEndInvestorProfile();

    const [searchParams, setSearchParams] = useSearchParams();
    const { subscriptions, isLoading } = useSubscriptionsStore();

    const { list: subscriptionsTemplates } =
        useSubscriptionsAndTemplates<SubscriptionResponse>(
            endInvestorProfileId!,
            'subscriptions-templates'
        );

    const pillOptions = useMemo(() => {
        const options: {
            label: string;
            value: PortfolioPillOptionType;
            isVisible?: boolean;
        }[] = [
            {
                label: t('client.pill_selector.portfolio'),
                value: 'Portfolio',
                isVisible: preferences?.feature.clientView.enablePortfolioView,
            },
            {
                label: t('client.pill_selector.subscriptions'),
                value: 'Subscriptions',
                isVisible: true,
            },
            {
                label: t('client.pill_selector.investment_reports'),
                value: 'InvestmentReportsDetails',
                isVisible: currentUser?.user.role !== AuthRoleEnum.fundAdmin,
            },
            {
                label: t('client.pill_selector.underlying_clients'),
                value: 'UnderlyingClients',
                isVisible:
                    endInvestorProfile?.endInvestorType ===
                    EndInvestorProfileResponse.EndInvestorType.nomineeEntity,
            },
            {
                label: t('client.pill_selector.subscription_templates'),
                value: 'SubscriptionTemplates',
                isVisible: subscriptionsTemplates.length > 0,
            },
        ];

        return options.filter((option) => option.isVisible);
    }, [
        preferences?.feature.clientView.enablePortfolioView,
        currentUser?.user.role,
        endInvestorProfile?.endInvestorType,
        subscriptionsTemplates.length,
    ]);

    const handlePillSelection = () => {
        const pillValueFromUrl = searchParams.get(
            'section'
        ) as PortfolioPillOptionType;

        const fromLogin = searchParams.get('from') === 'login';

        const isNotPortfolioData = portfolioData && portfolioData?.length < 1;

        let pill = pillOptions[0].value;

        if (pillValueFromUrl) pill = pillValueFromUrl;

        if (isNotPortfolioData && subscriptions.length > 0 && !isLoading) {
            pill = 'Subscriptions';
        }

        if (pillOptions.some((p) => p.value === pill)) {
            if (fromLogin && !isNotPortfolioData) {
                navigate('/products/');
            } else {
                setSelectedPill(pill);
                setSearchParams({ section: pill });
            }
        }
    };

    useEffect(() => {
        handlePillSelection();
    }, [portfolioData, subscriptions]);

    return { pillOptions };
};
