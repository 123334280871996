import { useEffect, useState } from 'react';

interface CountDown {
    setEndDate: (endDate?: Date) => void;
    endDate?: Date;
    days: number | null;
    hours: number | null;
    minutes: number | null;
    seconds: number | null;
}

const useCountdown = (): CountDown => {
    const [endDate, _setEndDate] = useState<Date>();
    const [enabled, setEnabled] = useState<boolean>();
    const [countDown, setCountDown] = useState<number>();

    useEffect(() => {
        const updateCountDown = () => {
            if (endDate) {
                const timeDifference = endDate.getTime() - new Date().getTime();

                if (timeDifference < 0) {
                    setEnabled(false);
                    _setEndDate(undefined);
                } else {
                    setCountDown(timeDifference);
                }
            } else {
                console.warn('endDate not set');
            }
        };

        let interval: NodeJS.Timeout;
        if (enabled) {
            updateCountDown();
            interval = setInterval(() => updateCountDown(), 1000);
        }

        return () => clearInterval(interval);
    }, [endDate, enabled]);

    const setEndDate = (endDate?: Date) => {
        if (endDate) {
            setEnabled(true);
            _setEndDate(endDate);
        } else {
            setEnabled(false);
            _setEndDate(undefined);
        }
    };

    return { ...getReturnValues(countDown), setEndDate, endDate };
};

const getReturnValues = (countDown?: number) => {
    if (!countDown) {
        return { days: null, hours: null, minutes: null, seconds: null };
    }
    // calculate time left
    const days = Math.floor(countDown / (1000 * 60 * 60 * 24));
    const hours = Math.floor(
        (countDown % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
    );
    const minutes = Math.floor((countDown % (1000 * 60 * 60)) / (1000 * 60));
    const seconds = Math.floor((countDown % (1000 * 60)) / 1000);
    return { days, hours, minutes, seconds };
};

export { useCountdown };
