/**
 * @export
 * @enum {string}
 */
export enum SubscriptionStatusEnum {
    inProgress = 'inProgress',
    readyForReview = 'readyForReview',
    approved = 'approved',
    rejected = 'rejected',
    cancelled = 'cancelled',
}

export const getSubscriptionStatusTypeLabels = (t: (key) => string, subscriptionStatusType: SubscriptionStatusEnum): string => {
    return {
        [SubscriptionStatusEnum.inProgress]: t('subscription_status_type.in_progress'),
        [SubscriptionStatusEnum.readyForReview]: t('subscription_status_type.ready_for_review'),
        [SubscriptionStatusEnum.approved]: t('subscription_status_type.approved'),
        [SubscriptionStatusEnum.rejected]: t('subscription_status_type.rejected'),
        [SubscriptionStatusEnum.cancelled]: t('subscription_status_type.cancelled'),
    }[subscriptionStatusType];
}

export enum SubscriptionFiltersEnum {
    readyForReview = 'readyForReview',
    readyToApprove = 'readyToApprove',
    awaitingSignatures = 'awaitingSignatures',
}

export const subscriptionFilterTypeLabels: { [key: string]: string } = {
    readyForReview: 'Ready For Review',
    readyToApprove: 'Ready to Approve',
    awaitingSignatures: 'Awaiting Signatures',
};
