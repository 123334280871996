import { MouseEventHandler } from 'react';
import classNames from 'classnames';
import { Loading } from '@components/Atoms';

interface ButtonProps {
    label?: string;
    buttonType?:
        | 'primary'
        | 'secondary'
        | 'preview'
        | 'link'
        | 'pill'
        | 'error';
    type?: 'submit' | 'reset' | 'button' | undefined;
    id?: string;
    startIcon?: React.ReactNode;
    endIcon?: React.ReactNode;
    hasIconOnly?: boolean;
    disabled?: boolean;
    className?: string;
    onClick?: MouseEventHandler<HTMLButtonElement>;
    dataTestId?: string;
    size?: 'small' | 'medium' | 'large';
    tabIndex?: number;
    isLoading?: boolean;
    isHoverDisabled?: boolean;
}

const Button: React.FC<ButtonProps> = (props) => {
    const {
        id,
        dataTestId,
        onClick,
        disabled,
        type,
        startIcon,
        endIcon,
        hasIconOnly,
        label,
        children,
        tabIndex,
        isLoading = false,
        isHoverDisabled = false,
    } = props;

    const buttonStyle = classNames(
        `font-regular text-sm xl:text-base leading-4`,
        props.className,
        {
            'bg-logo-blue text-white': props.buttonType === 'primary',
            'bg-white border border-zinc-300 hover:border-logo-blue-900':
                props.buttonType === 'secondary',
            'bg-gold-300 border border-zinc-300 hover:border-logo-blue-900':
                props.buttonType === 'preview',
            'rounded-full ': props.buttonType !== 'link',
            'border inline-flex flex justify-center text-sm rounded-full py-2':
                props.buttonType === 'pill',
            'hover:bg-logo-blue-900':
                !isHoverDisabled && props.buttonType === 'primary',
            'bg-red-500 text-white hover:bg-red-400':
                props.buttonType === 'error',
        },
        {
            'py-1 px-1': props.size === 'small',
            'py-1 px-2 lg:px-5': props.size === 'medium',
            'py-3 px-3 lg:px-5': props.size === 'large',
        },
        {
            'bg-logo-grey hover:bg-logo-grey cursor-not-allowed':
                props.disabled,
        }
    );

    return (
        <button
            id={id}
            data-testid={dataTestId}
            onClick={onClick}
            disabled={disabled || isLoading}
            className={!hasIconOnly ? buttonStyle : props.className}
            type={type}
            tabIndex={tabIndex}
        >
            {hasIconOnly && startIcon ? (
                startIcon
            ) : (
                <div className="flex items-center justify-center">
                    {startIcon && <div className="mr-2">{startIcon}</div>}

                    {label}
                    {children}

                    {endIcon && !isLoading && (
                        <div className="ml-2">{endIcon}</div>
                    )}
                    {isLoading && (
                        <div className="ml-2">
                            <Loading />
                        </div>
                    )}
                </div>
            )}
        </button>
    );
};

Button.defaultProps = {
    type: 'button',
    buttonType: 'primary',
    size: 'medium',
};

export default Button;
