import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { t } from 'i18next';

import { useSystemUsers } from '@stores/SystemUsers/useSystemUsers';
import { useEndInvestorProfileState } from '@contexts/EndInvestorProfileContext';
import { useAuthState } from '@contexts/AuthContext';

import { AuthPermissionEnum, SystemUserResponse } from '@interfaces/Api';

import { getEndInvestorName } from '@helpers/EndInvestorHelper';

import { BasicBreadcrumbs } from '@components/Atoms';
import { ActionsMenu, ReviewFrame, ToggleSwitch } from '@components/Molecules';
import { mainLayoutWithBreadcrumbsConfig } from '@components/Layout';

import ReviewClientPersonalDetails from './ReviewClientPersonalDetails';
import ReviewClientContactDetails from './ReviewClientContactDetails';
import ReviewClientAddressesDetails from './ReviewClientAddressesDetails';
import ReviewClientBankDetails from './ReviewClientBankDetails';
import ReviewClientBusinessDetails from './ReviewClientBusinessDetails';
import ReviewClientBusinessRelationshipDetails from './ReviewClientBusinessRelationshipDetails';
import ReviewClientKYCDetails from './ReviewClientKYCDetails';

import EndInvestorNameHeadingComponent from './endInvestorHeadingComponent';

const ClientDetailsBreadCrumbs = () => {
    const params = useParams();
    const { endInvestorProfiles } = useEndInvestorProfileState();
    const endInvestorProfile = endInvestorProfiles.find(
        ({ _id }) => _id === params.endInvestorProfileId
    );

    return (
        <BasicBreadcrumbs
            items={[
                {
                    displayText: t('navbar.clients'),
                    navigationUrl: '/clients',
                },
                {
                    displayText: getEndInvestorName(endInvestorProfile),
                    navigationUrl: `/clients/${endInvestorProfile?._id}?section=Portfolio`,
                },
                {
                    displayText: 'Account Details',
                    navigationUrl: '',
                },
            ]}
        />
    );
};

const ClientDetails: React.FC = () => {
    const params = useParams();
    const { endInvestorProfiles } = useEndInvestorProfileState();
    const { hasPermissions } = useAuthState();

    const endInvestorProfile = endInvestorProfiles.find(
        ({ _id }) => _id === params.endInvestorProfileId
    );
    const [isExpanded, setIsExpanded] = useState<boolean>(false);

    const { systemUsers } = useSystemUsers();
    const navigate = useNavigate();

    const [linkedSystemUser, setLinkedSystemUser] =
        useState<SystemUserResponse>();

    useEffect(() => {
        if (endInvestorProfile) {
            setLinkedSystemUser(
                systemUsers.find(({ endInvestorProfileIds }) =>
                    endInvestorProfileIds?.includes(endInvestorProfile._id)
                )
            );
        }
    }, [endInvestorProfile, systemUsers]);

    return (
        <div className="mb-8">
            <div className="flex items-center justify-between">
                <EndInvestorNameHeadingComponent
                    endInvestorProfile={endInvestorProfile}
                />
                <div className="flex my-2 justify-end">
                    <ActionsMenu
                        actionItems={[
                            {
                                label: 'Investments',
                                onClick: () =>
                                    navigate(
                                        `/clients/${endInvestorProfile?._id}?section=Portfolio`
                                    ),
                            },
                            {
                                label: 'Edit',
                                show: hasPermissions([
                                    AuthPermissionEnum.updateEndInvestorProfile,
                                ]),
                                onClick: () =>
                                    navigate(
                                        `/clients/edit-investor/${endInvestorProfile?._id}`
                                    ),
                            },
                        ]}
                    />
                </div>
            </div>

            <div className="flex my-2 w-full justify-end">
                <ToggleSwitch
                    title={t('ui.controls.show_hide_all')}
                    checked={isExpanded}
                    onChange={(e) => setIsExpanded(!isExpanded)}
                />
            </div>
            <div className="my-2 w-full">
                <ReviewFrame
                    title="Personal Details"
                    className="-my-4"
                    expanded={isExpanded}
                >
                    <ReviewClientPersonalDetails data={endInvestorProfile} />
                </ReviewFrame>

                {endInvestorProfile?.endInvestorType !== 'individual' ? (
                    <ReviewFrame
                        title="Business Entity Details"
                        className="-my-4"
                        expanded={isExpanded}
                    >
                        <ReviewClientBusinessDetails
                            data={endInvestorProfile}
                        />
                    </ReviewFrame>
                ) : null}

                <ReviewFrame
                    title="Contact Details"
                    className="-my-4"
                    expanded={isExpanded}
                >
                    <ReviewClientContactDetails
                        data={endInvestorProfile}
                        linkedSystemUser={linkedSystemUser}
                    />
                </ReviewFrame>

                <ReviewFrame
                    title="Address Details"
                    className="-my-4"
                    expanded={isExpanded}
                >
                    <ReviewClientAddressesDetails data={endInvestorProfile} />
                </ReviewFrame>

                <ReviewFrame
                    title="Bank Details"
                    className="-my-4"
                    expanded={isExpanded}
                >
                    <ReviewClientBankDetails data={endInvestorProfile} />
                </ReviewFrame>

                <ReviewFrame
                    title="Business Relationship Details"
                    className="-my-4"
                    expanded={isExpanded}
                >
                    <ReviewClientBusinessRelationshipDetails
                        data={endInvestorProfile}
                    />
                </ReviewFrame>

                <ReviewFrame
                    title="KYC Details"
                    className="-my-4"
                    expanded={isExpanded}
                >
                    <ReviewClientKYCDetails data={endInvestorProfile} />
                </ReviewFrame>
            </div>
        </div>
    );
};

export default mainLayoutWithBreadcrumbsConfig(
    ClientDetails,
    undefined,
    <ClientDetailsBreadCrumbs />
);
