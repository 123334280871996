import {
    ErrorMessage,
    Field,
    FieldHookConfig,
    FieldInputProps,
    FieldMetaProps,
} from 'formik';
import React from 'react';

export interface TextAreaBoxProps {
    name: string;
    label?: string;
    placeholder: string;
    rows?: number;
    cols?: number;
    value?: any;
    readOnly?: boolean;
    disabled?: boolean;
}

const TextAreaBox: React.FC<TextAreaBoxProps> = (
    props: TextAreaBoxProps & FieldHookConfig<string>
) => {
    const { name, placeholder } = props;

    return (
        <div className="relative w-full py-3">
            <label
                htmlFor={name}
                className="absolute left-1 top-5 w-1/2 text-xs flex items-center px-2 pb-2 pointer-events-none"
            >
                {props.label}
            </label>
            <Field id={name} name={name} readOnly={props.readOnly}>
                {({
                    field,
                    meta,
                }: {
                    field: FieldInputProps<string>;
                    meta: FieldMetaProps<string>;
                }) => (
                    <textarea
                        className={`w-full h-auto pt-8 pl-3 pr-3 placeholder-gray-300 border rounded-lg focus:shadow-outline ${
                            meta.touched && meta.error
                                ? 'border-red-error bg-error'
                                : ''
                        }`}
                        rows={props.rows}
                        placeholder={placeholder}
                        readOnly={props.readOnly}
                        disabled={props.disabled}
                        {...field}
                    />
                )}
            </Field>

            <div className="flex content-start text-red-500 text-sm ">
                <ErrorMessage name={name} render={(msg) => <div>{msg}</div>} />
            </div>
        </div>
    );
};

export default TextAreaBox;
