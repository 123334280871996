import { useState } from 'react';
import { useFormikContext } from 'formik';
import { RadioOption } from '@interfaces/InterfaceFormsProps';
import { useConfigurationState } from '@contexts/ConfigurationContext';
import { FormRadioGroup } from '@components/Molecules';
import { FileUploadAsync } from '@components/Organisms';

import getClientRegistrationFormFields from '../ClientRegistrationFormFields';
import { useRegisterInvestorState } from '../RegisterInvestor.context';
import { useTranslation } from 'react-i18next';
import KYCDocumentsList from '@components/Organisms/KYCDocumentsList/KYCDocumentsList';

export const KycSelection = () => {
    const { t } = useTranslation();
    const { data } = useRegisterInvestorState();
    const { preferences } = useConfigurationState();
    const { handleChange } = useFormikContext();
    const [currentSelection, updateCurrentSelection] = useState();

    const kycOptions = [
        {
            label: t('client.registration.kyc_compliance.yes'),
            abstract: t('client.registration.kyc_compliance.yes.description'),
            value: 'true',
        },
        {
            label: t('client.registration.kyc_compliance.no'),
            abstract: t('client.registration.kyc_compliance.no.description'),
            value: 'false',
        },
    ];

    const isKYCYesEnabled =
        preferences?.feature?.investorRegistrationProcess?.enableRelianceOnKYC;

    const kycCompliantRadioOptions: RadioOption<string>[] = isKYCYesEnabled
        ? kycOptions
        : kycOptions.filter(
              (item: RadioOption<string>) => item.value === 'false'
          );

    const handleOnChange = (e: any) => {
        handleChange(e);
        updateCurrentSelection(e.target.value);
    };

    return (
        <>
            <h2 className="mb-4 text-2xl">
                {t('client.registration.kyc_compliance.title')}
            </h2>
            <p className="text-base py-3">
                {t('client.registration.kyc_compliance.question')}
            </p>

            <FormRadioGroup
                name={getClientRegistrationFormFields(t).isKycCompliant.name}
                optionsData={kycCompliantRadioOptions}
                onChange={handleOnChange}
            />

            <>
                {currentSelection === 'false' && data.endInvestorType && (
                    <div className="mt-2 mb-8">
                        <KYCDocumentsList
                            endInvestorType={data.endInvestorType}
                        />
                    </div>
                )}

                {currentSelection === 'true' && (
                    <p className="text-sm my-4">
                        Please add a reliance letter from the intermediary
                        attesting to the fact that KYC/AML has been completed
                    </p>
                )}
            </>

            <p className="text-xs mb-2">Files should be: PDF, Word or Excel</p>

            <FileUploadAsync
                name="kycDocuments"
                fileLabel="kycDocument"
                hasLabel
            />
        </>
    );
};
