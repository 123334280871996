import { Form, Formik } from 'formik';
import { useTranslation } from 'react-i18next';

import { Icons } from '@components/Atoms';
import { Multiselect, FormInputBox } from '@components/Molecules';

export const SearchBar = ({
    nameSearch,
    setNameSearch,
    endInvestorProfileGroupOptions,
    setSelectedGroupIds,
}: any) => {
    const { t } = useTranslation();
    return (
    <div className="mt-8 mb-4">
        <Formik initialValues={{ search: '', group: [] }} onSubmit={() => {}}>
            <Form className="flex flex-row w-full md:max-w-2xl">
                <div className="flex-1">
                    <FormInputBox
                        name="search"
                        label={t('ui.controls.search')}
                        placeholder={t('clients.search_placeholder')}
                        value={nameSearch}
                        onChange={(val: any) => setNameSearch(val.target.value)}
                        rightIcon={
                            <Icons name="MagnifyingGlassIcon" size="small" />
                        }
                    />
                </div>

                <div className="flex-1 ml-4">
                    <Multiselect
                        label={t('clients.filter_by_group')}
                        name="groups"
                        options={endInvestorProfileGroupOptions}
                        onChange={(val) => {
                            setSelectedGroupIds(val.map((a) => a.value));
                        }}
                        placeholder={t('ui.controls.search')}
                    />
                </div>
            </Form>
        </Formik>
    </div>
)};
