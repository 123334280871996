import { useMemo } from 'react';

import { isEndInvestorNomineeAccount } from '@helpers/EndInvestorHelper';

import ReviewClientSubscriptionsList from './ReviewClientSubscriptionsList';
import { EndInvestorSubscriptionsProfile } from '@interfaces/Api/EndInvestorProfileResponse';

interface NomineeListProps {
    visibleEndInvestorProfiles: EndInvestorSubscriptionsProfile[];
}

export const NomineeList: React.FC<NomineeListProps> = (props) => {
    const { visibleEndInvestorProfiles } = props;
    const nominees = useMemo(
        () =>
            visibleEndInvestorProfiles.filter(
                (nominees: EndInvestorSubscriptionsProfile) =>
                    isEndInvestorNomineeAccount(nominees)
            ),
        [visibleEndInvestorProfiles]
    );

    return (
        <>
            <div className="mb-4">
                <h2 className="text-2xl logo-blue align-middle">
                    Nominee Entities ({nominees.length})
                </h2>
                {nominees.map(
                    (endInvestorProfile: EndInvestorSubscriptionsProfile) => {
                        return (
                            endInvestorProfile && (
                                <ReviewClientSubscriptionsList
                                    endInvestorProfile={endInvestorProfile}
                                />
                            )
                        );
                    }
                )}
            </div>
        </>
    );
};
