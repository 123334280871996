import { useEndInvestorProfileState } from '@contexts/EndInvestorProfileContext';

import { FormSelect } from '@components/Molecules';

import getClientRegistrationFormFields from '../ClientRegistrationFormFields';

import { useConfigurationState } from '@contexts/ConfigurationContext';
import { ClientEnum } from '@interfaces/Api/ConfigurationResponse';
import { useRegisterInvestorState } from '../RegisterInvestor.context';
import { useTranslation } from 'react-i18next';

export const JurisdictionSelection: React.FC = () => {
    const { t } = useTranslation();
    const { data } = useRegisterInvestorState();
    const { investorJurisdictions = [] } = useEndInvestorProfileState();
    const { isClient } = useConfigurationState();

    const onboardingJurisdictionLabelOptions = investorJurisdictions.map(
        (data) => ({
            label: data.jurisdictionCountry,
            value: data.countryCode,
        })
    );

    return (
        <>
            <h2 className="pt-7 py-3 text-2xl">{t('client.registration.client_relationship.onboarding_jurisdiction')}</h2>
            <div>
                {investorJurisdictions.length === 0 ? (
                    <div className="container mx-auto">
                        <p className="text-xl">
                            Onboarding Country not loading!
                        </p>
                        <p>Sorry! There are no countries currently listed!</p>
                    </div>
                ) : (
                    <FormSelect
                        dataTestId={
                            getClientRegistrationFormFields(t)
                                .onboardingJurisdictionCountryCode.name
                        }
                        name={
                            getClientRegistrationFormFields(t)
                                .onboardingJurisdictionCountryCode.name
                        }
                        label={
                            isClient(ClientEnum.VP)
                                ? 'Domicile'
                                : getClientRegistrationFormFields(t)
                                      .onboardingJurisdictionCountryCode.label
                        }
                        optionsData={onboardingJurisdictionLabelOptions}
                    />
                )}
            </div>
        </>
    );
};
