import {
    ReviewClientSummaryDetailsProps,
    businessRelationShipsReviewTypeLabelMap,
} from '@interfaces/ReviewClientSummaryDetailsProps';
import { isoCountries } from '@helpers/isoCountries';
import { toCapitalizedWords } from '@helpers/ToCapitalizedWords';
import { useEndInvestorProfileState } from '@contexts/EndInvestorProfileContext';

import { useSystemUsers } from '@stores/SystemUsers/useSystemUsers';
import { ReviewItem } from './ReviewItem';

const ReviewClientBusinessRelationshipDetails = ({
    data,
}: ReviewClientSummaryDetailsProps) => {
    const { endInvestorProfiles } = useEndInvestorProfileState();
    const profile = endInvestorProfiles.find(
        (profile) => profile._id === data.nomineeEndInvestorProfileId
    );

    const { systemUsers } = useSystemUsers();
    const advisory = systemUsers.find(
        (user) => user._id === data.adviserSystemUserId
    );

    return (
        <div className="leading-5 mb-4">
            {data?.bookingCentreIds && data?.bookingCentreIds.length > 0 && (
                <ReviewItem
                    label="Booking Centres:"
                    value={data?.bookingCentreIds
                        .map((bc) => bc.bookingCentreName)
                        .join()}
                />
            )}

            <ReviewItem
                label="Service Level Type:"
                value={
                    data?.serviceLevelType
                        ? businessRelationShipsReviewTypeLabelMap[
                              data?.serviceLevelType
                          ]
                        : ''
                }
            />

            {advisory && (
                <ReviewItem
                    label="Relationship Manager:"
                    value={`${advisory.firstName} ${' '} ${advisory.surname}`}
                />
            )}

            <ReviewItem
                label="Subscription Process Type:"
                value={
                    data?.subscriptionProcessType
                        ? businessRelationShipsReviewTypeLabelMap[
                              data?.subscriptionProcessType
                          ]
                        : ''
                }
            />

            {profile && (
                <ReviewItem
                    label="Associated nominee:"
                    value={profile?.businessEntityDetails?.companyName}
                />
            )}

            <ReviewItem
                label="Onboarding Jurisdiction:"
                value={
                    data?.onboardingJurisdictionCountryCode
                        ? toCapitalizedWords(
                              isoCountries[
                                  data?.onboardingJurisdictionCountryCode
                              ]
                          )
                        : ''
                }
            />

            <ReviewItem
                label="Client Type:"
                value={
                    data?.clientType ? toCapitalizedWords(data?.clientType) : ''
                }
            />
        </div>
    );
};

export default ReviewClientBusinessRelationshipDetails;
