import React, { Suspense, useState } from 'react';
const DatePicker = React.lazy(() => import('react-datepicker'));

import dayjs from 'dayjs';

import { DatepickerHeader } from './DatepickerHeader';
import { getInputStyle } from '../InputBox/InputBox.style';

import 'react-datepicker/dist/react-datepicker.css';

interface Datepicker {
    isCustomHeader?: boolean;
    dateFormat?: string;
    name?: string;
    label?: string;
    minDate?: Date;
    locale?: string;
    placeholderText?: string;
    maxDate?: Date;
    disabled?: boolean;
    isError?: boolean;
    onChange?: (value: string | Date) => void;
    value: string;
    onBlur?: (e: React.FocusEvent<HTMLInputElement>) => void;
}

const CustomDatePicker: React.FC<Datepicker> = (props) => {
    const {
        name,
        dateFormat,
        locale,
        isCustomHeader = false,
        label,
        minDate,
        maxDate,
        isError,
        disabled,
        onChange,
        placeholderText,
        value,
        onBlur,
    } = props;

    const [startDate, setStartDate] = useState<Date | null>(
        value ? new Date(value) : null
    );

    const handleOnChange = (date: Date) => {
        setStartDate(date);

        if (onChange)
            if (date) {
                onChange(new Date(date));
            } else {
                onChange('');
            }
    };

    const inputStyle = getInputStyle({
        disabled,
        label,
        error: isError,
        cursor: 'pointer',
    });
    return (
        <div className="relative">
            <Suspense fallback={<div />}>
                <DatePicker
                    name={name}
                    selected={startDate}
                    placeholderText={placeholderText}
                    minDate={minDate}
                    disabled={disabled}
                    dateFormat={dateFormat}
                    locale={locale}
                    onBlur={(e: React.FocusEvent<HTMLInputElement>) => {
                        const date = new Date(e.target.value);
                        if (onChange && dayjs(date).isValid()) {
                            onChange(date);
                        }
                        onBlur && onBlur(e);
                    }}
                    maxDate={maxDate}
                    onChange={handleOnChange}
                    {...(isCustomHeader && {
                        renderCustomHeader: (props) => (
                            <DatepickerHeader
                                {...props}
                                onChange={onChange}
                                setStartDate={setStartDate}
                            />
                        ),
                    })}
                    customInput={
                        <input autoComplete="off" className={inputStyle} />
                    }
                />
            </Suspense>
            {label && (
                <label className="absolute left-1 top-2 text-xs flex items-center px-2 pointer-events-none">
                    {label}
                </label>
            )}
        </div>
    );
};

export default CustomDatePicker;
