import { useCallback, useState } from 'react';

import {
    AuthPermissionEnum,
    SigningDocument,
    SigningUserStatusEnum,
    SigningDocumentStatusEnum,
} from '@interfaces/Api';
import {
    RedemptionApplicationSigningDocument,
    SigningMethodEnum,
} from '@interfaces/Api/SigningDocument';
import { docusignEnvelopeGet } from '@api/Docusign';

import { Icons } from '@components/Atoms';

import { useSystemUsers } from '@stores/SystemUsers/useSystemUsers';
import { useAuthState } from '@contexts/AuthContext';
import { useStorageTokenState } from '@contexts/StorageTokenContext';

import { dateTimeFormatter } from '@helpers/Date.helper';
import { notifyError } from '@helpers/toastrHelper';
import { notNull } from '@helpers/typeGuards';
import { downloadFileOnTheClient } from '@helpers/File.helper';

interface DocumentSigningSummaryProps {
    showHeader?: boolean;
    signingDocuments?:
        | SigningDocument[]
        | RedemptionApplicationSigningDocument[];
}

// TODO: this component needs to be moved into a folder that makes sense
const DocumentSigningSummary: React.FC<DocumentSigningSummaryProps> = ({
    showHeader = true,
    signingDocuments,
}) => {
    const { hasPermissions } = useAuthState();
    const { systemUsers } = useSystemUsers();
    const { withToken } = useStorageTokenState();

    const [areDocumentsDownloading, setAreDocumentsDownloading] = useState<
        boolean[]
    >(new Array(signingDocuments?.length || 0).map(() => false));

    const setIsDocumentDownloading = (
        index: number,
        isDownloading: boolean
    ) => {
        const newAreDocumentsDownloading = [
            ...areDocumentsDownloading.slice(0, index),
            isDownloading,
            ...areDocumentsDownloading.slice(index + 1, 0),
        ];
        setAreDocumentsDownloading(newAreDocumentsDownloading);
    };

    const onSignedDocumentDownloadClick = async (
        index: number,
        signingDocument: SigningDocument | RedemptionApplicationSigningDocument
    ) => {
        if (areDocumentsDownloading[index]) return;

        switch (signingDocument.signingMethod) {
            case SigningMethodEnum.docusign:
                signingDocument.signingMethodId &&
                    handleDocumentClick(index, signingDocument.signingMethodId);
                break;
        }
    };

    const handleDocumentClick = async (index: number, envelopeId: string) => {
        setIsDocumentDownloading(index, true);
        try {
            const response = await docusignEnvelopeGet(envelopeId);
            downloadFileOnTheClient(response);
        } catch (err) {
            notifyError(
                'Could not download the document. Error:' + JSON.stringify(err)
            );
        }
        setIsDocumentDownloading(index, false);
    };

    const getSystemUserName = useCallback(
        (systemUserId: string) => {
            const systemUser = systemUsers.find(
                (val) => val._id === systemUserId
            );
            return [systemUser?.firstName, systemUser?.surname]
                .filter((a) => a)
                .join(' ');
        },
        [systemUsers]
    );

    const getDocumentInformationLines = (
        signingDocument: SigningDocument | RedemptionApplicationSigningDocument,
        signingDocumentStatus: SigningDocumentStatusEnum
    ) => {
        const statusLog =
            signingDocument.statusLogs &&
            signingDocument.statusLogs.find(
                (a) => a.status == signingDocumentStatus
            );

        if (!statusLog) {
            return <></>;
        }

        const informationLines: string[] = [];
        if (signingDocumentStatus == SigningDocumentStatusEnum.draft) {
            const createdBy = `Created by: ${getSystemUserName(
                statusLog.updatedBy
            )}`;
            const updatedAt = `Created at: ${dateTimeFormatter(
                statusLog.createdAt
            )}`;
            informationLines.push(createdBy, updatedAt);
        }
        if (signingDocumentStatus == SigningDocumentStatusEnum.sent) {
            const sentBy = `Sent by: ${getSystemUserName(statusLog.updatedBy)}`;
            const sentAt = `Sent at: ${dateTimeFormatter(statusLog.createdAt)}`;
            informationLines.push(sentBy, sentAt);
        }
        if (signingDocumentStatus == SigningDocumentStatusEnum.signed) {
            const completedAt = `Completed at: ${dateTimeFormatter(
                statusLog.createdAt
            )}`;
            informationLines.push(completedAt);
        }

        if (
            [
                SigningDocumentStatusEnum.sent,
                SigningDocumentStatusEnum.signed,
            ].includes(signingDocumentStatus) &&
            signingDocument.signingUsers &&
            signingDocument.signingUsers.length > 0
        ) {
            // add lines for each signer
            const signerLines = signingDocument.signingUsers
                .map((signingUser) => {
                    // add lines for each update created by signer
                    return signingUser.statusLogs
                        .map((log) => {
                            switch (log.status) {
                                case SigningUserStatusEnum.onHold:
                                    if (
                                        signingUser.status ==
                                        SigningUserStatusEnum.onHold
                                    )
                                        return `Waiting for further confirmation to send to ${signingUser.name} (${signingUser.email})`;
                                    break;
                                case SigningUserStatusEnum.sent:
                                    return `Sent to ${signingUser.name} (${
                                        signingUser.email
                                    }) at ${dateTimeFormatter(log.createdAt)}`;
                                case SigningUserStatusEnum.signed:
                                    return `Signed by ${signingUser.name} (${
                                        signingUser.email
                                    }) at ${dateTimeFormatter(log.createdAt)}`;
                                case SigningUserStatusEnum.declined:
                                    return `Rejected by ${signingUser.name} (${
                                        signingUser.email
                                    }) at ${dateTimeFormatter(log.createdAt)}`;
                                default:
                                    break;
                            }
                        })
                        .filter(notNull);
                })
                .flat(2);
            informationLines.push(...signerLines);
        }

        return (
            <>
                {informationLines.map((a, i) => (
                    <p key={a + i} className="text-xs pl-4">
                        {a}
                    </p>
                ))}
            </>
        );
    };

    if (!signingDocuments) {
        return <div></div>;
    }

    return (
        <div className="w-full">
            {hasPermissions(AuthPermissionEnum.getDocuSignEnvelope) &&
                signingDocuments &&
                signingDocuments.length > 0 && (
                    <>
                        {showHeader && (
                            <h3 className="text-xl font-normal py-3">
                                Subscription Documents
                            </h3>
                        )}

                        {signingDocuments.map((signingDocument, index) => (
                            <div
                                className="mb-4"
                                key={signingDocument.fileName + index}
                            >
                                <h3 className="text-lg">
                                    {signingDocument.fileName &&
                                        signingDocument.fileName.split('-')[0]}
                                </h3>

                                {getDocumentInformationLines(
                                    signingDocument,
                                    SigningDocumentStatusEnum.draft
                                )}

                                {signingDocument.status ==
                                    SigningDocumentStatusEnum.sent && (
                                    <>
                                        <a
                                            className="text-md flex cursor-pointer pt-2"
                                            onClick={() =>
                                                !areDocumentsDownloading[
                                                    index
                                                ] &&
                                                signingDocument.signingMethodId &&
                                                handleDocumentClick(
                                                    index,
                                                    signingDocument.signingMethodId
                                                )
                                            }
                                        >
                                            <div className="pr-2">
                                                <Icons
                                                    name={
                                                        areDocumentsDownloading[
                                                            index
                                                        ]
                                                            ? 'Loading'
                                                            : 'ArrowDownTrayIcon'
                                                    }
                                                />
                                            </div>
                                            Download in progress document
                                        </a>
                                        {getDocumentInformationLines(
                                            signingDocument,
                                            SigningDocumentStatusEnum.sent
                                        )}
                                    </>
                                )}

                                {signingDocument.status ==
                                    SigningDocumentStatusEnum.signed && (
                                    <>
                                        {signingDocument.signingMethod ===
                                            SigningMethodEnum.manual &&
                                            signingDocument.signedDocument
                                                ?.url && (
                                                <>
                                                    <a
                                                        className="text-md flex cursor-pointer pt-2"
                                                        href={withToken(
                                                            signingDocument
                                                                ?.signedDocument
                                                                ?.url
                                                        )}
                                                        target="_blank"
                                                        rel="noreferrer"
                                                    >
                                                        <div className="pr-2">
                                                            <Icons
                                                                name={
                                                                    areDocumentsDownloading[
                                                                        index
                                                                    ]
                                                                        ? 'Loading'
                                                                        : 'ArrowDownTrayIcon'
                                                                }
                                                            />
                                                        </div>
                                                        Download signed document
                                                    </a>
                                                </>
                                            )}
                                        {signingDocument.signingMethod !==
                                            SigningMethodEnum.manual && (
                                            <>
                                                <a
                                                    className="text-md flex cursor-pointer pt-2"
                                                    onClick={() =>
                                                        onSignedDocumentDownloadClick(
                                                            index,
                                                            signingDocument
                                                        )
                                                    }
                                                >
                                                    <div className="pr-2">
                                                        <Icons
                                                            name={
                                                                areDocumentsDownloading[
                                                                    index
                                                                ]
                                                                    ? 'Loading'
                                                                    : 'ArrowDownTrayIcon'
                                                            }
                                                        />
                                                    </div>
                                                    Download signed document
                                                </a>
                                            </>
                                        )}
                                        {getDocumentInformationLines(
                                            signingDocument,
                                            SigningDocumentStatusEnum.signed
                                        )}
                                    </>
                                )}

                                <p className="my-8">
                                    {/* {JSON.stringify(signingDocument)} */}
                                </p>
                            </div>
                        ))}
                        <div className="border-b"></div>
                    </>
                )}
        </div>
    );
};

export default DocumentSigningSummary;
