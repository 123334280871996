import React from 'react';

interface DatepickerHeaderProps {
    date: Date;
    changeYear(year: number): void;
    changeMonth(month: number): void;
    decreaseMonth(): void;
    increaseMonth(): void;
    prevMonthButtonDisabled: boolean;
    nextMonthButtonDisabled: boolean;
    setStartDate: Function;
    onChange?: (val: Date) => void;
}

export const DatepickerHeader: React.FC<DatepickerHeaderProps> = ({
    date,
    changeMonth,
    changeYear,
    decreaseMonth,
    increaseMonth,
    nextMonthButtonDisabled,
    prevMonthButtonDisabled,
    setStartDate,
    onChange,
}: DatepickerHeaderProps) => {
    const generateArrayOfYears = () => {
        const max = new Date().getFullYear();
        const min = max - 60;
        const years = [];

        for (let i = max; i >= min; i--) {
            years.push(i);
        }
        return years;
    };

    const years = generateArrayOfYears();
    const months = [
        'January',
        'February',
        'March',
        'April',
        'May',
        'June',
        'July',
        'August',
        'September',
        'October',
        'November',
        'December',
    ];

    const updateSelectedDate = (date: Date) => {
        setStartDate(date);
        onChange && onChange(date);
    };

    const setOption = (option: string | number) => (
        <option key={option} value={option}>
            {option}
        </option>
    );

    return (
        <div className="flex justify-evenly p-1">
            <button onClick={decreaseMonth} disabled={prevMonthButtonDisabled}>
                {'<'}
            </button>

            <select
                className="p-1"
                value={months[date.getMonth()]}
                onChange={({ target: { value } }) => {
                    changeMonth(months.indexOf(value));
                    const updatedDate = date;
                    updatedDate.setMonth(months.indexOf(value));
                    updateSelectedDate(updatedDate);
                }}
            >
                {months.map(setOption)}
            </select>

            <select
                className="p-1"
                value={date.getFullYear()}
                onChange={({ target: { value } }) => {
                    changeYear(Number(value));
                    const updatedDate = date;
                    updatedDate.setFullYear(Number(value));
                    updateSelectedDate(updatedDate);
                }}
            >
                {years.map(setOption)}
            </select>

            <button onClick={increaseMonth} disabled={nextMonthButtonDisabled}>
                {'>'}
            </button>
        </div>
    );
};
