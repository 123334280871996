import { BankAccount } from './BankAccount';

enum DividendPaymentEnum {
    'Payout',
    'Reinvest',
}

export interface DistributionOfFunds {
    subscriptionPayments?: BankAccount;
    bankAccount: BankAccount;
    dividendPayments?: DividendPaymentEnum;
    distributionsCorrespondentBank? : BankAccount;
    subscriptionPaymentsCorrespondentBank?: BankAccount;
}
