import { useAuthState } from '@contexts/AuthContext';
import { useConfigurationState } from '@contexts/ConfigurationContext';
import { dateTimeFormatter } from '@helpers/Date.helper';
import {
    ClientEnum,
    EnvironmentEnum,
} from '@interfaces/Api/ConfigurationResponse';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { strategies, getStrategies } from '@services/Strategies';

export const useUserInfo = () => {
    const { logout, currentUser } = useAuthState();
    const [isDropDownOpen, setIsDropDownOpen] = useState<boolean>(false);
    const [initials, setInitials] = useState<string>('👤');
    const [fullName, setFullName] = useState<string>('');

    const navigate = useNavigate();
    const { configuration, isEnvironment, isClient } = useConfigurationState();

    const isPrivacyLinkVisible =
        isEnvironment(EnvironmentEnum.LOCAL) ||
        isEnvironment(EnvironmentEnum.DEV) ||
        isClient(ClientEnum.VP);

    const canChangePassword = !currentUser?.user?.disableChangeOwnPassword;

    const lastLoginDate = dateTimeFormatter(
        currentUser?.user.currentLoginDate ||
            currentUser?.user.lastLoginDate ||
            new Date(),
        true
    );

    useEffect(() => {
        if (currentUser) {
            const user = currentUser.user;

            const fullName = [user?.firstName, user?.surname]
                .filter((a) => a)
                .join(' ');
            setFullName(fullName);

            const initials = fullName
                .split(' ')
                .map((a) => a[0])
                .join('');
            setInitials(initials);
        }
    }, [currentUser]);

    const handleLogout = () => {
        logout();
        navigate('/login');

        const strategy = getStrategies(configuration?.client as ClientEnum);

        if (strategy && strategy.onLogout) {
            strategy.onLogout();
        }
    };

    const toggleDropDownOpen = () => setIsDropDownOpen(!isDropDownOpen);

    return {
        initials,
        fullName,
        role: currentUser?.user.role,
        availableRoles: currentUser?.user.availableRoles,
        handleLogout,
        isDropDownOpen,
        toggleDropDownOpen,
        isPrivacyLinkVisible,
        canChangePassword,
        lastLoginDate,
        platformName: configuration?.platformName,
        isClient,
    };
};
