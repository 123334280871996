import { Button } from "@components/Atoms";
import { AlertBox } from "@components/Molecules";
import { Pagination } from "@components/Organisms";
import { useSortableData } from "@hooks/useSortableData/useSortableData";
import { DocumentTemplate, DocumentTemplateRow } from "@interfaces/Api/DocumentTemplate";
import DocumentTemplateTable from "@pages/Admin/Containers/DocumentTemplateLibrary/components/DocumentTemplateTable";
import { ModalService } from "@services/ModalService";
import { FC, useState, useCallback, useEffect } from "react";

export const TemplateSelections: FC<{
    templateDocuments: DocumentTemplate[];
    onAdd: (documentIds: string[]) => void;
}> = ({ templateDocuments, onAdd }) => {
    const [itemsPerPage, setItemsPerPage] = useState<number | string>(50);
    const [selectedDocumentIds, setSelectedDocumentIds] = useState<string[]>(
        []
    );
    const [hasError, setHasError] = useState<boolean>(false);

    const handleCancel = useCallback(() => {
        ModalService.getInstance().hideModal();
    }, []);

    const handleAdd = useCallback(() => {
        ModalService.getInstance().hideModal();

        if (selectedDocumentIds) {
            onAdd(selectedDocumentIds);
        }
    }, [onAdd, selectedDocumentIds]);

    const {
        tableData,
        tableDataLength,
        requestSort,
        sortConfig,
        currentPageNumber,
        paginate,
        paginateBack,
        paginateFront,
    } = useSortableData({
        tableData: templateDocuments,
        itemsPerPage,
    });

    const pagination = (
        <Pagination
            rowsPerPage={itemsPerPage}
            itemsLength={tableDataLength}
            paginate={paginate}
            currentPage={currentPageNumber}
            paginateBack={paginateBack}
            paginateFront={paginateFront}
            onPageSizeChange={setItemsPerPage}
        />
    );

    const handelSelectionChange = useCallback(
        (document: DocumentTemplateRow, checked) => {
            if (checked && !selectedDocumentIds.includes(document._id)) {
                setSelectedDocumentIds((pre) => {
                    return [...pre, document._id];
                });
            } else if (!checked && selectedDocumentIds.includes(document._id)) {
                setSelectedDocumentIds((pre) => {
                    return pre.filter((id) => id !== document._id);
                });
            }
        },
        [selectedDocumentIds]
    );

    useEffect(() => {
        // need to check if there are more the two documents have same document type
        const documentTypeMap = {};
        const documentMap = templateDocuments.reduce((acc, current) => {
            acc[current._id] = current;
            return acc;
        }, {});

        let error = false;

        for (const documentId of selectedDocumentIds) {
            const docType = documentMap[documentId].documentType;

            if (!documentTypeMap[docType]) {
                documentTypeMap[docType] = [];
            }

            documentTypeMap[docType].push(documentId);

            if (documentTypeMap[docType].length >= 2) {
                error = true;
                break;
            }
        }

        setHasError(error);
    }, [selectedDocumentIds, templateDocuments]);

    return (
        <div className="relative w-full mb-3 cursor-default">
            {hasError && (
                <AlertBox
                    alertType="error"
                    title="Error"
                    message="You can only select one document for each document type!"
                />
            )}
            {pagination}
            <DocumentTemplateTable
                searchText={''}
                items={tableData}
                requestSort={requestSort}
                columnSortConfig={sortConfig}
                setShowDocumentModal={() => {}}
                selectable
                hideOperations
                showDocumentUploadModal={false}
                onSelectCallback={handelSelectionChange}
            />
            {pagination}
            <div className="flex justify-between mt-5">
                <Button buttonType="secondary" onClick={handleCancel}>
                    {' '}
                    Cancel{' '}
                </Button>
                <Button
                    buttonType="primary"
                    onClick={handleAdd}
                    disabled={hasError}
                >
                    Add Templates
                </Button>
            </div>
        </div>
    );
};