import { BaseUrl } from '../Helpers/Environment';
import { Http } from '../libs/http';
import { ProductGroup } from '@interfaces/Api/ProductGroup';

export const PRODUCT_GROUP_URL = `${BaseUrl}/product-group`;

export const productGroupsGet = () => {
    return Http.get<ProductGroup[]>(PRODUCT_GROUP_URL);
};

export const productGroupsPost = (newGroup: ProductGroup) => {
    return Http.post<ProductGroup>(PRODUCT_GROUP_URL, newGroup);
};

export const productGroupDelete = (id: string) => {
    return Http.delete(`${PRODUCT_GROUP_URL}/${id}`);
};
