import { useSystemUsersStore } from './SystemUsers.store';


const useSystemUsersState = () =>
    useSystemUsersStore((state) => ({
        isLoading: state.isLoading,
        systemUsers: state.systemUsers,
    }));

const useSystemUsersActions = () =>
    useSystemUsersStore((state) => state.actions);

export { useSystemUsersState, useSystemUsersActions };
