import classNames from 'classnames';
import React, { ReactNode } from 'react';
import Highlighter from 'react-highlight-words';

export type BadgeType =
    | 'neutral'
    | 'primary'
    | 'secondary'
    | 'info'
    | 'success'
    | 'error'
    | 'preview'
    | 'supplementary';

type SizeType = 'small' | 'medium' | 'large' | 'xlarge' | 'xxlarge';

const buttonTypeClassName: { [key in BadgeType]: string } = {
    neutral: 'bg-white border border-zinc-300 text-black',
    primary: 'bg-logo-blue text-white',
    secondary: 'bg-yellow-400 text-yellow-700 border-zinc-300',
    info: `text-logo-blue border-zinc-300`,
    success: 'bg-green-500 text-white',
    error: 'bg-red-500 text-white',
    preview: 'bg-gold-300',
    supplementary: 'bg-container-grey',
};

const sizeTypeClassName: { [key in SizeType]: string } = {
    small: 'px-2 py-1 text-xs',
    medium: 'px-3 py-2 text-sm',
    large: 'px-3 py-2 text-base',
    xlarge: 'px-3 py-2 text-lg',
    xxlarge: 'px-3 py-2 text-xl',
};

interface BadgeProps {
    label: string;
    badgeType: BadgeType;
    sizeType: SizeType;
    startIcon?: ReactNode;
    endIcon?: ReactNode;
    highlightedText?: string;
}

const Badge: React.FC<BadgeProps> = (props) => (
    <div
        className={classNames([
            'rounded-full leading-none flex items-center max-w-max',
            buttonTypeClassName[props.badgeType],
            sizeTypeClassName[props.sizeType],
        ])}
    >
        <div className="flex items-center justify-center text-center">
            {props.startIcon && (
                <span className="p-1 text-xs ml-1">{props.startIcon}</span>
            )}
            <Highlighter
                searchWords={props.highlightedText?.split(' ') || []}
                autoEscape={true}
                textToHighlight={props.label}
            />

            {props.endIcon && (
                <span className="p-1 text-xs ml-1">{props.endIcon}</span>
            )}
        </div>
    </div>
);

export default Badge;
